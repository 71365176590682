import PWPop from "../../../img/pass_pop.png";
const PasswordFound = ({ id, setIsSuccess, setId, setOpen, phone }) => {
  return (
    <div>
      <div id="comm_layer" className="pop-con">
        <div className="id_tit id_center">
          <span>
            <img src={PWPop} alt="비번찾기아이콘" />
          </span>
          <div className="text">
            <strong className="te_center">
              <p>
                입력하신 <em>{phone}</em> 로
              </p>
              <p>임시비밀번호가 발급되었습니다.</p>
            </strong>
            <p>
              지금 바로 문자를 확인하여 발급받은 임시비밀번호로 로그인 해주세요.
            </p>
          </div>
        </div>
        <div className="s-button s-idpass padt30 cf">
          <button
            className="id_sear sc"
            onClick={() => {
              setIsSuccess(false);
              setOpen(false);
              setId("");
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
export default PasswordFound;
