import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ElecEsnAtom, ElecLabelAtom } from "../../../recoil";
import { useElecActions } from "../../../recoil/api/elec";
import ElecFooter from "../../common/elec/ElecFooter";
import ViewElec from "../../common/elec/ViewElec";
import { ElecURLAtom } from "../../../recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { JOINDOCTYPE } from "../../../JSON/enum/Dataenum";
import CompanyCreateSidebar from "./CompanyCreateSidebar";

const CompanySignatureElec = () => {
  const elecActions = useElecActions();
  const [docURL, setDocURL] = useRecoilState(ElecURLAtom);
  const esn = useRecoilValue(ElecEsnAtom);
  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const navigate = useNavigate();
  const { type } = useParams();
  const { state } = useLocation();

  //docURL과 esn이 없거나, type이 없을 때
  useEffect(() => {
    if ((!docURL && !esn) || !type) {
      navigate("/company/eplydoc");
    } else if (type) {
      if (parseInt(type) !== JOINDOCTYPE.work_contract || state?.sample) {
        getCommonDoc(type, state.contract_sn);
      }
    }
  }, []);

  const getCommonDoc = async (type, contract) => {
    setDocURL("");
    const res = await elecActions.getCommonDoc(type, contract);
    if (res.status === 200) {
      setDocURL(res?.data?.url);
      setLabels(JSON.parse(res?.data?.json));
    }
    return res;
  };

  return (
    <div className="elec-container">
      <CompanyCreateSidebar mode="sign" isCompany={true} />
      <div className="elec-content">
        <div style={{ position: "absolute", top: "80px", right: "10px" }}>
          <button
            className="primarybtn-sm border-line zoom_font"
            onClick={() => {
              if (
                window.confirm(
                  "등록한 사인/도장, 텍스트, 체크박스의 항목을 모두 입력해주세요."
                )
              ) {
                navigate("/company/eplydoc");
              }
            }}
          >
            닫기
          </button>
        </div>
        {docURL && (
          <ViewElec mode="sign" isCompany={true} src={docURL} type={type} />
        )}
      </div>
      <ElecFooter
        onSubmit={() => elecActions.companySignatureElec(type, labels)}
      />
    </div>
  );
};

export default CompanySignatureElec;
