import { Link } from "react-router-dom";
import { authAtom } from "../../../recoil";
import { useRecoilValue } from "recoil";
import linkButton from "../../../ico/home_link_btn.svg";
import HomeScheduleCardInfo from "./HomeScheduleCardInfo";
import dayjs from "dayjs";
import { useAccoutTypeActions } from "../../../recoil/api/auth";

const HomeScheduleCard = ({
  title,
  link,
  scheduleDocs,
  getScheduleDocsList,
}) => {
  const accountType = useAccoutTypeActions();
  const auth = useRecoilValue(authAtom);

  return (
    <div className="checkcal">
      <h2 className="title-h2 zoom_font" style={{ display: "inline-block" }}>
        {title}
      </h2>
      <span>
        <Link to={`/${auth.accountType}/${link}`}>
          <button
            className="border-line"
            style={{
              float: "right",
            }}
            id="vacation"
          >
            <img src={linkButton} alt="바로가기" aria-hidden="true" />
          </button>
        </Link>
      </span>
      {scheduleDocs?.list?.map((item, index) => {
        const dday = accountType.isEmployee()
          ? dayjs(item.end_date).diff(dayjs(), "day") + 1
          : dayjs(item.end_time).diff(dayjs(), "day") + 1;
        const color = dday <= 3 ? "#CE3426" : dday <= 5 ? "#FFAB00" : "#36B37E";
        return (
          <HomeScheduleCardInfo
            key={index}
            item={item}
            color={color}
            getScheduleDocsList={getScheduleDocsList}
          />
        );
      })}
    </div>
  );
};

export default HomeScheduleCard;
