import X from "../../../img/x.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  MailListUpdateAtom,
  MailModalAtom,
  MailUserListQuery,
  MailViewAtom,
} from "../../../recoil";
import Draggable from "react-draggable";
import ReceivedMail from "./ReceivedMail";
import SendMail from "./SendMail";
import SentMail from "./SentMail";
import ReservationMail from "./ReservationMail";
import ReceivedMailDetail from "./ReceivedMailDetail";
import SentMailDetail from "./SentMailDetail";
import React, { useEffect, useState } from "react";
import { socket } from "../../../socket";
import { SOCKETENUM } from "../../../JSON/enum/Dataenum";
import EditMail from "./EditMail";

export const MAIL_VIEW = {
  RECEIVED_MAIL: 0,
  SENT_MAIL: 1,
  SEND_MAIL: 2,
  RESERVATION_MAIL: 3,
  RECEIVED_MAIL_DETAIL: 4,
  EDIT_MAIL: 5,
};

export const MailLayout = () => {
  const [mailModalOpen, setMailModalOpen] = useRecoilState(MailModalAtom);
  const [view, setView] = useRecoilState(MailViewAtom);
  const mailListUpdate = useSetRecoilState(MailListUpdateAtom);
  const setMailUserList = useSetRecoilState(MailUserListQuery());
  const [prevInfo, setPrevInfo] = useState(["", null]);
  const [prevInfo2, setPrevInfo2] = useState(["", null]);

  useEffect(() => {
    socket.on(SOCKETENUM.NOTENOTI, (res) => {
      mailListUpdate((update) => update + 1);
    });

    return () => {
      socket.off(SOCKETENUM.NOTENOTI);
    };
  }, []);

  useEffect(() => {
    if (mailModalOpen) {
      setView(MAIL_VIEW.RECEIVED_MAIL);
    }
  }, [mailModalOpen]);

  const removePrevInfo = () => {
    setPrevInfo(["", null]);
    setPrevInfo2(["", null]);
  };

  return (
    <Draggable
      handle=".chat-top"
      bounds={{
        top: 0,
        left: -(window.document.querySelector("#root").clientWidth - 500),
        right: 0,
        bottom: window.document.querySelector("#root").clientHeight - 750,
      }}
      defaultPosition={{
        x: -(window.document.querySelector("#root").clientWidth - 700),
        y: 0,
      }}
    >
      <div
        className="chat-box message-pop"
        style={{ display: mailModalOpen ? "block" : "none" }}
      >
        <div className="chat-top">
          <div>쪽지함</div>
          <div>
            <button onClick={() => setMailModalOpen(false)}>
              <img src={X} alt="닫기" />
            </button>
          </div>
        </div>
        <div className="ptab">
          <ul>
            <li
              className={view === MAIL_VIEW.RECEIVED_MAIL ? "on" : "off"}
              onClick={() => {
                removePrevInfo();
                setView(MAIL_VIEW.RECEIVED_MAIL);
              }}
            >
              <a>받은쪽지</a>
            </li>
            <li
              className={view === MAIL_VIEW.SENT_MAIL ? "on" : "off"}
              onClick={() => {
                removePrevInfo();
                setView(MAIL_VIEW.SENT_MAIL);
              }}
            >
              <a>보낸쪽지</a>
            </li>
            <li
              className={view === MAIL_VIEW.SEND_MAIL ? "on" : "off"}
              onClick={() => {
                setMailUserList({ reset: true });
                setView(MAIL_VIEW.SEND_MAIL);
              }}
            >
              <a>쪽지보내기</a>
            </li>
            <li
              className={view === MAIL_VIEW.RESERVATION_MAIL ? "on" : "off"}
              onClick={() => {
                setView(MAIL_VIEW.RESERVATION_MAIL);
              }}
            >
              <a>예약쪽지</a>
            </li>
          </ul>
        </div>
        {mailModalOpen && (
          <React.Suspense fallback={<div>Loading...</div>}>
            {view === MAIL_VIEW.RECEIVED_MAIL && (
              <ReceivedMail prevInfo={prevInfo} setPrevInfo={setPrevInfo} />
            )}
            {view === MAIL_VIEW.RECEIVED_MAIL_DETAIL && <ReceivedMailDetail />}
            {/* 받은쪽지 */}{" "}
            {view === MAIL_VIEW.SENT_MAIL && (
              <SentMail prevInfo={prevInfo2} setPrevInfo={setPrevInfo2} />
            )}
            {view === MAIL_VIEW.SENT_MAIL_DETAIL && <SentMailDetail />}
            {/* 보낸쪽지 */}
            {view === MAIL_VIEW.SEND_MAIL && <SendMail />}
            {/* 쪽지보내기 */}
            {view === MAIL_VIEW.RESERVATION_MAIL && <ReservationMail />}
            {/* 예약쪽지 */}
            {view === MAIL_VIEW.EDIT_MAIL && <EditMail />}
            {/* 쪽지수정 */}
          </React.Suspense>
        )}
      </div>
    </Draggable>
  );
};

export default MailLayout;
