import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { authAtom } from "../../../recoil";
import { useRecoilValue } from "recoil";
import { useWorkGuideActions } from "../../../recoil/api/workGuide";
import BreadCrumb from "../BreadCrumb";
import { downloadFile } from "../../../utils";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

const WorkGuideView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const param = useParams();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // workGuideView 상태 값
  const [workGuideData, setWorkGuideData] = useState([]);
  const [fileData, setFileData] = useState([]);

  // API 호출 함수 - workGuideView 데이터 불러오기
  const workGuideActions = useWorkGuideActions();

  const loadWorkGuideView = async (docsn) => {
    const res = await workGuideActions.getWorkGuideView(docsn);
    if (res.status === 200) {
      setWorkGuideData(() => res.data);
    }

    if (res.data.url) {
      const res = await workGuideActions.getWorkGuideFileUrl(docsn);
      setFileData(res.data);
    }
  };

  useEffect(() => {
    loadWorkGuideView({ docsn: param.sn });
  }, []);

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form id="save_form" method="get" className="workform">
        <div className="table-responsive">
          <table className="writetable">
            <tbody>
              <tr>
                <th className="text-center-title w-15 zoom_font">작성자</th>
                <td className="zoom_font">{workGuideData?.writer}</td>
                <th className="text-center-title w-15 zoom_font">작성일</th>
                <td className="zoom_font">
                  {dayjs(workGuideData?.createtime).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">제목</th>
                <td colSpan="3" className="zoom_font">
                  {workGuideData?.title}
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="3" className="content-td-height">
                  <div
                    className="zoom_font_id ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(workGuideData?.contents),
                    }}
                  ></div>
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">파일첨부</th>
                <td colSpan="3">
                  {/* props 변경을 감지해야 리랜더링 됨 */}
                  {fileData?.map((value, index) => (
                    <div key={index}>
                      <a
                        onClick={() => {
                          downloadFile(value);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {`${value?.filename}.${value?.extension}`}
                      </a>
                      <br />
                    </div>
                  ))}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <div className="writebtnbox">
        {/* 수정 버튼 - 기업, 관리 계정일 때 활성화 */}
        {(auth.user.isCompany || auth.user.isAdmin) && (
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="button"
            onClick={() =>
              navigate(
                `/${auth.accountType}/business_guide_edit/${workGuideData.workguide_sn}`
              )
            }
          >
            수정
          </button>
        )}

        <button
          className="writebtn cancelbtn border-line mouse zoom_font"
          type="button"
          onClick={() =>
            navigate(`/${auth.accountType}/business_guide`, {
              state: { page: state?.page, keyword: state?.keyword, prev: true },
            })
          }
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default WorkGuideView;
