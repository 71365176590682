import "./css/common.css";
import "./css/common2.css";
import "./css/layout.css";
import "./css/style.css";
import "./css/style2.css";
import "./css/style3.css";
import "./css/elec.css";
import "./css/popup.css";
import "./css/chat.css";
import "./css/accessibility.css";
import "./css/popup_write.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tabs/style/react-tabs.css";
import "react-quill/dist/quill.snow.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RoomListAtom, authAtom } from "./recoil";
import { ACCOUNTTYPE } from "./JSON/enum/account";
import { getKeyByValue } from "./utils";

import Home from "./component/common/Home";
import Login from "./component/common/Login";
import WorkOrderWrite from "./component/common/WorkOrder/WorkOrderWrite";
import WorkOrder from "./component/common/WorkOrder/WorkOrder";
import RegisterSelect from "./component/common/RegisterSelect";
import Schedule from "./component/common/Schedule/Schedule";
import ScheduleList from "./component/common/Schedule/ScheduleList";
import CompanyRegister from "./component/company/Register";
import WorkOrderView from "./component/common/WorkOrder/WorkOrderView";
import PaymentInsert from "./component/company/PaymentInsert";
import Payment from "./component/company/Payment";
import PaymentModify from "./component/company/PaymentModify";
import CompanyLayout from "./component/company/CompanyLayout";
import UserRegister from "./component/employee/Register";
import EmployeeLayout from "./component/employee/EmployeeLayout";
import EmployeeVacation from "./component/employee/TNAManagement/EmployeeVacation";
import WorkGuide from "./component/common/WorkGuide/WorkGuide";
import WorkGuideWrite from "./component/common/WorkGuide/WorkGuideWrite";
import WorkGuideView from "./component/common/WorkGuide/WorkGuideView";
import EmployeeWorkstamp from "./component/employee/TNAManagement/EmployeeWorkstamp";
import CompanyWorkstamp from "./component/company/TNAManagement/CompanyWorkstamp";
import WorkArchive from "./component/common/WorkArchive/WorkArchive";
import WorkReport from "./component/common/WorkReport/WorkReport";
import WorkReportView from "./component/common/WorkReport/WorkReportView";
import Fresh from "./component/common/TNAManagement/Fresh";
import Doc from "./component/common/Doc/Doc";
import WorkOrderEdit from "./component/common/WorkOrder/WorkOrderEdit";
import Reason from "./component/company/TNAManagement/Reason";
import CompanyVacation from "./component/company/TNAManagement/CompanyVacation";
import Settings from "./component/company/TNAManagement/Settings";
import WorkReportWrite from "./component/common/WorkReport/WorkReportWrite";
import WorkReportEdit from "./component/common/WorkReport/WorkReportEdit";
import WorkGuideEdit from "./component/common/WorkGuide/WorkGuideEdit";
import { useAccoutTypeActions } from "./recoil/api/auth";
import ElecLayout from "./component/common/ElecLayout";
import CompanyCreateElec from "./component/company/elec/CompanyCreateElec";
import Eplydoc from "./component/common/HRSupport/Eplydoc";
import EplydocList from "./component/common/HRSupport/EplydocList";
import CompanySignatureElec from "./component/company/elec/CompanySignatureElec";
import EmployeeSignatureElec from "./component/employee/elec/EmployeeSignatureElec";
import BoardNotice from "./component/common/Board/BoardNotice";
import BoardBenefit from "./component/common/Board/BoardBenefit";
import BoardNoticeView from "./component/common/Board/BoardNoticeView";
import BoardNoticeWrite from "./component/common/BoardNotice/BoardNoticeWrite";
import BoardNoticeEdit from "./component/common/Board/BoardNoticeEdit";
import BoardBenefitWrite from "./component/common/Board/BoardBenefitWrite";
import BoardBenefitView from "./component/common/Board/BoardBenefitView";
import BoardBenefitEdit from "./component/common/Board/BoardBenefitEdit";
import Approval from "./component/common/Approval/Approval";
import ApprovalWrite from "./component/common/Approval/ApprovalWrite";
import Management from "./component/admin/management/Management";
import CompanyRegist from "./component/admin/settings/CompanyRegist";
import EmployeeManage from "./component/admin/management/EmployeeManage";
import Hrm from "./component/common/hrm/Hrm";
import HrmWrite from "./component/employee/hrm/HrmWrite";
import HrmDetail from "./component/common/hrm/HrmDetail";
import Working from "./component/admin/TNAManagement/Working";
import PaymentPrint from "./component/company/PaymentPrint";
import Popup from "./component/common/Popup/Popup";
import PopupWrite from "./component/common/Popup/PopupWrite";
import PopupEdit from "./component/common/Popup/PopupEdit";
import ChatMonitoring from "./component/admin/management/ChatMonitoring";
import PostMonitoring from "./component/admin/management/PostMonitoring";
import HrmEdit from "./component/employee/hrm/HrmEdit";
import CommonCode from "./component/admin/settings/CommonCode";
import MenuConfig from "./component/admin/settings/MenuConfig";
import DocumentFormWrite from "./component/common/hrm/DocumentFormWrite";
import DocumentForm from "./component/common/hrm/DocumentForm";
import { useEffect } from "react";
import { socket } from "./socket";
import { toast } from "react-toastify";
import Logo from "./ico/flip_logo.svg";
import mailReceive from "./img/notification_sound.wav";
import ScheduleDocsList from "./component/common/Schedule/ScheduleDocsList";
import CompanyVacationRule from "./component/company/TNAManagement/CompanyVacationRule";
import CompanyVacationHistory from "./component/company/TNAManagement/CompanyVacationHistory";
import CompanyVacationRules from "./component/admin/management/CompanyVacationRules";

function App() {
  const roomList = useRecoilValue(RoomListAtom);
  const setRoomList = useSetRecoilState(RoomListAtom);
  const ButtonWithSoundEffect = async () => {
    try {
      const audio = new Audio(mailReceive);
      await audio.play();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    socket.on("alarm", async (msg) => {
      notify(msg);
      await ButtonWithSoundEffect();
    });
    return () => {
      socket.off("alarm");
    };
  }, []);

  useEffect(() => {
    socket.on("CHATROOMLISTRE", async (res) => {
      if (roomList) {
        const copyRoomList = [...roomList];
        let newRoomList = JSON.parse(res.roomList).roomList[0];
        for (let i = 0; i < copyRoomList.length; i++) {
          if (copyRoomList[i].room_sn === newRoomList.room_sn) {
            copyRoomList[i] = { ...newRoomList };
          }
        }
        setRoomList(copyRoomList);
      }
    });
    return () => {
      socket.off("CHATROOMLISTRE");
    };
  }, [roomList]);

  const notify = (content) =>
    toast.info(content, {
      icon: () => <img src={Logo} />,
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      bodyClassName: "toastBold",
    });

  const auth = useRecoilValue(authAtom);
  const accountType = useAccoutTypeActions();

  if (!auth) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/register-select" element={<RegisterSelect />}></Route>
          <Route path="/company/register" element={<CompanyRegister />}></Route>
          <Route path="/user/register" element={<UserRegister />}></Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* 관리자 */}
        {auth.user.accountType === ACCOUNTTYPE.ADMIN && (
          <>
            <Route path="admin" element={<EmployeeLayout />}>
              <Route path="home" element={<Home />} />
              <Route path="payment" element={<Payment />} />
              <Route path="payment_insert" element={<PaymentInsert />} />
              <Route path="payment_edit/:sn" element={<PaymentModify />} />
              <Route path="payment_print/:sn" element={<PaymentPrint />} />
              {/* 스케줄 */}
              <Route path="schedule" element={<Schedule />} />
              <Route path="schedule_list" element={<ScheduleList />} />
              <Route path="schedule_docs" element={<ScheduleDocsList />} />

              {/* 사내 문서 탭 */}
              <Route path="innerdoc" element={<Doc />} />

              {/* 전자 결재 탭 */}
              <Route path="elec" element={<Approval state={0} />} />
              <Route path="elecaccept" element={<Approval state={1} />} />
              <Route path="elecdeny" element={<Approval state={2} />} />
              <Route path="eleccomplete" element={<Approval state={3} />} />

              {/* 인사지원 탭 */}
              <Route path="hrm" element={<Hrm />} />
              <Route path="hrm_detail/:sn" element={<HrmDetail />} />
              <Route path="eplydoc" element={<Eplydoc />} />
              <Route path="joindoc" element={<EplydocList />} />
              <Route path="docform" element={<DocumentForm />} />
              <Route
                path="docform_write/:type/:sn"
                element={<DocumentFormWrite />}
              />

              {/* 근태관리 탭 */}
              <Route path="workstamp" element={<CompanyWorkstamp />} />
              <Route path="fresh" element={<Fresh />} />
              <Route path="reason" element={<Reason />} />
              <Route path="vacation" element={<CompanyVacation />} />
              <Route path="working" element={<Working />} />
              <Route path="settings" element={<Settings />} />
              <Route path="vacation_rule" element={<CompanyVacationRules />} />
              <Route
                path="vacation_history"
                element={<CompanyVacationHistory />}
              />

              {/* 업무 탭 시작 */}
              <Route path="workorder" element={<WorkOrder />} />
              <Route path="workorder_view/:sn" element={<WorkOrderView />} />
              <Route path="workreport" element={<WorkReport />} />
              <Route path="workreport_view/:sn" element={<WorkReportView />} />
              <Route path="workreport_write" element={<WorkReportWrite />} />
              <Route path="workreport_edit/:sn" element={<WorkReportEdit />} />
              <Route path="business_guide" element={<WorkGuide />} />
              <Route path="business_guide_write" element={<WorkGuideWrite />} />
              <Route
                path="business_guide_view/:sn"
                element={<WorkGuideView />}
              />
              <Route
                path="business_guide_edit/:sn"
                element={<WorkGuideEdit />}
              />
              <Route path="archive" element={<WorkArchive />} />

              {/* 게시판 탭 시작 */}
              <Route path="notice" element={<BoardNotice />} />
              <Route path="notice_view/:sn" element={<BoardNoticeView />} />
              <Route path="notice_write" element={<BoardNoticeWrite />} />
              <Route path="notice_edit/:sn" element={<BoardNoticeEdit />} />
              <Route path="benefits" element={<BoardBenefit />} />
              <Route path="benefits_write" element={<BoardBenefitWrite />} />
              <Route path="benefits_view/:sn" element={<BoardBenefitView />} />
              <Route path="benefits_edit/:sn" element={<BoardBenefitEdit />} />
              <Route path="workreport_view" element={<WorkReportView />} />

              {/* 회원 관리 */}
              <Route path="management" element={<Management />} />
              <Route path="employeeManage" element={<EmployeeManage />} />
              <Route path="postMonitoring" element={<PostMonitoring />} />
              <Route path="chatMonitoring" element={<ChatMonitoring />} />

              {/* 환경 설정 */}
              <Route path="commonCode" element={<CommonCode />} />
              <Route path="menuConfig" element={<MenuConfig />} />
              <Route path="companyRegist" element={<CompanyRegist />} />

              {/* 팝업 관리 */}
              <Route path="popup" element={<Popup />} />
              <Route path="popup_write" element={<PopupWrite />} />
              <Route path="popup_edit/:sn" element={<PopupEdit />} />

              <Route path="" element={<Navigate to="/admin/home" replace />} />
            </Route>
            {/* <Route
              path="/pass/*"
              element={<Navigate to={window.location.pathname} />}
            /> */}
          </>
        )}
        {/* 기업 */}
        {auth.user.accountType === ACCOUNTTYPE.COMPANY && (
          <Route path="company" element={<CompanyLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="payment" element={<Payment />} />
            <Route path="payment_insert" element={<PaymentInsert />} />
            <Route path="payment_edit/:sn" element={<PaymentModify />} />
            <Route path="payment_print/:sn" element={<PaymentPrint />} />

            <Route path="schedule" element={<Schedule />} />
            <Route path="schedule_list" element={<ScheduleList />} />
            <Route path="schedule_docs" element={<ScheduleDocsList />} />

            {/* 사내 문서 탭 */}
            <Route path="innerdoc" element={<Doc />} />

            {/* 전자 결재 탭 */}
            <Route path="elec_insert" element={<ApprovalWrite />} />
            <Route path="elec" element={<Approval state={0} />} />
            <Route path="elecaccept" element={<Approval state={1} />} />
            <Route path="elecdeny" element={<Approval state={2} />} />
            <Route path="eleccomplete" element={<Approval state={3} />} />

            {/* 인사지원 탭 */}
            <Route path="hrm" element={<Hrm />} />
            <Route path="hrm_detail/:sn" element={<HrmDetail />} />
            <Route path="eplydoc" element={<Eplydoc />} />
            <Route path="joindoc" element={<EplydocList />} />
            <Route path="docform" element={<DocumentForm />} />
            <Route
              path="docform_write/:type/:sn"
              element={<DocumentFormWrite />}
            />

            {/* 근태관리 탭 */}
            <Route path="workstamp" element={<CompanyWorkstamp />} />
            <Route path="fresh" element={<Fresh />} />
            <Route path="reason" element={<Reason />} />
            <Route path="vacation" element={<CompanyVacation />} />
            <Route path="settings" element={<Settings />} />
            <Route path="vacation_rule" element={<CompanyVacationRule />} />
            <Route
              path="vacation_history"
              element={<CompanyVacationHistory />}
            />

            {/* 업무 탭 */}
            <Route path="workorder" element={<WorkOrder />} />
            <Route path="workorder_view/:sn" element={<WorkOrderView />} />
            <Route path="workorder_write" element={<WorkOrderWrite />} />
            <Route path="workorder_edit/:sn" element={<WorkOrderEdit />} />
            <Route path="workreport" element={<WorkReport />} />
            <Route path="workreport_view/:sn" element={<WorkReportView />} />
            <Route path="workreport_write" element={<WorkReportWrite />} />
            <Route path="business_guide" element={<WorkGuide />} />
            <Route path="business_guide_write" element={<WorkGuideWrite />} />
            <Route path="business_guide_view" element={<WorkGuideView />} />
            <Route path="business_guide_write" element={<WorkGuideWrite />} />
            <Route path="business_guide_view/:sn" element={<WorkGuideView />} />
            <Route path="business_guide_edit/:sn" element={<WorkGuideEdit />} />
            <Route path="archive" element={<WorkArchive />} />

            {/* 게시판 탭 시작 */}
            <Route path="notice" element={<BoardNotice />} />
            <Route path="notice_view/:sn" element={<BoardNoticeView />} />
            <Route path="notice_write" element={<BoardNoticeWrite />} />
            <Route path="notice_edit/:sn" element={<BoardNoticeEdit />} />
            <Route path="benefits" element={<BoardBenefit />} />
            <Route path="benefits_view/:sn" element={<BoardBenefitView />} />

            {/* 팝업 관리 */}
            <Route path="popup" element={<Popup />} />
            <Route path="popup_write" element={<PopupWrite />} />

            <Route path="" element={<Navigate to="company/home" replace />} />
          </Route>
        )}
        {/* 유저 */}
        {auth.user.accountType === ACCOUNTTYPE.EMPLOYEE && (
          <>
            <Route path="employee" element={<EmployeeLayout />}>
              <Route path="home" element={<Home />} />
              <Route path="payment" element={<Payment />} />
              <Route path="payment_insert" element={<PaymentInsert />} />
              <Route path="payment_print/:sn" element={<PaymentPrint />} />

              <Route path="schedule" element={<Schedule />} />
              <Route path="schedule_list" element={<ScheduleList />} />
              <Route path="schedule_docs" element={<ScheduleDocsList />} />

              {/* 사내 문서 탭 */}
              <Route path="innerdoc" element={<Doc />} />

              {/* 전자 결재 탭 */}
              <Route path="elec_insert" element={<ApprovalWrite />} />
              <Route path="elec_insert/:sn" element={<ApprovalWrite />} />
              <Route path="elec" element={<Approval state={0} />} />
              <Route path="elecaccept" element={<Approval state={1} />} />
              <Route path="elecdeny" element={<Approval state={2} />} />
              <Route path="eleccomplete" element={<Approval state={3} />} />

              {/* 인사지원 탭 */}
              <Route path="hrm" element={<Hrm />} />
              <Route path="hrm_edit/:sn" element={<HrmEdit />} />
              <Route path="hrm_detail/:sn" element={<HrmDetail />} />
              <Route path="hrm_write" element={<HrmWrite />} />
              <Route path="joindoc" element={<EplydocList />} />

              {/* 근태관리 탭 */}
              <Route path="workstamp" element={<EmployeeWorkstamp />} />
              {/* <Route path="workstamp2" element={<CompanyWorkstamp />} /> */}
              <Route path="vacation" element={<EmployeeVacation />} />
              <Route path="fresh" element={<Fresh />} />

              {/* 업무 탭 */}
              <Route path="workorder" element={<WorkOrder />} />
              <Route path="workorder_view/:sn" element={<WorkOrderView />} />

              {/* 업무일지 */}
              <Route path="workreport" element={<WorkReport />} />
              <Route path="workreport_view/:sn" element={<WorkReportView />} />
              <Route
                path="workreport_write/:type"
                element={<WorkReportWrite />}
              />
              <Route path="workreport_edit/:sn" element={<WorkReportEdit />} />
              <Route path="business_guide" element={<WorkGuide />} />
              <Route
                path="business_guide_view/:sn"
                element={<WorkGuideView />}
              />
              <Route path="archive" element={<WorkArchive />} />

              {/* 게시판 탭 시작 */}
              <Route path="notice" element={<BoardNotice />} />
              <Route path="notice_view/:sn" element={<BoardNoticeView />} />
              <Route path="benefits" element={<BoardBenefit />} />
              <Route path="benefits_view/:sn" element={<BoardBenefitView />} />

              <Route
                path=""
                element={<Navigate to="employee/home" replace />}
              />
            </Route>
            {/* <Route
              path="/pass/*"
              element={<Navigate to={window.location.pathname} />}
            /> */}
          </>
        )}
        {/* 전자서명 */}
        {accountType.isCompany() && (
          <Route path="elec" element={<ElecLayout />}>
            <Route path="create_elec" element={<CompanyCreateElec />} />
            <Route path="sign/:type" element={<CompanySignatureElec />} />
          </Route>
        )}
        {accountType.isEmployee() && (
          <Route path="elec" element={<ElecLayout />}>
            <Route path="sign/:type" element={<EmployeeSignatureElec />} />
          </Route>
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={`/${getKeyByValue(
                ACCOUNTTYPE,
                auth.user.accountType
              ).toLowerCase()}/home`}
              replace
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
