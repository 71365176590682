import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import useSearchActions from "../../recoil/api/search";
import { ReactComponent as CheckOn } from "../../../src/img/check-on.svg";
import { ReactComponent as CheckOff } from "../../../src/img/check-off.svg";
import { searchRegex } from "../../utils/regex";

export const SelectOneManager = ({
  open,
  selectedEmployee,
  setSelectedEmployee,
  onChangeModalState,
  COMP_CD = null,
}) => {
  const searchActions = useSearchActions();

  // 전체 employeeList 상태 값
  const [companyEmployee, setCompanyEmployee] = useState([]);

  const [newSelectedEmployee, setNewSelectedEmployee] =
    useState(selectedEmployee);

  // 검색어 상태 값
  const [searchWords, setSearchWords] = useState("");

  // 로딩 상태 값
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      if (COMP_CD) {
        getEmployeeByCOMPCD(COMP_CD);
      } else {
        getEmployeeByCompanyId();
      }
    }

    setNewSelectedEmployee(selectedEmployee);
  }, [open]);

  const getEmployeeByCompanyId = async () => {
    setLoading(true);
    const res = await searchActions.getEmployeeByCompanyId();
    if (res.status === 200) {
      setCompanyEmployee(res.data);
    }
    setLoading(false);
  };

  const getEmployeeByCOMPCD = async (COMP_CD) => {
    setLoading(true);
    const res = await searchActions.getEmployeeByCOMPCD(COMP_CD);
    if (res.status === 200) {
      setCompanyEmployee(() => res.data);
    }
    setLoading(false);
  };

  return (
    <div>
      <div
        className="of-auto"
        style={{ margin: "-20px -30px", marginBottom: 0 }}
      >
        <div id="member-left" className="float-left" style={{ width: "100%" }}>
          <input
            className="gray-input"
            placeholder="멤버검색"
            value={searchWords}
            onChange={(e) => {
              setSearchWords(e.target.value);
            }}
            onInput={(e) => {
              if (searchRegex.test(e.target.value)) {
                e.target.value = e.target.value.replace(searchRegex, "");
              }
            }}
          />
          <div className="inputsearch">
            <button className="person-searchbtn"></button>
          </div>
          <div className="person-scroll scroll-200 text-center">
            {loading && <ClipLoader />}
            {companyEmployee
              ?.filter((employee) =>
                employee?.employee_name?.includes(searchWords)
              )
              ?.map((employee) => {
                return (
                  <div
                    className="person-choice pointer"
                    key={employee?.esn}
                    onClick={() => {
                      setNewSelectedEmployee(employee);
                    }}
                  >
                    <div>
                      <div className="person-circle">
                        <div className="green-circle"></div>
                      </div>
                      <span className="person-name">{`${
                        employee?.employee_department || ""
                      } ${employee?.employee_name}`}</span>
                    </div>
                    {newSelectedEmployee?.esn === employee.esn ? (
                      <CheckOn />
                    ) : (
                      <CheckOff />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div id="modalfoot">
        <div className="btn-group">
          <button
            className="fbtn submitbtn"
            type="button"
            onClick={() => {
              onChangeModalState();
              setSelectedEmployee(newSelectedEmployee);
            }}
          >
            저장
          </button>
          <button
            className="fbtn cancelbtn"
            onClick={() => {
              onChangeModalState();
            }}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};
