import CommonModal from "../../modal/CommonModal";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  disabilityDegreeOptions,
  disabilityTypeOptions,
  subDisabilityTypeOptions,
} from "../../employee/Register";
import { useForm, Controller } from "react-hook-form";
import useManagementActions from "../../../recoil/api/management";
import { authAtom, toastAtom } from "../../../recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { InputText } from "../../form/Input";
import { dateUtils } from "../../../utils";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import ChangePasswordModal from "../../common/home/ChangePasswordModal";
import { ACCOUNTTYPEENUM } from "../../../JSON/enum/account";

const sortStr = (str) => {
  return str.split("").sort().join("");
};

const getWorkingdays = ({ sun, mon, tue, wen, thu, fri, sat }) => {
  let str = "";
  if (sun) str += "0";
  if (mon) str += "1";
  if (tue) str += "2";
  if (wen) str += "3";
  if (thu) str += "4";
  if (fri) str += "5";
  if (sat) str += "6";
  return sortStr(str);
};

const EmployeeDetailModal = ({
  getDetail,
  open,
  setOpen,
  loading,
  employeeDetail,
  setEmployeeDetail,
  readOnly = false,
}) => {
  const managementActions = useManagementActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const [company, setCompany] = useState({});
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [changeCompanyModalOpen, setChangeCompanyModalOpen] = useState(false);
  const [isPwModal, setIsPwModal] = useState(false);
  const auth = useRecoilValue(authAtom);
  const [index, setIndex] = useState(0);
  const getWorkstampList = async (data) => {
    let worklog = { ...employeeDetail?.worklog[index] };
    worklog.contract_sn = worklog.sn;
    let params = {
      COMP_CD: data.COMP_CD,
      contract_sn: worklog.sn,
    };
    const res = await managementActions.changeUserCompanyDetail({ ...params });
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      setOpen(false);
    } else {
      setToast({ ...toast, error: true, message: "변경 실패." });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      esn: employeeDetail?.esn + "",
      disabled_grade: disabilityDegreeOptions.filter(
        (option) => option.value === employeeDetail?.disabled_grade
      )[0],
      disabled_name: disabilityTypeOptions.filter(
        (option) => option.value === employeeDetail?.disabled_name
      )[0],
      sub_disabled_name: subDisabilityTypeOptions.filter(
        (option) => option.value === employeeDetail?.sub_disabled_name
      )[0],
      working_starttime_string: employeeDetail?.working_starttime_string
        ? dateUtils.mmssToTime(employeeDetail?.working_starttime_string)
        : "",
      working_endtime_string: employeeDetail?.working_endtime_string
        ? dateUtils.mmssToTime(employeeDetail?.working_endtime_string)
        : "",

      department:
        employeeDetail?.department || employeeDetail?.employee_department,
      employee_tel: employeeDetail?.employee_tel,
      employee_email: employeeDetail?.employee_email,
      workingdays: employeeDetail?.workingdays,
      days_total:
        Math.round(
          employeeDetail?.days_total * (employeeDetail?.day_standard || 1) * 10
        ) /
          10 +
        "",
      days_used:
        Math.round(
          employeeDetail?.days_used * (employeeDetail?.day_standard || 1) * 10
        ) /
          10 +
        "",
      resttime_memo: employeeDetail?.resttime_memo || 0,
      memo: employeeDetail?.memo,
    },
  });

  const onSubmit = async (data) => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    if (!regex.test(data.employee_email)) {
      alert("이메일 양식이 올바르지 않습니다.");
      return;
    }

    if (employeeDetail?.ishired === 0 && company.COMP_CD) {
      await managementActions.changeEmployeeCompany({
        esn: employeeDetail.esn,
        COMP_CD: company.COMP_CD,
      });
    }

    let params = {
      ...data,
      department: !!data.department ? data.department : " ",
      workingdays: getWorkingdays({ ...data }),
      working_starttime_string: data.working_starttime_string
        .split(":")
        .join(""),
      working_endtime_string: data.working_endtime_string.split(":").join(""),
      sun: null,
      mon: null,
      tue: null,
      wen: null,
      thu: null,
      fri: null,
      sat: null,
      disabled_grade: data.disabled_grade.value,
      disabled_name: data.disabled_name.value,
      sub_disabled_name: data.sub_disabled_name.value,
      memo: !!data.memo ? data.memo : " ",
      days_total:
        Math.round(
          (data.days_total / (employeeDetail.day_standard || 1)) * 10
        ) / 10,
      days_used:
        Math.round((data.days_used / (employeeDetail.day_standard || 1)) * 10) /
        10,
      days_remain:
        Math.round(
          ((data.days_total - data.days_used) /
            (employeeDetail.day_standard || 1)) *
            10
        ) / 10,
      // days_total: parseFloat(data.days_total),
      // days_used: parseInt(data.days_used),
      resttime_memo: parseInt(data.resttime_memo),
    };
    const res = await managementActions.changeUserDetail(params);

    if (res.status === 200) {
      setToast({ ...toast, on: true });
      setOpen(false);
    }
  };

  return (
    <CommonModal
      open={open}
      setOpen={setOpen}
      title="직원 정보 상세보기"
      width="1100px"
    >
      {!loading && employeeDetail && (
        <form onSubmit={handleSubmit(onSubmit)} className="manage-form">
          <div
            className="pri-company table-responsive"
            style={{ paddingTop: 0 }}
          >
            <table id="stafftable">
              <tbody>
                <tr>
                  <th className="text-center-title w-20">아이디</th>
                  <td className="w-30">{employeeDetail?.employee_id}</td>
                  <th className="text-center-title w-20">이름(실명)</th>
                  <td className="w-30">{employeeDetail?.employee_name}</td>
                </tr>
                <tr>
                  <th className="text-center-title">회사명</th>
                  <td>
                    {readOnly === true && employeeDetail?.company_name}
                    {(auth.user.isCompany || employeeDetail.ishired === 1) && (
                      <>
                        {
                          employeeDetail?.worklog[
                            employeeDetail.worklog.length - 1
                          ].companyname
                        }
                      </>
                    )}
                    {auth.user.isAdmin && employeeDetail.ishired === 0 && (
                      <div className="btn-group">
                        <input
                          type="text"
                          style={{
                            width: "60%",
                            border: "1px solid #eee",
                            padding: 8,
                          }}
                          value={
                            company.company_name ||
                            employeeDetail?.companyname ||
                            ""
                          }
                          readOnly
                        />
                        <button
                          className="primarybtn-md"
                          type="button"
                          onClick={() => setIsSearchCompanyModalOpen(true)}
                        >
                          검색
                        </button>
                      </div>
                    )}
                  </td>

                  <th className="text-center-title">직무</th>
                  <td>
                    <InputText
                      errors={errors}
                      refs={{
                        ...register("department", {}),
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">이메일</th>
                  <td>
                    {readOnly === true ? (
                      employeeDetail?.employee_email
                    ) : (
                      <InputText
                        errors={errors}
                        refs={{
                          ...register("employee_email", {
                            required: true,
                          }),
                        }}
                      />
                    )}
                  </td>
                  <th className="text-center-title">휴대폰</th>
                  <td>
                    {readOnly === true ? (
                      employeeDetail?.employee_tel
                    ) : (
                      <InputText
                        type={"number"}
                        errors={errors}
                        refs={{
                          ...register("employee_tel", {
                            required: true,
                          }),
                        }}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">비밀번호</th>
                  <td>
                    <button
                      type="button"
                      className={readOnly ? "blackbtn-sm" : "primarybtn-sm"}
                      onClick={() => {
                        if (!readOnly) {
                          setIsPwModal(true);
                        }
                      }}
                    >
                      {readOnly === true ? "변경 불가" : "변경"}
                    </button>
                  </td>
                  <th className="text-center-title">중/경증</th>
                  <td>
                    <Controller
                      name="disabled_grade"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={employeeDetail?.disabled_grade}
                      render={({ field }) => {
                        if (readOnly) {
                          return employeeDetail?.disabled_grade;
                        }
                        return (
                          <Select
                            {...field}
                            placeholder="선택"
                            options={disabilityDegreeOptions}
                          />
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">장애유형</th>
                  <td>
                    <Controller
                      name="disabled_name"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={employeeDetail?.disabled_name}
                      render={({ field }) => {
                        if (readOnly) {
                          return employeeDetail?.disabled_name;
                        }
                        return (
                          <Select
                            {...field}
                            placeholder="선택"
                            options={disabilityTypeOptions}
                          />
                        );
                      }}
                    />
                  </td>
                  <th className="text-center-title">부장애유형</th>
                  <td>
                    <Controller
                      name="sub_disabled_name"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={employeeDetail?.sub_disabled_name}
                      render={({ field }) => {
                        if (readOnly) {
                          return employeeDetail?.disabled_name;
                        }
                        return (
                          <Select
                            {...field}
                            placeholder="선택"
                            options={subDisabilityTypeOptions}
                          />
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">근무이력</th>
                  <td colSpan={3}>
                    <table id="historytable">
                      <tbody>
                        <tr className="text-center-title">
                          <th>계약 차 수</th>
                          <th>계약 시작일</th>
                          <th>계약만료</th>
                          <th>근무기업</th>
                          <th>총근무일</th>
                          <th>삭제</th>
                          <th>수정</th>
                        </tr>
                        {employeeDetail?.worklog?.map((log, idx) => {
                          return (
                            <tr className="text-center" key={log.sn}>
                              <td className="set_첫계약">{log.contractNum}</td>
                              <td className="set_마지막계약">
                                {log.joindatetime === "Invalid Date"
                                  ? "-"
                                  : log.joindatetime}
                              </td>
                              <td className="set_계약만료">
                                {log.exitdatetime === "Invalid Date"
                                  ? "-"
                                  : log.exitdatetime}
                              </td>
                              <td className="set_근무기업">
                                {log.companyname}
                              </td>
                              <td className="set_총근무일">{log.totalwork}</td>
                              <td>
                                <div className="btn-group">
                                  {/* {employeeDetail?.worklog?.length > 2 && (
                                    <button
                                      className="blackbtn-sm"
                                      type="button"
                                      onClick={async () => {
                                        const res =
                                          await managementActions.deleteWorklog(
                                            {
                                              sn: log.sn,
                                            }
                                          );
                                        if (res.status === 200) {
                                          setEmployeeDetail({
                                            ...employeeDetail,
                                            worklog:
                                              employeeDetail.worklog.filter(
                                                (newLog) => {
                                                  return newLog.sn !== log.sn;
                                                }
                                              ),
                                          });
                                        }
                                      }}
                                    >
                                      삭제
                                    </button>
                                  )} */}
                                </div>
                              </td>
                              <td>
                                {!readOnly && (
                                  <button
                                    className="primarybtn-sm border-line zoom_font"
                                    type="button"
                                    onClick={() => {
                                      setIndex(idx);
                                      setChangeCompanyModalOpen(true);
                                    }}
                                  >
                                    수정
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">출근시간</th>
                  <td>
                    <input
                      {...register("working_starttime_string")}
                      type="time"
                    />
                  </td>
                  <th className="text-center-title">퇴근시간</th>
                  <td>
                    <input
                      {...register("working_endtime_string")}
                      type="time"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">연차일수</th>
                  <td>
                    <InputText
                      type="number"
                      errors={errors}
                      refs={{
                        ...register("days_total", {
                          required: true,
                        }),
                      }}
                      step={0.1}
                    />
                  </td>
                  <th className="text-center-title">휴게시간</th>
                  <td>
                    <InputText
                      type="number"
                      errors={errors}
                      refs={{
                        ...register("resttime_memo", {
                          required: true,
                        }),
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">연차사용일</th>
                  <td>
                    <InputText
                      isReadOnly={true}
                      errors={errors}
                      refs={{
                        ...register("days_used", {
                          required: true,
                        }),
                      }}
                    />
                  </td>
                  <th className="text-center-title">근무요일</th>
                  <td>
                    <label className="container border-line mouse zoom_font">
                      <span>월</span>
                      <input
                        {...register("mon")}
                        type="checkbox"
                        defaultChecked={employeeDetail?.workingdays?.includes(
                          1
                        )}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container border-line mouse zoom_font">
                      <span>화</span>
                      <input
                        {...register("tue")}
                        type="checkbox"
                        defaultChecked={employeeDetail?.workingdays?.includes(
                          2
                        )}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container border-line mouse zoom_font">
                      <span>수</span>
                      <input
                        {...register("wen")}
                        type="checkbox"
                        defaultChecked={employeeDetail?.workingdays?.includes(
                          3
                        )}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container border-line mouse zoom_font">
                      <span>목</span>
                      <input
                        {...register("thu")}
                        type="checkbox"
                        defaultChecked={employeeDetail?.workingdays?.includes(
                          4
                        )}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container border-line mouse zoom_font">
                      <span>금</span>
                      <input
                        {...register("fri")}
                        type="checkbox"
                        defaultChecked={employeeDetail?.workingdays?.includes(
                          5
                        )}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container border-line mouse zoom_font">
                      <span>토</span>
                      <input
                        {...register("sat")}
                        type="checkbox"
                        defaultChecked={employeeDetail?.workingdays?.includes(
                          6
                        )}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="container border-line mouse zoom_font">
                      <span>일</span>
                      <input
                        {...register("sun")}
                        type="checkbox"
                        defaultChecked={employeeDetail?.workingdays?.includes(
                          0
                        )}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">비고</th>
                  <td className="비고" colSpan={3}>
                    <InputText
                      errors={errors}
                      refs={{
                        ...register("memo", {}),
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {(auth?.user?.accountType === ACCOUNTTYPEENUM.COMPANY &&
            employeeDetail.ishired) ||
          !readOnly ? (
            <div className="s-button cf">
              <button className="s-save sc" type="submit">
                수정
              </button>
              <button
                className="s-cans sc"
                type="button"
                onClick={() => setOpen(false)}
              >
                취소
              </button>
            </div>
          ) : (
            <div className="s-button cf">
              <button
                className="s-cans sc"
                type="button"
                onClick={() => {
                  setOpen(false);
                  setEmployeeDetail({});
                }}
              >
                목록
              </button>
            </div>
          )}
        </form>
      )}

      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />

      <SearchCompanyModal
        open={changeCompanyModalOpen}
        setIsSearchCompanyModalOpen={setChangeCompanyModalOpen}
        onChange={(company) => {
          getWorkstampList(company);
        }}
      />

      <ChangePasswordModal
        userId={employeeDetail?.employee_id}
        user={false}
        open={isPwModal}
        setOpen={setIsPwModal}
      />
    </CommonModal>
  );
};

export default EmployeeDetailModal;
