import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { useRecoilState, useRecoilValue } from "recoil";
import { APPLICATIONSTATE, HRMTYPE } from "../../../JSON/enum/Dataenum";
import { authAtom, toastAtom } from "../../../recoil";
import useHrmActions from "../../../recoil/api/hrm";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../BreadCrumb";
import DataTable from "../DataTable";
import PaginationBar from "../PaginationBar";
import SearchByDate from "../SearchByDate";
import HiredHrm from "./HiredHrm";
import { useReactToPrint } from "react-to-print";
import CareerHrm from "./CareerHrm";
import { toast as reactToast } from "react-toastify";
import { overMaxLength } from "../../../utils";
import dayjs from "dayjs";
import HrmFunction from "./HrmFunction";

// const typeOptions = [
//   { label: "전체증명서", value: "" },
//   { label: "재직증명서", value: HRMTYPE.HIRED + "" },
//   { label: "경력증명서", value: HRMTYPE.CAREER + "" },
// ];
const stateOptions = [
  { label: "전체", value: "" },
  { label: "대기", value: APPLICATIONSTATE.NONE + "" },
  { label: "완료", value: APPLICATIONSTATE.APPROVE + "" },
  { label: "반려", value: APPLICATIONSTATE.REJECT + "" },
];
// export const getHrmType = (hrm) => {
//   switch (hrm) {
//     case HRMTYPE.HIRED:
//       return "재직증명서";
//     case HRMTYPE.CAREER:
//       return "경력증명서";
//     default:
//       return "오류";
//   }
// };

export const getApproveType = (type) => {
  switch (type) {
    case APPLICATIONSTATE.NONE:
      return (
        <button className="secondarybtn-sm border-line zoom_font">
          결재대기
        </button>
      );
    case APPLICATIONSTATE.APPROVE:
      return (
        <button className="primarybtn-sm border-line zoom_font">
          결재완료
        </button>
      );
    case APPLICATIONSTATE.REJECT:
      return (
        <button className="pinkbtn-sm border-line zoom_font">결재반려</button>
      );
    default:
      return null;
  }
};

const Hrm = () => {
  const locationObj = useLocation();
  const navigate = useNavigate();

  const [date, setDate] = useState(DateState);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [hrmList, setHrmList] = useState([]);
  const auth = useRecoilValue(authAtom);
  const hrmActions = useHrmActions();
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [type, setType] = useState(null);
  const [state, setState] = useState(null);
  const componentRef = useRef(null);
  const [printHrm, setPrintHrm] = useState(null);
  const [stamp, setStamp] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [timer, setTimer] = useState(0);

  const hrmFn = HrmFunction();

  // 체크 박스 핸들러
  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const toastTest = () => {
    reactToast.promise(handleOnBeforeGetContent, {
      pending: "인쇄 준비 중...",
      error: "실패했습니다.",
    });
  };

  //debouncer api 요청이 연속으로 들어오면 마지막 api만 처리함
  const debouncer = (api) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      api();
    }, 100);

    setTimer(newTimer);
  };

  useEffect(() => {
    setLoading(true);
    if (printHrm) {
      hrmActions
        .getCompanyStamp({
          COMP_CD: printHrm.COMP_CD,
        })
        .then((res) => {
          if (res.status === 200 && res.data) {
            setStamp(() => res.data);
            setTimeout(() => {
              handleSubmit();
            }, 500);
          } else if (res.data === "") {
            setStamp(() => res.data);
            setTimeout(() => {
              handleSubmit();
            }, 500);
            return alert("등록된 도장이 없습니다.");
          }
        });
    }
    setLoading(false);
  }, [printHrm]);

  const handleSubmit = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: toastTest,
  });

  useEffect(() => {
    if (locationObj.state?.page) {
      setPageInfo((old) => {
        return {
          ...old,
          current: locationObj.state?.page,
        };
      });
    }
  }, [locationObj.state?.page]);

  useEffect(() => {
    debouncer(getHrm);
  }, [date, state, type, pageInfo.current]);

  const getHrm = async () => {
    setLoading(true);
    const res = await hrmActions.getHrm({ date, pageInfo, state, type });
    if (res.status === 200) {
      setHrmList(res.data.list);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    if (
      window.confirm(
        `선택된 ${checkedItemIds.length}건의 증명서가 삭제됩니다. 이후 복구는 불가능합니다. 정말 삭제하시겠습니까?`
      )
    ) {
      const res = await hrmActions.deleteHrmList(checkedItemIds);
      if (res) {
        getHrm();
        setToast({ ...toast, on: true });
      }
    }
  };
  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div className="btn-group btn-group-left">
            {auth.user.isEmployee && (
              <Link to="/employee/hrm_write">
                <button className="primarybtn-md border-line zoom_font">
                  작성하기
                </button>
              </Link>
            )}
            <div>
              <button
                className="trashbtn border-line mouse"
                type="button"
                onClick={() => {
                  onDelete();
                }}
              ></button>
            </div>
          </div>
        </div>
        <SearchByDate
          dateAndKeyword={date}
          onChange={(date) => setDate(date)}
          placeholder={"용도 또는 이름 입력"}
          select={
            <div className="btn-group btn-group-left select-border-line">
              <Select
                options={hrmFn.typeOptions()}
                placeholder="증명서 종류"
                onChange={(e) => setType(e.value)}
              />
              <Select
                options={stateOptions}
                placeholder="전체"
                onChange={(e) => setState(e.value)}
              />
            </div>
          }
          loading={loading}
        />
      </div>
      <DataTable
        loading={loading}
        loadedData={hrmList}
        setCheckedItemIds={setCheckedItemIds}
        columns={[
          "증명서 종류",
          "용도",
          "신청자",
          "신청일",
          "결재여부",
          "출력",
        ]}
      >
        {hrmList?.map((hrm, i) => {
          return (
            <tr
              className="pointer"
              key={i}
              onClick={() =>
                navigate(`/${auth.accountType}/hrm_detail/${hrm.sn}`, {
                  state: { page: pageInfo?.current },
                })
              }
            >
              <td className="hide">
                <div className="agree" onClick={(e) => e.stopPropagation()}>
                  <label>
                    <input
                      type="checkbox"
                      name="personinfo"
                      onChange={(e) => {
                        onChangeCheckBoxHandler(
                          e.currentTarget.checked,
                          hrm.sn
                        );
                      }}
                      checked={checkedItemIds?.includes(hrm.sn) ? true : false}
                    />
                    {checkedItemIds?.includes(hrm.sn) ? (
                      <div className="chkbox border-line mouse checked"></div>
                    ) : (
                      <div className="chkbox border-line mouse "></div>
                    )}
                  </label>
                </div>
              </td>
              <td className="border-line zoom_font">
                {hrmFn.getHrmType(hrm.type)}
              </td>
              <td className="border-line zoom_font" style={{ width: "250px" }}>
                {overMaxLength(hrm.purpose, 20)}
              </td>
              <td className="border-line zoom_font">{hrm.id}</td>
              <td className="border-line zoom_font">{hrm.createtime}</td>
              <td>{getApproveType(hrm.state)}</td>
              <td
                style={{ width: "100px" }}
                onClick={(e) => e.stopPropagation()}
              >
                {hrm.state === 1 && (
                  <button
                    className="secondarybtn-sm border-line zoom_font"
                    onClick={async () => {
                      setLoading(true);
                      const res = await hrmActions.getHrmDetail({ sn: hrm.sn });
                      if (res.status === 200) {
                        setPrintHrm(res.data);
                      } else {
                        setPrintHrm(null);
                      }
                      setLoading(false);
                    }}
                  >
                    인쇄하기
                  </button>
                )}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {printHrm && printHrm.type === HRMTYPE.HIRED && (
            <HiredHrm
              hrm={printHrm}
              date={dayjs().format("YYYY-MM-DD")}
              stamp={stamp}
            />
          )}
          {printHrm && printHrm.type === HRMTYPE.CAREER && (
            <CareerHrm hrm={printHrm} stamp={stamp} />
          )}
        </div>
      </div>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default Hrm;
