import LoginBg from "../../img/login-bg.png";
import Logo from "../../img/logo.png";
import UserImage from "../../ico/icon_개인회원.svg";
import CompanyImage from "../../ico/icon_기업회원.svg";
import { Link } from "react-router-dom";

const RegisterSelect = () => {
  return (
    <div className="con-login" style={{ height: 810 }}>
      <img className="loimage" src={LoginBg} alt="배경" />
      <div className="flex-log login-l">
        <div className="l-cont">
          <a>
            <img src={Logo} alt="로고" />
          </a>
          <p>재택근무 인사관리 플랫폼</p>
          <span>(주)브이드림</span>
        </div>
      </div>
      <div className="flex-log login-r">
        <div className="r-cont">
          <div className="setit">
            <h3>가입 유형을 선택해 주세요.</h3>
            <p>
              회원종류에 따라 회원가입 절차가 다릅니다.
              <br />
              해당 유형을 선택하여 회원가입을 진행해 주시기 바랍니다.
            </p>
          </div>
          <div className="selectwo cf" style={{ overflow: "auto" }}>
            <div className="person">
              <span>
                <img src={UserImage} alt="회원가입" />
              </span>
              <strong>개인 회원</strong>
              <div className="setit">
                <p>
                  개인회원 가입 시
                  <br />
                  소속 회사명을 확인해 주세요.
                </p>
              </div>
              <div className="s-button s-select cf">
                <Link to="/user/register" className="join sc">
                  가입하기
                </Link>
              </div>
            </div>
            <div className="company">
              <span>
                <img src={CompanyImage} alt="회원가입" />
              </span>
              <strong>기업 회원</strong>
              <div className="setit">
                <p>
                  기업관리자 가입 시
                  <br />
                  사업자등록번호가 필요합니다.
                </p>
              </div>
              <div className="s-button s-select cf">
                <Link to="/company/register" className="join sc">
                  가입하기
                </Link>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Link to="/login">
              <span className="primarybtn-sm">뒤로</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSelect;
