import { useEffect, useState } from "react";
import { PageInfoState } from "../../../recoil/types/data";
import { MAIL_VIEW } from "./MailLayout";
import MailList from "./MailList";
import { useMailActions } from "../../../recoil/api/mail";

const SentMail = ({ prevInfo, setPrevInfo }) => {
  const [pageInfo, setPageInfo] = useState(
    prevInfo[1] !== null ? prevInfo[1] : PageInfoState
  );
  const [keyword, setKeyword] = useState(
    prevInfo[0]?.length > 0 ? prevInfo[0] : ""
  );
  const [getMail, setGetMail] = useState();
  const [loading, setLoading] = useState(true);
  const [effectState, setEffectState] = useState(0);
  const mailActions = useMailActions();

  const getSendMailList = async () => {
    setLoading(true);

    const res = await mailActions.getSendMailList({ keyword, pageInfo });
    setGetMail(res);
    setPageInfo(res.pageinfo);
    setPrevInfo([keyword, res.pageinfo]);

    setLoading(false);
  };

  useEffect(() => {
    getSendMailList();
  }, [keyword, pageInfo.current, effectState]);

  return (
    <MailList
      setKeyword={setKeyword}
      pageInfo={pageInfo}
      setPageInfo={setPageInfo}
      view={MAIL_VIEW.SENT_MAIL_DETAIL}
      list={getMail?.list}
      recive={false}
      loading={loading}
      prevInfo={prevInfo}
      setPrevInfo={setPrevInfo}
      setEffectState={setEffectState}
    />
  );
};

export default SentMail;
