import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { JOINDOCTYPE, JOINDOCUMNET } from "../../../JSON/enum/Dataenum";
import { authAtom, ElecEsnAtom, ElecURLAtom, toastAtom } from "../../../recoil";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import { useElecActions } from "../../../recoil/api/elec";
import { dateUtils, downloadFile } from "../../../utils";
import CommonModal from "../../modal/CommonModal";
import BreadCrumb from "../BreadCrumb";
import DataTable from "../DataTable";
import DocIco from "../../../img/doc/monitor.png";
import dayjs from "dayjs";

export const getJoinDocType = (joinDoc, check) => {
  switch (joinDoc) {
    case JOINDOCUMNET.NONE:
      return "미등록";
    case JOINDOCUMNET.APPLICATIONDONE:
      if (!check) {
        return "등록";
      } else {
        return "서명 전";
      }
    case JOINDOCUMNET.PRESIGN:
      return "서명 전";
    case JOINDOCUMNET.SIGN:
      return "서명완료";
    default:
      return "오류";
  }
};

const getJoinDocManageType = (status, type, auth) => {
  if (auth.user.isAdmin) {
    return JOINDOCUMNET.SIGN;
  }
  if (
    type === JOINDOCTYPE.idcerti ||
    type === JOINDOCTYPE.degree ||
    type === JOINDOCTYPE.account ||
    type === JOINDOCTYPE.disable ||
    type === JOINDOCTYPE.health ||
    type === JOINDOCTYPE.voucher ||
    type === JOINDOCTYPE.additional
  ) {
    if (status === JOINDOCUMNET.NONE) {
      return JOINDOCUMNET.NONE;
    } else {
      return "UPLOADED";
    }
  } else {
    //전자서명 동의서, 재택근무 보안서약서
    if (type === JOINDOCTYPE.digital_sign || type === JOINDOCTYPE.remotework) {
      if (status === JOINDOCUMNET.NONE) {
        return JOINDOCUMNET.PRESIGN;
      } else if (status === JOINDOCUMNET.APPLICATIONDONE) {
        return JOINDOCUMNET.APPLICATIONDONE;
      }
    }

    //개인정보동의서, 비밀유지서약서
    if (
      type === JOINDOCTYPE.personalagree ||
      type === JOINDOCTYPE.secretagree
    ) {
      if (status === JOINDOCUMNET.NONE) {
        return JOINDOCUMNET.PRESIGN;
      }
    }
    if (status === JOINDOCUMNET.NONE) {
      return JOINDOCUMNET.NONE;
    } else if (status === JOINDOCUMNET.APPLICATIONDONE) {
      return JOINDOCUMNET.APPLICATIONDONE;
    } else if (status === JOINDOCUMNET.PRESIGN) {
      return JOINDOCUMNET.PRESIGN;
    } else if (status === JOINDOCUMNET.SIGN) {
      return JOINDOCUMNET.SIGN;
    }
  }
};

const ListTr = ({
  title,
  status,
  time,
  type,
  setDocType,
  esn,
  COMP_CD,
  setIsUploadDocModalOpen = () => {},
  setIsBrowseFilesModalOpen,
  setBrowsingFilesList,
  firstCheck = false,
}) => {
  const { state } = useLocation();
  const elecActions = useElecActions();
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const accoutType = useAccoutTypeActions();
  const setElecURL = useSetRecoilState(ElecURLAtom);
  const setEsn = useSetRecoilState(ElecEsnAtom);

  const getDocContractByCompanyId = async () => {
    let params = {};
    if (accoutType.isCompany() || accoutType.isAdmin()) {
      params.esn = esn;
    } else if (accoutType.isEmployee()) {
      params.id = auth.user.id;
    }
    const res = await elecActions.getDocContractByCompanyId(params);
    if (res.status === 200) {
      setElecURL(res.data[0].url);
      navigate("/elec/create_elec");
    }
  };

  const getDocByType = async () => {
    setBrowsingFilesList([]);
    let params = {
      type,
      ...(auth.user.isCompany || auth.user.isAdmin ? { esn, COMP_CD } : {}),
      ...(auth.user.isEmployee ? { id: auth.user.id } : {}),
    };
    if (accoutType.isCompany() || accoutType.isAdmin()) {
      params.esn = state.esn;
      params.COMP_CD = COMP_CD;
    }
    if (accoutType.isEmployee()) {
      params.id = auth.user.id;
    }

    const res = await elecActions.getDocDocByType(params);
    if (res?.data) {
      setBrowsingFilesList(res.data);
      return res.data[res.data.length - 1];
    }
  };

  //company인데 state가 없으면 돌아가기
  useEffect(() => {
    if ((!state || !state.esn) && accoutType.isCompany()) {
      navigate("/company/eplydoc");
    } else if ((!state || !state.esn) && accoutType.isAdmin()) {
      navigate("/admin/eplydoc");
    }

    if (state?.esn) {
      setEsn(state.esn);
    }
  }, []);
  return (
    <>
      <tr>
        <td className="border-line zoom_font">{title}</td>
        <td>
          <button
            className={`border-line zoom_font ${
              status === JOINDOCUMNET.NONE ||
              status === JOINDOCUMNET.PRESIGN ||
              (status === JOINDOCUMNET.APPLICATIONDONE && firstCheck)
                ? "blackbtn-sm"
                : "primarybtn-sm"
            }`}
          >
            {getJoinDocType(status, firstCheck)}
          </button>
        </td>
        <td className="border-line zoom_font">
          {getJoinDocManageType(status, type, auth) === JOINDOCUMNET.SIGN ||
          (status === JOINDOCUMNET.APPLICATIONDONE && !firstCheck) ? (
            <button
              className="t-download"
              onClick={async () => {
                const res = await getDocByType();
                downloadFile(res);
              }}
            />
          ) : (
            <>-</>
          )}
        </td>
        <td className="border-line zoom_font">
          {status && time !== "Invalid Date" ? dateUtils.dateToYMD(time) : "-"}
        </td>
        <td>
          <div className="btn-group">
            {getJoinDocManageType(status, type, auth) === JOINDOCUMNET.NONE && (
              <>
                {!(
                  (accoutType.isCompany() &&
                    type !== JOINDOCTYPE.work_contract) ||
                  (accoutType.isEmployee() &&
                    type === JOINDOCTYPE.work_contract) ||
                  accoutType.isAdmin()
                ) && (
                  <button
                    className="primarybtn-sm border-line zoom_font"
                    onClick={() => {
                      setDocType(type);
                      setIsUploadDocModalOpen(true);
                    }}
                    disabled={
                      (accoutType.isCompany() &&
                        type !== JOINDOCTYPE.work_contract) ||
                      (accoutType.isEmployee() &&
                        type === JOINDOCTYPE.work_contract) ||
                      accoutType.isAdmin()
                    }
                  >
                    등록
                  </button>
                )}
              </>
            )}
            {getJoinDocManageType(status, type, auth) ===
              JOINDOCUMNET.APPLICATIONDONE && (
              <>
                {auth.user.isCompany && (
                  <button
                    className="bluebtn-sm border-line zoom_font"
                    onClick={() => {
                      if (type === JOINDOCTYPE.work_contract) {
                        getDocContractByCompanyId();
                      } else {
                        navigate(`/elec/sign/${type}`, {
                          state: { contract_sn: esn },
                        });
                      }
                    }}
                    disabled={accoutType.isEmployee()}
                  >
                    서명
                  </button>
                )}
              </>
            )}
            {getJoinDocManageType(status, type, auth) ===
              JOINDOCUMNET.PRESIGN &&
              auth.user.isEmployee && (
                <Link to={`/elec/sign/${type}`} state={{ contract_sn: esn }}>
                  <button
                    className="bluebtn-sm border-line zoom_font"
                    disabled={accoutType.isCompany()}
                  >
                    서명
                  </button>
                </Link>
              )}
            {getJoinDocManageType(status, type, auth) === JOINDOCUMNET.SIGN && (
              <button
                className="primarybtn-sm border-line zoom_font"
                onClick={async () => {
                  await getDocByType();
                  setIsBrowseFilesModalOpen(true);
                }}
              >
                열람
              </button>
            )}

            {getJoinDocManageType(status, type, auth) === "UPLOADED" && (
              <div className="btn-group">
                {auth.user.isEmployee && (
                  <button
                    className="primarybtn-sm border-line zoom_font"
                    onClick={() => {
                      setDocType(type);
                      setIsUploadDocModalOpen(true);
                    }}
                    disabled={
                      (accoutType.isCompany() &&
                        type !== JOINDOCTYPE.work_contract) ||
                      (accoutType.isEmployee() &&
                        type === JOINDOCTYPE.work_contract) ||
                      accoutType.isAdmin()
                    }
                  >
                    등록
                  </button>
                )}
                <button
                  className="blackbtn-sm"
                  onClick={async () => {
                    await getDocByType();
                    setIsBrowseFilesModalOpen(true);
                  }}
                >
                  열람
                </button>
              </div>
            )}
          </div>
        </td>
        {!auth.user.isEmployee && (
          <td>
            {(type === JOINDOCTYPE.work_contract ||
              type === JOINDOCTYPE.personalagree ||
              type === JOINDOCTYPE.secretagree) && (
              <button
                className="pinkbtn-sm border-line zoom_font"
                onClick={async () => {
                  if (
                    window.confirm(
                      "기존에 작성해둔 문서가 삭제됩니다.\n초기화 하시겠습니까?"
                    )
                  ) {
                    elecActions
                      .delDocByType({
                        type: type,
                        contract_sn: esn,
                        COMP_CD: state?.COMP_CD,
                      })
                      .then(() => {
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  } else {
                    return;
                  }
                }}
              >
                초기화
              </button>
            )}
          </td>
        )}
      </tr>
    </>
  );
};

const EplydocList = () => {
  const elecActions = useElecActions();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [docList, setDocList] = useState([]);
  const [isUploadDocModalOpen, setIsUploadDocModalOpen] = useState(false);
  const { register, handleSubmit, setValue, watch } = useForm();
  const [docType, setDocType] = useState(null);

  // 계정 상태 값
  const accoutType = useAccoutTypeActions();
  const auth = useRecoilValue(authAtom);

  // 로딩 상태 값
  const [loading, setLoading] = useState(false);

  // 열람 파일 상태 값
  const [browsingFilesList, setBrowsingFilesList] = useState([]);

  // 열람 모달 상태 값
  const [isBrowseFilesModalOpen, setIsBrowseFilesModalOpen] = useState(false);
  const onChangeBrowseFilesModalState = () => {
    setIsBrowseFilesModalOpen(false);
  };

  const [toast, setToast] = useRecoilState(toastAtom);

  useEffect(() => {
    getDocList();
  }, [state?.COMP_CD, state?.esn, state?.id]);

  const getDocList = async () => {
    setLoading(true);
    const res = await elecActions.getDocList({
      COMP_CD: state?.COMP_CD || auth.user.COMP_CD,
      esn: state?.esn || auth.user.esn,
      id: state?.id || auth.user.id,
    });

    if (res.status === 200) {
      setDocList(res.data);
    }
    setLoading(false);
  };

  const onSubmit = async (e) => {
    setLoading(true);
    const fileTypes = [
      "image/png",
      "image/gif",
      "image/jpeg",
      "application/pdf",
    ];
    const fileTypesWorkContract = ["application/pdf"];

    if (!e.file?.length) {
      setToast({
        ...toast,
        error: true,
        message: "파일을 업로드 해 주세요!",
      });
      setLoading(false);
      return;
    }
    if (e.file[0].size > 5 * 1024 * 1024) {
      setToast({
        ...toast,
        error: true,
        message: "파일 용량은 5MB를 초과할 수 없습니다.",
      });
      setLoading(false);
      return;
    }
    if (!fileTypes.includes(e.file[0].type)) {
      setToast({
        ...toast,
        error: true,
        message: "등록 할 수 없는 파일 입니다.",
      });
      setLoading(false);
      return;
    }
    if (
      !fileTypesWorkContract.includes(e.file[0].type) &&
      docType === JOINDOCTYPE.work_contract
    ) {
      setToast({
        ...toast,
        error: true,
        message: "근로계약서는 pdf파일만 올릴 수 있습니다.",
      });
      setLoading(false);
      return;
    }

    //기업이 근로계약서 업로드 할 때
    if (accoutType.isCompany() && docType === JOINDOCTYPE.work_contract) {
      const res = await elecActions.uploadDocFile({
        esn: state?.esn,
        file: e.file,
      });

      if (res.status === 200) {
        getDocList();
      }
    } else {
      //근로자가 문서 업로드 할 때
      const res = await elecActions.uploadEmployeeDocFile({
        type: docType,
        file: e.file,
      });

      if (res.status === 200) {
        getDocList();
      }
    }
    setValue("file", null);
    setIsUploadDocModalOpen(false);
    setLoading(false);
  };

  return (
    <>
      <BreadCrumb />
      <DataTable
        loading={loading}
        checkbox={false}
        columns={
          !auth.user.isEmployee
            ? ["입사서류", "상태", "다운로드", "등록일", "관리", "초기화"]
            : ["입사서류", "상태", "다운로드", "등록일", "관리"]
        }
      >
        <ListTr
          title={auth?.commonCode[5]["근로계약서"]}
          status={docList?.work_contract}
          time={docList?.work_contract_time}
          type={JOINDOCTYPE.work_contract}
          esn={docList?.contract_sn}
          setDocType={setDocType}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          firstCheck={true}
        />
        <ListTr
          title={auth?.commonCode[6]["전자서명 동의서"]}
          status={docList?.digital_sign}
          time={docList?.digital_sign_time}
          type={JOINDOCTYPE.digital_sign}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[7]["재택근무 보안서약서"]}
          status={docList?.remotework}
          time={docList?.remotework_time}
          type={JOINDOCTYPE.remotework}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[8]["개인정보동의서"]}
          status={docList?.personalagree}
          time={docList?.personalagree_time}
          type={JOINDOCTYPE.personalagree}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
          firstCheck={true}
        />
        <ListTr
          title={auth?.commonCode[9]["비밀유지 및 겸업 금지 서약서"]}
          status={docList?.secretagree}
          time={docList?.secretagree_time}
          type={JOINDOCTYPE.secretagree}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
          firstCheck={true}
        />
        <ListTr
          title={auth?.commonCode[10]["주민등록등본"]}
          status={docList?.idcerti}
          time={docList?.idcerti_time}
          type={JOINDOCTYPE.idcerti}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          setDocType={setDocType}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[11]["최종학교졸업증명서"]}
          status={docList?.degree}
          time={docList?.degree_time}
          type={JOINDOCTYPE.degree}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          setDocType={setDocType}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[12]["통장사본"]}
          status={docList?.account}
          time={docList?.account_time}
          type={JOINDOCTYPE.account}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          setDocType={setDocType}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[13]["중증장애인확인서"]}
          status={docList?.disable}
          time={docList?.disable_time}
          type={JOINDOCTYPE.disable}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          setDocType={setDocType}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[14]["건강보험득실확인서"]}
          status={docList?.health}
          time={docList?.health_time}
          type={JOINDOCTYPE.health}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          setDocType={setDocType}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[15]["복지카드"]}
          status={docList?.voucher}
          time={docList?.voucher_time}
          type={JOINDOCTYPE.voucher}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          setDocType={setDocType}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
        <ListTr
          title={auth?.commonCode[16]["추가요청서류"]}
          status={docList?.additional}
          time={docList?.additional_time}
          type={JOINDOCTYPE.additional}
          setIsUploadDocModalOpen={setIsUploadDocModalOpen}
          setDocType={setDocType}
          esn={docList?.contract_sn}
          COMP_CD={state?.COMP_CD}
          isBrowseFilesModalOpen={isBrowseFilesModalOpen}
          setIsBrowseFilesModalOpen={setIsBrowseFilesModalOpen}
          browsingFilesList={browsingFilesList}
          setBrowsingFilesList={setBrowsingFilesList}
        />
      </DataTable>
      <CommonModal
        title="서류 등록"
        open={isUploadDocModalOpen}
        setOpen={setIsUploadDocModalOpen}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tabbtnbox">
            <button className="tabbtn tactive" type="button">
              내컴퓨터
            </button>
            {docType === JOINDOCTYPE.work_contract && (
              <button
                className="tabbtn"
                type="button"
                onClick={async () => {
                  if (
                    window.confirm("근로계약서 샘플을 업로드 하시겠습니까?")
                  ) {
                    navigate("/elec/sign/1", {
                      state: {
                        sample: true,
                        contract_sn: docList?.contract_sn,
                      },
                    });
                  }
                }}
              >
                계약서 샘플
              </button>
            )}
          </div>
          <div className="tabcontbox">
            <div className="tabcont" style={{ textAlign: "center" }}>
              {watch("file")?.length === 0 && (
                <div>
                  <img src={DocIco} />
                  <p style={{ margin: "15px 0" }}>
                    <span>내 컴퓨터 에서</span>
                    <br />
                    업로드 할 문서를 선택해주세요.
                  </p>
                </div>
              )}

              <span className="filetype">
                <span className="file-select">
                  <input type="file" className="input-file" size="15" />
                </span>
                <input
                  {...register("file")}
                  type="file"
                  id="uploadBtn"
                  accept="image/png, image/gif, image/jpeg, application/pdf"
                  style={{ display: "none" }}
                />
                <input
                  type="text"
                  className="custom-input w-100"
                  disabled
                  value={watch("file") ? watch("file")[0]?.name || "" : ""}
                />
                <label className="mt-3" htmlFor="uploadBtn" id="docfile-btn">
                  파일 찾기
                </label>
              </span>
              <div className="file-guide">
                <ul>
                  <li>
                    {docType === JOINDOCTYPE.work_contract
                      ? "지원파일 : PDF"
                      : "지원파일 : PDF, JPG, JPEG, PNG 등"}
                  </li>
                  <li>업로드 가능 최대 용량 : 5MB</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="uploadbtn">
            <button disabled={loading}>업로드</button>
          </div>
        </form>
      </CommonModal>
      {/* 열람 모달 */}
      <CommonModal
        title="상세보기"
        open={isBrowseFilesModalOpen}
        onChangeModalState={onChangeBrowseFilesModalState}
      >
        <DataTable
          checkbox={false}
          columns={["입사서류", "서명(등록)일자", "관리"]}
          isResponsive={false}
        >
          {browsingFilesList?.map((data) => {
            return (
              <>
                <tr key={data?.sn} className="text-center">
                  <td className="border-line mouse zoom_font">
                    <div>{data?.filename}</div>
                  </td>
                  <td className="border-line mouse zoom_font">
                    <div>{dayjs(data?.createtime).format("YYYY-MM-DD")}</div>
                  </td>
                  <td className="border-line mouse zoom_font">
                    <button
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      className="primarybtn-sm"
                      onClick={async () => {
                        window.open(data?.url);
                      }}
                    >
                      열람
                    </button>
                    {(auth.user.isAdmin || auth.user.isCompany) && (
                      <button
                        style={{ cursor: "pointer" }}
                        className="pinkbtn-sm"
                        onClick={async () => {
                          if (window.confirm("삭제하시겠습니까?")) {
                            setLoading(true);
                            elecActions
                              .delDocByType({
                                type: data?.type,
                                contract_sn: docList?.contract_sn,
                                COMP_CD: state?.COMP_CD,
                              })
                              .then(() => {
                                setLoading(false);
                                setToast({
                                  ...toast,
                                  on: true,
                                  message: `${data?.filename} 삭제 되었습니다.`,
                                });
                                window.location.reload();
                              });
                          }
                        }}
                      >
                        삭제
                      </button>
                    )}
                  </td>
                </tr>
              </>
            );
          })}
        </DataTable>
      </CommonModal>
    </>
  );
};

export default EplydocList;
