import { getXlsxDownloadHref } from "../../utils";
import { toast } from "react-toastify";

const WorkReportDownload = ({ api, params = {}, name }) => {
  return (
    <>
      <button
        className="primarybtn-md border-line mouse zoom_font"
        type="button"
        onClick={async () => {
          const toastId = toast.loading("업무일지 다운로드 중... ");
          return await getXlsxDownloadHref({
            api: api,
            params: {
              ...params,
              count: 10000,
              page: 1,
              isxlsx: 1,
            },
            name: name,
          })
            .then(() => {
              toast.update(toastId, {
                render: "업무일지 다운로드가 완료되었습니다.",
                type: "success",
                isLoading: false,
                autoClose: 2000,
              });
            })
            .catch((err) => {
              if (err.response.status === 404) {
                toast.update(toastId, {
                  render: "선택된 업무일지가 없습니다.",
                  type: "warning",
                  isLoading: false,
                  autoClose: 2000,
                });
              } else {
                toast.update(toastId, {
                  render: "다운로드에 실패했습니다. 다시 시도해주세요.",
                  type: "error",
                  isLoading: false,
                  autoClose: 2000,
                });
              }
            });
        }}
      >
        업무일지 다운로드
      </button>
    </>
  );
};

export default WorkReportDownload;
