import CommonModal from "../../modal/CommonModal";

import React, { useState, useEffect } from "react";
import { authAtom, DocListUpdateAtom, toastAtom } from "../../../recoil";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

import PdfLogo from "../../../img/doc/icon_file_PDF.svg";
import GifLogo from "../../../img/doc/icon_file_GIF.svg";
import JpgLogo from "../../../img/doc/icon_file_JPG.svg";
import PngLogo from "../../../img/doc/icon_file_PNG.svg";
import PptLogo from "../../../img/doc/icon_file_PPT.svg";
import TxtLogo from "../../../img/doc/icon_file_TXT.svg";
import XmlLogo from "../../../img/doc/icon_file_XML.svg";
import DocLogo from "../../../img/doc/icon_file_DOC.svg";
import XlsxLogo from "../../../img/doc/icon_file_XLSX.svg";
import EtcLogo from "../../../img/doc/icon_file_ETC.svg";
import NoFiles from "../../../img/doc/icon_noFiles.svg";

import Edit from "../../../img/doc/doc_edit.png";
import Delete from "../../../img/doc/doc_delete.png";

import BreadCrumb from "../BreadCrumb";
import Select from "react-select";
import PaginationBar from "../PaginationBar";
import { PageInfoState } from "../../../recoil/types/data";
import { DocListQuery } from "../../../recoil";
import { Controller, useForm } from "react-hook-form";
import { INNERDOCTYPE } from "../../../JSON/enum/Dataenum";
import { useDocActions } from "../../../recoil/api/doc";
import SearchKeyword from "../SearchKeyword";
import { downloadFile, overMaxLength } from "../../../utils";
import { downloadFileSetting } from "../../../utils";

const getDocExtensionImage = (extension) => {
  switch (extension) {
    case "pdf":
      return PdfLogo;
    case "gif":
      return GifLogo;
    case "jpg":
      return JpgLogo;
    case "jpeg":
      return JpgLogo;
    case "png":
      return PngLogo;
    case "ppt":
      return PptLogo;
    case "txt":
      return TxtLogo;
    case "xml":
      return XmlLogo;
    case "doc":
      return DocLogo;
    case "xlsx":
      return XlsxLogo;
    default:
      return EtcLogo;
  }
};

const docTypeOptions = [
  { value: INNERDOCTYPE.RULES, label: "회사규칙" },
  { value: INNERDOCTYPE.BYLAWS, label: "회사내규" },
  { value: INNERDOCTYPE.FORMAT, label: "회사서식" },
  { value: INNERDOCTYPE.COUNCIL, label: "노사협의회" },
  { value: INNERDOCTYPE.BUSINESS, label: "사업자등록증" },
];

const Doc = () => {
  const docActions = useDocActions();
  const auth = useRecoilValue(authAtom);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [keyword, setKeyword] = useState("");
  const [docList, setDocList] = useState();
  // const docList = useRecoilValue(DocListQuery({ keyword, ...pageInfo }));
  const [editValue, setEditValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [update, docListUpdate] = useRecoilState(DocListUpdateAtom);

  useEffect(() => {
    docListGet();
  }, [pageInfo.current, update, keyword]);

  const docListGet = async () => {
    setLoading(true);
    const res = await docActions.getDocList({ pageInfo, keyword });
    setLoading(false);
    setDocList(res.data);
    setPageInfo(res.data.pageinfo);
  };

  useEffect(() => {
    if (!isCheckModalOpen) {
      setEditValue(null);
    }
  }, [isCheckModalOpen]);

  return (
    <div>
      <BreadCrumb />
      <div>
        <div className="all-group">
          {auth.user.isCompany && (
            <button
              slot="modal_button"
              className="holidaybtn greenbtn border-line mouse zoom_font doc-insert-btn"
              type="button"
              onClick={() => {
                setIsCheckModalOpen(true);
              }}
            >
              문서등록
            </button>
          )}

          <SearchKeyword placeholder="제목 입력" setKeyword={setKeyword} />
        </div>
      </div>
      <div className="sub-top"></div>
      <div className="mt-35">
        <div style={{ textAlign: "center" }}>
          {docList?.list?.length === 0 ? (
            <>
              <div>
                <div>
                  <img
                    src={NoFiles}
                    style={{ textAlign: "center", width: 200, height: 200 }}
                  ></img>
                </div>
                <div>등록된 문서가 없습니다.</div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="subin">
            <div className="doc-wrap">
              <ul className="doc-w cf doc-grid">
                {docList?.list?.map((doc) => {
                  return (
                    <div className="doc-card" key={doc.sn}>
                      {auth.user.isCompany && (
                        <div className="doc-card-header">
                          <button
                            className="doc-card-btn"
                            onClick={() => {
                              setEditValue(doc);
                              setIsCheckModalOpen(true);
                            }}
                          >
                            <img src={Edit} />
                          </button>
                          <button
                            className="doc-card-btn"
                            onClick={async () => {
                              if (window.confirm("삭제하시겠습니까?")) {
                                const res = await docActions.deleteInnerDoc({
                                  docsn: doc.sn,
                                });
                                if (res) {
                                  docListUpdate((update) => update + 1);
                                }
                              } else {
                                return false;
                              }
                            }}
                          >
                            <img src={Delete} />
                          </button>
                        </div>
                      )}
                      <div className="doc-content border-line">
                        <div>
                          <img
                            src={getDocExtensionImage(
                              doc.extension.toLowerCase()
                            )}
                            height={100}
                          />
                        </div>
                        <p className="doc-ellipsis zoom_font border-line">
                          {doc.company_name}
                        </p>
                        <p className="zoom_font border-line">
                          {overMaxLength(`${doc.title}`, 10)}
                        </p>
                      </div>
                      <div className="doc-btn-group">
                        <button
                          className="doc-greybtn border-line mouse zoom_font"
                          type="button"
                          onClick={() => {
                            // txt는 바로가기로 열면 깨져서 다운로드로 바꿈
                            if (doc.extension.toLowerCase() === "txt") {
                              downloadFile(downloadFileSetting(doc));
                            } else {
                              window.open(doc.url);
                            }
                          }}
                        >
                          바로가기
                        </button>
                        <button
                          className="doc-greenbtn border-line mouse"
                          type="button"
                          onClick={() => {
                            downloadFile(downloadFileSetting(doc));
                          }}
                        >
                          <a
                            className="zoom_font"
                            download="download"
                            style={{ color: "#FFFFFF" }}
                          >
                            다운로드
                          </a>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
      <CommonModal
        id="businessstaffeModal"
        open={isCheckModalOpen}
        setOpen={setIsCheckModalOpen}
        title="사내문서 등록"
      >
        <ModalContents
          setIsCheckModalOpen={setIsCheckModalOpen}
          editValue={editValue}
        />
      </CommonModal>
    </div>
  );
};

const ModalContents = ({ setIsCheckModalOpen, editValue }) => {
  const { register, handleSubmit, setValue, watch, control, reset } = useForm();
  const docActions = useDocActions();
  const [loading, setLoading] = useState("");
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const docListUpdate = useSetRecoilState(DocListUpdateAtom);

  useEffect(() => {}, [watch("file")]);

  useEffect(() => {
    setValue("title", editValue?.title);
    setValue("file", [{ name: editValue?.filename }]);
    setValue("type", docTypeOptions[editValue?.type - 1]);
  }, [editValue]);

  const onSubmit = async (data) => {
    // type을 이제 안 써서 포스트 보내는곳에서 1로 강제지정했습니다
    // if (data.type === undefined) {
    //   setToast({ ...toast, error: true, message: "구분을 입력 해 주세요." });
    //   return;
    // }
    if (data.title === undefined || data.title.trim() === "") {
      setToast({ ...toast, error: true, message: "제목을 입력 해 주세요." });
      return;
    }
    if (data.file[0].name === undefined) {
      setToast({
        ...toast,
        error: true,
        message: "첨부파일을 등록 해 주세요.",
      });
      return;
    }
    if (data.file[0].name.split(".").length >= 2) {
    } else {
      setToast({
        ...toast,
        error: true,
        message: "확장자를 확인해주세요.",
      });
      return;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    setLoading(true);
    if (editValue) {
      let params = {
        ...editValue,
        ...data,
      };

      const res = await docActions.editInnerDoc(params);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        setIsCheckModalOpen(false);
        docListUpdate((update) => update + 1);
      }
      setLoading(false);
      setDoubleclickLoading(false);
      return false;
    }

    const res = await docActions.postInnerDoc(data);
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      setIsCheckModalOpen(false);
      docListUpdate((update) => update + 1);
      reset();
      setValue("type", null);
    }
    setLoading(false);

    setDoubleclickLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table id="stafftable" className="pop-doc">
          <tbody>
            <tr>
              <td className="text-center-title zoom_font w-25">제목</td>
              <td>
                <input
                  {...register("title")}
                  className="finput border-line mouse zoom_font"
                  type="text"
                  placeholder="제목을 입력해 주세요."
                />
              </td>
            </tr>
            <tr />
            <tr>
              <td className="text-center-title zoom_font">첨부파일</td>
              <td>
                <input
                  type="text"
                  className="finput fin-bt border-line mouse zoom_font"
                  placeholder="첨부파일을 등록해 주세요."
                  readOnly
                  value={watch("file") ? watch("file")[0]?.name || "" : ""}
                />
                <label
                  className="primarybtn-md border-line mouse zoom_font"
                  htmlFor="upload"
                  style={{ margin: "0 5px" }}
                >
                  등록
                </label>
                <input
                  {...register("file")}
                  id="upload"
                  type="file"
                  accept="*/*"
                  style={{ display: "none" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <span>▶첨부파일은 1개의 파일만 등록가능합니다.</span>
        <br />
        <span>파일이 여러개일 경우 압축파일로 등록해주세요.</span>
        <div id="modalfoot" className="btn-group">
          <button
            className="fbtn btn-main border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>
          <button
            className="fbtn btn-sub border-line mouse zoom_font"
            type="button"
            onClick={() => {
              setIsCheckModalOpen(false);
            }}
          >
            취소
          </button>
        </div>
      </form>
    </>
  );
};

export default Doc;
