import { useEffect, useState } from "react";
import { useWorkOrderActions } from "../../../recoil/api/workOrder";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { authAtom } from "../../../recoil";
import { useRecoilValue } from "recoil";
import BreadCrumb from "../BreadCrumb";
import { downloadFile } from "../../../utils";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

const WorkOrderView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useRecoilValue(authAtom);
  const workOrderActions = useWorkOrderActions();
  const param = useParams();

  const [workOrderData, setWorkOrderData] = useState({
    name: [{ employee_name: "" }],
  });
  const [fileUrlData, setFileUrlData] = useState([]);

  const loadWorkOrderView = async (docsn) => {
    const res = await workOrderActions.getWorkOrderView(docsn);
    if (res.status === 200) {
      setWorkOrderData(() => res.data);
    }
    workOrderActions
      .getWorkOrderFileUrl(docsn)
      .then((res) => {
        if (res?.data) {
          setFileUrlData(res.data);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    loadWorkOrderView({ docsn: param.sn, esn: state.esn });
  }, []);

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form id="save_form" method="get" className="workform">
        <div className="table-responsive">
          <table className="writetable">
            <tbody>
              <tr>
                <th className="text-center-title w-15 zoom_font">대상자</th>
                <td className="zoom_font">
                  {workOrderData?.name[0].employee_name}
                </td>
                <th className="text-center-title w-15 zoom_font">지시일</th>
                <td className="zoom_font">
                  {dayjs(workOrderData?.work_order_ymd).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">제목</th>
                <td className="zoom_font">{workOrderData?.work_order_title}</td>
                <th className="text-center-title zoom_font">지시자</th>
                <td className="zoom_font">{workOrderData?.company_name}</td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="3" className="content-td-height">
                  <div
                    className="zoom_font_id ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        workOrderData?.work_order_contents
                      ),
                    }}
                  ></div>
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">첨부파일</th>
                <td colSpan="3">
                  {/* props 변경을 감지해야 리랜더링 됨 */}
                  {fileUrlData?.map((value, index) => (
                    <div key={index}>
                      <a
                        onClick={() => downloadFile(value)}
                        style={{ cursor: "pointer" }}
                      >
                        {`${value?.filename}.${value?.extension}`}
                      </a>
                      <br />
                    </div>
                  ))}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <div className="writebtnbox">
        {auth.user.isCompany && (
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="button"
            onClick={() => {
              navigate(
                `/${auth.accountType}/workorder_edit/${workOrderData.sn}`,
                {
                  state: {
                    page: state?.page,
                    esn: workOrderData.name[0].esn,
                    edit: true,
                  },
                }
              );
            }}
          >
            수정
          </button>
        )}

        <button
          className="writebtn cancelbtn border-line mouse zoom_font"
          type="button"
          onClick={() => {
            navigate(`/${auth.accountType}/workorder`, {
              state: { page: state?.page, keyword: state?.keyword, prev: true },
            });
          }}
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default WorkOrderView;
