import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useEmployeeRegisterActions,
  useUserActions,
} from "../../../recoil/api/auth";
import { InputText } from "../../form/Input";
import CommonModal from "../../modal/CommonModal";
import { toastAtom } from "../../../recoil";
import { useRecoilState } from "recoil";
import { phoneRegex } from "../../../utils/regex";

const ChangePhoneNumberModal = ({ open, setOpen, setModalPhoneNumber }) => {
  const userActions = useUserActions();
  const employeeRegisterActions = useEmployeeRegisterActions();
  const [toast, setToast] = useRecoilState(toastAtom);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  //인증문자 발송여부 체크
  const [isSendSMS, setSendSMS] = useState(false);
  //인증 문자 체크결과
  const [isSMSCertified, setSMSCertified] = useState(false);
  //인증 결과 보여줄 문자
  const [isSMSFailReason, setSMSFailReason] = useState("");
  //최종 인증결과 저장할 스테이트
  const [isCertified, setCertified] = useState(false);
  //휴대폰 번호 담을 스테이트
  const [isUserPhoneNumber, setUserPhoneNumber] = useState("");

  return (
    <CommonModal title="휴대폰전화 변경" open={open} setOpen={setOpen}>
      <InputText
        type="text"
        errors={errors}
        refs={{
          ...register("user_phone_number", {
            required: true,
            pattern: /^[0-9]+$/,
          }),
          placeholder: "휴대폰번호를 - 없이 입력해주세요.",
        }}
        style={{ width: "79%", display: "inline-block" }}
      />

      <button
        className="primarybtn-sm"
        type="button"
        style={{ marginLeft: "5px", width: "20%", height: "40px" }}
        onClick={async () => {
          setSMSCertified(false);
          setSMSFailReason("");
          setSendSMS(false);

          if (!phoneRegex.test(watch("user_phone_number"))) {
            setSMSCertified(true);
            setSMSFailReason("휴대폰 번호를 정확하게 입력하세요.");
          } else {
            const res = await employeeRegisterActions.phoneCheckRequest({
              tel: watch("user_phone_number"),
            });
            if (res) {
              setSendSMS(true);
              setUserPhoneNumber(watch("user_phone_number"));
            } else {
              setSMSCertified(true);
              setSMSFailReason(
                "인증번호 발송에 실패했습니다. 다시 발송해주세요."
              );
            }
          }
          // const res =
          //   await employeeRegisterActions.phoneCheckRequest({
          //     tel: watch("user_phone_number"),
          //   });
        }}
      >
        인증번호 발송
      </button>

      {isSendSMS && (
        <div className="mt-3 mb-3">
          <InputText
            type="text"
            errors={errors}
            refs={{
              ...register("certified_number", {
                required: true,
                pattern: /^[0-9]+$/,
              }),
              placeholder: "인증번호를 입력해주세요",
            }}
            style={{ width: "79%", display: "inline-block" }}
          />

          <button
            className="primarybtn-sm"
            type="button"
            style={{ marginLeft: "5px", width: "20%", height: "40px" }}
            onClick={async () => {
              const res = await employeeRegisterActions.phoneCheck({
                tel: watch("user_phone_number"),
                authNum: watch("certified_number"),
              });
              if (res.result) {
                setCertified(true);
                // setValue("tel", watch("user_phone_number"));
              } else {
                setSMSCertified(true);
                setSMSFailReason(String(res.data));
              }
            }}
          >
            인증하기
          </button>
        </div>
      )}

      {isSMSCertified && (
        <span className="register-warning">{isSMSFailReason}</span>
      )}

      <div className="mt-3"></div>
      <div className="btn-group">
        <button
          type="button"
          className="primarybtn-lg"
          onClick={() => {
            if (isCertified) {
              setOpen(false);
              // setValue("tel", watch("user_phone_number"));
              // setValue("tel", isUserPhoneNumber);
              setModalPhoneNumber(isUserPhoneNumber);
            } else {
              setToast({
                ...toast,
                error: true,
                message: "인증절차가 완료되지 않았습니다.",
              });
            }
          }}
        >
          변경하기
        </button>
      </div>
    </CommonModal>
  );
};

export default ChangePhoneNumberModal;
