import dayjs from "dayjs";
import React from "react";

const PaymentPrint = ({ printPayment }) => {
  const payday = dayjs(printPayment?.pay?.payday);
  return (
    <div
      className="WordSection1"
      style={{
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 25,
        marginRight: 25,
      }}
    >
      <p
        className="MsoTitle"
        align="center"
        style={{
          textAlign: "center",
          lineHeight: "normal",
          fontSize: 24,
        }}
      >
        <br></br>
        <span lang="EN-US" style={{ color: "black" }}>
          급여명세서
        </span>
      </p>
      <br></br>
      <p
        className="MsoTitle"
        align="center"
        style={{
          textAlign: "center",
          lineHeight: "normal",
          fontSize: 20,
        }}
      >
        <span lang="EN-US" style={{ color: "black" }}>
          {payday.year()}년 {payday.month() + 1}월
        </span>
      </p>
      <br></br>

      <table
        className="MsoTableGrid"
        border={1}
        cellSpacing={0}
        cellPadding={0}
        style={{ borderCollapse: "collapse", border: "none" }}
      >
        <tbody>
          <tr>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  소속
                </span>
              </p>
            </td>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.company_name}
                </span>
              </p>
            </td>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  이름
                </span>
              </p>
            </td>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.employeeList[0]?.employee_name}
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p
        className="MsoTitle"
        align="left"
        style={{
          textAlign: "left",
          textIndent: "112pt",
          lineHeight: "normal",
        }}
      ></p>

      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoListParagraph"
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "20pt",
          textIndent: "-18pt",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US" style={{ color: "black", fontSize: 20 }}>
          1. 실 지급액
        </span>
      </p>
      <br></br>
      <table
        className="MsoTableGrid"
        border={1}
        cellSpacing={0}
        cellPadding={0}
        style={{ borderCollapse: "collapse", border: "none" }}
      >
        <tbody>
          <tr>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  지급 합계
                </span>
              </p>
            </td>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  공제 합계
                </span>
              </p>
            </td>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  실 수령액
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.total.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={195}
              style={{
                width: "146.5pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.total_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={195}
              style={{
                width: "146.5pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.result.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <br></br>
      <br></br>
      <p
        className="MsoListParagraph"
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "20pt",
          textIndent: "-18pt",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US" style={{ color: "black", fontSize: 20 }}>
          2. 지급 내역
        </span>
      </p>
      <br></br>
      <table
        className="MsoTableGrid"
        border={1}
        cellSpacing={0}
        cellPadding={0}
        style={{ borderCollapse: "collapse", border: "none" }}
      >
        <tbody>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
              colSpan={4}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  세부 항목
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={293}
              colSpan={2}
              style={{
                width: "219.7pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  지급 항목
                </span>
              </p>
            </td>
            <td
              width={293}
              colSpan={2}
              style={{
                width: "219.8pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  공제 합계
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  기본급여
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.default_pay.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  국민연금
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.national_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  교통비 보조
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.traffic_pay.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  고용보험
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.hire_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  업무수당
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.work_pay.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  건강보험
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.health_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  직책수당
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.cheif_pay.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  산재보험
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.industry_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  연장수당
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.extra_pay.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  갑근세
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.source_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  연차수당
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.vacation_pay.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  소득세
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.income_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  기타수당
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.etc_pay.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  기타비용
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.etc_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}></span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  급여총액
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.total.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  공제총액
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  - {printPayment?.pay?.total_duty.toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
              colSpan={3}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  실 수 령 액
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {(
                    printPayment?.pay?.total - printPayment?.pay?.total_duty
                  ).toLocaleString("ko-KR")}
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <br></br>
      <br></br>
      <p
        className="MsoListParagraph"
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "20pt",
          textIndent: "-18pt",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US" style={{ color: "black", fontSize: 20 }}>
          3. 근무일수, 시간 등에 따라 금액이 달라지는 항목에 대해서만 계산방법
          기재 (예시 8시간 기준)
        </span>
      </p>
      <br></br>

      {/* 여기에 테이블 넣기 */}
      <table
        className="MsoTableGrid"
        border={1}
        cellSpacing={0}
        cellPadding={0}
        style={{ borderCollapse: "collapse", border: "none" }}
      >
        <tbody>
          <tr>
            <td
              style={{
                width: "219.7pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  통상시급(원)
                </span>
              </p>
            </td>
            <td
              width={293}
              style={{
                width: "219.7pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  연장근로시간 수
                </span>
              </p>
            </td>
            <td
              style={{
                width: "219.7pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  야간근로시간 수
                </span>
              </p>
            </td>
            <td
              style={{
                width: "219.7pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  휴일근로시간 수
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  9,620
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  0
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  0
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  0
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
              colSpan={4}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  계산 방법
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  기본급
                </span>
              </p>
            </td>
            <td
              colSpan={3}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  계약서 상의 기본근로시간 * 통상 시급
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  시간외 근로수당
                </span>
              </p>
            </td>
            <td
              colSpan={3}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  계약서상의 시간외근로시간 * 통상시급(가산포함)
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  연장근로수당
                </span>
              </p>
            </td>
            <td
              colSpan={3}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  연장근로시간 * 통상시급 * 1.5
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  휴일근로수당
                </span>
              </p>
            </td>
            <td
              colSpan={3}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  휴일근로시간 * 통상시급 * 1.5
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  미사용연차수당
                </span>
              </p>
            </td>
            <td
              colSpan={3}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  연차휴가일 수 * 통상시급 * 8
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  공 제 내 역
                </span>
              </p>
            </td>
            <td
              colSpan={3}
              style={{
                width: "109.85pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  관계법령의 산정근거에 따름
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <br></br>
      <br></br>
      <p
        className="MsoListParagraph"
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "20pt",
          textIndent: "-18pt",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US" style={{ color: "black", fontSize: 20 }}>
          4. 비고
        </span>
      </p>
      <br></br>

      <table
        className="MsoTableGrid"
        border={1}
        cellSpacing={0}
        cellPadding={0}
        style={{ borderCollapse: "collapse", border: "none" }}
      >
        <tbody>
          <tr>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  비고
                </span>
              </p>
            </td>
          </tr>{" "}
          <tr>
            <td
              width={195}
              style={{
                width: "146.5pt",
                border: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {printPayment?.pay?.comment}
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <br></br>
      <br></br>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US" style={{ color: "black", fontSize: 20 }}>
          귀하의 노고에 항상 감사드립니다.
        </span>
      </p>
      <br></br>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US" style={{ color: "black", fontSize: 20 }}>
          {payday.year()}년 {payday.month() + 1}월 {payday.date()}일
        </span>
      </p>
      <br></br>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="right"
        style={{
          marginBottom: "0cm",
          textAlign: "right",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US" style={{ color: "black", fontSize: 20 }}>
          {printPayment.pay.company_name}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {printPayment.pay.sign_url && (
            <img src={printPayment.pay.sign_url} width={100} height={100} />
          )}
          {!printPayment.pay.sign_url && "인"}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <br></br>
      <p className="MsoNormal">
        <span lang="EN-US">&nbsp;</span>
      </p>
    </div>
  );
};

export default PaymentPrint;
