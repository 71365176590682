import dayjs from "dayjs";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const CompanyRegisterInitialState = {
  business_number: "",
  company_name: "",
  name: "",
  user_id: "",
  password: "",
  password_confirm: "",
  user_tel: "",
  user_email: "",
};

export const LoginInitialState = {
  accountType: ACCOUNTTYPE.EMPLOYEE,
  id: "",
  pw: "",
};

export const SavedIdlState = {
  ID: false,
};

export const MenuInitialState = [
  //서브메뉴 있음
  {
    name: "",
    submenu: [
      {
        name: "",
        link: "",
      },
    ],
    link: "",
    subshow: false,
  },
];

export const PaymentsInitialState = {
  period: { start_date: "", end_date: "" },
  payments: [
    {
      name: "이름",
      payday: "3/4",
      allowance_sum: "23847",
      exemption_sum: "1435",
      tatal: "3452",
    },
  ],
};

export const ToastState = {
  on: false,
  error: false,
  message: "",
};

export const PageInfoState = {
  total: "1",
  current: "1",
  totalCnt: "1",
  loading: true,
};

export const DateState = {
  starttime: dayjs().subtract(3, "day").format("YYYYMMDD"),
  endtime: dayjs().format("YYYYMMDD"),
  keyword: "",
};

export const ElecLabelState = [];

export const ElecTextareaState = {};
