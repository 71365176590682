import { dateUtils } from "../../../utils";

const CareerHrm = ({ hrm, stamp }) => {
  return (
    <div
      className="WordSection1"
      style={{
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 25,
        marginRight: 25,
      }}
    >
      <h1
        className="MsoTitle"
        style={{
          textAlign: "center",
          lineHeight: "normal",
          fontSize: 24,
        }}
      >
        <br></br>
        <p lang="EN-US" style={{ color: "black" }}>
          경력증명서
        </p>
      </h1>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <table
        className="MsoTableGrid"
        border={1}
        cellSpacing={0}
        cellPadding={0}
        style={{
          borderCollapse: "collapse",
          border: "none",
          marginBottom: 7,
        }}
      >
        <tbody>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  회사명
                </span>
              </p>
            </td>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {hrm.company_name}
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  성명
                </span>
              </p>
            </td>
            <td
              width={147}
              style={{
                width: "109.9pt",
                border: "solid windowtext 1pt",
                borderLeft: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {hrm.employee_name}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  주소
                </span>
              </p>
            </td>
            <td
              width={440}
              colSpan={3}
              style={{
                width: "329.65pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {hrm.company_address}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  사업장 주소
                </span>
              </p>
            </td>
            <td
              width={440}
              colSpan={3}
              style={{
                width: "329.65pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {hrm.company_address
                    ? `${hrm.company_address} ${hrm.company_address_detail}`
                    : ""}
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              width={146}
              style={{
                width: "109.85pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                background: "#aeaaaa",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  재직기간
                </span>
              </p>
            </td>
            <td
              width={440}
              colSpan={3}
              style={{
                width: "329.65pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1pt",
                borderRight: "solid windowtext 1pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {hrm.joinday}
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "502.7pt" }}>
            <td
              width={586}
              colSpan={4}
              valign="top"
              style={{
                width: "439.5pt",
                border: "solid windowtext 1pt",
                borderTop: "none",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "502.7pt",
              }}
            >
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginBottom: "0cm", lineHeight: "normal" }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  위 사실이 틀림없음을 증명합니다.
                </span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {`${
                    hrm.createtime &&
                    dateUtils
                      .dateToDayjs(hrm.createtime)
                      .format("YYYY년 MM월 DD일")
                  }`}
                </span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0cm",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US">&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                align="right"
                style={{
                  marginBottom: "0cm",
                  textAlign: "right",
                  lineHeight: "normal",
                }}
              >
                <span lang="EN-US" style={{ color: "black" }}>
                  {hrm.company_name} 대표이사
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {stamp && <img src={stamp} width={100} height={100} />}
                  {!stamp && "인"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="MsoNormal">
        <span lang="EN-US">&nbsp;</span>
      </p>
    </div>
  );
};

export default CareerHrm;
