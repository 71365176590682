import Xgray from "../../../img/x-gray.svg";

import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { ChatViewAtom, RoomSnAtom } from "../../../recoil";
import { CHAT_VIEW } from "./ChattingLayout";

const ChatMenu = ({ setKeyword, placeholder, setFlag }) => {
  const setView = useSetRecoilState(ChatViewAtom);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const setRoomSn = useSetRecoilState(RoomSnAtom);
  return (
    <div>
      <div className="chat-icon">
        <button
          className="icon_search"
          onClick={() => setIsSearchOpen(true)}
        ></button>
        <button
          className="icon_profile"
          onClick={() => setView(CHAT_VIEW.MEMBER_LIST)}
        ></button>
        <button
          className="icon_chat"
          onClick={() => setView(CHAT_VIEW.ROOM_LIST)}
        ></button>
        <button
          className="icon_plus"
          onClick={() => {
            setFlag(false);
            setRoomSn(null);
            setView(CHAT_VIEW.INVITE);
          }}
        ></button>
      </div>
      {isSearchOpen && (
        <div className="chat-sec-search">
          <div>
            <input
              className="chat-search"
              type="text"
              placeholder={placeholder || "이름 또는 업체명을 입력하세요."}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <button
              className="search-x"
              onClick={() => {
                setKeyword("");
                setIsSearchOpen(false);
              }}
            >
              <img src={Xgray} alt="닫기" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMenu;
