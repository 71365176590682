import "react-calendar/dist/Calendar.css";
import "../../css/calendar.css";

import Meeting from "../../img/calendar/meeting.png";
import Birth from "../../img/calendar/birth.png";
import Event from "../../img/calendar/event.png";
import Trip from "../../img/calendar/trip.png";
import Vacation from "../../img/calendar/vacation.png";

import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Calendar } from "react-calendar";
import Draggable from "react-draggable";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom, menuAtom } from "../../recoil";
import { useAccoutTypeActions } from "../../recoil/api/auth";
import { getHolidayType } from "../../recoil/api/holiday";
import useMainActions from "../../recoil/api/main";
import { dateUtils, overMaxLength } from "../../utils";
import AdminMemberCnt from "../admin/home/AdminMemberCnt";
import CompanyInfo from "../company/home/CompanyInfo";
import CompanyMemberCnt from "../company/home/CompanyMemberCnt";
import EmployeeInfo from "../employee/home/EmployeeInfo";
import EmployeeWorkstampHome from "../employee/home/EmployeeWorkstampHome";
import DataTable from "./DataTable";
import HomeCard from "./HomePage/HomeCard";
import { SCHEDULETYPE } from "../../JSON/enum/Dataenum";
import { Link, useNavigate } from "react-router-dom";
import { downloadFiles } from "../../utils";
import { useBoardNoticeActions } from "../../recoil/api/boardNotice";
import ScheduleFunction from "./Schedule/ScheduleFunction";
import { getCookie, setCookie } from "../../utils/cookies";
import { WorkstampTypeAtom } from "../../recoil/atoms/workstamp";
import HomeScheduleCard from "./HomePage/HomeScheduleCard";
import useScheduleActions from "../../recoil/api/schedule";

//TO-DO 오늘의 근로자 현황 관리자랑 근로자 분리
const Home = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const accountType = useAccoutTypeActions();
  const [noticeList, setNoticeList] = useState([]);
  const [vacationList, setVacationList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [scheduleDocs, setScheduleDocs] = useState([]);
  const mainActions = useMainActions();
  const [popupList, setPopupList] = useState([]);
  const [hiddenPopupSnList, setHiddenPopupSnList] = useState([]);
  const scFn = ScheduleFunction();
  const setMenu = useSetRecoilState(menuAtom);
  const [workstampType, setWorkstampType] = useRecoilState(WorkstampTypeAtom);
  const [todayDay, setTodayDay] = useState(dayjs().format("YYYYMMDD"));
  const scheduleActions = useScheduleActions();

  const boardAction = useBoardNoticeActions();

  const navigate = useNavigate();

  useEffect(() => {
    getNoticeList();
    getVacationList();
    getScheduleList();
    getWorkOrder();
    getPopupList();
    getSettingMenu();
    getCommonCode();
    getScheduleDocsList();
  }, []);

  const getCommonCode = async () => {
    const res = await mainActions.getCommonCode();
    if (res.status === 200 && res.data) {
      const commonCode = res.data.commonCode.map((item) => {
        return { [item.codename]: item.text };
      });
      const user = { ...auth, commonCode: [...commonCode] };
      setAuth(user);
      const userData = JSON.parse(localStorage.getItem("user"));
      userData.commonCode = commonCode;
      localStorage.setItem("user", JSON.stringify(userData));
    }
  };

  const getSettingMenu = async () => {
    const res = await mainActions.getSettingMenu();
    const parentMenu = res.data
      .filter((m) => {
        if (m.upper_menu_sn === 0) {
          return true;
        }
        return false;
      })
      .map((n) => {
        return {
          ...n,
          submenu: [],
          subshow: false,
        };
      });
    const childMenu = res.data.filter((m) => {
      if (m.upper_menu_sn !== 0) {
        return true;
      }
      return false;
    });
    for (let cm of childMenu) {
      parentMenu
        .find((x) => {
          return x.sn === cm.upper_menu_sn;
        })
        .submenu.push(cm);
    }
    setMenu(parentMenu);
  };

  const getScheduleDocsList = async () => {
    const res = await scheduleActions.getScheduleDocsList({
      page: 1,
      count: 3,
    });
    if (res.status === 200) {
      setScheduleDocs(res.data);
    }
  };

  const getPopupList = async () => {
    const res = await mainActions.getPopup();
    if (res.status === 200 && res.data) {
      setPopupList(
        res.data.map((ele) => {
          return { ...ele, isOpened: true };
        })
      );
    }
  };

  const getWorkOrder = async () => {
    const res = await mainActions.getWorkOrder({
      starttime: dayjs().subtract(7, "day").format("YYYYMMDD"),
      endtime: dayjs().format("YYYYMMDD"),
    });
    if (res.status === 200) {
      setWorkOrderList(res.data.list);
    }
  };

  const getNoticeList = async () => {
    const res = await mainActions.getNoticeList({
      starttime: dayjs().subtract(7, "day").format("YYYYMMDD"),
      endtime: dayjs().format("YYYYMMDD"),
    });
    if (res.status === 200) {
      setNoticeList(res.data.list);
    }
  };

  const getScheduleList = async (todaySchedule) => {
    const res = await mainActions.getScheduleToday({
      starttime:
        todaySchedule === undefined
          ? dayjs().format("YYYYMMDD")
          : todaySchedule,
      endtime:
        todaySchedule === undefined
          ? dayjs().format("YYYYMMDD")
          : todaySchedule,
    });
    if (res.status === 200 && res.data.list) {
      setScheduleList(res.data.list);
    }
  };

  const getVacationList = async () => {
    const res = await mainActions.getVacationList({
      starttime: dayjs().subtract(7, "day").format("YYYYMMDD"),
      endtime: dayjs().format("YYYYMMDD"),
      home: true,
    });
    if (res.status === 200) {
      setVacationList(res.data.list);
    }
  };

  const getDayOfWeek = (day) => {
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    return week[day];
  };

  const getScheduleImage = (scheduleType) => {
    switch (scheduleType) {
      case SCHEDULETYPE.MEETING:
        return Meeting;
      case SCHEDULETYPE.BIRTHDAY:
        return Birth;
      case SCHEDULETYPE.HALFHALFOFF:
        return Vacation;
      case SCHEDULETYPE.HALFOFF:
        return Vacation;
      case SCHEDULETYPE.DAYOFF:
        return Vacation;
      case SCHEDULETYPE.OUTWORKING:
        return Trip;
      case SCHEDULETYPE.BUSINESSTRIP:
        return Trip;
      case SCHEDULETYPE.CONFFRENCE:
        return Event;
      default:
        return Event;
    }
  };

  return (
    <div className="substanceCont">
      <div className="checkw cf">
        <div className="checkcont">
          <h2 className="title-h2 zoom_font">
            {accountType.isEmployee() ? `나의 현황` : `오늘의 근로자 현황 `}
          </h2>
          <div className="check01 cf main-box">
            {accountType.isEmployee() && <EmployeeInfo />}
            {accountType.isCompany() && <CompanyInfo />}
            {accountType.isAdmin() && <CompanyInfo />}
            <div className="check-margin checkmain w-100">
              <HomeScheduleCard
                title="오늘 할일 목록"
                link="schedule_docs"
                scheduleDocs={scheduleDocs}
                getScheduleDocsList={getScheduleDocsList}
              />
            </div>
            <div className="check01-0 check01-4 check-margin grid-item">
              <HomeCard title="업무 지시" link="workorder">
                <ul style={{ marginTop: 14 }}>
                  {workOrderList?.map((workOrder, index) => {
                    return (
                      <div
                        key={index}
                        className="zoom_font pointer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          navigate(
                            `/${auth.accountType}/workorder_view/${workOrder.sn}`,
                            {
                              state: { esn: workOrder.esn },
                            }
                          );
                        }}
                      >
                        <p className="ellipsis3 zoom_font">
                          {workOrder.work_order_title}
                        </p>
                        <p
                          className="zoom_font"
                          style={{ flexShrink: "0", marginLeft: "5px" }}
                        >
                          {workOrder.work_order_ymd}
                        </p>
                      </div>
                    );
                  })}
                </ul>
              </HomeCard>
            </div>
          </div>
          <div className="check01 cf">
            <HomeCard title="연차 현황" link="vacation">
              <DataTable
                checkbox={false}
                columns={[
                  "이름",
                  "휴가기간",
                  "휴가종류",
                  "휴가사유",
                  "부여일수",
                  "사용일수",
                  "잔여일수",
                ]}
              >
                {vacationList?.map((vacation) => {
                  return (
                    <tr className="text-center" key={vacation?.sn}>
                      <td className="border-line zoom_font">
                        {vacation?.name}
                      </td>
                      <td className="border-line zoom_font">
                        {`${dayjs(vacation.startYMD).format(
                          "YYYY-MM-DD"
                        )} ~ ${dayjs(vacation.endYMD).format("YYYY-MM-DD")}`}
                      </td>
                      <td className="border-line zoom_font">
                        {getHolidayType(vacation?.type)}
                      </td>
                      <td className="border-line zoom_font">
                        {overMaxLength(vacation.reason, 15)}
                      </td>
                      <td className="border-line zoom_font">
                        {Math.round(
                          vacation?.total * vacation?.day_standard * 10
                        ) / 10}
                      </td>
                      <td className="border-line zoom_font">
                        {Math.round(
                          vacation?.used * vacation?.day_standard * 10
                        ) / 10}
                      </td>
                      <td className="border-line zoom_font">
                        {Math.round(
                          vacation?.remain * vacation?.day_standard * 10
                        ) / 10}
                      </td>
                    </tr>
                  );
                })}
              </DataTable>
            </HomeCard>
            <HomeCard title="공지사항" link="notice">
              <DataTable
                columns={["제목", "작성자", "등록일", "첨부"]}
                checkbox={false}
              >
                {noticeList?.map((notice) => {
                  return (
                    <tr
                      className="text-center"
                      key={notice.sn}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(
                          `/${auth.accountType}/notice_view/${notice.sn}`
                        );
                      }}
                    >
                      <td className="border-line zoom_font">
                        {overMaxLength(notice.title, 10)}
                      </td>
                      <td className="border-line zoom_font">{notice.id}</td>
                      <td className="border-line zoom_font">
                        {notice.createtime?.slice(0, 10)}
                      </td>
                      {notice?.url >= 1 ? (
                        <td onClick={(e) => e.stopPropagation()}>
                          <button
                            className="t-download border-line mouse zoom_font"
                            type="button"
                            onClick={() => {
                              downloadFiles(
                                notice,
                                boardAction.getBoardNoticeFileUrl,
                                { docsn: notice.sn },
                                `공지사항_${notice.title}`,
                                0
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td />
                      )}
                    </tr>
                  );
                })}
              </DataTable>
            </HomeCard>
          </div>
        </div>
        {accountType.isEmployee() && <EmployeeWorkstampHome />}
        {accountType.isCompany() && <CompanyMemberCnt />}
        {accountType.isAdmin() && <AdminMemberCnt />}
        <div className="checkmain-full">
          <HomeScheduleCard
            title="오늘 할일 목록"
            link="schedule_docs"
            scheduleDocs={scheduleDocs}
            getScheduleDocsList={getScheduleDocsList}
          />
        </div>
        <div
          className="checkcal"
          style={{ padding: "15px 5px", marginTop: "10px" }}
        >
          <div className="cal-top">
            <div className="monthtop">
              <div id="calender">
                <Calendar
                  calendarType="US"
                  allowPartialRange={false}
                  showNavigation={true}
                  navigationAriaLabel={"test"}
                  formatDay={(_, date) => dayjs(date).format("DD")}
                  minDate={dateUtils.minDate()}
                  maxDate={dateUtils.maxDate()}
                  onClickDay={(day) => {
                    getScheduleList(dayjs(day).format("YYYYMMDD"));
                    setTodayDay(dayjs(day).format("YYYYMMDD"));
                  }}
                  tileContent={({ activeStartDate, date, view }) =>
                    view === "month" &&
                    String(new Date(date).getDate()) === dayjs().format("D") &&
                    String(new Date(date).getMonth() + 1) ===
                      dayjs().format("M") &&
                    scheduleList.length > 0 &&
                    todayDay === dayjs().format("YYYYMMDD") && (
                      <>
                        <divs
                          style={{
                            lineHeight: "20px",
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#fd9f9f",
                            borderRadius: "50%",
                            position: "absolute",
                            top: "0",
                            right: "0",
                          }}
                        >
                          {scheduleList?.length > 99
                            ? "99+"
                            : scheduleList?.length}
                        </divs>
                      </>
                    )
                  }
                />
              </div>

              <div className="home-calendar-content">
                <div className="home-calendar-title border-line">
                  <p>
                    {dayjs(todayDay).format("M월 D일")}{" "}
                    {getDayOfWeek(dayjs().day())}요일의 일정
                  </p>
                </div>
                {scheduleList.length === 0 && <div>오늘 일정이 없습니다.</div>}
                {scheduleList?.slice(0, 5)?.map((schedule) => {
                  return (
                    <div
                      key={schedule.sn}
                      style={{ marginBottom: 16, display: "flex" }}
                      className="border-line"
                    >
                      <div>
                        <img
                          src={getScheduleImage(schedule.scheduleType)}
                          style={{ marginRight: 8 }}
                        />
                      </div>
                      <div>
                        <span>
                          {schedule.name}님의{" "}
                          {scFn.getScheduleType(schedule.scheduleType)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupList?.map((ele) => {
        return getCookie(`pop${ele?.sn}`)
          ? dayjs().isBefore(dayjs(getCookie(`pop${ele?.sn}`)))
          : !hiddenPopupSnList.includes(ele?.sn) && (
              <Draggable
                key={ele.sn}
                handle=".popup-box"
                defaultPosition={{ x: ele?.x, y: ele?.y }}
              >
                <div
                  className="popup-box"
                  style={{
                    display: "block",
                    width: `${ele?.width}px`,
                    height: `${ele?.height}px`,
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      margin: 30,
                      maxWidth: "100%",
                      maxHeight: "100%",
                      overflow: "auto",
                    }}
                  >
                    <div id="popup-content">
                      <div
                        className="zoom_font_id"
                        dangerouslySetInnerHTML={{ __html: ele?.contents }}
                      />
                    </div>
                  </div>
                  <div id="popup-bottom" style={{ display: "fixed" }}>
                    <button
                      onClick={() => {
                        setCookie(
                          `pop${ele?.sn}`,
                          `${dayjs().format("YYYY-MM-DD")}`,
                          {
                            path: "/",
                            expires: new Date(
                              dayjs().add(1, "day").add(9, "hour")
                            ),
                          }
                        );
                        setHiddenPopupSnList([...hiddenPopupSnList, ele?.sn]);
                      }}
                    >
                      오늘 하루동안 보지 않기
                    </button>
                    <button
                      onClick={() => {
                        setHiddenPopupSnList([...hiddenPopupSnList, ele?.sn]);
                      }}
                    >
                      X
                    </button>
                  </div>
                </div>
              </Draggable>
            );
      })}
    </div>
  );
};

export default Home;
