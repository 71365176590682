import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import useManagementActions, {
  EmployeeManagementListQuery,
  updateEmployeeManagementState,
} from "../../../recoil/api/management";
import { PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchKeyword from "../../common/SearchKeyword";
import { InputText } from "../../form/Input";
import CommonModal from "../../modal/CommonModal";

const EmployeeManage = () => {
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const update = useRecoilValue(updateEmployeeManagementState);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [targetEmployee, setTargetEmployee] = useState({});
  const [keyword, setKeyword] = useState("");
  const [auth, setAuth] = useRecoilState(authAtom);
  const [loading, setLoading] = useState(false);
  const managementActions = useManagementActions();
  const [employeeManagementList, setEmployeeManageList] = useState([]);

  const getManagement = async (pageInfo, keyword) => {
    setLoading(true);
    const res = await managementActions.EmployeeManagementList({
      pageInfo,
      keyword,
    });
    setEmployeeManageList(res.list);
    setPageInfo(res.pageinfo);
    setLoading(false);
  };

  useEffect(() => {
    getManagement(pageInfo, keyword);
  }, [pageInfo.current, keyword, update]);

  // 최초실행은 안 하고 모달 닫을때마다 새로고침되는 유즈이펙트
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (editModalOpen === false) {
      getManagement(pageInfo, keyword);
    }
  }, [editModalOpen]);

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group"></div>
        <div className="all-group">
          <SearchKeyword placeholder="이름 입력" setKeyword={setKeyword} />
        </div>
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={[
          "아이디",
          "이름",
          "휴대폰",
          "회사명",
          "직무",
          "입사일",
          "퇴사일",
          "상태",
          "편집",
        ]}
      >
        {employeeManagementList?.map((employee, i) => {
          return (
            <tr key={i}>
              <td className="border-line mouse zoom_font">
                {employee.employee_id}
              </td>
              <td className="border-line mouse zoom_font">
                {employee.employee_name}
              </td>
              <td className="border-line mouse zoom_font">{employee.tel}</td>
              <td className="border-line mouse zoom_font">
                {employee.companyname}
              </td>
              <td className="border-line mouse zoom_font">
                {employee.department || "-"}
              </td>
              <td className="border-line mouse zoom_font">
                {employee.joindatetime !== "Invalid Date"
                  ? employee.joindatetime.slice(0, 10)
                  : "-"}
              </td>
              <td className="border-line mouse zoom_font">
                {employee.exitdatetime !== "Invalid Date"
                  ? employee.exitdatetime.slice(0, 10)
                  : "-"}
              </td>
              <td className="border-line mouse zoom_font">
                {employee.ishired
                  ? auth.commonCode[1]["재직중"]
                  : auth.commonCode[0]["퇴사"]}
              </td>
              <td>
                {/* {!!employee.ishired && ( */}
                <button
                  className="pinkbtn-sm border-line mouse zoom_font"
                  onClick={() => {
                    setEditModalOpen(true);
                    setTargetEmployee(employee);
                  }}
                >
                  수정
                </button>
                {/* )} */}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <CommonModal
        title="근로자 정보"
        open={editModalOpen}
        setOpen={setEditModalOpen}
      >
        {targetEmployee && (
          <EditModalContent
            setOpen={setEditModalOpen}
            targetEmployee={targetEmployee}
          />
        )}
      </CommonModal>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export const EditModalContent = ({ setOpen, targetEmployee }) => {
  const managementActions = useManagementActions();
  const updateEmployeeManagement = useSetRecoilState(
    updateEmployeeManagementState
  );
  const [toast, setToast] = useRecoilState(toastAtom);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});
  useEffect(() => {
    setValue("department", targetEmployee.department);
    setValue("joindatetime", targetEmployee?.joindatetime?.slice(0, 10));
    if (targetEmployee.exitdatetime !== "Invalid Date") {
      setValue("exitdatetime", targetEmployee?.exitdatetime?.slice(0, 10));
    } else {
      setValue("exitdatetime", "");
    }
    if (targetEmployee.flipped_start_datetime !== "Invalid Date") {
      setValue(
        "flipped_start_datetime",
        dayjs(targetEmployee?.flipped_start_datetime).format("YYYY-MM-DD")
      );
    } else {
      setValue("flipped_start_datetime", "");
    }
  }, [targetEmployee]);

  const onSubmit = async (data) => {
    if (data.joindatetime.includes("Invalid")) {
      setToast({
        ...toast,
        error: true,
        message: "입사일이 없습니다.",
      });
      return;
    }

    if (data.joindatetime === "") {
      setToast({
        ...toast,
        error: true,
        message: "입사일을 선택해주세요",
      });
      return;
    }

    if (data?.flipped_start_datetime?.includes("Invalid")) {
      setToast({
        ...toast,
        error: true,
        message: "플립 시작일을 선택해주세요",
      });
      return;
    }

    if (data?.flipped_start_datetime === "") {
      setToast({
        ...toast,
        error: true,
        message: "플립 시작일을 선택해주세요",
      });
      return;
    }

    if (!targetEmployee.ishired && data.exitdatetime === "") {
      setToast({
        ...toast,
        error: true,
        message: "퇴사일을 선택해주세요",
      });
      return;
    }

    let params = {
      ...data,
      listnum: targetEmployee.listnum,
      esn: targetEmployee.esn,
      COMP_CD: targetEmployee.COMP_CD,
    };
    if (
      dayjs(targetEmployee.joindatetime.slice(0, 10)).isAfter(
        dayjs(data.exitdatetime)
      ) ||
      dayjs(data.flipped_start_datetime.slice(0, 10)).isAfter(
        dayjs(data.exitdatetime)
      )
    ) {
      return setToast({
        ...toast,
        error: true,
        message: "입사일 이후의 날짜를 선택해주세요",
      });
    }

    const res = await managementActions.changeEmployeeStatus(params);
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      updateEmployeeManagement((update) => update + 1);
      setOpen(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table className="writetable" style={{ border: "1px solid #e5e5e5" }}>
        <tbody>
          <tr>
            <th className="text-center-title w-30 zoom_font">직무</th>
            <td>
              <InputText
                errors={errors}
                refs={{
                  ...register("department"),
                }}
              />
            </td>
          </tr>
          <tr>
            <th className="text-center-title w-30 zoom_font">입사일</th>
            <td className="zoom_font">
              {targetEmployee.joindatetime !== "Invalid Date" && (
                <input
                  {...register("joindatetime")}
                  style={{ border: "1px solid #e5e5e5" }}
                  type="date"
                />
              )}
            </td>
            {/* <td className="zoom_font">
              <input
                {...register("joindatetime")}
                style={{ border: "1px solid #e5e5e5" }}
                type="date"
              />
            </td> */}
          </tr>
          <tr>
            <th className="text-center-title w-30 zoom_font">확인일</th>
            <td className="zoom_font">
              <input
                {...register("flipped_start_datetime")}
                style={{ border: "1px solid #e5e5e5" }}
                type="date"
              />
            </td>
          </tr>
          <tr>
            <th className="text-center-title w-30 zoom_font">퇴사일</th>
            <td className="zoom_font">
              <input
                {...register("exitdatetime")}
                style={{ border: "1px solid #e5e5e5" }}
                type="date"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="modalfoot">
        <div className="btn-group">
          <button className="writebtn submitbtn border-line mouse zoom_font">
            수정
          </button>
          <button
            type="button"
            className="writebtn cancelbtn border-line mouse zoom_font"
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </button>
        </div>
      </div>
    </form>
  );
};

export const EditModalContentApprove = ({
  setOpen,
  targetEmployee,
  getManagement,
}) => {
  const managementActions = useManagementActions();
  const useStartDate = useRef();

  const [toast, setToast] = useRecoilState(toastAtom);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({});

  useEffect(() => {
    setValue("department", targetEmployee.department);
    setValue(
      "joindatetime",
      dayjs(targetEmployee?.joindatetime).format("YYYY-MM-DD")
    );
    if (targetEmployee.flipped_start_datetime !== "Invalid Date") {
      setValue(
        "flipped_start_datetime",
        dayjs(targetEmployee?.flipped_start_datetime).format("YYYY-MM-DD")
      );
    } else {
      setValue("flipped_start_datetime", "");
    }
    if (useStartDate) {
      useStartDate.current.checked = false;
    }
  }, [targetEmployee]);

  const onSubmit = async (data) => {
    if (data.joindatetime.includes("Invalid")) {
      setToast({
        ...toast,
        error: true,
        message: "입사일이 없습니다.",
      });
      return;
    }

    if (data.joindatetime === "") {
      setToast({
        ...toast,
        error: true,
        message: "입사일을 선택해주세요",
      });
      return;
    }

    if (data.flipped_start_datetime.includes("Invalid")) {
      setToast({
        ...toast,
        error: true,
        message: "확인일이 없습니다.",
      });
      return;
    }

    if (data.flipped_start_datetime === "") {
      setToast({
        ...toast,
        error: true,
        message: "확인일을 선택해주세요",
      });
      return;
    }

    // let params = {
    //   ...data,
    //   listnum: targetEmployee.listnum,
    //   esn: targetEmployee.esn,
    //   COMP_CD: targetEmployee.COMP_CD,
    // };

    if (
      dayjs(data?.joindatetime?.slice(0, 10)).isAfter(
        dayjs(data.flipped_start_datetime)
      )
    ) {
      return setToast({
        ...toast,
        error: true,
        message: "입사일 이후의 날짜를 선택해주세요",
      });
    }

    const res = await managementActions.changeUserState({
      userList: [
        {
          flipped_start_datetime: data.flipped_start_datetime,
          joindatetime: data.joindatetime,
          esn: [targetEmployee?.esn],
          isapprove: targetEmployee?.isapprove,
          ishired: !!!targetEmployee?.ishired,
        },
      ],
    });
    if (res?.status === 200) {
      getManagement();
      setToast({
        ...toast,
        on: true,
      });
      setOpen(false);
    } else if (res?.status === 400) {
      alert(res.data);
    } else if (res?.status === undefined) {
      alert("에러입니다.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table className="writetable" style={{ border: "1px solid #e5e5e5" }}>
        <tbody>
          <tr>
            <th className="text-center-title w-30 zoom_font">직무</th>
            <td>
              <InputText
                errors={errors}
                refs={{
                  ...register("department"),
                }}
              />
            </td>
          </tr>
          <tr>
            <th className="text-center-title w-30 zoom_font">입사일</th>
            <td className="zoom_font">
              {targetEmployee.joindatetime !== "Invalid Date" && (
                <input
                  {...register("joindatetime")}
                  style={{ border: "1px solid #e5e5e5" }}
                  type="date"
                />
              )}
            </td>
          </tr>
          <tr>
            <th className="text-center-title w-30 zoom_font">확인일</th>
            <td className="zoom_font">
              <input
                {...register("flipped_start_datetime")}
                style={{ border: "1px solid #e5e5e5" }}
                type="date"
                onChange={() => {
                  useStartDate.current.checked = false;
                }}
              />

              <input
                id="flipped_start_datetime"
                style={{
                  border: "1px solid #e5e5e5",
                  marginLeft: "20px",
                  marginRight: "4px",
                }}
                ref={useStartDate}
                type="checkbox"
                onClick={() =>
                  setValue("flipped_start_datetime", getValues("joindatetime"))
                }
              />
              <label htmlFor="flipped_start_datetime">입사일과 동일</label>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="modalfoot">
        <div className="btn-group">
          <button className="writebtn submitbtn border-line mouse zoom_font">
            수정
          </button>
          <button
            type="button"
            className="writebtn cancelbtn border-line mouse zoom_font"
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </button>
        </div>
      </div>
    </form>
  );
};
export default EmployeeManage;
