import { useEffect, useState } from "react";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import { useWorkstampActions } from "../../../recoil/api/workstamp";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import { getWorkstampType } from "../../common/calendar/CalendarLg";
import { getWorkstampType2 } from "../../common/calendar/CalendarLg";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import XlsxDownload from "../../form/XlsxDownload";
import CommonModal from "../../modal/CommonModal";
import { dateUtils } from "../../../utils";
import { toastAtom } from "../../../recoil";
import { useRecoilState } from "recoil";

const MAX_REASON_LENGTH = 15;
export const isReasonOverMaxLength = (reason) => {
  return reason > MAX_REASON_LENGTH ? true : false;
};

const Reason = () => {
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [date, setDate] = useState(DateState);
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [company, setCompany] = useState({});
  const accountType = useAccoutTypeActions();
  const workstampActions = useWorkstampActions();
  const [toast, setToast] = useRecoilState(toastAtom);

  // 뒤에 2 붙은건 초과근무입니다
  const [reasonDetail, setReasonDetail] = useState("");
  const [reasonDetail2, setReasonDetail2] = useState("");
  const [reasons, setReasons] = useState([]);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const [isReasonModalOpen2, setIsReasonModalOpen2] = useState(false);
  const auth = useRecoilValue(authAtom);

  // 수정, 삭제 관련 변수
  const [isReasonChangeModalOpen, setisReasonChangeModalOpen] = useState(false);
  const [changedReason, setChangedReason] = useState("");
  const [changedReason2, setChangedReason2] = useState("");
  const [changedSn, setChangedSn] = useState("");

  // 초과근무인지 타입 저장
  const [worktype, setWorktype] = useState(0);

  const lateOptions = [
    {
      label: "전체",
      value: null,
    },
    {
      label: auth?.commonCode[34]["조기출근"],
      value: 1,
    },
    {
      label: auth?.commonCode[35]["정상출근"],
      value: 2,
    },
    {
      label: auth?.commonCode[36]["지각"],
      value: 3,
    },
    {
      label: "조기퇴근",
      value: 9,
    },
    {
      label: auth?.commonCode[37]["정상퇴근"],
      value: 4,
    },
    {
      label: auth?.commonCode[38]["초과근무"],
      value: 5,
    },
    {
      label: "미퇴근",
      value: 8,
    },
  ];

  //검색창 조건중에 초과근무 지각 데이터 검색하는 값
  const [isType, setType] = useState(null);

  useEffect(() => {
    getReasonByCompanyId();
  }, [date, pageInfo?.current, company, isType]);

  const getReasonByCompanyId = async () => {
    setLoading(true);
    const res = await workstampActions.getReasonByCompanyId({
      date,
      pageInfo,
      COMP_CD: company.COMP_CD || null,
      type: isType,
    });

    if (res.status === 200) {
      setReasons(res.data.workstamp);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const putReasonChange = async (changedReason, changedReason2, changedSn) => {
    if (changedReason === "" || changedReason2 === "") {
      setToast({
        ...toast,
        error: true,
        message: "사유는 공백이 될 수 없습니다.",
      });
      return;
    }

    setLoading(true);
    const res = await workstampActions.putReasonChange({
      workin_reason: changedReason,
      workout_reason: changedReason2,
      sn: changedSn,
    });

    if (res.status === 200) {
      // setReasons(res.data.workstamp);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const delReasonChange = async (sn) => {
    setLoading(true);
    const res = await workstampActions.delReasonChange({
      sn: sn,
    });

    if (res.status === 200) {
      // setReasons(res.data.workstamp);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <XlsxDownload
            api={`${auth.accountType}/reason`}
            params={{
              id: auth.user.id,
              COMP_CD: company?.COMP_CD || null,
              type: isType,
            }}
            name="근태 사유"
          />
        </div>
        <div className="all-group">
          <div className="btn-group-inline btn-group-left">
            {accountType.isAdmin() && (
              <button
                className="secondarybtn-md"
                type="button"
                onClick={() => {
                  setIsSearchCompanyModalOpen(true);
                }}
              >
                {company?.company_name || "기업 선택"}
              </button>
            )}
            <div className="select-border-line">
              <Select
                placeholder="선택"
                options={lateOptions}
                onChange={(e) => {
                  setType(e.value);
                }}
              />
            </div>
          </div>
          <div className="d-inline-block">
            <SearchByDate
              placeholder="근로자 이름 입력"
              dateAndKeyword={date}
              onChange={(value) => setDate(value)}
              oneday={true}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div>
        <DataTable
          checkbox={false}
          loading={loading}
          columns={
            accountType.isAdmin()
              ? [
                  "출근",
                  "퇴근",
                  "작성자",
                  "작성일",
                  "작성 시간",
                  "근무 시간",
                  "조기출근, 지각 사유",
                  "조기퇴근, 초과근무, 미퇴근 사유",
                  "작업",
                ]
              : [
                  "출근",
                  "퇴근",
                  "작성자",
                  "작성일",
                  "작성 시간",
                  "근무 시간",
                  "조기출근, 지각 사유",
                  "조기퇴근, 초과근무, 미퇴근 사유",
                ]
          }
        >
          {reasons?.map((reason, idx) => {
            return (
              <tr key={idx}>
                <td className="border-line zoom_font" style={{ minWidth: 80 }}>
                  {getWorkstampType2(reason.workin_type)}
                </td>
                <td className="border-line zoom_font" style={{ minWidth: 80 }}>
                  {getWorkstampType2(reason.workout_type)}
                </td>
                <td className="border-line zoom_font">
                  {accountType.isAdmin() === true
                    ? `${reason.company_name} / ${reason.employee_name}`
                    : reason.employee_name}
                </td>
                <td className="border-line zoom_font">
                  {reason.workin_stamptime?.slice(0, 10)}
                </td>
                <td className="border-line zoom_font">
                  {reason.workin_stamptime?.slice(11, 19)}
                </td>
                <td className="border-line zoom_font">
                  {`
                  ${dateUtils.mmssToTime(reason.working_starttime_string)} ~ 
                  ${dateUtils.mmssToTime(reason.working_endtime_string)}  
                  `}
                </td>
                <td className="border-line zoom_font">
                  {reason?.workin_reason?.length > 30 ? (
                    <div
                      className="pointer"
                      onClick={() => {
                        setReasonDetail(reason.workin_reason);
                        setIsReasonModalOpen(true);
                      }}
                    >{`${reason.workin_reason.substring(0, 30)}...`}</div>
                  ) : (
                    reason.workin_reason
                  )}
                </td>
                <td className="border-line zoom_font">
                  {reason?.workout_reason?.length > 30 ? (
                    <div
                      className="pointer"
                      onClick={() => {
                        setReasonDetail2(reason.workout_reason);
                        setIsReasonModalOpen2(true);
                      }}
                    >{`${reason.workout_reason.substring(0, 30)}...`}</div>
                  ) : (
                    reason.workout_reason
                  )}
                </td>
                {accountType.isAdmin() && (
                  <td>
                    <div className="btn-group">
                      <button
                        className="primarybtn-sm border-line zoom_font"
                        onClick={() => {
                          setChangedReason(reason.workin_reason);
                          setChangedReason2(reason.workout_reason);
                          setChangedSn(reason.workstamp_sn);
                          setisReasonChangeModalOpen(true);
                          setWorktype(reason.workout_type);
                        }}
                      >
                        수정
                      </button>
                      <button
                        className="pinkbtn-sm border-line zoom_font"
                        onClick={() => {
                          if (window.confirm("삭제하시겠습니까?")) {
                            delReasonChange(reason.workstamp_sn);
                          } else {
                            return;
                          }
                        }}
                      >
                        삭제
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </DataTable>
      </div>
      <CommonModal
        title="상세 사유"
        open={isReasonModalOpen}
        setOpen={setIsReasonModalOpen}
      >
        {reasonDetail}
      </CommonModal>
      <CommonModal
        title="초과근무 사유"
        open={isReasonModalOpen2}
        setOpen={setIsReasonModalOpen2}
      >
        {reasonDetail2}
      </CommonModal>
      <CommonModal
        title="사유 수정"
        open={isReasonChangeModalOpen}
        setOpen={setisReasonChangeModalOpen}
      >
        <div className="input-group">
          <p className="zoom_font">지각 사유</p>
          <input
            disabled={worktype === 5 ? true : false}
            style={{ width: "100%" }}
            className="custom-input txt-search border-line mouse zoom_font"
            placeholder="지각 사유를 입력해주세요."
            value={changedReason}
            onChange={(e) => {
              setChangedReason(e.target.value);
            }}
          />
        </div>
        <br></br>
        <br></br>
        <div className="input-group">
          <p className="zoom_font">초과근무 사유</p>
          <input
            disabled={worktype === 5 ? false : true}
            style={{ width: "100%" }}
            className="custom-input txt-search border-line mouse zoom_font"
            placeholder="초과근무 사유를 입력해주세요."
            value={changedReason2}
            onChange={(e) => {
              setChangedReason2(e.target.value);
            }}
          />
        </div>
        <div className="btn-group">
          <button
            type="button"
            className="fbtn submitbtn"
            onClick={() => {
              putReasonChange(changedReason, changedReason2, changedSn);
              setisReasonChangeModalOpen(false);
            }}
          >
            승인
          </button>
        </div>
      </CommonModal>

      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setIsSearchCompanyModalOpen(false);
          setCompany(company);
        }}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default Reason;
