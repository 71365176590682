import Select from "react-select";
import DataTable from "../../common/DataTable";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useHolidayActions } from "../../../recoil/api/holiday";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";

const VacationRuleDetail = ({ isReset, setIsModalOpen }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const holidayActions = useHolidayActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const today = dayjs();

  // 예상 부여일(연차) 계산 로직
  const ExpectedYearGrantDay = (year_mask) => {
    const result =
      15 +
      Math.floor((year_mask - 1) / watch("additional_base_year")?.value) *
        watch("additional_grant_day");
    return result;
  };

  useEffect(() => {
    reset();
    setValue("additional_base_year", { value: null, label: "사용안함" });
  }, [isReset]);

  useEffect(() => {
    if (watch("additional_base_year")?.value === null) {
      setValue("additional_grant_day", null);
      clearErrors("additional_grant_day");
    }
  }, [watch("additional_base_year")]);

  useEffect(() => {
    if (watch("base_date")?.value === 0) {
      setValue("fiscal_date", null);
    }
  }, [watch("base_date")]);

  useEffect(() => {
    fiscalDate();
  }, [watch("fiscal_date")]);

  useEffect(() => {
    if (watch("is_promotion") === 1) {
      setValue("is_carry", 0);
    }
  }, [watch("is_promotion")]);

  // 부여일 정규식(소수)
  const rulesDay = (setValue, fieldName, fieldValue) => {
    const isValidGrade = /^\d{1,1}$|^\d{1,}\.\d{0,2}$/.test(fieldValue);
    const formattedValue = isValidGrade ? fieldValue : fieldValue.slice(0, -1);

    setValue(fieldName, formattedValue);
  };

  // 회계일 및 부여일 계산 로직
  const [fiscalDay, setFiscalDay] = useState({ date: "", day: "" }); // 최초 도래 회계일, 소급부여일
  const [MonthlyDay, setMonthlyDay] = useState(""); // 입사년 마지막 월차 발생일

  const fiscalDate = () => {
    const fiscalDate = watch("fiscal_date");

    if (fiscalDate === undefined || fiscalDate === null) {
      return setFiscalDay({ date: "회계일을 입력해주세요.", day: "" });
    }

    const input = watch("fiscal_date").split("/");
    if (input.length !== 2) {
      return setFiscalDay({ date: "회계일을 확인해주세요.", day: "" });
    }

    let fiscalDay = dayjs(today).format(`YYYY. ${input[0]}. ${input[1]}`);

    if (
      dayjs(fiscalDay).isBefore(dayjs(today).format("YYYY. MM. DD 00:00:00"))
    ) {
      fiscalDay = dayjs(today)
        .add(1, "year")
        .format(`YYYY. ${input[0]}. ${input[1]}`);

      if (dayjs(fiscalDay).date() - dayjs(today).date() < 0) {
        setMonthlyDay(
          dayjs(fiscalDay).add(-1, "month").format(`YYYY. MM. ${today.date()}`)
        );
      } else {
        setMonthlyDay(dayjs(fiscalDay).format(`YYYY. MM. ${today.date()}`));
      }
    } else {
      if (dayjs(fiscalDay).date() - dayjs(today).date() < 0) {
        setMonthlyDay(
          dayjs(fiscalDay).add(-1, "month").format(`YYYY. MM. ${today.date()}`)
        );
      } else {
        setMonthlyDay(dayjs(fiscalDay).format(`YYYY. MM. ${today.date()}`));
      }
    }

    let grantDay = Math.min(
      Math.ceil(((dayjs(fiscalDay).diff(today, "day") + 2) * 15) / 365),
      15
    );

    setFiscalDay({ date: fiscalDay, day: grantDay });
  };

  const onSubmit = async (values) => {
    if (values.day_standard === "") {
      values.day_standard = 1;
    }
    values.base_date = values.base_date.value;
    values.additional_base_year = values.additional_base_year?.value || null;
    values.additional_grant_day = values.additional_grant_day || null;

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const res = await holidayActions.createVacationRule(values);
    if (res.status === 200) {
      setToast({ ...toast, on: true, message: "연차규칙 추가 완료." });
      setIsModalOpen(false);
    } else if (res.status === 400) {
      setToast({ ...toast, error: true, message: res.data });
    } else {
      setToast({
        ...toast,
        error: true,
        message: "규칙 추가 실패. 다시 시도해주세요.",
      });
    }
    setDoubleclickLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", gap: "24px" }}>
          <section style={{ width: "60%" }}>
            <table className="pop-in-pop">
              <tbody>
                <tr>
                  <th className="pop_thd">적용시작일</th>
                  <td className="pop_thd" colSpan={3}>
                    <input
                      type="date"
                      name="active_date"
                      className="pop_input"
                      style={{
                        border: errors?.active_date ? "1px solid #e85d6c" : "",
                      }}
                      min={dayjs().add(1, "day").toJSON().slice(0, 10)}
                      onKeyDown={(e) => e.preventDefault()}
                      {...register("active_date", {
                        required: "시작일을 선택해주세요.",
                      })}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="pop_thd">부여기준일</th>
                  <td className="pop_thd" colSpan={3}>
                    <Controller
                      name="base_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              border: errors?.base_date
                                ? "1px solid #e85d6c"
                                : "",
                            }),
                          }}
                          placeholder="부여기준일을 선택해주세요."
                          isSearchable={false}
                          options={[
                            { value: 0, label: "입사일(근로기준법)" },
                            { value: 1, label: "회계일" },
                          ]}
                          // onChange={(e) => field.onChange(e.value)}
                          // value={
                          //   field.value === 0
                          //     ? { value: 0, label: "입사일(근로기준법)" }
                          //     : { value: 1, label: "회계일" }
                          // }
                        />
                      )}
                    />
                  </td>
                </tr>
                {/* 회계일인 경우 true */}
                {watch("base_date")?.value === 1 && (
                  <tr>
                    <th className="pop_thd">회계일</th>
                    <td className="pop_thd" colSpan={3}>
                      <input
                        type="text"
                        name="fiscal_date"
                        className="pop_input"
                        style={{
                          border: errors?.fiscal_date
                            ? "1px solid #e85d6c"
                            : "",
                        }}
                        placeholder="MM/DD"
                        maxLength={5}
                        onInput={(e) =>
                          setValue(
                            "fiscal_date",
                            e.target.value
                              ?.replace(/\D/g, "")
                              .replace(
                                /^(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/,
                                "$1/$2"
                              )
                              .substring(0, 5)
                          )
                        }
                        {...register("fiscal_date", {
                          required: "회계일을 입력해주세요.",
                          pattern: {
                            value:
                              /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/,
                          },
                        })}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th className="pop_thd">
                    <p>부여일</p>
                    <p
                      style={{
                        marginTop: "2px",
                        fontSize: "12px",
                        color: "#e85d6c",
                      }}
                    >
                      * 1개월 만근 시
                    </p>
                  </th>
                  <td className="pop_thd" colSpan={3}>
                    <input
                      type="text"
                      name="grant_day"
                      className="pop_input"
                      style={{
                        border: errors?.grant_day ? "1px solid #e85d6c" : "",
                      }}
                      placeholder="부여일을 입력해주세요. (소수점 가능)"
                      maxLength={3}
                      onInput={(e) =>
                        rulesDay(setValue, "grant_day", e.target.value)
                      }
                      {...register("grant_day", {
                        required: "부여일을 입력해주세요.",
                        min: 0.1,
                      })}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="pop_thd">추가부여기준</th>
                  <td className="pop_thd" colSpan={2}>
                    <Controller
                      name="additional_base_year"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isSearchable={false}
                          options={[
                            { value: null, label: "사용안함" },
                            { value: 1, label: "1년마다" },
                            { value: 2, label: "2년마다" },
                            { value: 3, label: "3년마다" },
                            { value: 4, label: "4년마다" },
                            { value: 5, label: "5년마다" },
                          ]}
                        />
                      )}
                    />
                  </td>
                  <td className="pop_thd">
                    <input
                      type="text"
                      name="additional_grant_day"
                      className="pop_input"
                      maxLength={3}
                      style={{
                        width: "50%",
                        marginRight: "10px",
                        border: errors?.additional_grant_day
                          ? "1px solid #e85d6c"
                          : "",
                      }}
                      onInput={(e) =>
                        rulesDay(
                          setValue,
                          "additional_grant_day",
                          e.target.value
                        )
                      }
                      disabled={
                        watch("additional_base_year")?.value === null
                          ? true
                          : false
                      }
                      {...register("additional_grant_day", {
                        required:
                          watch("additional_base_year")?.value !== null
                            ? true
                            : false,
                        min: 0.1,
                      })}
                    />
                    일
                  </td>
                </tr>
                <tr>
                  <th className="pop_thd">연차촉진제도</th>
                  <td className="pop_thd" colSpan={3}>
                    <Controller
                      name="is_promotion"
                      control={control}
                      defaultValue={0}
                      render={({ field }) => (
                        <div
                          className="vacation-radio"
                          style={{ display: "flex", alignContent: "center" }}
                        >
                          <input
                            {...field}
                            id="usePromotionTrue"
                            type="radio"
                            value={0}
                            checked={field.value === 0}
                            onChange={() => field.onChange(0)}
                          />
                          <label
                            htmlFor="usePromotionTrue"
                            style={{ marginLeft: "8px", padding: 1 }}
                          >
                            사용안함
                          </label>
                          <input
                            {...field}
                            id="usePromotionFalse"
                            type="radio"
                            value={1}
                            checked={field.value === 1}
                            onChange={() => field.onChange(1)}
                            style={{ marginLeft: "24px" }}
                          />
                          <label
                            htmlFor="usePromotionFalse"
                            style={{ marginLeft: "8px", padding: 1 }}
                          >
                            사용
                          </label>
                        </div>
                      )}
                    />
                  </td>
                </tr>
                {watch("is_promotion") === 0 && (
                  <tr>
                    <th className="pop_thd">이월여부</th>
                    <td className="pop_thd" colSpan={3}>
                      <Controller
                        name="is_carry"
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <div
                            className="vacation-radio"
                            style={{ display: "flex", alignContent: "center" }}
                          >
                            <input
                              {...field}
                              id="useCarryTrue"
                              type="radio"
                              value={0}
                              checked={field.value === 0}
                              onChange={() => field.onChange(0)}
                            />
                            <label
                              htmlFor="useCarryTrue"
                              style={{ marginLeft: "8px", padding: 1 }}
                            >
                              불가능
                            </label>
                            <input
                              {...field}
                              id="useCarryFalse"
                              type="radio"
                              value={1}
                              checked={field.value === 1}
                              onChange={() => field.onChange(1)}
                              style={{ marginLeft: "24px" }}
                            />
                            <label
                              htmlFor="useCarryFalse"
                              style={{ marginLeft: "8px", padding: 1 }}
                            >
                              가능
                            </label>
                          </div>
                        )}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th className="pop_thd">1일기준</th>
                  <td className="pop_thd" colSpan={3}>
                    <input
                      type="text"
                      name="day_standard"
                      placeholder="미입력 시 기본 1일로 설정됩니다."
                      className="pop_input"
                      {...register("day_standard")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section
            style={{
              width: "41%",
              padding: "12px 8px 0 8px",
              border: "1px solid #80808030",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3
                style={{
                  marginBottom: "16px",
                  textIndent: "8px",
                }}
              >
                예상 연차부여일
              </h3>
              <span
                style={{ fontSize: "12px", marginTop: "5px", color: "#e85d6c" }}
              >
                예시 입사일 : {today.format("YY. MM. DD")}
              </span>
            </div>
            {/* 입사일기준? 회계일기준? */}
            {watch("base_date") === undefined ||
            watch("base_date")?.value === 0 ? (
              <>
                <DataTable
                  columns={["근속", "휴가 발생일", "휴가수"]}
                  checkbox={false}
                  isResponsive={false}
                >
                  <tr>
                    <td>1년미만</td>
                    <td>
                      {dayjs(today).add(1, "month").format("YY. MM. DD") +
                        " ~ " +
                        dayjs(today).add(11, "month").format("YY. MM. DD")}
                      <br />
                      매월 {today.format("DD")}일 ({watch("grant_day") || "1"}
                      개씩)
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("grant_day")
                          ? Math.round(watch("grant_day") * 11 * 10) / 10
                          : "11"}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>1년</td>
                    <td>
                      {dayjs(today).add(1, "year").format("YYYY. MM. DD")}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>15</span>일
                    </td>
                  </tr>
                  <tr>
                    <td>2년</td>
                    <td>
                      {dayjs(today).add(2, "year").format("YYYY. MM. DD")}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("additional_base_year")?.value !== null
                          ? Math.round(ExpectedYearGrantDay(2) * 10) / 10
                          : "15"}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>3년</td>
                    <td>
                      {dayjs(today).add(3, "year").format("YYYY. MM. DD")}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("additional_base_year")?.value !== null
                          ? Math.round(ExpectedYearGrantDay(3) * 10) / 10
                          : "16"}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>4년</td>
                    <td>
                      {dayjs(today).add(4, "year").format("YYYY. MM. DD")}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("additional_base_year")?.value !== null
                          ? Math.round(ExpectedYearGrantDay(4) * 10) / 10
                          : "16"}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>5년</td>
                    <td>
                      {dayjs(today).add(5, "year").format("YYYY. MM. DD")}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("additional_base_year")?.value !== null
                          ? Math.round(ExpectedYearGrantDay(5) * 10) / 10
                          : "17"}
                      </span>
                      일
                    </td>
                  </tr>
                </DataTable>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: "16px",
                    fontSize: "12px",
                    lineHeight: "20px",
                  }}
                >
                  근로자의 <span style={{ color: "#22ccb7" }}>입사일</span>을
                  기준으로 계산 된 예상 결과입니다.
                  <br />
                  <span style={{ color: "#e85d6c" }}>
                    * 근로기준법에 따른 원칙
                  </span>
                </div>
              </>
            ) : (
              <>
                <DataTable
                  columns={["근속", "휴가 발생일", "휴가수"]}
                  checkbox={false}
                  isResponsive={false}
                >
                  <tr>
                    <td>
                      입사년
                      <br />
                      (월차)
                    </td>
                    <td>
                      {dayjs(MonthlyDay).isValid() && watch("grant_day") ? (
                        <>
                          {dayjs(MonthlyDay)?.format("YY. MM. DD") <
                          dayjs(today).add(1, "month").format("YY. MM. DD") ? (
                            "-"
                          ) : (
                            <>
                              {dayjs(today)
                                .add(1, "month")
                                .format("YY. MM. DD") +
                                " ~ " +
                                dayjs(MonthlyDay)?.format("YY. MM. DD")}
                              <br />
                              매월 {today.format("DD")}일 (
                              {watch("grant_day") || "1"}
                              개씩)
                            </>
                          )}
                        </>
                      ) : (
                        "회계・부여일을 입력해주세요."
                      )}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {dayjs(MonthlyDay).isValid() && watch("grant_day")
                          ? dayjs(MonthlyDay)?.format("YY. MM. DD") <
                            dayjs(today).add(1, "month").format("YY. MM. DD")
                            ? ""
                            : Math.round(
                                (dayjs(MonthlyDay).diff(today, "month") + 1) *
                                  watch("grant_day") *
                                  10
                              ) / 10
                          : ""}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>
                      2년차
                      <br />
                      (연차)
                    </td>
                    <td>{fiscalDay?.date}</td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>{fiscalDay?.day}</span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>
                      2년차
                      <br />
                      (월차)
                    </td>
                    <td>
                      {dayjs(MonthlyDay).isValid() && watch("grant_day") ? (
                        <>
                          {dayjs(MonthlyDay)
                            ?.add(1, "month")
                            .format("YY. MM. DD") >
                          dayjs(today).add(11, "month").format("YY. MM. DD") ? (
                            "-"
                          ) : (
                            <>
                              {dayjs(MonthlyDay)
                                ?.add(1, "month")
                                .format("YY. MM. DD") +
                                " ~ " +
                                dayjs(today)
                                  .add(11, "month")
                                  .format("YY. MM. DD")}
                              <br />
                              매월 {today.format("DD")}일 (
                              {watch("grant_day") || "1"}
                              개씩)
                            </>
                          )}
                        </>
                      ) : (
                        "회계・부여일을 입력해주세요."
                      )}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {dayjs(MonthlyDay).isValid() && watch("grant_day")
                          ? dayjs(MonthlyDay)
                              ?.add(1, "month")
                              .format("YY. MM. DD") >
                            dayjs(today).add(11, "month").format("YY. MM. DD")
                            ? ""
                            : Math.round(
                                (dayjs(today)
                                  .add(11, "month")
                                  .diff(
                                    dayjs(MonthlyDay).add(1, "month"),
                                    "month"
                                  ) +
                                  1) *
                                  watch("grant_day") *
                                  10
                              ) / 10
                          : ""}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>3년</td>
                    <td>
                      {dayjs(fiscalDay?.date).isValid()
                        ? dayjs(fiscalDay?.date)
                            ?.add(1, "year")
                            ?.format("YYYY. MM. DD")
                        : "회계일을 입력해주세요."}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("additional_base_year")?.value !== null
                          ? Math.round(ExpectedYearGrantDay(2) * 10) / 10
                          : "15"}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>4년</td>
                    <td>
                      {dayjs(fiscalDay?.date).isValid()
                        ? dayjs(fiscalDay?.date)
                            ?.add(2, "year")
                            ?.format("YYYY. MM. DD")
                        : "회계일을 입력해주세요."}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("additional_base_year")?.value !== null
                          ? Math.round(ExpectedYearGrantDay(3) * 10) / 10
                          : "15"}
                      </span>
                      일
                    </td>
                  </tr>
                  <tr>
                    <td>5년</td>
                    <td>
                      {dayjs(fiscalDay?.date).isValid()
                        ? dayjs(fiscalDay?.date)
                            ?.add(3, "year")
                            ?.format("YYYY. MM. DD")
                        : "회계일을 입력해주세요."}
                    </td>
                    <td>
                      <span style={{ color: "#22ccb7" }}>
                        {watch("additional_base_year")?.value !== null
                          ? Math.round(ExpectedYearGrantDay(4) * 10) / 10
                          : "16"}
                      </span>
                      일
                    </td>
                  </tr>
                </DataTable>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: "16px",
                    fontSize: "12px",
                    lineHeight: "20px",
                  }}
                >
                  회사의{" "}
                  <span style={{ color: "#22ccb7" }}>
                    회계일({watch("fiscal_date") || " "})
                  </span>
                  을 기준으로 계산 된 예상 결과입니다.
                  <br />
                  <span style={{ color: "#e85d6c" }}>
                    * 2년차 연차 부여시 소수점 절상 처리 (예 : 11.2 -> 12)
                    <br />* 판례・노동부 행정해석에 따라 가능
                  </span>
                </div>
              </>
            )}
          </section>
        </div>
        <div style={{ marginTop: "16px", textAlign: "right" }}>
          <button
            className="primarybtn-md border-line mouse zoom_font"
            type="submit"
            // onClick={() => handleSubmit(onSubmit())}
          >
            추가하기
          </button>
        </div>
      </form>
    </>
  );
};

export default VacationRuleDetail;
