import { useRecoilValue } from "recoil";
import { CompanymployeeInfoQuery } from "../../../recoil/api/main";
import { authAtom } from "../../../recoil";
import { useState } from "react";
import DataTable from "../../common/DataTable";
import dayjs from "dayjs";
import { getWorkstampType } from "../../common/calendar/CalendarLg";

const CompanyInfo = () => {
  const employeeInfo = useRecoilValue(CompanymployeeInfoQuery());
  const auth = useRecoilValue(authAtom);

  function HoverScrollBox({ children, inList }) {
    const [show, setShow] = useState(false);

    let data = employeeInfo.workinList;
    if (inList === "workinList") {
      data = employeeInfo.workinList;
    } else if (inList === "lateinList") {
      data = employeeInfo.lateinList;
    } else if (inList === "disapperList") {
      data = employeeInfo.disapperList;
    } else if (inList === "vacationList") {
      data = employeeInfo.vacationList;
    }

    return (
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        style={{
          position: "relative",
          display: "inline-block",
        }}
      >
        {children}
        {show && auth.user.isCompany && (
          <>
            <div className="speech-bubble">
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "#ffffff",
                  paddingTop: 10,
                  width: inList === "workinList" ? "150%" : "100%",
                  borderRadius: "0.8em",
                }}
              >
                <span className="input-time-t2">
                  {inList === "workinList"
                    ? "출근 정보"
                    : inList === "lateinList"
                    ? "지각 정보"
                    : inList === "disapperList"
                    ? "미출근 정보"
                    : "휴가 정보"}
                </span>
                <DataTable
                  checkbox={false}
                  columns={
                    inList === "workinList"
                      ? ["이름", "근무시간", "출근시간", "출근상태"]
                      : inList === "lateinList"
                      ? ["이름", "근무시간", "출근시간"]
                      : inList === "disapperList"
                      ? ["이름", "근무시간"]
                      : ["이름", "휴가 신청기간"]
                  }
                  isResponsive={false}
                >
                  {inList === "workinList" &&
                    data?.map((inData, index) => {
                      return (
                        <tr key={index}>
                          <td>{inData?.employee_name}</td>
                          <td>{`${inData?.working_starttime_string} ~ ${inData?.working_endtime_string}`}</td>
                          <td>
                            {dayjs(inData?.workin_stamptime).format("HH:mm:ss")}
                          </td>
                          <td>{getWorkstampType(inData?.workin_type)}</td>
                        </tr>
                      );
                    })}
                  {inList === "lateinList" &&
                    data?.map((inData, index) => {
                      return (
                        <tr key={index}>
                          <td>{inData?.employee_name}</td>
                          <td>{`${inData?.working_starttime_string} ~ ${inData?.working_endtime_string}`}</td>
                          <td>
                            {dayjs(inData?.workin_stamptime).format("HH:mm:ss")}
                          </td>
                        </tr>
                      );
                    })}
                  {inList === "disapperList" &&
                    data?.map((inData, index) => {
                      return (
                        <tr key={index}>
                          <td>{inData?.employee_name}</td>
                          <td>{`${inData?.working_starttime_string} ~ ${inData?.working_endtime_string}`}</td>
                        </tr>
                      );
                    })}
                  {inList === "vacationList" &&
                    data?.map((inData, index) => {
                      return (
                        <tr key={index}>
                          <td>{inData?.employee_name}</td>
                          <td>
                            {`${dayjs(inData?.vacation_start_time).format(
                              "YYYYMMDD"
                            )} ~ ${dayjs(inData?.vacation_end_time).format(
                              "YYYYMMDD"
                            )}`}
                          </td>
                        </tr>
                      );
                    })}
                </DataTable>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="check01-1 check01-3">
        <ul className="cal01 cal cf">
          <HoverScrollBox inList="workinList">
            <li className="work-t">
              <div className="clist">
                <p className="zoom_font">
                  <strong>{employeeInfo?.workin}</strong>
                  <em className="num">명</em>
                </p>
                <span className="zoom_font">출근인원</span>
              </div>
              <span className="icocal"></span>
            </li>
          </HoverScrollBox>
          <HoverScrollBox inList="lateinList">
            <li className="late">
              <div className="clist">
                <p className="zoom_font">
                  <strong>{employeeInfo?.latein}</strong>
                  <em className="num">명</em>
                </p>
                <span className="zoom_font">지각인원</span>
              </div>
              <span className="icocal"></span>
            </li>
          </HoverScrollBox>
          <HoverScrollBox inList="disapperList">
            <li className="absen">
              <div className="clist">
                <p className="zoom_font">
                  <strong>{employeeInfo?.disapper}</strong>
                  <em className="num">명</em>
                </p>
                <span className="zoom_font">미출근자</span>
              </div>
              <span className="icocal"></span>
            </li>
          </HoverScrollBox>
          <HoverScrollBox inList="vacationList">
            <li className="holly">
              <div className="clist">
                <p className="zoom_font">
                  <strong>{employeeInfo?.vacation}</strong>
                  <em className="num">명</em>
                </p>
                <span className="zoom_font">휴가인원</span>
              </div>
              <span className="icocal"> </span>
            </li>
          </HoverScrollBox>
        </ul>
      </div>
    </>
  );
};

export default CompanyInfo;
