import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { useElecActions } from "../../../recoil/api/elec";
import { PageInfoState } from "../../../recoil/types/data";
import { dateUtils, downloadFiles } from "../../../utils";
import BreadCrumb from "../BreadCrumb";
import DataTable from "../DataTable";
import PaginationBar from "../PaginationBar";
import SearchKeyword from "../SearchKeyword";
import { useDocformActions } from "../../../recoil/api/docform";
import Select from "react-select";

const Eplydoc = () => {
  const auth = useRecoilValue(authAtom);
  let navigate = useNavigate();
  const [isRetired, setIsRetired] = useState(false);
  const elecActions = useElecActions();
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [employeeList, setEmployeeList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const action = useDocformActions();
  const [keywordType, setKeyWordType] = useState("");
  const [loading, setLoading] = useState(false);

  const keyT = [
    {
      value: null,
      label: "전체",
    },
    {
      value: "name",
      label: "이름",
    },
    {
      value: "id",
      label: "아이디",
    },
  ];

  useEffect(() => {
    getCompanyJoinDocEmployeeList({
      pageInfo,
      keywordType,
      keyword,
      ishired: isRetired ? 0 : 1,
    });
  }, [pageInfo?.current, keyword, isRetired]);

  const getCompanyJoinDocEmployeeList = async (data) => {
    setLoading(true);
    setEmployeeList([]);
    const res = await elecActions.getCompanyJoinDocEmployeeList(data);
    if (res.status === 200) {
      setEmployeeList(res.data.list);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const navigateToList = (state) => {
    navigate(`/${auth.accountType}/joindoc`, {
      state,
    });
  };

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div className="btn-group btn-group-left">
            {/* zoom_font 재직자 안됨 */}
            <button
              className="primarytoggle-md border-line mouse zoom_font"
              onClick={() => setIsRetired(false)}
              disabled={!isRetired}
            >
              재직자
            </button>
            <button
              className="primarytoggle-md border-line mouse zoom_font"
              onClick={() => setIsRetired(true)}
              disabled={isRetired}
            >
              퇴직자
            </button>
          </div>
        </div>
        <div className="btn-group d-block">
          <div className="select-border-line">
            <Select
              options={keyT}
              placeholder={"검색조건"}
              onChange={(e) => {
                setKeyWordType(e.value);
              }}
            />
          </div>
          <SearchKeyword
            setKeyword={setKeyword}
            placeholder="이름 또는 아이디 입력"
          />
        </div>
      </div>
      <DataTable
        checkbox={false}
        loading={loading}
        columns={["회사명", "이름", "아이디", "입사일자", "상태", "다운로드"]}
      >
        {employeeList?.map((employee) => {
          return (
            <tr className="pointer" key={employee.sn}>
              <td
                className="border-line zoom_font"
                onClick={() => {
                  navigateToList({
                    COMP_CD: employee.COMP_CD,
                    esn: employee.esn,
                  });
                }}
              >
                {employee.companyname}
              </td>
              <td
                className="border-line zoom_font"
                onClick={() => {
                  navigateToList({
                    COMP_CD: employee.COMP_CD,
                    esn: employee.esn,
                  });
                }}
              >
                {employee.name}
              </td>
              <td
                className="border-line zoom_font"
                onClick={() => {
                  navigateToList({
                    COMP_CD: employee.COMP_CD,
                    esn: employee.esn,
                  });
                }}
              >
                {employee.id}
              </td>
              <td
                className="border-line zoom_font"
                onClick={() => {
                  navigateToList({
                    COMP_CD: employee.COMP_CD,
                    esn: employee.esn,
                  });
                }}
              >
                {employee.joindatetime !== "Invalid Date"
                  ? dateUtils.dateToYMD(employee.joindatetime)
                  : "-"}
              </td>
              <td
                onClick={() => {
                  navigateToList({
                    COMP_CD: employee.COMP_CD,
                    esn: employee.esn,
                  });
                }}
              >
                <button
                  className="blackbtn-sm border-line zoom_font"
                  style={{
                    backgroundColor: employee.joindoc_state ? "#22ccb7" : "",
                  }}
                >
                  {employee.joindoc_state ? "등록완료" : "미등록"}
                </button>
              </td>
              <td>
                {employee.joindoc_state ? (
                  <button
                    className="t-download border-line zoom_font"
                    onClick={() => {
                      downloadFiles(
                        employee,
                        action.getEployDocUrl,
                        { esn: employee.esn, COMP_CD: employee.COMP_CD },
                        `입사서류_${employee.name}`,
                        1
                      );
                    }}
                  />
                ) : (
                  <></>
                )}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default Eplydoc;
