import { useRecoilValue } from "recoil";
import { get, del, post, getPage, createWithUploadFile } from ".";
import { authAtom } from "..";

export const useBoardNoticeActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    getBoardNoticeList,
    getBoardNoticeView,
    getBoardNoticeFileUrl,
    getBoardNoticeCompressFileUrl,
    createBoardNotice,
    updateBoardNotice,
    deleteBoardNotice,
    deleteBoardNoticeFile,
  };

  async function getBoardNoticeList(data, url = `select/noticeList`) {
    return getPage(
      url,
      auth.user.isAdmin
        ? data
        : auth.user.isCompany
        ? {
            ...data,
            COMP_CD: auth.user.COMP_CD,
          }
        : auth.user.isEmployee && {
            ...data,
            id: auth.user.id,
          }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getBoardNoticeView(data, url = `select/notice`) {
    return get(url, { ...data })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getBoardNoticeFileUrl(data) {
    const req = {
      ...data,
    };
    return await get(`select/notice/attach`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getBoardNoticeCompressFileUrl(req) {
    return await post(`getCompressFiles`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function createBoardNotice(
    data,
    url = `${auth.accountType}/notice/write`
  ) {
    const req = auth.user.isAdmin
      ? {
          ...data,
          id: auth.user.id,
        }
      : auth.user.isCompany && {
          ...data,
          companyId: auth.user.id,
        };
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(url, req);
      return res;
    } else {
      // 파일이 없는 경우
      const res = await post(url, req);
      return res;
    }
  }

  async function updateBoardNotice(
    data,
    url = `${auth.accountType}/notice/update`
  ) {
    const req = auth.user.isAdmin
      ? {
          ...data,
          id: auth.user.id,
        }
      : auth.user.isCompany && {
          ...data,
          companyId: auth.user.id,
        };
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(url, req, undefined, "post", true);
      return res;
    } else {
      // 파일이 없는 경우
      const res = await post(url, {
        ...req,
        url: data.prevFiles.filter((ele) => ele),
        prevFiles: null,
      });
      return res;
    }
  }

  //   async function updateWorkGuide(data) {
  //     //파일이 있을 경우
  //     if (data.file.length) {
  //       const res = await createWithUploadFile(
  //         `${auth.accountType}/workguide/update`,
  //         {
  //           ...data,
  //           id: auth.user.id,
  //         }
  //       );

  //       return res;
  //     } else {
  //       const req = {
  //         ...data,
  //         id: auth.user.id,
  //       };
  //       const res = await post(`${auth.accountType}/workguide/update`, req);
  //       return res;
  //     }
  //   }

  async function deleteBoardNotice(data) {
    await deleteAllBoardNoticeFiles(data);
    return await del(`delete/notice`, { docsn: data.docsn })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deleteAllBoardNoticeFiles(data) {
    const res = await getBoardNoticeFileUrl(data);
    const fileUrlData = res.data;
    console.log("file : ", fileUrlData);
    for (let i = 0; i < fileUrlData.length; i++) {
      const req = { sn: fileUrlData[i].sn };
      await deleteBoardNoticeFile(req);
    }
  }

  async function deleteBoardNoticeFile(req) {
    await del(`delete/notice/attach`, req)
      .then((res) => {
        console.log("file delete : ", res);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
};
