import { useEffect, useState } from "react";

const FindPhone = ({ setPhone }) => {
  const [vPhone, setVphone] = useState();

  useEffect(() => {
    setPhone(vPhone);
  });

  return (
    <div className="id_input lotable">
      <input
        type="number"
        maxLength={11}
        placeholder="휴대폰번호를 -없이 입력해주세요."
        className="form-control form-control-sm"
        onInput={(e) => {
          e.target.value = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");
        }}
        onChange={(e) => {
          setVphone(e.target.value);
        }}
      />
    </div>
  );
};

export default FindPhone;
