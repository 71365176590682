import { useRecoilValue } from "recoil";
import { get, del, post, getPage, createWithUploadFile } from ".";
import { authAtom } from "..";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const useWorkReportActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    createWorkReport,
    updateWorkReport,
    getWorkReportList,
    getWorkReportView,
    getWorkReportFileUrl,
    getWorkReportCompressFileUrl,
    deleteWorkReport,
    deleteWorkReportFile,
  };

  async function createWorkReport(
    data,
    url = `${auth.accountType}/workreport/regist`,
    check = "doc"
  ) {
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(
        url,
        auth.user.isEmployee && {
          ...data,
          id: auth.user.id,
        },
        check
      );
      return res;
    } else {
      // 파일이 없는 경우
      const req = auth.user.isEmployee && {
        ...data,
        id: auth.user.id,
      };
      const res = await post(url, req);
      return res;
    }
  }

  async function updateWorkReport(
    data,
    url = `${auth.accountType}/workreport/update`,
    check = "doc"
  ) {
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(
        url,
        auth.user.isAdmin
          ? {
              ...data,
              id: data.employ_id,
            }
          : auth.user.isEmployee && {
              ...data,
              id: auth.user.id,
            },
        check,
        "post",
        true
      );

      return res;
    } else {
      // 파일이 없는 경우
      const req = auth.user.isAdmin
        ? {
            ...data,
            url: data.prevFiles.filter((ele) => ele),
            prevFiles: null,
            id: data.employ_id,
          }
        : auth.user.isEmployee && {
            ...data,
            url: data.prevFiles.filter((ele) => ele),
            prevFiles: null,
            id: auth.user.id,
          };
      const res = await post(url, req);
      return res;
    }
  }

  async function getWorkReportList(req) {
    return getPage(
      `${auth.accountType}/workreport`,
      auth.user.isEmployee
        ? {
            ...req,
            id: auth.user.id,
          }
        : auth.user.isCompany
        ? {
            ...req,
            companyId: auth.user.id,
          }
        : auth.user.isAdmin && req
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkReportView(req) {
    const res = get(
      `select/workreport`,
      req
      // auth.user.isCompany ? { ...req, isCompany: 1 } : req
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return res;
  }

  async function getWorkReportFileUrl(data) {
    const req = {
      ...data,
    };
    return await get(`select/workreport/attachlink`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkReportCompressFileUrl(req) {
    return await post(`getCompressFiles`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deleteWorkReport(data) {
    await deleteAllWorkReportFiles(data);

    return await del(`delete/workreport`, { docsn: data.docsn })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deleteWorkReportFile(req) {
    // await del(`delete/workreportattach`, req)
    //   .then((res) => {
    //   })
    //   .catch((err) => {
    //     throw err;
    //   });
  }

  async function deleteAllWorkReportFiles(data) {
    const res = await getWorkReportFileUrl(data);
    const fileUrlData = res.data;

    for (let i = 0; i < fileUrlData.length; i++) {
      const req = { sn: fileUrlData[i].sn };
      await deleteWorkReportFile(req);
    }
  }
};
