import { useEffect, useState } from "react";
import Select from "react-select";
import useManagementActions from "../../../recoil/api/management";
import { PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchKeyword from "../../common/SearchKeyword";
import EmployeeDetailModal from "./EmployeeDetailModal";
import CompanyDetailModal from "./CompanyDetailModal";
import { getXlsxDownloadHref } from "../../../utils";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import { useRecoilState } from "recoil";
import { USERGRADE } from "../../../JSON/enum/Dataenum";
import { authAtom } from "../../../recoil";
import CommonCode from "../settings/CommonCode";
import HrmFunction from "../../common/hrm/HrmFunction";
import CommonModal from "../../modal/CommonModal";
import { EditModalContentApprove } from "./EmployeeManage";

const Management = () => {
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [userList, setUserList] = useState([]);
  const [isEmployee, setIsEmployee] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [targetEmployee, setTargetEmployee] = useState();
  const [isCompanySettingModalOpen, setIsCompanySettingModalOpen] =
    useState(false);
  const managementActions = useManagementActions();
  const [employeeDetail, setEmployeeDetail] = useState({});
  const [companyDetail, setCompanyDetail] = useState({});
  const [ishired, setIshired] = useState(null);
  const accountType = useAccoutTypeActions();
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [company, setCompany] = useState({});
  const [auth, setAuth] = useRecoilState(authAtom);
  const hrmFn = HrmFunction();

  useEffect(() => {
    if (!isSettingModalOpen && !isCompanySettingModalOpen) {
      getManagement();
    }
  }, [
    ishired,
    pageInfo?.current,
    isEmployee,
    keyword,
    isSettingModalOpen,
    isCompanySettingModalOpen,
    company?.COMP_CD,
  ]);

  const getManagementAccountGradeType = (value) => {
    if (value === USERGRADE.BAD) {
      return auth.commonCode[4]["불량회원"];
    } else if (value === USERGRADE.GOOD) {
      return auth.commonCode[3]["우수회원"];
    } else {
      return auth?.commonCode[2]["일반회원"];
    }
  };

  const managementAccountGrade = [
    { value: USERGRADE.BAD, label: auth?.commonCode[4]["불량회원"] },
    { value: USERGRADE.NORMAL, label: auth?.commonCode[2]["일반회원"] },
    { value: USERGRADE.GOOD, label: auth?.commonCode[3]["우수회원"] },
  ];

  const getDetail = async (sn) => {
    setLoading(true);
    if (isEmployee) {
      const res = await managementActions.getEmployeeDetail({
        esn: sn,
      });
      setEmployeeDetail(res.data);
    } else {
      const res = await managementActions.getCompanyDetail({
        id: sn,
      });
      setCompanyDetail(res.data);
    }
    setLoading(false);
  };

  const getManagement = async () => {
    setLoading(true);
    if (isEmployee) {
      const res = await managementActions.getManagementEmployee({
        pageInfo,
        keyword,
        ishired,
        COMP_CD: company?.COMP_CD,
      });
      setUserList(res.data.list);
      setPageInfo(res.data.pageinfo);
    } else {
      const res = await managementActions.getManagementCompany({
        pageInfo,
        keyword,
      });
      setUserList(res.data.list);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const changeUserState = async (sn, isapprove, ishired, user) => {
    setLoading(true);
    if (isEmployee) {
      if (isapprove && !ishired) {
        setEditModalOpen(true);
        setTargetEmployee(user);
      } else {
        const res = await managementActions.changeUserState({
          userList: [
            {
              esn: [sn],
              isapprove: isapprove,
              ishired: ishired,
            },
          ],
        });
        if (res?.status === 400) {
          alert(res.data);
        } else if (res?.status === undefined) {
          alert("에러입니다.");
        }
      }
    } else {
      await managementActions.changeCompanyState({
        userList: [
          {
            id: sn,
            isapprove: isapprove,
          },
        ],
      });
    }
    setLoading(false);
    getManagement();
  };

  const putGradeChange = async (grade, esn) => {
    setLoading(true);
    const res = await managementActions.putGradeChange({
      grade: grade,
      esn: esn,
    });

    if (res.status === 200) {
      // setReasons(res.data.workstamp);
      // setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div
            className="btn-group btn-group-left"
            style={{ pointerEvents: loading === true ? "none" : "" }}
          >
            <button
              className="primarybtn-md border-line mouse zoom_font"
              onClick={() => setIsEmployee(true)}
            >
              일반 회원
            </button>
            <button
              className="holidaybtn bluebtn border-line mouse zoom_font"
              onClick={() => {
                setIsEmployee(false);
                setPageInfo({
                  ...pageInfo,
                  current: 1,
                });
              }}
            >
              기업 회원
            </button>
            <button
              className="download border-line mouse zoom_font"
              type="button"
              onClick={async () =>
                await getXlsxDownloadHref({
                  api: isEmployee
                    ? "admin/management/employee"
                    : "admin/management/company",
                  params: {
                    page: 1,
                    count: 10000,
                    keyword,
                    ishired,
                    COMP_CD: company?.COMP_CD,
                    isxlsx: 1,
                  },
                  name: "회원관리",
                })
              }
            ></button>
          </div>
        </div>
        <div className="all-group">
          {/* {isEmployee && (
            <button
              style={{ marginRight: "5px" }}
              className="secondarybtn-md border-line zoom_font"
              type="button"
              onClick={() => {
                setIsSearchCompanyModalOpen(true);
              }}
            >
              {company?.company_name || "기업 선택"}
            </button>
          )} */}
          <div className="btn-group-left btn-group-inline">
            {isEmployee && (
              <div className="select-border-line">
                <Select
                  placeholder="근무 여부"
                  options={hrmFn.workOrNotOptions()}
                  onChange={(e) => setIshired(e.value)}
                />
              </div>
            )}
            <SearchKeyword
              setKeyword={setKeyword}
              placeholder="이름, 아이디, 회사명 입력"
            />
          </div>
        </div>
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={
          isEmployee
            ? [
                "아이디",
                "이름",
                "회사명",
                "휴대폰",
                "직무",
                "등급",
                "승인여부",
                "메모",
                "근무여부",
              ]
            : [
                "아이디",
                "이름",
                "회사명",
                "휴대폰",
                "직무",
                "등급",
                "승인여부",
                "메모",
              ]
        }
      >
        {userList?.map((user) => {
          return (
            <tr
              style={
                user.isapprove
                  ? { backgroundColor: "" }
                  : { backgroundColor: "#E6E6E6" }
              }
              className="pointer"
              key={user.esn || user.csn}
              onClick={() => {
                if (user.esn) {
                  setIsSettingModalOpen(true);
                } else if (user.csn) {
                  setIsCompanySettingModalOpen(true);
                }
                getDetail(user.esn || user.company_id);
              }}
            >
              <td className="border-line zoom_font">
                {user.employee_id || user.company_id}
              </td>
              <td className="border-line zoom_font">
                {user.employee_name || user.name}
              </td>
              <td className="border-line zoom_font">
                {user.companyname || user.company_name}
              </td>
              <td className="border-line zoom_font">
                {user.employee_tel || user.tel}
              </td>
              <td className="border-line zoom_font">
                {user.department ? user.department : "N"}
              </td>
              <td onClick={(e) => e.stopPropagation()}>
                {
                  <Select
                    placeholder={getManagementAccountGradeType(user.grade)}
                    options={managementAccountGrade}
                    onChange={(e) => {
                      putGradeChange(e.value, user.esn);
                      setTimeout(() => {
                        getManagement();
                      }, 100);
                    }}
                  ></Select>
                }
              </td>
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  className="border-line"
                  type="checkbox"
                  checked={user.isapprove}
                  onChange={() => {
                    user.esn
                      ? changeUserState(
                          user.esn,
                          !!!user.isapprove,
                          user.ishired,
                          user
                        )
                      : changeUserState(user.company_id, !user.isapprove);
                  }}
                />
              </td>
              <td className="border-line zoom_font">
                {user.memo?.trim() ? "Y" : "N"}
              </td>
              {isEmployee && (
                <td onClick={(e) => e.stopPropagation()}>
                  <input
                    className="border-line"
                    type="checkbox"
                    checked={user.ishired}
                    // onChange={() => {
                    //   changeUserState(
                    //     user.esn,
                    //     user.isapprove,
                    //     !!!user.ishired
                    //   );
                    // }}
                  />
                </td>
              )}
            </tr>
          );
        })}
      </DataTable>
      {!loading && (
        <EmployeeDetailModal
          open={isSettingModalOpen}
          setOpen={setIsSettingModalOpen}
          employeeDetail={employeeDetail}
          setEmployeeDetail={setEmployeeDetail}
          loading={loading}
        />
      )}
      {!loading && (
        <CompanyDetailModal
          open={isCompanySettingModalOpen}
          setOpen={setIsCompanySettingModalOpen}
          companyDetail={companyDetail}
          loading={loading}
        />
      )}
      <CommonModal
        title="근로자 정보"
        open={editModalOpen}
        setOpen={setEditModalOpen}
      >
        {targetEmployee && (
          <EditModalContentApprove
            setOpen={setEditModalOpen}
            getManagement={getManagement}
            targetEmployee={targetEmployee}
          />
        )}
      </CommonModal>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setIsSearchCompanyModalOpen(false);
          setCompany(company);
        }}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default Management;
