import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import BreadCrumb from "../BreadCrumb";
import { usePopupActions } from "../../../recoil/api/popup";
import Draggable from "react-draggable";

const PopupEdit = () => {
  const params = useParams();
  const navigate = useNavigate();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);

  // 팝업 상태 값
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPositionX, setDeltaPositionX] = useState(0);
  const [deltaPositionY, setDeltaPositionY] = useState(0);
  const [prevDeltaPositionX, setPrevDeltaPositionX] = useState(0);
  const [prevDeltaPositionY, setPrevDeltaPositionY] = useState(0);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(300);

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };
  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const handleDrag = (e, ui) => {
    const x = deltaPositionX;
    const y = deltaPositionY;
    setDeltaPositionX(x + ui.deltaX);
    setDeltaPositionY(y + ui.deltaY);
  };

  const [isLocationSettingOrPreview, setIsLocationSettingOrPreview] =
    useState();

  // 체크박스 대상 상태 값
  const [isCheckedEmployee, setIsCheckedEmployee] = useState(false);
  const [isCheckedCompany, setIsCheckedCompany] = useState(false);
  const [isCheckedAdmin, setIsCheckedAdmin] = useState(false);

  // API 호출 함수 - 팝업 내용 조회
  const popupActions = usePopupActions();

  const loadPopupView = async (req) => {
    popupActions.getPopupView(req).then((res) => {
      if (res?.data) {
        setValue("title", res.data?.title);
        setValue("starttime", dayjs(res.data?.starttime).format("YYYY-MM-DD"));
        setValue("endtime", dayjs(res.data?.endtime).format("YYYY-MM-DD"));
        setValue("contents", res.data?.contents);
        setDeltaPositionX(res.data?.x);
        setDeltaPositionY(res.data?.y);
        setPrevDeltaPositionX(res.data?.x);
        setPrevDeltaPositionY(res.data?.y);
        setWidth(res.data?.width);
        setHeight(res.data?.high);
        setIsCheckedAdmin(
          res.data?.target?.toString().includes(ACCOUNTTYPE.ADMIN)
        );
        setIsCheckedCompany(
          res.data?.target?.toString().includes(ACCOUNTTYPE.COMPANY)
        );
        setIsCheckedEmployee(
          res.data?.target?.toString().includes(ACCOUNTTYPE.EMPLOYEE)
        );
        setEditorLoadedState(res.data?.contents);
      }
    });
  };

  const [editorLoadedState, setEditorLoadedState] = useState(false);

  // useEffect
  useEffect(() => {
    loadPopupView({ sn: params.sn });
  }, []);

  // useForm
  const { register, handleSubmit, setValue, watch, control } = useForm();

  // Submit 함수
  const onSubmit = async (data) => {
    const req = {
      sn: params.sn,
      title: data.title,
      starttime: dayjs(data.starttime).format("YYYYMMDD"),
      endtime: dayjs(data.endtime).format("YYYYMMDD"),
      x: deltaPositionX.toString(),
      y: deltaPositionY.toString(),
      width: width.toString(),
      high: height.toString(),
      target: (
        (isCheckedAdmin ? ACCOUNTTYPE.ADMIN : "").toString() +
        (isCheckedCompany ? ACCOUNTTYPE.COMPANY : "").toString() +
        (isCheckedEmployee ? ACCOUNTTYPE.EMPLOYEE : "").toString()
      ).toString(),
      contents: data.contents ?? editorLoadedState,
    };

    if (req.title && req.starttime && req.endtime && req.target) {
      const res = await popupActions.updatePopup(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate(`/${auth.accountType}/popup`);
      } else {
        setToast({ ...toast, error: true, message: "작성 실패" });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
    }
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="writetable hide">
          <tbody>
            <tr>
              <th className="text-center-title zoom_font">제목</th>
              <td className="w-30">
                <input
                  {...register("title")}
                  className="write-input border-line mouse zoom_font"
                  placeholder="제목 입력"
                />
              </td>

              <th className="text-center-title zoom_font">유지기간</th>
              <td className="w-30">
                <div style={{ display: "flex" }}>
                  <input
                    {...register("starttime")}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                  />
                  <div style={{ margin: 10 }}> - </div>
                  <input
                    {...register("endtime")}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">위치설정</th>
              <td>
                <div style={{ display: "flex" }}>
                  <button
                    className="holidaybtn greenbtn border-line mouse zoom_font"
                    type="button"
                    onClick={() => {
                      setIsModalOpened(true);
                      setIsLocationSettingOrPreview("LOCATION_SETTING");
                    }}
                  >
                    설정
                  </button>
                  <div
                    style={{ margin: 10 }}
                  >{`(${deltaPositionX}, ${deltaPositionY})`}</div>
                </div>
              </td>
              <th className="text-center-title zoom_font">크기</th>
              <td>
                <div style={{ display: "flex" }}>
                  <input
                    className="write-date border-line mouse zoom_font"
                    placeholder="width"
                    type="number"
                    style={{ textAlign: "center" }}
                    onChange={(e) => {
                      setWidth(e.target.value);
                    }}
                    value={width}
                  />
                  <div style={{ margin: 10 }}> -</div>
                  <input
                    className="write-date border-line mouse zoom_font"
                    placeholder="height"
                    type="number"
                    style={{ textAlign: "center" }}
                    onChange={(e) => {
                      setHeight(e.target.value);
                    }}
                    value={height}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">대상</th>
              <td colSpan="5">
                <div style={{ display: "flex" }}>
                  <div style={{ margin: 10, display: "flex" }}>
                    <div
                      id="employee_checkbox"
                      className={`chkbox border-line mouse ${
                        isCheckedEmployee ? "checked" : null
                      }`}
                      onClick={() => {
                        setIsCheckedEmployee(!isCheckedEmployee);
                      }}
                    ></div>
                    <label htmlFor="employee_checkbox">근로자</label>
                  </div>
                  <div style={{ margin: 10, display: "flex" }}>
                    <div
                      id="company_checkbox"
                      className={`chkbox border-line mouse ${
                        isCheckedCompany ? "checked" : null
                      }`}
                      onClick={() => {
                        setIsCheckedCompany(!isCheckedCompany);
                      }}
                    ></div>
                    <label htmlFor="company_checkbox">기업</label>
                  </div>
                  <div style={{ margin: 10, display: "flex" }}>
                    <div
                      id="admin_checkbox"
                      className={`chkbox border-line mouse ${
                        isCheckedAdmin ? "checked" : null
                      }`}
                      onClick={() => {
                        setIsCheckedAdmin(!isCheckedAdmin);
                      }}
                    ></div>
                    <label htmlFor="admin_checkbox">관리자</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">내용</th>
              <td colSpan="5">
                {editorLoadedState && (
                  <Controller
                    name="contents"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Editor
                        apiKey={
                          "pwlbxd7c7x9lapnd8nwqidzm2gre8bazisoovnitdbojymrp"
                        }
                        value={value}
                        onEditorChange={onChange}
                        init={{
                          plugins: ["table", "link", "autolink", "contextmenu"],
                          language: "ko_KR",
                          selector: "div",
                          elementpath: false,
                          branding: false,
                          autolink: true,
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                          contextmenu: "true",
                          menubar: "file insert view format table",
                        }}
                      />
                    )}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-group">
          <button
            className="writebtn  bluebtn border-line mouse zoom_font"
            type="button"
            onClick={(e) => {
              setIsLocationSettingOrPreview("PREVIEW");
              setIsModalOpened(true);
            }}
          >
            미리보기
          </button>
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>
          <Link to={`/${auth.accountType}/popup`}>
            <button
              className="writebtn cancelbtn border-line mouse zoom_font"
              type="button"
            >
              취소
            </button>
          </Link>
        </div>
      </form>
      <Draggable
        handle=".popup-box"
        onDrag={handleDrag}
        {...dragHandlers}
        position={{ x: deltaPositionX, y: deltaPositionY }}
      >
        <div
          className="popup-box"
          style={{
            display: isModalOpened ? "block" : "none",
            width: `${width}px`,
            height: `${height}px`,
            overflow: "auto",
          }}
        >
          {isLocationSettingOrPreview === "LOCATION_SETTING" && (
            <>
              <div
                style={{
                  margin: 30,
                  display: "center",
                }}
              >
                {`(${deltaPositionX}, ${deltaPositionY})`}
              </div>
              <div className="btn-group">
                <button
                  className="writebtn submitbtn border-line mouse zoom_font"
                  type="button"
                  onClick={() => {
                    setPrevDeltaPositionX(deltaPositionX);
                    setPrevDeltaPositionY(deltaPositionY);
                    setIsModalOpened(false);
                  }}
                >
                  저장
                </button>

                <button
                  className="writebtn cancelbtn border-line mouse zoom_font"
                  type="button"
                  onClick={() => {
                    setDeltaPositionX(prevDeltaPositionX);
                    setDeltaPositionY(prevDeltaPositionY);
                    setIsModalOpened(false);
                  }}
                >
                  취소
                </button>
              </div>
            </>
          )}

          {isLocationSettingOrPreview === "PREVIEW" && (
            <>
              <div
                style={{
                  margin: 30,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  overflow: "auto",
                }}
              >
                <div id="popup-content">
                  <div
                    className="zoom_font_id"
                    dangerouslySetInnerHTML={{ __html: watch("contents") }}
                  />
                </div>
              </div>
              <div id="popup-bottom" style={{ display: "fixed" }}>
                <button>오늘 하루동안 보지 않기</button>
                <button
                  onClick={() => {
                    setIsModalOpened(false);
                  }}
                >
                  X
                </button>
              </div>
            </>
          )}
        </div>
      </Draggable>
    </div>
  );
};

export default PopupEdit;
