import { createContext } from "react";
import socketio from "socket.io-client";

const CHAT_SERVER = () => {
  if (window.location.hostname === "localhost") {
    return `http://${process.env.REACT_APP_CHAT_SERVER}:${process.env.REACT_APP_CHAT_PORT}`;
  } else {
    return `https://${window.location.hostname}`;
  }
};

export const socket = socketio(CHAT_SERVER(), {
  transports: ["websocket"],
  timeout: 600000,
  autoConnect: true,
});

export const SocketContext = createContext(socket);
