import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  DetailMailQuery,
  DetailSnAtom,
  MailListUpdateAtom,
  MailUserListQuery,
  MailViewAtom,
} from "../../../recoil";
import File from "../../../img/pop-atta.png";
import { MAIL_VIEW } from "./MailLayout";
import { FileContent } from "./SentMailDetail";
import { useEffect } from "react";
import { useMailActions } from "../../../recoil/api/mail";
import { useRecoilState } from "recoil";
import DOMPurify from "dompurify";

const ReceivedMailDetail = () => {
  const mailActions = useMailActions();
  const detailMail = useRecoilValue(
    DetailMailQuery({ open: true, readType: true })
  );
  const setView = useSetRecoilState(MailViewAtom);
  const setMailUserList = useSetRecoilState(MailUserListQuery());
  const [mailUserList] = useRecoilState(MailUserListQuery());
  const sn = useRecoilValue(DetailSnAtom);
  const mailListUpdate = useSetRecoilState(MailListUpdateAtom);

  useEffect(() => {
    if (detailMail?.isread === 0) {
      readMail();
    }
  });

  const readMail = async () => {
    const res = await mailActions.readMail(sn);
    if (res.status === 200) {
      mailListUpdate((update) => update + 1);
    }
  };

  if (detailMail === undefined) {
    alert("퇴사자입니다.");
    setView(MAIL_VIEW.RECEIVED_MAIL);
  }

  return (
    <div id="message_detail" className="poimt sendmess">
      <div className="sendcont">
        <div className="tit cf">
          <div className="name">
            <span>보낸사람 : </span>
            <strong
              title={`${detailMail?.sender_name}(${detailMail?.sender_id}_${detailMail?.sender_company})`}
            >{`${detailMail?.sender_name}(${detailMail?.sender_id}_${detailMail?.sender_company})`}</strong>
          </div>
          <div className="date">
            <span>{detailMail?.createtime}</span>
          </div>
          <div>&nbsp;</div>
        </div>
        <div
          className="tit cf"
          style={{
            background: "#eee",
            color: "gray",
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <div>{detailMail?.title}</div>
        </div>
        <div className="cont">
          <div
            className="zoom_font_id ql-editor"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(detailMail?.contents),
            }}
          ></div>
        </div>
      </div>
      <div>
        <div className="searchee">
          {detailMail?.file_contents ? (
            <FileContent file_contents={detailMail?.file_contents} />
          ) : (
            <a>
              <img src={File} />
              첨부파일이 없습니다.
            </a>
          )}
        </div>
      </div>
      <div className="btn-group">
        <button
          type="button"
          className="primarybtn-lg"
          onClick={async () => {
            setView(MAIL_VIEW.SEND_MAIL);
            // 받은 쪽지함에 들어올때마다 setMailUserList러 유저가 늘어나서 초기화 시켜주고 셋해야함
            mailUserList.map((mailUser) => {
              let user = { ...mailUser };
              if (user.isSelected === true) {
                setMailUserList({ user });
              }
            });
            setMailUserList({
              user: {
                id: detailMail?.sender_id,
                name: detailMail?.sender_name,
              },
            });
          }}
        >
          답장
        </button>
        <button
          className="graybtn-lg"
          onClick={() => setView(MAIL_VIEW.RECEIVED_MAIL)}
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default ReceivedMailDetail;
