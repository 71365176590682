import { useState } from "react";
import Select from "react-select";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import CommonModal from "../../modal/CommonModal";
import BreadCrumb from "../BreadCrumb";
import SearchByDate from "../SearchByDate";
import SearchCompanyModal from "../SearchCompanyModal";
import ScheduleDetailTable from "./ScheduleDetailTable";
import ScheduleRegisteration from "./ScheduleRegistration";
import XlsxDownload from "../../form/XlsxDownload";
import { DateState } from "../../../recoil/types/data";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { useNavigate } from "react-router-dom";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import { getKeyByValue } from "../../../utils";
import ScheduleFunction from "./ScheduleFunction";

const ScheduleList = () => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const [date, setDate] = useState(DateState);
  const accountType = useAccoutTypeActions();
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [company, setCompany] = useState({});
  const [type, setType] = useState(null);
  const onChangeRegistrationModalState = () => {
    setIsRegistrationModalOpen(false);
    const newDate = { ...date };
    setDate(newDate);
  };

  const scFn = ScheduleFunction();

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group" style={{ marginBottom: "10px" }}>
          <button
            type="button"
            className="holidaybtn greenbtn zoom_font border-line"
            onClick={() => {
              navigate(
                `/${getKeyByValue(
                  ACCOUNTTYPE,
                  auth.user.accountType
                ).toLowerCase()}/schedule`
              );
            }}
          >
            달력
          </button>
          <button
            className="holidaybtn greenbtn zoom_font border-line"
            onClick={() => setIsRegistrationModalOpen(true)}
          >
            일정등록
          </button>

          {accountType.isAdmin() && (
            <button
              className="holidaybtn greenbtn zoom_font border-line"
              type="button"
              onClick={() => setIsSearchCompanyModalOpen(true)}
            >
              {company.company_name || "회사 검색"}
            </button>
          )}
          <XlsxDownload
            api={`${auth.accountType}/schedule/list`}
            name="스케줄 리스트"
          />
        </div>
        <SearchByDate
          dateAndKeyword={date}
          placeholder="이름 입력"
          select={
            <Select
              placeholder="구분"
              options={scFn.scheduleListOptions()}
              onChange={(e) => setType(e.value)}
              className="border-line"
            />
          }
          onChange={(value) => {
            setDate(value);
          }}
        />
      </div>
      <ScheduleDetailTable type={type} date={date} COMP_CD={company.COMP_CD} />
      <CommonModal
        open={isRegistrationModalOpen}
        onChangeModalState={onChangeRegistrationModalState}
        title="일정 등록"
      >
        <ScheduleRegisteration
          onChangeModalState={onChangeRegistrationModalState}
        />
      </CommonModal>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setIsSearchCompanyModalOpen(false);
          setCompany(company);
        }}
      />
    </div>
  );
};

export default ScheduleList;
