import SearchByDate from "../SearchByDate";
import CommonModal from "../../modal/CommonModal";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import DataTable from "../DataTable";
import BreadCrumb from "../BreadCrumb";
import { useWorkArchiveActions } from "../../../recoil/api/workArchive";
import PaginationBar from "../PaginationBar";
import { useForm } from "react-hook-form";
import { EditorState } from "draft-js";
import dayjs from "dayjs";
import { downloadFiles } from "../../../utils";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { overMaxLength } from "../../../utils";
import { fileNameCheck, fileSizeCheck } from "../../../utils";

const WorkArchive = () => {
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);

  // 모달 상태 값
  const [isCheckWriteModalOpen, setIsCheckWriteModalOpen] = useState(false);

  const onChangeWriteModalState = () => {
    setIsCheckWriteModalOpen(false);
  };

  // 모달 상태 값
  const [isCheckEditModalOpen, setIsCheckEditModalOpen] = useState(false);

  const onChangeEditModalState = () => {
    setIsCheckEditModalOpen(false);
  };

  // 로딩 상태 값
  const [loading, setLoading] = useState(false);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // 날짜 검색 상태 값
  const [dateAndKeyword, setDateAndKeyword] = useState();

  // 페이지네이션 상태 값
  const [pageInfo, setPageInfo] = useState({
    current: 1,
  });

  // archive 데이터 상태 값
  const [loadedData, setLoadedData] = useState([]);

  // (모달) - 제목 상태 값
  const [title, setTitle] = useState("");

  // (모달) - 삭제할 파일 선택 상태 값
  const [filesSnListToDelete, setFilseSnListToDelete] = useState([]);

  // 체크 박스 상태 값
  const [checkedItemIds, setCheckedItemIds] = useState([]);

  // 체크 박스 핸들러
  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  // 파일 상태 값
  const [prevArchive, setPrevArchive] = useState([]);
  const [files, setFiles] = useState([]);
  const [toRemainPrevFilesIndex, setToRemainPrevFilesIndex] = useState([]);

  // 파일 핸들러
  const onchangeFilesHandler = (event) => {
    event.preventDefault();
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };

  // API 호출 함수 - archive 데이터 불러오기
  const workArchiveActions = useWorkArchiveActions();

  const loadWorkArchiveList = async () => {
    if (dateAndKeyword) {
      setLoading(true);

      workArchiveActions
        .getWorkArchiveList({
          dateAndKeyword,
          pageInfo,
        })
        .then((res) => {
          setLoading(false);
          if (res?.data) {
            setLoadedData(res.data.list);
            setPageInfo({ ...res.data.pageinfo, current: pageInfo.current });
          }
        });
    }
  };

  const loadWorkArchiveView = async (ele) => {
    const req = { sn: ele?.sn };
    workArchiveActions.getWorkArchiveView(req).then((res) => {
      if (res?.data) {
        setTitle(ele?.title);
        setPrevArchive(res.data);
        setToRemainPrevFilesIndex(Array(res?.data?.length).fill(true));
      }
    });
  };

  const deleteWorkArchiveFiles = async () => {
    const result = {
      status: 200,
    };
    for (let i = 0; i < filesSnListToDelete?.length; i++) {
      await workArchiveActions
        .deleteWorkArchive({
          docsn: filesSnListToDelete[i],
        })
        .then((res) => {
          if (res.status !== 200) {
            result.status = false;
          }
        });
    }
    return result;
  };

  // useEffect
  useEffect(() => {
    loadWorkArchiveList();
  }, [pageInfo.current, dateAndKeyword]);

  // API 요청 함수 - workArchive 데이터 삭제하기
  const deleteWorkArchives = async () => {
    for (let i = 0; i < checkedItemIds?.length; i++) {
      // 파일 없어도 deletefile api 호출하는 쪽으로 일단 작성
      workArchiveActions
        .deleteWorkArchive({ docsn: checkedItemIds[i] })
        .then((res) => {
          if (res.status === 200) {
            setToast({ ...toast, on: true });
            setCheckedItemIds([]);
            loadWorkArchiveList();
          }
        });
    }
  };

  // useForm
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      editor: EditorState.createEmpty(),
      workorderYMD: dayjs().format("YYYY-MM-DD"),
    },
  });

  // Submit 함수
  const onSubmit = async (data) => {
    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const req = {
      title: title,
      file: files,
    };
    if (req.title && files.length) {
      const res = await workArchiveActions.createWorkArchive(req);

      if (res.status === 200) {
        setToast({ ...toast, on: true });
        loadWorkArchiveList();
        setIsCheckWriteModalOpen(false);
        setTitle("");
        setFiles([]);
      } else {
        setToast({
          ...toast,
          error: true,
          message: "업로드 실패",
        });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
    }

    setDoubleclickLoading(false);
  };

  // 수정 시 함수
  const onEdit = async (data) => {
    const req = {
      docsn: prevArchive[0]?.archive_sn,
      title: title,
      file: files,
    };

    if (req.title) {
      const deleteFilesRes = await deleteWorkArchiveFiles();
      const updateFilesRes = await workArchiveActions.updateWorkArchive({
        docsn: prevArchive[0]?.archive_sn,
        title: title,
        file: files,
        prevFiles: prevArchive.map((prevFile, index) => {
          if (toRemainPrevFilesIndex[index]) {
            return {
              filename: prevFile?.filename,
              extension: prevFile?.extension,
              url: prevFile?.url,
            };
          }
        }),
      });

      const res = await workArchiveActions.getWorkArchiveView({
        sn: prevArchive[0]?.archive_sn,
      });
      if (res?.data?.length === 0) {
        await workArchiveActions
          .deleteWorkArchive({ docsn: prevArchive[0]?.archive_sn })
          .then((res) => {
            if (res.status === 200) {
              setToast({ ...toast, on: true });
              setCheckedItemIds([]);
              loadWorkArchiveList();
              setFilseSnListToDelete([]);
            }
          });
      } else if (
        deleteFilesRes?.status === 200 &&
        updateFilesRes?.status === 200
      ) {
        setToast({ ...toast, on: true });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
    }
    loadWorkArchiveList();
    setFiles([]);

    setIsCheckEditModalOpen(false);
  };

  // 렌더링
  return (
    <>
      <BreadCrumb />
      <div className="substanceCont">
        <div className="date-filtering">
          <div className="all-group">
            {/* 생성/삭제 버튼 - 관리자 계정일 때, 활성화 */}
            {auth.user.isAdmin && (
              <div>
                <button
                  className="topbtn write border-line zoom_font"
                  type="button"
                  onClick={() => {
                    setIsCheckWriteModalOpen(true);
                    setTitle("");
                    setFiles([]);
                  }}
                >
                  작성하기
                </button>
                <button
                  className="trashbtn border-line"
                  onClick={() => {
                    if (window.confirm("삭제하시겠습니까?")) {
                      deleteWorkArchives();
                    } else {
                      return;
                    }
                  }}
                />
              </div>
            )}
          </div>
          <SearchByDate
            placeholder="제목 입력"
            onChange={(value) => setDateAndKeyword(value)}
          />
        </div>
        <DataTable
          loadedData={loadedData}
          loading={loading}
          setCheckedItemIds={setCheckedItemIds}
          checkbox={auth.user.isAdmin}
          columns={["제목", "등록일", "첨부"]}
        >
          {loadedData?.map((ele) => (
            <tr
              className="text-center"
              key={ele?.sn}
              onClick={() => {
                if (auth.user.isAdmin) {
                  loadWorkArchiveView(ele);
                  setIsCheckEditModalOpen(true);
                  setFilseSnListToDelete([]);
                }
              }}
              style={{ cursor: auth.user.isAdmin ? "pointer" : null }}
            >
              {/* 체크 박스 - 관리 계정일 때, 활성화 */}
              {auth.user.isAdmin && (
                <td className="hide" onClick={(e) => e.stopPropagation()}>
                  <div className="agree">
                    <label>
                      <input
                        type="checkbox"
                        name="personinfo"
                        onChange={(e) => {
                          onChangeCheckBoxHandler(
                            e.currentTarget.checked,
                            ele?.sn
                          );
                        }}
                        checked={
                          checkedItemIds?.includes(ele?.sn) ? true : false
                        }
                      />
                      {checkedItemIds?.includes(ele?.sn) ? (
                        <div className="chkbox border-line mouse checked"></div>
                      ) : (
                        <div className="chkbox border-line mouse "></div>
                      )}
                    </label>
                  </div>
                </td>
              )}

              <td className="border-line zoom_font">
                {overMaxLength(ele?.title, 30)}
              </td>
              <td className="border-line zoom_font">
                {dayjs(ele?.createtime).format("YYYY-MM-DD")}
              </td>

              <td onClick={(e) => e.stopPropagation()}>
                <button
                  className="t-download border-line mouse zoom_font"
                  type="button"
                  onClick={() => {
                    downloadFiles(
                      ele,
                      workArchiveActions.getWorkArchiveFileUrl,
                      { sn: ele?.sn },
                      `자료실_`,
                      0
                    );
                  }}
                ></button>
              </td>
            </tr>
          ))}
        </DataTable>
        <PaginationBar
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          loading={loading}
        />
        {/* 파일 등록 모달 */}
        <CommonModal
          open={isCheckWriteModalOpen}
          onChangeModalState={onChangeWriteModalState}
          title="자료실 파일 등록"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <table className="writetable">
              <tbody>
                <tr>
                  <th className="text-center-title w-15 zoom_font">제목</th>
                  <td>
                    <input
                      id="title"
                      className="custom-input"
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="제목을 입력해주세요."
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title zoom_font">첨부파일</th>
                  <td>
                    <input
                      className="custom-input"
                      type="text"
                      placeholder="첨부파일을 등록해주세요."
                      readOnly
                    />
                    <label
                      style={{ overflow: "visible" }}
                      className="filter-btn zoom_font border-line"
                      htmlFor="input-file"
                    >
                      등록
                    </label>
                    <input
                      {...register("file")}
                      type="file"
                      multiple="multiple"
                      id="input-file"
                      onChange={onchangeFilesHandler}
                      style={{ display: "none" }}
                    />
                    {files.length > 0 &&
                      files.map((ele) => {
                        return (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={() => {
                              if (window.confirm("삭제하시겠습니까?")) {
                                removeFile(ele.id);
                              } else {
                                return;
                              }
                            }}
                            key={ele.id}
                            type="text"
                            className="file-text"
                          >
                            {ele?.file?.name}
                            <FileDeleteBtn />
                          </div>
                        );
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="s-button cf">
              <button
                type="submit"
                id="archive_writing"
                className="s-save sc border-line zoom_font"
              >
                저장
              </button>
              <button
                onClick={() => {
                  setIsCheckWriteModalOpen(false);
                  setTitle("");
                  setFiles([]);
                }}
                type="button"
                className="s-cans sc border-line zoom_font"
              >
                취소
              </button>
            </div>
          </form>
        </CommonModal>
        {/* 파일 수정 모달 */}
        <CommonModal
          open={isCheckEditModalOpen}
          onChangeModalState={onChangeEditModalState}
          title="자료실 파일 수정"
        >
          <form onSubmit={handleSubmit(onEdit)}>
            <table className="writetable">
              <tbody>
                <tr>
                  <th className="text-center-title w-15">제목</th>
                  <td>
                    <input
                      id="title"
                      className="custom-input"
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="제목을 입력해주세요."
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-center-title">첨부파일</th>
                  <td>
                    <input
                      className="custom-input"
                      type="text"
                      placeholder="첨부파일을 등록해주세요."
                      readOnly
                    />
                    <label
                      style={{ overflow: "visible" }}
                      className="filter-btn zoom_font border-line"
                      htmlFor="input-file"
                    >
                      등록
                    </label>
                    <input
                      {...register("file")}
                      type="file"
                      multiple="multiple"
                      id="input-file"
                      onChange={onchangeFilesHandler}
                      style={{ display: "none" }}
                    />
                    {/* 이전에 저장된 파일 목록 */}
                    {prevArchive.map((ele, index) => {
                      return (
                        toRemainPrevFilesIndex[index] && (
                          <div
                            key={index}
                            id="prev-file"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            type="text"
                            className="file-text"
                            onClick={() => {
                              setToRemainPrevFilesIndex(() => {
                                const newIndexArray = [
                                  ...toRemainPrevFilesIndex,
                                ];
                                newIndexArray[index] = !newIndexArray[index];
                                return newIndexArray;
                              });
                              setFilseSnListToDelete([
                                ...filesSnListToDelete,
                                ele?.sn,
                              ]);
                            }}
                          >
                            {`${ele?.filename}.${ele?.extension}`}
                            <FileDeleteBtn />
                          </div>
                        )
                      );
                    })}

                    {/* 새로 업로드하는 파일 목록 */}
                    {files.length > 0 &&
                      files.map((ele) => {
                        return (
                          <div
                            key={ele.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              removeFile(ele.id);
                            }}
                            type="text"
                            className="file-text"
                          >
                            {ele?.file?.name}
                            <FileDeleteBtn />
                          </div>
                        );
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="s-button cf">
              <button
                type="submit"
                id="archive_writing"
                className="s-save sc"
                onClick={() => {
                  // deleteWorkArchiveFiles();
                  // onEdit();
                }}
              >
                수정
              </button>
              <button
                onClick={() => {
                  setIsCheckEditModalOpen(false);
                  setFilseSnListToDelete([]);
                }}
                type="button"
                className="s-cans sc"
              >
                취소
              </button>
            </div>
          </form>
        </CommonModal>
      </div>
    </>
  );
};

export default WorkArchive;
