import "../../css/payment-spinner.css";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { usePaymentActions } from "../../recoil/api/payment";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../recoil";
import CommonModal from "../modal/CommonModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import SearchCompanyModal from "../common/SearchCompanyModal";
import { SelectManagerPayment } from "../common/SelectManagerPayment";
import BreadCrumb from "../common/BreadCrumb";
import { NumericFormat } from "react-number-format";
// payment_edit/number

const PaymentInsert = () => {
  const params = useParams();
  const navigate = useNavigate();
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);

  // // 모달 상태 값
  // const onChangeModalState = () => {
  //   setIsCheckModalOpen(false);
  // };

  // 급여 양식 데이터 상태 값
  const [paymentFormData, setPaymentFormData] = useState();

  // 급여 상세 데이터 상태 값
  const [paymentView, setPaymentView] = useState([]);

  // 직접비, 간접비 상태 값
  const [totalPay, setTotalPay] = useState(0);
  const [totalDuty, setTotalDuty] = useState(0);
  const [total, setTotal] = useState(0);

  // 비고
  const [comment, setComment] = useState("");

  // (모달) - searchCompanyModal 상태 값
  const [company, setCompany] = useState({});

  // (모달) company 검색 상태 값
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);

  // (모달) member 선택 상태 값
  const onChangeSelectManagerModalState = () => {
    setIsSelectManagerModalOpen(false);
  };
  const [isSelectManagerModalOpen, setIsSelectManagerModalOpen] =
    useState(false);

  // // (모달) 멤버 검색 상태 값
  // const [keyword, setKeyword] = useState("");

  // const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  // const [members, setMembers] = useState([]);
  // const [esn, setEsn] = useState([]);
  // const [newEsn, setNewEsn] = useState([]);
  // const [name, setName] = useState([]);
  // const [newName, setNewName] = useState([]);

  // API 호출 함수 - 급여 양식 데이터 불러오기
  const paymentActions = usePaymentActions();
  const loadPaymentForms = async (data) => {
    const res = await paymentActions.getPaymentForms();

    if (res.status === 200) {
      setPaymentFormData(res?.data?.list);
    }
  };

  // API 호출 함수 - 급여 상세보기 데이터 불러오기
  const loadPaymentView = async (docsn) => {
    paymentActions.getPaymentView(docsn).then((res) => {
      if (res?.data) {
        setPaymentView(res.data);
      } else {
        setToast({
          ...toast,
          error: true,
          message: "데이터를 불러오지 못했습니다.",
        });
      }
    });
  };

  // useEffect
  useEffect(() => {
    setTotal(totalPay - totalDuty);
  }, [totalPay, totalDuty]);

  useEffect(() => {
    loadPaymentView({ sn: params.sn });
    loadPaymentForms();
  }, []);

  useEffect(() => {
    // // 급여 대상
    // setName([paymentView?.employee_name]);
    // setNewName([paymentView?.employee_name]);
    // setEsn([paymentView?.esn]);
    // setNewEsn([paymentView?.esn]);

    setValue("employee", {
      employee_name:
        paymentView?.employeeList === undefined
          ? undefined
          : paymentView?.employeeList[0]?.employee_name,
      esn:
        paymentView?.employeeList === undefined
          ? undefined
          : paymentView?.employeeList[0]?.esn,
    });

    setCompany({
      company_name: paymentView?.pay?.company_name,
      COMP_CD: paymentView?.pay?.COMP_CD,
    });

    // 지시일
    setValue("payday", dayjs(paymentView?.pay?.payday).format("YYYY-MM-DD"));
    // 직접비
    setValue("default_pay", Number(paymentView?.pay?.default_pay));
    setValue("traffic_pay", Number(paymentView?.pay?.traffic_pay));
    setValue("work_pay", Number(paymentView?.pay?.work_pay));
    setValue("cheif_pay", Number(paymentView?.pay?.cheif_pay));
    setValue("extra_pay", Number(paymentView?.pay?.extra_pay));
    setValue("vacation_pay", Number(paymentView?.pay?.vacation_pay));
    setValue("etc_pay", Number(paymentView?.pay?.etc_pay));
    setTotalPay(Number(paymentView?.pay?.total));
    // 간접비
    setValue("national_duty", Number(paymentView?.pay?.national_duty));
    setValue("hire_duty", Number(paymentView?.pay?.hire_duty));
    setValue("health_duty", Number(paymentView?.pay?.health_duty));
    setValue("industry_duty", Number(paymentView?.pay?.industry_duty));
    setValue("source_duty", Number(paymentView?.pay?.source_duty));
    setValue("income_duty", Number(paymentView?.pay?.income_duty));
    setValue("etc_duty", Number(paymentView?.pay?.etc_duty));
    setTotalDuty(Number(paymentView?.pay?.total_duty));
    setComment(paymentView?.pay?.comment);
  }, [paymentView]);

  // 총합 handleChange 함수
  const handleTotalPayChange = (e) => {
    setTotalPay(
      (parseInt(watch("default_pay")) || 0) +
        (parseInt(watch("traffic_pay")) || 0) +
        (parseInt(watch("work_pay")) || 0) +
        (parseInt(watch("cheif_pay")) || 0) +
        (parseInt(watch("extra_pay")) || 0) +
        (parseInt(watch("vacation_pay")) || 0) +
        (parseInt(watch("etc_pay")) || 0)
    );
  };

  const handleTotalDutyChange = (e) => {
    setTotalDuty(
      (parseInt(watch("national_duty")) || 0) +
        (parseInt(watch("hire_duty")) || 0) +
        (parseInt(watch("health_duty")) || 0) +
        (parseInt(watch("industry_duty")) || 0) +
        (parseInt(watch("source_duty")) || 0) +
        (parseInt(watch("income_duty")) || 0) +
        (parseInt(watch("etc_duty")) || 0)
    );
  };

  // useForm
  const { register, handleSubmit, setValue, watch, control } = useForm({
    defaultValues: {
      payday: dayjs().format("YYYY-MM-DD"),
      default_pay: 0,
      traffic_pay: 0,
      work_pay: 0,
      cheif_pay: 0,
      extra_pay: 0,
      vacation_pay: 0,
      etc_pay: 0,
      national_duty: 0,
      hire_duty: 0,
      health_duty: 0,
      industry_duty: 0,
      source_duty: 0,
      income_duty: 0,
      etc_duty: 0,
    },
  });

  // Submit 함수
  const onSubmit = async (data) => {
    if (
      data.default_pay === undefined ||
      data.traffic_pay === undefined ||
      data.work_pay === undefined ||
      data.cheif_pay === undefined ||
      data.extra_pay === undefined ||
      data.vacation_pay === undefined ||
      data.etc_pay === undefined ||
      data.national_duty === undefined ||
      data.hire_duty === undefined ||
      data.health_duty === undefined ||
      data.industry_duty === undefined ||
      data.source_duty === undefined ||
      data.income_duty === undefined ||
      data.etc_duty === undefined
    ) {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
      return;
    }

    const req = {
      sn: params.sn,
      esn: data.employee?.esn?.toString(),
      payday: dayjs(data.payday).format("YYYYMMDD"),
      default_pay: data.default_pay.toString(),
      traffic_pay: data.traffic_pay.toString(),
      work_pay: data.work_pay.toString(),
      cheif_pay: data.cheif_pay.toString(),
      extra_pay: data.extra_pay.toString(),
      vacation_pay: data.vacation_pay.toString(),
      etc_pay: data.etc_pay.toString(),
      national_duty: data.national_duty.toString(),
      hire_duty: data.hire_duty.toString(),
      health_duty: data.health_duty.toString(),
      industry_duty: data.industry_duty.toString(),
      source_duty: data.source_duty.toString(),
      income_duty: data.income_duty.toString(),
      etc_duty: data.etc_duty.toString(),
      comment: comment,
    };

    if (
      req.esn &&
      req.payday &&
      req.default_pay &&
      req.traffic_pay &&
      req.work_pay &&
      req.cheif_pay &&
      req.extra_pay &&
      req.vacation_pay &&
      req.etc_pay &&
      req.national_duty &&
      req.hire_duty &&
      req.health_duty &&
      req.industry_duty &&
      req.source_duty &&
      req.income_duty &&
      req.etc_duty &&
      total > 0
    ) {
      const res = await paymentActions.updatePayment(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate(`/${auth.accountType}/payment`);
      } else {
        setToast({ ...toast, error: true, message: "작성 실패" });
      }
    } else {
      if (total < 0) {
        return setToast({
          ...toast,
          error: true,
          message: "입력이 잘못되었습니다.",
        });
      }
      if (req.esn)
        setToast({
          ...toast,
          error: true,
          message: "급여 대상을 입력해주세요.",
        });
      else {
        setToast({
          ...toast,
          error: true,
          message:
            "입력값의 형식이 올바르지 않습니다. 정수만 입력할 수 있습니다.",
        });
      }
    }
  };

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <button type="button" style={{ display: "none" }} name="hidelnkbutton">
        이전페이지
      </button>
      <form
        id="save_form"
        className="insertform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="table-responsive">
          <table className="writetable pay hide">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font">급여 불러오기</th>
                <td className="w-20">
                  <div className="select_box type04">
                    <div className="box" style={{ width: "100%" }}>
                      <Select
                        options={paymentFormData?.map((ele) => ({
                          value: ele?.sn,
                          label: ele?.title,
                        }))}
                        onChange={(obj) => {
                          const newSelectedData = paymentFormData?.filter(
                            (ele) => ele.sn == obj?.value
                          )[0];
                          // 직접비
                          setValue("default_pay", newSelectedData?.default_pay);
                          setValue("traffic_pay", newSelectedData?.traffic_pay);
                          setValue("work_pay", newSelectedData?.work_pay);
                          setValue("cheif_pay", newSelectedData?.cheif_pay);
                          setValue("extra_pay", newSelectedData?.extra_pay);
                          setValue(
                            "vacation_pay",
                            newSelectedData?.vacation_pay
                          );
                          setValue("etc_pay", newSelectedData?.etc_pay);
                          setTotalPay(newSelectedData?.total);
                          // 간접비
                          setValue(
                            "national_duty",
                            newSelectedData?.national_duty
                          );
                          setValue("hire_duty", newSelectedData?.hire_duty);
                          setValue("health_duty", newSelectedData?.health_duty);
                          setValue(
                            "industry_duty",
                            newSelectedData?.industry_duty
                          );
                          setValue("source_duty", newSelectedData?.source_duty);
                          setValue("income_duty", newSelectedData?.income_duty);
                          setValue("etc_duty", newSelectedData?.etc_duty);
                          setTotalDuty(newSelectedData?.total_duty);
                        }}
                        placeholder="선택"
                      />
                    </div>
                  </div>
                </td>
                <th className="text-center-title zoom_font">급여 대상</th>
                {auth.user.isAdmin && (
                  <td className="w-20">
                    <input
                      className="custom-input border-line mouse zoom_font"
                      type="text"
                      style={{ width: "45%" }}
                      value={company?.company_name ?? ""}
                      readOnly
                    />
                    <button
                      className="holidaybtn greenbtn border-line mouse zoom_font"
                      onClick={() => {
                        setIsSearchCompanyModalOpen(true);
                      }}
                      type="button"
                    >
                      검색
                    </button>
                    {Object.keys(company).length !== 0 && (
                      <>
                        <input
                          className="custom-input border-line mouse zoom_font"
                          type="text"
                          style={{ width: "40%" }}
                          value={watch("employee")?.employee_name ?? ""}
                          readOnly
                        />
                        <button
                          className="holidaybtn greenbtn border-line mouse zoom_font"
                          onClick={() => {
                            setIsSelectManagerModalOpen(true);
                          }}
                          type="button"
                        >
                          대상자 검색
                        </button>
                      </>
                    )}
                    <div id="divhashlist"></div>
                  </td>
                )}
                {/* 급여 대상 선택 - 기업 계정일 때 */}
                {auth.user.isCompany && (
                  <td className="w-20">
                    <input
                      className="custom-input border-line mouse zoom_font"
                      type="text"
                      style={{ width: "40%" }}
                      value={watch("employee")?.employee_name ?? ""}
                      readOnly
                    />
                    <button
                      className="holidaybtn greenbtn border-line mouse zoom_font"
                      onClick={() => {
                        setIsSelectManagerModalOpen(true);
                      }}
                      type="button"
                    >
                      대상자 검색
                    </button>
                    <div id="divhashlist"></div>
                  </td>
                )}
                <th className="text-center-title zoom_font">지시일</th>
                <td className="w-20">
                  <input
                    {...register("payday")}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">입금</th>
                <td colSpan="5">
                  <table className="writetable">
                    <tbody>
                      <tr className="text-center-title">
                        <th className="zoom_font">항목명</th>
                        <th className="zoom_font">금액</th>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">기본급</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="default_pay"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalPayChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">교통비보조</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="traffic_pay"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalPayChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">업무수당</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="work_pay"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalPayChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">팀장수당</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="cheif_pay"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalPayChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">연장수당</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="extra_pay"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalPayChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">연차수당</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="vacation_pay"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalPayChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">기타수당</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="etc_pay"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalPayChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <th className="text-center-title zoom_font">입금 총액</th>
                <td colSpan="5">
                  <NumericFormat
                    thousandSeparator=","
                    allowNegative={false}
                    className="custom-input price total border-line mouse zoom_font"
                    style={{ width: "100%" }}
                    value={totalPay}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">공제내역</th>
                <td colSpan="5">
                  <table className="writetable">
                    <tbody>
                      <tr className="text-center-title">
                        <th className="zoom_font">항목명</th>
                        <th className="zoom_font">금액</th>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">국민연금</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="national_duty"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalDutyChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">고용보험</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="hire_duty"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalDutyChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">건강보험</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="health_duty"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalDutyChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">산재보험</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="industry_duty"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalDutyChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">갑근세</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="source_duty"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalDutyChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">소득세</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="income_duty"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalDutyChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="zoom_font">기타수당</th>
                        <td>
                          <div className="spinner-container">
                            <Controller
                              control={control}
                              name="etc_duty"
                              render={({ field }) => {
                                return (
                                  <NumericFormat
                                    value={field.value}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    maxLength={15}
                                    className="custom-input price border-line mouse zoom_font"
                                    style={{ width: "100%" }}
                                    onValueChange={(e) => {
                                      field.onChange(e.floatValue);
                                      handleTotalDutyChange(e.floatValue);
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">공제 총액</th>
                <td colSpan="5">
                  <NumericFormat
                    thousandSeparator=","
                    allowNegative={false}
                    className="custom-input price total border-line mouse zoom_font"
                    style={{ width: "100%" }}
                    value={totalDuty}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">실수령액</th>
                <td colSpan="5">
                  <NumericFormat
                    thousandSeparator=","
                    className="custom-input price total border-line mouse zoom_font"
                    style={{ width: "100%" }}
                    value={total}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">비고</th>
                <td colSpan="5">
                  <textarea
                    className="custom-input custom-input price"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    defaultValue={comment}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="writebtnbox">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>
          <Link to={`/${auth.accountType}/payment`}>
            <button
              className="writebtn cancelbtn border-line mouse zoom_font"
              type="button"
            >
              취소
            </button>
          </Link>
        </div>
      </form>
      {/* (모달) - 기업 검색, 관리자 계정에서 활성화 */}
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
          setValue("employee", {});
        }}
      />
      {/* (모달) - 대상자 검색 */}
      <CommonModal
        title="대상자"
        open={isSelectManagerModalOpen}
        onChangeModalState={onChangeSelectManagerModalState}
      >
        <SelectManagerPayment
          COMP_CD={auth.user.isAdmin && company?.COMP_CD}
          setValue={setValue}
          selectedEmployee={watch("employee")}
          open={isSelectManagerModalOpen}
          onChangeModalState={onChangeSelectManagerModalState}
        />
      </CommonModal>
    </div>
  );
};

export default PaymentInsert;
