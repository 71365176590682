import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import { authAtom } from "../../../recoil";
import CommonModal from "../../modal/CommonModal";
import VacationRuleDetail from "./VacationRuleDetail";
import { useHolidayActions } from "../../../recoil/api/holiday";

const CompanyVacationRule = () => {
  const today = dayjs().format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const holidayActions = useHolidayActions();
  const [activeRule, setActiveRule] = useState();
  const [ruleList, setRuleList] = useState([]);

  const [toast, setToast] = useRecoilState(toastAtom);
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [auth, setAuth] = useRecoilState(authAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  const getVacationActiveRule = async () => {
    const res = await holidayActions.getVacationActiveRule();
    if (res.status === 200) {
      setActiveRule(res.data[0]);
    } else if (res?.response?.status === 400) {
      setToast({ ...toast, error: true, message: res.response.data });
    } else {
      setToast({ ...toast, error: true, message: res.message });
    }
  };

  const getVacationRules = async () => {
    setLoading(true);
    const res = await holidayActions.getVacationRules({
      pageInfo,
    });
    if (res.status === 200) {
      setRuleList(res.data.list);
      setPageInfo(res.data.pageinfo);
    } else {
      setToast({ ...toast, error: true, message: res.message });
    }
    setLoading(false);
  };

  const onDelete = async () => {
    if (checkedItemIds.length === 0) {
      setToast({
        ...toast,
        error: true,
        message: "선택된 항목이 없습니다.",
      });
      return;
    }
    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);
    if (
      window.confirm(`선택된 ${checkedItemIds.length}건을 삭제하시겠습니까?`)
    ) {
      const res = await holidayActions.deleteVacationRules(checkedItemIds);
      if (res) {
        getVacationRules();
        setToast({ ...toast, on: true, message: "삭제되었습니다." });
      }
    }
    setDoubleclickLoading(false);
  };

  useEffect(() => {
    getVacationRules();
  }, [pageInfo?.current, isModalOpen]);

  useEffect(() => {
    getVacationActiveRule();
  }, []);

  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  return (
    <div>
      <BreadCrumb />
      <div id="vacation-rule">
        <h2 className="zoom_font">적용중인 연차 부여규칙</h2>
        <div style={{ display: "flex" }}>
          <div id="vacation-rule-box">
            <div className="vacation-rule-box zoom_font">
              <span className="zoom_font">적용시작일</span>
              <span className="user-time zoom_font">
                <span className="actrule">
                  {activeRule?.active_date
                    ? dayjs(activeRule?.active_date)?.format("YYYY-MM-DD")
                    : ""}
                </span>
                일
              </span>
            </div>
            <div className="vacation-rule-box zoom_font">
              <span className="zoom_font">부여기준일</span>
              <span className="user-time zoom_font">
                <span className="actrule">
                  {activeRule?.base_date === undefined
                    ? ""
                    : activeRule?.base_date === 0
                    ? "입사"
                    : "회계"}
                </span>
                일
              </span>
            </div>
            {/* 회계일 있을때만 표시 */}
            {activeRule?.fiscal_date && (
              <div className="vacation-rule-box zoom_font">
                <span className="zoom_font">회계일</span>
                <span className="user-time zoom_font">
                  <span className="actrule">
                    {activeRule?.fiscal_date || ""}
                  </span>
                  일
                </span>
              </div>
            )}
          </div>
          <div id="vacation-rule-box">
            <div className="vacation-rule-box zoom_font">
              <span className="zoom_font">부여일</span>
              <span className="user-time zoom_font">
                <span className="actrule">{activeRule?.grant_day || ""}</span>일
              </span>
            </div>
            {activeRule?.additional_base_year && (
              <div className="vacation-rule-box zoom_font">
                <span className="zoom_font">추가부여기준</span>
                <span className="user-time zoom_font">
                  <span className="actrule">
                    {activeRule?.additional_base_year || ""}
                  </span>
                  년 마다
                  <span style={{ marginLeft: "15px" }} className="actrule">
                    {activeRule?.additional_grant_day || ""}
                  </span>
                  일
                </span>
              </div>
            )}
            <div className="vacation-rule-box zoom_font">
              <span className="zoom_font">1일 기준</span>
              <span className="user-time zoom_font">
                <span className="actrule">
                  {activeRule?.day_standard || ""}
                </span>
                일
              </span>
            </div>
          </div>
          <div id="vacation-rule-box">
            <div className="vacation-rule-box zoom_font">
              <span className="zoom_font">연차촉진제도</span>
              <span
                className="user-time zoom_font actrule"
                style={{ marginRight: 0 }}
              >
                {activeRule?.is_promotion === undefined
                  ? ""
                  : activeRule.is_promotion === 1
                  ? "사용"
                  : "사용안함"}
              </span>
            </div>
            <div className="vacation-rule-box zoom_font">
              <span className="zoom_font">이월여부</span>
              <span
                className="user-time zoom_font actrule"
                style={{ marginRight: 0 }}
              >
                {activeRule?.is_carry === undefined
                  ? ""
                  : activeRule.is_carry === 1
                  ? "가능"
                  : "불가능"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ flex: 1, marginTop: 10 }}>
        <div className="date-filtering">
          <div className="btn-group">
            <button
              className="primarybtn-md border-line mouse zoom_font"
              type="submit"
              onClick={() => setIsModalOpen(true)}
            >
              추가하기
            </button>
            <button
              className="trashbtn border-line mouse"
              type="button"
              onClick={() => onDelete()}
            ></button>
          </div>
        </div>
        <DataTable
          setCheckedItemIds={setCheckedItemIds}
          loading={loading}
          loadedData={ruleList}
          columns={[
            "구분",
            "적용시작일",
            "부여기준일",
            "회계일",
            "부여일",
            "추가부여기준",
            "1일기준",
            "연차촉진제도",
            "이월여부",
          ]}
        >
          {ruleList?.map((rule, index) => {
            return (
              <tr key={rule.sn}>
                <td className="border-line zoom_font">
                  <div className="agree">
                    <label>
                      <input
                        type="checkbox"
                        name="personinfo"
                        onChange={(e) => {
                          onChangeCheckBoxHandler(
                            e.currentTarget.checked,
                            rule.sn
                          );
                        }}
                        checked={
                          checkedItemIds?.includes(rule.sn) ? true : false
                        }
                      />
                      {checkedItemIds?.includes(rule.sn) ? (
                        <div className="chkbox border-line mouse checked"></div>
                      ) : (
                        <div className="chkbox border-line mouse "></div>
                      )}
                    </label>
                  </div>
                </td>
                {/* <td>{index + 1 + (pageInfo.current - 1) * 8}</td> */}
                <td
                  style={{
                    color: rule.active_date > today ? "#22ccb7" : "",
                  }}
                >
                  {rule.active_date > today ? "예정" : "종료"}
                </td>
                <td
                  style={{
                    color: rule.active_date > today ? "#22ccb7" : "",
                  }}
                >
                  {dayjs(rule.active_date).format("YYYY-MM-DD")}
                </td>
                <td>{rule.base_date === 0 ? "입사일" : "회계일"}</td>
                <td>{rule.fiscal_date || "-"}</td>
                <td>{rule.grant_day + "일"}</td>
                <td>
                  {rule.additional_base_year !== null
                    ? rule.additional_base_year +
                      "년마다 " +
                      rule.additional_grant_day +
                      "일"
                    : "-"}
                </td>
                <td>{rule.day_standard + "일"}</td>
                <td>{rule.is_promotion === 0 ? "사용안함" : "사용"}</td>
                <td>{rule.is_carry === 0 ? "불가능" : "가능"}</td>
              </tr>
            );
          })}
        </DataTable>
        <p style={{ marginTop: 10 }}>{`총 : ${pageInfo?.totalCnt}건`}</p>
        <CommonModal
          title="연차규칙 추가"
          open={isModalOpen}
          setOpen={setIsModalOpen}
          width={900}
        >
          <VacationRuleDetail isReset={true} setIsModalOpen={setIsModalOpen} />
        </CommonModal>
        <PaginationBar
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CompanyVacationRule;
