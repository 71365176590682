import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import { useWorkstampActions } from "../../../recoil/api/workstamp";
import { getWorkstampType } from "../../common/calendar/CalendarLg";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import { SelectOneManager } from "../../common/SelectOneManager";
import CommonModal from "../../modal/CommonModal";
import { keeper } from "../../common/Schedule/ScheduleRegistrationDetail";

const InsertWorkstampModal = ({ setOpen, open, setAddSuccess }) => {
  const workstampActions = useWorkstampActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const { register, handleSubmit, watch, setValue, control, reset } = useForm();

  const [isSelectManagerModalOpen, setIsSelectManagerModalOpen] =
    useState(false);

  const onChangeSelectManagerModalState = () => {
    setIsSelectManagerModalOpen(false);
  };

  const [workDate, setWorkDate] = useState("");
  const [worktime, setWorktime] = useState(null);
  const [workingHours, setWorkingHours] = useState("");
  const [loading, setLoading] = useState(false);

  const [intime, setintime] = useState("");
  const [instate, setinstate] = useState("");
  const [outtime, setouttime] = useState("");
  const [outstate, setoutstate] = useState("");
  const [inreason, setinreason] = useState("");
  const [outreason, setoutreason] = useState("");
  const [instateInt, setinstateInt] = useState("");
  const [outstateInt, setoutstateInt] = useState("");

  // (모달) - searchCompanyModal 상태 값
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);

  const [company, setCompany] = useState({});

  const [selectedEmployee, setSelectedEmployee] = useState({});

  useEffect(() => {
    if (workDate) {
      setLoading(true);
      setWorktime("");
      getEmployeeWorktimeByYM(workDate);
      setLoading(false);
    }
  }, [workDate]);

  useEffect(() => {
    if (worktime && Object.keys(selectedEmployee).length && watch("type")) {
      setLoading(true);
      getEmployeeWorkstampByType({
        id: selectedEmployee?.id,
        nowtime: `${workDate} ${worktime}:00`,
        type: watch("type").value,
        esn: selectedEmployee?.esn,
      });
      setLoading(false);
    }
  }, [worktime, selectedEmployee, watch("type")]);

  const getInOutTime = (type, worktime) => {
    setLoading(true);
    getEmployeeWorkstampByType({
      id: selectedEmployee?.id,
      nowtime: `${workDate} ${worktime}:00`,
      type: type,
      esn: selectedEmployee?.esn,
    });
    setLoading(false);
  };

  const getEmployeeWorkstampByType = async (data) => {
    if (data.type === 2) {
      const res = await workstampActions.getEmployeeWorkstampByType(data);
      if (res.status === 200) {
        setinstate(getWorkstampType(parseInt(res.data.workstamp)));
        setinstateInt(res.data.workstamp);
      }
    } else if (data.type === 4) {
      const res = await workstampActions.getEmployeeWorkstampByType(data);
      if (res.status === 200) {
        setoutstate(getWorkstampType(parseInt(res.data.workstamp)));
        setoutstateInt(res.data.workstamp);
      }
    } else {
      const res = await workstampActions.getEmployeeWorkstampByType(data);
      if (res.status === 200) {
        setValue("status", res.data.workstamp);
      }
    }
  };

  const getEmployeeWorktimeByYM = async (date) => {
    const workDate = dayjs(date);
    const res = await workstampActions.getEmployeeWorktimeByYM({
      esn: selectedEmployee?.esn,
      COMP_CD: company.COMP_CD,
      year: workDate.year(),
      month: workDate.month() + 1,
    });
    if (res.status === 200) {
      setWorkingHours(
        `${res.data.working_starttime_string.slice(
          0,
          2
        )}:${res.data.working_starttime_string.slice(
          2,
          4
        )} ~ ${res.data.working_endtime_string.slice(
          0,
          2
        )}:${res.data.working_endtime_string.slice(2, 4)}`
      );
    }
  };

  useEffect(() => {
    if (open === false) {
      dataReset();
    }
  }, [open]);

  const dataReset = () => {
    setintime("");
    setinstate("");
    setouttime("");
    setoutstate("");
    setinreason("");
    setoutreason("");
    setinstateInt("");
    setoutstateInt("");
    setSelectedEmployee({});

    setOpen(false);
    setCompany({});
    setWorkDate("");
    setWorktime(null);
    setWorkingHours("");
    reset();
  };

  const onSubmit = async (data) => {
    if (company?.company_name === undefined) {
      setToast({ ...toast, error: true, message: "업체를 입력해 주세요." });
      return;
    }
    if (selectedEmployee?.employee_name === undefined) {
      setToast({ ...toast, error: true, message: "대상자를 입력해 주세요." });
      return;
    }
    if (workDate === "") {
      setToast({ ...toast, error: true, message: "근무시간을 입력해 주세요." });
      return;
    }
    if (intime === 7 || outstateInt === 7) {
      setToast({ ...toast, error: true, message: "출퇴근 시간이 아닙니다." });
      return;
    }
    if (instate === "지각 출근" && inreason === "") {
      setToast({ ...toast, error: true, message: "지각사유를 입력해 주세요." });
      return;
    }
    if (outstate === "초과 근무" && outreason === "") {
      setToast({
        ...toast,
        error: true,
        message: "초과근무사유를 입력해 주세요.",
      });
      return;
    }

    let params = {
      employee_id: selectedEmployee?.id,
      nowtime: {
        in: `${workDate} ${intime}:00`,
        out: `${workDate} ${outtime}:00`,
      },
      type: { in: instateInt, out: outstateInt },
      reason: { in: inreason, out: outreason },
    };

    if (instate !== "" && intime !== "" && outstate === "" && outtime === "") {
      // 출근만 할 때
      params = {
        employee_id: selectedEmployee?.id,
        nowtime: {
          in: `${workDate} ${intime}:00`,
        },
        type: { in: instateInt },
        reason: { in: inreason },
      };
    } else if (
      instate === "" ||
      outstate === "" ||
      intime === "" ||
      outtime === ""
    ) {
      setToast({
        ...toast,
        error: true,
        message: "출퇴근 시간을 입력해 주세요.",
      });
      return;
    }

    const res = await workstampActions.adminCreateWorkstamp({ ...params });
    if (res.status === 200) {
      if (data.status === res.data.workstamp) {
        dataReset();
        setToast({ ...toast, on: true });
        setAddSuccess(Math.floor(Math.random() * 100));
      }
    }
    if (res.status === 400) {
      setToast({ ...toast, error: true, message: "출퇴근 기록이 있습니다." });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="lotable">
          <tbody>
            <tr>
              <th className="text-center-title zoom_font">업체</th>
              <td>
                <div className="input-group">
                  <input
                    value={company.company_name || ""}
                    disabled
                    className="form-control form-control-sm border-line"
                    placeholder="업체를 검색해주세요."
                  />
                  <span
                    disabled={true}
                    className="input-group-btn"
                    onClick={() => setIsSearchCompanyModalOpen(true)}
                  >
                    <a className="border-line zoom_font">검색</a>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">대상자</th>
              <td>
                <div className="input-group">
                  <input
                    value={selectedEmployee?.employee_name || ""}
                    disabled
                    className="form-control form-control-sm border-line"
                    placeholder="대상자를 검색해주세요."
                  />
                  <span
                    className="input-group-btn"
                    onClick={() => setIsSelectManagerModalOpen(true)}
                  >
                    <a className="border-line zoom_font">검색</a>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">근무일</th>
              <td>
                <input
                  value={workDate}
                  className="border-line"
                  max={dayjs().format("YYYY-MM-DD")}
                  disabled={!company || !Object.keys(selectedEmployee).length}
                  type="date"
                  onChange={(e) => setWorkDate(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-center-title zoom_font"
                onChange={(e) => setWorktime(e.target.value)}
              >
                근무 시간
              </th>
              <td>
                <input
                  value={workingHours}
                  className="form-control form-control-sm border-line"
                  disabled
                ></input>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">출근시간</th>
              <td>
                <input
                  value={intime}
                  className="border-line"
                  type="time"
                  onChange={(e) => {
                    setintime(e.target.value);
                    getInOutTime(2, e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-center-title zoom_font"
                onChange={(e) => {
                  setinstate(e.target.value);
                  setinstateInt(e.target.value);
                }}
              >
                출근상태
              </th>
              <td>
                <input
                  value={instate}
                  className="form-control form-control-sm border-line"
                  disabled
                ></input>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">퇴근시간</th>
              <td>
                <input
                  value={outtime}
                  className="border-line"
                  type="time"
                  onChange={(e) => {
                    setouttime(e.target.value);
                    getInOutTime(4, e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-center-title zoom_font"
                onChange={(e) => {
                  setoutstate(e.target.value);
                  setoutstateInt(e.target.value);
                }}
              >
                퇴근상태
              </th>
              <td>
                <input
                  value={outstate}
                  className="form-control form-control-sm border-line"
                  disabled
                ></input>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">지각사유</th>
              <td>
                <input
                  disabled={instateInt === 3 ? false : true}
                  value={inreason}
                  className="form-control form-control-sm border-line"
                  onChange={(e) => {
                    setinreason(e.target.value);
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">초과근무사유</th>
              <td>
                <input
                  disabled={outstateInt === 5 ? false : true}
                  value={outreason}
                  className="form-control form-control-sm border-line"
                  onChange={(e) => {
                    setoutreason(e.target.value);
                  }}
                ></input>
              </td>
            </tr>
            {/* <tr>
              <th className="text-center-title zoom_font">구분</th>
              <td>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={[
                        {
                          value: null,
                          label: "전체",
                        },
                        { value: "2", label: "출근" },
                        { value: "4", label: "퇴근" },
                      ]}
                      placeholder="선택"
                    />
                  )}
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">등록시간</th>
              <td>
                <input
                  className="border-line"
                  type="time"
                  onChange={(e) => setWorktime(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">상태</th>
              <td>
                <input
                  {...register("status")}
                  value={getWorkstampType(parseInt(watch("status"))) || ""}
                  disabled
                  className="form-control form-control-sm border-line"
                />
              </td>
            </tr> */}
          </tbody>
        </table>
        <div id="modalfoot">
          <div className="btn-group">
            <button
              // hidden={
              //   watch("status") === "12" ||
              //   !watch("status") ||
              //   !worktime ||
              //   !workDate
              // }
              className="fbtn submitbtn border-line zoom_font"
              type="submit"
            >
              저장
            </button>
            <button
              className="fbtn cancelbtn border-line zoom_font"
              type="button"
              onClick={() => {
                dataReset();
              }}
            >
              취소
            </button>
          </div>
        </div>
      </form>
      <SearchCompanyModal
        title="업체 검색"
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      ></SearchCompanyModal>
      <CommonModal
        title={`${keeper} 검색`}
        open={isSelectManagerModalOpen}
        onChangeModalState={onChangeSelectManagerModalState}
      >
        <SelectOneManager
          COMP_CD={company.COMP_CD}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          open={isSelectManagerModalOpen}
          onChangeModalState={onChangeSelectManagerModalState}
        />
      </CommonModal>
    </>
  );
};

export default InsertWorkstampModal;
