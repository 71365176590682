import { useEffect } from "react";

const PaginationBar = ({
  pageInfo = { current: 1, total: 1 },
  setPageInfo,
  loading = false,
}) => {
  useEffect(() => {
    if (Number(pageInfo.current) > Number(pageInfo.total)) {
      setPageInfo({ ...pageInfo, current: "1" });
    }
  }, [pageInfo.total]);

  const PageList = ({ pageInfo, setPageInfo }) => {
    const startNumber = parseInt((pageInfo.current - 1) / 5) * 5 + 1;

    const result = [];

    for (let i = 0; i <= 4; i++) {
      if (startNumber + i <= pageInfo.total) {
        result.push(
          <li className="border_dd pointer" key={i}>
            {Number(pageInfo.current) === startNumber + i ? (
              <a style={{ color: "white", backgroundColor: "#BCBCBC" }}>
                {startNumber + i}
              </a>
            ) : (
              <a
                onClick={() => {
                  setPageInfo({
                    ...pageInfo,
                    current: String(startNumber + i),
                  });
                }}
              >
                {startNumber + i}
              </a>
            )}
          </li>
        );
      }
    }

    return result;
  };

  return (
    <div
      className="pagerow cf"
      style={{ pointerEvents: loading === true ? "none" : "" }}
    >
      <div className="row-r text-center">
        <ul className="cf border-line">
          <li className="pa2 border_dd pointer" value="1">
            <a
              onClick={() => {
                setPageInfo({ ...pageInfo, current: "1" });
              }}
            >
              &lt;&lt;
            </a>
          </li>
          <li
            className="pa1 border_dd pointer"
            value="{{ pagination.previous }}"
          >
            <a
              onClick={() => {
                if (pageInfo.current > 1) {
                  setPageInfo({
                    ...pageInfo,
                    current: String(Number(pageInfo.current) - 1),
                  });
                }
              }}
            >
              &lt;
            </a>
          </li>
          <PageList pageInfo={pageInfo} setPageInfo={setPageInfo} />
          <li className="pa4 border_dd pointer" value="{{ pagination.next }}">
            <a
              onClick={() => {
                if (Number(pageInfo.current) < Number(pageInfo.total)) {
                  setPageInfo({
                    ...pageInfo,
                    current: String(Number(pageInfo.current) + 1),
                  });
                }
              }}
            >
              &gt;
            </a>
          </li>
          <li className="pa3 border_dd pointer" value="{{ pagination.max }}">
            <a
              onClick={() => {
                setPageInfo({
                  ...pageInfo,
                  current: pageInfo?.total === "0" ? "1" : pageInfo.total,
                });
              }}
            >
              &gt;&gt;
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PaginationBar;
