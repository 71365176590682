import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import useManagementActions from "../../../recoil/api/management";
import CommonModal from "../../modal/CommonModal";
import useMainActions from "../../../recoil/api/main";
import { useSetRecoilState } from "recoil";
import { menuAtom } from "../../../recoil";

const CommonUpdateModal = (props) => {
  const manager = useManagementActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({
      ...props.common,
    });
  }, [props]);

  const onSubmit = async (data) => {
    if (data.text.trim() === "") {
      data.text = data.name;
    }
    const req = {
      ...data,
      etc: data.etc === "" ? " " : data.etc === null ? " " : data.etc,
    };
    const res = await manager.updateMenuSettings(req);
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      props.setOpen(false);
      reset();
      getSettingMenu();
    }
  };

  const setMenu = useSetRecoilState(menuAtom);
  const mainActions = useMainActions();
  const getSettingMenu = async () => {
    const res = await mainActions.getSettingMenu();
    const parentMenu = res.data
      .filter((m) => {
        if (m.upper_menu_sn === 0) {
          return true;
        }
        return false;
      })
      .map((n) => {
        return {
          ...n,
          submenu: [],
          subshow: false,
        };
      });
    const childMenu = res.data.filter((m) => {
      if (m.upper_menu_sn !== 0) {
        return true;
      }
      return false;
    });
    for (let cm of childMenu) {
      parentMenu
        .find((x) => {
          return x.sn === cm.upper_menu_sn;
        })
        .submenu.push(cm);
    }
    setMenu(parentMenu);
  };

  return (
    <CommonModal {...props}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="lotable">
          <tbody>
            <tr>
              <th className="text-center-title zoom_font">상위메뉴</th>
              <td>
                <div className="input-group">
                  <input
                    style={{ width: "100%" }}
                    {...register("catagory", { required: true })}
                    disabled
                    className="form-control border-line"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">하위메뉴</th>
              <td>
                <div className="input-group">
                  <input
                    style={{ width: "100%" }}
                    {...register("name", { required: true })}
                    disabled
                    className="form-control border-line"
                    placeholder="코드명을 입력하세요."
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">표시명</th>
              <td>
                <div className="input-group">
                  <input
                    style={{ width: "100%" }}
                    {...register("text")}
                    className="form-control border-line"
                    placeholder="페이지에 표시할 이름을 입력하세요."
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">비고</th>
              <td>
                <div className="work-view">
                  <textarea
                    className="custom-input"
                    style={{ border: "2px solid #eeeeee", outline: "none" }}
                    {...register("etc")}
                  ></textarea>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="modalfoot">
          <div className="btn-group">
            <button
              className="fbtn submitbtn border-line zoom_font"
              type="submit"
            >
              저장
            </button>
            <button
              className="fbtn cancelbtn border-line zoom_font"
              type="button"
              onClick={() => {
                props.setOpen(false);
              }}
            >
              취소
            </button>
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default CommonUpdateModal;
