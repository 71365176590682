import { atom } from "recoil";

export const WorkstampTypeAtom = atom({
  key: "WorkstampTypeAtom",
  default: 0,
});

export const ReasonAtom = atom({
  key: "ReasonAtom",
  default: "",
});

export const ReasonModalAtom = atom({
  key: "ReasonModalAtom",
  default: false,
});

export const ReasonModalTextAtom = atom({
  key: "ReasonModalTextAtom",
  default: "",
});

export const WorkstampTimeAtom = atom({
  key: "WorkstampTimeAtom",
  default: "",
});

export const DreamCafeModalAtom = atom({
  key: "DreamCafeModalAtom",
  default: false,
});
