import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import CommonModal from "../../modal/CommonModal";
import React, { useState } from "react";
import ScheduleSubmitFile from "../Schedule/ScheduleSubmitFile";
import { List } from "react-content-loader";
import ScheduleCheckFile from "../Schedule/ScheduleCheckFile";
import dayjs from "dayjs";
import { useAccoutTypeActions } from "../../../recoil/api/auth";

const HomeScheduleCardInfo = ({ item, color, getScheduleDocsList }) => {
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isFileCheckModalOpen, setIsFileCheckModalOpen] = useState(false);
  const accountType = useAccoutTypeActions();

  if (accountType.isEmployee()) {
    return (
      <>
        <button
          className="w-100 main-req-list main-req-tablet-main"
          style={{ background: color, borderRadius: "12px" }}
          onClick={() => setIsSubmitModalOpen(true)}
        >
          <div style={{ textAlign: "left" }}>
            <h4 className="main-docs-title" style={{ color: "#fff" }}>
              {item.docs_name}
            </h4>
          </div>
          <div style={{ minWidth: "58px" }}>
            <h4 className="main-docs-title" style={{ color: "#fff" }}>
              D-{dayjs(item.end_date).diff(dayjs(), "day") + 1}
            </h4>
            <p
              style={{
                fontSize: "10px",
                lineHeight: "12px",
                color: "#fff",
                marginTop: "10px",
              }}
            >
              {dayjs(item.end_date).format("YYYY-MM-DD")}
            </p>
          </div>
        </button>

        <CommonModal
          title={"제출하기"}
          open={isSubmitModalOpen}
          setOpen={setIsSubmitModalOpen}
        >
          {isSubmitModalOpen && (
            <React.Suspense fallback={<List />}>
              <ScheduleSubmitFile
                isSubmitModalOpen={setIsSubmitModalOpen}
                getScheduleDocsList={getScheduleDocsList}
                item={item}
              />
            </React.Suspense>
          )}
        </CommonModal>
      </>
    );
  }

  return (
    <>
      <div style={{ display: "flex", marginTop: "12px" }}>
        <div
          style={{
            borderRadius: "12px 0 0 12px",
            background: color,
            width: "12px",
          }}
        ></div>
        <button
          className="w-100 main-req-list"
          onClick={() => setIsFileCheckModalOpen(true)}
        >
          <div style={{ textAlign: "left" }}>
            <h4 className="main-docs-title">
              {accountType.isAdmin()
                ? `${item?.req_docs_name[0]} ${
                    item?.req_docs_name.length !== 1
                      ? `외 ${item?.req_docs_name.length - 1}건`
                      : ""
                  }`
                : item?.req_docs_name}
            </h4>
            {accountType.isAdmin() && <h5>{item?.company_name}</h5>}
            <h5 className="main-docs-req">요청일: {item?.start_time}</h5>
          </div>
          <div style={{ minWidth: "58px" }}>
            <h4 className="main-docs-title" style={{ color: color }}>
              D-{dayjs(item.end_time).diff(dayjs(), "day") + 1}
            </h4>
            <p
              style={{
                fontSize: "10px",
                lineHeight: "12px",
                color: color,
                marginTop: "10px",
              }}
            >
              {item.end_time}
            </p>
          </div>
        </button>
      </div>
      <CommonModal
        title={"제출 파일 확인"}
        open={isFileCheckModalOpen}
        setOpen={setIsFileCheckModalOpen}
      >
        {isFileCheckModalOpen && (
          <React.Suspense fallback={<List />}>
            <ScheduleCheckFile
              docsData={item}
              getScheduleDocsList={getScheduleDocsList}
              setOpen={setIsFileCheckModalOpen}
            />
          </React.Suspense>
        )}
      </CommonModal>
    </>
  );
};

export default HomeScheduleCardInfo;
