import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { authAtom } from "../../../recoil";
import { useRecoilValue } from "recoil";
import { downloadFile } from "../../../utils";
import { useBoardBenefitActions } from "../../../recoil/api/boardBenefit";
import BreadCrumb from "../BreadCrumb";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

const BoardBenefitView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 파일 URL 상태 값
  const [fileUrlData, setFileUrlData] = useState([]);

  //   boardBenefit 상세 데이터 상태 값
  const [boardBenefitData, setBoardBenefitData] = useState();

  // API 호출 함수 - boardBenefitView 데이터 불러오기
  const boardBenefitActions = useBoardBenefitActions();

  const loadBoardBenefitView = async (docsn) => {
    const res = await boardBenefitActions.getBoardBenefitView(docsn);
    if (res.status === 200) {
      setBoardBenefitData(() => res.data);
    }

    if (res.data.url) {
      const res = await boardBenefitActions.getBoardBenefitFileUrl(docsn);
      setFileUrlData(res.data);
    }
  };

  // useEffect
  useEffect(() => {
    loadBoardBenefitView({ docsn: params.sn });
  }, []);

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form id="save_form" method="get" className="workform">
        <div className="table-responsive">
          <table className="writetable">
            <tbody>
              <tr>
                <th className="text-center-title w-15 zoom_font">작성자</th>
                <td className="zoom_font">{boardBenefitData?.writer}</td>
                <th className="text-center-title w-15 zoom_font">작성일</th>
                <td className="zoom_font">
                  {dayjs(boardBenefitData?.createtime).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">제목</th>
                <td className="zoom_font" colSpan="3">
                  {boardBenefitData?.title}
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="3" className="content-td-height">
                  <div
                    className="zoom_font_id ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(boardBenefitData?.contents),
                    }}
                  ></div>
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">첨부파일</th>
                <td colSpan="3">
                  {/* props 변경을 감지해야 리랜더링 됨 */}
                  {fileUrlData?.map((value, index) => (
                    <div key={index}>
                      <a
                        onClick={() => downloadFile(value)}
                        style={{ cursor: "pointer" }}
                      >
                        {`${value?.filename}.${value?.extension}`}
                      </a>
                      <br />
                    </div>
                  ))}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <div className="writebtnbox">
        {auth.user.isAdmin && (
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="button"
            onClick={() =>
              navigate(`/${auth.accountType}/benefits_edit/${params.sn}`)
            }
          >
            수정
          </button>
        )}

        <button
          className="writebtn cancelbtn border-line mouse zoom_font"
          type="button"
          onClick={() =>
            navigate(`/${auth.accountType}/benefits`, {
              state: { page: state?.page, keyword: state?.keyword, prev: true },
            })
          }
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default BoardBenefitView;
