import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { useDocformActions } from "../../../recoil/api/docform";
import {
  getApprovalDocDetailType,
  getApprovalDocType,
} from "../Approval/ApprovalDetailModal";
import BreadCrumb from "../BreadCrumb";
import DataTable from "../DataTable";

const DocumentForm = () => {
  const auth = useRecoilValue(authAtom);
  const [docFormList, setDocFormList] = useState([]);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const docformActions = useDocformActions();

  useEffect(() => {
    getDocFormList();
  }, []);

  const getDocFormList = async () => {
    setLoading(true);
    const res = await docformActions.getDocFormList();
    setDocFormList(res.data);
    setLoading(false);
  };

  return (
    <>
      <BreadCrumb />
      <DataTable
        checkbox={false}
        loading={loading}
        columns={["양식구분", "양식타입", "관리"]}
        isResponsive={false}
      >
        {docFormList?.map((t) => {
          return (
            <tr className="pointer" key={t.sn}>
              <td className="border-line zoom_font">
                {getApprovalDocType(t.type)}
              </td>
              <td className="border-line zoom_font">
                {getApprovalDocDetailType(t.type)}
              </td>
              <td className="border-line zoom_font">
                <button
                  className="primarytoggle-md border-line mouse zoom_font"
                  style={{ backgroundColor: "#22ccb7" }}
                  onClick={() =>
                    navigate(
                      `/${auth.accountType}/docform_write/${t.type}/${t.sn}`
                    )
                  }
                >
                  보기
                </button>
              </td>
            </tr>
          );
        })}
      </DataTable>
    </>
  );
};

export default DocumentForm;
