import CommonModal from "../../modal/CommonModal";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useWorkOrderActions } from "../../../recoil/api/workOrder";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { SelectManagerList } from "../SelectManagerList";
import { Editor } from "@tinymce/tinymce-react";
import { fileNameCheck, fileSizeCheck } from "../../../utils";
import { QuillEditor } from "../../toolbar/QuillEditor";

const WorkOrderEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useRecoilValue(authAtom);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const onChangeModalState = () => {
    setIsCheckModalOpen(false);
  };
  const onChangeSelectManagerModalState = () => {
    setIsSelectManagerModalOpen(false);
  };
  const [isSelectManagerModalOpen, setIsSelectManagerModalOpen] =
    useState(false);
  const [company, setCompany] = useState({});
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [selectedEmployeeEsnList, setSelectedEmployeeEsnList] = useState([]);
  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };
  const workOrderActions = useWorkOrderActions();
  const loadEmployeeByCompany = async (data) => {
    await workOrderActions.searchEmployeeByCompany(data);
  };
  const [keyword, setKeyword] = useState("");
  const [name, setName] = useState([]);
  const [newName, setNewName] = useState([]);
  const [esn, setEsn] = useState([]);
  const [newEsn, setNewEsn] = useState([]);
  const [members, setMembers] = useState([]);
  const [workOrderData, setWorkOrderData] = useState();
  const [prevFiles, setPrevFiles] = useState([]);
  const [toRemoveFilesId, setToRemoveFilesId] = useState([]);
  const [files, setFiles] = useState([]);
  const [toRemainPrevFilesIndex, setToRemainPrevFilesIndex] = useState([]);
  const onchangeFilesHandler = (event) => {
    event.preventDefault();
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  const loadPrevFiles = async (docsn) => {
    const res = await workOrderActions.getWorkOrderFileUrl(docsn);
    setPrevFiles(res.data);
    setToRemainPrevFilesIndex(Array(res?.data?.length).fill(true));
  };

  const loadWorkOrderView = async (docsn) => {
    const res = await workOrderActions.getWorkOrderView(docsn);

    if (res.status === 200) {
      setWorkOrderData(() => res?.data);
      setSelectedEmployeeList([
        {
          employee_name: res?.data?.name[0].employee_name,
          esn: res?.data?.name[0].esn,
          id: res?.data?.name[0].id,
        },
      ]);
      setSelectedEmployeeEsnList([res?.data?.name[0].esn]);
      setName([res?.data?.name[0].employee_name]);
      setEsn([res?.data?.name[0].esn]);
      setNewEsn(esn);
      setNewName(name);
      setValue("title", res?.data?.work_order_title);
      setValue("editor", res?.data?.work_order_contents);
      setValue(
        "workorderYMD",
        dayjs(res?.data?.work_order_ymd).format("YYYY-MM-DD")
      );
    }
  };

  useEffect(() => {
    loadWorkOrderView({ docsn: params.sn, esn: state.esn });
    loadEmployeeByCompany({ id: auth.accountType });
    loadPrevFiles({ docsn: params.sn });
  }, []);

  // useForm
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      editor: "",
      workorderYMD: dayjs().format("YYYY-MM-DD"),
    },
  });

  const onSubmit = async (data) => {
    // 파일 삭제 API 호출
    for (let i = 0; i < toRemoveFilesId.length; i++) {
      await workOrderActions.deleteWorkOrderFile({
        sn: toRemoveFilesId[i],
      });
    }

    const req = {
      docsn: params.sn,
      employ_id: selectedEmployeeList?.map((ele) => ele?.id),
      title: data.title,
      esn: esn,
      workorderYMD: dayjs(data.workorderYMD).format("YYYYMMDD"),
      contents: data.editor,
      file: files,
      prevFiles: prevFiles.map((prevFile, index) => {
        if (toRemainPrevFilesIndex[index]) {
          return {
            filename: prevFile?.filename,
            extension: prevFile?.extension,
            url: prevFile?.url,
          };
        }
      }),
    };

    if (req.title && req.workorderYMD && req.esn.length) {
      const res = await workOrderActions.updateWorkOrder(req);

      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate("/company/workorder");
      } else {
        setToast({
          ...toast,
          error: true,
          message: "업로드 실패",
        });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
    }
  };

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table className="writetable hide">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font">대상자</th>
                <td className="w-20">
                  <input
                    className="custom-input border-line mouse zoom_font"
                    type="text"
                    style={{ width: "70%" }}
                    value={selectedEmployeeList.map(
                      (obj) => obj?.employee_name
                    )}
                    readOnly
                  />
                  <button
                    disabled={state.edit}
                    className="holidaybtn greenbtn border-line mouse zoom_font"
                    onClick={() => {
                      setNewName(name);
                      setNewEsn(esn);
                      setIsSelectManagerModalOpen(true);
                    }}
                    type="button"
                  >
                    검색
                  </button>
                  <div id="divhashlist"></div>
                </td>
                <th className="text-center-title zoom_font">지시자</th>
                <td className="w-20 zoom_font" id="writer">
                  {auth.user.userName}
                </td>
                <th className="text-center-title zoom_font">지시일</th>
                <td className="w-20">
                  <input
                    {...register("workorderYMD")}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">제목</th>
                <td colSpan="5">
                  <input
                    {...register("title")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="제목을 입력해주세요."
                    defaultValue={workOrderData?.work_order_title}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="5">
                  <Controller
                    name="editor"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <QuillEditor value={value} onChange={onChange} />

                      // <Editor
                      //   apiKey={"v6ji2nwc9qbq1zat0pwg4o4kb9tco1wnad8oh2xdplkg6q9z"}
                      //   value={value}
                      //   onEditorChange={onChange}
                      //   init={{
                      //     plugins: ["table", "link", "autolink", "contextmenu"],
                      //     language: "ko_KR",
                      //     selector: "div",
                      //     elementpath: false,
                      //     branding: false,
                      //     autolink: true,
                      //     toolbar:
                      //       "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                      //     contextmenu: "true",
                      //     menubar: "file insert view format table",
                      //   }}
                      // />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">첨부파일</th>
                <td colSpan="5">
                  <span className="filetype">
                    <span className="file-select border-line mouse zoom_font">
                      <input
                        {...register("file")}
                        type="file"
                        multiple="multiple"
                        className="input-file"
                        onChange={onchangeFilesHandler}
                      />
                    </span>
                    <span className="file-btn">+ 파일선택</span>
                    <input type="text" className="file-text" />
                    <div className="file-list">
                      {prevFiles?.map((prevFile, index) => {
                        return toRemainPrevFilesIndex[index] ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setToRemainPrevFilesIndex(() => {
                                const newIndexArray = [
                                  ...toRemainPrevFilesIndex,
                                ];
                                newIndexArray[index] = !newIndexArray[index];
                                return newIndexArray;
                              });
                              if (toRemoveFilesId.includes(prevFile.sn)) {
                                setToRemoveFilesId(
                                  [...toRemoveFilesId].filter(
                                    (ele) => ele !== prevFile.sn
                                  )
                                );
                              } else {
                                setToRemoveFilesId([
                                  ...toRemoveFilesId,
                                  prevFile.sn,
                                ]);
                              }
                            }}
                            key={prevFile.sn}
                            type="text"
                            className="file-text"
                          >
                            {`${prevFile?.filename}.${prevFile?.extension}`}
                            <FileDeleteBtn />
                          </div>
                        ) : (
                          <div
                            style={{
                              textDecoration: "line-through",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setToRemainPrevFilesIndex(() => {
                                const newIndexArray = [
                                  ...toRemainPrevFilesIndex,
                                ];
                                newIndexArray[index] = !newIndexArray[index];
                                return newIndexArray;
                              });
                              if (toRemoveFilesId.includes(prevFile.sn)) {
                                setToRemoveFilesId(
                                  [...toRemoveFilesId].filter(
                                    (ele) => ele !== prevFile.sn
                                  )
                                );
                              } else {
                                setToRemoveFilesId([
                                  ...toRemoveFilesId,
                                  prevFile.sn,
                                ]);
                              }
                            }}
                            key={prevFile.sn}
                            type="text"
                            className="file-text"
                          >
                            {`${prevFile?.filename}.${prevFile?.extension}`}
                            <FileDeleteBtn />
                          </div>
                        );
                      })}
                      {files.length > 0 &&
                        files.map((ele) => {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeFile(ele.id);
                              }}
                              onChange={() => {}}
                              key={ele.id}
                              type="text"
                              className="file-text"
                            >
                              {ele?.file?.name}

                              <FileDeleteBtn />
                            </div>
                          );
                        })}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-group">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>

          <button
            className="writebtn cancelbtn border-line mouse zoom_font"
            type="button"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
        </div>
      </form>

      <CommonModal
        title="대상자"
        open={isSelectManagerModalOpen}
        onChangeModalState={onChangeSelectManagerModalState}
      >
        <SelectManagerList
          COMP_CD={auth.user.isAdmin && company?.COMP_CD}
          setValue={setValue}
          selectedEmployeeList={selectedEmployeeList}
          setSelectedEmployeeList={setSelectedEmployeeList}
          selectedEmployeeEsnList={selectedEmployeeEsnList}
          setSelectedEmployeeEsnList={setSelectedEmployeeEsnList}
          open={isSelectManagerModalOpen}
          onChangeModalState={onChangeSelectManagerModalState}
        />
      </CommonModal>

      <CommonModal
        open={isCheckModalOpen}
        onChangeModalState={onChangeModalState}
        title="대상자 검색"
      >
        <div className="pri-company">
          <input
            className="modal-input border-line mouse zoom_font"
            placeholder="멤버검색"
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
          <div className="mt-3" style={{ width: "100%" }}>
            <div style={{ width: "60%", float: "left" }}>
              <div className="m-inputsearch">
                <button className="m-searchbtn border-line mouse"></button>
              </div>
              {members
                ?.filter((oldMembers) => {
                  return oldMembers.name.includes(keyword);
                })
                .map((member) => {
                  return (
                    <div
                      className="text-center pointer"
                      key={member.sn}
                      onClick={() => {
                        if (newName.includes(member.name)) {
                          setNewName(() =>
                            [...newName].filter((ele) => member.name !== ele)
                          );
                          setNewEsn(() =>
                            [...newEsn].filter((ele) => member.esn !== ele)
                          );
                        } else {
                          setNewName(() => [...newName, member.name]);
                          setNewEsn(() => [...newEsn, member.esn]);
                        }
                      }}
                    >
                      <div className="border-line mouse zoom_font">
                        {member.name}
                      </div>
                      <div className="border-line mouse zoom_font">
                        {member.department}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div style={{ width: "40%", float: "right" }}>
              {members
                ?.filter((ele) => newEsn.includes(ele.esn))
                .map((member) => {
                  return (
                    <div
                      className="text-center pointer"
                      key={member.sn}
                      onClick={() => {
                        if (newName.includes(member.name)) {
                          setNewName(() =>
                            [...newName].filter((ele) => member.name !== ele)
                          );
                          setNewEsn(() =>
                            [...newEsn].filter((ele) => member.esn !== ele)
                          );
                        } else {
                          setNewName(() => [...newName, member.name]);
                          setNewEsn(() => [...newEsn, member.esn]);
                        }
                      }}
                    >
                      <div className="border-line mouse zoom_font">
                        {member.name}
                      </div>
                      <div className="border-line mouse zoom_font">
                        {member.department}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div>{`선택 총 ${newEsn.length}`}</div>

        <div id="modalfoot">
          <div className="btn-group">
            <button
              className="fbtn submitbtn border-line mouse zoom_font"
              type="button"
              onClick={() => {
                setIsCheckModalOpen(false);
                setName(newName);
                setEsn(newEsn);
              }}
            >
              선택
            </button>
            <button
              className="fbtn cancelbtn border-line mouse zoom_font"
              type="button"
              onClick={() => {
                setIsCheckModalOpen(false);
                setNewName(name);
                setNewEsn(esn);
              }}
            >
              취소
            </button>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default WorkOrderEdit;
