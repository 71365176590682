import { useRecoilValue } from "recoil";
import BreadCrumb from "../BreadCrumb";
import DataTable from "../DataTable";
import { authAtom } from "../../../recoil";
import React, { useEffect, useState } from "react";
import { PageInfoState } from "../../../recoil/types/data";
import PaginationBar from "../PaginationBar";
import { overMaxLength } from "../../../utils";
import SearchKeyword from "../SearchKeyword";
import CommonModal from "../../modal/CommonModal";
import { List } from "react-content-loader";
import ScheduleRegRegister from "./ScheduleReqRegister";
import ScheduleCheckFile from "./ScheduleCheckFile";
import ScheduleSubmitFile from "./ScheduleSubmitFile";
import useScheduleActions from "../../../recoil/api/schedule";
import Select from "react-select";
import dayjs from "dayjs";

const ScheduleDocsList = () => {
  const auth = useRecoilValue(authAtom);
  const [loading, setLoading] = useState(false);
  const [loadedData, setLoadedData] = useState([]);
  const [target, setTarget] = useState();
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("");
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [isDocsRegisterModalOpen, setIsDocsRegisterModalOpen] = useState(false);
  const [isFileCheckModalOpen, setIsFileCheckModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const scheduleActions = useScheduleActions();

  const getScheduleDocsList = async () => {
    setLoading(true);
    const res = await scheduleActions.getScheduleDocsList({
      page: pageInfo.current || 1,
      keyword,
      type,
    });
    if (res.status === 200) {
      setLoadedData(res.data);
      setPageInfo({ ...res.data.pageInfo, current: pageInfo.current });
    }
    setLoading(false);
  };

  useEffect(() => {
    getScheduleDocsList();
  }, [
    isDocsRegisterModalOpen,
    pageInfo.current,
    keyword,
    isSubmitModalOpen,
    type,
  ]);

  const deleteScheduleDocs = async (sn) => {
    if (window.confirm("해당 일정을 삭제하시겠습니까?")) {
      setLoading(true);
      const res = await scheduleActions.deleteScheduleDocs(sn);
      if (res.status === 200) {
        getScheduleDocsList();
      }
      setLoading(false);
    }
  };
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        {(auth?.user?.isAdmin || auth?.user?.isCompany) && (
          <button
            className="primarybtn-lg"
            onClick={() => setIsDocsRegisterModalOpen(true)}
          >
            일정 등록
          </button>
        )}
        <div className="btn-group-left btn-group-inline">
          <Select
            className="elec-select"
            placeholder="구분 선택"
            options={
              auth?.user?.isEmployee
                ? [
                    { value: "all", label: "전체" },
                    { value: "proceeding", label: "진행중" },
                    { value: "deadline", label: "제출마감" },
                    { value: "complete", label: "제출완료" },
                  ]
                : [
                    { value: "all", label: "전체" },
                    { value: "complete", label: "진행중" },
                    { value: "deadline", label: "제출마감" },
                  ]
            }
            onChange={(e) => {
              setType(e.value);
            }}
          />
          <SearchKeyword
            setKeyword={setKeyword}
            placeholder={
              auth?.user?.isAdmin
                ? "기업명을 입력해주세요. "
                : "서류이름을 입력해주세요."
            }
          />
        </div>
      </div>

      {auth?.user?.isEmployee ? (
        <DataTable
          order={true}
          loadedData={loadedData}
          loading={loading}
          checkbox={false}
          columns={[
            "일정명",
            "서류이름",
            "제출기한",
            "요청기업",
            "제출여부",
            "제출일자",
            "수정/조회",
          ]}
        >
          {loadedData?.list?.map((obj, idx) => (
            <tr key={idx} className="text-center">
              <td className="border-line mouse zoom_font">
                {overMaxLength(obj.schedule_name, 30)}
              </td>
              <td className="border-line mouse zoom_font">
                {overMaxLength(obj.docs_name, 30)}
              </td>
              <td className="border-line mouse zoom_font">
                {dayjs(obj.start_date).format("YYYY-MM-DD")} ~{" "}
                {dayjs(obj.end_date).format("YYYY-MM-DD")}
              </td>
              <td className="border-line mouse zoom_font">
                {obj.company_name}
              </td>

              {dayjs(obj?.start_date).isBefore(dayjs()) ? (
                obj?.is_active ? (
                  <td
                    className="border-line mouse zoom_font"
                    style={{ color: "#36B37E" }}
                  >
                    {obj?.is_submit ? (
                      "제출 완료"
                    ) : (
                      <button
                        type="button"
                        className="redbtn-sm border-line zoom_font"
                        onClick={() => {
                          setTarget(obj);
                          setIsSubmitModalOpen(true);
                        }}
                      >
                        등록하기
                      </button>
                    )}
                  </td>
                ) : (
                  <td
                    className="border-line mouse zoom_font"
                    style={{
                      color: "#BE5252",
                    }}
                  >
                    제출 마감
                  </td>
                )
              ) : (
                <td className="border-line mouse zoom_font"></td>
              )}

              <td className="border-line mouse zoom_font">
                {obj?.submit_datetime &&
                  dayjs(obj.submit_datetime).format("YYYY-MM-DD")}
              </td>
              <td className="border-line mouse zoom_font">
                {obj?.is_submit ? (
                  <button
                    type="button"
                    className="blackbtn-sm border-line zoom_font"
                    onClick={() => {
                      setTarget(obj);
                      setIsSubmitModalOpen(true);
                    }}
                  >
                    확인
                  </button>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </DataTable>
      ) : (
        <DataTable
          order={true}
          loadedData={loadedData?.list}
          loading={loading}
          checkbox={false}
          columns={
            auth?.user?.isAdmin
              ? [
                  "기업명",
                  "서류이름",
                  "제출기한",
                  "대상자",
                  "제출여부",
                  "수정",
                  "삭제",
                ]
              : [
                  "일정명",
                  "서류이름",
                  "제출기한",
                  "대상자",
                  "제출여부",
                  "수정",
                  "삭제",
                ]
          }
        >
          {loadedData?.list?.map((obj, idx) => (
            <tr key={idx} className="text-center">
              {auth?.user?.isAdmin ? (
                <>
                  <td className="border-line mouse zoom_font">
                    {obj?.company_name}
                  </td>
                  <td className="border-line mouse zoom_font">
                    {overMaxLength(obj?.req_docs_name[0], 30)}
                    {obj?.req_docs_name.length > 1
                      ? ` 외 ${obj?.req_docs_name.length - 1}건`
                      : ""}
                  </td>
                  <td className="border-line mouse zoom_font">
                    {`${obj?.start_time} ~ ${obj?.end_time}`}
                  </td>

                  <td className="border-line mouse zoom_font">
                    {obj?.submit_target}명
                  </td>
                  {obj?.is_active ? (
                    <td
                      className="border-line mouse zoom_font"
                      style={{
                        color:
                          obj?.is_clear === obj?.total_docs_cnt
                            ? "#36B37E"
                            : "#FFAB00",
                      }}
                    >
                      {obj?.is_clear === obj?.total_docs_cnt
                        ? `제출완료`
                        : `${obj?.is_clear}/${obj?.total_docs_cnt} 진행중`}
                    </td>
                  ) : (
                    <td
                      className="border-line mouse zoom_font"
                      style={{
                        color: "#BE5252",
                      }}
                    >
                      제출 마감
                    </td>
                  )}
                </>
              ) : (
                <>
                  <td className="border-line mouse zoom_font">
                    {overMaxLength(obj.schedule_name, 30)}
                  </td>
                  <td className="border-line mouse zoom_font">
                    {overMaxLength(obj?.req_docs_name, 30)}
                  </td>
                  <td className="border-line mouse zoom_font">
                    {obj?.start_time} ~ {obj?.end_time}
                  </td>
                  <td className="border-line mouse zoom_font">
                    {obj?.submit_target?.all === true
                      ? "전체근로자"
                      : obj?.submit_target?.title}
                  </td>
                  {obj?.is_active ? (
                    <td
                      className="border-line mouse zoom_font"
                      style={{
                        color: obj?.is_clear ? "#36B37E" : "#FFAB00",
                      }}
                    >
                      {obj?.is_clear ? "제출 완료" : "진행중"}
                    </td>
                  ) : (
                    <td
                      className="border-line mouse zoom_font"
                      style={{
                        color: "#BE5252",
                      }}
                    >
                      제출 마감
                    </td>
                  )}
                </>
              )}

              <td className="complete hide">
                {obj?.is_active ? (
                  <button
                    className="primarybtn-sm border-line zoom_font"
                    onClick={() => {
                      setTarget(obj);
                      setIsFileCheckModalOpen(true);
                    }}
                  >
                    수정
                  </button>
                ) : (
                  <button
                    className="blackbtn-sm border-line zoom_font"
                    onClick={() => {
                      setTarget(obj);
                      setIsFileCheckModalOpen(true);
                    }}
                  >
                    확인
                  </button>
                )}
              </td>
              <td className="complete hide">
                {obj?.is_delete && obj.is_active && (
                  <button
                    className="redbtn-sm border-line zoom_font"
                    onClick={() => deleteScheduleDocs(obj.sn)}
                  >
                    삭제
                  </button>
                )}
              </td>
            </tr>
          ))}
        </DataTable>
      )}
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
      <CommonModal
        title={"요청서류 일정등록"}
        open={isDocsRegisterModalOpen}
        setOpen={setIsDocsRegisterModalOpen}
      >
        {isDocsRegisterModalOpen && (
          <React.Suspense fallback={<List />}>
            <ScheduleRegRegister
              setIsDocsRegisterModalOpen={setIsDocsRegisterModalOpen}
            />
          </React.Suspense>
        )}
      </CommonModal>
      <CommonModal
        title={"제출 파일 확인"}
        open={isFileCheckModalOpen}
        setOpen={setIsFileCheckModalOpen}
      >
        {isFileCheckModalOpen && (
          <React.Suspense fallback={<List />}>
            <ScheduleCheckFile
              docsData={target}
              getScheduleDocsList={getScheduleDocsList}
              setOpen={setIsFileCheckModalOpen}
            />
          </React.Suspense>
        )}
      </CommonModal>
      <CommonModal
        title={"제출하기"}
        open={isSubmitModalOpen}
        setOpen={setIsSubmitModalOpen}
      >
        {isSubmitModalOpen && (
          <React.Suspense fallback={<List />}>
            <ScheduleSubmitFile
              isSubmitModalOpen={setIsSubmitModalOpen}
              getScheduleDocsList={getScheduleDocsList}
              item={target}
            />
          </React.Suspense>
        )}
      </CommonModal>
    </div>
  );
};

export default ScheduleDocsList;
