import dayjs from "dayjs";
import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  ChatViewAtom,
  RoomListAtom,
  RoomSnAtom,
} from "../../../recoil";
import useChatActions from "../../../recoil/api/chatting";
import { overMaxLength } from "../../../utils";
import ChatMenu from "./ChatMenu";
import { CHAT_VIEW } from "./ChattingLayout";
import { FixedSizeList as List } from "react-window";

const RoomList = ({ setTargetList, setFlag }) => {
  const [keyword, setKeyword] = useState("");
  const roomList = useRecoilValue(RoomListAtom);
  const chatActions = useChatActions();
  const setView = useSetRecoilState(ChatViewAtom);
  const setRoomSn = useSetRecoilState(RoomSnAtom);
  const auth = useRecoilValue(authAtom);

  // Filter and sort the room list based on keyword
  const filteredRoomList = roomList
    ?.filter((room) => {
      if (keyword.trim() === "") return true;
      return room.userList.some(
        (user) => user.name.includes(keyword) || user.id.includes(keyword)
      );
    })
    ?.sort((a, b) => (a.lastesttime > b.lastesttime ? -1 : 1));

  // Render each chat room row
  const renderRoom = ({ index, style }) => {
    const room = filteredRoomList[index];

    return (
      <div key={room.room_sn} style={style}>
        <div
          onClick={async () => {
            await chatActions.readMessage(room.room_sn, true);
            await chatActions.getChatList(room.room_sn);
            setRoomSn(room.room_sn);
            setTargetList(room.userList);
            setView(CHAT_VIEW.CHATTING);
          }}
          className="chat-user-list-select flex-between"
          style={{ position: "relative" }}
        >
          <div className="flex-start">
            {room.lastestcontents_name !== auth.user.userName &&
            room.chatindex - room.read_index > 0 ? (
              <div className="green-ing"></div>
            ) : null}
            <div className="profile user-list"></div>
            <div>
              {room.userList?.length > 3 ? (
                <div>
                  {room.userList?.slice(0, 2).map((user, i) => {
                    if (
                      user.id !== auth.user.id &&
                      user.name !== auth.user.name
                    ) {
                      return (
                        <div key={user.id}>
                          <p className="user-name">
                            {`${user.id} (${user.name})`}
                            {i === 1 && <span>...</span>}
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : (
                <div>
                  {room.userList?.map((user) => {
                    if (
                      user.id !== auth.user.id &&
                      user.name !== auth.user.name
                    ) {
                      return (
                        <div key={user.id}>
                          <p className="user-name">
                            {user.id} ({user.name})
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              <p className="chatting-view">
                {room.lastestcontents_name
                  ? overMaxLength(
                      `${room.lastestcontents_name} : ${
                        JSON.parse(room.lastestcontents)?.type === "TEXT"
                          ? JSON.parse(room.lastestcontents)?.content
                          : `${
                              JSON.parse(room.lastestcontents)?.contents
                                ?.filename
                            }(${(
                              JSON.parse(room.lastestcontents)?.contents
                                ?.fileSize /
                              1024 /
                              1024
                            ).toFixed(2)}MB)`
                      }`,
                      20
                    )
                  : "채팅을 시작하세요"}
              </p>
            </div>
            {room.chatindex - room.read_index > 0 ? (
              <div
                className="alert-num"
                style={{ top: 10, right: "405px", color: "white" }}
              >
                {room.chatindex - room.read_index > 100
                  ? "99+"
                  : room.chatindex - room.read_index}
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "inline-block",
              width: "20%",
              textAlign: "right",
            }}
          >
            <p className="chat-time">
              {dayjs(room.lastesttime).format("YYYY-MM-DD A hh:mm")}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ChatMenu
        setFlag={setFlag}
        setKeyword={setKeyword}
        placeholder="이름 또는 아이디를 입력하세요."
      />
      <div className="chat-content-c">
        <div className="chat-user-list">
          <div className="chat-scroll" style={{ height: 422 }}>
            <List
              height={422} // Adjust height of the scrollable area
              itemCount={filteredRoomList.length} // Total number of rooms
              itemSize={80} // Adjust based on room row height
              width="100%"
            >
              {renderRoom}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RoomList);
