import { useState } from "react";
import { fileNameCheck, fileSizeCheck } from "../../../utils";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import useScheduleActions from "../../../recoil/api/schedule";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DocsTypeListAtom, toastAtom } from "../../../recoil";
import { toast as toastify } from "react-toastify";

export const ScheduleAddDocs = ({ open, setOpen }) => {
  // 파일 상태 값
  const [files, setFiles] = useState(null);
  const { register, setValue, handleSubmit, control } = useForm();
  const [toast, setToast] = useRecoilState(toastAtom);

  const [isDocsModify, setIsDocsModify] = useState(false);
  const [isChoiseSelect, setIsChoiseSelect] = useState(false);
  const [btnClickDisabled, setBtnClickDisabled] = useState(false);

  const scheduleActions = useScheduleActions();
  const setDocsTypeList = useSetRecoilState(DocsTypeListAtom);
  const docsTypeList = useRecoilValue(DocsTypeListAtom);

  // 파일 input 핸들러
  const onchangeFilesHandler = (event) => {
    event.preventDefault();

    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }
      setFiles(event?.target?.files[0]);
      setValue("file", null);
    }
  };

  const onSubmitModify = async (data) => {
    if (!!!data.docsname) {
      setToast({ ...toast, error: true, message: "서류 이름을 입력해주세요." });
      return;
    }

    if (
      data.docsname === data.docstype.label &&
      data.reason === data.docstype.value.fileinfo &&
      files === null
    ) {
      setToast({ ...toast, error: true, message: "변경사항이 없습니다" });
      return;
    }

    setBtnClickDisabled(true);
    let params = {
      ...data,
      docstype: data.docstype.value.sn,
    };

    if (files !== null) {
      params = { ...params, file: files };
    }
    const res = await scheduleActions.putScheduleDocsType(params);
    if (res.status === 200) {
      setToast({
        ...toast,
        on: true,
        message: "서류 수정이 완료되었습니다.",
      });
      const getList = await scheduleActions.getScheduleDocsTypeList();
      setDocsTypeList(getList.data);
      setOpen(false);
    } else {
      setToast({
        ...toast,
        error: true,
        message: "서류 등록에 실패하였습니다.",
      });

      setBtnClickDisabled(false);
    }
  };
  const onSubmitRegister = async (data) => {
    if (isChoiseSelect === false) {
      setToast({
        ...toast,
        error: true,
        message: "추가/수정을 선택해주세요.",
      });
      return;
    }
    if (!!!data.docsname) {
      setToast({ ...toast, error: true, message: "서류 이름을 입력해주세요." });
      return;
    }
    setBtnClickDisabled(true);
    let params = {
      ...data,
    };

    if (files !== null) {
      params = { ...params, file: files };
    }

    const toastId = toastify.loading("서류 타입을 등록 중입니다.");
    const res = await scheduleActions.postScheduleDocsType(params);
    if (res.status === 200) {
      toastify.update(toastId, {
        render: "서류 등록에 성공하였습니다.",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      const getList = await scheduleActions.getScheduleDocsTypeList();
      setDocsTypeList(getList.data);
      setOpen(false);
    } else {
      toastify.update(toastId, {
        render: "서류 등록에 실패하였습니다.",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });

      setBtnClickDisabled(false);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        background: "#fff",
        width: "350px",
        top: 0,
        right: -350,
        borderRadius: "8px",
        boxShadow: "0 10px 18px rgba(0, 0, 0, 0.5)",
      }}
    >
      <div id="modalheadstep">
        <span>서류 타입 추가</span>
        <button id="modalclose" onClick={() => setOpen(false)}>
          &times;
        </button>
      </div>
      <div style={{ padding: "8px" }}>
        <table className="pop-in-pop">
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <tbody>
            <tr>
              <th className="pop_thd">추가/수정</th>
              <td className="pop_thd">
                <Controller
                  control={control}
                  name="docstype"
                  render={({ field }) => (
                    <Select
                      placeholder="선택해주세요."
                      options={[
                        {
                          value: { sn: 0, fileinfo: "" },
                          label: "신규 서류 등록",
                        },
                        ...docsTypeList,
                      ]}
                      onChange={(e) => {
                        setIsChoiseSelect(true);
                        field.onChange(e);
                        if (e.value.sn === 0) {
                          setValue("reason", "");
                          setValue("docsname", "");
                          setIsDocsModify(false);
                        } else {
                          setValue("reason", e.value.fileinfo);
                          setValue("docsname", e.label);
                          setIsDocsModify(true);
                        }
                      }}
                    />
                  )}
                />
              </td>
            </tr>
            <tr>
              <th className="pop_thd">서류 이름</th>
              <td className="pop_thd">
                <input
                  type="text"
                  className="pop_input"
                  placeholder="서류 이름을 입력해주세요."
                  {...register("docsname")}
                />
              </td>
            </tr>
            <tr>
              <th className="pop_thd">참고파일 첨부</th>
              <td className="pop_thd">
                <span className="filetype">
                  <span className="file-select border-line mouse zoom_font">
                    <input
                      {...register("file")}
                      type="file"
                      //   multiple="multiple"
                      className="input-file"
                      onChange={onchangeFilesHandler}
                    />
                  </span>
                  <span className="file-btn zoom_font">+ 파일선택</span>

                  <div className="file-list">
                    {/* 새로 업로드하는 파일 목록 */}
                    {files && (
                      <div
                        style={{
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={() => {
                          setFiles();
                        }}
                        type="text"
                      >
                        {files?.name}
                      </div>
                    )}
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <th className="pop_thd">제출 사유</th>
              <td className="pop_thd">
                <textarea
                  type="text"
                  className="pop_input"
                  placeholder="제출 사유는 근로자에게 보이는 내용입니다."
                  style={{ height: "200px" }}
                  {...register("reason")}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 8,
          }}
        >
          <div style={{ fontSize: "12px" }}>
            <p>4대 보험 등 그룹화는 지원하지 않습니다.</p>
            <p>산재보험자격이력내역서 외 4개 등록 </p>
          </div>
          {isChoiseSelect &&
            (isDocsModify ? (
              <button
                type="button"
                className="pinkbtn-md"
                disabled={btnClickDisabled}
                onClick={() => handleSubmit(onSubmitModify)()}
              >
                수정하기
              </button>
            ) : (
              <button
                type="button"
                className="primarybtn-md"
                btnClickDisabled={btnClickDisabled}
                onClick={() => handleSubmit(onSubmitRegister)()}
              >
                신규등록
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleAddDocs;
