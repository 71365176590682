import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { WORKSTAMP } from "../../../JSON/enum/Dataenum";
import { authAtom, toastAtom } from "../../../recoil";
import {
  useCreateWorkstamp,
  useWorkstampActions,
} from "../../../recoil/api/workstamp";
import { dateUtils } from "../../../utils";
import BreadCrumb from "../../common/BreadCrumb";
import CalendarLg from "../../common/calendar/CalendarLg";
// import CalendarList from "../../common/calendar/CalendarList";
import CommonModalScrollTop from "../../modal/CommonModalScrollTop";
import CalendarReNew from "../../common/calendar/CalendarReNew";
import {
  ReasonAtom,
  ReasonModalAtom,
  ReasonModalTextAtom,
  WorkstampTypeAtom,
  WorkstampTimeAtom,
  DreamCafeModalAtom,
} from "../../../recoil/atoms/workstamp";
import Select from "react-select";
import {
  workstampNotLeavingWorkList,
  workstampEarlyWorkinList,
  workstampLateWorkinList,
  workstampEarlyWorkoutList,
  workstampLateWorkoutList,
} from "../home/EmployeeWorkstampHome";
import dayjs from "dayjs";
import CommonImageModal from "../../modal/CommonImageModal";

const EmployeeWorkstamp = () => {
  const workstampActions = useWorkstampActions();
  const createWorkstampActions = useCreateWorkstamp();
  const [workStampList, setWorkStampList] = useState([]);
  const [workInfo, setWorkInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [reason, setReason] = useRecoilState(ReasonAtom);
  const [date, setDate] = useState(dateUtils.startEndOfMonthObj());
  const auth = useRecoilValue(authAtom);
  const [workstampType, setWorkstampType] = useRecoilState(WorkstampTypeAtom);
  const [workstampTime, setWorkstampTime] = useRecoilState(WorkstampTimeAtom);
  const [isReasonModalOpen, setIsReasonModalOpen] =
    useRecoilState(ReasonModalAtom);
  const [isDreamCafeModalOpen, setIsDreamCafeModalOpen] =
    useRecoilState(DreamCafeModalAtom);
  const [reasonModalText, setReasonModalText] =
    useRecoilState(ReasonModalTextAtom);
  const [isCalendar, setIsCalendar] = useState(true);
  const [buttonC, setButtonC] = useState(false);
  const [buttonC2, setButtonC2] = useState(false);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [totalWorkTime, setTotalWorkTime] = useState("0 시간");
  // const [message, setMessage] = useState("");
  const [earlyworkin_time, setEarlyworkinTime] = useState(0);

  useEffect(() => {
    getEmployeeWorkInfoById();
    getEmployeeWorkstampCheckById();
  }, []);

  useEffect(() => {
    getWorkstamp();
  }, [date]);

  const getWorkstamp = async () => {
    setLoading(true);
    const res = await workstampActions.getWorkstamp(date);
    if (res.data) {
      setWorkStampList(res.data.workStampList);
      setTotalWorkTime(
        `${Math.floor(res?.data?.totalWorkTime / 60)} 시간 ${
          res?.data?.totalWorkTime % 60
        } 분`
      );
    }
    setLoading(false);
  };

  const getEmployeeWorkstampCheckById = async () => {
    setLoading(true);
    const res = await workstampActions.getEmployeeWorkstampCheckById({
      id: auth.user.id,
    });
    if (res) {
      setWorkstampType(parseInt(res.data.workstamp));
      // setMessage(res.data?.minute);
      if (res.data.workstamp === WORKSTAMP.earlyworkin) {
        setEarlyworkinTime(res?.data?.earlyworkin_time || 0);
      }
    }
    setLoading(false);
  };

  const getEmployeeWorkInfoById = async () => {
    setLoading(true);

    const res = await workstampActions.getEmployeeWorkInfoById({
      id: auth.user.id,
    });
    if (res) {
      setWorkInfo(res.data);
    }
    setLoading(false);
  };
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);

  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };

  // const lateWorkTime = (message) => {
  //   if (message >= 60) {
  //     let hour =
  //       parseInt(message / 60) +
  //       parseInt(workInfo?.working_endtime_string?.slice(0, 2));
  //     let minute =
  //       parseInt(message % 60) +
  //       parseInt(workInfo?.working_endtime_string?.slice(2, 4));
  //     if (minute >= 60) {
  //       hour += minute / 60;
  //     }
  //     return hour + "시" + minute + "분";
  //   }
  //   let hour = parseInt(workInfo?.working_endtime_string?.slice(0, 2));
  //   let minute =
  //     parseInt(message % 60) +
  //     parseInt(workInfo?.working_endtime_string?.slice(2, 4));
  //   if (minute >= 60) {
  //     hour += minute / 60;
  //   }
  //   return hour + "시" + minute + "분";
  // };
  return (
    <>
      <BreadCrumb />
      <div>
        <button
          className="primarybtn-md border-line zoom_font"
          onClick={() => {
            setIsCalendar(!isCalendar);
          }}
        >
          {isCalendar ? "리스트" : "캘린더"}
        </button>
      </div>
      <div className="calendar-wrapper">
        <div className="user-schedule-menu">
          <div className="user-schedule-menu-header">
            <h2 className="zoom_font">근로시간 통계</h2>
            <div className="timebox zoom_font">
              <span className="zoom_font">지정 출근시간</span>
              <span className="user-schedule-time zoom_font">
                {workInfo?.working_starttime_string?.slice(0, 2)} :{" "}
                {workInfo?.working_starttime_string?.slice(2, 4)}
              </span>
            </div>
            <div className="timebox zoom_font">
              <span className="zoom_font">지정 퇴근시간</span>
              <span className="user-schedule-time zoom_font">
                {workInfo?.working_endtime_string?.slice(0, 2)} :{" "}
                {workInfo?.working_endtime_string?.slice(2, 4)}
              </span>
            </div>
            <hr style={{ border: "1px solid #eee", margin: "20px 0" }} />
            <div className="timebox zoom_font">
              <span className="zoom_font">지정 근로시간</span>
              <span className="user-schedule-time zoom_font">
                {`${Math.floor(workInfo?.working_minute_total / 60)} 시간 ${
                  workInfo?.working_minute_total % 60
                } 분`}
              </span>
            </div>
            <div className="timebox zoom_font">
              <span className="zoom_font">총 근로시간</span>
              <span className="user-schedule-time zoom_font">
                {totalWorkTime}
              </span>
            </div>
          </div>
          <div>
            <button
              disabled={
                loading ||
                (workstampType !== WORKSTAMP.earlyworkin &&
                  workstampType !== WORKSTAMP.workin &&
                  workstampType !== WORKSTAMP.lateworkin) ||
                buttonC ||
                workstampType === WORKSTAMP.not
              }
              className="user-schedule-btn user-schedule-btn-top border-line mouse zoom_font"
              onClick={async () => {
                setButtonC(true);
                if (workstampType === WORKSTAMP.earlyworkin) {
                  if (earlyworkin_time <= -60) {
                    setToast({
                      ...toast,
                      error: true,
                      message: "조기출근은 출근 한 시간 전부터 가능합니다.",
                    });
                    setButtonC(false);
                    return;
                  }
                }
                setWorkstampTime(dayjs().format("YYYY-MM-DD HH:mm:ss"));
                await createWorkstampActions.createWorkstamp().finally(() => {
                  getEmployeeWorkstampCheckById();
                  getWorkstamp();
                  setButtonC(false);
                });
              }}
            >
              출근하기
            </button>
            <button
              disabled={
                loading ||
                (workstampType !== WORKSTAMP.workout &&
                  workstampType !== WORKSTAMP.lateworkout &&
                  workstampType !== WORKSTAMP.dailyReportRequired &&
                  workstampType !== WORKSTAMP.notLeavingWork &&
                  workstampType !== WORKSTAMP.earlyworkout) ||
                buttonC ||
                workstampType === WORKSTAMP.not
              }
              className="user-schedule-btn user-schedule-btn-bottom border-line mouse zoom_font"
              onClick={async () => {
                setButtonC(true);
                setWorkstampTime(dayjs().format("YYYY-MM-DD HH:mm:ss"));
                await createWorkstampActions.createWorkstamp().finally(() => {
                  getEmployeeWorkstampCheckById();
                  getWorkstamp();
                  setButtonC(false);
                });
              }}
            >
              퇴근하기
            </button>
          </div>
        </div>
        {/* 달력, 리스트 */}
        {isCalendar && (
          <CalendarLg
            loading={loading}
            setDate={setDate}
            workStampList={workStampList}
            workInfo={workInfo}
          />
        )}
        {!isCalendar && (
          <CalendarReNew
            loading={loading}
            setLoading={setLoading}
            showDetailsHandle={showDetailsHandle}
            workInfo={workInfo}
          ></CalendarReNew>
        )}

        <CommonModalScrollTop
          open={isReasonModalOpen}
          setOpen={setIsReasonModalOpen}
          title="사유 입력"
        >
          <div style={{ marginTop: "-20px" }}>
            <table>
              <tbody>
                <tr>
                  <td className={"text-center-title"} style={{ width: "20%" }}>
                    <div>사유 선택</div>
                  </td>
                  <td>
                    <div className="select-border-line">
                      <Select
                        className="elec-select2"
                        placeholder="기타(직접입력)"
                        options={
                          workstampType === WORKSTAMP.notLeavingWork
                            ? workstampNotLeavingWorkList
                            : workstampType === WORKSTAMP.earlyworkin
                            ? workstampEarlyWorkinList
                            : workstampType === WORKSTAMP.lateworkin
                            ? workstampLateWorkinList
                            : workstampType === WORKSTAMP.lateworkout
                            ? workstampLateWorkoutList
                            : workstampEarlyWorkoutList
                        }
                        onChange={(e) => {
                          setReason(e.value);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={"text-center-title"} style={{ width: "20%" }}>
                    {reasonModalText}
                  </td>
                  <td>
                    <textarea
                      className="textarea"
                      placeholder={"사유를 입력해주세요."}
                      onChange={(e) => setReason(e.target.value)}
                      value={reason}
                      style={{ width: "100%" }}
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="modalfoot">
              {/* <p style={{ textAlign: "center" }}>
                {message}분 지각으로 인해 {lateWorkTime(message)} 이후에
                퇴근하세요.
              </p> */}

              <div className="btn-group">
                <button
                  className="fbtn submitbtn"
                  disabled={buttonC2}
                  onClick={async () => {
                    if (reason.trim() === "") {
                      setToast({
                        ...toast,
                        error: true,
                        message: "사유를 입력해 주세요.",
                      });
                    } else {
                      setButtonC2(true);
                      await createWorkstampActions
                        .createReasonWorkstamp()
                        .finally(() => {
                          setButtonC2(false);
                          getWorkstamp();
                        });
                    }
                  }}
                >
                  입력
                </button>
              </div>
            </div>
          </div>
        </CommonModalScrollTop>
        <CommonImageModal
          open={isDreamCafeModalOpen}
          setOpen={setIsDreamCafeModalOpen}
        ></CommonImageModal>
      </div>
    </>
  );
};

export default EmployeeWorkstamp;
