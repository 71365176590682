import "../../../css/calendarRenew.css";
import { useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { dateUtils } from "../../../utils";
import { useWorkstampActions } from "../../../recoil/api/workstamp";
import { useEffect } from "react";
import dayjs from "dayjs";
import CommonModal from "../../modal/CommonModal";

const CalendarReNew = ({
  loading,
  setLoading,
  showDetailsHandle,
  workInfo,
}) => {
  const workstampActions = useWorkstampActions();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState(dateUtils.dateOfWeek());
  const [workStampList, setWorkStampList] = useState([]);
  const [check, setCheck] = useState(false);

  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const [reasonDetailOne, setReasonDetailOne] = useState("");
  const [reasonDetailTwo, setReasonDetailTwo] = useState("");

  const stHour =
    Number(`${workInfo.working_starttime_string?.slice(0, 2)}`) * 60;
  const stMinute = Number(`${workInfo.working_starttime_string?.slice(2, 4)}`);
  const endHour =
    Number(`${workInfo.working_endtime_string?.slice(0, 2)}`) * 60;
  const endMinute = Number(`${workInfo.working_endtime_string?.slice(2, 4)}`);

  const getWorkstamp = async () => {
    await workstampActions
      .getWorkstamp(date)
      .then((res) => {
        setWorkStampList([res.data.workStampList]);
      })
      .then(() => {
        setCheck(false);
      });
  };

  useEffect(() => {
    getWorkstamp();
  }, [date]);

  const lateChange = (now, tFormat, type) => {
    let hour;
    let minute;

    if (typeof tFormat !== "number") {
      if (type === 0) {
        hour = parseInt(
          (new Date(
            dayjs(tFormat)
              .set(
                "hour",
                Number(`${workInfo.working_starttime_string?.slice(0, 2)}`)
              )
              .set(
                "minute",
                Number(`${workInfo.working_starttime_string?.slice(2, 4)}`)
              )
          ).getTime() -
            now) /
            1000 /
            60 /
            60
        );

        minute = parseInt(
          (new Date(
            dayjs(tFormat)
              .set(
                "hour",
                Number(`${workInfo.working_starttime_string?.slice(0, 2)}`)
              )
              .set(
                "minute",
                Number(`${workInfo.working_starttime_string?.slice(2, 4)}`)
              )
          ).getTime() -
            now) /
            1000 /
            60 -
            hour * 60
        );

        if (hour <= 10) {
          return `${String(hour).padStart(2, "0")} : ${String(minute).padStart(
            2,
            "0"
          )}`;
        } else {
          return `${String(hour).padStart(2, "0")} : ${String(minute).padStart(
            2,
            "0"
          )}`;
        }
      } else if (type === 1) {
        hour = parseInt(
          (now -
            new Date(
              dayjs(tFormat)
                .set(
                  "hour",
                  Number(`${workInfo.working_endtime_string?.slice(0, 2)}`)
                )
                .set(
                  "minute",
                  Number(`${workInfo.working_endtime_string?.slice(2, 4)}`)
                )
            ).getTime()) /
            1000 /
            60 /
            60
        );

        minute = parseInt(
          (now -
            new Date(
              dayjs(tFormat)
                .set(
                  "hour",
                  Number(`${workInfo.working_endtime_string?.slice(0, 2)}`)
                )
                .set(
                  "minute",
                  Number(`${workInfo.working_endtime_string?.slice(2, 4)}`)
                )
            ).getTime()) /
            1000 /
            60 -
            hour * 60
        );

        if (hour <= 10) {
          return `${String(hour).padStart(2, "0")} : ${String(minute).padStart(
            2,
            "0"
          )}`;
        } else {
          return `${String(hour).padStart(2, "0")} : ${String(minute).padStart(
            2,
            "0"
          )}`;
        }
      }
    }
  };

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      setDate({
        ...date,
        starttime: dayjs(date.starttime).subtract(7, "day").format("YYYYMMDD"),
        endtime: dayjs(date.endtime).subtract(7, "day").format("YYYYMMDD"),
      });
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      setDate({
        ...date,
        starttime: dayjs(date.starttime).add(7, "day").format("YYYYMMDD"),
        endtime: dayjs(date.endtime).add(7, "day").format("YYYYMMDD"),
      });
    }
  };

  const renderHeader = () => {
    return (
      <div
        className="header2 row flex-middle calendar-header-flex"
        style={{ border: "1px #22CCB7 solid", borderRadius: "8px" }}
      >
        <div className="col col-start">
          <div
            style={{ color: "#22CCB7" }}
            className="icon"
            onClick={() => {
              if (!check) {
                changeWeekHandle("prev");
                setCheck(true);
              }
            }}
          >
            &lt;
          </div>
        </div>
        <div className="col col-center">
          <span style={{ color: "#22CCB7" }}>
            {format(currentMonth, "yyyy")}년 {format(currentMonth, "MM")}월{" "}
            {currentWeek}주차
          </span>
        </div>
        <div
          style={{ color: "#22CCB7" }}
          className="col col-end"
          onClick={() => {
            if (!check) {
              changeWeekHandle("next");
              setCheck(true);
            }
          }}
        >
          <div className="icon"> &gt;</div>
        </div>
      </div>
    );
  };

  const renderCells = (type) => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
    const weekDays = ["(일)", "(월)", "(화)", "(수)", "(목)", "(금)", "(토)"];
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        days.push(
          <div
            key={day}
            className={`col cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected2"
                : ""
            }`}
          >
            <span
              className="number"
              style={
                i === 0
                  ? { color: "red" }
                  : i === 6
                  ? { color: "blue" }
                  : { color: "black" }
              }
            >
              {formattedDate}
              {weekDays[i]}
            </span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div key={day}>
          {type === "row" ? (
            <>
              <div className="row">
                <div className="row-bottom">
                  <div className="col cell cell-bg">일자</div>
                  {days}
                </div>
                <div className="row-bottom">
                  <div className="col cell cell-bg">출근</div>
                  {!check &&
                    workStampList?.map((item, idx) => {
                      let working = [];
                      for (let i = 0; i < 7; i++) {
                        working.push(
                          <div
                            className="col cell"
                            key={i}
                            style={{ color: "#22CCB7", fontWeight: "bold" }}
                          >
                            {item[
                              dayjs(startDate).add(i, "day").format("YYYYMMDD")
                            ].workstamp?.length > 0
                              ? item[
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD")
                                ]?.workstamp[0]?.stampTime?.slice(11, 16)
                              : ""}
                          </div>
                        );
                      }
                      return (
                        <div key={Math.floor(Math.random() * 100)}>
                          {working}
                        </div>
                      );
                    })}
                </div>
                <div className="row-bottom">
                  <div className="col cell cell-bg">퇴근</div>
                  {!check &&
                    workStampList?.map((item, idx) => {
                      let working = [];
                      for (let i = 0; i < 7; i++) {
                        working.push(
                          <div
                            className="col cell"
                            key={i}
                            style={{ color: "#eb82b3", fontWeight: "bold" }}
                          >
                            {item[
                              dayjs(startDate).add(i, "day").format("YYYYMMDD")
                            ].workstamp?.length > 1
                              ? item[
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD")
                                ]?.workstamp[1]?.stampTime?.slice(11, 16)
                              : ""}
                          </div>
                        );
                      }
                      return (
                        <div key={Math.floor(Math.random() * 100)}>
                          {working}
                        </div>
                      );
                    })}
                </div>
                <div className="row-bottom">
                  <div className="col cell cell-bg">조기출근</div>
                  {!check &&
                    workStampList?.map((item, idx) => {
                      let working = [];
                      for (let i = 0; i < 7; i++) {
                        working.push(
                          <div
                            className="col cell"
                            key={i}
                            style={{ fontWeight: "bold" }}
                          >
                            {item[
                              dayjs(startDate).add(i, "day").format("YYYYMMDD")
                            ].workstamp?.length > 0 &&
                            item[
                              dayjs(startDate).add(i, "day").format("YYYYMMDD")
                            ].reasonList[0]?.stampType === 1
                              ? lateChange(
                                  new Date(
                                    item[
                                      dayjs(startDate)
                                        .add(i, "day")
                                        .format("YYYYMMDD")
                                    ].workstamp[0]?.stampTime //진짜 출근시간
                                  ).getTime(),
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD"), //오늘 날짜
                                  0
                                )
                              : ""}
                          </div>
                        );
                      }
                      return (
                        <div key={Math.floor(Math.random() * 100)}>
                          {working}
                        </div>
                      );
                    })}
                </div>
                <div className="row-bottom">
                  <div className="col cell cell-bg">초과근무</div>
                  {!check &&
                    workStampList?.map((item, idx) => {
                      let working = [];
                      for (let i = 0; i < 7; i++) {
                        working.push(
                          <div
                            className="col cell"
                            key={i}
                            style={{ fontWeight: "bold" }}
                          >
                            {new Date(
                              new Date(
                                item[
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD")
                                ].workstamp[1]?.stampTime
                              ).getTime() -
                                new Date(
                                  item[
                                    dayjs(startDate)
                                      .add(i, "day")
                                      .format("YYYYMMDD")
                                  ].workstamp[0]?.stampTime
                                ).getTime()
                            ) /
                              1000 /
                              60 >
                            endHour + endMinute - (stHour + stMinute)
                              ? lateChange(
                                  new Date(
                                    item[
                                      dayjs(startDate)
                                        .add(i, "day")
                                        .format("YYYYMMDD")
                                    ]?.workstamp[1]?.stampTime
                                  ).getTime(),
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD"),
                                  1
                                ) //오바한 시간 계산
                              : ""}
                          </div>
                        );
                      }
                      return (
                        <div key={Math.floor(Math.random() * 100)}>
                          {working}
                        </div>
                      );
                    })}
                </div>
                <div className="row-bottom">
                  <div className="col cell cell-bg">사유</div>
                  {!check &&
                    workStampList?.map((item, idx) => {
                      let working = [];
                      for (let i = 0; i < 7; i++) {
                        working.push(
                          <div
                            onClick={() => {
                              if (
                                !!item[
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD")
                                ]?.reasonList[1]?.reason
                              ) {
                                setReasonDetailOne(
                                  `${
                                    item[
                                      dayjs(startDate)
                                        .add(i, "day")
                                        .format("YYYYMMDD")
                                    ]?.reasonList[0]?.reason
                                  }`
                                );
                                setReasonDetailTwo(
                                  `${
                                    item[
                                      dayjs(startDate)
                                        .add(i, "day")
                                        .format("YYYYMMDD")
                                    ]?.reasonList[1]?.reason
                                  }`
                                );
                                setIsReasonModalOpen(true);
                              } else if (
                                !!item[
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD")
                                ]?.reasonList[0]?.reason
                              ) {
                                setReasonDetailOne(
                                  `${
                                    item[
                                      dayjs(startDate)
                                        .add(i, "day")
                                        .format("YYYYMMDD")
                                    ]?.reasonList[0]?.reason
                                  }`
                                );
                                setReasonDetailTwo("");
                                setIsReasonModalOpen(true);
                              }
                            }}
                            className="col cell"
                            key={i}
                            style={{ fontWeight: "bold" }}
                          >
                            {item[
                              dayjs(startDate).add(i, "day").format("YYYYMMDD")
                            ].workstamp?.length > 0
                              ? item[
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD")
                                ]?.reasonList[0]?.reason
                              : ""}
                            {item[
                              dayjs(startDate).add(i, "day").format("YYYYMMDD")
                            ].workstamp?.length > 1 && ", "}
                            {item[
                              dayjs(startDate).add(i, "day").format("YYYYMMDD")
                            ].workstamp?.length > 1
                              ? item[
                                  dayjs(startDate)
                                    .add(i, "day")
                                    .format("YYYYMMDD")
                                ]?.reasonList[1]?.reason
                              : ""}
                          </div>
                        );
                      }
                      return (
                        <div key={Math.floor(Math.random() * 100)}>
                          {working}
                        </div>
                      );
                    })}
                </div>
                <CommonModal
                  title="상세 사유"
                  open={isReasonModalOpen}
                  setOpen={setIsReasonModalOpen}
                >
                  <table>
                    <tbody>
                      <tr>
                        <th className="text-center-title zoom_font">
                          조기출근
                        </th>
                        <td>
                          <div className="input-group">
                            <p
                              style={{ width: "100%" }}
                              disabled
                              className="form-control border-line"
                            >
                              {reasonDetailOne}
                            </p>
                          </div>
                        </td>
                      </tr>
                      {reasonDetailTwo !== "" && (
                        <tr>
                          <th className="text-center-title zoom_font">
                            초과근무
                          </th>
                          <td>
                            <div className="input-group">
                              <p
                                style={{ width: "100%" }}
                                disabled
                                className="form-control border-line"
                              >
                                {reasonDetailTwo}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </CommonModal>
              </div>
            </>
          ) : (
            <>
              <div className="col">
                <div className="col-bottom">{days}</div>
              </div>
            </>
          )}
        </div>
      );
      days = [];
    }
    return <>{workStampList && <div className="body">{rows}</div>}</>;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderCells("row")}
    </div>
  );
};

export default CalendarReNew;
