const Privacy = () => {
  return (
    <div id="comm_layer" className="pop-con">
      <div className="pri-company">
        <div className="box">
          <p>
            '브이드림'은 (이하 '회사'는) 고객님의 개인정보를 중요시하며,
            "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
          </p>
          <p>
            회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가
            어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한
            조치가 취해지고 있는지 알려드립니다.
          </p>
          <p>
            회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는
            개별공지)을 통하여 공지할 것입니다.
          </p>
        </div>
        <h5>수집하는 개인정보 항목</h5>
        <ul>
          <li>
            회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은
            개인정보를 수집하고 있습니다.
            <ul>
              <li>
                - 수집항목 : 이름 , 로그인ID , 비밀번호 , 이메일 , 서비스
                이용기록 , 접속 로그 , 쿠키 , 접속 IP 정보
              </li>
              <li>- 개인정보 수집방법 : 홈페이지(회원가입,게시판) </li>
            </ul>
          </li>
        </ul>
        <h5>개인정보의 수집 및 이용목적</h5>
        <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다..</p>
        <ul>
          <li>
            서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 : 콘텐츠
            제공
          </li>
          <li>
            회원 관리 : 회원제 서비스 이용에 따른 본인확인 , 개인 식별 ,
            불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인{" "}
          </li>
          <li>
            마케팅 및 광고에 활용 : 접속 빈도 파악 또는 회원의 서비스 이용에
            대한 통계
          </li>
        </ul>
        <h5>개인정보의 보유 및 이용기간</h5>
        <p>
          원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체
          없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우
          회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를
          보관합니다.
        </p>
        <ul>
          <li>보존 항목 : 로그인ID</li>
          <li>보존 근거 : 신용정보의 이용 및 보호에 관한 법률</li>
          <li>보존 기간 : 3년</li>
          <li>
            표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한
            법률)
          </li>
          <li>
            소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의
            소비자보호에 관한 법률)
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
