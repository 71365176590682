import DOMPurify from "dompurify";
import { downloadFile } from "../../../utils";

const ApprovalDetailModalContent = ({
  doc,
  docType,
  docDetailType,
  itemList,
  footer,
  companysign,
  employeesign,
}) => {
  return (
    <div style={{ height: 600, overflow: "auto", padding: 10 }}>
      <table className="writetable hide">
        <tbody>
          <tr>
            <th className="text-center-title zoom_font" style={{ padding: 0 }}>
              양식구분
            </th>
            <td>{docType}</td>
            <th className="text-center-title zoom_font" style={{ padding: 0 }}>
              양식타입
            </th>
            <td>{docDetailType}</td>
          </tr>
          <tr>
            <th className="text-center-title zoom_font" style={{ padding: 0 }}>
              보존기한
            </th>
            <td>영구 보존</td>
            <th className="text-center-title zoom_font" style={{ padding: 0 }}>
              작성자
            </th>
            <td>{doc?.writer}</td>
          </tr>
        </tbody>
      </table>
      <div style={{ textAlign: "center" }}>
        <h3>{doc?.title}</h3>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 30,
        }}
      >
        {doc?.state !== 0 ? (
          <>
            <div style={{ border: "1px solid #eee" }}>
              <div style={{ background: "#eee", padding: 10 }}>신청자</div>
              <div style={{ padding: 10 }}>
                <img src={employeesign} width={80} />
              </div>
            </div>
            <div style={{ border: "1px solid #eee" }}>
              <div style={{ background: "#eee", padding: 10 }}>결재자</div>
              <div style={{ padding: 10 }}>
                <img src={companysign} width={80} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "1px solid #eee" }}>
              <div style={{ background: "#eee", padding: 10 }}>신청자</div>
              <div style={{ padding: 10 }}>
                <img src={employeesign} width={80} />
              </div>
            </div>
            <div style={{ border: "1px solid #eee" }}>
              <div style={{ background: "#eee", padding: 10 }}>결재자</div>
              <div style={{ padding: 10 }}>
                <div style={{ width: "80px" }}></div>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className="zoom_font_id ql-editor"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(doc?.contents) }}
      />

      {itemList}
      <table className="writetable hide">
        <tbody>
          <tr>
            <th className="text-center-title zoom_font w-20">비고</th>
            <td>{doc?.etc}</td>
          </tr>
          <tr>
            <th className="text-center-title zoom_font">첨부파일</th>
            <td colSpan={3}>
              <div className="border-line mouse zoom_font" href="#">
                {doc?.filelist?.map((value, index) => (
                  <div key={index}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        downloadFile(value);
                      }}
                      download
                    >
                      {`${value?.filename}.${value?.extension}`}
                    </a>
                    <br />
                  </div>
                ))}
              </div>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
      {footer}
    </div>
  );
};

export default ApprovalDetailModalContent;
