import { atom, selectorFamily, useRecoilValue } from "recoil";
import { del, get as getQuery, getPage, post, put } from ".";
import { authAtom } from "..";
import { queryPost } from ".";

// export const CompanyEmployeeManageListQuery = selectorFamily({
//   key: "CompanyEmployeeManageListQuery",
//   get:
//     ({ keyword, pageInfo }) =>
//     async ({ get }) => {
//       const auth = get(authAtom);
//       const companyId = get(authAtom).user.id;
//       const res = await getPage(`${auth.accountType}/settings`, {
//         companyId,
//         pageInfo,
//         keyword,
//       });
//       return res.data;
//     },
// });

export const EmployeeManagementSn = atom({
  key: "EmployeeManagementSn",
  default: 0,
});

export const updateEmployeeManagementState = atom({
  key: "updateEmployeeManagementState",
  default: 0,
});

// export const EmployeeManagementListQuery = selectorFamily({
//   key: "EmployeeManagementList",
//   get:
//     ({ pageInfo, keyword }) =>
//     async ({ get }) => {
//       get(updateEmployeeManagementState);
//       const res = await getPage("admin/employeemanagement/list", {
//         pageInfo,
//         keyword,
//       });
//       return res.data;
//     },
// });

const useManagementActions = () => {
  const auth = useRecoilValue(authAtom);
  return {
    changeEmployeeCompany,
    deleteWorklog,
    changeEmployeeStatus,
    changeCompanyDetail,
    changeUserDetail,
    changeUserCompanyDetail,
    getCompanyDetail,
    getEmployeeDetail,
    changeUserState,
    changeCompanyState,
    putGradeChange,
    getManagementEmployee,
    getManagementCompany,
    getCommoncCode,
    updateCommonCode,
    getMenuSettings,
    updateMenuSettings,
    getManageEmployee,
    EmployeeManagementList,
  };

  async function EmployeeManagementList(data) {
    const res = await getPage("admin/employeemanagement/list", {
      ...data,
    });
    return res.data;
  }

  async function getManageEmployee(data) {
    const companyId = auth.user.id;
    const res = await getPage(`${auth.accountType}/settings`, {
      companyId,
      ...data,
    });
    return res.data;
  }

  async function changeEmployeeCompany(data) {
    const res = await put("admin/management/employee/company", data);
    return res;
  }
  async function deleteWorklog(data) {
    const res = await del("admin/management/employee/company", data);
    return res;
  }
  async function changeEmployeeStatus(data) {
    const res = await put("admin/employeemanagement", data);
    return res;
  }
  async function changeCompanyDetail(data) {
    const res = await put("admin/management/company/select", data);
    return res;
  }

  async function changeUserDetail(data) {
    const res = await put(
      `${auth.accountType}/management/employee/select`,
      data
    );
    return res;
  }
  async function changeUserCompanyDetail(data) {
    const res = await queryPost("admin/management/employee/select", data);
    return res;
  }

  async function getCompanyDetail(data) {
    const res = await getQuery("admin/management/company/select", data);
    return res;
  }

  async function getEmployeeDetail(data) {
    const res = await getQuery("admin/management/employee/select", data);
    return res;
  }
  async function changeUserState(data) {
    const res = await put("admin/management/employee", data);
    return res;
  }
  async function changeCompanyState(data) {
    const res = await put("admin/management/company", data);
    return res;
  }

  async function putGradeChange(data) {
    const res = await put("admin/management/employee/grade", data);
    return res;
  }

  async function getManagementEmployee(data) {
    const res = await getPage("admin/management/employee", data);
    return res;
  }

  async function getManagementCompany(data) {
    const res = await getPage("admin/management/company", data);
    return res;
  }

  async function getCommoncCode(data) {
    const res = await getPage("select/commoncode", data);
    return res;
  }

  async function updateCommonCode(data) {
    const res = await put("admin/commoncode", data);
    return res;
  }

  async function getMenuSettings(data) {
    const res = await getPage("admin/menu", data);
    return res;
  }

  async function updateMenuSettings(data) {
    const res = await put("admin/menu", data);
    return res;
  }
};

export default useManagementActions;
