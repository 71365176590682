import { useState, useEffect } from "react";
import Select from "react-select";
import { ClipLoader } from "react-spinners";

const DataTable = ({
  columns,
  checkbox = true,
  setCheckedItemIds,
  loadedData,
  loading = false,
  children,
  isResponsive = true,
  order,
}) => {
  const [allChecked, setAllChecked] = useState(false);

  const onChangeAllCheckBoxHandler = (checked) => {
    if (checked) {
      if (order) {
        setCheckedItemIds(
          loadedData?.map((obj, idx) => {
            return idx;
          })
        );
      } else {
        setCheckedItemIds(
          loadedData?.map((obj, idx) => {
            return obj.sn;
          })
        );
      }
      setAllChecked(checked);
    } else {
      setAllChecked(checked);
      setCheckedItemIds([]);
    }
  };

  useEffect(() => {
    if (loadedData !== undefined) {
      try {
        setCheckedItemIds([]);
        setAllChecked(false);
      } catch {}
    }
  }, [loadedData]);

  return (
    <>
      <div className={isResponsive ? "table-responsive" : ""}>
        <table>
          <tbody className="text-center">
            <tr className="text-center-title">
              {checkbox && (
                <th scope="col" className="hide">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          onChangeAllCheckBoxHandler(e.currentTarget.checked);
                        }}
                        checked={allChecked}
                      />
                      {allChecked ? (
                        <div className="chkbox border-line mouse checked"></div>
                      ) : (
                        <div className="chkbox border-line mouse "></div>
                      )}
                    </label>
                  </div>
                </th>
              )}
              {columns?.map((column, key) => {
                if (column.select) {
                  return (
                    <th key={key} className="zoom_font" scope="col">
                      <Select
                        options={column.options}
                        placeholder={column.name}
                        onChange={column.onChange}
                      />
                    </th>
                  );
                } else {
                  return (
                    <th key={key} className="zoom_font" scope="col">
                      {column}
                    </th>
                  );
                }
              })}
            </tr>
            {loading && (
              <tr>
                <td className="text-center" colSpan={"100%"}>
                  <ClipLoader />
                </td>
              </tr>
            )}
            {(!children || children.length === 0) && !loading && (
              <tr>
                <td
                  className="text-center"
                  colSpan={"100%"}
                  style={{ padding: "1.3em 0" }}
                >
                  데이터가 없습니다.
                </td>
              </tr>
            )}
            {!loading && children}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataTable;
