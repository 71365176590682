import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePaymentActions } from "../../recoil/api/payment";

const PaymentItem = ({ paymentView, setPaymentView, sn }) => {
  // API 호출 함수 - 급여 상세보기 데이터 불러오기
  const paymentActions = usePaymentActions();

  const loadPaymentView = async (req) => {
    const res = await paymentActions.getPaymentView(req);
    if (res.status === 200) {
      setPaymentView(res.data);
    }
  };

  // useEffect
  useEffect(() => {
    loadPaymentView({ sn: sn });
  }, []);

  return (
    <div className="modalscroll" id="payment_content{{forloop.counter0}}">
      <table className="writetable">
        <tbody>
          <tr>
            <td className="text-center-title zoom_font">회사명</td>
            <td className="text-center w-28 zoom_font">
              {paymentView?.pay?.company_name}
            </td>
            <td className="text-center-title zoom_font">성명</td>
            <td className="text-center w-28 zoom_font">
              {paymentView?.employeeList[0]?.employee_name}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="writetable">
        <tbody>
          <tr className="text-center-title">
            <td className="w-50 zoom_font">항목명</td>
            <td className="w-50 zoom_font">금액</td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">기본급</td>
            <td className="zoom_font">
              {paymentView?.pay?.default_pay?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">교통비보조</td>
            <td className="zoom_font">
              {paymentView?.pay?.traffic_pay?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">업무수당</td>
            <td className="zoom_font">
              {paymentView?.pay?.work_pay?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">팀장수당</td>
            <td className="zoom_font">
              {paymentView?.pay?.cheif_pay?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">연장수당</td>
            <td className="zoom_font">
              {paymentView?.pay?.extra_pay?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">연차수당</td>
            <td className="zoom_font">
              {paymentView?.pay?.vacation_pay?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">기타수당</td>
            <td className="zoom_font">
              {paymentView?.pay?.etc_pay?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center greenbtn">
            <td className="zoom_font" style={{ color: "#FFFFFF" }}>
              급여합계
            </td>
            <td className="zoom_font" style={{ color: "#FFFFFF" }}>
              {paymentView?.pay?.total?.toLocaleString("ko-KR")}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="writetable">
        <tbody>
          <tr className="text-center-title">
            <td className="w-50 zoom_font">공제항목</td>
            <td className="w-50 zoom_font">금액</td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">국민연금</td>
            <td className="zoom_font">
              {paymentView?.pay?.national_duty?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">고용보험</td>
            <td className="zoom_font">
              {paymentView?.pay?.hire_duty?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">건강보험</td>
            <td className="zoom_font">
              {paymentView?.pay?.health_duty?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">산재보험</td>
            <td className="zoom_font">
              {paymentView?.pay?.industry_duty?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">갑근세</td>
            <td className="zoom_font">
              {paymentView?.pay?.source_duty?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center">
            <td className="zoom_font">기타비용</td>
            <td className="zoom_font">
              {paymentView?.pay?.etc_duty?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center gray">
            <td className="zoom_font" style={{ color: "#FFFFFF" }}>
              공제합계
            </td>
            <td className="zoom_font" style={{ color: "#FFFFFF" }}>
              {paymentView?.pay?.total_duty?.toLocaleString("ko-KR")}
            </td>
          </tr>
          <tr className="text-center greenbtn">
            <td className="zoom_font" style={{ color: "#FFFFFF" }}>
              총 지급액
            </td>
            <td className="zoom_font" style={{ color: "#FFFFFF" }}>
              {paymentView?.pay?.result?.toLocaleString("ko-KR")}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="writetable">
        <tbody>
          <tr className="text-center greenbtn">
            <td className="zoom_font" style={{ color: "#FFFFFF" }}>
              비고
            </td>
          </tr>
          <tr className="">
            <td className="zoom_font">
              <p style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                {paymentView?.pay?.comment}
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      {/* <table>
        <tr>
          <th className="text-center-title">비고</th>
        </tr>
        <tr>
          <td>비고내용</td>
        </tr>
      </table> */}
    </div>
  );
};

export default PaymentItem;
