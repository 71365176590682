import HrmWrite from "./HrmWrite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useHrmActions from "../../../recoil/api/hrm";

const HrmEdit = () => {
  const { sn } = useParams();
  const hrmActions = useHrmActions();
  const [hrm, setHrm] = useState({});

  useEffect(() => {
    getHrmDetail(sn);
  }, []);

  const getHrmDetail = async (sn) => {
    const res = await hrmActions.getHrmDetail({ sn });
    if (res.status === 200) {
      setHrm(res.data);
    }
  };

  return <HrmWrite hrm={hrm} />;
};

export default HrmEdit;
