import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { JOINDOCTYPE } from "../../../JSON/enum/Dataenum";
import { ElecEsnAtom, ElecLabelAtom, ElecURLAtom } from "../../../recoil";
import { useElecActions } from "../../../recoil/api/elec";
import ElecFooter from "../../common/elec/ElecFooter";
import ViewElec from "../../common/elec/ViewElec";
import CompanyCreateSidebar from "./CompanyCreateSidebar";

const CompanyCreateElec = () => {
  const elecActions = useElecActions();
  const docURL = useRecoilValue(ElecURLAtom);
  const esn = useRecoilValue(ElecEsnAtom);
  const navigate = useNavigate();
  const setLabels = useSetRecoilState(ElecLabelAtom);

  useEffect(() => {
    if (!docURL || !esn) {
      navigate("/company/eplydoc");
    }

    setLabels([]);
  }, []);

  return (
    <div className="elec-container">
      <CompanyCreateSidebar mode="create" isCompany={true} />
      <div className="elec-content">
        <div style={{ position: "absolute", top: "80px", right: "10px" }}>
          <button
            className="primarybtn-sm border-line zoom_font"
            onClick={() => {
              navigate(-1);
            }}
          >
            닫기
          </button>
        </div>
        {docURL && (
          <ViewElec
            mode="create"
            src={docURL}
            type={JOINDOCTYPE.work_contract}
          />
        )}
      </div>
      <ElecFooter onSubmit={elecActions.createElec} />
    </div>
  );
};

export default CompanyCreateElec;
