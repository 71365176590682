import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useUserActions } from "../../../recoil/api/auth";
import { InputText } from "../../form/Input";
import CommonModal from "../../modal/CommonModal";

const ChangePasswordModal = ({ open, setOpen, user, userId }) => {
  const userActions = useUserActions();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    if (!user) {
      if (data.newPw !== data.newPw_confirm) {
        return alert("비밀번호가 일치하지 않습니다.");
      } else {
        const req = { ...data, id: userId };
        const res = await userActions.adminChangePassword(req);
        if (res.status === 200) {
          setOpen(false);
          reset();
          return alert("비밀번호가 변경되었습니다.");
        }
      }
    } else {
      if (data.currnetPw === "") {
        return alert("현재 비밀번호가 입력되지 않았습니다.");
      }
      if (data.newPw !== data.newPw_confirm) {
        return alert("비밀번호가 일치하지 않습니다.");
      } else {
        const res = await userActions.changePassword(data);
        if (res.status === 200) {
          setOpen(false);
          reset();
          return alert("비밀번호가 변경되었습니다.");
        } else {
          return alert("현재 비밀번호가 일치하지 않습니다.");
        }
      }
    }
  };

  const pw = useRef({});
  pw.current = watch("newPw", "");
  pw.confirm = watch("newPw_confirm", "");

  return (
    <CommonModal title="비밀번호 변경" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {user && (
          <InputText
            type="password"
            errors={errors}
            refs={{
              ...register("currnetPw", {
                required: true,
              }),
              placeholder: "현재 비밀번호",
            }}
          />
        )}
        <div className="mt-3 mb-3">
          <InputText
            type="password"
            errors={errors}
            refs={{
              ...register("newPw", {
                required: true,
                maxLength: 15,
                pattern:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,15}$/,
              }),
              placeholder:
                "변경할 비밀번호 (!,@,#,$,%를 포함한 영문/숫자/특수문자 조합 6~15자)",
            }}
          />
        </div>
        <InputText
          type="password"
          errors={errors}
          refs={{
            ...register("newPw_confirm", {
              required: true,
            }),
            placeholder: "변경할 비밀번호를 한번 더 입력해 주세요.",
          }}
        />
        {pw.current !== pw.confirm && (
          <span className="register-warning">
            비밀번호가 일치하지 않습니다.
          </span>
        )}

        <div className="mt-3"></div>
        <div className="btn-group">
          <button className="primarybtn-lg">변경하기</button>
        </div>
      </form>
    </CommonModal>
  );
};

export default ChangePasswordModal;
