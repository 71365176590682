import Logout from "../../ico/icon_로그아웃.svg";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  menuAtom,
  MobileSideShowAtom,
  UpdateMenuSettingState,
} from "../../recoil";
import { useUserActions } from "../../recoil/api/auth";
import CommonModal from "../modal/CommonModal";
import DataTable from "./DataTable";
import { toastAtom } from "../../recoil";

const Navbar = () => {
  const auth = useRecoilValue(authAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);
  const [mobileSideshow, setMobileSideShow] =
    useRecoilState(MobileSideShowAtom);
  const userActions = useUserActions();
  const [isMenuSettingModalOpen, setIsMenuSettingModalOpen] = useState(false);
  const [menuSettingQuery, setMenuSettingQuery] = useState([]);
  const location = useLocation();

  useEffect(() => {
    settingMenu();
  }, []);

  const settingMenu = async () => {
    const res = await userActions.setMenuSetting();
    setMenuSettingQuery(res);
  };

  const onLogout = async () => {
    return userActions.logout();
  };

  // const closeAllMenu = () => {
  //   const newMenuList = menu.map((m) => {
  //     return { ...m, subshow: false };
  //   });
  //   setMenu(newMenuList);
  // };

  const toggleSubmenu = (index, boolean) => {
    const newMenuList = menu.map((m, i) => {
      if (index === i) {
        return {
          ...m,
          subshow: boolean || !m.subshow,
        };
      } else {
        return { ...m, subshow: false };
      }
    });
    setMenu(newMenuList);
  };

  return (
    <div className="section">
      <nav id="nav" className="menu float-left">
        <div className="lnb">
          <ul>
            {menu?.map((m, i) => {
              return (
                <>
                  <li
                    key={i}
                    style={{
                      display:
                        menuSettingQuery[i]?.view === 1 ? "block" : "none",
                    }}
                    className="dmain border-line zoom_font"
                    onClick={() => toggleSubmenu(i)}
                  >
                    {m.submenu.length === 0 && (
                      <>
                        {m.sn === 41 ? (
                          <a href={`https://xn--5y2bj31c.com/pass/`}>
                            <div
                              className="zoom_font menu-hover"
                              style={{ display: "inline-block" }}
                            >
                              {m.text}
                            </div>
                          </a>
                        ) : (
                          <Link to={`${m.url_name}`}>
                            <div
                              className="zoom_font menu-hover"
                              style={{ display: "inline-block" }}
                            >
                              {m.text}
                            </div>
                          </Link>
                        )}
                      </>
                    )}
                    {m.submenu.length !== 0 && m.subshow && (
                      <a className="" style={{ color: "#22ccb7" }}>
                        <div
                          className="zoom_font menu-hover"
                          style={{ display: "inline-block" }}
                        >
                          {m.text}
                        </div>
                        <span className="arrow" style={{ color: "#22ccb7" }}>
                          ▲
                        </span>
                      </a>
                    )}
                    {m.submenu.length !== 0 && !m.subshow && (
                      <a className="">
                        <div
                          className="zoom_font menu-hover"
                          style={{ display: "inline-block" }}
                        >
                          {m.text}
                        </div>
                        <span className="arrow">▼</span>
                      </a>
                    )}
                  </li>
                  {m.submenu.length !== 0 && (
                    <>
                      <ul
                        className="sub"
                        style={{ display: m.subshow ? "block" : "none" }}
                      >
                        {m.submenu.map((submenu, j) => {
                          return (
                            <li key={j} className="border-line zoom_font">
                              <Link to={`${submenu.url_name}`}>
                                {location?.pathname
                                  ?.split(`${auth.accountType}`)
                                  ?.includes(submenu.url_name) ? (
                                  <div
                                    className="zoom_font submenu-inline"
                                    style={{ color: "#22ccb7" }}
                                  >
                                    {submenu.text}
                                  </div>
                                ) : (
                                  <div className="zoom_font submenu-inline">
                                    {submenu.text}
                                  </div>
                                )}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </>
              );
            })}
          </ul>
        </div>

        <div
          id="menuset"
          className="pointer"
          onClick={() => setIsMenuSettingModalOpen(true)}
        >
          메뉴설정
        </div>
      </nav>

      <MenuSetting
        menu={menu}
        open={isMenuSettingModalOpen}
        setOpen={setIsMenuSettingModalOpen}
        menuSettingQuery={menuSettingQuery}
        setMenuSettingQuery={setMenuSettingQuery}
      />

      {mobileSideshow && (
        <>
          <nav id="mmenu" style={{ right: 0 }}>
            <div id="mgnb">
              <div id="mgnbicon"></div>
              <div id="muserinfo">
                <div className="float-left">
                  <button
                    className="gnbbtn border-line mouse"
                    onClick={(e) => onLogout(e)}
                  >
                    <img src={Logout} alt="로그아웃" />
                  </button>
                </div>
                <div id="musertxt">
                  <p>{auth.user.id}</p>
                  <p>{auth.user.userName} 님</p>
                </div>
              </div>
            </div>
            <div className="mlnb">
              <ul>
                {menu?.map((m, i) => {
                  return (
                    <>
                      <li
                        key={i}
                        className="dmain border-line"
                        style={{
                          display:
                            menuSettingQuery[i]?.view === 1 ? "block" : "none",
                        }}
                        onClick={() => {
                          toggleSubmenu(i);
                          if (m.submenu.length === 0) {
                            setMobileSideShow(false);
                          }
                        }}
                      >
                        {m.submenu.length === 0 && (
                          <>
                            {m.sn === 41 ? (
                              <a href={`https://xn--5y2bj31c.com/pass/`}>
                                <div
                                  className="zoom_font menu-hover"
                                  style={{ display: "inline-block" }}
                                >
                                  {m.text}
                                </div>
                              </a>
                            ) : (
                              <Link to={`${m.url_name}`}>
                                <div
                                  className="zoom_font menu-hover"
                                  style={{ display: "inline-block" }}
                                >
                                  {m.text}
                                </div>
                              </Link>
                            )}
                          </>
                        )}
                        {m.submenu.length !== 0 && m.subshow && (
                          <a className="zoom_font" style={{ color: "#22ccb7" }}>
                            <div
                              className="menu-hover"
                              style={{ display: "inline-block" }}
                            >
                              {m.text}
                            </div>
                            <span className="arrow">▲</span>
                          </a>
                        )}
                        {m.submenu.length !== 0 && !m.subshow && (
                          <a className="zoom_font">
                            <div
                              className="menu-hover"
                              style={{ display: "inline-block" }}
                            >
                              {m.text}
                            </div>
                            <span className="arrow">▼</span>
                          </a>
                        )}
                      </li>
                      {m.submenu.length !== 0 && (
                        <ul
                          onClick={() => {
                            setMobileSideShow(false);
                          }}
                          className="sub"
                          style={{ display: m.subshow ? "block" : "none" }}
                        >
                          {m.submenu.map((submenu, j) => {
                            return (
                              <li key={j} className="border-line">
                                <Link to={`${submenu.url_name}`}>
                                  <div className="zoom_font submenu-inline">
                                    {submenu.text}
                                  </div>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
            <div
              id="menuset"
              className="pointer"
              onClick={() => setIsMenuSettingModalOpen(true)}
            >
              메뉴설정
            </div>
          </nav>
          <div id="mback" onClick={() => setMobileSideShow(false)}></div>
        </>
      )}
    </div>
  );
};

export const MenuSetting = ({
  open,
  setOpen,
  menu,
  menuSettingQuery,
  setMenuSettingQuery,
}) => {
  const auth = useRecoilValue(authAtom);
  const userActions = useUserActions();
  const [toast, setToast] = useRecoilState(toastAtom);

  const updateMenuSettingState = useSetRecoilState(UpdateMenuSettingState);

  const [menuSetting, setMenuSetting] = useState([]);

  const onSubmit = async () => {
    const res = await userActions.putMenuSetting({
      menuList: menuSetting,
    });
    if (res.status === 200) {
      updateMenuSettingState((update) => update + 1);
      setToast({
        ...toast,
        on: true,
        message: "제목을 입력해주세요.",
      });
      setToast({ ...toast, on: true, message: "변경사항이 저장되었습니다." });
      setOpen(false);
      settingMenu();
    }
  };

  const settingMenu = async () => {
    const res = await userActions.setMenuSetting();
    setMenuSettingQuery(res);
  };

  useEffect(() => {
    setMenuSetting(() => menuSettingQuery);
  }, [menuSettingQuery]);

  const changeMenuSetting = (check, i) => {
    let temp = [...menuSetting];
    temp[i] = {
      id: temp[i].id,
      menu_sn: temp[i].menu_sn,
      view: check,
      lastest_update: temp[i].lastest_update,
    };
    setMenuSetting(temp);
  };

  return (
    <>
      <CommonModal title="메뉴 설정" open={open} setOpen={setOpen}>
        {menu.length !== 0 && (
          <DataTable
            columns={["메뉴명", "선택"]}
            checkbox={false}
            isResponsive={false}
          >
            {menu?.map((menu, i) => {
              return (
                <tr key={i}>
                  <td>{menu.text}</td>
                  <td>
                    <input
                      checked={menuSetting[i]?.view === 1}
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          changeMenuSetting(1, i);
                        } else {
                          changeMenuSetting(0, i);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </DataTable>
        )}

        <div className="btn-group">
          <button className="primarybtn-lg" onClick={() => onSubmit()}>
            적용
          </button>
        </div>
      </CommonModal>
    </>
  );
};
export default Navbar;
