import { useEffect, useState } from "react";
import useMainActions from "../../../recoil/api/main";

const AdminMemberCnt = () => {
  const mainActions = useMainActions();
  const [memberCnt, setMemberCnt] = useState(0);
  const [vacationCnt, setVacationCnt] = useState(0);

  useEffect(() => {
    getMemberCnt();
    getTotalVacationCnt();
  }, []);

  const getMemberCnt = async () => {
    const res = await mainActions.getMemberCnt();
    if (res.status === 200) {
      setMemberCnt(res.data.cnt);
    }
  };

  const getTotalVacationCnt = async () => {
    const res = await mainActions.getTotalVacationCnt();
    if (res.status === 200) {
      setVacationCnt(res.data.cnt);
    }
  };

  return (
    <>
      <div className="total-people zoom_font">
        <span className="zoom_font">총 회원 수</span>
        <span className="zoom_font">{memberCnt}명</span>
      </div>
      <div className="annual-leave zoom_font">
        <span className="zoom_font"> &#9829; 연차 결재 대기&nbsp;</span>
        <span className="zoom_font">{vacationCnt}건</span>
      </div>
    </>
  );
};

export default AdminMemberCnt;
