import { useRecoilValue } from "recoil";
import { del, getPage, post } from ".";
import { authAtom } from "..";
import { useAccoutTypeActions } from "./auth";

const useFreshActions = () => {
  const auth = useRecoilValue(authAtom);
  const accountType = useAccoutTypeActions();

  return {
    delHoliday,
    getHolidayNationalByDate,
    getHolidayCompanyByDate,
    postCompanyHoliday,
    getHolidayNationalByDate,
  };

  async function delHoliday(data) {
    const res = await del("delete/holiday", data);
    return res;
  }

  async function getHolidayNationalByDate(data) {
    if (data.date.starttime && data.date.endtime) {
      const res = await getPage(`holiday/national`, { ...data });
      return res;
    }
    return false;
  }

  async function getHolidayCompanyByDate(data) {
    let params = {
      ...data,
    };
    accountType.isCompany() && (params.id = auth.user.id);
    accountType.isEmployee() && (params.id = auth.user.id);
    if (data.date.starttime && data.date.endtime) {
      const res = await getPage(`${auth.accountType}/holiday/company`, params);
      return res;
    }
    return false;
  }

  async function postCompanyHoliday(data) {
    let params = {
      ...data,
    };

    !accountType.isAdmin() && (params.id = auth.user.id);

    const res = await post(
      `${auth.accountType}/holiday/company/regist`,
      params
    );

    return res;
  }
};

export default useFreshActions;
