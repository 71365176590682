import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { HRMTYPE } from "../../../JSON/enum/Dataenum";

const HrmFunction = () => {
  const auth = useRecoilValue(authAtom);

  return {
    getHrmType,
    typeOptions,
    workOrNotOptions,
  };

  function getHrmType(hrm) {
    switch (hrm) {
      case HRMTYPE.HIRED:
        return auth?.commonCode[17]["재직증명서"];
      case HRMTYPE.CAREER:
        return auth?.commonCode[18]["경력증명서"];
      default:
        return "오류";
    }
  }

  function typeOptions() {
    return [
      { label: "전체증명서", value: "" },
      { label: auth?.commonCode[17]["재직증명서"], value: HRMTYPE.HIRED + "" },
      { label: auth?.commonCode[18]["경력증명서"], value: HRMTYPE.CAREER + "" },
    ];
  }

  function workOrNotOptions() {
    return [
      { label: "전체", value: null },
      { label: auth?.commonCode[0]["퇴사"], value: 0 },
      { label: auth?.commonCode[1]["재직중"], value: 1 },
    ];
  }
};

export default HrmFunction;
