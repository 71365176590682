import { useEffect, useState } from "react";
import Select from "react-select";
import { useWorkstampActions } from "../../../recoil/api/workstamp";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import { dateUtils, getXlsxDownloadHref } from "../../../utils";
import BreadCrumb from "../../common/BreadCrumb";
import { getWorkstampType } from "../../common/calendar/CalendarLg";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import CommonModal from "../../modal/CommonModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import InsertWorkstampModal from "../../admin/TNAManagement/InsertWorkstampModal";
import XlsxDownload from "../../form/XlsxDownload";
import CommonModalScrollTop from "../../modal/CommonModalScrollTop";
import dayjs from "dayjs";

const CompanyWorkstamp = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [date, setDate] = useState(DateState);
  const [workstamps, setWorkstamps] = useState([]);
  const workstampActions = useWorkstampActions();
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [isPlusModalOpen, setIsPlusModalOpen] = useState(false);
  const [company, setCompany] = useState({});
  const auth = useRecoilValue(authAtom);
  const [stampType, setStampType] = useState(null);
  const [changeModalOpen, setChangeModalOpen] = useState(false);
  const [changeWorkstamp, setChangeWorkstamp] = useState();
  const [changeStartTime, setChangeStartTime] = useState();
  const [changeStopTime, setChangeStopTime] = useState();
  const [changeStartType, setChangeStartType] = useState();
  const [changeStopType, setChangeStopType] = useState();
  const [isRetired, setIsRetired] = useState(false);

  // 출퇴근기록 추가 모달에서 이 숫자를 늘리면 새로고침되는 변수
  const [addSuccess, setAddSuccess] = useState(1);

  useEffect(() => {
    if (isRetired) {
      getNotWorkstampList();
    } else {
      getWorkstampList();
    }
  }, [date, pageInfo?.current, company, stampType, addSuccess, isRetired]);

  const getWorkstampList = async () => {
    setLoading(true);
    setWorkstamps([]);
    const res = await workstampActions.getWorkstampList({
      date,
      pageInfo,
      COMP_CD: company?.COMP_CD || null,
      stampType: stampType,
    });
    if (res.status === 200) {
      setWorkstamps(res.data.workstamp);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const getNotWorkstampList = async () => {
    setLoading(true);
    setWorkstamps([]);
    const res = await workstampActions.getNotWorkstampList({
      COMP_CD: company?.COMP_CD || null,
      startDate: dayjs(date.starttime).format("YYYY-MM-DD"),
      endDate: dayjs(date.endtime).format("YYYY-MM-DD"),
      keyword: date.keyword,
    });
    if (res.status === 200) {
      let result = [];
      for (let prop in res.data) {
        for (let prop2 in res.data[prop]) {
          res.data[prop][prop2].workin_stamptime = prop;
          res.data[prop][prop2].isRetiredWorkout_type = "";
          if (
            dayjs(
              `${prop} ${res.data[prop][prop2].working_endtime_string}`
            ).isAfter(dayjs())
          ) {
            res.data[prop][prop2].isRetiredWorkin_type = "미출근";
          } else {
            res.data[prop][prop2].isRetiredWorkin_type = "결근";
          }
          result.push(res.data[prop][prop2]);
        }
      }
      result.reverse();

      const pageinfo = {
        current: String(pageInfo.current),
        total: String(Math.ceil(result.length / 8)),
        totalCnt: String(result.length),
      };

      result = result.slice(
        0 + (pageinfo.current - 1) * 8,
        8 + (pageinfo.current - 1) * 8
      );
      setWorkstamps(result);
      setPageInfo(pageinfo);
    }
    setLoading(false);
  };

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div
            className="btn-group btn-group-left"
            style={{ pointerEvents: loading === true ? "none" : "" }}
          >
            <div className="btn-group btn-group-left" style={{ width: 140 }}>
              <button
                className="primarytoggle-md border-line mouse zoom_font"
                onClick={() => setIsRetired(false)}
                disabled={!isRetired}
              >
                &nbsp;&nbsp;출근&nbsp;&nbsp;
              </button>
              <button
                className="primarytoggle-md border-line mouse zoom_font"
                onClick={() => setIsRetired(true)}
                disabled={isRetired}
              >
                미출근
              </button>
            </div>
            {auth.user.isAdmin && (
              <button
                className="plus border-line mouse zoom_font"
                type="button"
                onClick={() => setIsPlusModalOpen(true)}
              />
            )}
            <XlsxDownload
              api={`${auth.accountType}/workstamp`}
              name="출퇴근 기록"
              params={{
                COMP_CD: company?.COMP_CD || null,
                stampType: stampType,
              }}
            />
          </div>
        </div>
        <div className="all-group">
          {/* admin일 경우 기업선택 표시 */}
          {auth.user.isAdmin && (
            <div className="btn-group-inline btn-group-left">
              <button
                className="secondarybtn-md"
                type="button"
                onClick={() => {
                  setIsSearchCompanyModalOpen(true);
                }}
              >
                {company.company_name || "기업 선택"}
              </button>
            </div>
          )}
          <div className="d-inline-block">
            <SearchByDate
              dateAndKeyword={date}
              placeholder="이름 입력"
              period={0}
              select={
                <div
                  className="select-border-line"
                  style={{ display: isRetired === true ? "none" : "" }}
                >
                  <Select
                    onChange={(e) => setStampType(e.value)}
                    placeholder="구분"
                    options={[
                      {
                        label: "전체",
                        value: null,
                      },
                      {
                        label: auth?.commonCode[34]["조기출근"],
                        value: 1,
                      },
                      {
                        label: auth?.commonCode[35]["정상출근"],
                        value: 2,
                      },
                      {
                        label: auth?.commonCode[36]["지각"],
                        value: 3,
                      },
                      {
                        label: "조기퇴근",
                        value: 9,
                      },
                      {
                        label: auth?.commonCode[37]["정상퇴근"],
                        value: 4,
                      },
                      {
                        label: auth?.commonCode[38]["초과근무"],
                        value: 5,
                      },
                      {
                        label: "미퇴근",
                        value: 8,
                      },
                    ]}
                  />
                </div>
              }
              onChange={(value) => setDate(value)}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <DataTable
        checkbox={false}
        loading={loading}
        columns={
          auth.user.isAdmin
            ? [
                "업체",
                "작성자",
                "근무시간",
                "근무일",
                "출근시간",
                "출근상태",
                "출근IP",
                "퇴근시간",
                "퇴근상태",
                "퇴근IP",
                "삭제",
                "수정",
              ]
            : [
                "업체",
                "작성자",
                "근무시간",
                "근무일",
                "출근시간",
                "출근상태",
                "출근IP",
                "퇴근시간",
                "퇴근상태",
                "퇴근IP",
                "수정",
              ]
        }
      >
        {workstamps?.map((workstamp, index) => {
          return (
            <tr key={index}>
              <td className="border-line zoom_font">
                {workstamp.company_name}
              </td>
              <td className="border-line zoom_font">
                {workstamp.employee_name}
              </td>
              <td className="border-line zoom_font">
                {`${dateUtils.mmssToTime(
                  workstamp.working_starttime_string
                )} ~ ${dateUtils.mmssToTime(workstamp.working_endtime_string)}`}
              </td>
              <td className="border-line zoom_font">
                {workstamp.workin_stamptime?.slice(0, 10)}
              </td>
              <td className="border-line zoom_font">
                {workstamp.workin_stamptime?.slice(11, 19)}
              </td>
              <td className="border-line zoom_font">
                {workstamp.isRetiredWorkin_type !== undefined
                  ? workstamp.isRetiredWorkin_type
                  : getWorkstampType(workstamp.workin_type)}
              </td>
              <td className="border-line zoom_font">{workstamp.workin_IP}</td>

              <td className="border-line zoom_font">
                {workstamp.workout_stamptime?.slice(11, 19)}
              </td>
              <td className="border-line zoom_font">
                {workstamp.isRetiredWorkout_type !== undefined
                  ? workstamp.isRetiredWorkout_type
                  : getWorkstampType(workstamp.workout_type)}
              </td>
              <td className="border-line zoom_font">{workstamp.workout_IP}</td>

              {auth.user.isAdmin && isRetired === false && (
                <td>
                  <button
                    className="pinkbtn-sm border-line zoom_font"
                    onClick={async () => {
                      if (window.confirm("삭제하시겠습니까?")) {
                        const res = await workstampActions.deleteWorkstamp({
                          sn: workstamp.sn,
                        });
                        if (res.status === 200) {
                          setToast({ ...toast, on: true });
                          getWorkstampList();
                        }
                      } else {
                        return;
                      }
                    }}
                  >
                    삭제
                  </button>
                </td>
              )}
              {!auth.user.isEmployee && isRetired === false && (
                <td>
                  <button
                    className="primarybtn-sm border-line zoom_font"
                    onClick={() => {
                      setChangeModalOpen(true);
                      setChangeWorkstamp(workstamp);

                      setChangeStartTime(
                        dayjs(workstamp.workin_stamptime).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      );
                      setChangeStartType(workstamp.workin_type);

                      setChangeStopTime(
                        workstamp.workout_stamptime === null
                          ? undefined
                          : dayjs(workstamp.workout_stamptime).format(
                              "YYYY-MM-DD HH:mm"
                            )
                      );
                      setChangeStopType(workstamp.workout_type);
                    }}
                  >
                    수정
                  </button>
                </td>
              )}
            </tr>
          );
        })}
      </DataTable>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
      <CommonModal
        title="출퇴근 기록 추가"
        open={isPlusModalOpen}
        setOpen={setIsPlusModalOpen}
      >
        <InsertWorkstampModal
          open={isPlusModalOpen}
          setOpen={setIsPlusModalOpen}
          setAddSuccess={setAddSuccess}
        />
      </CommonModal>
      <CommonModalScrollTop
        title="출퇴근 기록 수정"
        open={changeModalOpen}
        setOpen={setChangeModalOpen}
      >
        <>
          <table className="lotable">
            <tbody>
              <tr>
                <th>출근시간</th>
                <td>
                  <input
                    className="border-line"
                    type="time"
                    value={dayjs(changeStartTime).format("HH:mm")}
                    onChange={(e) => {
                      setChangeStartTime(
                        `${changeWorkstamp.workin_stamptime.substring(0, 10)} ${
                          e.target.value
                        }`
                      );
                    }}
                  />
                </td>

                <th>퇴근시간</th>
                <td>
                  <input
                    className="border-line"
                    type="time"
                    value={
                      changeStopTime === undefined
                        ? undefined
                        : dayjs(changeStopTime).format("HH:mm")
                    }
                    onChange={(e) => {
                      setChangeStopTime(
                        `${changeWorkstamp.workin_stamptime.substring(0, 10)} ${
                          e.target.value
                        }`
                      );
                    }}
                  />
                </td>
              </tr>

              <tr>
                <th>출근상태</th>
                <td>
                  <Select
                    onChange={(e) => {
                      setChangeStartType(e.value);
                    }}
                    placeholder={getWorkstampType(changeStartType)}
                    value={changeStartType}
                    options={[
                      {
                        label: auth?.commonCode[34]["조기출근"],
                        value: 1,
                      },
                      {
                        label: auth?.commonCode[35]["정상출근"],
                        value: 2,
                      },
                      {
                        label: auth?.commonCode[36]["지각"],
                        value: 3,
                      },
                    ]}
                  />
                </td>

                <th>퇴근상태</th>
                <td>
                  <Select
                    onChange={(e) => {
                      setChangeStopType(e.value);
                    }}
                    placeholder={getWorkstampType(changeStopType)}
                    value={changeStopType}
                    options={[
                      {
                        label: auth?.commonCode[37]["정상퇴근"],
                        value: 4,
                      },
                      {
                        label: auth?.commonCode[38]["초과근무"],
                        value: 5,
                      },
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div id="modalfoot">
            <div className="btn-group">
              <button
                className="fbtn submitbtn border-line zoom_font"
                type="button"
                onClick={async () => {
                  if (changeStopTime !== undefined && changeStopType === null) {
                    setToast({
                      ...toast,
                      error: true,
                      message: "퇴근 상태를 입력해주세요.",
                    });
                    return;
                  }
                  if (changeStopTime === undefined && changeStopType !== null) {
                    setToast({
                      ...toast,
                      error: true,
                      message: "퇴근 시간을 입력해주세요.",
                    });
                    return;
                  }

                  if (
                    changeStopTime !== undefined &&
                    dayjs(changeStartTime).isAfter(changeStopTime)
                  ) {
                    setToast({
                      ...toast,
                      error: true,
                      message: "퇴근 시간이 출근 시간보다 빠를 수 없습니다.",
                    });
                    return;
                  }

                  const res = await workstampActions.adminChangeWorkstamp({
                    sn: changeWorkstamp.sn,
                    workin_stamptime: changeStartTime,
                    workin_type: changeStartType,
                    workin_IP: auth.user.isAdmin
                      ? changeWorkstamp.workin_IP || "127.0.0.1"
                      : changeWorkstamp.workin_IP || "127.0.0.2",
                    workout_stamptime: changeStopTime,
                    workout_type: changeStopType,
                    workout_IP:
                      changeWorkstamp.workout_IP ||
                      changeWorkstamp.workin_IP ||
                      (auth.user.isAdmin ? "127.0.0.1" : "127.0.0.2"),
                  });
                  if (res.status === 200) {
                    setToast({ ...toast, on: true });
                    getWorkstampList();
                    setChangeModalOpen(false);
                  }
                }}
              >
                저장
              </button>
              <button
                className="fbtn cancelbtn border-line zoom_font"
                onClick={() => {
                  setChangeModalOpen(false);
                }}
              >
                취소
              </button>
            </div>
          </div>
        </>
      </CommonModalScrollTop>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default CompanyWorkstamp;
