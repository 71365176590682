import { del, delBody, getPage, post, put } from ".";

const useSettngActions = () => {
  return {
    getCompanyList,
    companyRegist,
    deleteCompany,
    companyEdit,
    companyEditActive,
  };

  async function getCompanyList(data) {
    const res = await getPage("admin/companyregist", data);
    return res;
  }

  async function companyRegist(data) {
    const res = post("admin/companyRegist", data);
    return res;
  }

  async function deleteCompany(data) {
    const res = delBody("admin/companyregist", data);
    return res;
  }

  async function companyEdit(data) {
    const res = put("admin/companyRegist", data);
    return res;
  }

  async function companyEditActive(data) {
    const res = put("admin/companyRegist/check", data);
    return res;
  }
};

export default useSettngActions;
