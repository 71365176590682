import { useEffect, useState } from "react";
import useSearchActions from "../../recoil/api/search";
import CommonModal from "../modal/CommonModal";
import { BulletList } from "react-content-loader";
import { searchRegex } from "../../utils/regex";

const SearchCompanyModal = ({
  open,
  setIsSearchCompanyModalOpen,
  onChange = () => {},
}) => {
  const searchActions = useSearchActions();
  const [loading, setLoading] = useState(true);
  const [companys, setCompanys] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    let timer = setTimeout(() => {
      searchCompanyNameByKeyword();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [open, keyword]);

  const searchCompanyNameByKeyword = async () => {
    if (open) {
      setLoading(true);
      const res = await searchActions.searchCompanyRegister({ keyword });
      if (res.status === 200) {
        setCompanys(res.data);
      }
      setLoading(false);
    }
  };

  const onChangeSearchCompanyModalState = () => {
    setIsSearchCompanyModalOpen(false);
  };

  const onChangeCompany = (company) => {
    setIsSearchCompanyModalOpen(false);
    onChange(company);
  };
  return (
    <CommonModal
      open={open}
      onChangeModalState={onChangeSearchCompanyModalState}
      title="회사 검색"
    >
      <div>
        <div
          className="of-auto"
          style={{ margin: "-20px -30px", marginBottom: 0 }}
        >
          <div
            id="member-left"
            className="float-left"
            style={{ width: "100%" }}
          >
            <input
              className="gray-input border-line"
              placeholder="회사 검색"
              value={keyword}
              onInput={(e) => {
                if (searchRegex.test(e.target.value)) {
                  e.target.value = e.target.value.replace(searchRegex, "");
                }
              }}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className="inputsearch">
              <button className="person-searchbtn"></button>
            </div>
            <div className="person-choice">
              <div style={{ marginLeft: "90%" }}>{`${companys.length}개`}</div>
            </div>
            <div className="person-scroll scroll-200 text-center">
              {loading && <BulletList width={"90%"} />}
              {companys?.map((company) => {
                return (
                  <div
                    className="person-choice pointer"
                    key={company.COMP_CD}
                    onClick={() => onChangeCompany(company)}
                  >
                    <div>
                      <div className="person-circle">
                        <div className="green-circle"></div>
                      </div>
                      <span className="person-name">{`${company.company_name}`}</span>
                    </div>
                    <button></button>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="float-left person-scroll scroll-285"
            style={{ width: "40%" }}
          ></div>
        </div>
        <div id="modalfoot">
          <div className="btn-group">
            <button
              className="fbtn submitbtn border-line zoom_font"
              type="button"
            >
              저장
            </button>
            <button
              className="fbtn cancelbtn border-line zoom_font"
              onClick={() => onChangeSearchCompanyModalState()}
            >
              취소
            </button>
          </div>
          <button
            onClick={() => onChangeCompany({})}
            className="fbtn cancelbtn mt-3 border-line zoom_font"
          >
            선택 취소
          </button>
        </div>
      </div>
    </CommonModal>
  );
};

export default SearchCompanyModal;
