import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ApprovalSnAtom, authAtom, toastAtom } from "../../../recoil";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApprovalActions } from "../../../recoil/api/approval";
import { APPROVALDOCTYPE } from "../../../JSON/enum/Dataenum";
import BreadCrumb from "../BreadCrumb";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import DataTable from "../DataTable";
import ApprovalFunction from "./ApprovalFunction";
import { fileNameCheck, fileSizeCheck } from "../../../utils";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { QuillEditor } from "../../toolbar/QuillEditor";

export const getApprovalDocTypeNumber = (docType) => {
  switch (parseInt(docType)) {
    case APPROVALDOCTYPE.RESIGN:
      return APPROVALDOCTYPE.WORKATTITUDE;
    case APPROVALDOCTYPE.PROBLEM:
      return APPROVALDOCTYPE.WORKATTITUDE;
    case APPROVALDOCTYPE.COOPERATE:
      return APPROVALDOCTYPE.WORK;
    case APPROVALDOCTYPE.SPECIALDAY:
      return APPROVALDOCTYPE.PAYMENT;
    case APPROVALDOCTYPE.PREBUYPAYMENT:
      return APPROVALDOCTYPE.PAYMENT;
    case APPROVALDOCTYPE.BUYPAYMENT:
      return APPROVALDOCTYPE.PAYMENT;
    default:
      return "오류";
  }
};

export const ApprovalWrite = () => {
  const { sn } = useParams();
  const navigate = useNavigate();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const [toRemoveFilesId, setToRemoveFilesId] = useState([]);
  const [toRemainPrevFilesIndex, setToRemainPrevFilesIndex] = useState([]);

  // 파일 상태 값
  const [files, setFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);

  // 파일 input 핸들러
  const onchangeFilesHandler = (event) => {
    event.preventDefault();
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  // 파일 삭제 함수
  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };

  // workOrderView 데이터 상태 값
  const [workOrderData, setWorkOrderData] = useState();
  const [update, setUpdate] = useState(false);

  // approval 양식 상태 값
  const [approvalType, setApprovalType] = useState(null);
  const [approvalDetailType, setApprovalDetailType] = useState(null);

  const [itemList, setItemList] = useState([]);

  // API 요청 함수 - approval 데이터 불러오기
  const approvalActions = useApprovalActions();
  const [approvalDetail, setApprovalDetail] = useState();
  const setApprovalSn = useSetRecoilState(ApprovalSnAtom);

  const getApprovalDetail = async (sn) => {
    const res = await await approvalActions.getApprovalDoc({ sn: sn });
    setApprovalDetail(() => res.data);
    setPrevFiles(res.data.filelist === undefined ? [] : res.data.filelist);
    setToRemainPrevFilesIndex(Array(res?.data?.filelist?.length).fill(true));
  };

  const ApprovalFn = ApprovalFunction();

  const getDocTypeByApprovalType = (approvalType) => {
    if (approvalType === 0) return [{ label: "종류 선택", value: 0 }];
    switch (approvalType) {
      case APPROVALDOCTYPE.WORKATTITUDE:
        return ApprovalFn.workAttitudeOptions();
      case APPROVALDOCTYPE.WORK:
        return ApprovalFn.workOptions();
      case APPROVALDOCTYPE.PAYMENT:
        return ApprovalFn.paymentOptions();
      default:
        return null;
    }
  };

  useEffect(() => {
    if (sn > 0) {
      setApprovalSn(() => sn);
      getApprovalDetail(sn);
    }
  }, [sn]);

  // useForm
  const { register, handleSubmit, reset, setValue, control } = useForm({
    defaultValues: { contents: "" },
  });

  //수정 시
  useEffect(() => {
    if (approvalDetail?.type && sn > 0) {
      reset(approvalDetail);
      const type = getApprovalDocTypeNumber(approvalDetail.type);
      setApprovalType(type);
      setApprovalDetailType(parseInt(approvalDetail.type));
      setUpdate(true);
      setItemList(approvalDetail?.itemlist);
    }
  }, [approvalDetail]);

  useEffect(() => {
    if (approvalDetailType && !update) {
      getApprovalForm(approvalDetailType);
    }
  }, [approvalDetailType]);

  const getApprovalForm = async (type) => {
    const res = await approvalActions.getApprovalForm({ type });
    setValue("contents", res?.data.form);
  };

  const changeitemList = (i, key, value, maxLength) => {
    const newItemList = itemList.map((item, index) => {
      if (index === i && value.length <= maxLength) {
        return { ...item, [key]: value };
      } else {
        return item;
      }
    });
    setItemList(newItemList);
  };
  // Submit 함수
  const onSubmit = async (data) => {
    // 글 작성 API 호출

    if (!data.title) {
      setToast({
        ...toast,
        error: true,
        message: "제목을 입력해주세요.",
      });
      return false;
    }

    if (!approvalType) {
      setToast({
        ...toast,
        error: true,
        message: "양식 구분을 선택해주세요.",
      });
      return false;
    }

    if (!approvalDetailType) {
      setToast({
        ...toast,
        error: true,
        message: "양식 타입을 선택해주세요.",
      });
      return false;
    }

    if (!data.contents) {
      setToast({
        ...toast,
        error: true,
        message: "내용을 입력해주세요.",
      });
      return false;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const req = {
      ...data,
      contents: data.contents,
      id: auth.user.id,
      type: approvalDetailType,
      file: files,
      itemlist: approvalType === APPROVALDOCTYPE.PAYMENT ? itemList : null,
      prevFiles: prevFiles.map((prevFile, index) => {
        if (toRemainPrevFilesIndex[index]) {
          return {
            filename: prevFile?.filename,
            extension: prevFile?.extension,
            url: prevFile?.url,
          };
        }
      }),
    };

    //수정시
    if (sn) {
      const res = await approvalActions.editApproval({
        ...req,
        approval_sn: sn,
      });
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate("/employee/elec");
      }
    } else {
      const res = await approvalActions.postEmployeeApproval(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate("/employee/elec");
      }
    }

    setDoubleclickLoading(false);
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table className="writetable hide">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font w-20">양식 구분</th>
                <td className="w-30">
                  <Select
                    placeholder="구분 선택"
                    options={ApprovalFn.docDivisionOptions()}
                    onChange={(e) => {
                      setApprovalType(e.value);
                      setApprovalDetailType(null);
                    }}
                    value={ApprovalFn.docDivisionOptions().filter((option) => {
                      return option.value === approvalType;
                    })}
                  />
                  <div id="divhashlist"></div>
                </td>
                <th className="text-center-title zoom_font w-20">양식 타입</th>
                <td className="w-30 zoom_font" id="writer">
                  <Select
                    required
                    isDisabled={!approvalType}
                    placeholder="종류 선택"
                    options={getDocTypeByApprovalType(approvalType)}
                    onChange={async (e) => {
                      setApprovalDetailType(() => e.value);
                      setUpdate(false);
                    }}
                    value={getDocTypeByApprovalType(approvalType)?.filter(
                      (option) => {
                        return option.value === approvalDetailType;
                      }
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">제목</th>
                <td>
                  <input
                    {...register("title")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="제목을 입력해주세요."
                    defaultValue={workOrderData?.work_order_title}
                  />
                </td>
                <th className="text-center-title zoom_font">보존 기한</th>
                <td>
                  <Select
                    required
                    placeholder="종류 선택"
                    defaultValue={[{ label: "영구 보존", value: "forever" }]}
                    options={[{ label: "영구 보존", value: "forever" }]}
                    // onChange={async (e) => {
                    //   setApprovalDetailType(() => e.value);
                    //   setUpdate(false);
                    // }}
                    // value={getDocTypeByApprovalType(approvalType)?.filter(
                    //   (option) => {
                    //     return option.value === approvalDetailType;
                    //   }
                    // )}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="5">
                  {/* react hook form 연동 */}
                  <Controller
                    name="contents"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      // <QuillEditor value={value} onChange={onChange} />
                      <Editor
                        apiKey={
                          "pwlbxd7c7x9lapnd8nwqidzm2gre8bazisoovnitdbojymrp"
                        }
                        value={value}
                        onEditorChange={onChange}
                        initialValue={(sn && approvalDetail?.contents) || ""}
                        init={{
                          plugins: ["table", "link", "autolink", "contextmenu"],
                          language: "ko_KR",
                          selector: "div",
                          elementpath: false,
                          branding: false,
                          autolink: true,
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                          contextmenu: "true",
                          menubar: "file insert view format table",
                        }}
                      />
                    )}
                  />
                </td>
              </tr>

              {approvalType === APPROVALDOCTYPE.PAYMENT && (
                <tr>
                  <th className="text-center-title zoom_font">품목</th>
                  <td colSpan="5">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="primarybtn-sm"
                        onClick={() => {
                          const newItem = {
                            doc_approval_sn: sn,
                            name: "",
                            standard: "",
                            EA: 0,
                            price: 0,
                            etc: "",
                          };
                          const newItemList = [...itemList];
                          newItemList.push(newItem);
                          setItemList(newItemList);
                        }}
                      >
                        품목추가
                      </button>
                    </div>

                    <DataTable
                      checkbox={false}
                      columns={[
                        "품명",
                        "규격",
                        "수량",
                        "단가",
                        "금액",
                        "비고",
                        "삭제",
                      ]}
                    >
                      {itemList?.map((item, i) => {
                        return (
                          <tr key={i} style={{ width: "100%" }}>
                            <td>
                              <input
                                style={{ width: 100 }}
                                value={item.name}
                                onChange={(e) => {
                                  changeitemList(i, "name", e.target.value, 10);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                style={{ width: 100 }}
                                value={item.standard}
                                onChange={(e) => {
                                  changeitemList(
                                    i,
                                    "standard",
                                    e.target.value,
                                    10
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <input
                                style={{ width: 100, textAlign: "right" }}
                                min={0}
                                maxLength={10}
                                type="number"
                                value={item.EA}
                                onChange={(e) => {
                                  changeitemList(i, "EA", e.target.value, 4);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                style={{ width: 100, textAlign: "right" }}
                                min={0}
                                type="number"
                                value={item.price}
                                onChange={(e) => {
                                  changeitemList(i, "price", e.target.value, 7);
                                }}
                              />
                            </td>
                            <td>{item.EA * item.price}</td>
                            <td>
                              <input
                                style={{ width: 100 }}
                                value={item.etc !== null ? item.etc : ""}
                                onChange={(e) => {
                                  changeitemList(i, "etc", e.target.value, 10);
                                }}
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="primarybtn-md"
                                onClick={() => {
                                  setItemList(
                                    itemList.filter((item, index) => {
                                      if (index !== i) return true;
                                      else return false;
                                    })
                                  );
                                }}
                              >
                                삭제
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </DataTable>
                  </td>
                </tr>
              )}
              <tr>
                <th className="text-center-title zoom_font">비고</th>
                <td colSpan="5">
                  <input
                    {...register("etc")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="기타사항 입력"
                    defaultValue={workOrderData?.work_order_title}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">파일첨부</th>
                <td colSpan="5">
                  <span className="filetype">
                    <span className="file-select border-line mouse zoom_font">
                      <input
                        {...register("file")}
                        type="file"
                        multiple="multiple"
                        className="input-file"
                        onChange={onchangeFilesHandler}
                      />
                    </span>
                    <span className="file-btn zoom_font">+ 파일선택</span>

                    <div className="file-list">
                      {/* 이전에 저장된 파일 목록 */}
                      {prevFiles?.map((prevFile, index) => {
                        return toRemainPrevFilesIndex[index] ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setToRemainPrevFilesIndex(() => {
                                const newIndexArray = [
                                  ...toRemainPrevFilesIndex,
                                ];
                                newIndexArray[index] = !newIndexArray[index];
                                return newIndexArray;
                              });
                              if (toRemoveFilesId.includes(prevFile.sn)) {
                                setToRemoveFilesId(
                                  [...toRemoveFilesId].filter(
                                    (ele) => ele !== prevFile.sn
                                  )
                                );
                              } else {
                                setToRemoveFilesId([
                                  ...toRemoveFilesId,
                                  prevFile.sn,
                                ]);
                              }
                            }}
                            key={prevFile.sn}
                            type="text"
                            className="file-text"
                          >
                            {`${prevFile?.filename}.${prevFile?.extension}`}
                            <FileDeleteBtn />
                          </div>
                        ) : (
                          <div
                            style={{
                              textDecoration: "line-through",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setToRemainPrevFilesIndex(() => {
                                const newIndexArray = [
                                  ...toRemainPrevFilesIndex,
                                ];
                                newIndexArray[index] = !newIndexArray[index];
                                return newIndexArray;
                              });

                              if (toRemoveFilesId.includes(prevFile.sn)) {
                                setToRemoveFilesId(
                                  [...toRemoveFilesId].filter(
                                    (ele) => ele !== prevFile.sn
                                  )
                                );
                              } else {
                                setToRemoveFilesId([
                                  ...toRemoveFilesId,
                                  prevFile.sn,
                                ]);
                              }
                            }}
                            key={prevFile.sn}
                            type="text"
                            className="file-text"
                          >
                            {`${prevFile?.filename}.${prevFile?.extension}`}
                            <FileDeleteBtn />
                          </div>
                        );
                      })}
                      {/* 새로 업로드하는 파일 목록 */}
                      {files.length > 0 &&
                        files.map((ele) => {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeFile(ele.id);
                              }}
                              key={ele.id}
                              type="text"
                              className="file-text"
                            >
                              {ele.file.name}
                              <FileDeleteBtn />
                            </div>
                          );
                        })}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-group">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            결재요청
          </button>

          <Link to={`/${auth.accountType}/elec`}>
            <button
              className="writebtn cancelbtn border-line mouse zoom_font"
              type="button"
            >
              취소
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ApprovalWrite;
