import { useEffect, useState } from "react";
import useMainActions from "../../../recoil/api/main";

const EmployeeInfo = () => {
  const mainActions = useMainActions();
  const [mainInfo, setMainInfo] = useState({});
  useEffect(() => {
    getEmployeeInfo();
  }, []);

  const getEmployeeInfo = async () => {
    const res = await mainActions.getEmployeeInfo();
    if (res.status === 200) {
      setMainInfo(res.data);
    }
  };

  return (
    <div className="check01-1 check01-3">
      <ul className="cal01 cal cf">
        <li className="work-t">
          <div className="clist zoom_font">
            <p className="zoom_font">
              <strong>{mainInfo.workhour}</strong>
              <em className="num">시간</em>
            </p>
            <span className="zoom_font">근로시간</span>
          </div>
          <span className="icocal"></span>
        </li>
        <li className="late">
          <div className="clist zoom_font">
            <p className="zoom_font">
              <strong>{mainInfo.latehour}</strong>
              <em className="num">시간</em>
            </p>
            <span className="zoom_font">지각시간</span>
          </div>
          <span className="icocal"></span>
        </li>
        <li className="absen">
          <div className="clist zoom_font">
            <p className="zoom_font">
              <strong>{mainInfo.disaperday}</strong>
              <em className="num">일</em>
            </p>
            <span className="zoom_font">결근일수</span>
          </div>
          <span className="icocal"></span>
        </li>
        <li className="holly">
          <div className="clist zoom_font">
            <span className="zoom_font">
              부여{" "}
              {Math.round(
                mainInfo.Vacation?.total * mainInfo.Vacation?.day_standard * 10
              ) / 10}
              일
            </span>
            <span className="zoom_font">
              사용{" "}
              {Math.round(
                mainInfo.Vacation?.used * mainInfo.Vacation?.day_standard * 10
              ) / 10}
              일
            </span>
            <span className="zoom_font">
              잔여{" "}
              {Math.round(
                mainInfo.Vacation?.remain * mainInfo.Vacation?.day_standard * 10
              ) / 10}
              일
            </span>
          </div>
          <span className="icocal"></span>
        </li>
      </ul>
    </div>
  );
};

export default EmployeeInfo;
