import { useRecoilValue } from "recoil";
import { DetailReservationMailQuery } from "../../../recoil";
import SendMail from "./SendMail";

const EditMail = () => {
  const editMail = useRecoilValue(DetailReservationMailQuery());
  return <SendMail editMail={editMail} />;
};

export default EditMail;
