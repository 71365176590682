import { useRecoilValue } from "recoil";
import { authAtom } from "..";
import { SOCKETENUM } from "../../JSON/enum/Dataenum";
import { socket } from "../../socket";
import { useAccoutTypeActions } from "./auth";

const useChatActions = () => {
  const auth = useRecoilValue(authAtom);
  const accoutType = useAccoutTypeActions();

  let params = {
    name: auth?.user?.userName,
  };

  if (auth) {
    if (accoutType.isAdmin()) params.id = auth.user.id;
    if (accoutType.isCompany()) params.id = auth.user.id;
    if (accoutType.isEmployee()) params.id = auth.user.id;
  }

  return {
    readMessage,
    exitRoom,
    getRoomList,
    inviteUser,
    personalRoomCheck,
    getChatList,
    sendChat,
    createRoom,
    enterLobby,
    updateRoomList,
  };

  async function updateRoomList(roomSn) {
    socket.emit("updateRoomList", { ...params, room_sn: roomSn }, (error) => {
      console.log(error);
    });
  }

  async function readMessage(roomSn, join) {
    socket.emit(SOCKETENUM.READ_MESSAGE, {
      ...params,
      room_sn: roomSn,
      join: join,
    });
  }

  async function exitRoom(roomSn) {
    await new Promise((resolve) => {
      socket.emit(SOCKETENUM.EXITROOM, {
        ...params,
        room_sn: roomSn,
      });

      socket.on(SOCKETENUM.EXITROOM, (msg) => {
        socket.off(SOCKETENUM.EXITROOM);
        resolve(msg);
      });
    });
    enterLobby();
  }

  async function inviteUser(roomSn, targetList) {
    const res = await new Promise((resolve) => {
      socket.emit(SOCKETENUM.INVITEUSER, {
        ...params,
        room_sn: roomSn,
        targetList: [...targetList],
      });

      socket.on(SOCKETENUM.INVITEUSER, (msg) => {
        socket.off(SOCKETENUM.INVITEUSER);
        resolve(msg);
      });
    });

    return res;
  }

  async function personalRoomCheck(targetList) {
    const res = await new Promise((resolve) => {
      socket.emit(SOCKETENUM.PERSONALROOMCHECK, {
        ...params,
        targetList: [...targetList, { ...params }],
      });
      socket.on(SOCKETENUM.PERSONALROOMCHECK, (msg) => {
        socket.off(SOCKETENUM.PERSONALROOMCHECK);
        resolve(msg);
      });
    });
    return res;
  }
  async function getRoomList(room_sn) {
    if (!room_sn) {
      return false;
    } else {
      socket.emit(SOCKETENUM.REQUESTCHATROOM, {
        ...params,
        room_sn,
      });
    }
  }

  async function getChatList(room_sn) {
    socket.emit(SOCKETENUM.CHATLIST, {
      ...params,
      room_sn,
    });
  }

  async function sendChat(room_sn, contents) {
    socket.emit(SOCKETENUM.SEND_MESSAGE, {
      ...params,
      room_sn,
      contents,
    });
  }

  async function createRoom(targetList = [], contents) {
    const createRoomRes = await new Promise((resolve, reject) => {
      socket.emit(SOCKETENUM.CREATEROOM, {
        ...params,
        targetList: [...targetList, { ...params }],
      });
      socket.on(SOCKETENUM.CREATEROOMDONE, (msg) => {
        socket.off(SOCKETENUM.CREATEROOMDONE);
        resolve(msg);
      });
    });
    if (createRoomRes.code === 200) {
      //방 생성 후 sendChat 실행
      if (contents) {
        sendChat(createRoomRes.room_sn, contents);
      }
      return createRoomRes;
    } else {
      return null;
    }
  }

  async function enterLobby() {
    socket.emit(SOCKETENUM.ENTER_LOBBY, { ...params }, (error) => {
      console.log(error);
    });
  }
};

socket.on(SOCKETENUM.SERVEREEOR, (msg) => {});

export default useChatActions;
