import { useState, useEffect } from "react";
import SearchByDate from "../SearchByDate";
import DataTable from "../DataTable";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import PaginationBar from "../PaginationBar";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import { usePopupActions } from "../../../recoil/api/popup";
import { overMaxLength } from "../../../utils";
import Select from "react-select";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const popupOptions = [
  { label: "전체", value: 0 },
  { label: "대기", value: 1 },
  { label: "게시", value: 2 },
  { label: "종료", value: 3 },
];

const popupUserOptions = [
  { label: "전체", value: null },
  { label: "근로자", value: 3 },
  { label: "기업", value: 2 },
  { label: "관리자", value: 1 },
];

const Popup = () => {
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);

  // 로딩 상태 값
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [target, setTarget] = useState(null);
  const [state, setState] = useState(0);

  // 페이지네이션 상태 값
  const [pageInfo, setPageInfo] = useState({
    current: 1,
  });

  // 날짜 검색 상태 값
  const [dateAndKeyword, setDateAndKeyword] = useState();

  // API 요청 함수 - popupList 데이터 불러오기
  const popupActions = usePopupActions();

  const loadPopupList = async () => {
    setLoading(true);
    const req = {
      dateAndKeyword,
      pageInfo,
      state,
      target,
    };
    const res = await popupActions.getPopupList(req);

    if (res.status === 200) {
      setLoadedData(res.data.list);
      setPageInfo({ ...res.data.pageinfo, current: pageInfo.current });
    }
    setLoading(false);
  };

  // API 요청 함수 - popup 데이터 삭제
  const deletePopup = async (sn) => {
    popupActions
      .deletePopup({
        docsn: sn,
      })
      .then((res) => {
        if (res.status === 200) {
          setToast({ ...toast, on: true });
          loadPopupList();
        }
      });
  };

  useEffect(() => {
    loadPopupList();
  }, [pageInfo.current, dateAndKeyword, state, target]);

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <Link to={`/${auth.accountType}/popup_write`}>
            <button
              className="holidaybtn greenbtn border-line mouse zoom_font pencil-img"
              type="button"
            >
              팝업등록
            </button>
          </Link>
        </div>

        <SearchByDate
          placeholder="이름 입력"
          onChange={(value) => setDateAndKeyword(value)}
          select={
            <div className="btn-group btn-group-left select-border-line">
              <Select
                options={popupUserOptions}
                placeholder="대상"
                onChange={(e) => {
                  setTarget(e.value);
                }}
              />
              <Select
                options={popupOptions}
                placeholder="상태"
                onChange={(e) => {
                  setState(e.value);
                }}
              />
            </div>
          }
          loading={loading}
        />
      </div>
      <DataTable
        loadedData={loadedData}
        loading={loading}
        checkbox={false}
        columns={[
          "제목",
          "유지기간",
          "위치(x)",
          "위치(y)",
          "크기(width)",
          "크기(height)",
          "대상",
          "상태",
          "관리",
        ]}
      >
        {loadedData?.map((obj) => {
          return (
            <tr className="text-center" key={obj?.sn}>
              <td id="_hash" style={{ display: "none" }}></td>

              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {overMaxLength(obj?.title, 30)}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {`${obj?.starttime?.slice(0, 10)} ~ ${obj?.endtime?.slice(
                  0,
                  10
                )}`}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.x}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.y}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.width}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.high}
              </td>
              <td
                className="border-line mouse zoom_font"
                id="workreport_view/{{ k }}"
                style={{ cursor: "pointer" }}
              >
                {obj?.target?.toString().includes(ACCOUNTTYPE.EMPLOYEE) &&
                obj?.target?.toString().includes(ACCOUNTTYPE.COMPANY) &&
                obj?.target?.toString().includes(ACCOUNTTYPE.ADMIN)
                  ? "전체"
                  : obj?.target?.toString().includes(ACCOUNTTYPE.EMPLOYEE) &&
                    obj?.target?.toString().includes(ACCOUNTTYPE.COMPANY)
                  ? "근로자, 기업"
                  : obj?.target?.toString().includes(ACCOUNTTYPE.EMPLOYEE) &&
                    obj?.target?.toString().includes(ACCOUNTTYPE.ADMIN)
                  ? "근로자, 관리자"
                  : obj?.target?.toString().includes(ACCOUNTTYPE.COMPANY) &&
                    obj?.target?.toString().includes(ACCOUNTTYPE.ADMIN)
                  ? "기업, 관리자"
                  : obj?.target?.toString().includes(ACCOUNTTYPE.EMPLOYEE)
                  ? "근로자"
                  : obj?.target?.toString().includes(ACCOUNTTYPE.COMPANY)
                  ? "기업"
                  : obj?.target?.toString().includes(ACCOUNTTYPE.ADMIN) &&
                    "관리자"}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.state === "1"
                  ? "대기"
                  : obj?.state === "2"
                  ? "게시"
                  : "종료"}
              </td>

              <td>
                <Link to={`/${auth.accountType}/popup_edit/${obj?.sn}`}>
                  <button className="confirm greenbtn border-line mouse zoom_font">
                    수정
                  </button>
                </Link>
                <button
                  className="confirm pinkbtn-sm border-line mouse zoom_font"
                  type="button"
                  onClick={() => {
                    if (window.confirm("삭제하시겠습니까?")) {
                      deletePopup(obj?.sn);
                    }
                  }}
                >
                  삭제
                </button>
              </td>
            </tr>
          );
        })}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default Popup;
