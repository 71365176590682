import { atom } from "recoil";
import { getPage } from ".";

export const ChatDetailRoomSnAtom = atom({
  key: "ChatDetailRoomSnAtom",
  default: 0,
});

export const usePostMailActions = () => {
  const getPostMonitoring = async (dateAndKeyword, page) => {
    const res = await getPage("note/list", { ...dateAndKeyword, page });
    return res.data;
  };

  const getChatMonitoring = async (dateAndKeyword, page) => {
    const res = await getPage("admin/chat/list", { ...dateAndKeyword, page });
    return res.data;
  };
  return { getPostMonitoring, getChatMonitoring };
};
