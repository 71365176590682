import { useState, useEffect, useRef } from "react";
import SearchByDate from "../SearchByDate";
import DataTable from "../DataTable";
import daySvg from "../../../img/일일.svg";
import weekSvg from "../../../img/주간.svg";
import monthSvg from "../../../img/월간.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { useWorkReportActions } from "../../../recoil/api/workReport";
import PaginationBar from "../PaginationBar";
import { downloadFiles } from "../../../utils";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import { REPORTTYPE } from "../../../JSON/enum/Dataenum";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import dayjs from "dayjs";
import { overMaxLength } from "../../../utils";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import WorkReportDownload from "../../form/WorkReportDownload";

const WorkReport = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const [selectTypeValue, setSelectTypeValue] = useState(state?.select || 0);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [dateAndKeyword, setDateAndKeyword] = useState(DateState);
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [employeeWorkstampTimes, setEmployeeWorkstampTimes] = useState();
  const [sn, setSn] = useState([]);

  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  // API 요청 함수 - workReportList 데이터 불러오기
  const workReportActions = useWorkReportActions();

  const loadWorkReportList = async () => {
    setLoading(true);
    if (state?.prev) {
      workReportActions
        .getWorkReportList({
          dateAndKeyword: state?.keyword,
          pageInfo: state?.page,
          type: state?.select || null,
        })
        .then((res) => {
          setLoading(false);
          if (res?.data) {
            setLoadedData(res.data.list);
            setPageInfo({ ...res.data.pageinfo, current: pageInfo.current });

            setSn([]);
            let dataSn = [];
            res.data.listAll.map((listAll) => {
              return dataSn.push(listAll.sn);
            });
            if (auth.user.isEmployee) {
              setEmployeeWorkstampTimes(res.data.employeeWorkstampTime);
            }
            setSn(dataSn);
          }
          state.prev = false;
        });
    } else {
      workReportActions
        .getWorkReportList({
          dateAndKeyword,
          pageInfo,
          type: selectTypeValue || null,
        })
        .then((res) => {
          setLoading(false);
          if (res?.data) {
            setLoadedData(res.data.list);
            setPageInfo({ ...res.data.pageinfo, current: pageInfo.current });

            setSn([]);
            let dataSn = [];
            res.data.listAll.map((listAll) => {
              return dataSn.push(listAll.sn);
            });
            if (auth.user.isEmployee) {
              setEmployeeWorkstampTimes(res.data.employeeWorkstampTime);
            }
            setSn(dataSn);
          }
        });
    }
  };

  const onClickWorkReport = (e) => {
    // 근로자 계정에서는 출근시간이 있는지 확인
    if (!!!employeeWorkstampTimes.workin_stamptime) {
      alert("출근 후 시도해주세요.");
      e.preventDefault();
    }
    if (
      !dayjs().isAfter(dayjs(employeeWorkstampTimes?.working_starttime_string))
    ) {
      alert("현재 업무시간이 아닙니다.");
      e.preventDefault();
    }
    if (
      dayjs().diff(
        dayjs(employeeWorkstampTimes?.working_endtime_string),
        "minute"
      ) <= -60
    ) {
      alert("업무일지는 퇴근 한 시간 전부터 등록 가능합니다.");
      e.preventDefault();
    }
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      loadWorkReportList();
    }
  }, [pageInfo?.current, dateAndKeyword, selectTypeValue]);

  useEffect(() => {
    if (state?.page) {
      setPageInfo((old) => {
        return {
          ...old,
          current: state?.page.current,
        };
      });
    }
  }, [state?.page]);

  // API 요청 함수 - workReport 데이터 삭제하기
  const deleteWorkReports = async (checkedItemIds) => {
    for (let i = 0; i < checkedItemIds.length; i++) {
      const req = { docsn: checkedItemIds[i] };
      // 파일 없어도 deletefile api 호출하는 쪽으로 일단 작성
      const res = await workReportActions.deleteWorkReport(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
      }
    }
    setCheckedItemIds([]);
    loadWorkReportList();
  };

  return (
    <div className="substanceCont">
      <BreadCrumb />
      {/* 업무보고 작성 버튼 - 근로자 계정에서 활성화 */}
      {auth.user.isEmployee && (
        <div id="reportCard">
          <Link
            to={`/${auth.accountType}/workreport_write/${REPORTTYPE.DAYILY}`}
            onClick={onClickWorkReport}
          >
            <button className="write-card border-line mouse" id="day_write">
              <p className="card-title zoom_font">
                일일업무보고
                {/* <span className="mobile-inline" style={{ color: "#333" }}>
                  03/07월
                </span> */}
              </p>
              <p className="card-cont">
                <img src={daySvg} className="hide-menu" />
                <span className="zoom_font">일일 업무보고 작성</span>
              </p>
            </button>
          </Link>

          <Link
            to={`/${auth.accountType}/workreport_write/${REPORTTYPE.WEEKILY}`}
            onClick={onClickWorkReport}
          >
            <button className="write-card border-line mouse" id="week_write">
              <p className="card-title zoom_font">
                주간업무보고
                {/* <span className="mobile-inline" style={{ color: "#333" }}>
                  03/07월
                </span> */}
              </p>
              <p className="card-cont">
                <img src={weekSvg} className="hide-menu" />
                <span className="zoom_font">주간 업무보고 작성</span>
              </p>
            </button>
          </Link>

          <Link
            to={`/${auth.accountType}/workreport_write/${REPORTTYPE.MONTHLIY}`}
            onClick={onClickWorkReport}
          >
            <button className="write-card border-line mouse" id="month_write">
              <p className="card-title zoom_font">
                월간업무보고
                {/* <span className="mobile-inline" style={{ color: "#333" }}>
                  03/07월
                </span> */}
              </p>
              <p className="card-cont">
                <img src={monthSvg} className="hide-menu" />
                <span className="zoom_font">월간 업무보고 작성</span>
              </p>
            </button>
          </Link>
        </div>
      )}
      <div className="date-filtering">
        <div className="all-group" style={{ margin: "10px 0 10px 0" }}>
          {/* 삭제 버튼 - 관리자 계정에서 활성화 */}
          {auth.user.accountType === ACCOUNTTYPE.ADMIN && (
            <div style={{ marginLeft: "10px" }}>
              <WorkReportDownload
                api={`${auth.accountType}/workreport`}
                params={{
                  // COMP_CD: company?.COMP_CD || null,
                  type: selectTypeValue,
                  starttime: dateAndKeyword?.starttime,
                  endtime: dateAndKeyword?.endtime,
                  keyword: dateAndKeyword?.keyword,
                }}
                name="업무일지 리스트"
              />
              <button
                className="trashbtn border-line mouse"
                style={{ marginLeft: "10px" }}
                type="button"
                onClick={() => {
                  if (window.confirm("삭제하시겠습니까?")) {
                    deleteWorkReports(checkedItemIds);
                  } else {
                    return;
                  }
                }}
              ></button>
            </div>
          )}
        </div>

        <SearchByDate
          period={3}
          onChange={(value) => setDateAndKeyword(value)}
          isNeedSelector={true}
          defaultValue={state?.keyword?.keyword}
          defaultStarttime={
            state?.keyword?.starttime === undefined
              ? undefined
              : dayjs(state?.keyword.starttime)
          }
          defaultEndtime={
            state?.keyword?.endtime === undefined
              ? undefined
              : dayjs(state?.keyword.endtime)
          }
          selectTypeValue={selectTypeValue}
          setSelectTypeValue={setSelectTypeValue}
          selectTypeOptions={[
            { value: REPORTTYPE.NONE, label: "전체" },
            { value: REPORTTYPE.DAYILY, label: "일일업무일지" },
            { value: REPORTTYPE.WEEKILY, label: "주간업무일지" },
            { value: REPORTTYPE.MONTHLIY, label: "월별업무일지" },
          ]}
          loading={loading}
        />
      </div>
      <DataTable
        loadedData={loadedData}
        loading={loading}
        setCheckedItemIds={setCheckedItemIds}
        checkbox={auth.user.isAdmin}
        columns={[
          "타입",
          "제목",
          "내용",
          "대상자",
          "등록일",
          "첨부파일",
          "확인여부",
        ]}
      >
        {loadedData?.map((obj) => {
          return (
            <tr
              className="text-center"
              key={obj?.sn}
              onClick={() => {
                navigate(`/${auth.accountType}/workreport_view/${obj?.sn}`, {
                  state: {
                    page: pageInfo,
                    sn: sn,
                    currentSn: obj?.sn,
                    keyword: dateAndKeyword,
                    select: selectTypeValue,
                  },
                });
              }}
            >
              {/* 체크 박스 - 관리자 계정일 때, 활성화 */}
              {auth.user.isAdmin && (
                <td className="hide" onClick={(e) => e.stopPropagation()}>
                  <div className="agree">
                    <label>
                      <input
                        type="checkbox"
                        name="personinfo"
                        onChange={(e) => {
                          onChangeCheckBoxHandler(
                            e.currentTarget.checked,
                            obj.sn
                          );
                        }}
                        checked={
                          checkedItemIds?.includes(obj.sn) ? true : false
                        }
                      />
                      {checkedItemIds?.includes(obj.sn) ? (
                        <div className="chkbox border-line mouse checked"></div>
                      ) : (
                        <div className="chkbox border-line mouse "></div>
                      )}
                    </label>
                  </div>
                </td>
              )}
              <td id="_hash" style={{ display: "none" }}></td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.type === REPORTTYPE.DAYILY && <>일일</>}
                {obj?.type === REPORTTYPE.WEEKILY && <>주간</>}
                {obj?.type === REPORTTYPE.MONTHLIY && <>월간</>}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {overMaxLength(obj?.title, 30)}
              </td>
              <td
                className="border-line mouse zoom_font zoom_font_id"
                id="workreport_view/{{ k }}"
                style={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: overMaxLength(obj?.contents, 30),
                }}
              ></td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.name}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {dayjs(obj?.ymd).format("YYYY-MM-DD")}
              </td>
              {obj?.url >= 1 ? (
                <td onClick={(e) => e.stopPropagation()}>
                  <button
                    className="t-download border-line mouse zoom_font"
                    type="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      try {
                        downloadFiles(
                          obj,
                          workReportActions.getWorkReportFileUrl,
                          { docsn: obj?.sn },
                          `업무일지_`,
                          0
                        );
                      } catch {
                        alert(
                          "서버 이전으로 자료를 옮기고 있습니다.\n빠른 시일내로 정상화 하겠습니다."
                        );
                      }
                    }}
                  ></button>
                </td>
              ) : (
                <td onClick={(e) => e.stopPropagation()}></td>
              )}
              <td onClick={(e) => e.stopPropagation()}>
                {obj?.state === "Y" && (
                  <button className="confirm greenbtn border-line mouse zoom_font">
                    확인
                  </button>
                )}
                {obj?.state === "N" && (
                  <button className="confirm graybtn border-line mouse zoom_font">
                    미확인
                  </button>
                )}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default WorkReport;
