import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { getPage, get, post, uploadfile, delBody } from ".";
import {
  authAtom,
  ElecBase64ArrayAtom,
  ElecEsnAtom,
  ElecLabelAtom,
  ElecPageInfoAtom,
  ElecURLAtom,
} from "..";
import { useAccoutTypeActions } from "./auth";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { JOINDOCTYPE } from "../../JSON/enum/Dataenum";

export const getElecNameByType = (type) => {
  switch (type) {
    case JOINDOCTYPE.work_contract:
      return "근로계약서";
    case JOINDOCTYPE.digital_sign:
      return "전자서명동의서";
    case JOINDOCTYPE.remotework:
      return "재택근무보안서약서";
    case JOINDOCTYPE.personalagree:
      return "개인정보동의서";
    case JOINDOCTYPE.secretagree:
      return "비밀유지서약서";
    case JOINDOCTYPE.idcerti:
      return "주민등록등본";
    case JOINDOCTYPE.degree:
      return "최종학교졸업증명서";
    case JOINDOCTYPE.account:
      return "통장사본";
    case JOINDOCTYPE.disable:
      return "중증장애인확인서";
    case JOINDOCTYPE.health:
      return "건강보험득실확인서";
    case JOINDOCTYPE.voucher:
      return "복지카드";
    case JOINDOCTYPE.additional:
      return "추가요청서류";
    default:
      return "기타";
  }
};
export const useStageRef = () => {};

export const useElecActions = () => {
  const navigate = useNavigate();

  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const auth = useRecoilValue(authAtom);
  const [pageInfo, setPageInfo] = useRecoilState(ElecPageInfoAtom);
  const accountType = useAccoutTypeActions();
  const esn = useRecoilValue(ElecEsnAtom);
  const docURL = useRecoilValue(ElecURLAtom);
  const elecBase64Array = useRecoilValue(ElecBase64ArrayAtom);

  return {
    getStamp,
    uploadEmployeeDocFile,
    getDocDocByType,
    getEmployeeDoc,
    getCommonDoc,
    getDocContractByCompanyId,
    getDocList,
    getCompanyJoinDocEmployeeList,
    createElec,
    uploadDocFile,
    companySignatureElec,
    employeeSignatureElec,
    getStampCompanyUser,
    delDocByType,
  };

  async function getStampCompanyUser(data) {
    const res = await get("sign/enscomp", data);
    return res;
  }

  async function getStamp() {
    let params = {};
    if (accountType.isCompany()) params.id = auth.user.id;
    if (accountType.isEmployee()) params.id = auth.user.id;
    const res = await get(`sign/${auth.accountType}`, params);
    return res;
  }

  async function uploadEmployeeDocFile(data) {
    let params = {
      ...data,
    };

    const [file, fileRes] = await uploadfile(
      { file: params.file[0] },
      uuidv4(),
      "doc"
    );

    const res = await post("employee/joindoc/regist", {
      type: data.type,
      id: auth.user.id,
      url: `${fileRes.data}?alt=media`,
      filename: file.name,
      extension: file.name.split(".").pop(),
    });

    if (res.status === 200) {
      toast.success("문서 등록에 성공했습니다.", {
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return res;
  }
  async function getDocDocByType(data) {
    const res = await get("select/joindoc/doclist/type", data);
    return res;
  }

  async function delDocByType(data) {
    const res = await delBody("select/joindoc/doclist/type", data);
    return res;
  }

  async function getEmployeeDoc(data) {
    const res = await get("employee/joindoc/contract", {
      type: data,
      // esn: auth.user.esn,
    });
    return res;
  }

  async function getCommonDoc(type, contract_sn) {
    const res = await get("select/joindoc/doc/common", { type, contract_sn });
    return res;
  }
  async function getDocContractByCompanyId(data) {
    let params = {
      ...data,
      companyId: auth.user.id,
    };
    const res = await get("company/joindoc/contract/preregist", params);
    return res;
  }

  async function uploadDocFile(data) {
    let params = {
      ...data,
    };

    const [file, fileRes] = await uploadfile(
      { file: params.file[0] },
      uuidv4(),
      "doc"
    );

    const res = await post("company/joindoc/contract/preregist", {
      esn: data.esn,
      companyId: auth.user.id,
      url: `${fileRes.data}?alt=media`,
      filename: file.name,
      extention: file.name.split(".").pop(),
    });

    return res;
  }

  async function getDocList(data) {
    //company 일 땐 esn, COMP_CD 넘기기
    //employee 일 땐 id 넘기기
    const res = await get("select/joindoc/doclist", data);
    return res;
  }

  async function getCompanyJoinDocEmployeeList(data) {
    let params = {
      ...data,
    };

    if (accountType.isCompany()) params.id = auth.user.id;

    const res = await getPage(
      `${auth.accountType}/joindoc/employeeList`,
      params
    );
    return res;
  }

  async function createElec() {
    if (window.confirm("문서를 생성하시겠습니까?")) {
      setPageInfo({ ...pageInfo, current: 1 });
      navigate("/elec/sign/1", {
        state: {
          sample: false,
        },
      });
    }
    return false;
  }

  async function employeeSignatureElec({ blob, type }) {
    if (window.confirm("저장하시겠습니까?")) {
      const [file, fileRes] = await uploadfile({ file: blob }, uuidv4());

      const res = await post("employee/joindoc/regist", {
        type: parseInt(type),
        id: auth.user.id,
        url: `${fileRes.data}?alt=media`,
        filename: getElecNameByType(parseInt(type)),
        extension: "pdf",
      });

      if (res.status === 200) {
        toast.success("문서 등록에 성공했습니다.", {
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return navigate("/employee/joindoc");
      }

      return res;
    }
  }

  async function companySignatureElec(type, labels) {
    if (
      labels
        .filter((item) => item.isCompany === true)
        .every((item) => item.isFulfilled === true)
    ) {
      if (window.confirm(`저장하시겠습니까?`)) {
        let params = {
          type: parseInt(type),
          esn: esn,
          companyId: auth.user.id,
          url: docURL,
          label: JSON.stringify(labels),
        };

        const res = await post("company/joindoc/contract/regist", params);
        if (res.status === 200) {
          toast.success("문서 등록에 성공했습니다.", {
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          return navigate("/company/eplydoc");
        }
      }
    } else {
      alert("입력되지 않은 항목이 있습니다.");
    }
  }
};
