import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import { useWorkstampActions } from "../../../recoil/api/workstamp";
import { PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import SearchKeyword from "../../common/SearchKeyword";

const Working = () => {
  const workstampActions = useWorkstampActions();
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [worktimeList, setWorktimeList] = useState([]);
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [YM, setYM] = useState(dayjs().format("YYYY-MM"));
  const [company, setCompany] = useState({});
  const [searchWord, setSearchWord] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWorktime();
  }, [pageInfo.current, company, YM, searchWord]);

  const getWorktime = async () => {
    setLoading(true);
    setWorktimeList([]);
    const res = await workstampActions.getWorktime({
      pageInfo,
      COMP_CD: company.COMP_CD || null,
      keyword: searchWord,
      thisyear: YM.slice(0, 4),
      thismonth: YM.slice(5, 7),
    });
    if (res) {
      setPageInfo(res.data.pageinfo);
      setWorktimeList(
        res.data.list.map((list) => {
          return { ...list, edit: false };
        })
      );
    }
    setLoading(false);
  };

  const changeWorktimeList = (sn, key, value) => {
    const newWorktimeList = worktimeList.map((newWorktime) => {
      if (newWorktime.sn === sn) {
        return { ...newWorktime, [key]: value };
      } else {
        return newWorktime;
      }
    });

    setWorktimeList(newWorktimeList);
  };

  const changeWorktime = async (worktime) => {
    const res = await workstampActions.changeWorktime({
      esn: parseInt(worktime.esn),
      COMP_CD: parseInt(worktime.COMP_CD),
      thisyear: parseInt(worktime.thisyear),
      thismonth: parseInt(worktime.thismonth),
      workday: parseInt(worktime.workday),
      disappearday: parseInt(worktime.disappearday),
      lateday: parseInt(worktime.lateday),
      vacationday: parseInt(worktime.vacationday),
      monthLateMinute: parseInt(worktime.monthLateMinute),
      workingMinute: parseInt(worktime.workingMinute),
    });

    if (res.status === 200) return res;
    else return false;
  };

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div
            style={{ marginRight: 4 }}
            className="btn-group-left btn-group-inline"
          >
            <button
              className="secondarybtn-md border-line zoom_font"
              type="button"
              onClick={() => {
                setIsSearchCompanyModalOpen(true);
              }}
            >
              {company.company_name || "기업 선택"}
            </button>
          </div>
          <input
            className="date-input custom-input border-line zoom_font"
            style={{
              border: "1px solid #eee",
              borderRadius: 4,
              minWidth: 100,
            }}
            type="month"
            min="2018-01"
            max={dayjs().format("YYYY-MM")}
            value={YM}
            onChange={(e) => {
              setYM(e.target.value);
            }}
          />
        </div>
        <div className="all-group">
          <SearchKeyword placeholder="이름 입력" setKeyword={setSearchWord} />
        </div>
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={[
          "날짜",
          "기업명",
          "이름",
          "출근일",
          "결석일",
          "지각횟수",
          "휴가",
          "월 지각(분)",
          "월 근로시간(분)",
        ]}
      >
        {worktimeList.map((worktime, idx) => {
          return (
            <tr key={idx}>
              <td className="border-line zoom_font">{`${worktime.thisyear}년-${worktime.thismonth}월`}</td>
              <td className="border-line zoom_font">{worktime.companyName}</td>
              <td className="border-line zoom_font">{worktime.name}</td>

              <td className="border-line zoom_font">
                {worktime.edit && (
                  <input
                    style={{ width: 50 }}
                    type="number"
                    value={worktime.workday}
                    onChange={(e) => {
                      changeWorktimeList(
                        worktime.sn,
                        "workday",
                        e.target.value
                      );
                    }}
                  />
                )}
                {!worktime.edit && worktime.workday}
              </td>
              <td className="border-line zoom_font">
                {worktime.edit && (
                  <input
                    style={{ width: 50 }}
                    type="number"
                    value={worktime.disappearday}
                    onChange={(e) => {
                      changeWorktimeList(
                        worktime.sn,
                        "disappearday",
                        e.target.value
                      );
                    }}
                  />
                )}
                {!worktime.edit && worktime.disappearday}
              </td>
              <td className="border-line zoom_font">
                {worktime.edit && (
                  <input
                    style={{ width: 50 }}
                    type="number"
                    value={worktime.lateday}
                    onChange={(e) => {
                      changeWorktimeList(
                        worktime.sn,
                        "lateday",
                        e.target.value
                      );
                    }}
                  />
                )}
                {!worktime.edit && worktime.lateday}
              </td>
              <td className="border-line zoom_font">
                {worktime.edit && (
                  <input
                    style={{ width: 50 }}
                    type="number"
                    value={worktime.vacationday}
                    onChange={(e) => {
                      changeWorktimeList(
                        worktime.sn,
                        "vacationday",
                        e.target.value
                      );
                    }}
                  />
                )}
                {!worktime.edit && worktime.vacationday}
              </td>
              <td className="border-line zoom_font">
                {worktime.edit && (
                  <input
                    style={{ width: 50 }}
                    type="number"
                    value={worktime.lateMinute}
                    onChange={(e) => {
                      changeWorktimeList(
                        worktime.sn,
                        "lateMinute",
                        e.target.value
                      );
                    }}
                  />
                )}
                {!worktime.edit && worktime.lateMinute}
              </td>
              <td className="border-line zoom_font">
                {worktime.edit && (
                  <input
                    style={{ width: 50 }}
                    type="number"
                    value={worktime.workingMinute}
                    onChange={(e) => {
                      changeWorktimeList(
                        worktime.sn,
                        "workingMinute",
                        e.target.value
                      );
                    }}
                  />
                )}
                {!worktime.edit && worktime.workingMinute}
              </td>

              {/* <td>
                {worktime.edit && (
                  <button
                    className="primarybtn-sm border-line zoom_font"
                    onClick={async () => {
                      const res = await changeWorktime(worktime);
                      if (res) {
                        setToast({ ...toast, on: true });
                        changeWorktimeList(worktime.sn, "edit", false);
                      }
                    }}
                  >
                    완료
                  </button>
                )}
                {!worktime.edit && (
                  <button
                    className="pinkbtn-sm border-line zoom_font"
                    onClick={() => {
                      changeWorktimeList(worktime.sn, "edit", true);
                    }}
                  >
                    수정
                  </button>
                )}
              </td> */}
            </tr>
          );
        })}
      </DataTable>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default Working;
