import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Select from "react-select";
import { dateUtils } from "../../utils";
import dayjs from "dayjs";
import { searchRegex } from "../../utils/regex";

const SearchByDate = ({
  dateAndKeyword,
  onChange = () => {},
  isNeedSelector = false,
  isNeedSearchBar = true,
  prevSelect,
  select,
  setSelectTypeValue,
  selectTypeValue,
  selectTypeOptions,
  period = 6,
  isNeedPeriodButtons = true,
  defaultStarttime,
  defaultEndtime,
  defaultValue,
  placeholder = "제목 또는 이름 입력",
  oneday = false,
  loading = false,
}) => {
  // 날짜, 시간 상태 값
  const [starttime, setStarttime] = useState(
    defaultStarttime || dayjs().subtract(period, "day")
  );
  const [endtime, setEndtime] = useState(defaultEndtime || dayjs());
  const [keyword, setKeyword] = useState(defaultValue || "");

  const setDateAndKeyword = (newDateAndKeyword) => {
    if (
      dateAndKeyword?.starttime !== newDateAndKeyword.starttime ||
      dateAndKeyword?.endtime !== newDateAndKeyword.endtime ||
      dateAndKeyword?.keyword !== newDateAndKeyword.keyword
    ) {
      onChange(newDateAndKeyword);
    }
  };

  // 로딩 상태 값
  const [pageLoaded, setPageLoaded] = useState(false);

  const firstUpdate = useRef(true);
  // useEffect
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    setPageLoaded(true);
  });

  useEffect(() => {
    if (pageLoaded) {
      setDateAndKeyword({
        starttime: starttime.format("YYYYMMDD"),
        endtime: endtime.format("YYYYMMDD"),
        keyword,
      });
    }
  }, [pageLoaded]);

  useEffect(() => {
    if (
      (starttime?.isBefore(endtime) || starttime?.isSame(endtime)) &&
      pageLoaded
    ) {
      setDateAndKeyword({
        starttime: starttime.format("YYYYMMDD"),
        endtime: endtime.format("YYYYMMDD"),
        keyword,
      });
    }
  }, [starttime, endtime]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (pageLoaded) {
        setDateAndKeyword({
          starttime: starttime.format("YYYYMMDD"),
          endtime: endtime.format("YYYYMMDD"),
          keyword,
        });
        setKeyword(keyword);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [keyword]);

  const setDate = (newstarttime, newendtime) => {
    setStarttime(newstarttime);
    setEndtime(newendtime);
  };

  return (
    <div
      className="all-group"
      style={{ display: "flex", pointerEvents: loading === true ? "none" : "" }}
    >
      {isNeedPeriodButtons && (
        <div style={{ display: "flex" }}>
          <div className="datebox float-left ">
            {oneday === true ? (
              <>
                <button
                  onClick={() => setDate(dayjs(), dayjs())}
                  className="daybtn border-line mouse zoom_font"
                  type="button"
                >
                  &nbsp;&nbsp;1일&nbsp;&nbsp;
                </button>
              </>
            ) : (
              <></>
            )}

            <button
              onClick={() => setDate(dayjs().subtract(1, "week"), dayjs())}
              className="daybtn border-line mouse zoom_font"
              type="button"
            >
              1주일
            </button>

            <button
              onClick={() => setDate(dayjs().subtract(1, "month"), dayjs())}
              className="daybtn border-line mouse zoom_font"
              type="button"
            >
              1개월
            </button>
            {oneday === true ? (
              <></>
            ) : (
              <button
                onClick={() => setDate(dayjs().subtract(3, "month"), dayjs())}
                className="daybtn border-line mouse zoom_font"
                type="button"
              >
                3개월
              </button>
            )}
          </div>
        </div>
      )}

      <div className="mobile-w-100 all-group float-left flex-direction-row">
        <div className="searchbtn-group">
          {isNeedSelector && (
            <div className="select-width select-border-line">
              <Select
                defaultValue={selectTypeOptions.filter(
                  (item) => item.value === selectTypeValue
                )}
                options={selectTypeOptions}
                onChange={(obj) => {
                  setSelectTypeValue(obj.value);
                }}
                placeholder="선택"
                style={{ width: "120px" }}
              />
            </div>
          )}
          <div>{select}</div>

          <div className="inline-block mobile-w-100">
            <input
              min="2018-01-01"
              max={dateUtils.maxYMD()}
              className="date-input custom-input border-line mouse zoom_font"
              type="date"
              onChange={(e) => {
                if (dayjs(e.target.value).isBefore(endtime))
                  setStarttime(dayjs(e.target.value));
                else if (
                  dayjs(e.target.value).isAfter(endtime) ||
                  dayjs(e.target.value).isSame(endtime)
                ) {
                  setStarttime(dayjs(e.target.value));
                  setEndtime(dayjs(e.target.value));
                } else {
                  alert("날짜 범위 선택이 잘못되었습니다.");
                }
              }}
              value={starttime.format("YYYY-MM-DD")}
              style={{ paddingRight: "15px" }}
            />
            <span className="wave">-</span>
            <input
              min="2018-01-01"
              max={dateUtils.maxYMD()}
              className="date-input custom-input border-line mouse zoom_font"
              type="date"
              onChange={(e) => {
                if (dayjs(starttime).isBefore(dayjs(e.target.value)))
                  setEndtime(dayjs(e.target.value));
                else if (
                  dayjs(starttime).isAfter(dayjs(e.target.value)) ||
                  dayjs(starttime).isSame(dayjs(e.target.value))
                ) {
                  setStarttime(dayjs(e.target.value));
                  setEndtime(dayjs(e.target.value));
                } else {
                  alert("날짜 범위 선택이 잘못되었습니다.");
                }
              }}
              value={endtime.format("YYYY-MM-DD")}
              style={{ paddingRight: "15px" }}
            />
          </div>

          {isNeedSearchBar && (
            <div className="relative width-100 float-right">
              <input
                className="custom-input txt-search border-line mouse zoom_font"
                type="text"
                placeholder={placeholder}
                value={keyword}
                onInput={(e) => {
                  if (searchRegex.test(e.target.value)) {
                    e.target.value = e.target.value.replace(searchRegex, "");
                  }
                  setKeyword(e.target.value);
                }}
              ></input>

              <div className="inputsearch">
                <button className="searchbtn border-line mouse zoom_font"></button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchByDate;
