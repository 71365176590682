import { useRecoilValue } from "recoil";
import { get, put } from ".";
import { authAtom } from "..";

export const useDocformActions = () => {
  const auth = useRecoilValue(authAtom);
  return { getDocFormList, putDocForm, getEployDocUrl };
  async function getDocFormList() {
    const res = await get(`${auth.accountType}/docformlist`);
    return res;
  }

  async function putDocForm(data) {
    const res = await put("company/docform", data);
    return res;
  }

  async function getEployDocUrl(data) {
    return get(`select/joindoc/doclist/type`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
};
