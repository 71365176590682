import axios from "axios";
import dayjs from "dayjs";
import Cookie from "js-cookie";
import { decodeToken } from "react-jwt";
import { SERVER } from ".";

const refresh = async (config) => {
  const refreshtoken = Cookie.get("refreshToken");
  const expireAt = localStorage.getItem("expiresAt");
  let token = localStorage.getItem("accessToken");
  const auth = JSON.parse(localStorage.getItem("auth"));

  // 토큰이 만료되었고, refreshToken 이 저장되어 있을 때
  if (dayjs(expireAt * 1000).diff(dayjs()) < 0 && !!refreshtoken && !!auth) {
    alert("토큰 만료");
    const body = {
      id: auth.user.payload.user_id,
      refreshtoken,
    };

    // 토큰 갱신 서버통신
    const { data } = await axios
      .post(`${SERVER}login/${auth.user.accountType}/accesstoken`, body)
      .catch((err) => {
        localStorage.clear();
      });
    const payload = decodeToken(data.accesstoken);
    token = data.accesstoken;
    localStorage.setItem("accessToken", token);
    localStorage.setItem("expiresAt", payload.exp);
  }

  config.headers["authorization"] = `${token}`;

  return config;
};

const refreshErrorHandle = (err) => {
  console.log(err);
  alert("로그아웃 되었습니다.");
  localStorage.clear();
  Cookie.remove("refreshToken");
};

export { refresh, refreshErrorHandle };
