import { useEffect, useState } from "react";
import useMainActions from "../../../recoil/api/main";

const CompanyMemberCnt = () => {
  const mainActions = useMainActions();
  const [employeeCnt, setEmployeeCnt] = useState(0);
  const [vacationCnt, setVacationCnt] = useState(0);

  useEffect(() => {
    getCompanyEmployeeCnt();
    getCompanyVacationCnt();
  }, []);

  const getCompanyEmployeeCnt = async () => {
    const res = await mainActions.getCompanyEmployeeCnt();
    if (res.status === 200) {
      setEmployeeCnt(res.data.cnt);
    }
  };

  const getCompanyVacationCnt = async () => {
    const res = await mainActions.getCompanyVacationCnt();
    if (res.status === 200) {
      setVacationCnt(res.data.cnt);
    }
  };

  return (
    <>
      <div className="total-people zoom_font">
        <span className="zoom_font">회원 수</span>
        <span className="zoom_font">{employeeCnt}명</span>
      </div>
      <div className="annual-leave zoom_font">
        <span className="zoom_font"> &#9829; 연차 결재 대기&nbsp;</span>
        <span className="zoom_font">{vacationCnt}건</span>
      </div>
    </>
  );
};

export default CompanyMemberCnt;
