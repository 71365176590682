import { useRecoilValue, useSetRecoilState } from "recoil";
import { delBody, get, post, put, uploadfile } from ".";
import { authAtom, MailListUpdateAtom, MailViewAtom } from "..";
import { MAIL_VIEW } from "../../component/common/mail/MailLayout";
import { getPage } from ".";

export const useMailActions = () => {
  const auth = useRecoilValue(authAtom);
  const view = useRecoilValue(MailViewAtom);
  const mailListUpdate = useSetRecoilState(MailListUpdateAtom);

  return {
    editMail,
    deleteMail,
    readMail,
    getUnreadMailCnt,
    sendMail,
    getReceivedMailList,
    getSendMailList,
    getReservedMailList,
  };

  async function deleteMail({ snList }) {
    let api = "";

    if (view === MAIL_VIEW.RECEIVED_MAIL) api = "receive";
    if (view === MAIL_VIEW.SENT_MAIL) api = "send";
    else if (view === MAIL_VIEW.RESERVATION_MAIL) {
      api = "reserved";
      const res = await delBody(`note/${api}`, {
        snList,
        id: auth.user.id,
      });
      if (res.status === 200) {
        mailListUpdate((update) => update + 1);
      }
      return;
    }

    const res = await put(`note/${api}`, {
      snList,
      id: auth.user.id,
    });

    if (res.status === 200) {
      mailListUpdate((update) => update + 1);
    }
  }

  async function readMail(sn) {
    const res = await put("note/read", { sn, id: auth.user.id });
    return res;
  }

  async function getUnreadMailCnt() {
    let params = {};

    if (auth.user.isAdmin) params.id = auth.user.id;
    if (auth.user.isCompany) params.id = auth.user.id;
    if (auth.user.isEmployee) params.id = auth.user.id;

    const res = await get("note/read", params);
    return res;
  }

  async function editMail(data) {
    let params = {
      ...data,
      title: data.title.trim() === "" ? "[제목없음]" : data.title,
    };

    if (
      data?.file_contents?.length !== 0 &&
      data.file_contents !== null &&
      typeof data.file_contents[0] === "object"
    ) {
      const [file, fileRes] = await uploadfile({ file: data.file_contents[0] });
      params = {
        ...params,
        file_contents: {
          contents: {
            filename: file.name,
            extention: file.name.split(".").pop(),
            url: `${fileRes.data}?alt=media`,
          },
        },
      };
    } else {
      params = {
        ...params,
      };
    }
    const res = await put("note/reserved", params);
    return res;
  }

  async function sendMail(data) {
    let params = {
      ...data,
      title: data.title.trim() === "" ? "[제목없음]" : data.title,
    };
    if (data.file_contents.length !== 0) {
      const [file, fileRes] = await uploadfile({ file: data.file_contents[0] });
      params = {
        ...params,
        file_contents: {
          contents: {
            filename: file.name,
            extention: file.name.split(".").pop(),
            url: `${fileRes.data}?alt=media`,
          },
        },
      };
    } else {
      params = {
        ...params,
        file_contents: null,
      };
    }
    const res = await post("note", params);
    return res;
  }

  async function getReceivedMailList({ keyword, pageInfo }) {
    let params = {
      keyword: keyword,
      page: pageInfo.current,
      count: 10,
      starttime: "",
      endtime: "",
    };
    params.id = auth.user.id;
    return getPage("note/receive", params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getSendMailList({ keyword, pageInfo }) {
    let params = {
      keyword: keyword,
      page: pageInfo.current,
      count: 10,
      starttime: "",
      endtime: "",
    };
    params.id = auth.user.id;
    return getPage("note/send", params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getReservedMailList({ keyword, pageInfo }) {
    let params = {
      keyword: keyword,
      page: pageInfo.current,
      count: 10,
      starttime: "",
      endtime: "",
    };
    params.id = auth.user.id;
    return getPage("note/reserved", params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
};
