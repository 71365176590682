import Logo from "../../img/logo.svg";
import Commute from "../../img_new/pill_img_1.svg";
import Journal from "../../img_new/pill_img_2.svg";
import Acess from "../../img_new/pill_img_3.svg";

import Message from "../../ico/icon_쪽지.svg";
import Chatting from "../../ico/icon_채팅.svg";
import Logout from "../../ico/icon_로그아웃.svg";
import { Link } from "react-router-dom";
import { useUserActions } from "../../recoil/api/auth";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  ChatModalAtom,
  MailModalAtom,
  MobileSideShowAtom,
  RoomListAtom,
  UnreadMailCntQuery,
} from "../../recoil";
import React, { useEffect, useState } from "react";
import Accessibility from "./Accessibility";
import Hamburger from "../../img/hamburger-menu.svg";
import { ACCOUNTTYPE } from "../../JSON/enum/account";
import useChatActions from "../../recoil/api/chatting";
import { socket } from "../../socket";
import { NavLink } from "react-router-dom";

const MailBox = ({ setMailModalOpen }) => {
  const totalMailReadCnt = useRecoilValue(UnreadMailCntQuery());

  return (
    <button
      id="gnbbtn"
      className="gnbbtn border-line mouse"
      type="button"
      onClick={() => setMailModalOpen(true)}
    >
      <img src={Message} alt="" />
      <p className="zoom_font">쪽지</p>
      <ReadCntAlarm cnt={totalMailReadCnt} />
    </button>
  );
};
const ReadCntAlarm = ({ cnt }) => {
  return (
    <>
      {cnt === 99 && <div className="alert-num">99+</div>}
      {cnt === 0 && null}
      {cnt > 0 && cnt < 99 && <div className="alert-num">{cnt}</div>}
    </>
  );
};

const Header = ({ children }) => {
  const userActions = useUserActions();
  const auth = useRecoilValue(authAtom);
  const [isAccessOpen, setIsAccessOpen] = useState(false);
  const setMailModalOpen = useSetRecoilState(MailModalAtom);
  const setChatModalOpen = useSetRecoilState(ChatModalAtom);
  const setMobileSideShow = useSetRecoilState(MobileSideShowAtom);
  const roomList = useRecoilValue(RoomListAtom);
  const [totalChatReadCnt, setTotalChatReadCnt] = useState(0);
  const chatActions = useChatActions();

  const onLogout = async () => {
    return userActions.logout();
  };

  useEffect(() => {
    setTotalChatReadCnt(0);
    let chatCount = 0;
    if (!!roomList) {
      for (let room of roomList) {
        // if (room.lastestcontents_name === auth.user.userName) {
        //   continue;
        // } else {
        chatCount = chatCount + room.chatindex - room.read_index;
        if (chatCount > 99) {
          chatCount = 99;
          break;
        }
        // }
      }

      setTotalChatReadCnt(chatCount);
    }
  }, [roomList]);

  useEffect(() => {
    socket.connect();
    chatActions.enterLobby();
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket server connect.", new Date());
      chatActions.enterLobby();
    });

    socket.on("disconnect", () => {
      console.log("socket server disconnect.", new Date());
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [socket.active]);

  const reloadCheck = () => {
    const currentPathname = window.location.pathname;
    const segments = currentPathname.split("/");
    const lastSegment = segments[segments.length - 1];
    return lastSegment;
  };

  return (
    <>
      <div className="header">
        <h1 className="logo border-line mouse">
          {reloadCheck() === "home" ? (
            <a className="pointer" onClick={() => window.location.reload()}>
              <img src={Logo} alt="" />
            </a>
          ) : (
            <Link to={`/${auth.accountType}/home`}>
              <img src={Logo} alt="" />
            </Link>
          )}
        </h1>
        <div className="gnb">
          <div className="pillbtn float-left hide-menu">
            <Link to={`/${auth.accountType}/workstamp`}>
              <button
                className="pill blue border-line mouse zoom_font"
                type="button"
              >
                <img src={Commute} alt="" />
                출퇴근
              </button>
            </Link>
            <Link to={`/${auth.accountType}/workreport`}>
              <button
                className="pill pink border-line mouse zoom_font"
                type="button"
              >
                <img src={Journal} alt="" />
                업무일지
              </button>
            </Link>
            <button
              className="pill gray border-line mouse zoom_font"
              type="button"
              onClick={() => setIsAccessOpen(true)}
            >
              <img src={Acess} alt="" />
              접근성
            </button>
            {isAccessOpen && (
              <Accessibility setIsAccessOpen={setIsAccessOpen} />
            )}
          </div>
          <div className="utillbtn float-left">
            <React.Suspense
              fallback={
                <button
                  id="gnbbtn"
                  className="gnbbtn border-line mouse"
                  type="button"
                >
                  <div>
                    <img src={Message} alt="" />
                  </div>
                </button>
              }
            >
              <MailBox setMailModalOpen={setMailModalOpen} />
            </React.Suspense>

            <button
              className="gnbbtn border-line mouse"
              type="button"
              onClick={() => setChatModalOpen(true)}
            >
              <img src={Chatting} alt="" />
              <p className="zoom_font">채팅</p>
              <ReadCntAlarm cnt={totalChatReadCnt} />
            </button>

            {auth?.user.accountType !== ACCOUNTTYPE.ADMIN && children}
            <form style={{ display: "inline-block" }}>
              <button
                className="gnbbtn border-line mouse"
                type="button"
                onClick={(e) => onLogout(e)}
              >
                <img src={Logout} alt="" />
                <p className="zoom_font">로그아웃</p>
              </button>
            </form>
          </div>
        </div>

        <div className="mobile-menu" onClick={() => setMobileSideShow(true)}>
          <img src={Hamburger} alt="" />
        </div>
      </div>
      <div className="all-group mobile-block">
        <div className="pillbtn float-left">
          <Link to={`/${auth.accountType}/workstamp`}>
            <button
              className="pill blue border-line mouse zoom_font"
              type="button"
            >
              <img src={Commute} alt="" />
              출퇴근
            </button>
          </Link>
          <Link to={`/${auth.accountType}/workreport`}>
            <button
              className="pill pink border-line mouse zoom_font"
              type="button"
            >
              <img src={Journal} alt="" />
              업무일지
            </button>
          </Link>
          {/* <button className="pill gray" type="button">
            <img
              src={Acess}
              alt=""
            />
            접근성
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Header;
