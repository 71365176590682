import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { DetailMailQuery, DetailSnAtom } from "../../../recoil";
import { usePostMailActions } from "../../../recoil/api/monitoring";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import { FileContent } from "../../common/mail/SentMailDetail";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import CommonModal from "../../modal/CommonModal";
import dayjs from "dayjs";
import { isPageChanged } from "../../../utils";
import DOMPurify from "dompurify";

const PostMonitoring = () => {
  const actions = usePostMailActions();
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [dateAndKeyword, setDateAndKeyword] = useState({
    ...DateState,
    starttime: dayjs().subtract(6, "day").format("YYYYMMDD"),
  });
  const [changeCheck, setChangeCheck] = useState();
  const [isMonitoringModalOpen, setIsMonitoringModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const setDetailSn = useSetRecoilState(DetailSnAtom);
  const [mailMonitoringList, setMailMonitoringList] = useState();

  const monitoringData = async (pageInfo, dateAndKeyword) => {
    setLoading(true);
    const res = await actions.getPostMonitoring({ pageInfo, dateAndKeyword });
    setMailMonitoringList(res);
    setPageInfo(res.pageinfo);
    setLoading(false);
  };

  useEffect(() => {
    if (isPageChanged(changeCheck, dateAndKeyword, pageInfo)) {
    } else {
      setChangeCheck({ ...dateAndKeyword, current: pageInfo?.current });
      monitoringData(pageInfo, dateAndKeyword);
    }
  }, [pageInfo?.current, dateAndKeyword]);

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group"></div>
        <div className="all-group">
          <SearchByDate
            onChange={(value) => {
              setDateAndKeyword(value);
            }}
            loading={loading}
          />
        </div>
      </div>
      <DataTable checkbox={false} loading={loading}>
        <tr className="text-center-title">
          <th colSpan={3}>보낸회원</th>
          <th colSpan={3}>받는회원</th>
          <th colSpan={2}>시간</th>
          <th rowSpan={2}>첨부파일</th>
        </tr>
        <tr className="text-center-title">
          <th>업체명</th>
          <th>아이디</th>
          <th>이름</th>
          <th>업체명</th>
          <th>아이디</th>
          <th>이름</th>
          <th>보낸시간</th>
          <th>읽은시간</th>
        </tr>
        {mailMonitoringList?.list?.map((mail, idx) => {
          return (
            <tr
              className="pointer"
              key={idx}
              onClick={() => {
                setIsMonitoringModalOpen(true);
                setDetailSn(mail.sn);
              }}
            >
              <td className="border-line mouse zoom_font">
                {mail.sender_company || "-"}
              </td>
              <td className="border-line mouse zoom_font">{mail.sender_id}</td>
              <td className="border-line mouse zoom_font">
                {mail.sender_name}
              </td>
              <td className="border-line mouse zoom_font">
                {mail.receiver_company || "-"}
              </td>
              <td className="border-line mouse zoom_font">
                {mail.receiver_id}
              </td>
              <td className="border-line mouse zoom_font">
                {mail.receiver_name}
              </td>
              <td className="border-line mouse zoom_font">{mail.createtime}</td>
              <td className="border-line mouse zoom_font">{mail.readtime}</td>
              {JSON.parse(mail.file_contents)?.contents ? (
                <td>
                  <button
                    className="t-download border-line mouse zoom_font"
                    type="button"
                    style={{ cursor: "pointer" }}
                  ></button>
                </td>
              ) : (
                <td>
                  <div>-</div>
                </td>
              )}
            </tr>
          );
        })}
      </DataTable>
      <div style={{ display: loading === true ? "none" : "" }}>
        <PaginationBar
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          loading={loading}
        />
      </div>
      <MonitoringModal
        open={isMonitoringModalOpen}
        setOpen={setIsMonitoringModalOpen}
      />
    </>
  );
};

const MonitoringModal = ({ open, setOpen }) => {
  const detailMail = useRecoilValue(DetailMailQuery({ open }));

  return (
    <CommonModal title="쪽지 내용보기" open={open} setOpen={setOpen}>
      <table className="writetable">
        <tbody>
          <tr>
            <td className="text-center-title zoom_font">보낸회원</td>
            <td className="zoom_font">
              ({detailMail?.sender_company}) {detailMail?.sender_name}
            </td>
            <td className="text-center-title  zoom_font">보낸시간</td>
            <td className="zoom_font">{detailMail?.createtime}</td>
          </tr>
          <tr>
            <td className="text-center-title zoom_font">받는회원</td>
            <td className="zoom_font">
              {detailMail?.des_List?.map((item, idx) => {
                return (
                  <>
                    <p key={idx}>{item.name}</p>
                  </>
                );
              })}
            </td>
            <td className="text-center-title  zoom_font">읽은시간</td>
            <td className="zoom_font">
              {detailMail?.isread ? detailMail?.readtime : "읽지 않음"}
            </td>
          </tr>
          <tr>
            <td className="text-center-title zoom_font">첨부파일</td>
            <td className="zoom_font" colSpan={3}>
              {detailMail?.file_contents ? (
                <FileContent file_contents={detailMail?.file_contents} />
              ) : (
                <a>첨부파일이 없습니다.</a>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <div>
                <div>제목 : {detailMail?.title}</div>
              </div>
              <div className="cont">
                <div
                  className="zoom_font_id ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(detailMail?.contents),
                  }}
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="modalfoot">
        <div className="btn-group">
          <button className="graybtn-lg" onClick={() => setOpen(false)}>
            닫기
          </button>
        </div>
      </div>
    </CommonModal>
  );
};

export default PostMonitoring;
