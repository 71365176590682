export const InputText = ({
  errors,
  refs,
  type = "text",
  style,
  isReadOnly = false,
  maxV,
  maxLength,
  step = 1,
}) => {
  return (
    <>
      {refs && (
        <div style={style}>
          <input
            {...refs}
            type={type}
            className="pop_input"
            aria-invalid={errors[refs?.name] ? "true" : "false"}
            readOnly={isReadOnly}
            max={maxV}
            maxLength={maxLength}
            step={step}
          />
          {errors[refs?.name]?.type === "required" && (
            <p style={{ color: "#d92742" }}>입력되지 않았습니다.</p>
          )}
          {errors[refs?.name]?.type === "pattern" && (
            <p style={{ color: "#d92742" }}>값이 올바르지 않습니다.</p>
          )}
        </div>
      )}
    </>
  );
};
