import dayjs from "dayjs";
import Select from "react-select";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  DocsTypeListAtom,
  MailUserListQuery,
  UserListAtom,
  authAtom,
  toastAtom,
} from "../../../recoil";
import React, { useEffect, useState } from "react";
import SearchMemberTargetMenu from "./SearchMemberTargetMenu";
import { List } from "react-content-loader";
import ScheduleAddDocs from "./ScheduleAddDocs";
import { Controller, useForm } from "react-hook-form";
import useScheduleActions from "../../../recoil/api/schedule";
import { toast as toastify } from "react-toastify";

const ScheduleRegRegister = ({ setIsDocsRegisterModalOpen }) => {
  const auth = useRecoilValue(authAtom);
  const mailUserList = useRecoilValue(UserListAtom);
  const setDocsTypeList = useSetRecoilState(DocsTypeListAtom);
  const docsTypeList = useRecoilValue(DocsTypeListAtom);
  const [searchMemberMenuOpen, setSearchMemberMenuOpen] = useState(false);
  const [addDocsMenuOpen, setAddDocsMenuOpen] = useState(false);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [isScheduleNameInputReadOnly, setIsScheduleNameInputReadOnly] =
    useState(true);
  const scheduleActions = useScheduleActions();
  const setMailUserList = useSetRecoilState(MailUserListQuery());

  const { register, handleSubmit, control, watch, setValue, getValues } =
    useForm({
      defaultValues: { remind: { value: "0", label: "사용안함" } },
    });

  let select_mailUserList = mailUserList
    ?.filter((user) => {
      return user.isSelected;
    })
    .map((user) => {
      return { value: user.id, label: user.name };
    });

  const selectRemindOptions = [
    { value: "0", label: "사용안함" },
    { value: "1", label: "1일전 알림 발송" },
    { value: "3", label: "3일전 알림 발송" },
    { value: "7", label: "7일전 알림 발송" },
  ];

  useEffect(() => {
    getDocsTypeList();
  }, []);

  useEffect(() => {
    if (dayjs(watch("endtime")) < dayjs(watch("starttime"))) {
      setValue(
        "endtime",
        dayjs(watch("starttime")).add(1, "day").format("YYYY-MM-DD")
      );
    }
  }, [watch("starttime")]);

  const getDocsTypeList = async () => {
    const res = await scheduleActions.getScheduleDocsTypeList();
    if (res.status === 200) {
      setDocsTypeList(res.data);
    } else {
      setToast({
        ...toast,
        error: true,
        message: (
          <p>
            서류 종류 불러오기에 실패했습니다.
            <br /> 새로고침 후 진행해주세요.
          </p>
        ),
      });
    }
  };

  const onSubmit = async (data) => {
    if (data.title === undefined || data.title === "") {
      setToast({ ...toast, error: true, message: "일정명을 지정해주세요." });
      return;
    }
    if (select_mailUserList.length === 0) {
      setToast({ ...toast, error: true, message: "대상자를 지정해주세요." });
      return;
    }
    if (data.doc_list === undefined || data.doc_list.length === 0) {
      setToast({ ...toast, error: true, message: "서류를 지정해주세요." });
      return;
    }
    if (data.starttime >= data.endtime) {
      setToast({
        ...toast,
        error: true,
        message: "종료일이 시작일보다 같거나 빠를 수 없습니다.",
      });
      return;
    }
    if (data.remind === undefined) {
      setToast({ ...toast, error: true, message: "알림을 지정해주세요." });
      return;
    }

    const isRemindCheck = dayjs(data.endtime).diff(
      dayjs(data.starttime),
      "day"
    );
    if (data.remind.value !== "0" && isRemindCheck <= data.remind.value) {
      setToast({
        ...toast,
        error: true,
        message: "총 제출기한보다 알림 발송일이 더 클 수 없습니다.",
      });
      return;
    }

    let params = {
      ...data,
      des_list: mailUserList
        .filter((user) => {
          return user.isSelected;
        })
        .map((user) => {
          if (user.id) {
            return {
              ...user,
              id: user.id,
            };
          } else {
            return {
              ...user,
            };
          }
        }),
      remind: data.remind.value,
    };

    const toastId = toastify.loading("일정 등록 중...");
    const res = await scheduleActions.postScheduleDosc(params);
    if (res.status === 200) {
      toastify.update(toastId, {
        render: "일정 등록 완료",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      setIsDocsRegisterModalOpen(false);
    } else {
      toastify.update(toastId, {
        render: res.data,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <div className="pop-con" style={{ position: "relative" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="pop-in-pop">
            <tbody>
              <tr>
                <th className="pop_thd">일정명</th>
                <td className="pop_thd" colSpan={3}>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      marginBottom: 4,
                    }}
                  >
                    <input
                      id="isReadOnly"
                      type="checkbox"
                      onChange={() => {
                        setIsScheduleNameInputReadOnly((prev) => {
                          if (
                            getValues("doc_list") !== undefined &&
                            !prev &&
                            !!getValues("doc_list").length > 0
                          ) {
                            setValue(
                              "title",
                              `${getValues("doc_list")[0].label} 등 ${
                                getValues("doc_list").length - 1
                              }건`
                            );
                          } else {
                            setValue("title", "");
                          }
                          return !prev;
                        });
                      }}
                    />
                    <label htmlFor="isReadOnly">직접입력</label>
                  </div>

                  <input
                    disabled={isScheduleNameInputReadOnly}
                    type="text"
                    className="pop_input"
                    placeholder={
                      isScheduleNameInputReadOnly
                        ? "서류를 선택하시면 일정명이 자동완성 됩니다."
                        : "일정명을 입력하세요."
                    }
                    {...register("title")}
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">대상자</th>
                <td className="pop_thd" colSpan={3}>
                  <div className="docs-popup-tr" style={{ display: "flex" }}>
                    <div className="docs-popup-td">
                      <Select
                        className="mail-ellipsis2"
                        placeholder={mailUserList
                          ?.filter((user) => {
                            return user.isSelected;
                          })
                          .map((user) => {
                            // console.log(user);
                            return `${user.name} `;
                          })}
                        options={select_mailUserList}
                        value={""}
                      />
                    </div>
                    <div className="btn-group" style={{ margin: 0 }}>
                      <button
                        type="button"
                        className="primarybtn-md"
                        onClick={() => {
                          setSearchMemberMenuOpen(!searchMemberMenuOpen);
                          setAddDocsMenuOpen(false);
                        }}
                      >
                        회원 검색
                      </button>
                      <button
                        type="button"
                        className="pinkbtn-md"
                        onClick={() => {
                          setMailUserList({ reset: true });
                        }}
                      >
                        초기화
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="pop_thd">제출 시작일</th>
                <td className="pop_thd" colSpan={3}>
                  <input
                    name="starttime"
                    type="date"
                    className="pop_input"
                    min={new Date().toJSON().slice(0, 10)}
                    defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => {}}
                    {...register("starttime")}
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">제출 마감일</th>
                <td className="pop_thd" colSpan={3}>
                  <input
                    name="endtime"
                    type="date"
                    className="pop_input"
                    min={dayjs(watch("starttime"))
                      .add(1, "day")
                      .format("YYYY-MM-DD")}
                    defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                    {...register("endtime")}
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">제출 서류</th>
                <td className="pop_thd" colSpan={3}>
                  <div className="docs-popup-tr" style={{ display: "flex" }}>
                    <div className="docs-popup-td">
                      <Controller
                        name="doc_list"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder=""
                            options={docsTypeList}
                            isMulti
                            onChange={(e) => {
                              if (isScheduleNameInputReadOnly) {
                                if (e.length !== 0 && e.length === 1) {
                                  setValue("title", `${e[0].label}`);
                                } else if (e.length !== 0 && e.length > 1) {
                                  setValue(
                                    "title",
                                    `${e[0].label} 외 ${e.length}건`
                                  );
                                } else {
                                  setValue("title", "");
                                }
                              }
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                    {auth?.user?.isAdmin && (
                      <div className="btn-group" style={{ margin: 0 }}>
                        <button
                          type="button"
                          className="primarybtn-md"
                          onClick={() => {
                            setAddDocsMenuOpen(!addDocsMenuOpen);
                            setSearchMemberMenuOpen(false);
                          }}
                        >
                          서류 추가
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
              {/* <tr>
                <th className="pop_thd">알림</th>
                <td className="pop_thd" colSpan={3}>
                  <Controller
                    name="remind"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="일정 알림 설정"
                        options={selectRemindOptions}
                        defaultValue={selectRemindOptions[0]}
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                  />
                </td>
              </tr> */}
            </tbody>
          </table>
          <div style={{ textAlign: "right", marginTop: 20 }}>
            <button type="submit" className="s-save sc">
              등록하기
            </button>
          </div>
        </form>
        {searchMemberMenuOpen && (
          <React.Suspense fallback={<List />}>
            <SearchMemberTargetMenu
              open={searchMemberMenuOpen}
              setOpen={setSearchMemberMenuOpen}
            />
          </React.Suspense>
        )}
        {addDocsMenuOpen && (
          <React.Suspense fallback={<List />}>
            <ScheduleAddDocs
              open={addDocsMenuOpen}
              setOpen={setAddDocsMenuOpen}
            />
          </React.Suspense>
        )}
      </div>
    </>
  );
};

export default ScheduleRegRegister;
