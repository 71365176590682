import { useEffect, useState } from "react";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import XlsxDownload from "../../form/XlsxDownload";
import { useHolidayActions } from "../../../recoil/api/holiday";
import dayjs from "dayjs";

const CompanyVacationHistory = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const holidayActions = useHolidayActions();
  const [date, setDate] = useState(DateState);
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [company, setCompany] = useState({});
  const auth = useRecoilValue(authAtom);
  const [historyList, setHistoryList] = useState([]);

  const getVacationHistory = async () => {
    setLoading(true);
    const res = await holidayActions.getVacationHistory({
      pageInfo,
      date,
      COMP_CD: company?.COMP_CD,
    });
    if (res.status === 200) {
      setHistoryList(res.data.list);
      setPageInfo(res.data.pageinfo);
    } else {
      setToast({ ...toast, error: true, message: res.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    getVacationHistory();
  }, [pageInfo?.current, date, company?.COMP_CD]);

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div
            className="btn-group btn-group-left"
            style={{ pointerEvents: loading === true ? "none" : "" }}
          >
            <XlsxDownload
              api={`${auth.accountType}/vacation/history`}
              name="연차현황"
              params={{
                COMP_CD: company?.COMP_CD || null,
              }}
            />
          </div>
        </div>
        <div className="all-group">
          {/* admin일 경우 기업선택 표시 */}
          {auth.user.isAdmin && (
            <div className="btn-group-inline btn-group-left">
              <button
                className="secondarybtn-md"
                type="button"
                onClick={() => {
                  setIsSearchCompanyModalOpen(true);
                }}
              >
                {company.company_name || "기업 선택"}
              </button>
            </div>
          )}
          <div className="d-inline-block">
            <SearchByDate
              dateAndKeyword={date}
              placeholder="이름 입력"
              period={30}
              onChange={(value) => setDate(value)}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <DataTable
        checkbox={false}
        loading={loading}
        columns={
          auth.user.isAdmin
            ? [
                "기업",
                "이름",
                "입사일",
                "1일기준",
                "총부여일",
                "사용일",
                "잔여일",
                "부여일",
                "과거잔여연차",
              ]
            : [
                "이름",
                "입사일",
                "총부여일",
                "사용일",
                "잔여일",
                "부여일",
                "과거잔여연차",
              ]
        }
      >
        {historyList?.map((item, index) => {
          return (
            <tr key={item.contract_sn}>
              {auth.user.isAdmin && (
                <td className="border-line zoom_font">{item.company_name}</td>
              )}
              <td className="border-line zoom_font">{item.employee_name}</td>
              <td className="border-line zoom_font">
                {dayjs(item.joindatetime).format("YYYY-MM-DD")}
              </td>
              {auth.user.isAdmin && (
                <td className="border-line zoom_font">
                  {item.day_standard || 1}
                </td>
              )}
              <td className="border-line zoom_font">
                {Math.round(
                  item.total_day_off * (item.day_standard || 1) * 10
                ) / 10}
              </td>
              <td className="border-line zoom_font">
                {Math.round(item.used_day_off * (item.day_standard || 1) * 10) /
                  10}
              </td>
              <td className="border-line zoom_font">
                {Math.round(
                  item.remain_day_off * (item.day_standard || 1) * 10
                ) / 10}
              </td>
              <td className="border-line zoom_font">
                {dayjs(item.grant_day).format("YYYY-MM-DD")}
              </td>
              <td className="border-line zoom_font">
                {Math.round(
                  item.carried_day_off * (item.day_standard || 1) * 10
                ) / 10}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <p style={{ marginTop: 10 }}>{`총 : ${pageInfo?.totalCnt}건`}</p>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default CompanyVacationHistory;
