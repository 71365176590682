import { useEffect } from "react";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  ElecBase64ArrayAtom,
  ElecLabelAtom,
  ElecPageInfoAtom,
} from "../../../recoil";

const ElecFooter = ({ onSubmit, stageRef, getNewBase64Array }) => {
  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const [pageInfo, setPageInfo] = useRecoilState(ElecPageInfoAtom);
  const setElecBase64Array = useSetRecoilState(ElecBase64ArrayAtom);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (check) {
      onSubmit();
      setTimeout(() => {
        setCheck(false);
      }, 500);
    }
  }, [check]);

  return (
    <div className="elec-footer">
      <div className="btn-group">
        {pageInfo.current > 1 && pageInfo.current !== pageInfo.total && (
          <button
            className="footer-button-gray"
            onClick={() =>
              setPageInfo({
                ...pageInfo,
                current: pageInfo.current - 1,
              })
            }
          >
            이전
          </button>
        )}
        {pageInfo.current < pageInfo.total && (
          <button
            className="footer-button"
            onClick={() => {
              //근로자가 다음 버튼 누르면 base64 배열에 현재 캔버스 화면이 저장됨
              if (stageRef) {
                setElecBase64Array((e) => (e = getNewBase64Array()));
              }
              setPageInfo({ ...pageInfo, current: pageInfo.current + 1 });
            }}
          >
            다음
          </button>
        )}
      </div>
      {pageInfo.current === pageInfo.total && (
        <div className="btn-group">
          {pageInfo.current > 1 && (
            <button
              className="footer-button-gray"
              onClick={() =>
                setPageInfo({
                  ...pageInfo,
                  current: pageInfo.current - 1,
                })
              }
            >
              이전
            </button>
          )}
          <button
            className="footer-button"
            onClick={() => {
              if (stageRef) {
                setElecBase64Array((e) => (e = getNewBase64Array()));
              }
              setCheck(true);
            }}
          >
            완료
          </button>
        </div>
      )}
    </div>
  );
};

export default ElecFooter;
