import { useEffect, useState } from "react";

const MailSearchWord = ({ setKeyword, pageInfo, prevInfo, setPrevInfo }) => {
  const [searchWord, setSearchWord] = useState("");

  useEffect(() => {
    let timer = setTimeout(() => {
      setKeyword(prevInfo !== undefined ? prevInfo[0] : searchWord);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchWord]);

  return (
    <div className="psearch">
      <input
        onChange={(e) => {
          setSearchWord(e.target.value);
          setPrevInfo([e.target.value, pageInfo]);
        }}
        className="receive_note"
        id="search"
        type="text"
        name="search_keyword"
        placeholder="이름 또는 아이디를 입력하세요."
        width="100%"
        defaultValue={prevInfo !== undefined ? prevInfo[0] : ""}
      />
      <div className="inputsearch">
        <button
          type="button"
          className="searchbtn border-line mouse zoom_font"
        ></button>
      </div>
    </div>
  );
};

export default MailSearchWord;
