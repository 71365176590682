import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useBoardNoticeActions } from "../../../recoil/api/boardNotice";
import htmlToDraft from "html-to-draftjs";
import { NOTICETYPE } from "../../../JSON/enum/Dataenum";
import Select from "react-select";
import BreadCrumb from "../BreadCrumb";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { Editor } from "@tinymce/tinymce-react";
import { fileNameCheck, fileSizeCheck } from "../../../utils";
import { QuillEditor } from "../../toolbar/QuillEditor";

const BoardNoticeEdit = () => {
  const params = useParams();
  const navigate = useNavigate();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);

  // notice 타입 상태 값
  const noticeTypeOptions = [
    { value: NOTICETYPE.OFFICIAL, label: "공문" },
    { value: NOTICETYPE.DELEGATE, label: "위임장" },
  ];

  // 파일 상태 값
  const [prevFiles, setPrevFiles] = useState([]);
  const [toRemoveFilesId, setToRemoveFilesId] = useState([]);
  const [files, setFiles] = useState([]);
  const [toRemainPrevFilesIndex, setToRemainPrevFilesIndex] = useState([]);

  // 파일 핸들러
  const onchangeFilesHandler = (event) => {
    event.preventDefault();
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  // API 요청 함수 - 이전에 저장된 파일 목록 불러오기
  const loadPrevFiles = async (docsn) => {
    const res = await boardNoticeActions.getBoardNoticeFileUrl(docsn);
    if (res.data) {
      setPrevFiles(res.data);
      setToRemainPrevFilesIndex(Array(res?.data?.length).fill(true));
    }
  };

  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };

  // boardNoticeView 데이터 상태 값
  const [boardNotceData, setBoardNoticeData] = useState();

  // API 호출 함수 - boardNoticeView 상세 데이터 불러오기
  const boardNoticeActions = useBoardNoticeActions();

  const loadBoardNoticeView = async (docsn) => {
    const res = await boardNoticeActions.getBoardNoticeView(docsn);

    if (res.status === 200) {
      setBoardNoticeData((prevState) => res.data);

      setValue("title", res.data?.title);
      setValue("type", {
        value: res.data?.type,
        label: res.data?.type === 1 ? "공문" : res.data?.type === 2 && "위임장",
      });
      setValue("editor", res.data.contents);
      setValue(
        "workorderYMD",
        dayjs(res.data?.work_order_ymd).format("YYYY-MM-DD")
      );
    }
  };

  // useEffect
  useEffect(() => {
    loadBoardNoticeView({ docsn: params.sn });
    loadPrevFiles({ docsn: params.sn });
  }, []);

  // useForm
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      editor: "",
      workorderYMD: dayjs().format("YYYY-MM-DD"),
    },
  });

  // Submit 함수
  const onSubmit = async (data) => {
    // 파일 삭제 API 호출
    for (let i = 0; i < toRemoveFilesId.length; i++) {
      const res = await boardNoticeActions.deleteBoardNoticeFile({
        sn: toRemoveFilesId[i],
      });
    }

    const req = {
      docsn: params.sn,
      title: data.title,
      contents: data.editor,
      file: files,
      type: data.type?.value,
      prevFiles: prevFiles.map((prevFile, index) => {
        if (toRemainPrevFilesIndex[index]) {
          return {
            filename: prevFile?.filename,
            extension: prevFile?.extension,
            url: prevFile?.url,
          };
        }
      }),
    };

    if (req.title) {
      const res = await boardNoticeActions.updateBoardNotice(req);

      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate(`/${auth.accountType}/notice`);
      } else {
        setToast({ ...toast, error: true, message: "작성 실패" });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
    }
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table className="writetable hide">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font w-20">작성자</th>
                <td className="w-30">
                  {auth.user.userName}
                  <div id="divhashlist"></div>
                </td>
                <th className="text-center-title zoom_font w-20">지시일</th>
                <td className="w-30">{dayjs().format("YYYY-MM-DD")}</td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font w-20">제목</th>
                <td className="w-30">
                  <input
                    {...register("title")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="제목을 입력해주세요."
                  />
                </td>
                <th className="text-center-title zoom_font w-20">구분</th>
                <td className="w-30">
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={noticeTypeOptions}
                        placeholder="선택"
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="5">
                  <Controller
                    name="editor"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <QuillEditor value={value} onChange={onChange} />
                      // <Editor
                      //   apiKey={"v6ji2nwc9qbq1zat0pwg4o4kb9tco1wnad8oh2xdplkg6q9z"}
                      //   value={value}
                      //   onEditorChange={onChange}
                      //   init={{
                      //     plugins: ["table", "link", "autolink", "contextmenu"],
                      //     language: "ko_KR",
                      //     selector: "div",
                      //     elementpath: false,
                      //     branding: false,
                      //     autolink: true,
                      //     toolbar:
                      //       "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                      //     contextmenu: "true",
                      //     menubar: "file insert view format table",
                      //   }}
                      // />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">첨부파일</th>
                <td colSpan="5">
                  <span className="filetype">
                    <span className="file-select border-line mouse zoom_font">
                      <input
                        {...register("file")}
                        type="file"
                        multiple="multiple"
                        className="input-file"
                        onChange={onchangeFilesHandler}
                      />
                    </span>
                    <span className="file-btn">+ 파일선택</span>
                    <div className="file-list">
                      {/* 이전에 저장된 파일 목록 */}
                      {prevFiles.length !== 0 &&
                        prevFiles?.map((prevFile, index) => {
                          return toRemainPrevFilesIndex[index] ? (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setToRemainPrevFilesIndex(() => {
                                  const newIndexArray = [
                                    ...toRemainPrevFilesIndex,
                                  ];
                                  newIndexArray[index] = !newIndexArray[index];
                                  return newIndexArray;
                                });
                                if (toRemoveFilesId.includes(prevFile.sn)) {
                                  setToRemoveFilesId(
                                    [...toRemoveFilesId].filter(
                                      (ele) => ele !== prevFile.sn
                                    )
                                  );
                                } else {
                                  setToRemoveFilesId([
                                    ...toRemoveFilesId,
                                    prevFile.sn,
                                  ]);
                                }
                              }}
                              key={prevFile.sn}
                              type="text"
                              className="file-text"
                            >
                              {`${prevFile?.filename}.${prevFile?.extension}`}
                              <FileDeleteBtn />
                            </div>
                          ) : (
                            <div
                              style={{
                                textDecoration: "line-through",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setToRemainPrevFilesIndex(() => {
                                  const newIndexArray = [
                                    ...toRemainPrevFilesIndex,
                                  ];
                                  newIndexArray[index] = !newIndexArray[index];
                                  return newIndexArray;
                                });

                                if (toRemoveFilesId.includes(prevFile.sn)) {
                                  setToRemoveFilesId(
                                    [...toRemoveFilesId].filter(
                                      (ele) => ele !== prevFile.sn
                                    )
                                  );
                                } else {
                                  setToRemoveFilesId([
                                    ...toRemoveFilesId,
                                    prevFile.sn,
                                  ]);
                                }
                              }}
                              key={prevFile.sn}
                              type="text"
                              className="file-text"
                            >
                              {`${prevFile?.filename}.${prevFile?.extension}`}
                              <FileDeleteBtn />
                            </div>
                          );
                        })}
                      {/* 새로 업로드하는 파일 목록 */}
                      {files.length > 0 &&
                        files.map((ele) => {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeFile(ele.id);
                              }}
                              key={ele.id}
                              type="text"
                              className="file-text"
                            >
                              {ele.file.name}
                              <FileDeleteBtn />
                            </div>
                          );
                        })}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table className="writetable mobile-table">
          <tbody>
            <tr>
              <th className="text-center-title w-20">대상자</th>
              <td>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="제목을 검색해주세요."
                  style={{ width: "60%" }}
                />
                <button type="button">검색</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-group">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>

          <button
            className="writebtn cancelbtn border-line mouse zoom_font"
            type="button"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};

export default BoardNoticeEdit;
