import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { Link, useNavigate } from "react-router-dom";
import { useBoardBenefitActions } from "../../../recoil/api/boardBenefit";
import BreadCrumb from "../BreadCrumb";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { Editor } from "@tinymce/tinymce-react";
import { fileNameCheck, fileSizeCheck } from "../../../utils";
import { QuillEditor } from "../../toolbar/QuillEditor";

const BoardBenefitWrite = () => {
  const navigate = useNavigate();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // 파일 상태 값
  const [files, setFiles] = useState([]);

  // 파일 핸들러
  const onchangeFilesHandler = (event) => {
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };

  // useForm
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      editor: "",
      workorderYMD: dayjs().format("YYYY-MM-DD"),
    },
  });

  // Submit 함수
  const boardBenefitActions = useBoardBenefitActions();

  const onSubmit = async (data) => {
    if (data.title.trim() === "") {
      setToast({
        ...toast,
        error: true,
        message: "제목을 입력해주세요.",
      });
      return;
    }

    if (data.editor.trim() === "") {
      setToast({
        ...toast,
        error: true,
        message: "내용을 입력해주세요.",
      });
      return;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const req = {
      title: data.title,
      contents: data.editor,
      file: files,
    };

    if (req.title) {
      const res = await boardBenefitActions.createBoardBenefit(req);

      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate(`/${auth.accountType}/benefits`);
      } else {
        setToast({ ...toast, error: true, message: "작성 실패" });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "제목을 입력해주세요.",
      });
    }

    setDoubleclickLoading(false);
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="writetable hide">
          <tbody>
            <tr>
              <th className="text-center-title zoom_font w-20">작성자</th>
              <td className="w-30">
                <div id="divhashlist">{auth.user.userName}</div>
              </td>
              <th className="text-center-title zoom_font w-20">작성일</th>
              <td className="w-30">{dayjs().format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">제목</th>
              <td colSpan="5">
                <input
                  {...register("title")}
                  className="write-input border-line mouse zoom_font"
                  placeholder="제목을 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">내용</th>
              <td colSpan="5">
                <Controller
                  name="editor"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <QuillEditor value={value} onChange={onChange} />
                    // <Editor
                    //   apiKey={"v6ji2nwc9qbq1zat0pwg4o4kb9tco1wnad8oh2xdplkg6q9z"}
                    //   value={value}
                    //   onEditorChange={onChange}
                    //   init={{
                    //     plugins: ["table", "link", "autolink", "contextmenu"],
                    //     language: "ko_KR",
                    //     selector: "div",
                    //     elementpath: false,
                    //     branding: false,
                    //     autolink: true,
                    //     toolbar:
                    //       "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                    //     contextmenu: "true",
                    //     menubar: "file insert view format table",
                    //   }}
                    // />
                  )}
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">파일첨부</th>
              <td colSpan="5">
                <span className="filetype">
                  <span className="file-select border-line mouse zoom_font">
                    <input
                      {...register("file")}
                      type="file"
                      multiple="multiple"
                      className="input-file"
                      onChange={onchangeFilesHandler}
                    />
                  </span>
                  <span className="file-btn">+ 파일선택</span>

                  <div className="file-list">
                    {files.length > 0 &&
                      files.map((ele) => {
                        return (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              removeFile(ele.id);
                            }}
                            key={ele.id}
                            type="text"
                            className="file-text"
                          >
                            {ele.file.name}
                            <FileDeleteBtn />
                          </div>
                        );
                      })}
                  </div>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="writetable mobile-table">
          <tbody>
            <tr>
              <th className="text-center-title w-20">대상자</th>
              <td>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="제목을 검색해주세요."
                  style={{ width: "60%" }}
                />
                <button type="button" onClick={() => {}}>
                  검색
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-group">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>
          <Link to={`/${auth.accountType}/benefits`}>
            <button
              className="writebtn cancelbtn border-line mouse zoom_font"
              type="button"
            >
              취소
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default BoardBenefitWrite;
