import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { JOINDOCTYPE } from "../../../JSON/enum/Dataenum";
import {
  ElecBase64ArrayAtom,
  ElecLabelAtom,
  ElecPageInfoAtom,
  ElecURLAtom,
} from "../../../recoil";
import { useElecActions } from "../../../recoil/api/elec";
import ElecFooter from "../../common/elec/ElecFooter";
import ViewElec from "../../common/elec/ViewElec";
import CompanyCreateSidebar from "../../company/elec/CompanyCreateSidebar";

const EmployeeSignatureElec = () => {
  const navigate = useNavigate();
  const stageRef = useRef();
  const { type } = useParams();
  const { state } = useLocation();

  const elecActions = useElecActions();

  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const [docURL, setDocURL] = useRecoilState(ElecURLAtom);
  const [pageInfo, setPageInfo] = useRecoilState(ElecPageInfoAtom);
  const [elecBase64Array, setElecBase64Array] =
    useRecoilState(ElecBase64ArrayAtom);

  const getNewBase64Array = () => {
    const newArray = [...elecBase64Array];
    newArray[pageInfo.current - 1] = stageRef.current.toDataURL();
    return newArray;
  };

  const onSubmit = () => {
    if (labels.every((i) => i.isFulfilled === true)) {
      const pdf = new jsPDF("p", "px", [
        stageRef.current.width(),
        stageRef.current.height(),
      ]);
      for (let i = 0; i < pageInfo.total; i++) {
        if (i !== 0) {
          pdf.addPage();
        }
        pdf.addImage(
          elecBase64Array[i],
          "PNG",
          0,
          0,
          stageRef.current.width(),
          stageRef.current.height()
        );
      }
      const blob = pdf.output("blob");
      elecActions.employeeSignatureElec({ blob, type });
    } else {
      return alert("입력되지 않은 항목이 있습니다.");
    }
  };

  useEffect(() => {
    setElecBase64Array([]);
    getEmployeeDoc(type, state.contract_sn);
  }, []);

  const getEmployeeDoc = async (type, contract) => {
    setDocURL("");

    //전자서명 동의서나 재택근무 보안 서약서는 commonDoc에서 가져옴
    if (
      parseInt(type) === JOINDOCTYPE.digital_sign ||
      parseInt(type) === JOINDOCTYPE.remotework
    ) {
      const res = await elecActions.getCommonDoc(type, contract);
      if (res.status === 200) {
        setDocURL(res?.data?.url);
        setLabels(JSON.parse(res?.data?.json));
      }
      return res;
    } else {
      const res = await elecActions.getEmployeeDoc(type, contract);
      if (res.status === 200) {
        setDocURL(res?.data?.url);
        setLabels(JSON.parse(res?.data?.json));
      }
      return res;
    }
  };

  return (
    <div className="elec-container">
      <CompanyCreateSidebar mode="sign" isCompany={false} />
      <div className="elec-content">
        <div style={{ position: "absolute", top: "80px", right: "10px" }}>
          <button
            className="primarybtn-sm border-line zoom_font"
            onClick={() => {
              navigate(-1);
            }}
          >
            닫기
          </button>
        </div>
        {docURL && (
          <ViewElec
            type={type}
            mode="sign"
            isCompany={false}
            src={docURL}
            stageRef={stageRef}
          />
        )}
      </div>
      <ElecFooter
        getNewBase64Array={getNewBase64Array}
        onSubmit={onSubmit}
        stageRef={stageRef}
      />
    </div>
  );
};

export default EmployeeSignatureElec;
