import Konva from "konva";
import { useEffect, useRef, useState } from "react";
import { Image as Img, Layer, Rect, Stage, Transformer } from "react-konva";
import { useRecoilState } from "recoil";
import { ElecLabelAtom } from "../../../recoil";
import { useElecActions } from "../../../recoil/api/elec";

const UploadStamp = ({ targetIndex, setOpen, open }) => {
  const elecActions = useElecActions();
  const stageRef = useRef(null);
  const layerRef = useRef(null);
  const imageRef = useRef(null);
  const trRef = useRef(null);
  const cropRef = useRef(null);
  const [stamp, setStamp] = useState();
  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("");
  const [check, setCheck] = useState(false);
  const [scheck, setSCheck] = useState(false);
  const [crop, setCrop] = useState(false);

  useEffect(() => {
    getStamp();
  }, []);

  const getStamp = async () => {
    setLoading(true);
    const res = await elecActions.getStamp();
    if (res) {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = res.data;
      img.onload = () => {
        setStamp(img);
        setCheck(true);
      };
    }
    setLoading(false);
  };
  //등록된 도장 이미지 가져오기

  const changeImage = (e) => {
    setLoading(true);
    const url = URL.createObjectURL(e.target.files[0]);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setStamp(img);
    };
    setLoading(false);
  };
  //도장 파일 올리기

  const handleExport = () => {
    if (check && scheck) {
      const uri = stageRef.current.toDataURL();
      const newLabels = labels.map((label, i) => {
        if (i === targetIndex) {
          return { ...label, img: uri, isFulfilled: true };
        }
        return label;
      });
      setLabels(newLabels);
      setOpen(false);
    } else {
      if (!scheck) {
        return alert(
          "파일사이즈는 2MB를 초과할 수 없습니다. 다시 선택해주세요"
        );
      } else {
        return alert("이미지 파일만 업로드 가능합니다. 다시 선택해주세요");
      }
    }
  };
  //도장 파일 저장하기

  const imageCheck = (file) => {
    if (file[0].size < 2 * 1024 * 1024) {
      setSCheck(true);
      switch (file[0].type) {
        case "image/png":
          return true;
        case "image/gif":
          return true;
        case "image/jpeg":
          return true;
        default:
          return false;
      }
    } else {
      setSCheck(false);
      return false;
    }
  };

  const imageSelect = (e) => {
    setMode("size");
    if (trRef.current.nodes([])) {
      trRef.current.nodes([e.currentTarget]);
      trRef.current.getLayer().batchDraw();
      trRef.current.borderDash([0, 0]);
      trRef.current.anchorCornerRadius(0);
      trRef.current.rotateEnabled(true);
    }
  };
  const imageCrop = (e) => {
    setMode("crop");
    cropRef.current.x(e.currentTarget.x());
    cropRef.current.y(e.currentTarget.y());
    cropRef.current.width(e.currentTarget.width());
    cropRef.current.height(e.currentTarget.height());
    cropRef.current.scaleX(e.currentTarget.scaleX());
    cropRef.current.scaleY(e.currentTarget.scaleY());
    cropRef.current.visible(true);
    imageRef.current.opacity(0.5);
    trRef.current.nodes([cropRef.current]);
    trRef.current.getLayer().batchDraw();
    trRef.current.rotateEnabled(false);
    trRef.current.borderDash([5, 5]);
    trRef.current.anchorCornerRadius(5);
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      if (mode === "size") {
        imageRef.current.opacity(1);
        layerRef.current.draw();
        trRef.current.nodes([]);
      } else if (mode === "crop") {
        imageRef.current.opacity(1);
        layerRef.current.draw();
        trRef.current.nodes([]);
      }
      setCrop(false);
    }
  };

  return (
    <div>
      <input
        onChange={(e) => {
          if (imageCheck(e.target.files)) {
            changeImage(e);
            setCheck(true);
          } else {
            setCheck(false);
          }
        }}
        type="file"
        accept="image/png, image/gif, image/jpeg"
      />
      <div
        style={{
          border: "1px solid #eee",
          width: "300px",
        }}
      >
        <Stage
          ref={stageRef}
          width={300}
          height={300}
          onMouseDown={(e) => {
            checkDeselect(e);
          }}
        >
          <Layer ref={layerRef}>
            <Img
              listening={check}
              ref={imageRef}
              image={stamp}
              draggable
              x={75}
              y={75}
              width={150}
              height={150}
              onClick={(e) => {
                imageSelect(e);
              }}
              onDblClick={(e) => {
                imageCrop(e);
                setCrop(true);
              }}
              onDragMove={() => {}}
              onDragEnd={() => {}}
            />
            <Rect ref={cropRef} listening={crop} />
            <Transformer
              onTransformEnd={(e) => {
              }}
              boundBoxFunc={(oldBox, newBox) => {
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
              ref={trRef}
            />
          </Layer>
        </Stage>
      </div>
      <div className="modalfoot">
        <div className="btn-group">
          <button className="primarybtn-lg" onClick={handleExport}>
            입력하기
          </button>
        </div>
      </div>
    </div>
  );
};
export default UploadStamp;
