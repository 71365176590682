import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  DocsUserListQuery,
  MailUserFilterAtom,
  authAtom,
} from "../../../recoil";
import { overMaxLength } from "../../../utils";
import styled from "styled-components";
import check from "../../../img/check.png";

const SearchMemberTargetMenu = ({ open, setOpen }) => {
  const auth = useRecoilValue(authAtom);
  const [keyword, setKeyword] = useRecoilState(MailUserFilterAtom);
  const setMailUserList = useSetRecoilState(DocsUserListQuery());
  const [mailUserList] = useRecoilState(DocsUserListQuery());
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  const CheckBtnLabel = styled.label`
    font-size: 0.75rem;
    color: #222222;
    padding: 4px 8px;
    cursor: pointer;
    border: 2px solid #e4e4e4;
    background-color: #fff;
    border-radius: 2rem;
  `;

  const CheckBtnInput = styled.input`
    appearance: none;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
    &:checked + ${CheckBtnLabel} {
      background-color: #23cdb7;
      color: #fff;
    }
  `;

  // 하나라도 체크 안 되어있으면 전체선택 해제
  useEffect(() => {
    let checkValue = true;
    mailUserList.forEach((mailUser) => {
      let user = { ...mailUser };

      if (user.isSelected === false || user.isSelected === undefined) {
        checkValue = false;
      }
    });
    setSelectAllCheckbox(checkValue);
  }, [mailUserList]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          background: "#fff",
          width: "350px",
          top: 0,
          right: -350,
          borderRadius: "8px",
          boxShadow: "0 10px 18px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div id="modalheadstep">
          <span>대상자 선택</span>
          <button id="modalclose" onClick={() => setOpen(false)}>
            &times;
          </button>
        </div>
        <div style={{ padding: "0 0 8px 0" }}>
          <table className="pop-in-pop">
            <colgroup>
              <col width="80%" />
              <col width="20%" />
            </colgroup>
            <tbody>
              <tr>
                <td className="pop_thd" colSpan={2}>
                  <input
                    type="text"
                    className="pop_input"
                    placeholder={
                      auth.user.isAdmin
                        ? `이름 및 기업명을 입력하세요.`
                        : `이름을 입력하세요.`
                    }
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="pop_thd">
                  <CheckBtnInput
                    type="checkbox"
                    image={check}
                    id="all"
                    checked={selectAllCheckbox}
                    onChange={(e) => {
                      if (selectAllCheckbox === true) {
                        mailUserList.forEach((mailUser) => {
                          let user = { ...mailUser };
                          setMailUserList({ user });
                        });
                      } else {
                        mailUserList.forEach((mailUser) => {
                          let user = { ...mailUser };
                          if (
                            user.isSelected === false ||
                            user.isSelected === undefined
                          ) {
                            setMailUserList({ user });
                          }
                        });
                      }
                    }}
                  />
                  <CheckBtnLabel htmlFor="all">전체선택</CheckBtnLabel>
                </td>
                <td
                  className="pop_thd"
                  style={{
                    color: "#222222",
                    textAlign: "center",
                    padding: "10px 10px",
                    height: "0px",
                  }}
                >
                  {mailUserList.length}명
                </td>
              </tr>
              <tr>
                <td className="pop_thd" style={{ padding: "0" }} colSpan={2}>
                  <div
                    style={{
                      maxHeight: "400px",
                      overflowY: "scroll",
                    }}
                  >
                    <FilteredMemberList />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const FilteredMemberList = () => {
  const [mailUserList, setMailUserList] = useRecoilState(DocsUserListQuery());

  return (
    <>
      {mailUserList?.map((user, i) => {
        return (
          <div
            style={{
              backgroundColor: user.isSelected ? "rgb(235, 255, 253)" : "",
            }}
            className="popimg pointer"
            id="people"
            key={i}
          >
            <div
              className="popit"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 16px",
                borderBottom: "1px solid #E5E5E5",
              }}
              onClick={() => {
                setMailUserList({ user });
              }}
            >
              <dl style={{ color: "#222222" }}>
                <dt title={user?.name?.length > 18 ? user?.name : ""}>
                  {overMaxLength(user.name, 18)}
                  {` (${user.id})`}
                </dt>
                <dt
                  title={
                    user?.companyname?.length > 18 ? user?.companyname : ""
                  }
                >
                  {overMaxLength(user.companyname, 18)}
                </dt>
              </dl>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SearchMemberTargetMenu;
