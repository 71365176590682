import { useRecoilValue } from "recoil";
import { get, del, post, getPage, createWithUploadFile } from ".";
import { authAtom } from "..";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const useWorkArchiveActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    getWorkArchiveList,
    getWorkArchiveView,
    getWorkArchiveFileUrl,
    createWorkArchive,
    updateWorkArchive,
    deleteWorkArchive,
    // deleteWorkArchiveFile,
  };

  async function getWorkArchiveList(data, url = `select/archive`) {
    return getPage(url, {
      ...data,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function getWorkArchiveView(data) {
    return get(`select/archive/attach`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkArchiveFileUrl(req) {
    return get(`select/archive/attach`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function createWorkArchive(
    data,
    url = `${auth.accountType}/archive/regist`
  ) {
    const res = await createWithUploadFile(
      url,
      {
        ...data,
        id: auth.user.id,
      },
      "post"
    );

    return res;
  }

  async function updateWorkArchive(
    data,
    url = `${auth.accountType}/archive/update`
  ) {
    const res = await createWithUploadFile(url, data);

    return res;
  }

  //   async function updateWorkGuide(data) {
  //     //파일이 있을 경우
  //     if (data.file.length) {
  //       const res = await createWithUploadFile(
  //         `${auth.accountType}/workguide/update`,
  //         {
  //           ...data,
  //           id: auth.user.id,
  //         }
  //       );

  //       return res;
  //     } else {
  //       const req = {
  //         ...data,
  //         id: auth.user.id,
  //       };
  //       const res = await post(`${auth.accountType}/workguide/update`, req);
  //       return res;
  //     }
  //   }

  async function deleteWorkArchive(req) {
    const res = await del(`admin/archive/delete`, req);
    return res;
  }

  // async function deleteAllWorkArchiveFiles(data) {
  //   const res = await getWorkArchiveFileUrl(data);
  //   const fileUrlData = res.data;
  //   for (let i = 0; i < fileUrlData.length; i++) {
  //     const req = { sn: fileUrlData[i].sn };
  //     await deleteWorkArchiveFile(req);
  //   }
  // }

  // async function deleteWorkArchiveFile(req) {
  //   return await del(`admin/archive/delete`, req)
  //     .then((res) => {
  //     })
  //     .catch((err) => {
  //       throw err;
  //     });
  // }
};
