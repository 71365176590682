import dayjs from "dayjs";
import { useState } from "react";
import { getXlsxDownloadHref } from "../../utils";
import { toast } from "react-toastify";

const XlsxDownload = ({ api, params = {}, name }) => {
  const [month, setMonth] = useState(dayjs().format("YYYY-MM"));

  return (
    <>
      <input
        className="d-none"
        type="month"
        style={{
          padding: 8,
          border: "1px solid #eee",
          color: "#9a9a9a",
          marginRight: 4,
        }}
        value={month}
        onChange={(e) => setMonth(e.target.value)}
      />
      <button
        className="download border-line mouse zoom_font"
        type="button"
        onClick={async () => {
          const toastId = toast.loading("다운로드 중... ");
          await getXlsxDownloadHref({
            api: api,
            params: {
              ...params,
              starttime: dayjs(month).startOf("month").format("YYYYMMDD"),
              endtime: dayjs(month).endOf("month").format("YYYYMMDD"),
              count: 10000,
              page: 1,
              isxlsx: 1,
            },
            name: name,
          })
            .then(() => {
              toast.update(toastId, {
                render: "다운로드가 완료되었습니다.",
                type: "success",
                isLoading: false,
                autoClose: 2000,
              });
            })
            .catch((err) => {
              if (err.response.status === 404) {
                toast.update(toastId, {
                  render: "다운로드 할 데이터가 없습니다.",
                  type: "warning",
                  isLoading: false,
                  autoClose: 2000,
                });
              } else {
                toast.update(toastId, {
                  render: "다운로드에 실패했습니다. 다시 시도해주세요.",
                  type: "error",
                  isLoading: false,
                  autoClose: 2000,
                });
              }
            });
        }}
      ></button>
    </>
  );
};

export default XlsxDownload;
