import axios from "axios";
import dayjs from "dayjs";
import JSZip from "jszip";
import { toast as reactToast } from "react-toastify";
import { getBlob } from "../recoil/api";

export const getXlsxDownloadHref = async ({ api, params, name }) => {
  await getBlob(api, { ...params }).then((res) => {
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.setAttribute(
      "download",
      `${name} - ${new Date().toLocaleDateString()}xlsx`
    );
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000 * 60 * 5);
    a.remove();
  });
};

// 유즈스테이트가 객체를 감지할때 안의 값은 같더라도 주소가 바뀌면 작동해서 값을 보고 비교함
// dateAndKeyword의 starttime은 서버에서 주는 일과 맞춰놔야 페이지 진입시 2번 동작하지 않음
export const isPageChanged = (changeCheck, dateAndKeyword, pageInfo) => {
  if (
    changeCheck?.starttime === dateAndKeyword?.starttime &&
    changeCheck?.endtime === dateAndKeyword?.endtime &&
    changeCheck?.keyword === dateAndKeyword?.keyword &&
    changeCheck?.current === pageInfo?.current
  ) {
    return true;
  } else {
    return false;
  }
};

export const isJson = (str = "") => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const overMaxLength = (string, maxLength) => {
  if (typeof string === "string") {
    return string.length > maxLength
      ? `${string.substring(0, maxLength)}...`
      : string;
  }
};

export const fileNameCheck = (files) => {
  let nameChecked = false;
  Array.from(files.target.files).map((file) => {
    if (file.name.includes("'")) nameChecked = true;
  });
  if (nameChecked) {
    return false;
  }
  return true;
};

export const fileSizeCheck = (files, filesSize) => {
  let nameChecked = false;
  Array.from(files.target.files).map((file) => {
    if (file.size > filesSize * 1024 * 1024) nameChecked = true;
  });
  if (nameChecked) {
    return false;
  }
  return true;
};

// 파일 이름에 확장자까지 있을때 확장자 떼버리는 함수
export const downloadFileSetting = (downloadFile) => {
  let downloadFileData = { ...downloadFile };
  downloadFileData.filename = downloadFileData.filename.split(".");
  downloadFileData.filename.pop();
  downloadFileData.filename = downloadFileData.filename.join(".");
  return downloadFileData;
};

export const downloadFile = async (fileValue, url = fileValue?.url) => {
  if (!url.includes("?alt=media")) url = `${url}?alt=media`;
  //TO-DO toast로 표시하기
  await reactToast.promise(
    fetch(url, { method: "GET" })
      .then((res) => {
        if (res.status === 404) {
          alert(
            "서버 이전으로 자료를 옮기고 있습니다.\n빠른 시일내로 정상화 하겠습니다."
          );
          throw "error";
        }
        return res.blob();
      })
      .then((blob) => {
        console.log("File Downloading...");
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileValue?.filename}.${
          fileValue?.extension ? fileValue?.extension : fileValue?.extention
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 1000 * 60 * 5);
        a.remove();
      }),
    {
      pending: "파일을 준비하고 있습니다.",
      success: "파일이 준비되었습니다.",
      error: "문제가 생겼습니다. 다시 시도해주세요.",
    }
  );
};

export const zipDownload = async (files, name, type) => {
  const zip = new JSZip();
  let fileBlob;

  for (let i = 0; i < files?.length; i++) {
    if (!files[i].url.includes("?alt=media") && type === 0) {
      files[i].url = `${files[i].url}?alt=media`;
    }
    fileBlob = await axios.get(files[i].url, {
      responseType: "blob",
    });
    zip.file(`${files[i].filename}.${files[i].extension}`, fileBlob.data);
  }

  zip.generateAsync({ type: "blob" }).then((resZip) => {
    const url = window.URL.createObjectURL(resZip);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}_${dayjs(new Date()).format("YYYYMMDDHHmmss")}.zip`;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000 * 60 * 5);
    a.remove();
  });
};

export const downloadFiles = async (obj, api, data, name, type) => {
  let fileList = await api(data);

  fileList = fileList?.data;

  if (fileList?.list) {
    fileList = fileList.list;
  }

  if (fileList?.length === 1) {
    await downloadFile(fileList[0]);
  } else if (fileList?.length > 1) {
    await zipDownload(fileList, name, type);
  }
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const dateUtils = {
  mmssToTime: (mmss) => {
    const mm = mmss.slice(0, -2);
    const ss = mmss.slice(2);
    return `${mm}:${ss}`;
  },
  startEndOfMonthObj: (date = dayjs()) => {
    return {
      starttime: date.startOf("month").format("YYYYMMDD"),
      endtime: date.endOf("month").format("YYYYMMDD"),
    };
  },
  dateOfWeek: (date = dayjs()) => {
    return {
      starttime: date.startOf("week").format("YYYYMMDD"),
      endtime: date.endOf("week").format("YYYYMMDD"),
    };
  },
  dateToDayjs: (date) => {
    if (date) return dayjs(date.slice(0, -2));
  },
  dateToHm: (date) => {
    return dayjs(date.slice(0, -2)).format("HH:mm");
  },
  dateToYMD: (date) => {
    if (date) return dayjs(date.slice(0, -2)).format("YYYY-MM-DD");
  },

  minDate: () => {
    return new Date(dayjs().year("2018").month(0).date(1));
  },

  maxDate: () => {
    return new Date(
      dayjs()
        .year(dayjs().year() + 1)
        .month(11)
        .date(31)
    );
  },

  maxYMD: () => {
    return dayjs()
      .year(dayjs().year() + 1)
      .month(11)
      .date(31)
      .format("YYYY-MM-DD");
  },
};

const getAvailableYear = () => {
  const minYear = dateUtils.minDate().getFullYear();
  const maxYear = dateUtils.maxDate().getFullYear();
  const list = [];
  for (let i = minYear; i <= maxYear; i++) {
    list.push({
      value: i,
      label: i,
    });
  }
  return list;
};

export const yearOptions = getAvailableYear();

export const monthOptions = [
  {
    value: 0,
    label: "1월",
  },
  {
    value: 1,
    label: "2월",
  },
  {
    value: 2,
    label: "3월",
  },
  {
    value: 3,
    label: "4월",
  },
  {
    value: 4,
    label: "5월",
  },
  {
    value: 5,
    label: "6월",
  },
  {
    value: 6,
    label: "7월",
  },
  {
    value: 7,
    label: "8월",
  },
  {
    value: 8,
    label: "9월",
  },
  {
    value: 9,
    label: "10월",
  },
  {
    value: 10,
    label: "11월",
  },
  {
    value: 11,
    label: "12월",
  },
];

/** unit: mb */
export const singleFileLimitSize = (file, limit) => {
  if (file.size > limit * 1024 * 1024) {
    return true;
  } else {
    return false;
  }
};
