import { useRecoilValue } from "recoil";
import { post, get, del, getPage, createWithUploadFile } from ".";
import { authAtom } from "..";

export const useWorkOrderActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    searchEmployeeByCompany,
    createWorkOrder,
    updateWorkOrder,
    getWorkOrderList,
    getWorkOrderWrite,
    getWorkOrderView,
    getWorkOrderFileUrl,
    getWorkOrderCompressFileUrl,
    deleteWorkOrder,
    deleteWorkOrderFile,
  };

  async function createWorkOrder(
    data,
    url = `${auth.accountType}/workorder_write/write`
  ) {
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(url, {
        ...data,
        id: auth.user.id,
      });

      return res;
    } else {
      // 파일이 없는 경우
      const req = {
        ...data,
        id: auth.user.id,
      };
      const res = await post(url, req);
      return res;
    }
  }

  async function updateWorkOrder(
    data,
    url = `${auth.accountType}/workorder/update`
  ) {
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(
        url,
        {
          ...data,
        },
        undefined,
        "post",
        true
      );

      return res;
    } else {
      // 파일이 없는 경우
      const req = {
        ...data,
        url: data.prevFiles.filter((ele) => ele),
        prevFiles: null,
      };
      const res = await post(url, req);
      return res;
    }
  }

  async function searchEmployeeByCompany() {
    const res = await get("search/company/employee", {
      id: auth.user.id,
    });
    return res;
  }
  async function getWorkOrderList(data) {
    return getPage(
      `${auth.accountType}/workorder`,
      auth.user.isEmployee
        ? {
            ...data,
            id: auth.user.id,
          }
        : auth.user.isCompany
        ? {
            ...data,
            companyId: auth.user.id,
          }
        : auth.user.isAdmin && {
            ...data,
            // COMP_CD 어떻게 해야 함??
          }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkOrderWrite(data) {
    return get(`company/workorder_write`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  async function getWorkOrderView(data) {
    const req = {
      ...data,
    };
    return get(`select/workorder`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkOrderFileUrl(data) {
    const req = {
      ...data,
    };
    return await get(`select/workdoc/attachlink`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkOrderCompressFileUrl(req) {
    return await post(`getCompressFiles`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deleteWorkOrder(data) {
    await deleteAllWorkOrderFiles(data);

    return await del(`delete/workorder`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function deleteAllWorkOrderFiles(data) {
    const res = await getWorkOrderFileUrl(data);
    const fileUrlData = res.data;
    for (let i = 0; i < fileUrlData.length; i++) {
      const req = { sn: fileUrlData[i].sn };
      await deleteWorkOrderFile(req);
    }
  }

  async function deleteWorkOrderFile(req) {
    // await del(`delete/workorderattach`, req)
    //   .then((res) => {
    //   })
    //   .catch((err) => {
    //     throw err;
    //   });
  }
};
