import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  DetailMailQuery,
  MailUserListQuery,
  MailViewAtom,
} from "../../../recoil";
import File from "../../../img/pop-atta.png";
import { MAIL_VIEW } from "./MailLayout";
import { downloadFile, isJson } from "../../../utils";
import { downloadFileSetting } from "../../../utils";
import DOMPurify from "dompurify";

export const FileContent = ({ file_contents }) => {
  if (file_contents && isJson(file_contents)) {
    const contents = JSON.parse(file_contents)?.contents;
    return (
      <div
        className="pointer"
        onClick={() => {
          downloadFile(downloadFileSetting(contents));
        }}
      >
        {contents.filename || contents.url}
      </div>
    );
  }
};
const SentMailDetail = () => {
  const detailMail = useRecoilValue(DetailMailQuery({ open: true }));
  const setView = useSetRecoilState(MailViewAtom);
  const setMailUserList = useSetRecoilState(MailUserListQuery());

  let des_List = "";
  {
    detailMail?.des_List?.map((item, idx) => {
      return (des_List =
        des_List + `${item.name}(${item.id}_${item.company_name}) `);
    });
  }

  return (
    <div id="message_detail" className="poimt sendmess">
      <div className="sendcont">
        <div className="tit cf">
          <div className="name">
            <span>받는사람 : </span>
            <div title={des_List}>
              {detailMail?.des_List?.map((item, idx) => {
                return (
                  <>
                    <strong
                      key={item.id}
                    >{`${item?.name}(${item?.id}_${item?.company_name}) `}</strong>
                  </>
                );
              })}
            </div>
          </div>
          <div className="date">
            <span>{detailMail?.createtime}</span>
          </div>
          <div>&nbsp;</div>
        </div>
        <div
          className="tit cf"
          style={{
            background: "#eee",
            color: "gray",
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <div>제목 : {detailMail?.title}</div>
        </div>
        <div className="cont">
          <div
            className="zoom_font_id ql-editor"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(detailMail?.contents),
            }}
          ></div>
        </div>
      </div>
      <div>
        <div className="searchee">
          {detailMail?.file_contents ? (
            <FileContent file_contents={detailMail?.file_contents} />
          ) : (
            <a>
              <img src={File} />
              첨부파일이 없습니다.
            </a>
          )}
        </div>
      </div>
      <div className="btn-group">
        <button
          className="graybtn-lg"
          onClick={() => setView(MAIL_VIEW.SENT_MAIL)}
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default SentMailDetail;
