import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SearchCompanyModalAtom, toastAtom } from "../../../recoil";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import useFreshActions from "../../../recoil/api/fresh";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import { dateUtils } from "../../../utils";
import { errorcase } from "../../errorcase";
import CommonModal from "../../modal/CommonModal";
import BreadCrumb from "../BreadCrumb";
import DataTable from "../DataTable";
import PaginationBar from "../PaginationBar";
import SearchByDate from "../SearchByDate";
import SearchCompanyModal from "../SearchCompanyModal";

const Fresh = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const { register, handleSubmit, reset } = useForm();

  const accountType = useAccoutTypeActions();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(DateState);
  const [holidays, setHolidays] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [isNational, setIsNational] = useState(true);
  const [isRegistHolidayModalOpen, setIsRegistHolidayModalOpen] =
    useState(false);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const freshActions = useFreshActions();

  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);

  const [company, setCompany] = useState({});
  const [isUseVacation, setIsUseVacation] = useState(false);

  useEffect(() => {
    getHolidayByDate(date);
  }, [date, pageInfo?.current, isNational]);

  //어드민이고, company의 COMP_CD가 있고(not empty), 모달이 닫혀있을 때 검색
  useEffect(() => {
    if (accountType.isAdmin() && !isRegistHolidayModalOpen) {
      getHolidayByDate(date);
    }
  }, [company]);

  const getHolidayByDate = async (date) => {
    const api = isNational
      ? freshActions.getHolidayNationalByDate
      : freshActions.getHolidayCompanyByDate;

    setLoading(true);
    setHolidays([]);
    let params = { date, pageInfo };

    //admin이고, COMP_CD가 있다면 COMP_CD 추가
    accountType.isAdmin() &&
      company.COMP_CD &&
      !isNational &&
      (params.COMP_CD = company?.COMP_CD);

    const res = await api(params);
    if (res.status === 200) {
      setHolidays(res.data.list);
      setPageInfo(res.data.pageinfo || PageInfoState);
    }
    setLoading(false);
  };

  const onChangeRegistHolidayModalState = () => {
    setCompany({});
    setIsRegistHolidayModalOpen(false);
  };

  const delHoliday = async (sn) => {
    setLoading(true);
    const res = await freshActions.delHoliday({ sn });
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      getHolidayByDate(date);
    }
    setLoading(false);
  };

  //regist company holiday
  const onSubmit = async (data) => {
    let params = {
      ...data,
      holiday_YMD: dayjs(data.holiday_YMD).format("YYYYMMDD"),
    };

    //isAdmin, no company, return false
    if (accountType.isAdmin() && !company.COMP_CD) {
      setToast({ ...toast, error: true, message: "회사명을 입력하세요." });
      return false;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    //isAdmin, post COMP_CD
    if (accountType.isAdmin() && company.COMP_CD) {
      params.COMP_CD = company.COMP_CD;
    }

    setLoading(true);
    const res = await freshActions.postCompanyHoliday(params);
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      setIsRegistHolidayModalOpen(false);
      getHolidayByDate(date);
    } else {
      setToast({ ...toast, error: true, message: errorcase(res.data) });
    }
    setLoading(false);

    reset();
    setIsUseVacation(false);

    setDoubleclickLoading(false);
  };

  return (
    <div>
      <BreadCrumb />
      <div className="date-filtering flex-direction-column">
        <div className="all-group all-group-holiday">
          {accountType.isAdmin() && (
            <button
              style={{ marginRight: "10px" }}
              className="secondarybtn-md"
              type="button"
              onClick={() => {
                setIsSearchCompanyModalOpen(true);
              }}
            >
              {company?.company_name || "기업 선택"}
            </button>
          )}
          <button
            className={`holidaybtn border-line mouse zoom_font ${
              isNational ? "bluebtn" : "trans"
            }`}
            type="button"
            onClick={() => setIsNational(true)}
          >
            국가휴일
          </button>
          <button
            className={`holidaybtn border-line mouse zoom_font ${
              isNational ? "trans" : "bluebtn"
            }`}
            type="button"
            onClick={() => setIsNational(false)}
          >
            기업휴일
          </button>

          {/* 기업휴일 선택시, 해당 기업 검색 state: company (기업 휴일 등록과 state 공유) */}

          {!accountType.isEmployee() && (
            <button
              className="holidaybtn redbtn border-line mouse zoom_font"
              type="button"
              onClick={() => {
                setCompany({});
                setIsRegistHolidayModalOpen(true);
              }}
            >
              휴일등록
            </button>
          )}
        </div>

        <SearchByDate
          placeholder="휴일명 입력"
          dateAndKeyword={date}
          defaultStarttime={dayjs().month(0).date(1)}
          defaultEndtime={dayjs().month(11).date(31)}
          onChange={(value) => {
            setDate(value);
          }}
          loading={loading}
        />
      </div>

      <DataTable
        loading={loading}
        checkbox={false}
        columns={
          isNational === true
            ? ["구분", "휴일명", "휴일"]
            : ["구분", "휴일명", "휴일", "개인연차소모", "작업"]
        }
        isResponsive={isNational === true ? false : true}
      >
        {holidays?.map((holiday) => {
          return (
            <tr key={holiday.sn} className="text-center">
              <td className="border-line zoom_font">
                {isNational === true ? "대한민국" : holiday.company_name}
              </td>
              <td className="border-line zoom_font">{holiday.holiday_name}</td>
              <td className="border-line zoom_font">
                {dayjs(holiday.holiday_datetime).format("YYYY-MM-DD")}
              </td>
              <td className="border-line zoom_font">
                {holiday.is_use_vacation ? "O" : "-"}
              </td>
              {!accountType.isEmployee() && !isNational && (
                <td>
                  <button
                    className="pinkbtn-sm border-line zoom_font"
                    onClick={() => {
                      if (window.confirm("삭제하시겠습니까?")) {
                        delHoliday(holiday.sn);
                      } else {
                        return;
                      }
                    }}
                  >
                    삭제
                  </button>
                </td>
              )}
            </tr>
          );
        })}
      </DataTable>

      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />

      <CommonModal
        title="휴일등록"
        open={isRegistHolidayModalOpen}
        onChangeModalState={onChangeRegistHolidayModalState}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="writetable" style={{ border: "1px solid #e5e5e5" }}>
            <tbody>
              {accountType.isAdmin() && (
                <tr>
                  <th className="text-center-title w-15 zoom_font">회사명</th>
                  <td
                    colSpan={3}
                    className="zoom_font"
                    style={{ borderBottom: "1px solid #e5e5e5" }}
                  >
                    <button
                      type="button"
                      className="secondarybtn-md border-line zoom_font"
                      onClick={() => setIsSearchCompanyModalOpen(true)}
                    >
                      {!company.COMP_CD ? (
                        "기업 선택"
                      ) : (
                        <div>{company?.company_name}</div>
                      )}
                    </button>
                  </td>
                </tr>
              )}
              <tr>
                <th className="text-center-title zoom_font w-25 padding-0">
                  휴일명
                </th>
                <td className="zoom_font w-25" colSpan={3}>
                  <input
                    style={{ width: "100%" }}
                    {...register("holiday_name", { required: true })}
                    className="custom-input border-line mouse zoom_font"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font w-25 padding-0">
                  날짜 선택
                </th>
                <td className="w-38">
                  <input
                    style={{ width: "100%" }}
                    {...register("holiday_YMD", { required: true })}
                    className="date-input custom-input border-line mouse zoom_font"
                    type="date"
                  />
                </td>
                <th className="text-center-title zoom_font w-25 padding-0">
                  개인연차소모
                </th>
                <td className="zoom_font w-25 text-center">
                  <label>
                    <input
                      type="checkbox"
                      {...register("isUseVacation", {
                        required: false,
                        onChange: () => {
                          setIsUseVacation(!isUseVacation);
                        },
                      })}
                    />
                    {isUseVacation ? (
                      <div className="chkbox border-line mouse checked"></div>
                    ) : (
                      <div className="chkbox border-line mouse "></div>
                    )}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="btn-group">
            <button className="fbtn submitbtn" type="submit">
              저장
            </button>
            <button
              type="button"
              className="fbtn cancelbtn"
              onClick={() => onChangeRegistHolidayModalState()}
            >
              취소
            </button>
          </div>
        </form>
      </CommonModal>

      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
    </div>
  );
};

export default Fresh;
