import { DEFAILT_PAGE_COUNT } from "..";
import { v4 as uuidv4 } from "uuid";
import { singleFileLimitSize } from "../../utils";
import { refresh, refreshErrorHandle } from "./refresh";
import axios from "axios";

export const SERVER = `/api`;

export const Api = axios.create({
  baseURL: "",
  timeout: 1000 * 30,
  params: {},
});

export const Api60 = axios.create({
  baseURL: "",
  timeout: 1000 * 3600,
  // timeout: 5,
  params: {},
});

Api.interceptors.request.use(refresh, refreshErrorHandle);
Api60.interceptors.request.use(refresh, refreshErrorHandle);
const sessionCheck = (error) => {
  if (error.response.status === 403) {
    if (localStorage.getItem("user")) {
      alert("세션이 만료되었습니다. 다시 로그인 해주세요.");
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expiresAt");
    }
    window.location.reload();
    window.location.href("/");
  }
};
export const getBlob = async (url, data) => {
  const res = await Api60.get(`${SERVER}/${url}`, {
    params: data,
    responseType: "blob",
  });
  return res;
};

export const put = async (url, data) => {
  const res = await Api.put(`${SERVER}/${url}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionCheck(err);
      return err.response;
    });

  return res;
};

export const post = async (url, data) => {
  const res = await Api.post(`${SERVER}/${url}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionCheck(err);
      return err.response;
    });

  return res;
};

export const queryPost = async (url, data) => {
  const res = await Api.post(`${SERVER}/${url}`, null, {
    params: { ...data },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionCheck(err);
      return err.response;
    });

  return res;
};

export const getPage = async (url, data) => {
  const req = {
    ...data,
    count: data.count || data.pageInfo?.count || DEFAILT_PAGE_COUNT,
    page: data.pageInfo?.current || data.page || 1,
    starttime: data.dateAndKeyword?.starttime || data.date?.starttime || "",
    endtime: data.dateAndKeyword?.endtime || data.date?.endtime || "",
    keyword:
      data.dateAndKeyword?.keyword ||
      data.dateAndKeyword?.searchWord ||
      data.date?.searchWord ||
      data.date?.keyword ||
      data.keyword ||
      null,
    dateAndKeyword: null,
    pageInfo: null,
    date: null,
  };

  if (data.isxlsx) {
    const xlsxHref = Api.getUri({
      url: `${SERVER}/${url}`,
      params: req,
    });

    return xlsxHref;
  }

  const res = await Api.get(`${SERVER}/${url}`, { params: req })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      sessionCheck(err);
      return err;
    });

  return res;
};

export const get = async (url, data) => {
  const res = await Api.get(`${SERVER}/${url}`, { params: data })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      sessionCheck(err);
      return err;
    });

  return res;
};

export const del = async (url, data) => {
  const res = await Api.delete(`${SERVER}/${url}`, { params: data })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionCheck(err);
      return err;
    });

  return res;
};

export const delBody = async (url, data) => {
  const res = await Api.delete(`${SERVER}/${url}`, { data: data })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionCheck(err);
      return err;
    });

  return res;
};

export const uploadfile = async (files, check, filename = uuidv4()) => {
  //파일 객체랑, uuid 파일명이 같이 감
  //폼을 새로 만드는데 그 이유가 파일은 json으로 전송이 안 됨
  let form = new FormData();
  let file;

  //파일명을 바꿔주기 위해서 파일명 추출하고, uuid로 파일명 교체함
  if (files.url) {
    file = files.url;
  } else {
    file = files.file;
  }
  if (check !== "doc") {
    if (singleFileLimitSize(file, 300)) {
      console.log("file Size : ", file.size);
      alert(`파일 용량은 ${300}mb를 초과할 수 없습니다.`);
      return [(file = false), false];
    }
  }

  let blob = file.slice(0, file.size, file.type);
  const newFile = new File([blob], filename, { type: file.type });

  form.append("file", newFile);
  const res = await Api60.post(`${SERVER}/uploadfile`, form)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionCheck(err);
      return err;
    });

  return [file, res];
};

export const uploadfiledocs = async (files, path, filename = uuidv4()) => {
  let file = files.file;
  let form = new FormData();
  let blob = file.slice(0, file.size, file.type);
  const newFile = new File([blob], filename, { type: file.type });
  form.append("file", newFile);
  form.append("filepath", path);
  const res = await Api60.post(`${SERVER}/uploadfiledocs`, form)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return [file, res];
};

export const popupUploadFile = async (file) => {
  let form = new FormData();
  let filename = uuidv4();

  const newFile = new File([file], filename, { type: file.type });

  form.append("file", newFile);
  const res = await Api.post(`${SERVER}/uploadfile`, form)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      sessionCheck(err);
      return err;
    });

  return [file, res];
};

export const createWithUploadFile = async (
  url,
  data,
  check,
  method = "post",
  update = false
) => {
  let errorCheck = false;
  let errorReason = "";
  const originalFileUrls = data.prevFiles ? [...data.prevFiles] : [];
  const fileUrls = data.prevFiles ? [...data.prevFiles] : [];
  if (data.file.length === 1) {
    const [file, fileRes] = await uploadfile({ url: data.file[0].file }, check);
    if (file && fileRes) {
      const fileDot = file.name.lastIndexOf(".");
      const fileName = file.name.substring(0, fileDot).toLowerCase();
      const fileType = file.name
        .substring(fileDot + 1, file.name.length)
        .toLowerCase();
      if (fileRes?.name === "AxiosError") {
        errorCheck = true;
        errorReason = fileRes?.message;
      }
      fileUrls.push({
        filename: fileName,
        extension: fileType,
        url: fileRes.data,
      });
    }
  } else {
    for (let i = 0; i < data.file.length; i++) {
      const [file, fileRes] = await uploadfile(
        {
          url: data.file[i].file,
        },
        check
      );
      if (file && fileRes) {
        const fileDot = file.name.lastIndexOf(".");
        const fileName = file.name.substring(0, fileDot).toLowerCase();
        const fileType = file.name
          .substring(fileDot + 1, file.name.length)
          .toLowerCase();
        if (fileRes.name === "AxiosError") {
          errorCheck = true;
          errorReason = fileRes?.message;
        }
        fileUrls.push({
          filename: fileName,
          extension: fileType,
          url: fileRes.data,
        });
      }
    }
  }
  // 2. 첨부 파일 업로드 API 로부터 URL을 응답받아, 글 작성 API에 함께 요청
  let req = {
    ...data,
    file: null,
    prevFiles: null,
    url: fileUrls.filter((ele) => ele),
  };
  if (errorCheck === true && update === false) {
    req = { ...data };
    alert("파일 업로드 오류(1)\nerrorReason : " + errorReason);
    window.location.reload();
    return;
  }
  if (errorCheck === true && update === true) {
    req = {
      ...data,
      file: null,
      prevFiles: null,
      url: originalFileUrls.filter((ele) => ele),
    };
    alert("파일 업로드 오류(2)\nerrorReason : " + errorReason);
    window.location.reload();
    return;
  }

  // 자료실 전용 실패처리
  if (
    errorCheck === true &&
    (url?.includes("archive/regist") || url?.includes("archive/update"))
  ) {
    return { status: 400 };
  }

  if (method === "put") {
    return await put(`${url}`, req);
  } else if (method === "post") {
    return await post(`${url}`, req);
  }
};
