import { useState } from "react";
import CommonModal from "../../modal/CommonModal";
import FindPhone from "./FindPhone.jsx";
import IdPop from "../../../img/id_pop.png";
import { useUserActions } from "../../../recoil/api/auth";
import IdFound from "./IdFound";
import { toastAtom } from "../../../recoil";
import { useRecoilState } from "recoil";
import { emailRegex, phoneRegex } from "../../../utils/regex";

const FindIdModal = ({ open, setOpen, setFindPasswordModalOpen }) => {
  const userActions = useUserActions();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [toast, setToast] = useRecoilState(toastAtom);

  const onSubmit = async () => {
    if (!name) {
      setToast({ ...toast, error: true, message: "이름을 입력하세요." });
      return false;
    }
    if (!phone) {
      setToast({ ...toast, error: true, message: "휴대폰번호를 입력하세요." });
      return false;
    }
    if (!phoneRegex.test(phone)) {
      setToast({
        ...toast,
        error: true,
        message: "휴대폰번호 양식에 맞지 않습니다.",
      });
      return false;
    }
    const res = await userActions.findId({
      name,
      tel: phone,
    });

    if (res.status === 200) {
      setId(res.data.employeeId);
      setCompanyId(res.data.companyId);
      setName("");
      setPhone("");
    } else {
      setToast({
        ...toast,
        error: true,
        message: "아이디를 찾을 수 없습니다.",
      });
    }
  };

  return (
    <CommonModal title="아이디 찾기" open={open} setOpen={setOpen}>
      {!id && (
        <>
          <div className="id_tit idpass">
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <img
                src={IdPop}
                alt="아이디 아이콘"
                style={{ display: "inline-block", verticalAlign: "top" }}
              />
            </span>
            <div className="text textin">
              <strong>아이디가 기억나지 않으세요?</strong>
              <p>회원가입시 입력한정보로 아이디를 찾으실 수 있습니다.</p>
            </div>
          </div>
          <div className="id_input lotable">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="이름"
            />
            <FindPhone setPhone={setPhone} />
            <div className="s-button s-idpass cf">
              <button
                className="id_sear id_width sc"
                onClick={() => onSubmit()}
              >
                아이디 찾기
              </button>
            </div>
          </div>
        </>
      )}

      {id && (
        <IdFound
          id={id}
          setId={setId}
          companyId={companyId}
          setCompanyId={setCompanyId}
          setOpen={setOpen}
          setFindPasswordModalOpen={setFindPasswordModalOpen}
        />
      )}
    </CommonModal>
  );
};
export default FindIdModal;
