import { useEffect } from "react";
import { ToastContainer, toast as T } from "react-toastify";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../recoil";

const Toast = () => {
  const [toast, setToast] = useRecoilState(toastAtom);

  useEffect(() => {
    if (toast.on) {
      T.success(toast.message || toast.msg || "성공 했습니다.", {
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (toast.error) {
      T.error(toast.message || toast.msg || "실패 했습니다.", {
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setToast({ ...toast, on: false, error: false, message: null });
  }, [toast.on, toast.error]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={2500}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Toast;
