import React, { useEffect, useState } from "react";
import { SCHEDULETYPE } from "../../../JSON/enum/Dataenum";
import useScheduleActions from "../../../recoil/api/schedule";
import CommonModal from "../../modal/CommonModal";
import { authAtom, toastAtom } from "../../../recoil";
import { useRecoilState } from "recoil";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import ScheduleFunction from "./ScheduleFunction";
import Select from "react-select";
import { SelectManagerList } from "../SelectManagerList";

// 담당자 참석자가 계속 바뀌어서 한번에 바꿀 수 있게 고정해둠
export const keeper = "참석자";

const ScheduleRegisterationDetail = ({
  eventDetail,
  setOpen,
  COMP_CD,
  delEdit,
}) => {
  const scheduleActions = useScheduleActions();
  const accountType = useAccoutTypeActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const scFn = ScheduleFunction();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useRecoilState(authAtom);

  // 참석자 선택쪽 변수
  const [isSelectManagerModalOpen, setIsSelectManagerModalOpen] =
    useState(false);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [selectedEmployeeEsnList, setSelectedEmployeeEsnList] = useState([]);
  const onChangeSelectManagerModalState = () => {
    setIsSelectManagerModalOpen(false);
  };

  const [eventscheduleType, setEventscheduleType] = useState(""); //scheduleType
  const [sdate, setSdate] = useState(""); //sdate
  const [stime, setStime] = useState(""); //stime
  const [edate, setEdate] = useState(""); //edate
  const [etime, setEtime] = useState(""); //etime
  const [location, setLocation] = useState(""); //location
  const [contents, setContents] = useState(""); //contents

  useEffect(() => {
    setEventscheduleType(eventDetail.scheduleType);
    setSdate(String(eventDetail.starttime).split(" ")[0]);
    setStime(String(eventDetail.starttime).split(" ")[1]);
    setEdate(String(eventDetail.endtime).split(" ")[0]);
    setEtime(String(eventDetail.endtime).split(" ")[1]);
    setLocation(eventDetail.location);
    setContents(eventDetail.contents);

    const idArr = eventDetail?.target_id?.split(",");
    const nameArr = eventDetail?.name?.split(",");
    setSelectedEmployeeList(
      eventDetail?.target_sn?.map((item, idx) => {
        if (item?.type === "csn") {
          return {
            employee_name: nameArr[idx],
            esn: item?.sn,
            id: idArr[idx],
          };
        } else {
          return {
            employee_name: nameArr[idx],
            esn: item?.sn,
            id: idArr[idx],
          };
        }
      })
    );
    setSelectedEmployeeEsnList(
      eventDetail?.target_sn?.map((item, idx) => {
        return item?.sn;
      })
    );
  }, [eventDetail]);

  const scheduleType = [
    {
      value: SCHEDULETYPE.MEETING,
      label: scFn.getScheduleType(SCHEDULETYPE.MEETING),
    },
    {
      value: SCHEDULETYPE.BIRTHDAY,
      label: scFn.getScheduleType(SCHEDULETYPE.BIRTHDAY),
    },
    {
      value: SCHEDULETYPE.HALFHALFOFF,
      label: scFn.getScheduleType(SCHEDULETYPE.HALFHALFOFF),
    },
    {
      value: SCHEDULETYPE.HALFOFF,
      label: scFn.getScheduleType(SCHEDULETYPE.HALFOFF),
    },
    {
      value: SCHEDULETYPE.DAYOFF,
      label: scFn.getScheduleType(SCHEDULETYPE.DAYOFF),
    },
    {
      value: SCHEDULETYPE.OUTWORKING,
      label: scFn.getScheduleType(SCHEDULETYPE.OUTWORKING),
    },
    {
      value: SCHEDULETYPE.BUSINESSTRIP,
      label: scFn.getScheduleType(SCHEDULETYPE.BUSINESSTRIP),
    },
    {
      value: SCHEDULETYPE.CONFFRENCE,
      label: scFn.getScheduleType(SCHEDULETYPE.CONFFRENCE),
    },
  ];

  const changeable = () => {
    if (auth.user.isAdmin === true || auth.user.isCompany === true) {
      return false;
    } else if (auth.user.id === eventDetail.writer_id) {
      return false;
    } else {
      return true;
    }
  };

  const updateSchedule = async () => {
    setLoading(true);
    const res = await scheduleActions.putSchedule({
      sn: eventDetail.sn,
      type: eventscheduleType,
      starttime: `${sdate} ${stime}`,
      endtime: `${edate} ${etime}`,
      location: location,
      contents: contents,
      attendant: selectedEmployeeList?.map((ele) => ele?.id),
    });
    if (res.status === 200) {
      delEdit(true);
    }
    setLoading(false);
  };

  const deleteSchedule = async () => {
    setLoading(true);
    setToast({ ...toast, on: true });

    const res = await scheduleActions.delSchedule({ sn: eventDetail.sn });
    if (res.status === 200) {
      delEdit(true);
    }
    setLoading(false);
  };

  return (
    <div className="table-responsive">
      <table style={{ minWidth: "400px" }}>
        <tbody>
          <tr>
            <th className="text-center-title">일정 종류</th>
            <td style={{ display: "flex" }}>
              {!changeable() ? (
                <Select
                  placeholder={scFn.getScheduleType(
                    parseInt(eventDetail.scheduleType)
                  )}
                  options={scheduleType}
                  onChange={(e) => {
                    setEventscheduleType(e.value);
                  }}
                />
              ) : (
                <input
                  style={{ width: "100%" }}
                  value={scFn.getScheduleType(
                    parseInt(eventDetail.scheduleType)
                  )}
                  readOnly={true}
                />
              )}
            </td>
          </tr>
          <tr>
            <th className="text-center-title">시간</th>
            <td style={{ display: "flex" }}>
              <input
                className="s-custom-input custom-input"
                type="date"
                value={sdate || ""}
                disabled={changeable()}
                onChange={(e) => {
                  setSdate(e.target.value);
                }}
              />
              {eventDetail.scheduleType !== 5 && (
                <input
                  className="s-custom-input custom-input"
                  type="time"
                  value={stime || ""}
                  disabled={changeable()}
                  onChange={(e) => {
                    setStime(e.target.value);
                  }}
                />
              )}
              <span className="wave">-</span>
              <input
                className="s-custom-input custom-input"
                type="date"
                value={edate || ""}
                disabled={changeable()}
                onChange={(e) => {
                  setEdate(e.target.value);
                }}
              />
              {eventDetail.scheduleType !== 5 && (
                <input
                  className="s-custom-input custom-input"
                  type="time"
                  value={etime || ""}
                  disabled={changeable()}
                  onChange={(e) => {
                    setEtime(e.target.value);
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th className="text-center-title">위치</th>
            <td>
              <input
                className="s-custom-input custom-input w-100"
                defaultValue={eventDetail.location || ""}
                disabled={changeable()}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <th className="text-center-title">설명</th>
            <td>
              <input
                className="s-custom-input custom-input w-100"
                defaultValue={eventDetail.contents || ""}
                disabled={changeable()}
                onChange={(e) => {
                  setContents(e.target.value);
                }}
              />
            </td>
          </tr>
          {accountType.isAdmin() && (
            <tr>
              <th className="text-center-title">기업</th>
              <td>
                <div>{eventDetail?.company_name || "-"}</div>
              </td>
            </tr>
          )}
          <tr>
            <th className="text-center-title">{keeper}</th>
            <td>
              {/* 여기 인풋에 언디파인 들어가서 워닝 뜸 */}
              <input
                className="custom-input border-line mouse zoom_font"
                type="text"
                style={{ width: "70%" }}
                value={selectedEmployeeList?.map((obj) => obj?.employee_name)}
                readOnly
              />
              {!changeable() && (
                <button
                  className="add-manager"
                  onClick={() => {
                    setIsSelectManagerModalOpen(true);
                  }}
                  type="button"
                >
                  {keeper} 선택
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div id="modalfoot">
        <div className="btn-group">
          {!changeable() && (
            <>
              <button
                className="fbtn greenbtn"
                type="button"
                onClick={() => {
                  setOpen(false);
                  updateSchedule();
                }}
              >
                수정
              </button>
              <button
                className="fbtn pinkbtn-sm"
                type="button"
                onClick={() => {
                  if (window.confirm("삭제 하시겠습니까?")) {
                    setOpen(false);
                    deleteSchedule();
                  }
                }}
              >
                삭제
              </button>
            </>
          )}
          <button
            className="fbtn cancelbtn"
            type="button"
            onClick={() => {
              setOpen(false);
            }}
          >
            확인
          </button>
        </div>
      </div>
      <SelectManagerList
        COMP_CD={auth.user.isAdmin && COMP_CD}
        selectedEmployeeList={selectedEmployeeList}
        setSelectedEmployeeList={setSelectedEmployeeList}
        selectedEmployeeEsnList={selectedEmployeeEsnList}
        setSelectedEmployeeEsnList={setSelectedEmployeeEsnList}
        open={isSelectManagerModalOpen}
        onChangeModalState={onChangeSelectManagerModalState}
        selfSelect={false}
      />
    </div>
  );
};

export default ScheduleRegisterationDetail;
