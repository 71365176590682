import { useRecoilValue } from "recoil";
import { post, get, getPage, del } from ".";
import { authAtom } from "..";
import { HOLIDAYTYPE } from "../../JSON/enum/Dataenum";
import { useAccoutTypeActions } from "./auth";

export const getHolidayType = (holidayType) => {
  switch (holidayType) {
    case HOLIDAYTYPE.DAYOFF:
      return "연차";
    case HOLIDAYTYPE.HALFAM:
      return "오전 반차";
    case HOLIDAYTYPE.HALFPM:
      return "오후 반차";
    case HOLIDAYTYPE.HALFHALF:
      return "반반차";
    case HOLIDAYTYPE.CONGRATULATION:
      return "경조사";
    case HOLIDAYTYPE.LEAVE:
      return "병가";
    case HOLIDAYTYPE.ETC:
      return "기타 휴가";
    default:
      return "오류";
  }
};

export const useHolidayActions = () => {
  const auth = useRecoilValue(authAtom);
  const accountType = useAccoutTypeActions();

  const { id } = auth.user;

  return {
    deleteVacation,
    getEmployeeVacation,
    createEmployeeVacation,
    getVacationApplication,
    approveVacation,
    rejectVacation,
    delVacation,
    getVacationActiveRule,
    createVacationRule,
    getVacationRules,
    deleteVacationRules,
    getVacationHistory,
  };

  async function deleteVacation(data) {
    const res = await post("employee/vacation/delete", data);
    return res;
  }

  async function getEmployeeVacation() {
    return get("employee/vacation/personalremain", { id })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }
  async function createEmployeeVacation(data) {
    return post("employee/vacation/application", { ...data, id })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }
  async function getVacationApplication(data) {
    let params = {
      ...data,
    };
    if (auth.user.isEmployee) {
      params.id = auth.user.id;
    }

    if (auth.user.isCompany) {
      params.COMP_CD = auth.user.COMP_CD;
    }

    const res = await getPage(
      `${auth.accountType}/vacation/application`,
      params
    );
    return res;
  }

  async function approveVacation(sn) {
    const res = await post("approve/vacation", {
      sn,
    });
    return res;
  }
  async function rejectVacation(sn) {
    const res = await post("reject/vacation", { sn });
    return res;
  }

  async function delVacation(sn) {
    const res = await post(`${auth.accountType}/vacation/delete`, {
      sn,
    });

    return res;
  }
  async function getVacationActiveRule() {
    const res = await get(`${auth.accountType}/vacation/rule`, {});
    return res;
  }

  async function createVacationRule(data) {
    return post(`${auth.accountType}/vacation/rule`, { ...data })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  async function getVacationRules(data) {
    let params = {
      ...data,
    };
    const res = await getPage(`${auth.accountType}/vacation/rules`, params);
    return res;
  }

  async function deleteVacationRules(list) {
    for (let i of list) {
      await del(`${auth.accountType}/vacation/rule`, { sn: i });
    }
    return true;
  }

  async function getVacationHistory(req) {
    let params = {
      ...req,
    };
    const res = await getPage(`${auth.accountType}/vacation/history`, params);
    return res;
  }
};
