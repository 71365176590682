import React, { useState, useEffect, useRef } from "react";
import SearchByDate from "../SearchByDate";
import DataTable from "../DataTable";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import dayjs from "dayjs";
import PaginationBar from "../PaginationBar";
import { downloadFiles } from "../../../utils";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useBoardBenefitActions } from "../../../recoil/api/boardBenefit";
import BreadCrumb from "../BreadCrumb";
import { overMaxLength } from "../../../utils";
import { DateState, PageInfoState } from "../../../recoil/types/data";

const BoardBenefit = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [dateAndKeyword, setDateAndKeyword] = useState(DateState);
  const [checkedItemIds, setCheckedItemIds] = useState([]);

  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  // API 요청 함수 - boardBenefitList 데이터 불러오기
  const boardBenefitActions = useBoardBenefitActions();

  const loadBoardBenefitList = async () => {
    setLoading(true);
    if (state?.prev) {
      const res = await boardBenefitActions.getBoardBenefitList({
        dateAndKeyword: state?.keyword,
        pageInfo: state?.page,
      });
      if (res) {
        setLoadedData(res.data.list);
        setPageInfo(res.data.pageinfo);
      }
      state.prev = false;
    } else {
      const res = await boardBenefitActions.getBoardBenefitList({
        dateAndKeyword,
        pageInfo,
      });
      if (res) {
        setLoadedData(res.data.list);
        setPageInfo(res.data.pageinfo);
      }
    }
    setLoading(false);
  };

  // API 요청 함수 - boardBenefit 데이터 삭제하기
  const deleteBoardBenefit = async (checkedItemIds) => {
    for (let i = 0; i < checkedItemIds.length; i++) {
      const req = { docsn: checkedItemIds[i] };
      const res = await boardBenefitActions.deleteBoardBenefit(req);
      if (res) {
        setToast({ ...toast, on: true });
      }
    }
    setCheckedItemIds([]);
    loadBoardBenefitList();
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      loadBoardBenefitList();
    }
  }, [pageInfo.current, dateAndKeyword]);

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          {/* 혜택 정보 작성 버튼 - 관리 계정에서 활성화 */}
          {auth.user.isAdmin && (
            <div>
              <Link to={`/${auth.accountType}/benefits_write`}>
                <button
                  className="holidaybtn greenbtn border-line mouse zoom_font pencil-img"
                  type="button"
                  id="workorder_write"
                >
                  작성하기
                </button>
              </Link>
              <button
                className="trashbtn border-line mouse"
                type="button"
                onClick={() => {
                  if (window.confirm("삭제하시겠습니까?")) {
                    deleteBoardBenefit(checkedItemIds);
                  } else {
                    return false;
                  }
                }}
              ></button>
            </div>
          )}
        </div>
        <SearchByDate
          period={30}
          defaultValue={state?.keyword?.keyword}
          defaultStarttime={
            state?.keyword?.starttime === undefined
              ? undefined
              : dayjs(state?.keyword.starttime)
          }
          defaultEndtime={
            state?.keyword?.endtime === undefined
              ? undefined
              : dayjs(state?.keyword.endtime)
          }
          onChange={(value) => setDateAndKeyword(value)}
          loading={loading}
        />
      </div>
      <DataTable
        loadedData={loadedData}
        loading={loading}
        setCheckedItemIds={setCheckedItemIds}
        checkbox={auth.user.isAdmin}
        columns={["제목", "작성자", "등록일", "첨부파일"]}
      >
        {loadedData?.map((obj) => {
          return (
            <tr
              className="text-center"
              key={obj?.sn}
              onClick={() => {
                navigate(`/${auth.accountType}/benefits_view/${obj?.sn}`, {
                  state: {
                    page: pageInfo,
                    keyword: dateAndKeyword,
                  },
                });
              }}
            >
              {auth.user.isAdmin && (
                <td className="hide" onClick={(e) => e.stopPropagation()}>
                  {/* 체크 박스 - 관리 계정일 때, 활성화 */}
                  <div className="agree">
                    <label>
                      <input
                        type="checkbox"
                        name="personinfo"
                        onChange={(e) => {
                          onChangeCheckBoxHandler(
                            e.currentTarget.checked,
                            obj.sn
                          );
                        }}
                        checked={
                          checkedItemIds?.includes(obj.sn) ? true : false
                        }
                      />
                      {checkedItemIds?.includes(obj.sn) ? (
                        <div className="chkbox border-line mouse checked"></div>
                      ) : (
                        <div className="chkbox border-line mouse "></div>
                      )}
                    </label>
                  </div>
                </td>
              )}
              <td id="_hash" style={{ display: "none" }}></td>

              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {overMaxLength(obj?.title, 30)}
              </td>
              <td
                className="border-line mouse zoom_font hide"
                style={{ cursor: "pointer" }}
              >
                {/* {obj?.id} */}
                관리자
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.createtime?.slice(0, 10)}
              </td>
              <td onClick={(e) => e.stopPropagation()}>
                {obj?.url > 0 && (
                  <button
                    className="t-download border-line mouse zoom_font"
                    onClick={async (e) => {
                      e.stopPropagation();
                      downloadFiles(
                        obj,
                        boardBenefitActions.getBoardBenefitFileUrl,
                        { docsn: obj?.sn },
                        `장예인혜택과정보_`,
                        0
                      );
                    }}
                    type="button"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default BoardBenefit;
