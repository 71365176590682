import Arrow from "../../../img/arrow.svg";
import { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";

import useChatActions from "../../../recoil/api/chatting";
import { CHAT_VIEW } from "./ChattingLayout";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  ChatListAtom,
  RoomSnAtom,
  UserListAtom,
} from "../../../recoil";
import ChatMenu from "./ChatMenu";
import { overMaxLength } from "../../../utils";

export const MemberList = ({ setView, setTargetList, setFlag }) => {
  const auth = useRecoilValue(authAtom);
  const chatActions = useChatActions();
  const userList = useRecoilValue(UserListAtom);
  const [keyword, setKeyword] = useState("");
  const setRoomSn = useSetRecoilState(RoomSnAtom);
  const setChatList = useSetRecoilState(ChatListAtom);
  const [isMemberView, setIsMemberView] = useState(true);

  // Filter the user list based on the search keyword
  const filteredUserList = userList?.filter((user) => {
    return (
      user.name.includes(keyword) ||
      user.id?.includes(keyword) ||
      user.companyname?.includes(keyword)
    );
  });

  // Row component for each user item
  const Row = ({ index, style }) => {
    const member = filteredUserList[index];

    return (
      <div
        className="chat-user-list-select"
        style={style}
        onClick={async () => {
          const res = await chatActions.personalRoomCheck([member]);
          setRoomSn(res.room_sn);

          if (res.code === 200 && res.room_sn > 0) {
            await chatActions.getChatList(res.room_sn);
            await chatActions.readMessage(res.room_sn, true);
          } else if (res.room_sn === 0) {
            setChatList([]);
          }

          setTargetList([member]);
          setView(CHAT_VIEW.CHATTING);
        }}
      >
        <div className="profile user-list"></div>
        <div>
          <p
            title={member?.name?.length > 18 ? member?.name : ""}
            className="user-name"
          >
            {overMaxLength(member.name, 18)}
          </p>
          <p
            title={member?.companyname?.length > 18 ? member?.companyname : ""}
            className="user-name"
          >
            {overMaxLength(member.companyname, 18)}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <ChatMenu setFlag={setFlag} setKeyword={setKeyword} />
      <div className="chat-content">
        <div className="chat-user-select">
          <div className="profile user-select"></div>
          <span className="user-name">
            {auth.user.id} ({auth.user.userName})
          </span>
        </div>
        <div className="chat-user-list">
          <div className="list">
            <p>
              <span>목록</span>
              <img
                src={Arrow}
                alt="화살표"
                style={{
                  cursor: "pointer",
                  transform: `rotate(${isMemberView ? 0 : 180}deg)`,
                }}
                onClick={() => setIsMemberView(!isMemberView)}
              />
            </p>
          </div>
          <div
            className="chat-scroll"
            style={{ height: 210, display: isMemberView ? "block" : "none" }}
          >
            <List
              height={210} // Height of the visible window
              itemCount={filteredUserList.length} // Number of items in the list
              itemSize={70} // Height of each row (in pixels)
              width="100%" // Full width
            >
              {Row}
            </List>
          </div>
        </div>
      </div>
    </>
  );
};
