import { useEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { ElecLabelAtom, ElecPageInfoAtom } from "../../../recoil";

const CompanyCreateSidebar = ({ mode, isCompany }) => {
  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const [pageInfo, setPageInfo] = useRecoilState(ElecPageInfoAtom);
  const [count, setCount] = useState({
    cStamp: 0,
    cText: 0,
    cCheck: 0,
    uStamp: 0,
    uText: 0,
    uCheck: 0,
  });

  const createLabel = (props) => {
    let newLabels = [...labels];
    newLabels.push({
      ...props,
      isFulfilled: false,
      isDragging: false,
      page: pageInfo.current,
      x: 50,
      y: 50,
    });
    setLabels(newLabels);
  };

  const getRemainingLabelByType = (type) => {
    let cnt = 0;
    if (mode === "sign") {
      for (let label of labels) {
        if (
          label.isCompany === isCompany &&
          label.type === type &&
          !label.isFulfilled
        ) {
          cnt++;
        }
      }
      return `(${cnt}개 남음)`;
    }
    return "";
  };

  const deleteLabel = (label) => {
    let newLabels = [...labels];
    newLabels.splice(
      newLabels.findIndex(
        (item) =>
          item.idxCount === label.idxCount &&
          item.type === label.type &&
          item.isCompany === label.isCompany
      ),
      1
    );
    setLabels(newLabels);
  };

  return (
    <div className="left-menu">
      <div style={{ height: "80%", overflowY: "auto", overflowX: "hidden" }}>
        {isCompany && (
          <div className="elec-card elec-card-company">
            <h1>기업</h1>
            <button
              disabled={mode === "sign"}
              className="card-button"
              onClick={() => {
                createLabel({
                  idxCount: count.cStamp,
                  isCompany: true,
                  type: "stamp",
                  img: "",
                });
                setCount((c) => ({ ...c, cStamp: c.cStamp + 1 }));
              }}
            >
              {`사인/도장 ${
                getRemainingLabelByType("stamp") === ""
                  ? `${count.cStamp}개`
                  : getRemainingLabelByType("stamp")
              }`}
            </button>
            {mode !== "sign" && (
              <>
                {labels
                  .filter(
                    (item) => item.type === "stamp" && item.isCompany === true
                  )
                  .map((foo) => (
                    <>
                      <button
                        className="card-button-del"
                        type="button"
                        key={foo.idxCount}
                        onClick={() => {
                          deleteLabel(foo);
                          setCount((c) => ({ ...c, cStamp: c.cStamp - 1 }));
                        }}
                      >
                        {`${foo.idxCount + 1}`} 번 삭제
                      </button>
                    </>
                  ))}
              </>
            )}
            <button
              disabled={mode === "sign"}
              className="card-button"
              onClick={() => {
                createLabel({
                  idxCount: count.cText,
                  isCompany: true,
                  type: "text",
                  text: "",
                  width: 50,
                  height: 20,
                });
                setCount((c) => ({ ...c, cText: c.cText + 1 }));
              }}
            >
              {`텍스트 ${
                getRemainingLabelByType("text") === ""
                  ? `${count.cText}개`
                  : getRemainingLabelByType("text")
              }`}
            </button>
            {mode !== "sign" && (
              <>
                {labels
                  .filter(
                    (item) => item.type === "text" && item.isCompany === true
                  )
                  .map((foo) => (
                    <>
                      <button
                        className="card-button-del"
                        type="button"
                        key={foo.idxCount}
                        onClick={() => {
                          deleteLabel(foo);
                          setCount((c) => ({ ...c, cText: c.cText - 1 }));
                        }}
                      >
                        {`${foo.idxCount + 1}`} 번 삭제
                      </button>
                    </>
                  ))}
              </>
            )}
            <button
              disabled={mode === "sign"}
              className="card-button"
              onClick={() => {
                createLabel({
                  idxCount: count.cCheck,
                  isCompany: true,
                  type: "checkbox",
                });
                setCount((c) => ({ ...c, cCheck: c.cCheck + 1 }));
              }}
            >
              {`체크박스 ${
                getRemainingLabelByType("checkbox") === ""
                  ? `${count.cCheck}개`
                  : getRemainingLabelByType("checkbox")
              }`}
            </button>
            {mode !== "sign" && (
              <>
                {labels
                  .filter(
                    (item) =>
                      item.type === "checkbox" && item.isCompany === true
                  )
                  .map((foo) => (
                    <>
                      <button
                        className="card-button-del"
                        type="button"
                        key={foo.idxCount}
                        onClick={() => {
                          deleteLabel(foo);
                          setCount((c) => ({ ...c, cCheck: c.cCheck - 1 }));
                        }}
                      >
                        {`${foo.idxCount + 1}`} 번 삭제
                      </button>
                    </>
                  ))}
              </>
            )}
          </div>
        )}

        {(mode === "create" || !isCompany) && (
          <div className="elec-card elec-card-user">
            <h1>사용자</h1>
            <button
              disabled={mode === "sign"}
              className="card-button"
              onClick={() => {
                createLabel({
                  idxCount: count.uStamp,
                  isCompany: false,
                  type: "stamp",
                  img: "",
                });
                setCount((c) => ({ ...c, uStamp: c.uStamp + 1 }));
              }}
            >
              {`사인/도장 ${
                getRemainingLabelByType("stamp") === ""
                  ? `${count.uStamp}개`
                  : getRemainingLabelByType("stamp")
              }`}
            </button>
            {mode !== "sign" && (
              <>
                {labels
                  .filter(
                    (item) => item.type === "stamp" && item.isCompany === false
                  )
                  .map((foo) => (
                    <>
                      <button
                        className="card-button-del"
                        type="button"
                        key={foo.idxCount}
                        onClick={() => {
                          deleteLabel(foo);
                          setCount((c) => ({ ...c, uStamp: c.uStamp - 1 }));
                        }}
                      >
                        {`${foo.idxCount + 1}`} 번 삭제
                      </button>
                    </>
                  ))}
              </>
            )}
            <button
              disabled={mode === "sign"}
              className="card-button"
              onClick={() => {
                createLabel({
                  idxCount: count.uText,
                  isCompany: false,
                  type: "text",
                  text: "",
                  width: 50,
                  height: 20,
                });
                setCount((c) => ({ ...c, uText: c.uText + 1 }));
              }}
            >
              {`텍스트 ${
                getRemainingLabelByType("text") === ""
                  ? `${count.uText}개`
                  : getRemainingLabelByType("text")
              }`}
            </button>
            {mode !== "sign" && (
              <>
                {labels
                  .filter(
                    (item) => item.type === "text" && item.isCompany === false
                  )
                  .map((foo) => (
                    <>
                      <button
                        className="card-button-del"
                        type="button"
                        key={foo.idxCount}
                        onClick={() => {
                          deleteLabel(foo);
                          setCount((c) => ({ ...c, uText: c.uText - 1 }));
                        }}
                      >
                        {`${foo.idxCount + 1}`} 번 삭제
                      </button>
                    </>
                  ))}
              </>
            )}
            <button
              disabled={mode === "sign"}
              className="card-button"
              onClick={() => {
                createLabel({
                  idxCount: count.uCheck,
                  isCompany: false,
                  type: "checkbox",
                });
                setCount((c) => ({ ...c, uCheck: c.uCheck + 1 }));
              }}
            >
              {`체크박스 ${
                getRemainingLabelByType("checkbox") === ""
                  ? `${count.uCheck}개`
                  : getRemainingLabelByType("checkbox")
              }`}
            </button>
            {mode !== "sign" && (
              <>
                {labels
                  .filter(
                    (item) =>
                      item.type === "checkbox" && item.isCompany === false
                  )
                  .map((foo) => (
                    <>
                      <button
                        className="card-button-del"
                        type="button"
                        key={foo.idxCount}
                        onClick={() => {
                          deleteLabel(foo);
                          setCount((c) => ({ ...c, uCheck: c.uCheck - 1 }));
                        }}
                      >
                        {`${foo.idxCount + 1}`} 번 삭제
                      </button>
                    </>
                  ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyCreateSidebar;
