import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { authAtom } from "../../recoil";
//TO-DO breadcrumb 눌렀을 때 해당 경로 이동
const TotalTitle = styled.div`
  margin-bottom: 30px;
`;
const Breadcrumb = styled.p`
  color: #757575;
`;
const Title = styled.div`
  font-size: 20px;
  margin-top: 20px;
`;

const BreadCrumb = ({ workReport }) => {
  const auth = useRecoilValue(authAtom);

  const getMenu = (name) => {
    let returnText = undefined;
    auth.menu.map((obj) => {
      if (obj.name === name) {
        returnText = obj.text;
      }
    });
    return returnText;
  };

  const getSubmenu = (name, subname) => {
    let returnText = undefined;
    auth.menu.map((obj) => {
      if (obj.name === name) {
        obj.submenu.map((obj2) => {
          if (obj2.name === subname) {
            returnText = obj2.text;
          }
        });
      }
    });
    return returnText;
  };

  const dict = {
    employee: ["HOME"],
    company: ["HOME"],
    admin: ["HOME"],

    innerdoc: [getMenu("사내문서")],

    elec_insert: [getMenu("전자결재"), "결재신청"],
    elec: [getMenu("전자결재"), getSubmenu("전자결재", "결재함")],
    elecaccept: [getMenu("전자결재"), getSubmenu("전자결재", "기결함")],
    elecdeny: [getMenu("전자결재"), getSubmenu("전자결재", "반려함")],
    eleccomplete: [getMenu("전자결재"), getSubmenu("전자결재", "완료함")],

    hrm: [getMenu("인사지원"), getSubmenu("인사지원", "증명서")],
    hrm_write: [getMenu("인사지원"), "증명서 작성"],
    hrm_edit: [getMenu("인사지원"), "증명서"],
    hrm_detail: [getMenu("인사지원"), "증명서"],
    docform: [getMenu("인사지원"), "문서형식관리"],
    docform_write: [getMenu("인사지원"), "문서형식관리", "문서형식작성"],
    eplydoc: [getMenu("인사지원"), "입사서류 목록"],
    joindoc: [getMenu("인사지원"), getSubmenu("인사지원", "입사서류")],
    eply_doc_list: [getMenu("인사지원"), getSubmenu("인사지원", "입사서류")],
    schedule_docs: [
      getMenu("인사지원"),
      getSubmenu("인사지원", "요청서류목록"),
    ],

    schedule: [getMenu("일정관리"), getSubmenu("일정관리", "캘린더")],
    schedule_list: [getMenu("일정관리"), getSubmenu("일정관리", "리스트")],

    workstamp: [getMenu("근태관리"), getSubmenu("근태관리", "출퇴근기록")],
    vacation_rule: [getMenu("근태관리"), getSubmenu("근태관리", "연차규칙")],
    vacation_history: [getMenu("근태관리"), getSubmenu("근태관리", "연차현황")],
    vacation: [getMenu("근태관리"), getSubmenu("근태관리", "휴가신청")],
    fresh: [getMenu("근태관리"), getSubmenu("근태관리", "휴일관리")],
    working: [getMenu("근태관리"), getSubmenu("근태관리", "근로시간")],
    reason: [getMenu("근태관리"), getSubmenu("근태관리", "근태사유")],
    settings: [getMenu("근태관리"), getSubmenu("근태관리", "직원관리")],

    workorder: [getMenu("업무관리"), getSubmenu("업무관리", "업무지시")],
    workorder_write: [getMenu("업무관리"), "업무지시 작성"],
    workorder_view: [getMenu("업무관리"), getSubmenu("업무관리", "업무지시")],
    workorder_edit: [getMenu("업무관리"), "업무지시 수정"],

    workreport: [getMenu("업무관리"), getSubmenu("업무관리", "업무일지")],
    workreport_view: [
      getMenu("업무관리"),
      getSubmenu("업무관리", "업무일지"),
      "업무일지 상세보기",
    ],
    workreport_write: [
      getMenu("업무관리"),
      getSubmenu("업무관리", "업무일지"),
      "업무일지 작성",
    ],
    workreport_edit: [
      getMenu("업무관리"),
      getSubmenu("업무관리", "업무일지"),
      "업무일지 수정",
    ],

    business_guide: [getMenu("업무관리"), getSubmenu("업무관리", "업무안내")],
    business_guide_write: [
      getMenu("업무관리"),
      getSubmenu("업무관리", "업무안내"),
      "업무안내 작성",
    ],
    business_guide_view: [
      getMenu("업무관리"),
      getSubmenu("업무관리", "업무안내"),
      "업무안내 상세보기",
    ],
    business_guide_edit: [
      getMenu("업무관리"),
      getSubmenu("업무관리", "업무안내"),
      "업무안내 수정",
    ],
    archive: [getMenu("업무관리"), getSubmenu("업무관리", "자료실")],

    payment: [getMenu("급여관리")],
    payment_insert: [getMenu("급여관리"), "급여등록"],
    payment_edit: [getMenu("급여관리"), "급여수정"],

    notice: [getMenu("게시판"), getSubmenu("게시판", "공지사항")],
    notice_write: [getMenu("게시판"), getSubmenu("게시판", "공지사항")],
    notice_view: [getMenu("게시판"), getSubmenu("게시판", "공지사항")],
    notice_edit: [getMenu("게시판"), getSubmenu("게시판", "공지사항")],

    benefits: [getMenu("게시판"), getSubmenu("게시판", "자주 묻는 질문")],
    benefits_view: [getMenu("게시판"), getSubmenu("게시판", "자주 묻는 질문")],
    benefits_write: [getMenu("게시판"), getSubmenu("게시판", "자주 묻는 질문")],
    benefits_edit: [getMenu("게시판"), getSubmenu("게시판", "자주 묻는 질문")],
    management: [getMenu("회원관리")],
    postMonitoring: [
      getMenu("회원관리"),
      getSubmenu("회원관리", "쪽지 모니터링"),
    ],
    chatMonitoring: [
      getMenu("회원관리"),
      getSubmenu("회원관리", "채팅 모니터링"),
    ],
    employeeManage: [
      getMenu("회원관리"),
      getSubmenu("회원관리", "근로자 관리"),
    ],

    commonCode: [getMenu("환경설정"), getSubmenu("환경설정", "공통코드")],
    menuConfig: [getMenu("환경설정"), getSubmenu("환경설정", "메뉴관리")],
    companyRegist: [getMenu("환경설정"), getSubmenu("환경설정", "업체등록")],

    popup: [getMenu("팝업관리")],
    popup_write: [getMenu("팝업관리")],
    popup_edit: [getMenu("팝업관리")],
  };

  const location = useLocation();
  const [url, setUrl] = useState(location.pathname);
  const [paths, setPaths] = useState("");
  const [lastDict, setLastDict] = useState("");

  useEffect(() => {
    setPaths(getPaths(url));
  }, []);

  useEffect(() => {
    const lastDict = findLastDict(paths, paths.length - 1);
    setLastDict(findLastDict(paths, paths.length - 1));
  }, [paths]);

  const getPaths = (url) => {
    const pathArr = url.split("/");
    const paths = pathArr.filter((path) => path.length > 1);

    return paths;
  };

  const findLastDict = (paths, i) => {
    if (i > -1)
      return dict[paths[i]] ? dict[paths[i]] : findLastDict(paths, i - 1);
    return "";
  };

  return (
    <TotalTitle>
      <Breadcrumb>
        {paths &&
          paths.map((path, i) => {
            if (i > 0) {
              return dict[path]?.map((p) => {
                return (
                  <span
                    className="zoom_font border-line"
                    key={`${p}${i}`}
                  >{` > ${p}`}</span>
                );
              });
            } else {
              return (
                <span className="zoom_font border-line" key={`${path}/i`}>
                  {dict[path] ? dict[path] : path}
                </span>
              );
            }
          })}
      </Breadcrumb>
      <Title className="title border-line">
        <p className="zoom_font" style={{ display: "inline-block" }}>
          {workReport}
          {lastDict ? lastDict[lastDict.length - 1] : null}
        </p>
      </Title>
    </TotalTitle>
  );
};

export default BreadCrumb;
