import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SearchByDate from "../common/SearchByDate";
import CommonModal from "../modal/CommonModal";
import PaymentFormRegister from "./PaymentFormRegister";
import DataTable from "../common/DataTable";
import PaymentItem from "./PaymentItem";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../recoil";
import { ACCOUNTTYPE } from "../../JSON/enum/account";
import BreadCrumb from "../common/BreadCrumb";
import { usePaymentActions } from "../../recoil/api/payment";
import { toast as reactToast } from "react-toastify";
import PaginationBar from "../common/PaginationBar";
import { useReactToPrint } from "react-to-print";
import PaymentPrint from "./PaymentPrint";
import { PageInfoState } from "../../recoil/types/data";

const Payment = () => {
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 로딩 상태 값
  const [loading, setLoading] = useState(false);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const toastTest = () => {
    reactToast.promise(handleOnBeforeGetContent, {
      pending: "인쇄 준비 중...",
      error: "실패했습니다.",
    });
  };

  // 모달 상태 값
  const [isCheckModal1Open, setIsCheckModal1Open] = useState(false);
  const [isCheckModal2Open, setIsCheckModal2Open] = useState(false);
  const onChangeModal1State = () => {
    setIsCheckModal1Open(false);
  };
  const onChangeModal2State = () => {
    setIsCheckModal2Open(false);
  };

  // 체크 박스 상태 값
  const [checkedItemIds, setCheckedItemIds] = useState([]);

  // 체크 박스 핸들러
  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  // 날짜 검색 컴포넌트 상태 값
  const [dateAndKeyword, setDateAndKeyword] = useState();

  // 페이지네이션 상태 값
  const [pageInfo, setPageInfo] = useState(PageInfoState);

  // 데이터 상태 값
  const [loadedData, setLoadedData] = useState();

  // 급여 상세 데이터 상태 값
  const [paymentView, setPaymentView] = useState();

  // 인쇄 상태 값
  const componentRef = useRef(null);
  const [printPayment, setPrintPayment] = useState(null);

  // 인쇄 useEffect
  useEffect(() => {
    setLoading(true);
    if (printPayment) {
      handleSubmit();
    }
    setLoading(false);
  }, [printPayment]);

  const handleSubmit = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: toastTest,
  });

  // API 호출 함수 - paymentList 데이터 불러오기
  const paymentActions = usePaymentActions();

  const loadPaymentList = async () => {
    if (dateAndKeyword) {
      setLoading(true);

      paymentActions
        .getPaymentList({
          dateAndKeyword,
          pageInfo,
        })
        .then((res) => {
          setLoading(false);
          if (res?.data) {
            setLoadedData(res.data.list);
            setPageInfo({ ...res.data.pageinfo, current: pageInfo.current });
          }
        });
    }
  };

  // API 요청 함수 - payment 데이터 삭제
  const deletePayments = async () => {
    for (let i = 0; i < checkedItemIds.length; i++) {
      const req = { sn: checkedItemIds[i] };
      const res = await paymentActions.deletePayment(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
      }
    }
    setCheckedItemIds([]);
    loadPaymentList();
  };

  // (모달) 선택한 급여 상세보기 sn 상태 값
  const [sn, setSn] = useState();

  useEffect(() => {
    loadPaymentList();
  }, [pageInfo.current, dateAndKeyword]);

  return (
    <div>
      <div className="substanceCont">
        <BreadCrumb />
        <div className="date-filtering">
          {/* 작성/삭제 버튼 - 기업, 관리자 계정일때 활성화 */}

          <div className="all-group">
            {(auth.user.isCompany || auth.user.isAdmin) && (
              <div>
                <Link to={`/${auth.accountType}/payment_insert`}>
                  <button
                    className="holidaybtn greenbtn border-line mouse zoom_font pencil-img"
                    type="button"
                  >
                    급여등록
                  </button>
                </Link>
                {/* 관리자는 급여양식등록이 안 돼서 하이드처리 */}
                <button
                  style={{ display: auth.user.isAdmin === true ? "none" : "" }}
                  className="holidaybtn bluebtn border-line mouse zoom_font pencil-img"
                  type="button"
                  onClick={() => setIsCheckModal1Open(true)}
                >
                  급여양식등록
                </button>
                <button
                  className="trashbtn border-line mouse zoom_font"
                  type="button"
                  onClick={() => {
                    if (window.confirm("삭제하시겠습니까?")) {
                      deletePayments();
                    } else {
                      return false;
                    }
                  }}
                ></button>
              </div>
            )}
          </div>

          <SearchByDate
            placeholder="이름 입력"
            period={30}
            onChange={(value) => setDateAndKeyword(value)}
            isNeedSearchBar={auth.user.isCompany || auth.user.isAdmin}
            loading={loading}
          />
        </div>

        <DataTable
          loadedData={loadedData}
          loading={loading}
          setCheckedItemIds={setCheckedItemIds}
          checkbox={
            auth.user.accountType === ACCOUNTTYPE.COMPANY ||
            auth.user.accountType === ACCOUNTTYPE.ADMIN
          }
          columns={
            auth.user.isAdmin
              ? [
                  "제목",
                  "급여일",
                  "급여 합계",
                  "공제 합계",
                  "총 지급액",
                  "관리",
                ]
              : ["제목", "급여일", "관리"]
          }
        >
          {loadedData?.map((obj, index) => {
            return (
              <tr
                className="text-center"
                key={obj?.sn}
                onClick={() => {
                  setSn(obj?.sn);
                  setIsCheckModal2Open(true);
                }}
              >
                {/* 체크 박스 - 기업, 관리자 상태에 활성화 */}
                {(auth.user.accountType === ACCOUNTTYPE.COMPANY ||
                  auth.user.accountType === ACCOUNTTYPE.ADMIN) && (
                  <td onClick={(e) => e.stopPropagation()}>
                    <div className="agree">
                      <label>
                        <input
                          type="checkbox"
                          name="personinfo"
                          onChange={(e) => {
                            onChangeCheckBoxHandler(
                              e.currentTarget.checked,
                              obj.sn
                            );
                          }}
                          checked={
                            checkedItemIds.includes(obj.sn) ? true : false
                          }
                        />
                        {checkedItemIds.includes(obj.sn) ? (
                          <div className="chkbox border-line mouse checked"></div>
                        ) : (
                          <div className="chkbox border-line mouse "></div>
                        )}
                      </label>
                    </div>
                  </td>
                )}
                <td
                  className="border-line mouse zoom_font"
                  style={{ cursor: "pointer" }}
                >
                  {`${obj?.employee_name} 근로자의 ${obj?.payday?.substring(
                    0,
                    4
                  )}년 급여명세서`}
                </td>
                <td
                  className="border-line mouse zoom_font"
                  style={{ cursor: "pointer" }}
                >
                  {obj?.payday?.slice(0, 10)}
                </td>
                {auth.user.isAdmin && (
                  <>
                    <td
                      className="border-line mouse zoom_font"
                      style={{ cursor: "pointer" }}
                    >
                      {obj?.total?.toLocaleString("ko-KR")}
                    </td>
                    <td
                      className="border-line mouse zoom_font"
                      style={{ cursor: "pointer" }}
                    >
                      {obj?.total_duty?.toLocaleString("ko-KR")}
                    </td>
                    <td
                      className="border-line mouse zoom_font"
                      style={{ cursor: "pointer" }}
                    >
                      {obj?.result?.toLocaleString("ko-KR")}
                    </td>
                  </>
                )}
                <td onClick={(e) => e.stopPropagation()}>
                  {(auth.user.accountType === ACCOUNTTYPE.COMPANY ||
                    auth.user.accountType === ACCOUNTTYPE.ADMIN) && (
                    <Link to={`/${auth.accountType}/payment_edit/${obj?.sn}`}>
                      <button className="modifybtn border-line mouse zoom_font">
                        수정
                      </button>
                    </Link>
                  )}
                  <button
                    className="outlinebtn border-line mouse zoom_font"
                    onClick={async () => {
                      const res = await paymentActions.getPaymentView({
                        sn: obj?.sn,
                      });
                      if (res.status === 200) {
                        if (res.data.pay.sign_url === null) {
                          alert("등록된 도장이 없습니다.");
                        }
                        setPrintPayment(res.data);
                      }
                    }}
                  >
                    인쇄하기
                  </button>
                </td>
              </tr>
            );
          })}
        </DataTable>
        {/* 인쇄 시 렌더링 */}
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            {printPayment && <PaymentPrint printPayment={printPayment} />}
          </div>
        </div>
        <PaginationBar
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          loading={loading}
        />
      </div>

      {isCheckModal1Open && (
        <CommonModal
          open={isCheckModal1Open}
          onChangeModalState={onChangeModal1State}
          title="급여양식등록"
        >
          <PaymentFormRegister
            closeThisModal={() => {
              setIsCheckModal1Open(false);
            }}
          />
        </CommonModal>
      )}

      {isCheckModal2Open && (
        <CommonModal
          open={isCheckModal2Open}
          onChangeModalState={onChangeModal2State}
          title="급여보기"
        >
          <PaymentItem
            paymentView={paymentView}
            setPaymentView={setPaymentView}
            sn={sn}
          />
        </CommonModal>
      )}
    </div>
  );
};

export default Payment;
