import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { Link, useNavigate } from "react-router-dom";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import BreadCrumb from "../BreadCrumb";
import { usePopupActions } from "../../../recoil/api/popup";
import Draggable from "react-draggable";
import { popupUploadFile } from "../../../recoil/api/index.js";
import axios from "axios";

const PopupWrite = () => {
  const navigate = useNavigate();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // 팝업 상태 값
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPositionX, setDeltaPositionX] = useState(0);
  const [deltaPositionY, setDeltaPositionY] = useState(0);
  const [prevDeltaPositionX, setPrevDeltaPositionX] = useState(0);
  const [prevDeltaPositionY, setPrevDeltaPositionY] = useState(0);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(300);

  // Editor 제어 코드
  const [value, setValue] = useState("<p></p>");
  const [text, setText] = useState("");
  // const imageUploadHandler = async (blobInfo, success, failure, progres) => {
  //   const res = await uploadBlob(blobInfo.blob());
  //   return res[1]?.data + "?alt=media";
  // };

  useEffect(() => {
    setValue(value);
  }, [value]);

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };
  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const handleDrag = (e, ui) => {
    const x = deltaPositionX;
    const y = deltaPositionY;
    setDeltaPositionX(x + ui.deltaX);
    setDeltaPositionY(y + ui.deltaY);
  };

  const [isLocationSettingOrPreview, setIsLocationSettingOrPreview] =
    useState();

  // 체크박스 대상 상태 값
  const [isCheckedEmployee, setIsCheckedEmployee] = useState(false);
  const [isCheckedCompany, setIsCheckedCompany] = useState(false);
  const [isCheckedAdmin, setIsCheckedAdmin] = useState(false);

  const popupActions = usePopupActions();

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      contents: "",
      starttime: dayjs().format("YYYY-MM-DD"),
      endtime: dayjs().format("YYYY-MM-DD"),
    },
  });

  // Submit 함수
  const onSubmit = async (data) => {
    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const req = {
      title: data.title,
      starttime: dayjs(data.starttime).format("YYYYMMDD"),
      endtime: dayjs(data.endtime).format("YYYYMMDD"),
      x: deltaPositionX.toString(),
      y: deltaPositionY.toString(),
      width: width.toString(),
      high: height.toString(),
      target: (
        (isCheckedAdmin ? ACCOUNTTYPE.ADMIN : "").toString() +
        (isCheckedCompany ? ACCOUNTTYPE.COMPANY : "").toString() +
        (isCheckedEmployee ? ACCOUNTTYPE.EMPLOYEE : "").toString()
      ).toString(),
      contents: value,
    };

    if (req.title && req.starttime && req.endtime && req.target) {
      const res = await popupActions.createPopup(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate(`/${auth.accountType}/popup`);
      } else {
        setToast({ ...toast, error: true, message: "작성 실패" });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
    }

    setDoubleclickLoading(false);
  };

  //이미지 업로드 핸들링
  const imageUploadHandler = async (blobInfo, progress) => {
    // var file = new File([blobInfo.blob()], blobInfo.blob().name);
    // file.type = blobInfo.blob().type;

    const res = await popupUploadFile(blobInfo.blob());

    file_check(res[1]?.data + "?alt=media");

    // await temp(res[1]?.data + "?alt=media");
    return res[1]?.data + "?alt=media";
  };

  const file_check = (url) => {
    const file_url = url;
    axios
      .get(url)
      .then(function (response) {})
      .catch(function (error) {
        file_check(file_url);
      });
  };

  // const temp = async (url) => {
  //   let cnt = 0;
  //   while (true) {
  //     if (cnt > 30) return;
  //   }
  // };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="writetable hide">
          <tbody>
            <tr>
              <th className="text-center-title zoom_font">제목</th>
              <td className="w-30">
                <input
                  {...register("title")}
                  className="write-input border-line mouse zoom_font"
                  placeholder="제목 입력"
                />
              </td>

              <th className="text-center-title zoom_font">유지기간</th>
              <td className="w-30">
                <div style={{ display: "flex" }}>
                  <input
                    {...register("starttime")}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                  />
                  <div style={{ margin: 10 }}> -</div>
                  <input
                    {...register("endtime")}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">위치설정</th>
              <td>
                <div style={{ display: "flex" }}>
                  <button
                    className="holidaybtn greenbtn border-line mouse zoom_font"
                    type="button"
                    onClick={() => {
                      setIsLocationSettingOrPreview("LOCATION_SETTING");
                      setIsModalOpened(true);
                    }}
                  >
                    설정
                  </button>
                  <div
                    style={{ margin: 10 }}
                  >{`(${deltaPositionX}, ${deltaPositionY})`}</div>
                </div>
              </td>
              <th className="text-center-title zoom_font">크기</th>
              <td>
                <div style={{ display: "flex" }}>
                  <input
                    className="write-date border-line mouse zoom_font"
                    placeholder="width"
                    type="number"
                    style={{ textAlign: "center" }}
                    onChange={(e) => {
                      setWidth(e.target.value);
                    }}
                    value={width}
                  />
                  <div style={{ margin: 10 }}> -</div>
                  <input
                    className="write-date border-line mouse zoom_font"
                    placeholder="height"
                    type="number"
                    style={{ textAlign: "center" }}
                    onChange={(e) => {
                      setHeight(e.target.value);
                    }}
                    value={height}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">대상</th>
              <td colSpan="5">
                <div style={{ display: "flex" }}>
                  <div style={{ margin: 10, display: "flex" }}>
                    <div
                      id="employee_checkbox"
                      className={`chkbox border-line mouse ${
                        isCheckedEmployee ? "checked" : null
                      }`}
                      onClick={() => {
                        setIsCheckedEmployee(!isCheckedEmployee);
                      }}
                    ></div>
                    <label htmlFor="employee_checkbox">근로자</label>
                  </div>
                  <div style={{ margin: 10, display: "flex" }}>
                    <div
                      id="company_checkbox"
                      className={`chkbox border-line mouse ${
                        isCheckedCompany ? "checked" : null
                      }`}
                      onClick={() => {
                        setIsCheckedCompany(!isCheckedCompany);
                      }}
                    ></div>
                    <label htmlFor="company_checkbox">기업</label>
                  </div>
                  <div style={{ margin: 10, display: "flex" }}>
                    <div
                      id="admin_checkbox"
                      className={`chkbox border-line mouse ${
                        isCheckedAdmin ? "checked" : null
                      }`}
                      onClick={() => {
                        setIsCheckedAdmin(!isCheckedAdmin);
                      }}
                    ></div>
                    <label htmlFor="admin_checkbox">관리자</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">내용</th>
              <td colSpan="5">
                <Editor
                  apiKey={"pwlbxd7c7x9lapnd8nwqidzm2gre8bazisoovnitdbojymrp"}
                  value={value}
                  onInit={(evt, editor) => {
                    setText(editor.getContent({ format: "text" }));
                  }}
                  onEditorChange={(newValue, editor) => {
                    setValue(newValue);
                    setText(editor.getContent({ format: "text" }));
                  }}
                  init={{
                    plugins: [
                      "table",
                      "image",
                      "code",
                      "link",
                      "autolink",
                      "contextmenu",
                    ],
                    language: "ko_KR",
                    selector: "div",
                    relative_urls: false,
                    images_upload_handler: imageUploadHandler,
                    images_upload_credentials: true,
                    elementpath: false,
                    branding: false,
                    autolink: true,
                    contextmenu: "true",
                    menubar: "file insert view format table",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-group">
          <button
            className="writebtn  bluebtn border-line mouse zoom_font"
            type="button"
            onClick={(e) => {
              setIsLocationSettingOrPreview("PREVIEW");
              setIsModalOpened(true);
            }}
          >
            미리보기
          </button>
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>
          <Link to={`/${auth.accountType}/popup`}>
            <button
              className="writebtn cancelbtn border-line mouse zoom_font"
              type="button"
            >
              취소
            </button>
          </Link>
        </div>
      </form>
      <Draggable
        handle=".popup-box"
        onDrag={handleDrag}
        {...dragHandlers}
        position={{ x: deltaPositionX, y: deltaPositionY }}
      >
        <div
          className="popup-box"
          style={{
            display: isModalOpened ? "block" : "none",
            width: `${width}px`,
            height: `${height}px`,
            overflow: "auto",
          }}
        >
          {isLocationSettingOrPreview === "LOCATION_SETTING" && (
            <>
              <div
                style={{
                  margin: 30,
                  display: "center",
                }}
              >
                {`(${deltaPositionX}, ${deltaPositionY})`}
              </div>
              <div className="btn-group">
                <button
                  className="writebtn submitbtn border-line mouse zoom_font"
                  type="button"
                  onClick={() => {
                    setPrevDeltaPositionX(deltaPositionX);
                    setPrevDeltaPositionY(deltaPositionY);
                    setIsModalOpened(false);
                  }}
                >
                  저장
                </button>

                <button
                  className="writebtn cancelbtn border-line mouse zoom_font"
                  type="button"
                  onClick={() => {
                    setDeltaPositionX(prevDeltaPositionX);
                    setDeltaPositionY(prevDeltaPositionY);
                    setIsModalOpened(false);
                  }}
                >
                  취소
                </button>
              </div>
            </>
          )}

          {isLocationSettingOrPreview === "PREVIEW" && (
            <>
              <div
                style={{
                  margin: 30,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  overflow: "auto",
                }}
              >
                <div id="popup-content">
                  <div
                    className="zoom_font_id"
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                </div>
              </div>
              <div id="popup-bottom" style={{ display: "fixed" }}>
                <button>오늘 하루동안 보지 않기</button>
                <button
                  onClick={() => {
                    setIsModalOpened(false);
                  }}
                >
                  X
                </button>
              </div>
            </>
          )}
        </div>
      </Draggable>
    </div>
  );
};

export default PopupWrite;
