import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  ChatDetailRoomSnAtom,
  usePostMailActions,
} from "../../../recoil/api/monitoring";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import ChatModal from "./ChatModal";
import dayjs from "dayjs";
import { isPageChanged } from "../../../utils";

const ChatMonitoring = () => {
  const actions = usePostMailActions();
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [dateAndKeyword, setDateAndKeyword] = useState({
    ...DateState,
    starttime: dayjs().subtract(6, "day").format("YYYYMMDD"),
  });
  const [changeCheck, setChangeCheck] = useState();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatDetailRoomSn, setChatDetailRoomSn] =
    useRecoilState(ChatDetailRoomSnAtom);

  const [chatMonitoringList, setChatMonitoringList] = useState();

  const monitoringData = async (pageInfo, dateAndKeyword) => {
    setLoading(true);
    const res = await actions.getChatMonitoring({ pageInfo, dateAndKeyword });
    setChatMonitoringList(res);
    setPageInfo(res.pageinfo);
    setLoading(false);
  };

  useEffect(() => {
    if (isPageChanged(changeCheck, dateAndKeyword, pageInfo)) {
    } else {
      setChangeCheck({ ...dateAndKeyword, current: pageInfo?.current });
      monitoringData(pageInfo, dateAndKeyword);
    }
  }, [pageInfo?.current, dateAndKeyword]);

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group"></div>
        <div className="all-group">
          <SearchByDate
            onChange={(value) => setDateAndKeyword(value)}
            loading={loading}
          />
        </div>
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={[
          "생성일",
          "참가 인원 수",
          "최근 메세지",
          "보낸 사람",
          "보낸 날짜",
        ]}
      >
        {chatMonitoringList?.list?.map((chat) => {
          return (
            <tr
              className="pointer"
              key={chat.room_sn}
              onClick={() => {
                setIsDetailModalOpen(true);
                setChatDetailRoomSn(chat.room_sn);
              }}
            >
              <td className="border-line mouse zoom_font">{chat.createtime}</td>
              <td className="border-line mouse zoom_font">{chat.people}</td>
              {JSON.parse(chat?.latestcontents)?.content ? (
                <td>
                  <button
                    className="t-download border-line mouse zoom_font"
                    type="button"
                    style={{ cursor: "pointer" }}
                  ></button>
                </td>
              ) : (
                <td className="border-line mouse zoom_font">
                  <div>-</div>
                </td>
              )}
              <td className="border-line mouse zoom_font">
                {chat.latestcontents_name}
              </td>
              <td className="border-line mouse zoom_font">
                {chat.latest_updatetime}
              </td>
            </tr>
          );
        })}
      </DataTable>

      <React.Suspense fallback={<div>loading..</div>}>
        {isDetailModalOpen && (
          <ChatModal open={isDetailModalOpen} setOpen={setIsDetailModalOpen} />
        )}
      </React.Suspense>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default ChatMonitoring;
