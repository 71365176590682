import { useEffect, useState } from "react";
import { PageInfoState } from "../../../recoil/types/data";
import { MAIL_VIEW } from "./MailLayout";
import MailList from "./MailList";
import { useMailActions } from "../../../recoil/api/mail";

const ReservationMail = () => {
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [keyword, setKeyword] = useState("");
  const [getMail, setGetMail] = useState();
  const [loading, setLoading] = useState(true);
  const [effectState, setEffectState] = useState(0);
  const mailActions = useMailActions();

  const getReservedMailList = async () => {
    setLoading(true);

    const res = await mailActions.getReservedMailList({ keyword, pageInfo });
    setGetMail(res);
    setPageInfo(res.pageinfo);

    setLoading(false);
  };

  useEffect(() => {
    getReservedMailList();
  }, [keyword, pageInfo.current, effectState]);

  return (
    <MailList
      setKeyword={setKeyword}
      pageInfo={pageInfo}
      setPageInfo={setPageInfo}
      view={MAIL_VIEW.EDIT_MAIL}
      list={getMail?.list}
      recive={false}
      isReserved={1}
      loading={loading}
      setEffectState={setEffectState}
    />
  );
};

export default ReservationMail;
