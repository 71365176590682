import dayjs from "dayjs";
import { getHolidayType } from "../../../recoil/api/holiday";

const VacationPrint = ({ application, stamp }) => {
  return (
    <div
      className="WordSection1"
      style={{
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 25,
        marginRight: 25,
      }}
    >
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <table
        className="MsoTableGrid"
        border={1}
        cellSpacing={0}
        cellPadding={0}
        align="right"
        style={{
          width: "70%",
          borderCollapse: "collapse",
          border: "none",
          marginLeft: "4.85pt",
          marginBottom: 10,
        }}
      >
        <tbody>
          <tr style={{ height: "7.0pt" }}>
            <td
              width={53}
              rowSpan={2}
              style={{
                width: "39.4pt",
                border: "solid windowtext 1.0pt",
                background: "#AEAAAA",
                padding: 20,
                height: "7.0pt",
              }}
            >
              <p
                className="MsoTitle"
                style={{ textAlign: "center", lineHeight: "normal" }}
              >
                <span
                  lang="EN-US"
                  style={{ fontSize: "10.0pt", color: "black" }}
                >
                  결재
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                border: "solid windowtext 1.0pt",
                borderLeft: "none",
                background: "#AEAAAA",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "7.0pt",
              }}
            >
              <p
                className="MsoTitle"
                style={{ textAlign: "center", lineHeight: "normal" }}
              >
                <span
                  lang="EN-US"
                  style={{ fontSize: "10.0pt", color: "black" }}
                >
                  담당
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                border: "solid windowtext 1.0pt",
                borderLeft: "none",
                background: "#AEAAAA",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "7.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}></span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                border: "solid windowtext 1.0pt",
                borderLeft: "none",
                background: "#AEAAAA",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "7.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  &nbsp;
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                border: "solid windowtext 1.0pt",
                borderLeft: "none",
                background: "#AEAAAA",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "7.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  &nbsp;
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                border: "solid windowtext 1.0pt",
                borderLeft: "none",
                background: "#AEAAAA",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "7.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  &nbsp;
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "9.0pt" }}>
            <td
              width={53}
              style={{
                width: "39.4pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1.0pt",
                borderRight: "solid windowtext 1.0pt",
                padding: 5,
                height: "9.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  {stamp ? <img src={stamp} width={100} height={100} /> : <></>}
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1.0pt",
                borderRight: "solid windowtext 1.0pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "9.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  &nbsp;
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1.0pt",
                borderRight: "solid windowtext 1.0pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "9.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  &nbsp;
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1.0pt",
                borderRight: "solid windowtext 1.0pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "9.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  &nbsp;
                </span>
              </p>
            </td>
            <td
              width={53}
              style={{
                width: "39.4pt",
                borderTop: "none",
                borderLeft: "none",
                borderBottom: "solid windowtext 1.0pt",
                borderRight: "solid windowtext 1.0pt",
                padding: "0cm 5.4pt 0cm 5.4pt",
                height: "9.0pt",
              }}
            >
              <p className="MsoTitle" style={{ lineHeight: "normal" }}>
                <span lang="EN-US" style={{ fontSize: "10.0pt" }}>
                  &nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <br></br>
      <h1
        className="MsoTitle"
        align="left"
        style={{
          textAlign: "left",
          lineHeight: "normal",
          fontSize: 36,
        }}
      >
        <span lang="EN-US">&nbsp;&nbsp;휴가신청서</span>
      </h1>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <div align="center">
        <table
          className="MsoTableGrid"
          border={1}
          cellSpacing={0}
          cellPadding={0}
          style={{ borderCollapse: "collapse", border: "none" }}
        >
          <tbody>
            <tr style={{ height: "42.55pt" }}>
              <td
                width={146}
                style={{
                  width: "109.85pt",
                  border: "solid windowtext 1.0pt",
                  background: "#AEAAAA",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    성명
                  </span>
                </p>
              </td>
              <td
                width={146}
                style={{
                  width: "109.85pt",
                  border: "solid windowtext 1.0pt",
                  borderLeft: "none",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    {application.name}
                  </span>
                </p>
              </td>
              <td
                width={147}
                style={{
                  width: "109.9pt",
                  border: "solid windowtext 1.0pt",
                  borderLeft: "none",
                  background: "#AEAAAA",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    연락처
                  </span>
                </p>
              </td>
              <td
                width={147}
                style={{
                  width: "109.9pt",
                  border: "solid windowtext 1.0pt",
                  borderLeft: "none",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span
                    lang="EN-US"
                    style={{ color: "black" }}
                  >{`${application.tel?.slice(0, 3)}-${application.tel?.slice(
                    3,
                    7
                  )}-${application.tel?.slice(7, 11)}`}</span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "42.55pt" }}>
              <td
                width={146}
                style={{
                  width: "109.85pt",
                  border: "solid windowtext 1.0pt",
                  borderTop: "none",
                  background: "#AEAAAA",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    구분
                  </span>
                </p>
              </td>
              <td
                width={146}
                style={{
                  width: "109.85pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid windowtext 1.0pt",
                  borderRight: "solid windowtext 1.0pt",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    {getHolidayType(application.type)}
                  </span>
                </p>
              </td>
              <td
                width={147}
                style={{
                  width: "109.9pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid windowtext 1.0pt",
                  borderRight: "solid windowtext 1.0pt",
                  background: "#AEAAAA",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    신청기간
                  </span>
                </p>
              </td>
              <td
                width={147}
                style={{
                  width: "109.9pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid windowtext 1.0pt",
                  borderRight: "solid windowtext 1.0pt",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "42.55pt",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    {application.startYMD} ~ {application.endYMD}
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "3.0cm" }}>
              <td
                width={146}
                style={{
                  width: "109.85pt",
                  border: "solid windowtext 1.0pt",
                  borderTop: "none",
                  background: "#AEAAAA",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "3.0cm",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    신청사유
                  </span>
                </p>
              </td>
              <td
                width={440}
                colSpan={3}
                style={{
                  width: "329.65pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid windowtext 1.0pt",
                  borderRight: "solid windowtext 1.0pt",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "3.0cm",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "left",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    {application.reason}
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "3.0cm" }}>
              <td
                width={586}
                colSpan={4}
                style={{
                  width: "439.5pt",
                  border: "solid windowtext 1.0pt",
                  borderTop: "none",
                  padding: "0cm 5.4pt 0cm 5.4pt",
                  height: "3.0cm",
                }}
              >
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    상기 본인은 위와 같은 사유로 휴가를 신청하오니 허락하여
                    주시기를 바랍니다.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  align="center"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    {dayjs(application.applicationDatetime).format(
                      "YYYY년 MM월 DD일"
                    )}
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  align="right"
                  style={{
                    marginBottom: "0cm",
                    textAlign: "right",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-US" style={{ color: "black" }}>
                    신청자 : {application.name}
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0cm", lineHeight: "normal" }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="center"
        style={{
          marginBottom: "0cm",
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        align="right"
        style={{
          marginBottom: "0cm",
          textAlign: "right",
          lineHeight: "normal",
        }}
      >
        <span lang="EN-US">&nbsp;</span>
      </p>
    </div>
  );
};
export default VacationPrint;
