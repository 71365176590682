import { useEffect } from "react";
import { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { toastAtom } from "../../recoil";
import useMainActions, {
  CompanyMyInfoQuery,
  MyInfoUpdate,
} from "../../recoil/api/main";
import { emailRegex, phoneRegex } from "../../utils/regex";
import ChangePasswordModal from "../common/home/ChangePasswordModal";
import ChangePhoneNumberModal from "../common/home/ChangePhoneNumberModal";
import { InputText } from "../form/Input";
import CommonModal from "../modal/CommonModal";
import { authAtom } from "../../recoil";

const CompanyInfoSettings = ({ setIsSettingModalOpen }) => {
  const mainActions = useMainActions();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useRecoilState(toastAtom);
  const companyMyInfo = useRecoilValue(CompanyMyInfoQuery());
  const [stamp, setStamp] = useState();
  const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const myInfoUpdate = useSetRecoilState(MyInfoUpdate);
  const [check, setCheck] = useState(true);
  const [fName, setFName] = useState("");

  //휴대폰 번호 변경 모달
  const [isChangePhoneNumberModal, setChangPhoneNumberModal] = useState(false);

  //휴대폰 모달에서 전화번호 변경 감시
  const [isModalPhoneNumber, setModalPhoneNumber] = useState(companyMyInfo.tel);

  useEffect(() => {
    setValue("tel", String(isModalPhoneNumber));
  }, [isModalPhoneNumber]);

  useEffect(() => {
    reset(companyMyInfo);
  }, [companyMyInfo]);

  const changeStamp = async () => {
    await mainActions.setSign({
      url: stamp,
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (stamp) {
      await changeStamp();
    }
    if (!check) {
      setToast({
        ...toast,
        error: true,
        message: "이미지 파일만 업로드 가능합니다. 다시 선택해주세요",
      });
      setLoading(false);
      return;
    }
    if (!emailRegex.test(data.email)) {
      setToast({
        ...toast,
        error: true,
        message: "이메일 형식이 맞지 않습니다. 이메일을 다시 확인해주세요",
      });
      setLoading(false);
      return;
    }

    let params = {
      email: data.email,
      tel: data.tel,
      paydate: data.paydate,
      company_postnum: data.company_postnum,
      company_address: data.company_address,
      company_address_detail: data.company_address_detail,
      ceo_name: data.ceo_name,
      isdaily_report_chk: data.isdaily_report_chk,
    };

    const res = await mainActions.putCompanyMyInfo(params);

    if (res.status === 200) {
      myInfoUpdate((update) => update + 1);
      setIsSettingModalOpen(false);
      setLoading(false);

      setToast({ ...toast, on: true });
    }
  };

  const imageCheck = (file) => {
    switch (file[0].type) {
      case "image/png":
        return stampCheck(file);
      case "image/gif":
        return stampCheck(file);
      case "image/jpeg":
        return stampCheck(file);
      default:
        return stampCheck(file, false);
    }
  };

  const stampCheck = (file, err) => {
    if (err === false) {
      setToast({
        ...toast,
        error: true,
        message: "이미지 파일만 업로드 가능합니다. 다시 선택해주세요",
      });
      setCheck(false);
    } else {
      setStamp(file);
      setCheck(true);
    }
  };

  const auth = useRecoilValue(authAtom);
  return (
    <div id="comm_layer" className="pop-con">
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="pop-in-pop">
          <colgroup>
            <col width="80" />
            <col width="170" />
            <col width="80" />
            <col width="150" />
          </colgroup>
          <tbody>
            <tr>
              <th className="pop_thd">아이디</th>
              <td className="pop_thd">{companyMyInfo?.company_id}</td>
              <th className="pop_thd">이름(실명)</th>
              <td className="pop_thd">{companyMyInfo?.name}</td>
            </tr>
            <tr>
              <th className="pop_thd">회사명</th>
              <td className="pop_thd">{companyMyInfo?.company_name}</td>
              <th className="pop_thd">직무</th>
              <td className="pop_thd">
                {auth.user.isCompany === true
                  ? "기업회원"
                  : companyMyInfo?.department}
              </td>
            </tr>
            <tr>
              <th className="pop_thd">이메일</th>
              <td className="pop_thd">
                <InputText
                  errors={errors}
                  refs={{
                    ...register("email", {
                      required: true,
                      // pattern: emailRegex,
                    }),
                  }}
                  type="text"
                  className="pop_input"
                />
              </td>
              <th className="pop_thd">휴대폰</th>
              <td className="pop_thd">
                <InputText
                  errors={errors}
                  refs={{
                    ...register("tel", {
                      required: true,
                      pattern: phoneRegex,
                    }),
                  }}
                  type="text"
                  className="pop_input"
                  style={{ width: "70%", display: "inline-block" }}
                  isReadOnly={true}
                />

                <button
                  className="primarybtn-sm"
                  type="button"
                  onClick={() => setChangPhoneNumberModal(true)}
                  style={{ marginLeft: "5px" }}
                >
                  변경
                </button>
              </td>
            </tr>
            <tr>
              <th className="pop_thd">비밀번호</th>
              <td className="pop_thd">
                <button
                  className="primarybtn-sm"
                  type="button"
                  onClick={() => setIsChangePasswordModalOpen(true)}
                >
                  변경
                </button>
              </td>
              <th className="pop_thd">급여일</th>
              <td className="pop_thd">
                <InputText
                  errors={errors}
                  refs={{
                    ...register("paydate", {
                      required: true,
                      max: 31,
                      min: 1,
                      maxLength: 2,
                    }),
                  }}
                  type="number"
                  className="pop_input"
                />
              </td>
            </tr>
            <tr>
              <th className="pop_thd">우편번호</th>
              <td className="pop_thd">
                <InputText
                  style={{
                    width: "70%",
                    display: "inline-block",
                    marginRight: 4,
                  }}
                  errors={errors}
                  refs={{
                    readOnly: true,
                    ...register("company_postnum", {
                      required: true,
                    }),
                  }}
                  type="text"
                  className="pop_input"
                />
                <div
                  className="searchbtn butc pointer"
                  onClick={() => setIsPostcodeOpen(true)}
                >
                  <button type="button" />
                </div>
                {isPostcodeOpen && (
                  <Postcode
                    open={isPostcodeOpen}
                    setOpen={setIsPostcodeOpen}
                    setValue={setValue}
                  />
                )}
              </td>
              <th className="pop_thd">기본주소</th>
              <td className="pop_thd">
                <InputText
                  errors={errors}
                  refs={{
                    readOnly: true,
                    ...register("company_address", {
                      required: true,
                    }),
                  }}
                  type="text"
                  className="pop_input"
                />
              </td>
            </tr>
            <tr>
              <th className="pop_thd">상세주소</th>
              <td className="pop_thd" colSpan={3}>
                <InputText
                  errors={errors}
                  refs={{
                    ...register("company_address_detail", {}),
                  }}
                  type="text"
                  className="pop_input"
                />
              </td>
            </tr>
            <tr>
              <th className="pop_thd">대표자명</th>
              <td className="pop_thd check-w">
                <InputText
                  errors={errors}
                  refs={{
                    ...register("ceo_name"),
                  }}
                  type="text"
                  className="pop_input"
                />
              </td>
              <th className="pop_thd">일일업무일지</th>
              <td className="pop_thd check-w">
                <input
                  {...register("isdaily_report_chk")}
                  type="checkbox"
                  className="w-che pointer"
                />
                {watch("isdaily_report_chk") ? (
                  <span
                    className="on"
                    onClick={() => setValue("isdaily_report_chk", 0)}
                  ></span>
                ) : (
                  <span
                    className="off"
                    onClick={() => setValue("isdaily_report_chk", 1)}
                  ></span>
                )}
              </td>
            </tr>
            <tr>
              <th className="pop_thd">도장 이름</th>
              <td className="pop_thd " colSpan={3}>
                <div className="filetype">
                  <input
                    style={{ display: "none" }}
                    id="bizFile"
                    onChange={(e) => {
                      if (e.target.files[0].size > 2 * 1024 * 1024) {
                        setToast({
                          ...toast,
                          error: true,
                          message:
                            "파일사이즈는 2MB를 초과할 수 없습니다. 다시 선택해주세요",
                        });
                        return;
                      }
                      imageCheck(e.target.files);
                      setFName(e.target.files[0].name);
                    }}
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <label
                    style={{ height: "36px" }}
                    htmlFor="bizFile"
                    className="file-btn"
                  >
                    + 파일선택
                  </label>
                  <span style={{ marginLeft: "10px" }}>
                    {fName !== "" ? fName : companyMyInfo?.sign_name}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th className="pop_thd">도장 이미지</th>
              <td className="pop_thd" colSpan={3}>
                {stamp ? (
                  <img
                    src={URL.createObjectURL(stamp[0])}
                    width="150"
                    alt="도장이미지"
                  />
                ) : (
                  <>
                    {companyMyInfo?.sign_url ? (
                      <img
                        src={companyMyInfo?.sign_url}
                        width="150"
                        alt="도장이미지"
                      />
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="s-button cf">
          <button className="s-save sc" disabled={loading} type="submit">
            저장
          </button>
          <button
            className="s-cans sc"
            onClick={() => {
              setIsSettingModalOpen(false);
            }}
          >
            취소
          </button>
        </div>
      </form>
      <ChangePasswordModal
       user={true}
        open={isChangePasswordModalOpen}
        setOpen={setIsChangePasswordModalOpen}
      />

      <ChangePhoneNumberModal
        open={isChangePhoneNumberModal}
        setOpen={setChangPhoneNumberModal}
        setModalPhoneNumber={setModalPhoneNumber}
      />
    </div>
  );
};

const Postcode = ({ open, setOpen, setValue }) => {
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setValue("company_postnum", data.zonecode);
    setValue("company_address", fullAddress);
    setOpen(false);
  };

  return (
    <CommonModal title="주소 검색" open={open} setOpen={setOpen}>
      <DaumPostcode onComplete={handleComplete} height={700} />
    </CommonModal>
  );
};
export default CompanyInfoSettings;
