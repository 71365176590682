import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../recoil";
import { usePaymentActions } from "../../recoil/api/payment";
import DataTable from "../common/DataTable";
import CommonModal from "../modal/CommonModal";

const PaymentFormRegister = ({ closeThisModal }) => {
  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // 모달 상태 값
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const onChangeModalState = () => {
    setIsCheckModalOpen(false);
  };

  // 급여 양식 데이터 상태 값
  const [paymentFormData, setPaymentFormData] = useState();
  const [selectedPaymentForm, setSelectedPaymentForm] = useState();

  // 직접비, 간접비 상태 값
  const [totalPay, setTotalPay] = useState(0);
  const [totalDuty, setTotalDuty] = useState(0);

  // API 호출 함수 - 급여 양식 데이터 불러오기
  const paymentActions = usePaymentActions();
  const loadPaymentForms = async (data) => {
    const res = await paymentActions.getPaymentForms();

    if (res.status === 200) {
      setPaymentFormData(res?.data?.list);
    }
  };

  // API 호출 함수 - 급여 양식 삭제
  const deletePaymentForm = async (req) => {
    const res = await paymentActions.deletePaymentForm(req);
    if (res.status === 200) {
      setToast({ ...toast, on: true });
    }

    loadPaymentForms();
  };

  useEffect(() => {
    loadPaymentForms();
  }, []);

  // useEffect(() => {
  //   setSelectedPaymentForm();
  // }, [isCheckModalOpen]);

  useEffect(() => {
    setValue("title", selectedPaymentForm?.title);
    // 직접비
    setValue("default_pay", selectedPaymentForm?.default_pay);
    setValue("traffic_pay", selectedPaymentForm?.traffic_pay);
    setValue("work_pay", selectedPaymentForm?.work_pay);
    setValue("cheif_pay", selectedPaymentForm?.cheif_pay);
    setValue("extra_pay", selectedPaymentForm?.extra_pay);
    setValue("vacation_pay", selectedPaymentForm?.vacation_pay);
    setValue("etc_pay", selectedPaymentForm?.etc_pay);
    setTotalPay(selectedPaymentForm?.total);
    // 간접비
    setValue("national_duty", selectedPaymentForm?.national_duty);
    setValue("hire_duty", selectedPaymentForm?.hire_duty);
    setValue("health_duty", selectedPaymentForm?.health_duty);
    setValue("industry_duty", selectedPaymentForm?.industry_duty);
    setValue("source_duty", selectedPaymentForm?.source_duty);
    setValue("income_duty", selectedPaymentForm?.income_duty);
    setValue("etc_duty", selectedPaymentForm?.etc_duty);
    setTotalDuty(selectedPaymentForm?.total_duty);
  }, [selectedPaymentForm]);

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {},
  });

  // Submit 함수
  const onSubmit = async (data) => {
    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const req = {
      sn: selectedPaymentForm?.sn,
      title: data.title,
      default_pay:
        Number(data.default_pay) % 1 === 0 &&
        Number(data.default_pay).toString(),
      traffic_pay:
        Number(data.traffic_pay) % 1 === 0 &&
        Number(data.traffic_pay).toString(),
      work_pay:
        Number(data.work_pay) % 1 === 0 && Number(data.work_pay).toString(),
      cheif_pay:
        Number(data.cheif_pay) % 1 === 0 && Number(data.cheif_pay).toString(),
      extra_pay:
        Number(data.extra_pay) % 1 === 0 && Number(data.extra_pay).toString(),
      vacation_pay:
        Number(data.vacation_pay) % 1 === 0 &&
        Number(data.vacation_pay).toString(),
      etc_pay:
        Number(data.etc_pay) % 1 === 0 && Number(data.etc_pay).toString(),
      national_duty:
        Number(data.national_duty) % 1 === 0 &&
        Number(data.national_duty).toString(),
      hire_duty:
        Number(data.hire_duty) % 1 === 0 && Number(data.hire_duty).toString(),
      health_duty:
        Number(data.health_duty) % 1 === 0 &&
        Number(data.health_duty).toString(),
      industry_duty:
        Number(data.industry_duty) % 1 === 0 &&
        Number(data.industry_duty).toString(),
      source_duty:
        Number(data.source_duty) % 1 === 0 &&
        Number(data.source_duty).toString(),
      income_duty:
        Number(data.income_duty) % 1 === 0 &&
        Number(data.income_duty).toString(),
      etc_duty:
        Number(data.etc_duty) % 1 === 0 && Number(data.etc_duty).toString(),
    };

    if (
      req.title &&
      req.default_pay &&
      req.traffic_pay &&
      req.work_pay &&
      req.cheif_pay &&
      req.extra_pay &&
      req.vacation_pay &&
      req.etc_pay &&
      req.national_duty &&
      req.hire_duty &&
      req.health_duty &&
      req.industry_duty &&
      req.source_duty &&
      req.income_duty &&
      req.etc_duty
    ) {
      const res = await (selectedPaymentForm?.title
        ? paymentActions.updatePaymentForm(req)
        : paymentActions.createPaymentForm(req));
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        closeThisModal();
      } else {
        setToast({ ...toast, error: true, message: "작성 실패" });
      }
    } else {
      if (!req.title) {
        setToast({
          ...toast,
          error: true,
          message: "제목을 입력해주세요.",
        });
      } else {
        setToast({
          ...toast,
          error: true,
          message:
            "입력값의 형식이 올바르지 않습니다. 정수만 입력할 수 있습니다.",
        });
      }
    }

    setDoubleclickLoading(false);
  };

  return (
    <div className="modalscroll">
      <form
        id="save_form"
        className="registerform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <table className="writetable">
          <tbody>
            <tr>
              <td className="text-center-title w-50 zoom_font">
                급여 불러오기
              </td>
              <td className="w-50">
                <div className="select_box type04" id="type04">
                  <div className="box" style={{ width: "100%" }}>
                    <label
                      htmlFor="load-payment-form-button"
                      style={{ textAlign: "left", width: "calc(100% - 55px)" }}
                    >
                      <input
                        className="custom-input border-line mouse zoom_font"
                        type="text"
                        style={{ width: "70%" }}
                        value={selectedPaymentForm?.title}
                        readOnly
                      />
                    </label>
                    <button
                      id="load-payment-form-button"
                      className="holidaybtn greenbtn border-line mouse zoom_font"
                      onClick={() => setIsCheckModalOpen(true)}
                      type="button"
                    >
                      검색
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-center-title">급여 제목</td>
              <td>
                <input
                  {...register("title")}
                  className="custom-input border-line mouse zoom_font"
                  type="text"
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table className="writetable">
          <tbody>
            <tr className="text-center-title greenbtn">
              <td className="w-50 zoom_font" style={{ color: "#FFFFFF" }}>
                급여항목
              </td>
              <td className="w-50 zoom_font" style={{ color: "#FFFFFF" }}>
                금액
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">기본급</td>
              <td>
                <input
                  {...register("default_pay")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_BASE"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">교통비보조</td>
              <td>
                <input
                  {...register("traffic_pay")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_TRAFFIC"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">업무수당</td>
              <td>
                <input
                  {...register("work_pay")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_WORK"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">팀장수당</td>
              <td>
                <input
                  {...register("cheif_pay")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_LEADER"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">연장수당</td>
              <td>
                <input
                  {...register("extra_pay")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_EXTENSION"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">연차수당</td>
              <td>
                <input
                  {...register("vacation_pay")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_VACATION"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">기타수당</td>
              <td>
                <input
                  {...register("etc_pay")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_ETC"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table className="writetable">
          <tbody>
            <tr className="text-center-title">
              <td className="w-50 zoom_font">공제항목</td>
              <td className="w-50 zoom_font">금액</td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">국민연금</td>
              <td>
                <input
                  {...register("national_duty")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_PENS"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">고용보험</td>
              <td>
                <input
                  {...register("hire_duty")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_EMPLOY"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">건강보험</td>
              <td>
                <input
                  {...register("health_duty")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_INSU"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">산재보험</td>
              <td>
                <input
                  {...register("industry_duty")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_SAFETY"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">갑근세</td>
              <td>
                <input
                  {...register("source_duty")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_TAX"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">소득세</td>
              <td>
                <input
                  {...register("income_duty")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_INCOME"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="zoom_font">기타비용</td>
              <td>
                <input
                  {...register("etc_duty")}
                  className="custom-input price border-line mouse zoom_font"
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  id="PAY_ETC1"
                />
              </td>
            </tr>
            <tr className="text-center">
              <td className="greenbtn">
                <button
                  className="border-line mouse zoom_font"
                  style={{ color: "#FFFFFF", width: "100%", height: "100%" }}
                  type="submit"
                >
                  저장
                </button>
              </td>
              <td className="gray">
                <button
                  className="border-line mouse zoom_font"
                  style={{ color: "#FFFFFF", width: "100%", height: "100%" }}
                  type="button"
                  onClick={() => {
                    closeThisModal();
                  }}
                >
                  닫기
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      {/* (모달) - 대상자 검색 창 */}
      <CommonModal
        open={isCheckModalOpen}
        onChangeModalState={onChangeModalState}
      >
        <div className="pri-company">
          <DataTable
            checkbox={false}
            columns={["급여 제목", "등록 일자", "관리"]}
          >
            {paymentFormData?.map((ele) => (
              <tr key={ele.sn} className="text-center">
                <td className="border-line mouse zoom_font">
                  <div>{ele?.title}</div>
                </td>
                <td className="border-line mouse zoom_font">
                  <div> {ele?.createtime}</div>
                </td>

                <td className="complete hide zoom_font">
                  <button
                    className="modifybtn border-line mouse zoom_font"
                    onClick={() => {
                      setSelectedPaymentForm(ele);
                      setIsCheckModalOpen(false);
                    }}
                  >
                    선택
                  </button>
                  <button
                    className="outlinebtn border-line mouse zoom_font"
                    onClick={() => {
                      if (window.confirm("삭제하시겠습니까?")) {
                        deletePaymentForm({ sn: ele?.sn });
                        setIsCheckModalOpen(false);
                      } else {
                        return false;
                      }
                    }}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </DataTable>
        </div>
      </CommonModal>
    </div>
  );
};

export default PaymentFormRegister;
