import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";
import { socket, SocketContext } from "./socket";
import { CookiesProvider } from "react-cookie";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <SocketContext.Provider value={socket}>
      <RecoilRoot>
        <React.Suspense fallback={<div>Loading...</div>}>
          <App />
        </React.Suspense>
      </RecoilRoot>
    </SocketContext.Provider>
  </CookiesProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
