import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import linkButton from "../../../ico/home_link_btn.svg";

const HomeCard = ({ children, title, link = "" }) => {
  const auth = useRecoilValue(authAtom);
  return (
    <div className="check01-1 w-100 check-margin" style={{ height: "100%" }}>
      <div className="worknews main-card" style={{ height: "100%" }}>
        <div className="check-t cf">
          <h2
            className="title-h2 zoom_font"
            style={{ display: "inline-block" }}
          >
            {title}
          </h2>
          <span>
            <Link to={`/${auth.accountType}/${link}`}>
              <button
                className="border-line"
                style={{
                  float: "right",
                }}
                id="vacation"
              >
                <img src={linkButton} alt="바로가기" aria-hidden={true} />
              </button>
            </Link>
          </span>
          {children}
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
