import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import useHrmActions from "../../../recoil/api/hrm";
import VacationPrint from "./VacationPrint";

const VacationPrintButton = ({ application }) => {
  const hrmActions = useHrmActions();
  const [loading, setLoading] = useState(false);
  const [stamp, setStamp] = useState();

  const componentRef = useRef();

  const toastTest = () => {
    toast.promise(handleOnBeforeGetContent, {
      pending: "인쇄 준비 중...",
      error: "실패했습니다.",
    });
  };
  const handleSubmit = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: toastTest,
  });

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const handlePrint = async () => {
    const res = await hrmActions.getCompanyStamp({
      COMP_CD: application.COMP_CD,
    });
    //도장 있을때
    if (res.status === 200 && res.data) {
      setStamp(res.data);
    }
    //도장 없을때
    else if (res.status === 200 && !res.data) {
      handleSubmit();
      return alert("등록된 도장이 없습니다.");
    }
  };

  useEffect(() => {
    if (stamp) {
      handleSubmit();
      setTimeout(() => {
        setStamp(null);
      }, 500);
    }
  }, [stamp]);

  return (
    <>
      <button
        className="secondarybtn-sm border-line zoom_font"
        onClick={() => {
          handlePrint();
        }}
      >
        인쇄
      </button>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <VacationPrint application={application} stamp={stamp} />
        </div>
      </div>
    </>
  );
};

export default VacationPrintButton;
