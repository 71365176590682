import ReactQuill from "react-quill";
import Toolbar from "./Toolbar";
import { useMemo } from "react";
import "react-quill/dist/quill.snow.css";

export const QuillEditor = (props) => {
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: "#toolbar",
      },
    };
  }, []);

  return (
    <>
      <Toolbar images={props?.images} />
      <ReactQuill
        value={props?.value}
        theme="snow"
        modules={modules}
        onChange={(e) => {
          props?.onChange(e);
          if (props?.setEditorTextLength) {
            let content = e;
            content = content
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/&lt;/gi, "1")
              .replace(/&gt;/gi, "1")
              .replace(/&nbsp;/gi, "1")
              .replace(/&quot;/gi, "1")
              .replace(/&amp;/gi, "1");

            props?.setEditorTextLength(content.length);
          }
        }}
      />
    </>
  );
};
