import React, { useEffect, useState } from "react";
import DataTable from "../DataTable";
import DownloadActivate from "../../../ico/docs_down3.svg";
import DownloadDisabled from "../../../ico/docs_down2.svg";
import PaginationBar from "../PaginationBar";
import Select from "react-select";
import useScheduleActions from "../../../recoil/api/schedule";
import { downloadFiles } from "../../../utils";
import { PageInfoState } from "../../../recoil/types/data";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import { toast } from "react-toastify";

const ScheduleCheckFile = ({ docsData, setOpen, getScheduleDocsList }) => {
  const accountType = useAccoutTypeActions();
  const [loadedData, setLoadedData] = useState([]);
  const [docsInfo, setDocsInfo] = useState(docsData);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [docsSelectOption, setDocsSelectOption] = useState(
    accountType.isAdmin() &&
      docsInfo?.req_docs_name.map((data, i) => {
        return { label: data, value: i };
      })
  );
  const [docsPage, setDocsPage] = useState(docsSelectOption[0]);

  const scheduleActions = useScheduleActions();
  const submitOptions = [
    { label: "전체", value: null },
    { label: "제출", value: 1 },
    { label: "미제출", value: 0 },
  ];
  const [target, setTarget] = useState(null);

  const getScheduleDocsEmployeeList = async () => {
    setLoading(true);
    const res = await scheduleActions.getScheduleDocsList({
      docs_sn: accountType.isAdmin()
        ? docsInfo.sn_list[docsPage.value]
        : docsInfo.sn,
      page: pageInfo.current || 1,
      keyword: target,
    });
    if (res.status === 200) {
      setPageInfo({ ...res.data.pageInfo, current: pageInfo.current });
      setLoadedData({ ...res.data });
    }
    setLoading(false);
  };

  useEffect(() => {
    getScheduleDocsEmployeeList();
    setDocsSelectOption(
      accountType.isAdmin() &&
        docsInfo?.req_docs_name.map((data, i) => {
          return { label: data, value: i };
        })
    );
  }, [pageInfo.current, target, docsPage, docsInfo]);

  const adminSubmitAndDelete = () => {
    if (accountType.isAdmin()) {
      const sn_list = docsInfo?.sn_list;
      const docs_list = docsInfo?.req_docs_name;
      sn_list.splice(docsPage.value, 1);
      docs_list.splice(docsPage.value, 1);

      setDocsInfo({
        ...docsInfo,
        sn_list: sn_list,
        req_docs_name: docs_list,
      });
      setDocsPage({
        ...docsSelectOption.filter(
          (data, i) => data.value !== docsPage.value
        )[0],
        value: 0,
      });
    }
  };

  const submit_close = async () => {
    if (window.confirm("제출 마감 하시겠습니까?") === true) {
      const res = await scheduleActions.putScheduleSubmitClose({
        docs_sn: accountType.isAdmin()
          ? docsInfo.sn_list[docsPage.value]
          : docsInfo.sn,
      });
      if (res.status === 200) {
        if (docsInfo?.sn_list?.length === 1) {
          getScheduleDocsList();
          setOpen(false);
        }
        adminSubmitAndDelete();
        accountType.isCompany() &&
          setDocsInfo({ ...docsInfo, is_active: false });
      }
    }
  };

  const deleteScheduleDocs = async (sn) => {
    if (window.confirm("해당 일정을 삭제하시겠습니까?")) {
      setLoading(true);
      const res = await scheduleActions.deleteScheduleDocs(sn);
      if (res.status === 200) {
        if (docsInfo?.sn_list?.length === 1) {
          getScheduleDocsList();
          setOpen(false);
        } else {
          adminSubmitAndDelete();
          getScheduleDocsList();
        }
      }
      setLoading(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <h2
        className="title-h2"
        style={{
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "36px",
        }}
      >
        {accountType.isAdmin() ? (
          <>
            <Select
              className="elec-select"
              styles={{
                control: (provided) => ({
                  ...provided,
                  minWidth: "30rem",
                  width: "100%",
                  fontSize: "1rem",
                }),
                menu: (provided) => ({
                  ...provided,
                  width: "30rem",
                  padding: "0",
                  fontSize: "1rem",
                  fontWeight: "300",
                }),
              }}
              placeholder={docsInfo?.req_docs_name[0]}
              options={docsSelectOption}
              value={docsPage}
              onChange={(e) => {
                setDocsPage(e);
              }}
              isSearchable={false}
            />
          </>
        ) : (
          `${docsInfo?.req_docs_name}`
        )}
      </h2>
      {accountType.isAdmin() && <h3>{docsInfo.company_name}</h3>}
      <div
        className="docs-popup-title"
        style={{
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginTop: "8px", fontSize: "24px", lineHeight: "28px" }}>
          <p>
            {docsInfo?.start_time} ~{" "}
            <span style={{ color: "#BE5252" }}>{docsInfo?.end_time}</span>
            {docsInfo?.is_active || (
              <span style={{ fontSize: "16px" }}>(제출마감)</span>
            )}
          </p>
        </div>

        <div className="docs-popup">
          {/* <button
            className="btn-group btn-group-left select-border-line redbtn-sm"
            style={{
              minWidth: "118px",
            }}
          >
            <p
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              근로자 추가
            </p>
          </button> */}
          {accountType.isAdmin() &&
            loadedData?.list?.find((data) => data.is_submit === 0) && (
              <button
                className="btn-group btn-group-left select-border-line redbtn-sm"
                style={{
                  minWidth: "118px",
                }}
                onClick={() =>
                  deleteScheduleDocs(docsInfo?.sn_list[docsPage.value])
                }
              >
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  일정 삭제
                </p>
              </button>
            )}
          {docsInfo?.is_active && (
            <button
              className="btn-group btn-group-left select-border-line redbtn-sm"
              style={{
                minWidth: "118px",
              }}
              onClick={() => submit_close()}
            >
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                제출 마감 하기
              </p>
            </button>
          )}
          <button
            className="btn-group btn-group-left select-border-line primarybtn-sm"
            style={{
              minWidth: "118px",
            }}
            onClick={() => {
              try {
                const toastId = toast.loading("서류 다운로드 중... ");
                downloadFiles(
                  docsInfo,
                  scheduleActions.getScheduleDocsAttach,
                  accountType.isAdmin()
                    ? { docs_sn: docsInfo?.sn_list[docsPage.value] }
                    : { docs_sn: docsInfo?.sn },
                  accountType.isAdmin()
                    ? `${docsInfo?.req_docs_name[docsPage.value]}`
                    : `${docsInfo?.req_docs_name}`,
                  0
                )
                  .then(() => {
                    toast.update(toastId, {
                      render: "다운로드가 완료되었습니다.",
                      type: "success",
                      isLoading: false,
                      autoClose: 2000,
                    });
                  })
                  .catch(() => {
                    toast.update(toastId, {
                      render: "다운로드에 실패했습니다. 다시 시도해주세요.",
                      type: "error",
                      isLoading: false,
                      autoClose: 2000,
                    });
                  });
              } catch {
                alert(
                  "서버 이전으로 자료를 옮기고 있습니다.\n빠른 시일내로 정상화 하겠습니다."
                );
              }
            }}
          >
            <p
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              파일 일괄 다운
            </p>
          </button>
          <div
            className="btn-group btn-group-left select-border-line"
            style={{ float: "right" }}
          >
            <Select
              options={submitOptions}
              placeholder="제출 여부"
              onChange={(e) => setTarget(e.value)}
              styles={{
                control: (base) => ({
                  ...base,
                  minWidth: "118px",
                }),
                menu: (base) => ({
                  ...base,
                  minWidth: "118px",
                }),
              }}
            />
          </div>
        </div>
      </div>

      <DataTable
        order={true}
        loadedData={loadedData?.list}
        loading={loading}
        checkbox={false}
        columns={[
          "번호",
          "이름",
          "장애유형",
          "연락처",
          // "알림 여부",
          "제출 여부",
          "첨부파일",
        ]}
      >
        {loadedData?.list?.map((data, i) => (
          <tr key={i} className="text-center">
            <td>{i + 1}</td>
            <td>{data?.employee_name}</td>
            <td>{data?.disabled_name}</td>
            <td>{data?.employee_tel}</td>
            {/* <td>
              {data?.is_remind ? (
                <button className="primarybtn-sm border-line zoom_font">
                  발송완료
                </button>
              ) : docsInfo?.is_active ? (
                <button className="redbtn-sm border-line zoom_font">
                  재발송
                </button>
              ) : (
                <button className="redbtn-sm border-line zoom_font">
                  미발송
                </button>
              )}
            </td> */}
            <td style={{ color: data?.is_submit ? "#36B37E" : "#CE3426" }}>
              {data?.is_submit ? "제출" : "미제출"}
            </td>
            <td>
              {data?.file ? (
                <img
                  src={DownloadActivate}
                  alt="다운로드 활성화"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    try {
                      downloadFiles(
                        docsInfo,
                        scheduleActions.getScheduleDocsAttach,
                        { submit_sn: data?.sn },
                        `${docsInfo?.req_docs_name}`
                      );
                    } catch {
                      alert(
                        "서버 이전으로 자료를 옮기고 있습니다.\n빠른 시일내로 정상화 하겠습니다."
                      );
                    }
                  }}
                />
              ) : (
                <img src={DownloadDisabled} alt="다운로드 비활성화" />
              )}
            </td>
          </tr>
        ))}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default ScheduleCheckFile;
