import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { HOLIDAYTYPE } from "../../../JSON/enum/Dataenum";
import { toastAtom } from "../../../recoil";
import { getHolidayType, useHolidayActions } from "../../../recoil/api/holiday";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import VacationPrintButton from "../../common/TNAManagement/VacationPrintButton";
import { errorcase } from "../../errorcase";
import { isReasonOverMaxLength } from "../../company/TNAManagement/Reason";
import CommonModal from "../../modal/CommonModal";
import { VACATIONERROR } from "../../../JSON/enum/servererr";
import { authAtom } from "../../../recoil";
import VacationFunction from "../../company/TNAManagement/VacationFunction";

const EmployeeVacation = () => {
  const holidayActions = useHolidayActions();
  const [holidayType, setHolidayType] = useState(HOLIDAYTYPE.DAYOFF);
  const [annualStatus, setAnnualStatus] = useState({});
  const today = dayjs().format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [date, setDate] = useState({
    ...DateState,
    starttime: dayjs().subtract(30, "day").format("YYYYMMDD"),
    endtime: dayjs().add(30, "day").format("YYYYMMDD"),
  });
  const [toast, setToast] = useRecoilState(toastAtom);
  const [type, setType] = useState("");
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const [auth, setAuth] = useRecoilState(authAtom);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    clearErrors,
  } = useForm({});

  const dateRef = useRef({});
  dateRef.start = dayjs(watch("startYMD"));
  dateRef.end = dayjs(watch("endYMD"));

  useEffect(() => {
    getEmployeeVacation();
  }, []);

  useEffect(() => {
    getVacationApplication({ date, pageInfo, type });
  }, [date, pageInfo?.current, type]);

  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  const deleteVacation = async (items) => {
    if (window.confirm("삭제하시겠습니까?")) {
      const checkedItems = applicationList.filter((item) => {
        return item.state === 0 && items.includes(item.sn);
      });

      for (let item of checkedItems) {
        const res = await holidayActions.deleteVacation({ sn: item.sn });
      }

      setCheckedItemIds([]);
      getEmployeeVacation();
      getVacationApplication({ date, pageInfo, type });
    }
  };

  const onSubmit = async (data) => {
    if (data.reason.trim() === "") {
      setToast({
        ...toast,
        error: true,
        message: "사유를 입력 해주세요.",
      });
      return;
    }
    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const req = {
      ...data,
      type: holidayType,
    };
    const res = await holidayActions.createEmployeeVacation(req);
    if (res.status === 200) {
      setToast({ ...toast, on: true, message: "연차 신청에 성공했습니다." });
      getVacationApplication({ date, pageInfo });
      getEmployeeVacation();
      reset();
    } else if (res.status === 400) {
      if (res.data.error_code === 1) {
        setToast({
          ...toast,
          error: true,
          message: VACATIONERROR[1],
        });
      } else if (res.data.error_code === 2) {
        setToast({
          ...toast,
          error: true,
          message: VACATIONERROR[2],
        });
      } else if (res.data.error_code === 3) {
        setToast({ ...toast, error: true, message: VACATIONERROR[3] });
      } else if (res.data.error_code === 4) {
        setToast({
          ...toast,
          error: true,
          message: VACATIONERROR[4],
        });
      } else {
        setToast({
          ...toast,
          error: true,
          message: VACATIONERROR[5],
        });
      }
    } else {
      setToast({ ...toast, error: true, message: errorcase(res.data) });
    }
    setDoubleclickLoading(false);
  };

  const getEmployeeVacation = async () => {
    const res = await holidayActions.getEmployeeVacation();
    if (res.data) {
      setAnnualStatus(res.data);
    }
  };

  const getVacationApplication = async (data) => {
    setLoading(true);
    setApplicationList([]);

    const res = await holidayActions.getVacationApplication(data);
    if (res.status === 200) {
      setApplicationList(res.data.list);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
    return res;
  };

  // reason 모달 상태 값
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const [reasonDetail, setReasonDetail] = useState("");

  const vacationFn = VacationFunction();

  return (
    <div>
      <BreadCrumb />
      <div id="user-substance">
        <form className="vacation-menu" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="zoom_font">연차 휴가 현황</h2>
          <div id="currentbox">
            <div className="current zoom_font">
              <span className="zoom_font">총 부여일</span>
              <span className="user-time zoom_font">
                <span className="actday" id="v1">
                  {Math.round(
                    (annualStatus[0]?.total_day_off || 0) *
                      (annualStatus[0]?.day_standard || 1) *
                      10
                  ) / 10}
                </span>
                일
              </span>
            </div>
            <div className="current zoom_font">
              <span className="zoom_font">사용일</span>
              <span className="user-time zoom_font">
                <span className="actday" id="v2">
                  {Math.round(
                    (annualStatus[0]?.used_day_off || 0) *
                      (annualStatus[0]?.day_standard || 1) *
                      10
                  ) / 10}
                </span>
                일
              </span>
            </div>
            <div className="current zoom_font">
              <span className="zoom_font">잔여일</span>
              <span className="user-time zoom_font">
                <span className="actday" id="v3">
                  {Math.round(
                    (annualStatus[0]?.remain_day_off || 0) *
                      (annualStatus[0]?.day_standard || 1) *
                      10
                  ) / 10}
                </span>
                일
              </span>
            </div>
          </div>
          <div className="of-auto">
            <h3 className="zoom_font">연차 휴가 현황</h3>
            <div id="choicebox">
              {vacationFn.annualOptionsEmployee()?.map((item) => {
                return (
                  <button
                    type="button"
                    key={item.value}
                    className={`choice border-line mouse zoom_font ${
                      holidayType === item.value ? "actchoice" : ""
                    }`}
                    onClick={() => setHolidayType(item.value)}
                  >
                    {item.label}
                  </button>
                );
              })}
            </div>
          </div>
          <div>
            <h3 className="zoom_font">사유</h3>
            {/* textarea border-line 작동안함 */}
            <textarea
              {...register("reason")}
              className="border-line zoom_font"
              id="reason"
            ></textarea>
          </div>
          <div>
            <h3 className="zoom_font">시작 날짜 - 종료 날짜</h3>
            <input
              name="startYMD"
              type="date"
              className="custom-input input-w border-line"
              defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
              {...register("startYMD", {
                onChange: (e) => clearErrors(),
                required: true,
              })}
            />
            <span className="wave">-</span>
            <input
              type="date"
              className="custom-input input-w border-line"
              defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
              {...register("endYMD", {
                onChange: (e) => clearErrors(),
                required: true,
              })}
            />
            {dateRef.start.diff(dateRef.end) > 0 && (
              <div className="register-warning">
                시작 날짜가 끝 날짜보다 빠릅니다.
              </div>
            )}
          </div>
          <div className="btn-group">
            <button
              className="vacationbtn greenbtn border-line mouse zoom_font"
              type="submit"
            >
              신청하기
            </button>
            <button
              className="vacationbtn gray border-line mouse zoom_font"
              type="button"
              onClick={() => {
                reset();
              }}
            >
              초기화
            </button>
          </div>
          {errors.server && (
            <div className="register-warning">{errors.server.message}</div>
          )}
        </form>
        <div style={{ flex: 1 }}>
          <div className="date-filtering">
            <div className="all-group">
              <button
                className="trashbtn border-line mouse"
                type="button"
                onClick={() => {
                  deleteVacation(checkedItemIds);
                }}
              ></button>
            </div>
            <SearchByDate
              period={30}
              defaultEndtime={dayjs().add(30, "day")}
              placeholder="이름 입력"
              dateAndKeyword={date}
              onChange={(value) => setDate(value)}
              loading={loading}
            />
          </div>
          <DataTable
            setCheckedItemIds={setCheckedItemIds}
            loadedData={applicationList}
            loading={loading}
            columns={[
              "구분",
              "신청기간",
              "신청일",
              "신청자",
              "사유",
              "신청일수",
              "잔여일",
              "현재상태",
              "출력",
            ]}
          >
            {applicationList?.map((application) => {
              return (
                <tr key={application.sn}>
                  <td className="border-line zoom_font">
                    {application.state === 0 && application.isCancel !== 1 && (
                      <div className="agree">
                        <label>
                          <input
                            type="checkbox"
                            name="personinfo"
                            onChange={(e) => {
                              onChangeCheckBoxHandler(
                                e.currentTarget.checked,
                                application.sn
                              );
                            }}
                            checked={
                              checkedItemIds.includes(application.sn)
                                ? true
                                : false
                            }
                          />
                          {checkedItemIds.includes(application.sn) ? (
                            <div className="chkbox border-line mouse checked"></div>
                          ) : (
                            <div className="chkbox border-line mouse "></div>
                          )}
                        </label>
                      </div>
                    )}
                  </td>

                  <td className="border-line zoom_font">
                    {getHolidayType(application.type)}
                  </td>
                  <td className="border-line zoom_font">{`${application.startYMD} ~ ${application.endYMD}`}</td>
                  <td className="border-line zoom_font">
                    {application.applicationDatetime?.slice(0, 10)}
                  </td>
                  <td className="border-line zoom_font">{application.name}</td>
                  <td className="border-line zoom_font">
                    {isReasonOverMaxLength(application.reason.length) ? (
                      <div
                        className="pointer"
                        onClick={() => {
                          setReasonDetail(application.reason);
                          setIsReasonModalOpen(true);
                        }}
                      >{`${application.reason?.substring(0, 15)}...`}</div>
                    ) : (
                      application.reason
                    )}
                  </td>
                  <td className="border-line zoom_font">
                    {Math.round(
                      application.days * application.day_standard * 10
                    ) / 10}
                  </td>
                  <td className="border-line zoom_font">
                    {Math.round(
                      application.remain * application.day_standard * 10
                    ) / 10}
                  </td>
                  <td>
                    {application.state === 0 && application.isCancel === 1 && (
                      <div className="btn-group">
                        <button
                          className="border-line zoom_font"
                          disabled
                          style={{ color: "#222222", fontFamily: "s-core5" }}
                        >
                          취소
                        </button>
                      </div>
                    )}
                    {application.state === 0 && application.isCancel === 0 && (
                      <div className="btn-group">
                        <button
                          className="border-line zoom_font"
                          disabled
                          style={{
                            fontFamily: "s-core5",
                          }}
                        >
                          미승인
                        </button>
                      </div>
                    )}
                    {application.state === 1 && application.isCancel === 0 && (
                      <div className="btn-group">
                        <button
                          className="border-line zoom_font"
                          disabled
                          style={{
                            color: "#1a5c96",
                            fontFamily: "s-core5",
                          }}
                        >
                          승인
                        </button>
                      </div>
                    )}
                    {application.state === 2 && application.isCancel === 0 && (
                      <div className="btn-group">
                        <button
                          className="border-line zoom_font"
                          disabled
                          style={{ color: "#e85d6c", fontFamily: "s-core5" }}
                        >
                          반려
                        </button>
                      </div>
                    )}
                  </td>
                  <td>
                    {application.state === 1 && (
                      <VacationPrintButton application={application} />
                    )}
                  </td>
                </tr>
              );
            })}
          </DataTable>
          <CommonModal
            title="상세 사유"
            open={isReasonModalOpen}
            setOpen={setIsReasonModalOpen}
          >
            {reasonDetail}
          </CommonModal>
          <PaginationBar
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeVacation;
