import { useRecoilValue } from "recoil";
import { get, del, post, getPage, put } from ".";
import { authAtom } from "..";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const usePaymentActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    searchEmployeeByCompanyId,
    searchCompany,
    getPaymentList,
    getPaymentForms,
    getPaymentView,

    createPayment,
    createPaymentForm,
    updatePayment,
    updatePaymentForm,
    deletePayment,
    deletePaymentForm,
  };

  async function searchEmployeeByCompanyId(req) {
    const res = get("search/company/employee", req).then((res) => {
      return res;
    });
    return res;
  }

  async function searchCompany(req) {
    const res = await get("search/company/name", {
      ...req,
      count: 9999,
    });
    return res;
  }

  async function getPaymentList(data) {
    const req =
      auth.user.accountType === ACCOUNTTYPE.EMPLOYEE
        ? {
            ...data,
            id: auth.user.id,
          }
        : auth.user.accountType === ACCOUNTTYPE.COMPANY
        ? {
            ...data,
            companyId: auth.user.id,
          }
        : auth.user.accountType === ACCOUNTTYPE.ADMIN && {
            ...data,
          };

    return getPage(`${auth.accountType}/payment`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getPaymentForms() {
    return get(`${auth.accountType}/payment/form`, {
      page: 1,
      count: 100,
      COMP_CD: auth.user.isCompany ? auth.user.id : null,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getPaymentView(data) {
    return get(`${auth.accountType}/payment/payied`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //   async function getWorkGuideFileUrl(data) {
  //     const req = {
  //       ...data,
  //     };
  //     return await get(`select/workguide/attachlink`, req)
  //       .then((res) => {
  //         return res;
  //       })
  //       .catch((err) => {
  //         throw err;
  //       });
  //   }

  async function createPayment(data, url = `${auth.accountType}/payment/pay`) {
    const req =
      auth.user.accountType === ACCOUNTTYPE.ADMIN
        ? data
        : auth.user.accountType === ACCOUNTTYPE.COMPANY && {
            ...data,
            companyId: auth.user.id,
          };
    const res = await post(url, req);
    return res;
  }

  async function updatePayment(req, url = `${auth.accountType}/payment/pay`) {
    const res = await put(url, req);
    return res;
  }

  async function createPaymentForm(
    data,
    url = `${auth.accountType}/payment/form`
  ) {
    const req =
      auth.user.accountType === ACCOUNTTYPE.ADMIN
        ? { ...data, COMP_CD: 1000 }
        : auth.user.accountType === ACCOUNTTYPE.COMPANY && {
            ...data,
            companyId: auth.user.id,
          };
    const res = await post(url, req);
    return res;
  }

  async function updatePaymentForm(
    req,
    url = `${auth.accountType}/payment/form`
  ) {
    const res = await put(url, req);
    return res;
  }

  async function deletePaymentForm(req) {
    return await del(`${auth.accountType}/payment/form`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deletePayment(req) {
    return await del(`${auth.accountType}/payment/pay`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //   async function deleteAllWorkGuideFiles(data) {
  //     const res = await getWorkGuideFileUrl(data);
  //     const fileUrlData = res.data;
  //     for (let i = 0; i < fileUrlData.length; i++) {
  //       const req = { sn: fileUrlData[i].sn };
  //       await deleteWorkGuideFile(req);
  //     }
  //   }

  //   async function deleteWorkGuideFile(req) {
  //     await del(`delete/workguideattach`, req)
  //       .then((res) => {
  //       })
  //       .catch((err) => {
  //         throw err;
  //       });
  //   }
};
