import { useRecoilValue } from "recoil";
import { get, del, post, getPage, createWithUploadFile, put } from ".";
import { authAtom } from "..";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const useApprovalActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    editApproval,
    deleteApproval,
    processingApproval,
    postEmployeeApproval,
    getApprovalForm,
    getApprovalList,
    createApproval,
    getApprovalDoc,
  };

  async function editApproval(
    data,
    url = `${auth.accountType}/approval`,
    check = "doc"
  ) {
    // 파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(
        url,
        { ...data },
        check,
        "put",
        true
      );
      return res;
    } else {
      // 파일이 없는 경우
      const req = {
        ...data,
        url: data.prevFiles.filter((ele) => ele),
        prevFiles: null,
      };
      const res = await put(url, req);
      return res;
    }
  }

  async function deleteApproval(data) {
    const res = await del(`${auth.accountType}/approval`, data);
    return res;
  }

  async function processingApproval(data) {
    const res = await put(`${auth.accountType}/approval`, data);
    return res;
  }

  async function postEmployeeApproval(
    data,
    url = "employee/approval/regist",
    check = "doc"
  ) {
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(
        url,
        auth.user.isEmployee && {
          ...data,
          id: auth.user.id,
        },
        check
      );
      return res;
    } else {
      // 파일이 없는 경우
      const req = auth.user.isEmployee && {
        ...data,
        id: auth.user.id,
      };
      const res = await post(url, req);
      return res;
    }
  }

  async function getApprovalDoc(data) {
    const res = await get(`${auth.accountType}/approval/doc`, data);
    return res;
  }

  async function getApprovalForm(data) {
    const res = await get("select/docform", data);
    return res;
  }

  async function getApprovalList(data) {
    const req =
      auth.user.accountType === ACCOUNTTYPE.EMPLOYEE
        ? {
            ...data,
            id: auth.user.id,
          }
        : auth.user.accountType === ACCOUNTTYPE.COMPANY
        ? {
            ...data,
            companyId: auth.user.id,
          }
        : auth.user.accountType === ACCOUNTTYPE.ADMIN && {
            ...data,
          };

    return getPage(`${auth.accountType}/approval`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function createApproval(
    data,
    url = `${auth.accountType}/approval/regist`
  ) {
    const req = auth.user.accountType === ACCOUNTTYPE.EMPLOYEE && {
      ...data,
      id: auth.user.id,
    };
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(url, req);
      return res;
    } else {
      // 파일이 없는 경우
      const res = await post(url, req);
      return res;
    }
  }
};
