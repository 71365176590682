import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { post, get, getPage, del, put } from ".";
import { authAtom, toastAtom } from "..";
import { errorcase } from "../../component/errorcase";
import { WORKSTAMP } from "../../JSON/enum/Dataenum";
import {
  DreamCafeModalAtom,
  ReasonAtom,
  ReasonModalAtom,
  ReasonModalTextAtom,
  WorkstampTypeAtom,
} from "../atoms/workstamp";
import { useAccoutTypeActions } from "./auth";
import { WorkstampTimeAtom } from "../atoms/workstamp";
import dayjs from "dayjs";

//TO-DO 등록, 조회 시 미래는 막기
export const useWorkstampActions = () => {
  const auth = useRecoilValue(authAtom);
  const accountType = useAccoutTypeActions();

  return {
    changeWorktime,
    getWorktime,
    deleteWorkstamp,
    adminChangeWorkstamp,
    adminCreateWorkstamp,
    getEmployeeWorkstampByType,
    getEmployeeWorktimeByYM,
    getWorkstampList,
    getNotWorkstampList,
    getWorkstamp,
    createWorkstamp,
    getEmployeeWorkInfoById,
    getEmployeeWorkstampCheckById,
    getReasonByCompanyId,
    putReasonChange,
    delReasonChange,
  };
  async function changeWorktime(data) {
    const res = await put("admin/worktime", data);
    return res;
  }
  async function getWorktime(data) {
    const res = await getPage("admin/worktime", data);
    return res;
  }

  async function deleteWorkstamp(data) {
    const res = await del("admin/workstamp", data);
    return res;
  }

  async function adminChangeWorkstamp(data) {
    const res = await put(`${auth.accountType}/workstamp`, data);
    return res;
  }

  async function adminCreateWorkstamp(data) {
    const res = await post("admin/workstamp", data);
    return res;
  }
  async function getEmployeeWorkstampByType(data) {
    const res = await get("admin/workstamp/check", data);
    return res;
  }

  async function getEmployeeWorktimeByYM(data) {
    const res = await get("admin/workstamp/employee/worktime", data);
    return res;
  }

  async function getWorkstampList(data) {
    let params = {
      ...data,
    };
    if (accountType.isCompany()) params.id = auth.user.id;
    const res = await getPage(`${auth.accountType}/workstamp`, params);
    return res;
  }

  async function getNotWorkstampList(data) {
    let params = {
      ...data,
    };
    if (accountType.isCompany()) params.id = auth.user.id;
    const res = await getPage(`${auth.accountType}/workstamp/absence`, params);
    return res;
  }

  async function createWorkstamp(data) {
    return post("employee/workstamp", { ...data, employee_id: auth.user.id })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getEmployeeWorkstampCheckById(id) {
    return get("employee/workstamp/check", id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getEmployeeWorkInfoById(id) {
    return get("employee/workInfo", id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkstamp(data) {
    return get("employee/workstamp", {
      ...data,
      id: auth.user.id,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getReasonByCompanyId(data) {
    let params = {
      ...data,
    };
    if (accountType.isCompany()) params.id = auth.user.id;
    return getPage(`${auth.accountType}/reason`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function putReasonChange(data) {
    let params = {
      ...data,
    };
    return put(`${auth.accountType}/reason`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function delReasonChange(data) {
    let params = {
      ...data,
    };
    return del(`${auth.accountType}/reason`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
};

export const useCreateWorkstamp = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const [loading, setLoading] = useState(false);
  const workstampActions = useWorkstampActions();
  const [workstampType, setWorkstampType] = useRecoilState(WorkstampTypeAtom);
  const [reason, setReason] = useRecoilState(ReasonAtom);
  const [reasonModalText, setReasonModalText] =
    useRecoilState(ReasonModalTextAtom);
  const [isReasonModalOpen, setIsReasonModalOpen] =
    useRecoilState(ReasonModalAtom);
  const [workstampTime, setWorkstampTime] = useRecoilState(WorkstampTimeAtom);
  const [isDreamCafeModalOpen, setIsDreamCafeModalOpen] =
    useRecoilState(DreamCafeModalAtom);

  const auth = useRecoilValue(authAtom);

  return { createWorkstamp, createReasonWorkstamp };

  async function createReasonWorkstamp() {
    // 7과 13인데 왜 여기로 오는지 모르겠다.
    if (
      workstampType === WORKSTAMP.not ||
      workstampType === WORKSTAMP.dailyReportRequired
    ) {
      alert(
        "알수없는 에러.\n재로그인 후 출퇴근이 되지 않으면 관리자에게 쪽지를 남겨주세요."
      );
      window.location.reload();
      return;
    }

    //조기 출근, 지각, 초과근무 일 때 사유 입력
    const res = await workstampActions.createWorkstamp({
      type: workstampType,
      reason: reason,
      workStampInsertTime: workstampTime,
    });
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      if (workstampType === WORKSTAMP.lateworkout) {
        setIsDreamCafeModalOpen(true);
      }
    } else {
      setToast({ ...toast, error: true, message: errorcase(res.data) });
    }
    await workstampActions
      .getEmployeeWorkstampCheckById({
        employee_id: auth.user.id,
      })
      .then((res) => {
        setWorkstampType(parseInt(res.data.workstamp));
      });
    setIsReasonModalOpen(false);
    setReason("");
  }

  async function createWorkstamp() {
    setLoading(true);
    await workstampActions.getEmployeeWorkstampCheckById({
      employee_id: auth.user.id,
    });

    const pastWorkstampType = workstampType;
    const presentWorkstampType =
      await workstampActions.getEmployeeWorkstampCheckById({
        employee_id: auth.user.id,
      });

    if (pastWorkstampType !== presentWorkstampType?.data?.workstamp) {
      window.location.reload();
      return;
    }

    if (
      workstampType === WORKSTAMP.workin ||
      workstampType === WORKSTAMP.workout
    ) {
      const res = await workstampActions.createWorkstamp({
        type: workstampType,
        workStampInsertTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      });
      if (res.status === 200) {
        //정상 출근, 정상 퇴근 되었을 때
        setToast({ ...toast, on: true });
        if (workstampType === WORKSTAMP.workout) {
          setIsDreamCafeModalOpen(true);
        }
      } else {
        //정상 출근, 정상 퇴근 실패시
        setToast({ ...toast, error: true, message: errorcase(res.data) });
      }
      //조기출근
    } else if (workstampType === WORKSTAMP.earlyworkin) {
      setReasonModalText("조기출근 사유");
      setIsReasonModalOpen(true);
      return;
    } else if (workstampType === WORKSTAMP.lateworkin) {
      setReasonModalText("지각 사유");
      setIsReasonModalOpen(true);
      return;
    } else if (workstampType === WORKSTAMP.lateworkout) {
      setReasonModalText("초과근무 사유");
      setIsReasonModalOpen(true);
      return;
    } else if (workstampType === WORKSTAMP.earlyworkout) {
      setReasonModalText("조기퇴근 사유");
      setIsReasonModalOpen(true);
      return;
    } else if (workstampType === WORKSTAMP.notLeavingWork) {
      setReasonModalText("미퇴근 사유");
      setIsReasonModalOpen(true);
    } else if (workstampType === WORKSTAMP.dailyReportRequired) {
      setToast({
        ...toast,
        error: true,
        message: "업무일지를 작성해주세요.",
      });
    } else {
      setToast({ ...toast, error: true, message: "에러가 발생했습니다." });
    }
    await workstampActions.getEmployeeWorkInfoById();
    await workstampActions
      .getEmployeeWorkstampCheckById({
        employee_id: auth.user.id,
      })
      .then((res) => {
        setWorkstampType(parseInt(res.data.workstamp));
      });
    setLoading(false);
  }
};
