import { useEffect, useState } from "react";
import { useWorkOrderActions } from "../../../recoil/api/workOrder";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { authAtom } from "../../../recoil";
import { useRecoilValue } from "recoil";
import { useBoardNoticeActions } from "../../../recoil/api/boardNotice";
import { NOTICETYPE } from "../../../JSON/enum/Dataenum";
import BreadCrumb from "../BreadCrumb";
import dayjs from "dayjs";
import { downloadFile } from "../../../utils";
import DOMPurify from "dompurify";

const BoardNoticeView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const param = useParams();
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  const boardNoticeActions = useBoardNoticeActions();

  // boardNoticeView 데이터 상태 값
  const [BoardNoticeData, setBoardNoticeData] = useState();

  // 파일 데이터 상태 값
  const [fileUrlData, setFileUrlData] = useState();

  // API 호출 함수 - boardNotice 상세 데이터 불러오기
  const loadBoardNoticeView = async (docsn) => {
    const res = await boardNoticeActions.getBoardNoticeView(docsn);
    if (res.status === 200) {
      setBoardNoticeData((prevState) => res.data);
      if (res.data.url.length >= 1) {
        const res2 = await boardNoticeActions.getBoardNoticeFileUrl(docsn);
        setFileUrlData(res2.data);
      }
    }
    //파일 불러오기 미완성
  };

  // useEffect
  useEffect(() => {
    loadBoardNoticeView({ docsn: param.sn });
  }, []);

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form id="save_form" method="get" className="workform">
        <div className="table-responsive">
          <table className="writetable">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font">제목</th>
                <td className="zoom_font">{BoardNoticeData?.title}</td>
                <th className="text-center-title zoom_font">구분</th>
                <td className="zoom_font">
                  {BoardNoticeData?.type === NOTICETYPE.OFFICIAL && "공문"}
                  {BoardNoticeData?.type === NOTICETYPE.DELEGATE && "위임장"}
                </td>
              </tr>
              <tr>
                <th className="text-center-title w-15 zoom_font">작성자</th>
                <td className="zoom_font">{BoardNoticeData?.writer}</td>
                <th className="text-center-title w-15 zoom_font">작성일</th>
                <td className="zoom_font">
                  {dayjs(BoardNoticeData?.createtime).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td
                  colSpan="3"
                  className="content-td-height zoom_font_id ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(BoardNoticeData?.contents),
                  }}
                ></td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">파일첨부</th>
                <td colSpan="3">
                  {/* props 변경을 감지해야 리랜더링 됨 */}
                  {fileUrlData?.map((value, index) => (
                    <div key={index}>
                      <a
                        onClick={() => downloadFile(value)}
                        style={{ cursor: "pointer" }}
                      >
                        {`${value.filename}.${value.extension}`}
                      </a>
                      <br />
                    </div>
                  ))}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <div className="writebtnbox">
        {auth.user.isCompany && (
          // || auth.user.isAdmin
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="button"
            onClick={() =>
              navigate(
                `/${auth.accountType}/notice_edit/${BoardNoticeData?.notice_sn}`
              )
            }
          >
            수정
          </button>
        )}
        <button
          onClick={() =>
            navigate(`/${auth.accountType}/notice`, {
              state: { page: state?.page, keyword: state?.keyword, prev: true },
            })
          }
          className="writebtn cancelbtn border-line mouse zoom_font"
          type="button"
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default BoardNoticeView;
