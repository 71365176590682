import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import useManagementActions from "../../../recoil/api/management";
import { phoneRegex } from "../../../utils/regex";
import ChangePasswordModal from "../../common/home/ChangePasswordModal";
import { InputText } from "../../form/Input";
import CommonModal from "../../modal/CommonModal";

const CompanyDetailModal = ({ open, setOpen, companyDetail, loading }) => {
  const managementActions = useManagementActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const [isPwModal, setIsPwModal] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...companyDetail,
    },
  });
  const onSubmit = async (data) => {
    let params = {
      ...data,
      memo: !!data.memo ? data.memo : " ",
      department: !!data.department ? data.department : " ",
    };
    if (Number(data.paydate) > 31) {
      return setToast({
        ...toast,
        error: true,
        message: "급여일은 31일을 초과 할 수 없습니다.",
      });
    }
    const res = await managementActions.changeCompanyDetail(params);
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      setOpen(false);
    }
  };

  return (
    <CommonModal title="회원 정보 상세보기" open={open} setOpen={setOpen}>
      <div id="comm_layer" className="pop-con">
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="pop-in-pop">
            <colgroup>
              <col width="100" />
              <col width="170" />
              <col width="100" />
              <col width="150" />
            </colgroup>
            <tbody>
              <tr>
                <th className="pop_thd">아이디</th>
                <td className="pop_thd">{companyDetail?.company_id}</td>
                <th className="pop_thd">이름(실명)</th>
                <td className="pop_thd">{companyDetail?.name}</td>
              </tr>
              <tr>
                <th className="pop_thd">회사명</th>
                <td className="pop_thd">{companyDetail?.company_name}</td>
                <th className="pop_thd">직무</th>
                <td className="pop_thd">
                  <input
                    {...register("department")}
                    type="text"
                    className="pop_input"
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">이메일</th>
                <td className="pop_thd">
                  <InputText
                    errors={errors}
                    refs={{
                      ...register("email", {
                        required: true,
                        pattern: "",
                      }),
                    }}
                  />
                </td>
                <th className="pop_thd">휴대폰</th>
                <td className="pop_thd">
                  <InputText
                    errors={errors}
                    refs={{
                      ...register("tel", {
                        required: true,
                        pattern: phoneRegex,
                      }),
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">비밀번호</th>
                <td className="pop_thd">
                  <button
                    type="button"
                    className="primarybtn-sm"
                    onClick={() => setIsPwModal(true)}
                  >
                    변경
                  </button>
                </td>
                <th className="pop_thd">급여일</th>
                <td className="pop_thd">
                  <InputText
                    errors={errors}
                    refs={{
                      ...register("paydate"),
                    }}
                    type="number"
                    max={31}
                    maxLength={2}
                    className="pop_input"
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">비고</th>
                <td className="pop_thd" colSpan={3}>
                  <InputText
                    errors={errors}
                    refs={{
                      ...register("memo"),
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="s-button cf">
            <button className="s-save sc" type="submit">
              저장
            </button>
            <button
              className="s-cans sc"
              type="button"
              onClick={() => setOpen(false)}
            >
              취소
            </button>
          </div>
        </form>
      </div>
      <ChangePasswordModal
        userId={companyDetail?.company_id}
        user={false}
        open={isPwModal}
        setOpen={setIsPwModal}
      />
    </CommonModal>
  );
};

export default CompanyDetailModal;
