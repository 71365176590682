import React, { useState, useEffect } from "react";
import SearchByDate from "../SearchByDate";
import DataTable from "../DataTable";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  ApprovalDetailModalAtom,
  ApprovalSnAtom,
  authAtom,
} from "../../../recoil";
import PaginationBar from "../PaginationBar";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import { APPROVALDOCTYPE } from "../../../JSON/enum/Dataenum";
import { Link, useNavigate } from "react-router-dom";
import { useApprovalActions } from "../../../recoil/api/approval";
import BreadCrumb from "../BreadCrumb";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import ApprovalDetailModal from "./ApprovalDetailModal";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import { overMaxLength } from "../../../utils";
import ApprovalFunction from "./ApprovalFunction";

const Approval = ({ state }) => {
  const navigate = useNavigate();
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 로딩 상태 값
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(true);

  // 페이지네이션 상태 값
  const [pageInfo, setPageInfo] = useState(PageInfoState);

  // 날짜 검색 상태 값
  const [dateAndKeyword, setDateAndKeyword] = useState(DateState);

  // 체크 박스 상태 값
  const [checkedItemIds, setCheckedItemIds] = useState([]);

  const setApprovalDetailModal = useSetRecoilState(ApprovalDetailModalAtom);
  const setApprovalSn = useSetRecoilState(ApprovalSnAtom);
  const [approvalType, setApprovalType] = useState(null);
  const [approvalDetailType, setApprovalDetailType] = useState(null);

  const ApprovalFn = ApprovalFunction();

  const getDocTypeByApprovalType = (approvalType) => {
    if (approvalType === 0) return [{ label: "종류 선택", value: 0 }];
    switch (approvalType) {
      case APPROVALDOCTYPE.WORKATTITUDE:
        return ApprovalFn.workAttitudeOptions();
      case APPROVALDOCTYPE.WORK:
        return ApprovalFn.workOptions();
      case APPROVALDOCTYPE.PAYMENT:
        return ApprovalFn.paymentOptions();
      default:
        return null;
    }
  };

  // 체크 박스 핸들러
  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  // API 요청 함수 - approvalList 데이터 불러오기
  const approvalActions = useApprovalActions();

  const loadApprovalList = async (type) => {
    setLoading(true);
    const req = {
      dateAndKeyword,
      pageInfo,
      state,
      type: type === 0 ? null : type,
    };

    const res = await approvalActions.getApprovalList(req);
    if (res.status === 200) {
      setLoadedData(res.data);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadApprovalList(approvalType);
  }, [approvalType]);

  useEffect(() => {
    if (approvalDetailType) {
      loadApprovalList(approvalDetailType);
    }
  }, [approvalDetailType]);

  useEffect(() => {
    loadApprovalList(approvalDetailType || approvalType);
    setCheckedItemIds([]);
  }, [pageInfo?.current, dateAndKeyword, state]);

  return (
    <div className="substanceCont">
      <BreadCrumb />
      {/* 업무보고 작성 버튼 - 근로자 계정에서 활성화 */}
      <div id="reportCard-4">
        <button
          className="write-card border-line mouse"
          onClick={() => navigate(`/${auth.accountType}/eleccomplete`)}
        >
          <p className="card-title zoom_font">결재 완료</p>
          <p className="card-cont">
            <span className="zoom_font">{loadedData.done}</span>
          </p>
        </button>
        <button
          className="write-card border-line mouse"
          id="week_write"
          onClick={() => navigate(`/${auth.accountType}/elecaccept`)}
        >
          <p className="card-title zoom_font">기결문서</p>
          <p className="card-cont">
            <span className="zoom_font">{loadedData.aprove}</span>
          </p>
        </button>
        <button
          className="write-card border-line mouse"
          id="month_write"
          onClick={() => navigate(`/${auth.accountType}/elecdeny`)}
        >
          <p className="card-title zoom_font">반려문서</p>
          <p className="card-cont">
            <span className="zoom_font">{loadedData.reject}</span>
          </p>
        </button>
        <button
          className="write-card border-line mouse"
          id="month_write"
          onClick={() => navigate(`/${auth.accountType}/elec`)}
        >
          <p className="card-title zoom_font">결재전</p>
          <p className="card-cont">
            <span className="zoom_font">{loadedData.none}</span>
          </p>
        </button>
      </div>

      <div className="date-filtering">
        {/* 글 생성/삭제 버튼 - 결재함, 관리자 계정일 때, 활성화 */}
        <div className="all-group">
          <div className="btn-group btn-group-left">
            {state === 0 || auth.user.accountType === ACCOUNTTYPE.ADMIN ? (
              <>
                {auth.user.accountType === ACCOUNTTYPE.EMPLOYEE && (
                  <Link
                    to={{
                      pathname: `/${auth.accountType}/elec_insert`,
                    }}
                  >
                    <button
                      className="primarybtn-md border-line mouse zoom_font"
                      type="button"
                      id="workorder_write"
                      onClick={() => {
                        setApprovalSn(0);
                      }}
                    >
                      작성하기
                    </button>
                  </Link>
                )}
                <button
                  className="trashbtn border-line mouse"
                  type="button"
                  onClick={async () => {
                    if (window.confirm("삭제 하시겠습니까?")) {
                      for (let i of checkedItemIds) {
                        await approvalActions.deleteApproval({
                          sn: i,
                        });
                      }
                      setCheckedItemIds([]);
                      loadApprovalList();
                    } else {
                      return;
                    }
                  }}
                ></button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="app-group">
          <SearchByDate
            placeholder="제목 입력"
            dateAndKeyword={dateAndKeyword}
            onChange={(value) => setDateAndKeyword(value)}
            select={
              <div
                className="btn-group btn-group-left m-mt-12"
                style={{ margin: 0 }}
              >
                <div className="select-border-line">
                  <Select
                    className="elec-select"
                    placeholder="구분 선택"
                    options={ApprovalFn.docDivisionOptions()}
                    onChange={(e) => {
                      setApprovalType(e.value);
                      setApprovalDetailType(null);
                    }}
                  />
                </div>
                <div className="select-border-line">
                  <Select
                    isDisabled={!approvalType}
                    className="elec-select"
                    placeholder="종류 선택"
                    options={getDocTypeByApprovalType(approvalType)}
                    value={getDocTypeByApprovalType(approvalType)?.filter(
                      (type) => {
                        return approvalDetailType === type.value;
                      }
                    )}
                    onChange={(e) => {
                      setApprovalDetailType(e.value);
                    }}
                  />
                </div>
              </div>
            }
            loading={loading}
          />
        </div>
      </div>
      <DataTable
        loadedData={loadedData?.list}
        loading={loading}
        setCheckedItemIds={setCheckedItemIds}
        checkbox={state === 0 || auth.user.accountType === ACCOUNTTYPE.ADMIN}
        columns={["구분", "문서종류", "제목", "작성자", "신청일"]}
      >
        {loadedData?.list?.map((obj, idx) => {
          return (
            <tr
              className="text-center"
              key={obj?.sn}
              onClick={() => {
                if (auth.user.isEmployee && state === 0) {
                  navigate(`/${auth.accountType}/elec_insert/${obj.sn}`);
                  setApprovalSn(obj?.sn);
                } else {
                  setApprovalDetailModal(true);
                  setApprovalSn(obj?.sn);
                }
              }}
            >
              {/* 체크 박스 - 결재함, 관리자 계정일 때, 활성화 */}
              {state === 0 || auth.user.accountType === ACCOUNTTYPE.ADMIN ? (
                <td onClick={(e) => e.stopPropagation()}>
                  <div className="agree">
                    <label>
                      <input
                        type="checkbox"
                        name="personinfo"
                        onChange={(e) => {
                          onChangeCheckBoxHandler(
                            e.currentTarget.checked,
                            obj.sn
                          );
                        }}
                        checked={
                          checkedItemIds?.includes(obj.sn) ? true : false
                        }
                      />
                      {checkedItemIds?.includes(obj.sn) ? (
                        <div className="chkbox border-line mouse checked"></div>
                      ) : (
                        <div className="chkbox border-line mouse "></div>
                      )}
                    </label>
                  </div>
                </td>
              ) : (
                ""
              )}
              <td>
                <div className="border-line mouse zoom_font pointer">
                  {obj?.type
                    .toString()
                    .startsWith(APPROVALDOCTYPE.WORKATTITUDE) && "근태"}
                  {obj?.type.toString().startsWith(APPROVALDOCTYPE.WORK) &&
                    "업무"}
                  {obj?.type.toString().startsWith(APPROVALDOCTYPE.PAYMENT) &&
                    "지출/구매"}
                </div>
              </td>
              <td style={{ cursor: "pointer" }}>
                <div className="border-line mouse zoom_font">
                  {obj?.type == APPROVALDOCTYPE.RESIGN && "사직서"}
                  {obj?.type == APPROVALDOCTYPE.PROBLEM && "시말서"}
                  {obj?.type == APPROVALDOCTYPE.COOPERATE && "업무협조전"}
                  {obj?.type == APPROVALDOCTYPE.SPECIALDAY && "경조사비신청서"}
                  {obj?.type == APPROVALDOCTYPE.PREBUYPAYMENT && "지출품의서"}
                  {obj?.type == APPROVALDOCTYPE.BUYPAYMENT && "지출내역서"}
                  {obj?.type == APPROVALDOCTYPE.OFFICIAL && "공문"}
                  {obj?.type == APPROVALDOCTYPE.DELEGATE && "위임장"}
                </div>
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {overMaxLength(obj?.title, 30)}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.writer}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.createtime}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <p style={{ marginTop: 10 }}>{`게시글 수 : ${pageInfo?.totalCnt}건`}</p>
      <React.Suspense fallback={<ClipLoader />}>
        <ApprovalDetailModal loadApprovalList={loadApprovalList} />
      </React.Suspense>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default Approval;
