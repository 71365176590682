import { useRecoilValue } from "recoil";
import { get, del, post, getPage, createWithUploadFile } from ".";
import { authAtom } from "..";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const useBoardBenefitActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    getBoardBenefitList,
    getBoardBenefitView,
    getBoardBenefitFileUrl,
    createBoardBenefit,
    updateBoardBenefit,
    deleteBoardBenefit,
    deleteBoardBenefitFile,
  };

  async function getBoardBenefitList(data) {
    return getPage(`select/benefitsList`, {
      ...data,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getBoardBenefitView(data) {
    return get(`select/benefits`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getBoardBenefitFileUrl(data) {
    return await get(`select/benefits/attach`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function createBoardBenefit(
    data,
    url = `${auth.accountType}/benefits/write`
  ) {
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(
        url,
        {
          ...data,
          id: auth.user.id,
        },
        undefined,
        "post",
        true
      );

      return res;
    } else {
      // 파일이 없는 경우
      const req = {
        ...data,
        id: auth.user.id,
      };
      const res = await post(url, req);
      return res;
    }
  }

  async function updateBoardBenefit(data, url = `admin/benefits/update`) {
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(url, {
        ...data,
        id: auth.user.id,
      });

      return res;
    } else {
      // 파일이 없는 경우
      const req = {
        ...data,
        url: data.prevFiles.filter((ele) => ele),
        prevFiles: null,
        id: auth.user.id,
      };
      const res = await post(url, req);
      return res;
    }
  }

  async function deleteBoardBenefit(data) {
    await deleteAllBoardBenefitFiles(data);
    return await del(`delete/benefits`, { docsn: data.docsn })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deleteAllBoardBenefitFiles(data) {
    const res = await getBoardBenefitFileUrl(data);
    const fileUrlData = res.data;
    console.log("file : ", fileUrlData);
    for (let i = 0; i < fileUrlData.length; i++) {
      const req = { sn: fileUrlData[i].sn };
    }
  }

  async function deleteBoardBenefitFile(req) {
    await del(`delete/benefitsattach`, req)
      .then((res) => {
        console.log("file delete : ", res);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
};
