import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Xgray from "../../../img/x-gray.svg";
import {
  ChatViewAtom,
  RoomListAtom,
  RoomSnAtom,
  UserListAtom,
} from "../../../recoil";
import useChatActions from "../../../recoil/api/chatting";
import { CHAT_VIEW } from "./ChattingLayout";
import { overMaxLength } from "../../../utils";

const InviteChat = ({ setTargetList, flag }) => {
  const userListRecoil = useRecoilValue(UserListAtom);
  const [userList, setUserList] = useState(userListRecoil);
  const [roomSn, setRoomSn] = useRecoilState(RoomSnAtom);
  const chatActions = useChatActions();
  const setView = useSetRecoilState(ChatViewAtom);
  const roomList = useRecoilValue(RoomListAtom);
  const [keyword, setKeyword] = useState("");

  const selectUser = (selectedUser) => {
    const newUserList = userList.map((user) => {
      if (
        (selectedUser.id && user.id === selectedUser.id) ||
        (selectedUser.id && selectedUser.id === user.id) ||
        (selectUser.id &&
          selectedUser.id === user.id &&
          selectedUser.name === user.name)
      ) {
        return { ...user, selected: user.selected ? false : true };
      } else {
        return user;
      }
    });
    setUserList(newUserList);
  };

  const onSubmit = async () => {
    const targetList = userList.filter((user) => {
      return user.selected ? true : false;
    });

    //새로운 대화 생성일 때
    if (targetList.length > 0) {
      const res = await chatActions.personalRoomCheck(targetList);
      setRoomSn(res.room_sn);
      if (res.code === 200 && res.room_sn > 0) {
        await chatActions.getChatList(res.room_sn);
        await chatActions.readMessage(res.room_sn, true);
        setTargetList(targetList);
        setView(CHAT_VIEW.CHATTING);
      } else if (!roomSn) {
        const createRoomRes = await chatActions.createRoom(targetList);
        await chatActions.getChatList(createRoomRes.room_sn);
        setTargetList(targetList);
        setRoomSn(createRoomRes.room_sn);
        setView(CHAT_VIEW.CHATTING);
        await chatActions.enterLobby();
      } else {
        const res = await chatActions.inviteUser(roomSn, targetList);
        if (res.code === 200) {
          setRoomSn(res.room_sn);
          setTargetList((prev) => {
            return [
              ...prev,
              ...res.targetList.map((item) => {
                return { id: item.id, name: item.name };
              }),
            ];
          });
        }
        setView(CHAT_VIEW.CHATTING);
        await chatActions.enterLobby();
      }
    } else {
      return alert("선택된 회원이 없습니다.");
    }
  };

  return (
    <>
      <div className="choice-user">
        <div style={{ width: "90%" }}>
          {userList.map((user, i) => {
            return (
              <React.Fragment key={i}>
                {user.selected && (
                  <div
                    className="select-user"
                    style={{ width: "calc(100% / 6)" }}
                  >
                    <div
                      className="profile user-list"
                      style={{ marginLeft: 10, position: "relative" }}
                    >
                      <button
                        className="profile-delete"
                        onClick={() => selectUser(user)}
                      ></button>
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        width: "70px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {user?.name}
                    </p>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div>
          <span className="text-green">
            {userList.filter((user) => (user.selected ? true : false)).length}
          </span>
          <span>선택</span>
        </div>
      </div>
      <div className="chat-sec-list">
        <div style={{ position: "relative" }}>
          <input
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="chat-search"
            type="text"
            placeholder="이름 또는 아이디를 입력하세요."
          />
          <button
            className="search-x"
            onClick={() => {
              setKeyword("");
            }}
          >
            <img src={Xgray} alt="닫기" />
          </button>
        </div>
      </div>
      <div className="chat-content-c">
        <div className="chat-user-list">
          <div className="chat-scroll" style={{ height: 331 }}>
            {roomSn === null ? (
              <>
                {userList
                  ?.filter((user) => {
                    return user.name.includes(keyword) ||
                      user.id?.includes(keyword)
                      ? true
                      : false;
                  })
                  ?.map((user, i) => {
                    return (
                      <div
                        className="chat-user-list-select flex-between"
                        key={i}
                        onClick={() => selectUser(user)}
                      >
                        <div className="flex-start">
                          <div className="profile user-list"></div>
                          <div>
                            <p
                              title={user?.name?.length > 18 ? user?.name : ""}
                              className="user-name"
                            >
                              {overMaxLength(user.name, 18)}
                            </p>
                            <p
                              title={
                                user?.companyname?.length > 18
                                  ? user?.companyname
                                  : ""
                              }
                              className="user-name"
                            >
                              {overMaxLength(user.companyname, 18)}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className={
                              user.selected ? "invite-chk-on" : "invite-chk-off"
                            }
                          ></div>
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                {userList
                  ?.filter((user) => {
                    return user.name.includes(keyword) ||
                      user.id?.includes(keyword)
                      ? true
                      : false;
                  })
                  ?.filter((user) => {
                    if (
                      roomList
                        .filter((item) => item.room_sn === roomSn)[0]
                        ?.userList.map((e) => e.name)
                        .indexOf(user.name) === -1 ||
                      roomList
                        .filter((item) => item.room_sn === roomSn)[0]
                        ?.userList.map((e) => e.id)
                        .indexOf(user.id) === -1
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  ?.map((user, i) => {
                    return (
                      <div
                        className="chat-user-list-select flex-between"
                        key={i}
                        onClick={() => selectUser(user)}
                      >
                        <div className="flex-start">
                          <div className="profile user-list"></div>
                          <div>
                            <p
                              title={user?.name?.length > 18 ? user?.name : ""}
                              className="user-name"
                            >
                              {overMaxLength(user.name, 18)}
                            </p>
                            <p
                              title={
                                user?.companyname?.length > 18
                                  ? user?.companyname
                                  : ""
                              }
                              className="user-name"
                            >
                              {overMaxLength(user.companyname, 18)}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className={
                              user.selected ? "invite-chk-on" : "invite-chk-off"
                            }
                          ></div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="chat-bottom">
        <button
          onClick={() => {
            onSubmit();
          }}
        >
          {!flag ? "대화시작하기" : "초대하기"}
        </button>
      </div>
    </>
  );
};

export default InviteChat;
