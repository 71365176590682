import { useRecoilValue } from "recoil";
import { del, get, getPage, post, put, uploadfiledocs } from ".";
import { authAtom } from "..";
import { useAccoutTypeActions } from "./auth";

export const useScheduleActions = () => {
  const auth = useRecoilValue(authAtom);
  const accountType = useAccoutTypeActions();

  return {
    delSchedule,
    postSchedule,
    putSchedule,
    getCalendar,
    getScheduleByDate,
    getScheduleDocsTypeList,
    getScheduleDocsList,
    getScheduleDocsAttach,
    getScheduleTypeAttachFile,
    postScheduleDosc,
    postScheduleDocsSubmit,
    putScheduleDocsSubmit,
    postScheduleDocsType,
    putScheduleDocsType,
    putScheduleSubmitClose,
    deleteScheduleDocs,
  };

  async function delSchedule(data) {
    const res = await del("delete/schedule", data);
    return res;
  }

  async function postSchedule(data) {
    let params = {
      ...data,
    };

    if (accountType.isCompany()) params.id = auth.user.id;
    if (accountType.isEmployee()) params.id = auth.user.id;

    const res = await post(`${auth.accountType}/schedule/regist`, params);
    return res;
  }

  async function putSchedule(data) {
    let params = {
      ...data,
    };

    if (accountType.isCompany()) params.id = auth.user.id;
    if (accountType.isEmployee()) params.id = auth.user.id;

    const res = await put(`${auth.accountType}/schedule/regist`, params);
    return res;
  }

  async function getScheduleByDate(data) {
    let params = {
      ...data,
    };

    if (accountType.isCompany()) params.id = auth.user.id;
    if (accountType.isEmployee()) params.id = auth.user.id;

    const res = await getPage(`${auth.accountType}/schedule/list`, params);
    return res;
  }

  async function getCalendar(data) {
    let params = {
      ...data,
    };

    if (accountType.isCompany()) params.id = auth.user.id;
    if (accountType.isEmployee()) params.id = auth.user.id;

    const res = await get(`${auth.accountType}/schedule/calendar`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function getScheduleDocsList(data) {
    let parmas = { ...data };
    const res = await get(`${auth.accountType}/schedule/docs`, parmas)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function postScheduleDosc(data) {
    let params = {
      ...data,
    };
    const res = await post(`${auth.accountType}/schedule/docs`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function getScheduleDocsTypeList() {
    const res = await get(`${auth.accountType}/schedule/typelist`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function getScheduleDocsAttach(data) {
    let params = { ...data };
    const res = await get(`${auth.accountType}/schedule/docs/attach`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function postScheduleDocsType(data) {
    let params = {
      ...data,
    };
    if (params?.file.length !== 0) {
      const [file, fileRes] = await uploadfiledocs(
        { file: params.file },
        "type"
      );
      if (file && fileRes) {
        const fileDot = file.name.lastIndexOf(".");
        const fileName = file.name.substring(0, fileDot).toLowerCase();
        const fileType = file.name
          .substring(fileDot + 1, file.name.length)
          .toLowerCase();
        params = {
          ...params,
          filename: fileName,
          extension: fileType,
          url: fileRes.data,
        };
      }
    }
    const res = await post(`${auth.accountType}/schedule/type`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function postScheduleDocsSubmit(data) {
    let params = { ...data };

    const [file, fileRes] = await uploadfiledocs(
      { file: params.file },
      `submit/${params.sn}`
    );
    if (file && fileRes) {
      const fileDot = file.name.lastIndexOf(".");
      const fileName = file.name.substring(0, fileDot).toLowerCase();
      const fileType = file.name
        .substring(fileDot + 1, file.name.length)
        .toLowerCase();
      params = {
        sn: data.sn,
        schedule_name: data.schedule_name,
        company_name: data.company_name,
        filename: fileName,
        extension: fileType,
        url: fileRes.data,
      };
    }
    const res = await post(`${auth.accountType}/schedule/docs`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function putScheduleDocsSubmit(data) {
    let params = {
      ...data,
    };
    const [file, fileRes] = await uploadfiledocs(
      { file: params.file },
      `submit/${params.sn}`
    );
    if (file && fileRes) {
      const fileDot = file.name.lastIndexOf(".");
      const fileName = file.name.substring(0, fileDot).toLowerCase();
      const fileType = file.name
        .substring(fileDot + 1, file.name.length)
        .toLowerCase();
      params = {
        sn: data.sn,
        schedule_name: data.schedule_name,
        company_name: data.company_name,
        filename: fileName,
        extension: fileType,
        url: fileRes.data,
      };
    }
    const res = await put(`${auth.accountType}/schedule/docs`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function getScheduleTypeAttachFile(data) {
    const res = await get(`${auth.accountType}/schedule/type`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }
  async function putScheduleDocsType(data) {
    let params = {
      ...data,
    };
    if (params?.file.length !== 0) {
      const [file, fileRes] = await uploadfiledocs(
        { file: params.file },
        "type"
      );
      params = {
        ...params,
        filename: file.name.split(".")[0],
        extension: file.name.split(".").pop(),
        url: `${fileRes.data}?alt=media`,
      };
    }
    const res = await put(`${auth.accountType}/schedule/type`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function putScheduleSubmitClose(data) {
    let params = { ...data };
    const res = await put(`${auth.accountType}/schedule/docs`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }

  async function deleteScheduleDocs(data) {
    const res = await del(`${auth.accountType}/schedule/docs`, { sn: data })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return res;
  }
};

export default useScheduleActions;
