import { Navigate } from "react-router-dom";
import { authAtom } from "../../recoil";
import { useRecoilValue } from "recoil";
import CommonLayout from "../common/CommonLayout";
import { ACCOUNTTYPE } from "../../JSON/enum/account";
import Header from "../common/Header";
import Qm from "../../ico/icon_물음표.svg";
import Gear from "../../ico/icon_환경설정.svg";
import CommonModal from "../modal/CommonModal";
import React, { useState } from "react";
import EmployeeInfoSettings from "./EmployeeInfoSettings";
import { List } from "react-content-loader";

const EmployeeLayout = () => {
  const auth = useRecoilValue(authAtom);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  if (!auth.user?.accountType === ACCOUNTTYPE.EMPLOYEE) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Header>
        <button className="gnbbtn border-line mouse">
          <img src={Qm} alt="" />
          <p className="zoom_font">이용방법</p>
        </button>
        <button
          className="gnbbtn border-line mouse"
          onClick={() => setIsSettingModalOpen(true)}
        >
          <img src={Gear} alt="" />
          <p className="zoom_font">환경설정</p>
        </button>
      </Header>
      <CommonModal
        title="근로자 환경설정"
        open={isSettingModalOpen}
        setOpen={setIsSettingModalOpen}
      >
        {isSettingModalOpen && (
          <React.Suspense fallback={<List />}>
            <EmployeeInfoSettings
              setIsSettingModalOpen={setIsSettingModalOpen}
            />
          </React.Suspense>
        )}
      </CommonModal>

      <CommonLayout />
    </>
  );
};

export default EmployeeLayout;
