import DataTable from "../DataTable";
import SearchByDate from "../SearchByDate";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { useWorkGuideActions } from "../../../recoil/api/workGuide";
import { downloadFiles } from "../../../utils";
import PaginationBar from "../PaginationBar";
import BreadCrumb from "../BreadCrumb";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import { overMaxLength } from "../../../utils";
import dayjs from "dayjs";

const WorkGuide = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateAndKeyword, setDateAndKeyword] = useState(DateState);
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [pageInfo, setPageInfo] = useState(PageInfoState);

  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };
  // API 호출 함수 - workGuideList 데이터 불러오기
  const workGuideActions = useWorkGuideActions();

  const loadWorkGuideList = async (pageInfo, dateAndKeyword) => {
    setLoading(true);
    if (state?.prev) {
      const res = await workGuideActions.getWorkGuideList({
        dateAndKeyword: state?.keyword,
        pageInfo: state?.page,
      });
      setLoading(false);
      setPageInfo(res?.data.pageinfo);
      setLoadedData(res?.data.list);
      state.prev = false;
    } else {
      const res = await workGuideActions.getWorkGuideList({
        pageInfo,
        dateAndKeyword,
      });
      setLoading(false);
      setPageInfo(res?.data.pageinfo);
      setLoadedData(res?.data.list);
    }
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      loadWorkGuideList(pageInfo, dateAndKeyword);
    }
  }, [pageInfo?.current, dateAndKeyword]);

  // API 호출 함수 - workGuide 삭제
  const deleteWorkGuides = async (checkedItemIds) => {
    for (let i = 0; i < checkedItemIds.length; i++) {
      const req = { docsn: checkedItemIds[i] };
      // 파일 없어도 deletefile api 호출하는 쪽으로 일단 작성
      const res = await workGuideActions.deleteWorkGuide(req);

      if (res.status === 200) {
        setToast({ ...toast, on: true });
      }
    }
    setCheckedItemIds([]);
    loadWorkGuideList();
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          {/* workGuide 작성/삭제 버튼 - 기업, 관리 계정일 때 활성화 */}
          {(auth.user.isAdmin || auth.user.isCompany) && (
            <div>
              <Link to={`/${auth.accountType}/business_guide_write`}>
                <button
                  className="holidaybtn greenbtn border-line mouse zoom_font"
                  type="button"
                  id="business_guide_write"
                >
                  업무안내 작성
                </button>
              </Link>
              <button
                className="trashbtn border-line mouse"
                type="button"
                onClick={() => {
                  if (window.confirm("삭제하시겠습니까?")) {
                    deleteWorkGuides(checkedItemIds);
                  } else {
                    return;
                  }
                }}
              ></button>
            </div>
          )}
        </div>
        <SearchByDate
          placeholder="제목 입력"
          defaultValue={state?.keyword?.keyword}
          defaultStarttime={
            state?.keyword?.starttime === undefined
              ? undefined
              : dayjs(state?.keyword.starttime)
          }
          defaultEndtime={
            state?.keyword?.endtime === undefined
              ? undefined
              : dayjs(state?.keyword.endtime)
          }
          onChange={(value) => setDateAndKeyword(value)}
        />
      </div>
      <DataTable
        loadedData={loadedData}
        loading={loading}
        setCheckedItemIds={setCheckedItemIds}
        checkbox={auth.user.isAdmin || auth.user.isCompany}
        columns={["제목", "작성일", "첨부파일"]}
      >
        {loadedData?.map((ele) => (
          <tr
            key={ele.sn}
            className="text-center"
            onClick={() => {
              navigate(`/${auth.accountType}/business_guide_view/${ele?.sn}`, {
                state: {
                  page: pageInfo,
                  keyword: dateAndKeyword,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          >
            {/* 체크 박스 - 관리자, 기업 계정일 때 활성화 */}
            {(auth.user.isAdmin || auth.user.isCompany) && (
              <td
                className="hide"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="agree">
                  <label>
                    <input
                      type="checkbox"
                      name="personinfo"
                      onChange={(e) => {
                        onChangeCheckBoxHandler(
                          e.currentTarget.checked,
                          ele.sn
                        );
                      }}
                      checked={checkedItemIds?.includes(ele.sn) ? true : false}
                    />
                    {checkedItemIds?.includes(ele.sn) ? (
                      <div className="chkbox border-line mouse checked"></div>
                    ) : (
                      <div className="chkbox border-line mouse "></div>
                    )}
                  </label>
                </div>
              </td>
            )}

            <td className="border-line mouse zoom_font">
              {overMaxLength(ele?.title, 30)}
            </td>
            <td className="border-line mouse zoom_font">
              {ele.createtime?.slice(0, 10)}
            </td>
            <td
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {ele?.url > 0 && (
                <button
                  className="t-download border-line mouse zoom_font"
                  type="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    downloadFiles(
                      ele,
                      workGuideActions.getWorkGuideFileUrl,
                      { docsn: ele.sn },
                      `업무안내_`,
                      0
                    );
                  }}
                ></button>
              )}
            </td>
          </tr>
        ))}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default WorkGuide;
