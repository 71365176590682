import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import LoginBg from "../../img/login-bg.png";
import Logo from "../../img/logo.png";
import { SERVEREEOR } from "../../JSON/enum/servererr";
import { LoginAlert, LoginInfo } from "../../recoil";
import { SavedId } from "../../recoil";
import { useUserActions } from "../../recoil/api/auth";
import FindIdModal from "./login/FindIdModal";
import FindPasswordModal from "./login/FindPasswordModal";
import Toast from "./Toast";

const Login = () => {
  const [user, setUser] = useRecoilState(LoginInfo);
  const [loginAlert, setLoginAlert] = useRecoilState(LoginAlert);
  const [rememberId, setRememberId] = useState(false);
  const userActions = useUserActions();
  const [findIdModalOpen, setFindIdModalOpen] = useState(false);
  const [findPasswordModalOpen, setFindPasswordModalOpen] = useState(false);
  const [ID, setID] = useRecoilState(SavedId);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const idCheck = () => {
    if (localStorage.getItem("ID")) {
      setRememberId(true);
      setID(true);
    } else {
      setRememberId(false);
      setID(false);
    }
  };

  useEffect(() => {
    // if (loginAlert === 0) {
    //   setLoginAlert(1);
    //   setTimeout(() => {
    //     alert(
    //       "<근로자>\n아이디 : 기존 아이디\n비밀번호 : 아이디 + 회원가입시 사용한 휴대폰 번호 마지막 4자리\n\n<기업>\nID : 기존 아이디\nPW : 기존 아이디 + 아이디 앞 두 글자+!\n* 로그인 이후 비밀번호를 꼭 변경해주세요"
    //     );
    //   }, 500);
    // }

    idCheck();
  }, []);

  const onSubmit = async (user) => {
    return userActions
      .login({
        id: user.id,
        pw: user.pw,
      })
      .catch((err) => {
        let msg = "로그인 정보가 일치하지 않습니다.";
        if (err.data === SERVEREEOR.REQUIREAPPROVE) {
          msg = "회원 승인이 되지 않았습니다. 관리자에게 문의하시기 바랍니다.";
        }
        toast.error(msg, {
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="con-login">
      <img className="loimage" src={LoginBg} alt="배경" />
      <div className="flex-log login-l">
        <div className="l-cont">
          <a>
            <img src={Logo} alt="로고" />
          </a>
          <p>재택근무 인사관리 플랫폼</p>
          <span>(주)브이드림</span>
        </div>
      </div>
      <div className="flex-log login-r">
        <div className="r-cont">
          <div className="lotit">
            <h3>로그인</h3>
            <p>환영합니다. 사용을 위해 로그인 해주세요.</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <table className="table lotable">
              <tbody>
                <tr>
                  <td>
                    <input
                      autoFocus
                      {...register("id", {
                        required: true,
                      })}
                      name="id"
                      placeholder="아이디를 입력해주세요."
                      defaultValue={localStorage.getItem("ID")}
                    />
                    {errors.id?.type === "required" && (
                      <span id="alert-danger" className="register-warning">
                        아이디를 입력하세요.
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      {...register("pw", {
                        required: true,
                      })}
                      type="password"
                      name="pw"
                      placeholder="비밀번호를 입력해주세요."
                    />
                    {errors.pw?.type === "required" && (
                      <span id="alert-danger" className="register-warning">
                        비밀번호를 입력하세요.
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }} className="checkbt">
                    <input type="checkbox" id="check-w" className="check-w" />
                    <span
                      style={{ marginBottom: 10 }}
                      className={rememberId ? "on" : "off"}
                      onClick={() => {
                        setID(!rememberId);
                        setRememberId(!rememberId);
                      }}
                    ></span>
                    <label
                      htmlFor="check-w"
                      id="remember"
                      style={{ marginLeft: 4, marginTop: 8 }}
                      onClick={() => {
                        setID(!rememberId);
                        setRememberId(!rememberId);
                      }}
                    >
                      아이디 기억하기
                    </label>
                  </td>
                  <td className="forget">
                    <button
                      type="button"
                      onClick={() => setFindIdModalOpen(true)}
                    >
                      아이디 찾기&nbsp;
                    </button>
                    |
                    <button
                      type="button"
                      onClick={() => setFindPasswordModalOpen(true)}
                    >
                      &nbsp;비밀번호 찾기
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="tdbutt">
                  <td colSpan={2}>
                    <button className="lobutt on" type="submit">
                      로그인
                    </button>
                  </td>
                </tr>
                <tr className="tdbutt">
                  <td colSpan={2}>
                    <Link to="/register-select">
                      <button className="lobutt">회원가입</button>
                    </Link>
                  </td>
                </tr>
              </tfoot>
            </table>
          </form>
        </div>
      </div>
      <Toast />
      <FindIdModal
        open={findIdModalOpen}
        setOpen={setFindIdModalOpen}
        setFindPasswordModalOpen={setFindPasswordModalOpen}
      />
      <FindPasswordModal
        open={findPasswordModalOpen}
        setOpen={setFindPasswordModalOpen}
      />
    </div>
  );
};

export default Login;
