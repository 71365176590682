import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { DetailSnAtom, MailViewAtom } from "../../../recoil";
import { useMailActions } from "../../../recoil/api/mail";
import PaginationBar from "../PaginationBar";
import MailSearchWord from "./MailSearchWord";
import { overMaxLength } from "../../../utils";
import dayjs from "dayjs";

const MailList = ({
  pageInfo,
  setPageInfo,
  view,
  list,
  setKeyword,
  recive,
  isReserved = 0,
  loading = false,
  prevInfo,
  setPrevInfo,
  setEffectState = 0,
}) => {
  const mailActions = useMailActions();
  const setView = useSetRecoilState(MailViewAtom);
  const setDetailSn = useSetRecoilState(DetailSnAtom);
  const [checkedList, setCheckedList] = useState([]);

  const changeCheckedList = useCallback(
    (sn) => {
      if (checkedList.includes(sn)) {
        setCheckedList((list) =>
          list.filter((item) => {
            return item !== sn;
          })
        );
      } else {
        setCheckedList((old) => [...old, sn]);
      }
    },
    [checkedList]
  );

  const deleteMail = async ({ checkedList }) => {
    if (window.confirm("삭제하시겠습니까?")) {
      await mailActions.deleteMail({ snList: checkedList });
      setCheckedList([]);
      setEffectState(Math.floor(Math.random() * 100));
    }
  };

  return (
    <div id="message_view">
      <MailSearchWord
        setKeyword={setKeyword}
        pageInfo={pageInfo}
        prevInfo={prevInfo}
        setPrevInfo={setPrevInfo}
      />
      <div className="check-line cf">
        <div className="del" style={{ display: "inline-block" }}>
          <div
            style={{
              padding: 0,
              marginTop: "8px",
              display: list?.length > 0 ? "inline-block" : "none",
            }}
            className="checkbt"
            name="msg_sub_chk"
            onClick={(e) => {
              e.stopPropagation();
              if (checkedList.length === list?.length) {
                setCheckedList([]);
              } else {
                setCheckedList(
                  list?.map((item) => {
                    return item.sn;
                  })
                );
              }
            }}
          >
            <input type="checkbox" className="w-che" name="check_delete_seq" />
            <span
              className={checkedList?.length === list?.length ? "on" : "off"}
            ></span>
          </div>
          <button
            hidden={checkedList.length === 0}
            className="trashbtn border-line mouse"
            type="button"
            style={{ width: 30, height: 30, fontSize: "0px" }}
            onClick={() => {
              setTimeout(() => {
                deleteMail({ checkedList });
              }, 50);
            }}
          >
            삭제버튼
          </button>
        </div>
        <div className="me-l">
          <span>전체 :</span>
          <span>
            <strong id="msg_cnt" className="count">
              {pageInfo?.totalCnt || 0}
            </strong>
            통
          </span>
        </div>
      </div>
      <React.Suspense fallback={<div>로딩중...</div>}>
        <div className="poimt" id="MR_databind">
          <div
            id="main-message-frame"
            className="poimt-cont"
            style={{ height: 410 }}
          >
            {list?.map((mail, index) => {
              return (
                <div
                  key={index}
                  className="popimg popmess read"
                  onClick={() => {
                    setDetailSn(mail.sn);
                    setTimeout(() => {
                      setView(view);
                    }, 250);
                  }}
                >
                  <div
                    className="checkbt"
                    name="msg_sub_chk"
                    onClick={(e) => {
                      e.stopPropagation();
                      changeCheckedList(mail.sn);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="w-che"
                      name="check_delete_seq"
                    />
                    <span
                      className={checkedList.includes(mail.sn) ? "on" : "off"}
                    ></span>
                  </div>
                  <a
                    className={`message-detail-a ${
                      mail.isread ? "msg-read-img" : "msg-unread-img"
                    }`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="popit"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ lineHeight: "22px" }}>
                        <p>{overMaxLength(mail?.title, 10)}</p>
                        <p
                          className="mail-ellipsis"
                          title={
                            !recive && Number(mail?.peple) - 1 > 0
                              ? `${mail?.name} 외 ${Number(mail?.peple) - 1}명`
                              : `${mail?.name}(${mail?.id}_${mail?.company_name})`
                          }
                        >
                          {!recive && Number(mail?.peple) - 1 > 0
                            ? `${mail?.name} 외 ${Number(mail?.peple) - 1}명`
                            : `${overMaxLength(mail?.name, 5)}(${overMaxLength(
                                mail?.id,
                                5
                              )})`}
                        </p>
                      </div>
                      <div
                        className="noline"
                        style={{ lineHeight: "22px", textAlign: "right" }}
                      >
                        <p>
                          {dayjs(mail.createtime?.slice(0, 16)).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </p>
                        <p
                          className="read"
                          style={{
                            display: "inline-block",
                            width: "60px",
                            textAlign: "center",
                            fontSize: "12px",
                            backgroundColor: "#E7E6E6",
                            borderRadius: "5px",
                            color: "#937C74",
                          }}
                        >
                          {mail.isread ? "읽음" : "읽지않음"}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>

          {!list && <div>메일이 없습니다.</div>}
        </div>
      </React.Suspense>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};
export default MailList;
