import dayjs from "dayjs";
import React from "react";
import { useState } from "react";
import { Calendar } from "react-calendar";
import { BarLoader } from "react-spinners";
import { WORKSTAMP } from "../../../JSON/enum/Dataenum";
import { dateUtils } from "../../../utils";
import CalendarModal from "./CalendarModal";

export const getWorkstampType = (workstampType) => {
  switch (workstampType) {
    case WORKSTAMP.earlyworkin:
      return "조기 출근";
    case WORKSTAMP.workin:
      return "정상 출근";
    case WORKSTAMP.lateworkin:
      return "지각 출근";
    case WORKSTAMP.workout:
      return "정상 퇴근";
    case WORKSTAMP.lateworkout:
      return "초과 근무";
    case WORKSTAMP.disapper:
      return "결근";
    case WORKSTAMP.notLeavingWork:
      return "미퇴근";
    case WORKSTAMP.earlyworkout:
      return "조기 퇴근";
    default:
      return "없음";
  }
};

// 지각 출근을 지각으로 바꾸려고 하나 만듬
export const getWorkstampType2 = (workstampType) => {
  switch (workstampType) {
    case WORKSTAMP.earlyworkin:
      return "조기 출근";
    case WORKSTAMP.workin:
      return "정상 출근";
    case WORKSTAMP.lateworkin:
      return "지각";
    case WORKSTAMP.workout:
      return "정상 퇴근";
    case WORKSTAMP.lateworkout:
      return "초과 근무";
    case WORKSTAMP.disapper:
      return "결근";
    case WORKSTAMP.notLeavingWork:
      return "미퇴근";
    case WORKSTAMP.earlyworkout:
      return "조기 퇴근";
    default:
      return "없음";
  }
};

const CalendarLg = ({ loading, setDate, workStampList, workInfo }) => {
  const [detail, setDetail] = useState([]);
  const [detailDate, setDetailDate] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const stHour =
    Number(`${workInfo.working_starttime_string?.slice(0, 2)}`) * 60;
  const stMinute = Number(`${workInfo.working_starttime_string?.slice(2, 4)}`);
  const endHour =
    Number(`${workInfo.working_endtime_string?.slice(0, 2)}`) * 60;
  const endMinute = Number(`${workInfo.working_endtime_string?.slice(2, 4)}`);

  const timeChange = (start, end) => {
    let hour;
    let minute;

    hour = parseInt(
      ((start - end) / 1000 / 60 -
        (endHour + endMinute - (stHour + stMinute))) /
        60
    );

    minute = parseInt(
      (start - end) / 1000 / 60 -
        (endHour + endMinute - (stHour + stMinute)) -
        hour * 60
    );

    if (hour <= 10) {
      return `${String(hour).padStart(2, "0")} : ${String(minute).padStart(
        2,
        "0"
      )}`;
    } else {
      return `${String(hour)} : ${String(minute).padStart(2, "0")}`;
    }
  };

  return (
    <>
      <style jsx="true">{`
        .react-calendar {
          width: 100%;
        }
        .react-calendar button {
          border: 1px solid #eee;
        }
        .react-calendar__month-view__days {
          min-height: 500px;
          display: grid !important;
          grid-template-columns: repeat(7, 1fr);
          grid-auto-rows: 1fr;
        }
        .react-calendar__navigation {
          width: 100%;
        }
        .react-calendar button {
          padding: 0;
        }
        .events {
          width: 100%;
          color: #4e4e4e;
        }
        .event-time {
          font-weight: bold;
        }
        .event-1 {
          color: #22ccb7;
        }
        .event-2 {
          color: #22ccb7;
        }
        .event-3 {
          color: #22ccb7;
        }
        .event-4 {
          color: #eb6877;
        }
        .event-5 {
          color: #eb6877;
        }
        .event-9 {
          color: #eb6877;
        }
        .event:hover {
          background: green;
        }
        .react-calendar__tile {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          overflow: hidden !important;
        }
        .react-calendar__tile--now {
          color: black;
          background: none;
          border-radius: 0px;
        }
        .react-calendar__viewContainer {
          border: 1px solid #eee;
        }
        .react-calendar__tile--now::before {
          display: none;
        }
        .react-calendar__tile--now > abbr {
          position: static;
          transform: none;
          z-index: 1;
          text-align: left;
        }
      `}</style>
      <Calendar
        calendarType="US"
        minDate={dateUtils.minDate()}
        maxDate={new Date()}
        prevLabel={"<"}
        nextLabel={">"}
        allowPartialRange={false}
        onActiveStartDateChange={(e) => {
          const thisMonth = dayjs(e.activeStartDate);
          setDate(dateUtils.startEndOfMonthObj(thisMonth));
        }}
        showNavigation={true}
        formatDay={(locale, date) => {
          const targetDate = dayjs(date).format("YYYYMMDD");
          const workstamps = workStampList[targetDate];
          const DD = dayjs(date).format("DD");
          return (
            <>
              {workstamps?.holiday?.length > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ color: "#d10000" }}>{DD}</div>
                  <div style={{ color: "#d10000" }}>
                    {workstamps?.holiday[0]}
                  </div>
                </div>
              ) : workstamps?.companyHoliday?.length > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ color: "#d10000" }}>{DD}</div>
                  <div style={{ color: "#d10000" }}>
                    {workstamps?.companyHoliday[0]}
                  </div>
                </div>
              ) : (
                DD
              )}
            </>
          );
        }}
        tileContent={({ date }) => {
          const targetDate = dayjs(date).format("YYYYMMDD");
          const workstamps = workStampList[targetDate];
          return (
            <>
              {loading && (
                <div key={targetDate}>
                  <BarLoader />
                </div>
              )}
              {!loading && workstamps && (
                <div
                  key={targetDate}
                  className="events"
                  onClick={() => {
                    setDetailDate(dayjs(targetDate).format("YYYY-MM-DD"));
                    setDetail(() => workstamps.workstamp);
                    if (workstamps.workstamp) {
                      setModalOpen(true);
                    }
                  }}
                >
                  {workstamps?.workstamp?.length === 2 && (
                    <>
                      {workstamps?.workstamp?.map((workstamp, index) => {
                        dateUtils.dateToDayjs(workstamp?.stampTime);
                        return (
                          <div key={index}>
                            <div>{getWorkstampType(workstamp?.stampType)}</div>
                            <div
                              className={`event-${workstamp?.stampType} event-time`}
                            >
                              {workstamp?.stampTime?.slice(10, 19)}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                  {workstamps.workstamp.length === 1 && (
                    <>
                      <div>
                        {getWorkstampType(workstamps.workstamp[0].stampType)}
                      </div>
                      <div
                        className={`event-${workstamps.workstamp[0].stampType} event-time`}
                      >
                        {workstamps.workstamp[0].stampTime?.slice(10, 19)}
                      </div>
                    </>
                  )}
                  {(workstamps?.workstamp[0]?.stampType === 1 ||
                    workstamps?.workstamp[1]?.stampType === 5) &&
                    new Date(
                      new Date(workstamps?.workstamp[1]?.stampTime).getTime() -
                        new Date(workstamps?.workstamp[0]?.stampTime).getTime()
                    ) /
                      1000 /
                      60 >
                      endHour + endMinute - (stHour + stMinute) && (
                      <>
                        <div>연장근무</div>
                        <div style={{ fontWeight: "bold" }}>
                          {timeChange(
                            new Date(
                              workstamps?.workstamp[1]?.stampTime
                            ).getTime(),
                            new Date(
                              workstamps?.workstamp[0]?.stampTime
                            ).getTime()
                          )}
                        </div>
                      </>
                    )}
                </div>
              )}
            </>
          );
        }}
      />
      <CalendarModal
        date={detailDate}
        detail={detail}
        workInfo={workInfo}
        title="출퇴근 상세보기"
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </>
  );
};

export default React.memo(CalendarLg);
