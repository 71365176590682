import { get, post, put } from ".";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom, LoginInfo, menuAtom, MobileSideShowAtom } from "..";
import { LoginInitialState } from "../types/data";
import { SERVERMESSAGE } from "../../JSON/enum/serverMessage";
import { getKeyByValue } from "../../utils";
import { ACCOUNTTYPE } from "../../JSON/enum/account";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";
import { SavedId } from "..";
import jwtDecode from "jwt-decode";
import { LoginAlert } from "..";

export const useAccoutTypeActions = () => {
  const auth = useRecoilValue(authAtom);

  return { isAdmin, isCompany, isEmployee };

  function isAdmin() {
    return auth.user.accountType === ACCOUNTTYPE.ADMIN;
  }

  function isCompany() {
    return auth.user.accountType === ACCOUNTTYPE.COMPANY;
  }

  function isEmployee() {
    return auth.user.accountType === ACCOUNTTYPE.EMPLOYEE;
  }
};

export const useCompanyRegisterActions = () => {
  return { phoneCheck, phoneCheckRequest, register, idCheck, emailCheck };

  async function phoneCheck(data) {
    const res = await post("account/tel/auth", data);
    if (res.status === 200) {
      alert("인증이 완료되었습니다.");
      return { result: true };
    } else {
      if (res.data === "authtimeout") {
        return "인증시간이 만료되었습니다. 다시 인증요청 해주세요";
      } else if (res.data === "does not math data") {
        return { result: false, data: "인증번호가 일치하지 않습니다." };
      } else {
        return {
          result: false,
          data: "예기치 못한 오류로 인해 인증에 실패하였습니다.",
        };
      }
    }
  }

  async function phoneCheckRequest({ tel }) {
    const res = await post("account/tel/request", { tel });
    if (res.status === 200) {
      alert("인증번호가 발송되었습니다. 알림톡을 확인해주세요.");
      return true;
    } else {
      alert("이미 사용중인 핸드폰번호 입니다.");
      return false;
    }
  }

  async function register(user) {
    console.log(user);
    const res = await post("login/company/regist", user);
    return res;
  }

  async function idCheck({ id }) {
    const res = await post("login/duplicate", { id });
    if (res.status === 200 && res.data === SERVERMESSAGE.ALLOW) {
      return true;
    } else {
      alert("이미 사용중인 아이디입니다.");
      return false;
    }
  }

  async function emailCheck({ email }) {
    const res = await post("login/duplicateEmail", { email });
    if (res.status === 200) {
      return true;
    } else {
      alert("이미 사용중인 이메일입니다.");
      return false;
    }
  }
};

export const useEmployeeRegisterActions = () => {
  return { phoneCheck, phoneCheckRequest, register, idCheck, emailCheck };

  async function register(user) {
    const res = await post("login/employee/regist", user);
    return res;
  }

  async function phoneCheck(data) {
    const res = await post("account/tel/auth", data);
    if (res.status === 200) {
      alert("인증이 완료되었습니다.");
      return { result: true };
    } else {
      if (res.data === "authtimeout") {
        return "인증시간이 만료되었습니다. 다시 인증요청 해주세요";
      } else if (res.data === "does not math data") {
        return { result: false, data: "인증번호가 일치하지 않습니다." };
      } else {
        return {
          result: false,
          data: "예기치 못한 오류로 인해 인증에 실패하였습니다. 휴대폰 인증번호를 다시 발급받아 주세요.",
        };
      }
    }
  }

  async function phoneCheckRequest({ tel }) {
    const res = await post("account/tel/request", { tel });
    if (res.status === 200) {
      alert("인증번호가 발송되었습니다. 알림톡을 확인해주세요.");
      return true;
    } else {
      alert("이미 사용중인 핸드폰번호 입니다.");
      return false;
    }
  }

  async function idCheck({ id }) {
    const res = await post("login/duplicate", { id });
    if (res.status === 200 && res.data === SERVERMESSAGE.ALLOW) {
      return true;
    } else {
      alert("이미 사용중인 아이디입니다.");
      return false;
    }
  }

  async function emailCheck({ email }) {
    const res = await post("login/duplicateEmail", { email });
    if (res.status === 200) {
      return true;
    } else {
      alert("이미 사용중인 이메일입니다.");
      return false;
    }
  }
};

export const useUserActions = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authAtom);
  const setUser = useSetRecoilState(LoginInfo);
  const setMenu = useSetRecoilState(menuAtom);
  const setMobileSideShow = useSetRecoilState(MobileSideShowAtom);
  const [ID, setID] = useRecoilState(SavedId);
  const [loginAlert, setLoginAlert] = useRecoilState(LoginAlert);

  return {
    changePassword,
    putMenuSetting,
    findId,
    findPassword,
    login,
    logout,
    adminChangePassword,
    setMenuSetting,
  };

  async function changePassword(data) {
    let params = {
      ...data,
      id: auth.user.id,
    };
    const res = await post("account/changepassword", params);
    return res;
  }

  async function setMenuSetting(data) {
    const res = await get(`${auth.accountType}/home/menu`, {
      id: auth.user.id,
    });
    if (res.status === 200) {
      if (res.data.length === 0) {
        const res2 = await get(`${auth.accountType}/home/menu`, {
          id: auth.user.id,
        });
        if (res2.status === 200) return res2.data;
      }
      return res.data;
    } else {
      if (window.confirm("불러오기 오류\n새로 고침 하시겠습니까?")) {
        window.location.reload();
      } else {
        return logout();
      }
    }
    return res;
  }

  async function adminChangePassword(data) {
    let params = {
      ...data,
    };
    const res = await post("account/changepassword/admin", params);
    return res;
  }

  async function putMenuSetting(data) {
    const res = await put(`${auth.accountType}/home/menu`, data);
    return res;
  }

  async function findId(data) {
    const res = await post("account/find/id", data);
    return res;
  }

  async function findPassword(data) {
    const res = await post("account/tempPassword", data);
    return res;
  }

  async function login(user) {
    return post(`login/common`, user)
      .then((res) => {
        if (res.status === 500) {
          throw res;
        }

        const { data } = res;
        const parentMenu = data.menu
          .filter((m) => {
            if (m.upper_menu_sn === 0) {
              return true;
            }
            return false;
          })
          .map((n) => {
            return {
              ...n,
              submenu: [],
              subshow: false,
            };
          });

        const commonCode = data.commonCode.map((item) => {
          return { [item.codename]: item.text };
        });

        const childMenu = data.menu.filter((m) => {
          if (m.upper_menu_sn !== 0) {
            return true;
          }
          return false;
        });

        for (let cm of childMenu) {
          parentMenu
            .find((x) => {
              return x.sn === cm.upper_menu_sn;
            })
            .submenu.push(cm);
        }
        const accountType = getKeyByValue(
          ACCOUNTTYPE,
          data.user.accountType
        ).toLowerCase();

        const user = {
          ...data,
          commonCode: commonCode,
          menu: parentMenu,
          accountType: accountType,
          user: {
            ...data.user,
            isCompany: data.user.accountType === ACCOUNTTYPE.COMPANY,
            isEmployee: data.user.accountType === ACCOUNTTYPE.EMPLOYEE,
            isAdmin: data.user.accountType === ACCOUNTTYPE.ADMIN,
          },
        };

        const payload = jwtDecode(res.data.accessToken);

        Cookies.set("refreshToken", res?.data?.refreshToken);
        localStorage.setItem("expiresAt", payload.exp);
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("user", JSON.stringify(user));
        // 아이디 기억하기를 하면 ID에 저장
        if (ID === true) {
          localStorage.setItem("ID", String(data.user.id));
        } else {
          localStorage.setItem("ID", "");
        }
        setAuth(user);
        setMenu(parentMenu);
        setMobileSideShow(false);
        navigate(`/${user.accountType}/home`);
      })
      .catch((err) => {
        setUser(LoginInitialState);
        throw err;
      });
  }

  async function logout() {
    setLoginAlert(0);
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiresAt");
    setAuth(null);
    setMenu([]);
    navigate("/");
    window.location.reload(); //채팅이 여러번 발생하는 문제때문에 일단 해둠 다른 좋은 방법있는지 찾아보면 좋을듯
  }
};
