import { useEffect, useState } from "react";
import useScheduleActions from "../../../recoil/api/schedule";
import DataTable from "../DataTable";
import { toastAtom } from "../../../recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import PaginationBar from "../PaginationBar";
import { PageInfoState } from "../../../recoil/types/data";
import { authAtom } from "../../../recoil";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import dayjs from "dayjs";
import { overMaxLength } from "../../../utils";
import ScheduleFunction from "./ScheduleFunction";
import CommonModal from "../../modal/CommonModal";
import ScheduleRegisterationDetail from "./ScheduleRegistrationDetail";

const ScheduleDetailTable = ({
  date,
  type,
  COMP_CD = null,
  maxLength = 30,
  onChangeModalState,
}) => {
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);
  const [toast, setToast] = useRecoilState(toastAtom);
  const scheduleActions = useScheduleActions();
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [isEventDetailModalOpen, setIsEventDetailModalOpen] = useState(false);
  const [eventDetail, setEventDetail] = useState({});
  const [COMP, setCOMP] = useState();
  const [delEdit, setDelEdit] = useState(false);

  const scFn = ScheduleFunction();

  useEffect(() => {
    if ((date.starttime && date.endtime) || delEdit) {
      getScheduleByDate();
      setDelEdit(false);

      // 캘린더 일때만 onChangeModalState로 캘린더를 새로고침 함
      try {
        onChangeModalState();
      } catch (error) {}
    }
  }, [date, type, COMP_CD, pageInfo?.current, delEdit]);

  const deleteSchedule = async (schedule) => {
    setLoading(true);
    setToast({ ...toast, on: true });

    const res = await scheduleActions.delSchedule({ sn: schedule.sn });
    if (res.status === 200) {
      getScheduleByDate();
    }
    setLoading(false);
  };

  const getScheduleByDate = async () => {
    setLoading(true);
    const res = await scheduleActions.getScheduleByDate({
      date,
      pageInfo,
      COMP_CD,
      type: type === 0 ? null : type,
    });

    if (res.status === 200) {
      setSchedules(res.data.list);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const deleteCheck = (writer_id) => {
    if (auth.user.isAdmin === true || auth.user.isCompany === true) {
      return true;
    }
    if (auth.user.id === writer_id) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={["구분", "대상자", "시행기간", "신청일", "삭제"]}
      >
        {schedules?.map((schedule, i) => {
          return (
            <tr
              key={i}
              className="text-center"
              onClick={() => {
                setIsEventDetailModalOpen(true);
                setEventDetail(schedule);
                setCOMP(schedule.COMP_CD);
              }}
            >
              <td className="border-line zoom_font" style={{ minWidth: 60 }}>
                {scFn.getScheduleType(schedule.scheduleType)}
              </td>
              <td className="border-line zoom_font">
                {overMaxLength(schedule.name, maxLength)}
              </td>
              <td className="border-line zoom_font">
                {`${dayjs(schedule.starttime).format("YYYY-MM-DD")} ~ ${dayjs(
                  schedule.endtime
                ).format("YYYY-MM-DD")}`}
              </td>
              <td className="border-line zoom_font">{schedule.createtime}</td>
              <td style={{ minWidth: 60 }}>
                {deleteCheck(schedule.writer_id) && (
                  <button
                    className="pinkbtn-sm border-line zoom_font"
                    onClick={(e) => {
                      if (window.confirm("삭제하시겠습니까?")) {
                        e.stopPropagation();
                        deleteSchedule(schedule);
                        setDelEdit(true);
                      } else {
                        return false;
                      }
                    }}
                  >
                    삭제
                  </button>
                )}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />

      <CommonModal
        open={isEventDetailModalOpen}
        setOpen={setIsEventDetailModalOpen}
        title="일정 보기"
      >
        {eventDetail && (
          <ScheduleRegisterationDetail
            COMP_CD={COMP}
            eventDetail={eventDetail}
            setOpen={setIsEventDetailModalOpen}
            delEdit={setDelEdit}
          />
        )}
      </CommonModal>
    </div>
  );
};

export default ScheduleDetailTable;
