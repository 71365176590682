import { useEffect, useState, useRef } from "react";
import SearchByDate from "../SearchByDate";
import DataTable from "../DataTable";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import { useWorkOrderActions } from "../../../recoil/api/workOrder";
import PaginationBar from "../PaginationBar";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import BreadCrumb from "../BreadCrumb";
import { downloadFiles } from "../../../utils";
import { overMaxLength } from "../../../utils";
import { DateState, PageInfoState } from "../../../recoil/types/data";

dayjs.extend(isSameOrAfter);

const WorkOrder = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [toast, setToast] = useRecoilState(toastAtom);

  const auth = useRecoilValue(authAtom);
  const [loading, setLoading] = useState(true);
  const [loadedData, setLoadedData] = useState([]);
  const [dateAndKeyword, setDateAndKeyword] = useState(DateState);
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [pageInfo, setPageInfo] = useState(PageInfoState);

  const onChangeCheckBoxHandler = (checked, idx) => {
    if (checked) {
      setCheckedItemIds(() => [...checkedItemIds, idx]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((bar) => bar !== idx));
    }
  };

  // API 요청 함수 - workOrder 데이터 불러오기
  const workOrderActions = useWorkOrderActions();

  const loadWorkOrderList = async (pageInfo, dateAndKeyword) => {
    setLoading(true);
    if (state?.prev) {
      const res = await workOrderActions.getWorkOrderList({
        dateAndKeyword: state?.keyword,
        pageInfo: state?.page,
      });
      setLoading(false);
      setLoadedData(res?.data.list);
      setPageInfo(res.data.pageinfo);
      state.prev = false;
    } else {
      const res = await workOrderActions.getWorkOrderList({
        dateAndKeyword,
        pageInfo,
      });
      setLoading(false);
      setLoadedData(res?.data.list);
      setPageInfo(res.data.pageinfo);
    }
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      loadWorkOrderList(pageInfo, dateAndKeyword);
    }
  }, [pageInfo?.current, dateAndKeyword]);

  // API 요청 함수 - workOrder 데이터 삭제
  const deleteWorkOrders = async (item) => {
    for (let i = 0; i < checkedItemIds.length; i++) {
      const req = {
        docsn: item[checkedItemIds[i]].sn,
        employee_id: item[checkedItemIds[i]].employee_id,
      };
      const res = await workOrderActions.deleteWorkOrder(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
      }
    }
    setCheckedItemIds([]);
    loadWorkOrderList(pageInfo, dateAndKeyword);
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          {/* 글 생성/삭제 버튼 - 기업 상태에 활성화 */}
          {auth.user.isCompany && (
            <div>
              <Link to={`/${auth.accountType}/workorder_write`}>
                <button
                  className="holidaybtn greenbtn border-line mouse zoom_font"
                  type="button"
                  id="workorder_write"
                >
                  업무지시 작성
                </button>
              </Link>
              <button
                className="trashbtn border-line mouse"
                type="button"
                onClick={() => {
                  if (window.confirm("삭제하시겠습니까?")) {
                    deleteWorkOrders(loadedData);
                  } else {
                    return;
                  }
                }}
              ></button>
            </div>
          )}
        </div>
        {/* 검색 컴포넌트 - date와 검색어를 같이 props로 넘겨줌 */}
        <SearchByDate
          placeholder="이름 입력"
          defaultValue={state?.keyword?.keyword}
          defaultStarttime={
            state?.keyword?.starttime === undefined
              ? undefined
              : dayjs(state?.keyword.starttime)
          }
          defaultEndtime={
            state?.keyword?.endtime === undefined
              ? undefined
              : dayjs(state?.keyword.endtime)
          }
          onChange={(value) => {
            setDateAndKeyword(value);
          }}
          loading={loading}
        />
      </div>
      <DataTable
        order={true}
        loadedData={loadedData}
        loading={loading}
        setCheckedItemIds={setCheckedItemIds}
        checkbox={auth.user.accountType === ACCOUNTTYPE.COMPANY}
        columns={["제목", "대상자", "지시일", "첨부파일", "업무진행도"]}
      >
        {loadedData?.map((obj, idx) => (
          <tr
            key={idx}
            className="text-center"
            onClick={() => {
              navigate(`/${auth.accountType}/workorder_view/${obj.sn}`, {
                state: {
                  page: pageInfo,
                  esn: obj.esn,
                  keyword: dateAndKeyword,
                },
              });
            }}
          >
            {/* 체크 박스 - 기업 상태에 활성화 */}
            {auth.user.isCompany && (
              <td onClick={(e) => e.stopPropagation()}>
                <div className="agree">
                  <label>
                    <input
                      type="checkbox"
                      name="personinfo"
                      onChange={(e) => {
                        onChangeCheckBoxHandler(e.currentTarget.checked, idx);
                      }}
                      checked={checkedItemIds.includes(idx) ? true : false}
                    />
                    {checkedItemIds.includes(idx) ? (
                      <div className="chkbox border-line mouse checked"></div>
                    ) : (
                      <div className="chkbox border-line mouse "></div>
                    )}
                  </label>
                </div>
              </td>
            )}

            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {overMaxLength(obj.work_order_title, 30)}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj.employee_name}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {dayjs(obj.work_order_ymd).format("YYYY-MM-DD")}
            </td>
            <td>
              {obj?.url > 0 && (
                <button
                  className="t-download border-line mouse"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFiles(
                      obj,
                      workOrderActions.getWorkOrderFileUrl,
                      { docsn: obj?.sn },
                      `업무지시_`,
                      0
                    );
                  }}
                  style={{ cursor: "pointer" }}
                ></button>
              )}
            </td>
            <td className="complete hide" style={{ cursor: "pointer" }}>
              {dayjs(obj.work_order_ymd).isSameOrAfter(
                dayjs().format("YYYYMMDD")
              ) ? (
                <div
                  className="border-line mouse zoom_font"
                  style={{ color: "green" }}
                >
                  진행중
                </div>
              ) : (
                <div
                  className="border-line mouse zoom_font"
                  style={{ color: "gray" }}
                >
                  진행완료
                </div>
              )}
            </td>
          </tr>
        ))}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default WorkOrder;
