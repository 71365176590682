import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { APPROVALDOCTYPE } from "../../../JSON/enum/Dataenum";

const ApprovalFunction = () => {
  const auth = useRecoilValue(authAtom);

  return {
    docDivisionOptions,
    workAttitudeOptions,
    workOptions,
    paymentOptions,
  };

  function docDivisionOptions() {
    return [
      { label: "전체", value: 0 },
      {
        label: auth?.commonCode[39]["근태"],
        value: APPROVALDOCTYPE.WORKATTITUDE,
      },
      { label: auth?.commonCode[40]["업무"], value: APPROVALDOCTYPE.WORK },
      {
        label: auth?.commonCode[41]["지출 / 구매"],
        value: APPROVALDOCTYPE.PAYMENT,
      },
    ];
  }

  function workAttitudeOptions() {
    return [
      { label: auth?.commonCode[42]["사직서"], value: APPROVALDOCTYPE.RESIGN },
      { label: auth?.commonCode[43]["시말서"], value: APPROVALDOCTYPE.PROBLEM },
    ];
  }

  function workOptions() {
    return [
      {
        label: auth?.commonCode[44]["업무협조전"],
        value: APPROVALDOCTYPE.COOPERATE,
      },
    ];
  }
  function paymentOptions() {
    return [
      {
        label: auth?.commonCode[45]["경조사비신청서"],
        value: APPROVALDOCTYPE.SPECIALDAY,
      },
      {
        label: auth?.commonCode[47]["지출품의서"],
        value: APPROVALDOCTYPE.PREBUYPAYMENT,
      },
      {
        label: auth?.commonCode[46]["지출내역서"],
        value: APPROVALDOCTYPE.BUYPAYMENT,
      },
    ];
  }
};

export default ApprovalFunction;
