import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { SCHEDULETYPE } from "../../../JSON/enum/Dataenum";
import useScheduleActions from "../../../recoil/api/schedule";
import CommonModal from "../../modal/CommonModal";
import { getScheduleType } from "./Schedule";
import { authAtom, toastAtom } from "../../../recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import SearchCompanyModal from "../SearchCompanyModal";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import { SelectManagerList } from "../SelectManagerList";
import { useEffect } from "react";
import ScheduleFunction from "./ScheduleFunction";
import Select from "react-select";
import { keeper } from "./ScheduleRegistrationDetail";

const ScheduleRegisteration = ({ onChangeModalState }) => {
  const scheduleActions = useScheduleActions();
  const accountType = useAccoutTypeActions();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // (모달) - searchCompanyModal 상태 값
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);

  const [company, setCompany] = useState({});
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [selectedEmployeeEsnList, setSelectedEmployeeEsnList] = useState([]);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const scFn = ScheduleFunction();
  const [selectType, SetSelectType] = useState();

  const scheduleType = [
    {
      value: SCHEDULETYPE.MEETING,
      label: scFn.getScheduleType(SCHEDULETYPE.MEETING),
    },
    {
      value: SCHEDULETYPE.BIRTHDAY,
      label: scFn.getScheduleType(SCHEDULETYPE.BIRTHDAY),
    },
    {
      value: SCHEDULETYPE.HALFHALFOFF,
      label: scFn.getScheduleType(SCHEDULETYPE.HALFHALFOFF),
    },
    {
      value: SCHEDULETYPE.HALFOFF,
      label: scFn.getScheduleType(SCHEDULETYPE.HALFOFF),
    },
    {
      value: SCHEDULETYPE.DAYOFF,
      label: scFn.getScheduleType(SCHEDULETYPE.DAYOFF),
    },
    {
      value: SCHEDULETYPE.OUTWORKING,
      label: scFn.getScheduleType(SCHEDULETYPE.OUTWORKING),
    },
    {
      value: SCHEDULETYPE.BUSINESSTRIP,
      label: scFn.getScheduleType(SCHEDULETYPE.BUSINESSTRIP),
    },
    {
      value: SCHEDULETYPE.CONFFRENCE,
      label: scFn.getScheduleType(SCHEDULETYPE.CONFFRENCE),
    },
  ];
  const [isSelectManagerModalOpen, setIsSelectManagerModalOpen] =
    useState(false);

  const onChangeSelectManagerModalState = () => {
    setIsSelectManagerModalOpen(false);
  };

  const { register, handleSubmit, watch, reset } = useForm();

  useEffect(() => {
    reset();
    setCompany({});
    setSelectedEmployeeList([]);
    setSelectedEmployeeEsnList([]);
  }, [onChangeModalState]);

  const onSubmit = async (data) => {
    if (selectType === undefined) {
      setToast({
        ...toast,
        error: true,
        message: "일정종류를 선택 해 주세요.",
      });
      return false;
    }
    if (
      data.sdate === "" ||
      data.stime === "" ||
      data.edate === "" ||
      data.etime === ""
    ) {
      setToast({ ...toast, error: true, message: "시간을 입력 해주세요." });
      return false;
    }
    const starttime = new Date(`${data.sdate} ${data.stime}`);
    const endtime = new Date(`${data.edate} ${data.etime}`);
    if (starttime >= endtime) {
      setToast({ ...toast, error: true, message: "시간이 올바르지 않습니다." });
      return false;
    }
    if (accountType.isAdmin() && !company.COMP_CD) {
      setToast({ ...toast, error: true, message: "기업을 추가해주세요." });
      return false;
    }
    if (selectedEmployeeEsnList.length === 0) {
      setToast({
        ...toast,
        error: true,
        message: `${keeper}를 선택 해 주세요.`,
      });
      return false;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);
    const req = {
      starttime: starttime.toString(),
      endtime: endtime.toString(),
      type: selectType,
      attendant: selectedEmployeeList.map((ele) => ele.id),
      location: data.location || "",
      contents: data.contents || "",
      employee_id: selectedEmployeeList.map((ele) => ele.id),
      employee_name: selectedEmployeeList.map((ele) => ele.name),
    };

    if (accountType.isAdmin() && company.COMP_CD) {
      req.COMP_CD = company.COMP_CD;
    }
    const res = await scheduleActions.postSchedule(req);
    if (res.status === 200) {
      setToast({ ...toast, message: "일정 등록에 성공했습니다.", on: true });
    }
    onChangeModalState();
    setDoubleclickLoading(false);
  };

  return (
    <div>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table style={{ minWidth: "400px" }}>
            <tbody>
              <tr style={{ borderBottom: "1px solid #eee" }}>
                <th className="text-center-title zoom_font w-30">
                  일정 종류 선택
                </th>
                <td>
                  <Select
                    placeholder="일정 종류 선택"
                    options={scheduleType}
                    onChange={(e) => {
                      SetSelectType(e.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">시간 선택</th>
                <td style={{ display: "flex" }}>
                  <input
                    {...register("sdate")}
                    className="s-custom-input custom-input border-line"
                    type="date"
                  />
                  <input
                    {...register("stime")}
                    className="s-custom-input custom-input border-line"
                    type="time"
                  />
                  <span className="wave">-</span>
                  <input
                    {...register("edate")}
                    className="s-custom-input custom-input border-line"
                    type="date"
                  />
                  <input
                    {...register("etime")}
                    className="s-custom-input custom-input border-line"
                    type="time"
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">위치 추가</th>
                <td>
                  <input
                    {...register("location")}
                    className="s-custom-input custom-input w-100 border-line"
                    placeholder="위치를 추가해 주세요."
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">설명 추가</th>
                <td>
                  <input
                    {...register("contents")}
                    className="s-custom-input custom-input w-100 border-line"
                    placeholder="설명을 입력해주세요."
                  />
                </td>
              </tr>
              {accountType.isAdmin() && (
                <tr>
                  <th className="text-center-title zoom_font">기업 추가</th>
                  <td>
                    <input
                      className="custom-input border-line mouse zoom_font"
                      type="text"
                      style={{ width: "70%" }}
                      value={company?.company_name || ""}
                      readOnly
                    />
                    <button
                      className="add-manager border-line zoom_font"
                      type="button"
                      onClick={() => {
                        setIsSearchCompanyModalOpen(true);
                      }}
                    >
                      기업 선택
                    </button>
                  </td>
                </tr>
              )}
              {/* 회사거나, 어드민이고 company 선택 했을 때 */}
              {(accountType.isCompany() ||
                accountType.isEmployee() ||
                (accountType.isAdmin() && company.COMP_CD)) && (
                <tr>
                  <th className="text-center-title zoom_font">{keeper} 추가</th>
                  <td>
                    <input
                      className="custom-input border-line mouse zoom_font"
                      type="text"
                      style={{ width: "70%" }}
                      value={
                        selectedEmployeeList.map((obj) =>
                          obj?.companyAccount_name
                            ? obj?.companyAccount_name
                            : obj?.employee_name
                        ) || ""
                      }
                      readOnly
                    />
                    <button
                      className="add-manager"
                      type="button"
                      onClick={() => {
                        setIsSelectManagerModalOpen(true);
                      }}
                    >
                      {watch("employee")?.name || `${keeper} 선택`}
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div id="modalfoot">
          <div className="btn-group">
            <button
              className="fbtn submitbtn border-line zoom_font"
              type="submit"
            >
              저장
            </button>
            <button
              className="fbtn cancelbtn border-line zoom_font"
              type="button"
              onClick={() => onChangeModalState()}
            >
              취소
            </button>
          </div>
        </div>
      </form>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
      <SelectManagerList
        COMP_CD={auth.user.isAdmin && company?.COMP_CD}
        selectedEmployeeList={selectedEmployeeList}
        setSelectedEmployeeList={setSelectedEmployeeList}
        selectedEmployeeEsnList={selectedEmployeeEsnList}
        setSelectedEmployeeEsnList={setSelectedEmployeeEsnList}
        open={isSelectManagerModalOpen}
        onChangeModalState={onChangeSelectManagerModalState}
        selfSelect={true}
      />
    </div>
  );
};

export default ScheduleRegisteration;
