import QnA from "../../../ico/docs_qna.svg";
import DownloadQnA from "../../../ico/docs_down1.svg";
import dayjs from "dayjs";
import {
  downloadFiles,
  fileNameCheck,
  fileSizeCheck,
  overMaxLength,
} from "../../../utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import { toast as toastify } from "react-toastify";

import useScheduleActions from "../../../recoil/api/schedule";
const ScheduleSubmitFile = ({
  item,
  getScheduleDocsList,
  isSubmitModalOpen,
}) => {
  const { register, setValue, handleSubmit, control } = useForm();
  const [files, setFiles] = useState(null);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [btnClickDisabled, setBtnClickDisabled] = useState(false);
  const scheduleActions = useScheduleActions();

  // 파일 input 핸들러
  const onchangeFilesHandler = (event) => {
    event.preventDefault();

    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }
      setFiles(event?.target?.files[0]);
      setValue("file", null);
    }
  };

  const onSubmitInit = () => {
    if (!!!files) {
      setToast({ ...toast, error: true, message: "파일을 첨부해주세요." });
      return;
    }
    setBtnClickDisabled(true);
    let params = {
      ...item,
      file: files,
    };

    const toastId = toastify.loading(
      "첨부파일 용량과 인터넷 속도에 따라 저장이 길어질 수 있습니다."
    );
    return [params, toastId];
  };

  const onSubmitClear = (res, toastId) => {
    if (res.status === 200) {
      toastify.update(toastId, {
        render: "서류 제출이 완료되었습니다.",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      getScheduleDocsList();
      isSubmitModalOpen(false);
    } else {
      toastify.update(toastId, {
        render: "서류 제출이 실패하였습니다.",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
    setBtnClickDisabled(false);
  };

  const onSubmitRegister = async () => {
    const [params, toastId] = onSubmitInit();
    const res = await scheduleActions.postScheduleDocsSubmit(params);
    onSubmitClear(res, toastId);
  };

  const onSubmitModify = async () => {
    const [params, toastId] = onSubmitInit();
    const res = await scheduleActions.putScheduleDocsSubmit(params);
    onSubmitClear(res, toastId);
  };
  return (
    <form>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          className="title-h2"
          style={{ fontWeight: 600, fontSize: "32px", lineHeight: "36px" }}
        >
          {item.docs_name}
        </h2>
        {/* <button type="button" style={{ float: "right" }}>
          <img src={QnA} alt="서류 제출이유?" />
        </button> */}
      </div>
      <span style={{ marginTop: "8px", fontSize: "24px", lineHeight: "28px" }}>
        {dayjs(item?.start_date).format("YYYY-MM-DD")} ~{" "}
        <span style={{ color: "#BE5252" }}>
          {dayjs(item?.end_date).format("YYYY-MM-DD")}
        </span>
      </span>
      <hr style={{ margin: "16px 0", border: "1px solid #D9D9D9" }} />
      <div
        style={{
          color: "#454545",
          fontSize: "18px",
          lineHeight: "24px",
        }}
      >
        <span>
          {item?.docs_info
            ? item?.docs_info
            : `${dayjs(item?.start_date).format("YYYY년 MM월")} ${
                item?.docs_name
              } 제출입니다.`}
        </span>
      </div>
      {item?.docs_filename && (
        <button
          type="button"
          style={{
            margin: "20px 0",
            padding: "16px 24px",
            background: "#D9D9D9",
            width: "100%",
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => {
            try {
              downloadFiles(
                item,
                scheduleActions.getScheduleTypeAttachFile,
                { docs_sn: item?.sn },
                `서류제출방법_`
              );
            } catch {
              alert(
                "서버 이전으로 자료를 옮기고 있습니다.\n빠른 시일내로 정상화 하겠습니다."
              );
            }
          }}
        >
          <div>
            <h3>
              {item?.docs_filename}.{item?.docs_extension}
            </h3>
            <span style={{ marginTop: "4px" }}>서류 제출 방법입니다.</span>
          </div>

          <img src={DownloadQnA} alt="제출방법 다운로드" />
        </button>
      )}
      <div
        style={{
          marginTop: "24px",
          color: "#454545",
        }}
      >
        <div>
          <span style={{ marginRight: "40px" }}>첨부파일</span>
          {!!item?.is_active && (
            <>
              <input
                {...register("file")}
                id="file"
                type="file"
                style={{ display: "none" }}
                className="input-file"
                onChange={onchangeFilesHandler}
                disabled={dayjs(item?.start_date).isAfter(dayjs())}
              />
              <label
                htmlFor="file"
                style={{ padding: "4px 16px", border: "1px solid #D9D9D9" }}
              >
                내 PC
              </label>
              {dayjs(item?.start_date).isAfter(dayjs()) && (
                <span style={{ color: "#BE5252", marginLeft: "8px" }}>
                  *현재 제출기한이 아닙니다
                </span>
              )}
            </>
          )}
        </div>
        <div
          style={{
            border: "1px solid #D9D9D9",
            width: "70%",
            height: "60px",
            marginTop: "16px",
            padding: "0 20px",
            alignContent: "center",
          }}
        >
          {files ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {overMaxLength(files?.name, 30)}
              <button
                type="button"
                className="pinkbtn-sm"
                onClick={() => setFiles()}
              >
                삭제
              </button>
            </div>
          ) : (
            item.filename && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {overMaxLength(`${item.filename}.${item.extension}`, 30)}
                {/* <button
                  type="button"
                  className="blackbtn-sm"
                  onClick={() => setFiles()}
                >
                  확인
                </button> */}
              </div>
            )
          )}
        </div>
      </div>
      <div style={{ textAlign: "right", marginTop: 20 }}>
        {!!item?.is_active &&
          dayjs(item?.start_date).isBefore(dayjs()) &&
          (!!item?.is_submit ? (
            dayjs(item?.end_date).isAfter(dayjs()) ? (
              <button
                type="button"
                className="s-save sc"
                disabled={btnClickDisabled}
                onClick={() => handleSubmit(onSubmitModify)()}
              >
                수정하기
              </button>
            ) : (
              ""
            )
          ) : (
            <button
              type="button"
              className="s-save sc"
              disabled={btnClickDisabled}
              onClick={() => handleSubmit(onSubmitRegister)()}
            >
              등록하기
            </button>
          ))}
      </div>
    </form>
  );
};

export default ScheduleSubmitFile;
