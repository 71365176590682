import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import useManagementActions, {
  CompanyEmployeeManageListQuery,
} from "../../../recoil/api/management";
import { PageInfoState } from "../../../recoil/types/data";
import EmployeeDetailModal from "../../admin/management/EmployeeDetailModal";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import { authAtom } from "../../../recoil";
import SearchKeyword from "../../common/SearchKeyword";
import XlsxDownload from "../../form/XlsxDownload";
import Select from "react-select";
import HrmFunction from "../../common/hrm/HrmFunction";

const Settings = () => {
  const auth = useRecoilValue(authAtom);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [companyEmployeeList, setCompanyEmployeeList] = useState([]);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [isEmployeeDetailModalOpen, setIsEmployeeDetailModalOpen] =
    useState(false);
  const [employeeDetail, setEmployeeDetail] = useState({});
  const manageActions = useManagementActions();
  const [type, setType] = useState(null);
  const hrmFn = HrmFunction();

  const getManageList = async () => {
    setLoading(true);
    const res = await manageActions.getManageEmployee({
      pageInfo,
      keyword,
      type: type,
    });
    if (res) {
      setCompanyEmployeeList(res.list);
      setPageInfo(res.pageinfo);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isEmployeeDetailModalOpen) {
      setEmployeeDetail({});
      getManageList();
    }
  }, [pageInfo?.current, keyword, type, isEmployeeDetailModalOpen]);

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div className="btn-group btn-group-left">
            <XlsxDownload
              api={`${auth.accountType}/settings`}
              params={{ companyId: auth.user.id }}
              name="직원관리"
            />
          </div>
        </div>
        <div className="btn-group d-block">
          <div className="select-border-line">
            <Select
              placeholder={"전체"}
              options={hrmFn.workOrNotOptions()}
              onChange={(e) => {
                setType(e.value);
              }}
            ></Select>
          </div>
          <SearchKeyword setKeyword={setKeyword} />
        </div>
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={[
          "이름",
          "직무",
          "전화번호",
          "연차일수",
          "사용일수",
          "재직여부",
        ]}
      >
        {companyEmployeeList?.map((employee, idx) => {
          return (
            <tr
              key={idx}
              className="pointer"
              onClick={() => {
                setIsEmployeeDetailModalOpen(true);

                setEmployeeDetail(employee);
              }}
            >
              <td className="border-line zoom_font">
                {employee?.employee_name}
              </td>
              <td className="border-line zoom_font">
                {employee?.employee_department}
              </td>
              <td className="border-line zoom_font">
                {employee?.employee_tel}
              </td>
              <td className="border-line zoom_font">
                {Math.round(
                  employee?.days_total * (employee?.day_standard || 1) * 10
                ) / 10}
              </td>
              <td className="border-line zoom_font">
                {Math.round(
                  employee?.days_used * (employee.day_standard || 1) * 10
                ) / 10}
              </td>
              <td className="border-line zoom_font">
                {employee?.worklog[employee?.worklog.length - 1]?.ishired ===
                  undefined && "입사예정"}
                {employee?.worklog[employee?.worklog.length - 1]?.ishired ===
                  0 && "퇴직"}
                {employee?.worklog[employee?.worklog.length - 1]?.ishired ===
                  1 && "재직중"}
              </td>
            </tr>
          );
        })}
      </DataTable>
      <p style={{ marginTop: 10 }}>{`직원 수 : ${pageInfo?.totalCnt}건`}</p>
      {!loading && employeeDetail.employee_id && (
        <EmployeeDetailModal
          readOnly={true}
          employeeDetail={employeeDetail}
          setEmployeeDetail={setEmployeeDetail}
          open={isEmployeeDetailModalOpen}
          setOpen={setIsEmployeeDetailModalOpen}
          loading={loading}
        />
      )}
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default Settings;
