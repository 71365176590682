import { useRecoilValue } from "recoil";
import { get, del, post, getPage, createWithUploadFile } from ".";
import { authAtom } from "..";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const useWorkGuideActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    searchCompany,
    getWorkGuideList,
    getWorkGuideView,
    getWorkGuideFileUrl,
    getWorkGuideCompressFileUrl,
    createWorkGuide,
    updateWorkGuide,
    deleteWorkGuide,
    deleteWorkGuideFile,
  };

  async function searchCompany() {
    const res = await get("search/company/name", {
      count: 8,
      // id: auth.user.id,
    });
    return res;
  }

  async function getWorkGuideList(data) {
    const req = auth.user.isEmployee
      ? {
          ...data,
          id: auth.user.id,
        }
      : auth.user.isCompany
      ? {
          ...data,
          companyId: auth.user.id,
        }
      : auth.user.isAdmin && {
          ...data,
        };

    return getPage(`${auth.accountType}/workguide`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkGuideView(data) {
    const req = {
      ...data,
    };
    return get(`select/workguide`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function createWorkGuide(
    data,
    url = `${auth.accountType}/workguide_write/write`
  ) {
    const req = auth.user.isAdmin
      ? data
      : auth.user.isCompany && {
          ...data,
          id: auth.user.id,
        };
    //파일이 있을 경우
    if (data.file.length) {
      const res = await createWithUploadFile(url, req);
      return res;
    } else {
      // 파일이 없는 경우
      const res = await post(url, req);
      return res;
    }
  }

  async function updateWorkGuide(
    data,
    url = `${auth.accountType}/workguide/update`
  ) {
    //파일이 있을 경우
    if (data.file.length) {
      const req = auth.user.isAdmin
        ? { ...data, url: data.prevFiles }
        : auth.user.isCompany && {
            ...data,
            url: data.prevFiles,
            id: auth.user.id,
          };
      const res = await createWithUploadFile(url, req);
      return res;
    } else {
      const req = auth.user.isAdmin
        ? { ...data, url: null, prevFiles: null }
        : auth.user.isCompany && {
            ...data,
            // url: null,
            url: data.prevFiles.filter((ele) => ele),
            prevFiles: null,
            id: auth.user.id,
          };
      // 파일이 없는 경우
      const res = await post(url, req);
      return res;
    }
  }

  async function deleteWorkGuide(data) {
    await deleteAllWorkGuideFiles(data);
    return await del(`delete/workguide`, { docsn: data.docsn })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkGuideFileUrl(req) {
    return await get(`select/workguide/attachlink`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getWorkGuideCompressFileUrl(req) {
    return await post(`getCompressFiles`, req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deleteAllWorkGuideFiles(data) {
    const res = await getWorkGuideFileUrl(data);
    const fileUrlData = res.data;
    console.log("file : ", fileUrlData);
    for (let i = 0; i < fileUrlData.length; i++) {
      const req = { sn: fileUrlData[i].sn };
      await deleteWorkGuideFile(req);
    }
  }

  async function deleteWorkGuideFile(req) {
    // await del(`delete/workguideattach`, req)
    //   .then((res) => {
    //   })
    //   .catch((err) => {
    //     throw err;
    //   });
  }
};
