import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { APPLICATIONSTATE } from "../../../JSON/enum/Dataenum";
import { authAtom, toastAtom } from "../../../recoil";
import useHrmActions from "../../../recoil/api/hrm";
import { dateUtils } from "../../../utils";
import { getHrmType } from "./Hrm";
import BreadCrumb from "../BreadCrumb";
import { HRMTYPE } from "../../../JSON/enum/Dataenum";
import HrmFunction from "./HrmFunction";

const HrmDetail = () => {
  const locationObj = useLocation();
  const navigate = useNavigate();
  const hrmActions = useHrmActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const auth = useRecoilValue(authAtom);
  let { sn } = useParams();
  const [hrm, setHrm] = useState({});
  const hrmFn = HrmFunction();

  useEffect(() => {
    getHrmDetail(sn);
  }, []);

  const getHrmDetail = async (sn) => {
    const res = await hrmActions.getHrmDetail({ sn });
    if (res.status === 200) {
      setHrm(res.data);
    }
  };

  const changeHrmState = async (data) => {
    const res = await hrmActions.changeHrmState(data);
    if (res.status === 200) {
      setToast({ ...toast, on: true });
      navigate(`/${auth.accountType}/hrm`);
    }
  };

  return (
    <form>
      <BreadCrumb />
      <div className="table-responsive">
        <table className="writetable hide">
          <tbody>
            <tr>
              <th className="text-center-title zoom_font">대상자</th>
              <td className="border-line zoom_font">{hrm.employee_name}</td>
              <th className="text-center-title zoom_font">작성일</th>
              <td className="border-line zoom_font">
                {hrm.createtime && dateUtils.dateToYMD(hrm.createtime)}
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">증명서 종류</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrmFn.getHrmType(hrm.type)}
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">근무부서</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrm.department}
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">직책</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrm.position}
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">생년월일</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrm.birthday}
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">입사일</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrm.joinday}
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">주소</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrm.address}
              </td>
            </tr>
            {/* <tr>
              <th className="text-center-title zoom_font">제출처</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrm.address_submit}
              </td>
            </tr> */}
            <tr>
              <th className="text-center-title zoom_font">용도</th>
              <td className="border-line zoom_font" colSpan="3">
                {hrm.purpose}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-group">
        {(auth.user.isCompany || auth.user.isAdmin) && hrm.state === 0 && (
          <>
            <button
              type="button"
              className="fbtn submitbtn"
              onClick={() => {
                changeHrmState({
                  docsn: hrm.sn,
                  state: APPLICATIONSTATE.APPROVE,
                });
              }}
            >
              승인
            </button>
            <button
              type="button"
              className="fbtn rejectbtn"
              onClick={() => {
                changeHrmState({
                  docsn: hrm.sn,
                  state: APPLICATIONSTATE.REJECT,
                });
              }}
            >
              반려
            </button>
          </>
        )}

        <div className="btn-group">
          {auth.user.isEmployee && hrm.state === 0 && (
            <button
              type="button"
              className="fbtn submitbtn"
              onClick={() => {
                navigate(`/employee/hrm_edit/${sn}`);
              }}
            >
              수정
            </button>
          )}

          <button
            className="fbtn cancelbtn"
            onClick={() => {
              navigate(`/${auth.accountType}/hrm`, {
                state: { page: locationObj?.state?.page },
              });
            }}
          >
            목록
          </button>
        </div>
      </div>
    </form>
  );
};

export default HrmDetail;
