import { useRecoilValue } from "recoil";
import { get, del, post, getPage, put } from ".";
import { authAtom } from "..";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const usePopupActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    createPopup,
    updatePopup,
    getPopupList,
    getPopupView,
    deletePopup,
  };

  async function createPopup(data, url = `${auth.accountType}/popup`) {
    return await post(url, {
      ...data,
    });
  }

  async function updatePopup(data, url = `${auth.accountType}/popup`) {
    return await put(url, {
      ...data,
    });
  }

  async function getPopupList(data) {
    return getPage(
      `admin/popup`,
      auth.user.accountType === ACCOUNTTYPE.ADMIN && {
        ...data,
      }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getPopupView(data) {
    return get(`${auth.accountType}/popup/doc`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function deletePopup(data) {
    return await del(`${auth.accountType}/popup`, { sn: data.docsn })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
};
