import { useRecoilValue } from "recoil";
import { del, get, getPage, post, put } from ".";
import { authAtom } from "..";

const useHrmActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    getCompanyStamp,
    getHrmDetail,
    getHrm,
    hrmRegist,
    updateHrm,
    changeHrmState,
    deleteHrmList,
    getEmployee,
  };
  async function getEmployee(){
    const res = await get(`${auth.accountType}/hrm/info`);
    return res;
  }

  async function getCompanyStamp({ COMP_CD }) {
    const res = await get("sign/company", { COMP_CD });
    return res;
  }
  async function changeHrmState(data) {
    const res = await put(`${auth.accountType}/hrm`, data);
    return res;
  }

  async function deleteHrmList(list) {
    for (let i of list) {
      await del(`${auth.accountType}/hrm`, { docsn: i });
    }
    return true;
  }

  async function getHrmDetail(data) {
    const res = await get(`${auth.accountType}/hrm/application`, {
      docsn: data.sn,
    });
    return res;
  }
  async function getHrm(data) {
    let params = {
      ...data,
    };

    if (auth.user.isEmployee) params.id = auth.user.id;
    if (auth.user.isCompany) params.id = auth.user.id;

    const res = await getPage(`${auth.accountType}/hrm`, params);
    return res;
  }

  async function hrmRegist(data) {
    const res = await post(`${auth.accountType}/hrm/regist`, data);
    return res;
  }

  async function updateHrm(data) {
    const res = await put(`${auth.accountType}/hrm/update`, data);
    return res;
  }
};

export default useHrmActions;
