import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { Link, useNavigate } from "react-router-dom";
import { useWorkGuideActions } from "../../../recoil/api/workGuide";
import BreadCrumb from "../BreadCrumb";
import SearchCompanyModal from "../SearchCompanyModal";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { Editor } from "@tinymce/tinymce-react";
import { fileNameCheck, fileSizeCheck } from "../../../utils";
import { QuillEditor } from "../../toolbar/QuillEditor";

const WorkGuideWrite = () => {
  const navigate = useNavigate();
  const workGuideActions = useWorkGuideActions();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // 파일 상태 값
  const [files, setFiles] = useState([]);

  // (모달) company 검색 상태 값
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);

  // (모달) - searchCompanyModal 상태 값
  const [company, setCompany] = useState({});

  // API 호출 함수 - files 업로드/삭제
  const onchangeFilesHandler = (event) => {
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };

  // useForm
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      editor: "",
      workorderYMD: dayjs().format("YYYY-MM-DD"),
    },
  });

  // Submit 함수
  const onSubmit = async (data) => {
    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const req = {
      COMP_CD: auth.user.isAdmin && company?.COMP_CD,
      title: data.title,
      contents: data.editor,
      file: files,
    };

    if (req.title && (auth.user.isAdmin ? req.COMP_CD : true)) {
      const res = await workGuideActions.createWorkGuide(req);
      if (res.status === 200) {
        setToast({ ...toast, on: true });
        navigate(`/${auth.accountType}/business_guide`);
      } else {
        setToast({ ...toast, error: true, message: "작성 실패" });
      }
    } else {
      setToast({
        ...toast,
        error: true,
        message: "입력하지 않은 항목이 있습니다.",
      });
    }

    setDoubleclickLoading(false);
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table className="writetable hide fixedTh">
            <tbody>
              <tr>
                {auth.user.isAdmin && (
                  <>
                    <th className="text-center-title zoom_font w-20">
                      대상자 기업
                    </th>
                    <td className="w-30">
                      <input
                        className="custom-input border-line mouse zoom_font"
                        type="text"
                        style={{ width: "calc(100% - 90px)" }}
                        value={company?.company_name ?? ""}
                        readOnly
                      />
                      <button
                        className="holidaybtn greenbtn border-line mouse zoom_font"
                        onClick={() => {
                          setIsSearchCompanyModalOpen(true);
                        }}
                        type="button"
                      >
                        기업 검색
                      </button>
                    </td>
                  </>
                )}
                <th className="text-center-title zoom_font w-20">안내자</th>
                <td
                  className={`zoom_font ${auth.user.isAdmin ? "w-30" : "w-80"}`}
                >
                  {auth.user.userName}
                  <div id="divhashlist"></div>
                </td>
                {/* <th className="text-center-title zoom_font">작성일</th>
                <td className="zoom_font">{dayjs().format("YYYY-MM-DD")}</td> */}
              </tr>
              <tr>
                <th className="text-center-title zoom_font">제목</th>
                <td colSpan="3">
                  <input
                    {...register("title")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="제목을 입력해주세요."
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="3">
                  <Controller
                    name="editor"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <QuillEditor value={value} onChange={onChange} />

                      // <Editor
                      //   apiKey={"v6ji2nwc9qbq1zat0pwg4o4kb9tco1wnad8oh2xdplkg6q9z"}
                      //   value={value}
                      //   onEditorChange={onChange}
                      //   init={{
                      //     plugins: ["table", "link", "autolink", "contextmenu"],
                      //     language: "ko_KR",
                      //     selector: "div",
                      //     elementpath: false,
                      //     branding: false,
                      //     autolink: true,
                      //     toolbar:
                      //       "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                      //     contextmenu: "true",
                      //     menubar: "file insert view format table",
                      //   }}
                      // />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">첨부파일</th>
                <td colSpan="3">
                  <span className="filetype">
                    <span className="file-select border-line mouse zoom_font">
                      <input
                        {...register("file")}
                        type="file"
                        multiple="multiple"
                        className="input-file"
                        onChange={onchangeFilesHandler}
                      />
                    </span>
                    <span className="file-btn zoom_font">+ 파일선택</span>

                    <div className="file-list">
                      {files.length > 0 &&
                        files.map((ele) => {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeFile(ele.id);
                              }}
                              key={ele.id}
                              type="text"
                              className="file-text"
                            >
                              {ele.file.name}
                              <FileDeleteBtn />
                            </div>
                          );
                        })}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table className="writetable mobile-table">
          <tbody></tbody>
        </table>
        <div className="btn-group">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>
          <Link to={`/${auth.accountType}/business_guide`}>
            <button
              className="writebtn cancelbtn border-line mouse zoom_font"
              type="button"
            >
              취소
            </button>
          </Link>
        </div>
      </form>
      {/* (모달) - 기업 검색, 관리자 계정에서 활성화 */}
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
    </div>
  );
};

export default WorkGuideWrite;
