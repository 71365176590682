import IdPop from "../../../img/id_pop.png";

const IdFound = ({
  id,
  setId,
  companyId,
  setCompanyId,
  setOpen,
  setFindPasswordModalOpen,
}) => {
  // const replacedId = id.slice(0, -3) + "***";
  return (
    <div>
      <div id="comm_layer" className="pop-con">
        <form id="SearchForm" name="SearchForm">
          <div className="pop-con">
            <div className="padt30"></div>
            <div className="id_tit idpass">
              <span>
                <img src={IdPop} alt="아이디아이콘" />
              </span>
              <div className="text textin">
                <strong>아이디 찾기가 완료되었습니다.</strong>
                <p>입력하신 정보와 일치하는 아이디는 다음과 같습니다.</p>
              </div>
            </div>
            <div className="lotable id-complete">
              {id.map((getId, index) => {
                return (
                  <p key={index}>
                    근로자 아이디 : <span>{getId.id.slice(0, -3) + "***"}</span>
                  </p>
                );
              })}
              {companyId.map((getId, index) => {
                return (
                  <p key={index}>
                    기업 아이디 : <span>{getId.id.slice(0, -3) + "***"}</span>
                  </p>
                );
              })}
            </div>
            <div className="s-button cf btn-group">
              <button
                className="sc"
                type="button"
                onClick={() => {
                  setOpen(false);
                  setId("");
                  setCompanyId("");
                  setFindPasswordModalOpen(true);
                }}
              >
                비밀번호 찾기
              </button>
              <button
                type="button"
                className="id_sear id_width sc"
                onClick={() => {
                  setOpen(false);
                  setId("");
                  setCompanyId("");
                }}
              >
                닫기
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IdFound;
