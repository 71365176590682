import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import useSearchActions from "../../recoil/api/search";
import { ReactComponent as CheckOn } from "../../../src/img/check-on.svg";
import { ReactComponent as CheckOff } from "../../../src/img/check-off.svg";
import { searchRegex } from "../../utils/regex";

export const SelectManagerPayment = ({
  setValue,
  open,
  selectedEmployee,
  onChangeModalState,
  COMP_CD = null,
}) => {
  const searchActions = useSearchActions();
  const [employeeList, setEmployeeList] = useState([]);
  const [CompanyEmployees, setCompanyEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (open) {
      if (COMP_CD) {
        getEmployeeByCOMPCD(COMP_CD);
      } else {
        getEmployeeByCompanyId();
      }
    }
  }, [open]);

  const setEmployee = (employee) => {
    setValue("employee", employee);
  };

  const getEmployeeByCompanyId = async () => {
    setLoading(true);
    const res = await searchActions.getEmployeeByCompanyId();
    if (res.status === 200) {
      setCompanyEmployees(res.data);
    }
    setLoading(false);
  };

  const getEmployeeByCOMPCD = async (COMP_CD) => {
    setLoading(true);
    const res = await searchActions.getEmployeeByCOMPCD(COMP_CD);
    if (res.status === 200) {
      setCompanyEmployees(res.data);
    }
    setLoading(false);
  };

  return (
    <div>
      <div
        className="of-auto"
        style={{ margin: "-20px -30px", marginBottom: 0 }}
      >
        <div id="member-left" className="float-left" style={{ width: "100%" }}>
          <input
            className="gray-input"
            placeholder="멤버검색"
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            onInput={(e) => {
              if (searchRegex.test(e.target.value)) {
                e.target.value = e.target.value.replace(searchRegex, "");
              }
            }}
          />
          <div className="inputsearch">
            <button className="person-searchbtn"></button>
          </div>
          <div className="person-choice pointer">
            <div
              style={{ marginLeft: "95%" }}
            >{`${CompanyEmployees?.length}명`}</div>
          </div>
          <div className="person-scroll scroll-200 text-center">
            {loading && <ClipLoader />}
            {
              <>
                {CompanyEmployees?.filter((oldMembers) => {
                  return oldMembers.employee_name.includes(keyword);
                }).map((employee) => {
                  return (
                    <div
                      className="person-choice pointer"
                      key={employee.sn}
                      onClick={() => {
                        setEmployee(employee);
                      }}
                    >
                      <div>
                        <div className="person-circle">
                          <div className="green-circle"></div>
                        </div>
                        <span className="person-name">{`${
                          employee.employee_department || ""
                        } ${employee.employee_name} (${employee.id})`}</span>
                      </div>
                      {selectedEmployee?.esn === employee.esn ? (
                        <CheckOn />
                      ) : (
                        <CheckOff />
                      )}
                    </div>
                  );
                })}
              </>
            }
          </div>
        </div>
        <div
          id="member-right"
          className="float-left person-scroll scroll-345"
          style={{ width: "40%", display: "none" }}
        >
          {employeeList.map((employee) => {
            return (
              <div className="persin-pill" key={employee.sn}>
                <div className="person-circle-gray"></div>
                <p className="person-name-choice">{`${employee?.employee_name}`}</p>
                <button className="choice-cancel" type="button">
                  x
                </button>
              </div>
            );
          })}
        </div>
        <div
          className="float-left person-scroll scroll-285"
          style={{ width: "40%" }}
        ></div>
      </div>
      <div id="modalfoot">
        <div className="btn-group">
          <button
            className="fbtn submitbtn"
            type="button"
            onClick={() => {
              onChangeModalState();
            }}
          >
            저장
          </button>
          <button
            className="fbtn cancelbtn"
            onClick={() => onChangeModalState()}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};
