import { useState, useEffect, useRef } from "react";
import SearchByDate from "../SearchByDate";
import DataTable from "../DataTable";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import PaginationBar from "../PaginationBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBoardNoticeActions } from "../../../recoil/api/boardNotice";
import BreadCrumb from "../BreadCrumb";
import { downloadFiles } from "../../../utils";
import { overMaxLength } from "../../../utils";
import { DateState } from "../../../recoil/types/data";
import dayjs from "dayjs";

const BoardNotice = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 로딩 상태 값
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(true);

  // 페이지네이션 상태 값
  const [pageInfo, setPageInfo] = useState({
    current: 1,
  });

  // 날짜 검색 상태 값
  const [dateAndKeyword, setDateAndKeyword] = useState(DateState);

  // 체크 박스 상태 값
  const [checkedItemIds, setCheckedItemIds] = useState([]);

  // 체크 박스 핸들러
  const onChangeCheckBoxHandler = (checked, id) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, id]);
    } else {
      setCheckedItemIds(checkedItemIds.filter((el) => el !== id));
    }
  };

  // API 요청 함수 - workReportList 데이터 불러오기
  const boardNoticeActions = useBoardNoticeActions();
  const [timer, setTimer] = useState(0);

  //debouncer api 요청이 연속으로 들어오면 마지막 api만 처리함
  const debouncer = (api) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(async () => {
      api();
    }, 100);

    setTimer(newTimer);
  };

  const loadBoardNoticeList = async () => {
    setLoading(true);
    if (state?.prev) {
      const req = {
        dateAndKeyword: state?.keyword,
        pageInfo: state?.page,
      };
      const res = await boardNoticeActions.getBoardNoticeList(req);

      if (res.status === 200) {
        setLoadedData(res.data.list);
        setPageInfo({ ...res.data.pageinfo, current: pageInfo.current });
      }
      state.prev = false;
    } else {
      const req = {
        dateAndKeyword,
        pageInfo,
      };
      const res = await boardNoticeActions.getBoardNoticeList(req);

      if (res.status === 200) {
        setLoadedData(res.data.list);
        setPageInfo({ ...res.data.pageinfo, current: pageInfo.current });
      }
    }
    setLoading(false);
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      debouncer(loadBoardNoticeList);
    }
  }, [pageInfo.current, dateAndKeyword]);

  // API 요청 함수 - boardNotice 데이터 삭제하기
  const deleteBoardNotice = async (checkedItemIds) => {
    for (let i = 0; i < checkedItemIds.length; i++) {
      const req = { docsn: checkedItemIds[i] };
      // 파일 없어도 deletefile api 호출하는 쪽으로 일단 작성
      const res = await boardNoticeActions.deleteBoardNotice(req);
    }
    setCheckedItemIds([]);
    loadBoardNoticeList();
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          {/* 글 생성/삭제 버튼 - 기업, 관리자 계정일 때 활성화 */}
          {
            // auth.user.isAdmin ||
            auth.user.isCompany && (
              <div>
                <Link to={`/${auth.accountType}/notice_write`}>
                  <button
                    className="holidaybtn greenbtn border-line mouse zoom_font"
                    type="button"
                  >
                    작성하기
                  </button>
                </Link>
                <button
                  className="trashbtn border-line mouse"
                  type="button"
                  onClick={() => {
                    if (window.confirm("삭제하시겠습니까?")) {
                      deleteBoardNotice(checkedItemIds);
                    } else {
                      return false;
                    }
                  }}
                ></button>
              </div>
            )
          }
        </div>

        <SearchByDate
          period={30}
          defaultValue={state?.keyword?.keyword}
          defaultStarttime={
            state?.keyword?.starttime === undefined
              ? undefined
              : dayjs(state?.keyword.starttime)
          }
          defaultEndtime={
            state?.keyword?.endtime === undefined
              ? undefined
              : dayjs(state?.keyword.endtime)
          }
          onChange={(value) => setDateAndKeyword(value)}
          loading={loading}
        />
      </div>
      <DataTable
        loadedData={loadedData}
        loading={loading}
        setCheckedItemIds={setCheckedItemIds}
        checkbox={
          // auth.user.isAdmin ||
          auth.user.isCompany // admin 계정 생성 시에 해제
        }
        columns={["제목", "작성자", "등록일", "첨부파일"]}
      >
        {loadedData?.map((obj) => {
          return (
            <tr
              className="text-center"
              key={obj?.sn}
              onClick={() => {
                navigate(`/${auth.accountType}/notice_view/${obj?.sn}`, {
                  state: {
                    page: pageInfo,
                    keyword: dateAndKeyword,
                  },
                });
              }}
            >
              {/* 체크 박스 - 근로자 계정일 때, 활성화 */}
              {
                // auth.user.isAdmin ||
                auth.user.isCompany && ( // admin 계정 생성 시에 해제
                  <td className="hide" onClick={(e) => e.stopPropagation()}>
                    <div className="agree">
                      <label>
                        <input
                          type="checkbox"
                          name="personinfo"
                          onChange={(e) => {
                            onChangeCheckBoxHandler(
                              e.currentTarget.checked,
                              obj.sn
                            );
                          }}
                          checked={
                            checkedItemIds?.includes(obj.sn) ? true : false
                          }
                        />
                        {checkedItemIds?.includes(obj.sn) ? (
                          <div className="chkbox border-line mouse checked"></div>
                        ) : (
                          <div className="chkbox border-line mouse "></div>
                        )}
                      </label>
                    </div>
                  </td>
                )
              }
              <td id="_hash" style={{ display: "none" }}></td>

              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {overMaxLength(obj?.title, 30)}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.id}
              </td>
              <td
                className="border-line mouse zoom_font"
                style={{ cursor: "pointer" }}
              >
                {obj?.createtime?.slice(0, 10)}
              </td>
              {obj?.url >= 1 ? (
                <td onClick={(e) => e.stopPropagation()}>
                  <button
                    className="t-download border-line mouse zoom_font"
                    type="button"
                    onClick={() => {
                      downloadFiles(
                        obj,
                        boardNoticeActions.getBoardNoticeFileUrl,
                        { docsn: obj.sn },
                        `공지사항_`,
                        0
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </td>
              ) : (
                <td style={{ cursor: "pointer" }} />
              )}
            </tr>
          );
        })}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default BoardNotice;
