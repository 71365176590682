import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import useSearchActions from "../../recoil/api/search";
import { ReactComponent as CheckOn } from "../../../src/img/check-on.svg";
import { ReactComponent as CheckOff } from "../../../src/img/check-off.svg";
import CommonModal from "../modal/CommonModal";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../recoil";
import { overMaxLength } from "../../utils";
import { keeper } from "./Schedule/ScheduleRegistrationDetail";
import { searchRegex } from "../../utils/regex";

export const SelectManagerList = ({
  open,
  selectedEmployeeList,
  setSelectedEmployeeList,
  selectedEmployeeEsnList,
  setSelectedEmployeeEsnList,
  onChangeModalState,
  COMP_CD,
  selfSelect,
}) => {
  const auth = useRecoilValue(authAtom);
  const [selfState, setSelfState] = useState(false);
  const searchActions = useSearchActions();
  const [companyEmployeeList, setCompanyEmployeeList] = useState([]);
  const [newSelectedEmployeeList, setNewSelectedEmployeeList] =
    useState(selectedEmployeeList);
  const [newSelectedEmployeeEsnList, setNewSelectedEmployeeEsnList] = useState(
    selectedEmployeeEsnList
  );
  const [searchWords, setSearchWords] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      if (!!COMP_CD) {
        getEmployeeByCOMPCD(COMP_CD);
      } else {
        getEmployeeByCompanyId();
      }
      setNewSelectedEmployeeList(selectedEmployeeList);
      setNewSelectedEmployeeEsnList(selectedEmployeeEsnList);
    }
  }, [open]);

  const getEmployeeByCompanyId = async () => {
    setLoading(true);
    if (selfSelect === true && auth.user.isCompany === true) {
      const res = await searchActions.getECompanyAllUserByCOMPCD();
      if (res.status === 200) {
        setCompanyEmployeeList(res.data);
      }
    } else {
      const res = await searchActions.getEmployeeByCompanyId();
      if (res.status === 200) {
        setCompanyEmployeeList(res.data);
      }
    }
    setLoading(false);
  };

  // 관리자용 API
  const getEmployeeByCOMPCD = async (COMP_CD) => {
    setLoading(true);
    if (selfSelect === true || auth.user.isAdmin === true) {
      const res = await searchActions.getEmployeeByCOMPCDAdmin(COMP_CD);
      if (res.status === 200) {
        setCompanyEmployeeList(res.data);
      }
    } else {
      const res = await searchActions.getEmployeeByCOMPCD(COMP_CD);
      if (res.status === 200) {
        setCompanyEmployeeList(res.data);
      }
    }
    setLoading(false);
  };

  return (
    <CommonModal
      title={keeper}
      open={open}
      onChangeModalState={onChangeModalState}
    >
      <div>
        <div
          className="of-auto"
          style={{ margin: "-20px -30px", marginBottom: 0 }}
        >
          <div id="member-left" className="float-left" style={{ width: "60%" }}>
            <input
              className="gray-input"
              placeholder="멤버검색"
              value={searchWords}
              onChange={(e) => {
                setSearchWords(e.target.value);
              }}
              onInput={(e) => {
                if (searchRegex.test(e.target.value)) {
                  e.target.value = e.target.value.replace(searchRegex, "");
                }
              }}
            />
            <div className="inputsearch">
              <button className="person-searchbtn"></button>
            </div>
            <div className="person-choice pointer">
              <div style={{ marginLeft: "90%" }}>{`${
                companyEmployeeList.company
                  ? companyEmployeeList.company.length +
                    companyEmployeeList.employee.length
                  : companyEmployeeList?.length
              }명`}</div>
            </div>
            <div className="person-scroll scroll-200 text-center">
              {loading && <ClipLoader />}
              {(companyEmployeeList?.employee
                ? companyEmployeeList?.employee
                : companyEmployeeList
              )
                ?.filter((employee) =>
                  employee?.employee_name?.includes(searchWords)
                )
                ?.map((employee) => {
                  if (selfState === false && selfSelect === true) {
                    if (auth.user.id === employee?.id) {
                      setNewSelectedEmployeeEsnList([
                        ...newSelectedEmployeeEsnList,
                        employee?.esn,
                      ]);
                      setNewSelectedEmployeeList([
                        ...newSelectedEmployeeList,
                        employee,
                      ]);
                      setSelfState(true);
                    }
                  }
                  return (
                    <div
                      className="person-choice pointer"
                      key={employee?.esn}
                      onClick={() => {
                        if (auth.user.id === employee?.id) {
                          setSelfState(false);
                        }
                        if (
                          newSelectedEmployeeEsnList.includes(employee?.esn)
                        ) {
                          setNewSelectedEmployeeEsnList(
                            newSelectedEmployeeEsnList.filter(
                              (ele) => ele !== employee?.esn
                            )
                          );
                          setNewSelectedEmployeeList(
                            newSelectedEmployeeList.filter(
                              (ele2) => ele2.esn !== employee.esn
                            )
                          );
                        } else {
                          setNewSelectedEmployeeEsnList([
                            ...newSelectedEmployeeEsnList,
                            employee?.esn,
                          ]);
                          setNewSelectedEmployeeList([
                            ...newSelectedEmployeeList,
                            employee,
                          ]);
                        }
                      }}
                    >
                      <div>
                        <div className="person-circle">
                          <div className="green-circle"></div>
                        </div>
                        <span className="person-name">{`
                          ${employee?.employee_name}
                         (${overMaxLength(employee?.id, 10)})`}</span>
                      </div>
                      {newSelectedEmployeeEsnList?.includes(employee.esn) ? (
                        <CheckOn />
                      ) : (
                        <CheckOff />
                      )}
                    </div>
                  );
                })}
              {(companyEmployeeList?.company
                ? companyEmployeeList?.company
                : null
              )
                ?.filter((company) =>
                  company?.companyAccount_name?.includes(searchWords)
                )
                ?.map((company) => {
                  if (selfState === false && selfSelect === true) {
                    if (auth.user.id === company?.id) {
                      setNewSelectedEmployeeList([
                        ...newSelectedEmployeeList,
                        company,
                      ]);
                      setNewSelectedEmployeeEsnList([
                        ...newSelectedEmployeeEsnList,
                        company?.csn,
                      ]);
                      setSelfState(true);
                    }
                  }

                  return (
                    <div
                      className="person-choice pointer"
                      key={company?.csn}
                      onClick={() => {
                        if (auth.user.id === company?.id) {
                          setSelfState(false);
                        }
                        if (newSelectedEmployeeEsnList.includes(company?.csn)) {
                          setNewSelectedEmployeeEsnList(
                            newSelectedEmployeeEsnList.filter(
                              (ele) => ele !== company?.csn
                            )
                          );
                          setNewSelectedEmployeeList(
                            newSelectedEmployeeList.filter(
                              (ele2) => ele2.esn !== company.esn
                            )
                          );
                        } else {
                          setNewSelectedEmployeeEsnList([
                            ...newSelectedEmployeeEsnList,
                            company?.csn,
                          ]);
                          setNewSelectedEmployeeList([
                            ...newSelectedEmployeeList,
                            company,
                          ]);
                        }
                      }}
                    >
                      <div>
                        <div className="person-circle">
                          <div className="green-circle"></div>
                        </div>
                        <span className="person-name">{`
                          ${company?.companyAccount_name}
                         (${overMaxLength(company?.id, 15)})`}</span>
                      </div>

                      {newSelectedEmployeeEsnList?.includes(company.csn) ? (
                        <CheckOn />
                      ) : (
                        <CheckOff />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>

          <div
            id="member-right"
            className="float-left person-scroll scroll-345"
            style={{ width: "40%" }}
          >
            {newSelectedEmployeeList?.map((employee, index) => {
              return (
                <div className="persin-pill" key={index}>
                  <div className="person-circle-gray"></div>
                  <p className="person-name-choice">
                    {employee?.companyAccount_name
                      ? employee?.companyAccount_name
                      : employee?.employee_name}
                  </p>
                  <button
                    className="choice-cancel"
                    type="button"
                    onClick={() => {
                      if (
                        auth.user.id === employee?.id &&
                        selfSelect === true
                      ) {
                      } else {
                        setNewSelectedEmployeeEsnList(
                          newSelectedEmployeeEsnList.filter((ele) =>
                            employee.csn
                              ? ele !== employee.csn
                              : ele !== employee.esn
                          )
                        );
                        setNewSelectedEmployeeList(
                          newSelectedEmployeeList.filter((ele2) =>
                            employee.csn
                              ? ele2.csn !== employee.csn
                              : ele2.esn !== employee.esn
                          )
                        );
                      }
                    }}
                  >
                    x
                  </button>
                </div>
              );
            })}
          </div>
          <div
            className="float-left person-scroll scroll-285"
            style={{ width: "40%" }}
          ></div>
        </div>
        <div id="modalfoot">
          <p align="right">선택 총 {newSelectedEmployeeList.length}명</p>
          <div className="btn-group">
            <button
              className="fbtn submitbtn"
              type="button"
              onClick={() => {
                setSelectedEmployeeEsnList(newSelectedEmployeeEsnList);
                setSelectedEmployeeList(newSelectedEmployeeList);
                onChangeModalState();
              }}
            >
              저장
            </button>
            <button
              className="fbtn cancelbtn"
              onClick={() => {
                setNewSelectedEmployeeEsnList([]);
                setNewSelectedEmployeeList([]);
                onChangeModalState();
              }}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};
