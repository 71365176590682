import { useRecoilState } from "recoil";
import { AccessibilibyAtom } from "../../recoil";
import Draggable from "react-draggable";

const Accessibility = ({ setIsAccessOpen }) => {
  const [accessibility, setAccessibility] = useRecoilState(AccessibilibyAtom);
  return (
    <Draggable handle=".tit">
      <div
        className="cal-popup"
        id="damyMol"
        style={{
          position: "absolute",
          top: "40%",
          left: "68%",
          display: "block",
        }}
      >
        <div className="tit" style={{ cursor: "move" }}>
          <h5 style={{ color: "black" }}>특화 기능 설정</h5>
          <button
            className="close"
            style={{ border: "none" }}
            onClick={() => setIsAccessOpen(false)}
          >
            X
          </button>
        </div>
        <div id="comm_layer" className="pop-con">
          <div className="access_l">
            <h3>확대기능</h3>
            <ul className="cf">
              <li>
                <p>화면 확대</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="zoom"
                    checked={accessibility.zoom}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <li>
                <p>마우스포인터</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="pointer"
                    checked={accessibility.pointer}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        grayPointer: false,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <li>
                <p>흑백포인터</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="grayPointer"
                    checked={accessibility.grayPointer}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        pointer: false,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
            </ul>
          </div>
          <div className="access_l">
            <h3>읽기기능</h3>
            <ul className="cf">
              <li>
                <p>읽기 안내 선</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="guideLine"
                    checked={accessibility.guideLine}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <li>
                <p>읽기 마스크</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="mask"
                    checked={accessibility.mask}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <li>
                <p>글자 돋보기</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="zoomLetter"
                    checked={accessibility.zoomLetter}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
            </ul>
          </div>
          <div className="access_l">
            <h3>영역표시기능</h3>
            <ul className="cf">
              <li>
                <p>전체영역표시</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="allArea"
                    checked={accessibility.allArea}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <li>
                <p>선택영역표시</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="selectedArea"
                    checked={accessibility.selectedArea}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <li>
                <p>흑백화면보기</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="grayscale"
                    checked={accessibility.grayscale}
                    onChange={(e) =>
                      setAccessibility({
                        ...accessibility,
                        [e.target.name]: !accessibility[e.target.name],
                      })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Accessibility;
