import { Outlet } from "react-router-dom";
import Header from "./Header";

const ElecLayout = () => {
  return (
    <>
      <Header />
      <div className="section">
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default ElecLayout;
