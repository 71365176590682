import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useWorkReportActions } from "../../../recoil/api/workReport";
import { downloadFile } from "../../../utils";
import BreadCrumb from "../BreadCrumb";
import { REPORTTYPE } from "../../../JSON/enum/Dataenum";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

const WorkReportView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useRecoilValue(authAtom);
  const [workReportData, setWorkReportData] = useState();
  const [fileUrlData, setFileUrlData] = useState([]);
  const [snCurrent, setSnCurrent] = useState(0);
  const [snFirst, setSnFirst] = useState(false);
  const [snLast, setSnLast] = useState(false);
  const params = useParams();

  useEffect(() => {
    let indexNumber = state?.sn?.findIndex(
      (element, index, arr) => element === state?.currentSn
    );
    setSnCurrent(indexNumber);
    if (indexNumber === 0) {
      setSnFirst(true);
    } else {
      setSnFirst(false);
    }
    if (indexNumber === state?.sn?.length - 1) {
      setSnLast(true);
    } else {
      setSnLast(false);
    }
    loadWorkReportView({ docsn: state?.currentSn });
  }, [state]);
  // API 요청 함수 - workReport 상세 데이터 불러오기
  const workReportActions = useWorkReportActions();

  const loadWorkReportView = async (req) => {
    const res = await workReportActions.getWorkReportView(req);
    if (res.status === 200) {
      setWorkReportData(() => res.data);
    }
    const res2 = await workReportActions.getWorkReportFileUrl(req);
    setFileUrlData(res2.data);
  };

  useEffect(() => {
    loadWorkReportView({ docsn: params.sn });
  }, []);

  return (
    <div className="substanceCont">
      <BreadCrumb
        workReport={
          REPORTTYPE.DAYILY == workReportData?.type
            ? "일일 "
            : REPORTTYPE.WEEKILY == workReportData?.type
            ? "주간 "
            : REPORTTYPE.MONTHLIY == workReportData?.type && "월간 "
        }
      />
      <div className="table-responsive">
        <table className="writetable hide">
          <tbody>
            {/* <tr>
              <th className="text-center-title"></th>
              <th className="text-center-title zoom_font" colSpan={3}>
                일일 업무보고
              </th>
            </tr> */}
            <tr>
              <th className="text-center-title w-20 zoom_font">작성자</th>
              <td className="zoom_font">{workReportData?.id}</td>
              <th className="text-center-title w-20 zoom_font">작성일</th>
              <td className="zoom_font">
                {dayjs(workReportData?.work_report_ymd).format("YYYY-MM-DD")}
              </td>
            </tr>
            <tr style={{ display: "none" }}>
              <th className="text-center-title zoom_font">제목</th>
              <td colSpan="3" className="zoom_font">
                {workReportData?.work_report_title}
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">내용</th>
              <td colSpan={3} className="content-td-height">
                <ul className="circle">
                  <div
                    className="zoom_font_id ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        workReportData?.work_report_contents
                      ),
                    }}
                  ></div>
                </ul>
              </td>
            </tr>
            <tr>
              <th className="text-center-title zoom_font">첨부파일</th>
              <td colSpan={3}>
                <div className="border-line mouse zoom_font" href="#">
                  {fileUrlData?.map((value, index) => (
                    <div key={index}>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          try {
                            downloadFile(value);
                          } catch {
                            alert(
                              "서버 이전으로 자료를 옮기고 있습니다.\n빠른 시일내로 정상화 하겠습니다."
                            );
                          }
                        }}
                        download
                      >
                        {`${value?.filename}.${value?.extension}`}
                      </a>
                      <br />
                    </div>
                  ))}
                </div>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="writebtnbox">
        <button
          disabled={snFirst}
          className={`writebtn ${
            snFirst === true ? "cancelbtn" : "submitbtn"
          } border-line mouse zoom_font`}
          type="button"
          id="workreport"
          onClick={() => {
            navigate(
              `/${auth.accountType}/workreport_view/${state.sn[snCurrent - 1]}`,
              {
                state: {
                  page: state?.page,
                  sn: state.sn,
                  currentSn: state.sn[snCurrent - 1],
                  keyword: state?.keyword,
                  select: state?.select,
                  prev: true,
                },
              }
            );
          }}
        >
          이전글
        </button>
        <button
          disabled={snLast}
          className={`writebtn ${
            snLast === true ? "cancelbtn" : "submitbtn"
          } border-line mouse zoom_font`}
          type="button"
          id="workreport"
          onClick={() => {
            navigate(
              `/${auth.accountType}/workreport_view/${state.sn[snCurrent + 1]}`,
              {
                state: {
                  page: state?.page,
                  sn: state.sn,
                  currentSn: state.sn[snCurrent + 1],
                  keyword: state?.keyword,
                  select: state?.select,
                  prev: true,
                },
              }
            );
          }}
        >
          다음글
        </button>
        {!auth.user.isCompany && (
          <Link to={`/${auth.accountType}/workreport_edit/${params.sn}`}>
            <button
              className="writebtn submitbtn border-line mouse zoom_font"
              type="button"
            >
              수정하기
            </button>
          </Link>
        )}
        <button
          className="writebtn cancelbtn border-line mouse zoom_font"
          type="button"
          id="workreport"
          onClick={() => {
            navigate(`/${auth.accountType}/workreport`, {
              state: {
                page: state?.page,
                keyword: state?.keyword,
                select: state?.select,
                prev: true,
              },
            });
          }}
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default WorkReportView;
