import { useEffect, useState } from "react";
import "../../css/toast.css";
import dreamCafe from "../../img/dream_cafe.png";
const CommonImageModal = ({
  open,
  setOpen = () => {},
  onChangeModalState = () => {},
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    onChangeModalState(false);
  };
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div>
      <div style={{ display: isOpen ? "block" : "none" }} id="modal">
        <div id="modalwin" style={{ position: "relative" }}>
          <button
            id="modalclose"
            style={{ margin: "16px 16px", position: "absolute", right: 0 }}
            onClick={() => closeModal()}
          >
            &times;
          </button>

          <img
            src={dreamCafe}
            // id="modalsec"
            // className="dream_cafe"
            onClick={() => window.open("https://cafe.naver.com/vdreamhr")}
            aria-label="드림터 바로가기"
            style={{
              width: "100%",
              height: "auto",
              cursor: "pointer",
              borderRadius: "16px",
              display: "inline",
            }}
          />
          {/* <div id="modaltable ">{children}</div> */}

          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  );
};

export default CommonImageModal;
