import { SERVEREEOR } from "../JSON/enum/servererr";

export const errorcase = (error) => {
  switch (error) {
    case SERVEREEOR.DB:
      return "데이터베이스 에러입니다.";
    case SERVEREEOR.SERVERERR:
      return "서버 에러입니다.";
    case SERVEREEOR.WRONGPATH:
      return "잘못된 경로입니다.";
    case SERVEREEOR.NOTFOUND:
      return "데이터를 찾을 수 없습니다.";
    case SERVEREEOR.DBINSERTFAIL:
      return "데이터베이스에 값을 쓰지 못했습니다.";
    case SERVEREEOR.REQUIREACCEPT:
      return "require accept";
    case SERVEREEOR.ALREADYEXIST:
      return "값이 이미 존재합니다.";
    case SERVEREEOR.DOESNOTMATH:
      return "값이 일치하지 않습니다.";
    case SERVEREEOR.NOTEXISTDATA:
      return "값이 존재하지 않습니다.";
    case SERVEREEOR.INCORRECTDATE:
      return "유효하지 않은 날짜입니다.";
    case SERVEREEOR.REQUIREAPPROVE:
      return "필수 값이 없습니다.";
    default:
      return "알수없는 에러입니다.";
  }
};
