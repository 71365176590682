import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import useManagementActions from "../../../recoil/api/management";
import { PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import MenuUpdateModal from "../management/MenuUpdateModal";
import SearchKeyword from "../../common/SearchKeyword";

const MenuConfig = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);

  const { getMenuSettings } = useManagementActions();
  const [menuList, setMenuList] = useState([]);
  const [menuSelect, setMenuSelect] = useState({});
  const [keyword, setKeyword] = useState("");

  const getMenuSetting = async () => {
    setLoading(true);
    const res = await getMenuSettings({ pageInfo, keyword });
    setPageInfo(() => res?.data.pageinfo);
    setMenuList(() => res?.data.list);
    setLoading(false);
  };

  useEffect(() => {
    getMenuSetting(pageInfo);
  }, [pageInfo?.current, modal, keyword]);

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering" style={{ justifyContent: "end" }}>
        <div className="all-group"></div>
        {/* 코드명을 기준으로 검색하는 API가 필요함 */}
        <SearchKeyword placeholder="하위메뉴명" setKeyword={setKeyword} />
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={["상위메뉴", "하위메뉴", "표시명", "비고"]}
      >
        {menuList?.map((obj) => (
          <tr
            key={obj?.sn}
            className="text-center"
            onClick={() => {
              setModal(true);
              setMenuSelect(obj);
            }}
          >
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj?.catagory}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj?.name}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj?.text}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj?.etc}
            </td>
          </tr>
        ))}
      </DataTable>
      <MenuUpdateModal
        common={menuSelect}
        title="메뉴이름 편집"
        open={modal}
        setOpen={setModal}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default MenuConfig;
