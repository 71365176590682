import dayjs from "dayjs";
import CommonModal from "../../modal/CommonModal";

const CalendarModal = (props) => {
  const stHour =
    Number(`${props.workInfo.working_starttime_string?.slice(0, 2)}`) * 60;
  const stMinute = Number(
    `${props.workInfo.working_starttime_string?.slice(2, 4)}`
  );
  const endHour =
    Number(`${props.workInfo.working_endtime_string?.slice(0, 2)}`) * 60;
  const endMinute = Number(
    `${props.workInfo.working_endtime_string?.slice(2, 4)}`
  );

  const timeChange = (start, end) => {
    let hour;
    let minute;

    hour = parseInt(
      ((start - end) / 1000 / 60 -
        (endHour + endMinute - (stHour + stMinute))) /
        60
    );

    minute = parseInt(
      (start - end) / 1000 / 60 -
        (endHour + endMinute - (stHour + stMinute)) -
        hour * 60
    );

    if (hour <= 10) {
      return `${String(hour).padStart(2, "0")} : ${String(minute).padStart(
        2,
        "0"
      )}`;
    } else {
      return `${String(hour)} : ${String(minute).padStart(2, "0")}`;
    }
  };

  return (
    <CommonModal {...props}>
      <table>
        <tbody>
          <tr></tr>
          <tr>
            <th className="text-center-title zoom_font">날짜</th>
            <td>
              <div className="input-group">
                <p
                  style={{ width: "100%" }}
                  disabled
                  className="form-control border-line"
                >
                  {props?.date?.slice(0, 10)}{" "}
                  {dayjs(props?.date).day() === 0
                    ? "(일)"
                    : dayjs(props?.date).day() === 1
                    ? "(월)"
                    : dayjs(props?.date).day() === 2
                    ? "(화)"
                    : dayjs(props?.date).day() === 3
                    ? "(수)"
                    : dayjs(props?.date).day() === 4
                    ? "(목)"
                    : dayjs(props?.date).day() === 5
                    ? "(금)"
                    : "(토)"}
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <th className="text-center-title zoom_font">출근</th>
            <td>
              <div className="input-group">
                <p
                  style={{ width: "100%" }}
                  disabled
                  className="form-control border-line"
                >
                  {props.detail[0]?.stampTime?.slice(10, 19)}
                </p>
              </div>
            </td>
          </tr>
          {props.detail[1] && (
            <tr>
              <th className="text-center-title zoom_font">퇴근</th>
              <td>
                <div className="input-group">
                  <p
                    style={{ width: "100%" }}
                    disabled
                    className="form-control border-line"
                  >
                    {props.detail[1]?.stampTime?.slice(10, 19)}
                  </p>
                </div>
              </td>
            </tr>
          )}
          {(props.detail[0]?.stampType === 1 ||
            props.detail[1]?.stampType === 5) &&
            new Date(
              new Date(props.detail[1]?.stampTime).getTime() -
                new Date(props.detail[0]?.stampTime).getTime()
            ) /
              1000 /
              60 >
              endHour + endMinute - (stHour + stMinute) && (
              <tr>
                <th className="text-center-title zoom_font">연장 근무</th>
                <td>
                  <div className="input-group">
                    <p
                      style={{ width: "100%" }}
                      disabled
                      className="form-control border-line"
                    >
                      {timeChange(
                        new Date(props.detail[1]?.stampTime).getTime(),
                        new Date(props.detail[0]?.stampTime).getTime()
                      )}
                    </p>
                  </div>
                </td>
              </tr>
            )}
        </tbody>
      </table>
      <div id="modalfoot"></div>
    </CommonModal>
  );
};

export default CalendarModal;
