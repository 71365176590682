import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useAccoutTypeActions } from "../../../recoil/api/auth";
import { getHolidayType, useHolidayActions } from "../../../recoil/api/holiday";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import { annualOptions } from "../../employee/TNAManagement/EmployeeVacation";
import CommonModal from "../../modal/CommonModal";
import { isReasonOverMaxLength } from "./Reason";
import VacationPrintButton from "../../common/TNAManagement/VacationPrintButton";
import XlsxDownload from "../../form/XlsxDownload";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { useRecoilState } from "recoil";
import { HOLIDAYTYPE } from "../../../JSON/enum/Dataenum";
import VacationFunction from "./VacationFunction";

const CompanyVacation = () => {
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [date, setDate] = useState({
    ...DateState,
    starttime: dayjs().subtract(30, "day").format("YYYYMMDD"),
    endtime: dayjs().add(30, "day").format("YYYYMMDD"),
  });
  const [company, setCompany] = useState({});
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const accountType = useAccoutTypeActions();
  const holidayActions = useHolidayActions();
  const [applicationList, setApplicationList] = useState([]);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const [reasonDetail, setReasonDetail] = useState("");
  const auth = useRecoilValue(authAtom);

  const vacationFn = VacationFunction();

  //검색시 연차, 병가 등등 반영할 데이터
  const [isType, setType] = useState();

  const componentRef = useRef();

  useEffect(() => {
    getVacationApplication();
  }, [date, pageInfo?.current, company?.COMP_CD, isType]);

  const getVacationApplication = async () => {
    setLoading(true);
    const res = await holidayActions.getVacationApplication({
      date,
      pageInfo,
      COMP_CD: company.COMP_CD,
      type: isType,
    });
    if (res.status === 200) {
      setApplicationList(res.data.list);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
    return res;
  };

  const approveVacation = async (sn) => {
    setLoading(true);
    const res = await holidayActions.approveVacation(sn);
    if (res.status === 200) {
    }
    getVacationApplication();
    setLoading(false);
  };

  const rejectVacation = async (sn) => {
    setLoading(true);
    const res = await holidayActions.rejectVacation(sn);
    if (res.status === 200) {
    }
    getVacationApplication();
    setLoading(false);
  };

  const delVacation = async (sn) => {
    setLoading(true);
    const res = await holidayActions.delVacation(sn);
    if (res.status === 200) {
    }
    getVacationApplication();
    setLoading(false);
  };

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div className="btn-group btn-group-left">
            <XlsxDownload
              api={`${auth.accountType}/vacation/application`}
              params={{
                COMP_CD: company?.COMP_CD || null,
                type: isType,
              }}
              name="휴가 리스트"
            />
          </div>
        </div>
        <div>
          <div
            style={{ marginRight: 4 }}
            className="btn-group-left btn-group-inline"
          >
            {accountType.isAdmin() && (
              <button
                className="secondarybtn-md border-line zoom_font"
                type="button"
                onClick={() => {
                  setIsSearchCompanyModalOpen(true);
                }}
              >
                {company?.company_name || "기업 선택"}
              </button>
            )}
            <div className="select-border-line">
              <Select
                placeholder="구분"
                options={vacationFn.annualOptions()}
                onChange={(e) => {
                  setType(e.value);
                }}
              />
            </div>
          </div>
          <div className="d-inline-block">
            <SearchByDate
              period={30}
              defaultEndtime={dayjs().add(30, "day")}
              dateAndKeyword={date}
              onChange={(value) => setDate(value)}
              placeholder="이름 입력"
              loading={loading}
            />
          </div>
        </div>
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={
          auth.user.isAdmin
            ? [
                "구분",
                "신청기간",
                "신청일",
                "신청자",
                "사유",
                "부여일수",
                "신청일수",
                "잔여일수",
                "결재상태",
                "출력",
                "삭제",
              ]
            : [
                "구분",
                "신청기간",
                "신청일",
                "신청자",
                "사유",
                "부여일수",
                "신청일수",
                "잔여일수",
                "결재상태",
                "출력",
                // "삭제",
              ]
        }
      >
        {applicationList?.map((application) => {
          return (
            <tr key={application.sn}>
              <td className="border-line zoom_font">
                {getHolidayType(application.type)}
              </td>
              <td className="border-line zoom_font">{`${application.startYMD} ~ ${application.endYMD}`}</td>
              <td className="border-line zoom_font">
                {application.applicationDatetime?.slice(0, 10)}
              </td>
              <td className="border-line zoom_font">
                {accountType.isAdmin() === true
                  ? `${application.companyName} / ${application.name}`
                  : application.name}
              </td>
              <td className="border-line zoom_font">
                {isReasonOverMaxLength(application.reason.length) ? (
                  <div
                    className="pointer"
                    onClick={() => {
                      setReasonDetail(application.reason);
                      setIsReasonModalOpen(true);
                    }}
                  >{`${application.reason?.substring(0, 15)}...`}</div>
                ) : (
                  application.reason
                )}
              </td>
              <td className="border-line zoom_font">
                {/* {Math.round(application.total * 100) / 100} */}
                {Math.round(application.total * application.day_standard * 10) /
                  10}
              </td>
              <td className="border-line zoom_font">
                {Math.round(application.days * application.day_standard * 10) /
                  10}
              </td>
              <td className="border-line zoom_font">
                {/* {Math.round(application.remain * 100) / 100} */}
                {Math.round(
                  application.remain * application.day_standard * 10
                ) / 10}
              </td>
              {application.isCancel === 0 ? (
                <td>
                  {application.state === 0 && (
                    <div className="btn-group">
                      <button
                        className="primarybtn-sm border-line zoom_font"
                        onClick={() => {
                          approveVacation(application.sn);
                        }}
                      >
                        승인
                      </button>
                      <button
                        className="pinkbtn-sm border-line zoom_font"
                        onClick={() => {
                          rejectVacation(application.sn);
                        }}
                      >
                        반려
                      </button>
                    </div>
                  )}
                  {application.state === 1 && (
                    <div className="btn-group">
                      <button
                        disabled
                        style={{ color: "#1a5c96", fontFamily: "s-core5" }}
                      >
                        승인
                      </button>
                    </div>
                  )}
                  {application.state === 2 && (
                    <div className="btn-group">
                      <button
                        disabled
                        style={{ color: "#e85d6c", fontFamily: "s-core5" }}
                      >
                        반려
                      </button>
                    </div>
                  )}
                </td>
              ) : (
                <td>
                  <div className="btn-group">
                    <button
                      disabled
                      style={{ color: "#222222", fontFamily: "s-core5" }}
                    >
                      취소
                    </button>
                  </div>
                </td>
              )}

              <td>
                {application.state === 1 && (
                  <>
                    <VacationPrintButton application={application} />
                  </>
                )}
              </td>
              {auth.user.isAdmin ? (
                <td>
                  <button
                    className="blackbtn-sm border-line zoom_font"
                    onClick={() => {
                      if (window.confirm("삭제하시겠습니까?")) {
                        delVacation(application.sn);
                      } else {
                        return;
                      }
                    }}
                  >
                    삭제
                  </button>
                </td>
              ) : null}
            </tr>
          );
        })}
      </DataTable>
      <CommonModal
        title="상세 사유"
        open={isReasonModalOpen}
        setOpen={setIsReasonModalOpen}
      >
        {reasonDetail}
      </CommonModal>

      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setIsSearchCompanyModalOpen(false);
          setCompany(company);
        }}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default CompanyVacation;
