import { Navigate } from "react-router-dom";
import { authAtom } from "../../recoil";
import { useRecoilValue } from "recoil";
import CommonLayout from "../common/CommonLayout";
import { ACCOUNTTYPE } from "../../JSON/enum/account";
import CompanyInfoSettings from "../company/CompanyInfoSettings";
import CommonModal from "../modal/CommonModal";
import Gear from "../../ico/icon_환경설정.svg";
import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import useMainActions from "../../recoil/api/main";
import { List } from "react-content-loader";

const CompanyLayout = () => {
  const auth = useRecoilValue(authAtom);
  const mainActions = useMainActions();
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [cnt, setCnt] = useState(0);

  useEffect(() => {
    getCompanyEmployeeCnt();
  }, []);

  if (!auth.user?.accountType === ACCOUNTTYPE.COMPANY) {
    return <Navigate to="/" replace />;
  }

  const getCompanyEmployeeCnt = async () => {
    const res = await mainActions.getCompanyEmployeeCnt();
    if (res.status === 200) {
      setCnt(res.data);
    }
  };

  return (
    <>
      <Header>
        <button
          className="gnbbtn border-line mouse"
          onClick={() => setIsSettingModalOpen(true)}
        >
          <img src={Gear} alt="환경설정" />
          <p className="zoom_font">환경설정</p>
        </button>
      </Header>
      <CommonModal
        title={"기업 환경설정"}
        open={isSettingModalOpen}
        setOpen={setIsSettingModalOpen}
      >
        {isSettingModalOpen && (
          <React.Suspense fallback={<List />}>
            <CompanyInfoSettings
              setIsSettingModalOpen={setIsSettingModalOpen}
            />
          </React.Suspense>
        )}
      </CommonModal>

      <CommonLayout />
    </>
  );
};

export default CompanyLayout;
