import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { searchRegex } from "../../utils/regex";

const SearchKeyword = ({ setKeyword, placeholder = "검색어 입력" }) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    setPageLoaded(true);
  });

  useEffect(() => {
    let timer = setTimeout(() => {
      if (pageLoaded) setKeyword(searchWord);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchWord]);

  return (
    <div className="of-auto">
      <div className="relative width-100 float-right">
        <input
          style={{ minWidth: "190px" }}
          placeholder={placeholder}
          className="custom-input txt-search border-line mouse zoom_font"
          type="text"
          onChange={(e) => {
            setSearchWord(e.target.value);
          }}
          onInput={(e) => {
            if (searchRegex.test(e.target.value)) {
              e.target.value = e.target.value.replace(searchRegex, "");
            }
          }}
        ></input>
        <div className="inputsearch">
          <button className="searchbtn border-line mouse zoom_font"></button>
        </div>
      </div>
    </div>
  );
};

export default SearchKeyword;
