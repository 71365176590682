exports.SERVEREEOR = {
  DB: "Datatbase wrong",
  SERVERERR: "servererror",
  WRONGPATH: "this request incorrect path",
  NOTFOUND: "can't not found page",
  DBINSERTFAIL: "DB insert fail",
  REQUIREACCEPT: "require accept",
  ALREADYEXIST: "already Exists",
  DOESNOTMATH: "does not math data",
  NOTEXISTDATA: "not exist data",
  INCORRECTDATE: "incorrect day",
  REQUIREAPPROVE: "require approve not yet approve",
  DOESNOTMATCHDATETIME: "does not match datetime",
  NEEDAREASON: "need a reason",
  AUTHTIMEOUT: "authtimeout",
};

exports.VACATIONERROR = {
  1: "연차 시작/종료 일이 잘못되었습니다.",
  2: "반차, 반반차 신청시 동일 날짜에 신청해주세요.",
  3: "휴가 일수가 부족합니다.",
  4: "이미 해당 기간에 신청 된 연차가 있습니다.",
  5: "해당 날짜는 공휴일 또는 기업 휴일입니다.",
};
