import React, { useEffect } from "react";
import { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { APPLICATIONSTATE, APPROVALDOCTYPE } from "../../../JSON/enum/Dataenum";
import {
  ApprovalDetailModalAtom,
  ApprovalDetailUpdateAtom,
  ApprovalSnAtom,
  authAtom,
  EmployeeCompanyStampQuery,
  toastAtom,
} from "../../../recoil";
import { useApprovalActions } from "../../../recoil/api/approval";
import { useElecActions } from "../../../recoil/api/elec";
import CommonModal from "../../modal/CommonModal";
import ApprovalDetailModalContent from "./ApprovalDetailModalContent";
import CommonModalScrollTop from "../../modal/CommonModalScrollTop";

export const getApprovalDocType = (docType) => {
  switch (parseInt(docType)) {
    case APPROVALDOCTYPE.RESIGN:
      return "근태";
    case APPROVALDOCTYPE.PROBLEM:
      return "근태";
    case APPROVALDOCTYPE.COOPERATE:
      return "업무";
    case APPROVALDOCTYPE.SPECIALDAY:
      return "지출/구매";
    case APPROVALDOCTYPE.PREBUYPAYMENT:
      return "지출/구매";
    case APPROVALDOCTYPE.BUYPAYMENT:
      return "지출/구매";
    case APPROVALDOCTYPE.OFFICIAL:
      return "공문";
    case APPROVALDOCTYPE.DELEGATE:
      return "위임장";
    default:
      return "오류";
  }
};

export const getApprovalDocDetailType = (docType) => {
  switch (parseInt(docType)) {
    case APPROVALDOCTYPE.RESIGN:
      return "사직서";
    case APPROVALDOCTYPE.PROBLEM:
      return "시말서";
    case APPROVALDOCTYPE.COOPERATE:
      return "업무협조전";
    case APPROVALDOCTYPE.SPECIALDAY:
      return "경조사비신청서";
    case APPROVALDOCTYPE.PREBUYPAYMENT:
      return "지출품의서";
    case APPROVALDOCTYPE.BUYPAYMENT:
      return "지출내역서";
    case APPROVALDOCTYPE.OFFICIAL:
      return "공문";
    case APPROVALDOCTYPE.DELEGATE:
      return "위임장";
    default:
      return "오류";
  }
};

const ItemListTr = ({ item }) => {
  return (
    <tr>
      <td className="text-center zoom_font">{item.name}</td>
      <td className="text-center zoom_font">{item.standard}</td>
      <td className="text-center zoom_font">{item.EA}</td>
      <td className="text-center zoom_font">{item.price}</td>
      <td className="text-center zoom_font">{item.EA * item.price}</td>
      <td className="text-center zoom_font">{item.etc}</td>
    </tr>
  );
};

const ApprovalDetailModal = ({ loadApprovalList }) => {
  const auth = useRecoilValue(authAtom);
  const [snAtom, setSnAtom] = useRecoilState(ApprovalSnAtom);
  const approvalActions = useApprovalActions();
  const elecActions = useElecActions();
  const [approvalDetailModal, setApprovalDetailModal] = useRecoilState(
    ApprovalDetailModalAtom
  );
  const [toast, setToast] = useRecoilState(toastAtom);
  const [approvalDetail, setApprovalDetail] = useState({});
  const [employeeCompanyStamp, setEmployeeCompanyStamp] = useState({});
  const detailUpdate = useSetRecoilState(ApprovalDetailUpdateAtom);

  const getApprovalData = async () => {
    const res = await approvalActions.getApprovalDoc({ sn: snAtom });
    setApprovalDetail(res.data);
  };

  const getStamp = async () => {
    const res = await elecActions.getStampCompanyUser({ sn: snAtom });
    setEmployeeCompanyStamp(res.data);
  };

  useEffect(() => {
    if (snAtom > 0) {
      getApprovalData();
      getStamp();
    }
  }, [snAtom]);

  // 전자결재 상세보기 모달을 CommonModalScrollTop로 변경했습니다.
  // 창을 열때마다 렌더링이 되기에 스크롤이 최상단으로 갑니다
  return (
    <CommonModalScrollTop
      title="전자결재 상세보기"
      open={approvalDetailModal}
      setOpen={setApprovalDetailModal}
    >
      {approvalDetail && (
        <ApprovalDetailModalContent
          doc={approvalDetail}
          docType={getApprovalDocType(approvalDetail.type)}
          docDetailType={getApprovalDocDetailType(approvalDetail.type)}
          companysign={employeeCompanyStamp?.companysign}
          employeesign={employeeCompanyStamp?.employeesign}
          itemList={
            approvalDetail.itemlist?.length > 0 && (
              <table className="writetable hide" style={{ marginTop: 30 }}>
                <tbody>
                  <tr>
                    <th className="text-center-title zoom_font">품명</th>
                    <th className="text-center-title zoom_font">규격</th>
                    <th className="text-center-title zoom_font">수량</th>
                    <th className="text-center-title zoom_font">단가</th>
                    <th className="text-center-title zoom_font">금액</th>
                    <th className="text-center-title zoom_font">비고</th>
                  </tr>

                  {approvalDetail.itemlist?.map((item, i) => {
                    return <ItemListTr item={item} key={i} />;
                  })}
                </tbody>
              </table>
            )
          }
          footer={
            <>
              <div className="btn-group">
                {auth.user.isCompany && approvalDetail?.state === 0 && (
                  <>
                    <button
                      type="button"
                      className="fbtn submitbtn"
                      onClick={async () => {
                        const res = await approvalActions.processingApproval({
                          docsn: approvalDetail.sn,
                          state: APPLICATIONSTATE.APPROVE,
                        });
                        if (res.status === 200) {
                          detailUpdate((update) => update + 1);
                          setToast({ ...toast, on: true });
                          loadApprovalList();
                          setApprovalDetailModal(false);
                          setSnAtom(0);
                        }
                      }}
                    >
                      승인
                    </button>
                    <button
                      type="button"
                      className="fbtn rejectbtn"
                      onClick={async () => {
                        const res = await approvalActions.processingApproval({
                          docsn: approvalDetail.sn,
                          state: APPLICATIONSTATE.REJECT,
                        });
                        if (res.status === 200) {
                          detailUpdate((update) => update + 1);
                          setToast({ ...toast, on: true });
                          loadApprovalList();
                          setApprovalDetailModal(false);
                          setSnAtom(0);
                        }
                      }}
                    >
                      반려
                    </button>
                  </>
                )}

                <button
                  type="button"
                  className="fbtn cancelbtn"
                  onClick={() => setApprovalDetailModal(false)}
                >
                  취소
                </button>
              </div>
            </>
          }
        />
      )}
    </CommonModalScrollTop>
  );
};

export default ApprovalDetailModal;
