import LoginBg from "../../img/login-bg.png";
import Logo from "../../img/logo.png";
import RegisterPop from "../../img/register-pop.png";
import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import CommonModal from "../modal/CommonModal";
import Stiputation from "../policy/Stipulation";
import Privacy from "../policy/Privacy";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { useEmployeeRegisterActions } from "../../recoil/api/auth";
import SearchCompanyModal from "../common/SearchCompanyModal";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../recoil";
import { emailRegex } from "../../utils/regex";
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/;
export const disabilityDegreeOptions = [
  { value: "경증", label: "경증" },
  { value: "중증", label: "중증" },
];

export const disabilityTypeOptions = [
  { value: "지체장애", label: "지체장애" },
  { value: "뇌병변장애", label: "뇌병변장애" },
  { value: "시각장애", label: "시각장애" },
  { value: "청각장애", label: "청각장애" },
  { value: "언어장애", label: "언어장애" },
  { value: "지적장애", label: "지적장애" },
  { value: "자폐성장애", label: "자폐성장애" },
  { value: "정신장애", label: "정신장애" },
  { value: "신장장애", label: "신장장애" },
  { value: "심장장애", label: "심장장애" },
  { value: "호흡기장애", label: "호흡기장애" },
  { value: "간장애", label: "간장애" },
  { value: "안면장애", label: "안면장애" },
  { value: "장루/요루장애", label: "장루/요루장애" },
  { value: "뇌전증장애", label: "뇌전증장애" },
];
export const subDisabilityTypeOptions = [
  { value: "없음", label: "없음" },
  { value: "지체장애", label: "지체장애" },
  { value: "뇌병변장애", label: "뇌병변장애" },
  { value: "시각장애", label: "시각장애" },
  { value: "청각장애", label: "청각장애" },
  { value: "언어장애", label: "언어장애" },
  { value: "지적장애", label: "지적장애" },
  { value: "자폐성장애", label: "자폐성장애" },
  { value: "정신장애", label: "정신장애" },
  { value: "신장장애", label: "신장장애" },
  { value: "심장장애", label: "심장장애" },
  { value: "호흡기장애", label: "호흡기장애" },
  { value: "간장애", label: "간장애" },
  { value: "안면장애", label: "안면장애" },
  { value: "장루/요루장애", label: "장루/요루장애" },
  { value: "뇌전증장애", label: "뇌전증장애" },
];
export const exceptThisSymbols = ["e", "E", "+", "-", "."];

export const worktimeHourOption = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];

export const worktimeMinuteOption = [
  { value: "00", label: "00" },
  { value: "05", label: "05" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "55", label: "55" },
];

const Register = () => {
  const employeeRegisterActions = useEmployeeRegisterActions();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [isCheck1ModalOpen, setIsCheck1ModalOpen] = useState(false);
  const [isCheck2ModalOpen, setIsCheck2ModalOpen] = useState(false);

  const [emailCustom, setEmailCustom] = useState(false);
  const [emailHead, setEmailHead] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);

  const [isIDVerified, setISIDVerified] = useState(false);
  const [isDoneModalOpen, setIsDoneModalOpen] = useState(false);

  const [phoneCheck, setPhoneCheck] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [authNum, setAuthNum] = useState("");

  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const [isPageLoad, setPageLoad] = useState(false);
  const [isUserIdError, setUserIdError] = useState("");
  const [isWorkTimeError, setWorkTimeError] = useState("");
  const [isSMSCertified, setSMSCertified] = useState(false);
  const [isSMSFailReason, setSMSFailReason] = useState("");
  const [isEmailCheck, setIsEmailCheck] = useState();
  const [alreadyPhone, setAlreadyPhone] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const pw = useRef({});
  pw.current = watch("pw", "");
  pw.confirm = watch("pw_confirm", "");

  const onSubmit = async (data) => {
    if (!isIDVerified) {
      alert("아이디 중복체크를 해주세요.");
      return false;
    }

    if (!isPhoneVerified) {
      alert("휴대폰 인증을 해주세요.");
      return false;
    }

    if (
      parseInt(`${data.start_time1.value}${data.start_time2.value}`) >=
      parseInt(`${data.end_time1.value}${data.end_time2.value}`)
    ) {
      alert("출퇴근 시간이 올바르지 않습니다.");
      return false;
    }

    if (pw.current !== pw.confirm) {
      alert("비밀번호가 일치하지 않습니다.");
      return false;
    }

    if (!emailVerified) {
      alert("이메일 중복체크를 해주세요.");
      return false;
    }

    const res = await employeeRegisterActions.register({
      ...data,
      isdiasbled: "1",
      disabled_grade: data.disability_degree.value,
      disabled_name: data.disability_type.value,
      sub_disabled_name: data.sub_disability_type.value,
      accept_terms: checked1 ? "1" : "0",
      accept_personal_info: checked2 ? "1" : "0",
      start_time1: data.start_time1.value,
      start_time2: data.start_time2.value,
      end_time1: data.end_time1.value,
      end_time2: data.end_time2.value,

      working_starttime_string: `${data.start_time1.value}${data.start_time2.value}`,
      working_endtime_string: `${data.end_time1.value}${data.end_time2.value}`,
    });

    if (res.status === 200) {
      setIsDoneModalOpen(true);
    }
  };

  const onChageModal1State = () => {
    setIsCheck1ModalOpen(false);
  };
  const onChageModal2State = () => {
    setIsCheck2ModalOpen(false);
  };

  useEffect(() => {
    if (emailHead.length !== 0 && emailDomain.length !== 0) {
      const email = `${emailHead}@${emailDomain}`;
      setValue("email", email);
    } else {
      setValue("email", "");
    }
  }, [emailHead, emailDomain]);

  useEffect(() => {
    if (checked1 && checked2) {
      setValue("allow_all", true);
    } else {
      setValue("allow_all", false);
    }
  }, [checked1, checked2]);

  const emailCheck = async (email, domain) => {
    setEmailVerified(false);
    if (!email) {
      setIsEmailCheck(1); // 이메일 미입력
    } else if (!domain) {
      setIsEmailCheck(2); // 도메인 미입력
    } else {
      const reqEmail = `${email}@${domain}`;
      if (!emailRegex.test(reqEmail)) {
        alert("이메일을 올바르게 입력하세요.");
        return;
      }
      const res = await employeeRegisterActions.emailCheck({ email: reqEmail });
      if (!res) {
        setIsEmailCheck(3); //사용중인 이메일
      } else {
        setIsEmailCheck(0);
        setEmailVerified(true);
      }
    }
  };

  return (
    <div>
      <div className="con-login">
        <img className="loimage" src={LoginBg} alt="배경" />
        <div className="flex-log login-l">
          <div className="l-cont">
            <img src={Logo} alt="로고" />
            <p>재택근무 인사관리 플랫폼</p>
            <span>(주)브이드림</span>
          </div>
        </div>
        <div className="flex-log login-r register-table">
          <div
            className="r-cont"
            style={{ marginBottom: "150px", marginTop: "90px" }}
          >
            <div className="lotit">
              <h3>회원가입</h3>
              <span>(개인회원)</span>
              <p>플립에 오신 것을 환영합니다.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <table className="table lotable">
                <tbody>
                  <tr>
                    <td>
                      <input
                        placeholder="이름을 입력해주세요."
                        {...register("name", { required: true })}
                      />
                      {errors.name?.type === "required" && (
                        <span className="register-warning">
                          이름을 입력하세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group">
                        <input
                          {...register("company_name", {
                            required: true,
                          })}
                          disabled={true}
                          className="form-control form-control-sm"
                          placeholder="회사명을 검색해주세요."
                        />
                        <span
                          className="input-group-btn"
                          onClick={() => setIsSearchCompanyModalOpen(true)}
                        >
                          <a>검색</a>
                        </span>
                        <input
                          {...register("COMP_CD", {
                            required: true,
                          })}
                          disabled={true}
                          style={{ display: "none" }}
                        />
                      </div>
                      {!watch("COMP_CD") && isPageLoad && (
                        <span className="register-warning">
                          회사명을 검색해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group">
                        <input
                          {...register("id", {
                            required: true,
                            pattern: /^[A-za-z0-9]{6,}$/,
                          })}
                          className="form-control form-control-sm"
                          name="id"
                          placeholder="아이디를 입력해주세요."
                          onChange={() => {
                            setISIDVerified(false);
                          }}
                        />
                        <span
                          className="input-group-btn"
                          onClick={async (e) => {
                            const user_id = watch("id").trim();
                            const user_id_regx = /^[A-za-z0-9]{6,}$/;
                            setUserIdError("");
                            setISIDVerified(false);
                            if (user_id.length === 0) {
                              setUserIdError("noInput");
                              return;
                            }
                            if (user_id.length <= 5) {
                              setUserIdError("minimumLength");
                              return;
                            }
                            if (!user_id_regx.test(user_id)) {
                              setUserIdError("regularExpression");
                              return;
                            }
                            const res = await employeeRegisterActions.idCheck({
                              id: watch("id"),
                            });
                            setISIDVerified(res);
                          }}
                        >
                          <a>중복확인</a>
                        </span>
                      </div>
                      <div className="alert-w id_check">
                        <i>!</i>
                        <span className="register-warning">
                          이미 사용중인 아이디 입니다.
                        </span>
                      </div>
                      {isIDVerified && (
                        <span className="register-ok">
                          사용 가능한 아이디 입니다.
                        </span>
                      )}
                      {errors.id?.type === "pattern" && (
                        <span className="register-warning">
                          아이디는 최소 6자이상 입력해주세요.
                        </span>
                      )}

                      {errors.id?.type === "required" && (
                        <span className="register-warning">
                          아이디를 입력해주세요.
                        </span>
                      )}

                      {isUserIdError === "noInput" && (
                        <span className="register-warning">
                          아이디를 입력해주세요.
                        </span>
                      )}

                      {isUserIdError === "minimumLength" && (
                        <span className="register-warning">
                          아이디는 6자리 이상 입력해주세요.
                        </span>
                      )}

                      {isUserIdError === "regularExpression" && (
                        <span className="register-warning">
                          아이디는 영문 숫자만 사용가능합니다.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        {...register("pw", {
                          required: true,
                          maxLength: 15,
                          pattern:
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,15}$/,
                        })}
                        type="password"
                        className="form-control"
                        placeholder="!,@,#,$,%를 포함한 비밀번호를 입력해주세요.(영문/숫자/특수문자 조합 6~15자)"
                      />
                      {errors.pw?.type === "pattern" && (
                        <span className="register-warning">
                          영문/숫자/특수문자 조합 6~15자를 입력하셔야 합니다.
                        </span>
                      )}

                      {errors.pw?.type === "required" && (
                        <span className="register-warning">
                          비밀번호를 입력해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        {...register("pw_confirm", {
                          required: true,
                          maxLength: 15,
                        })}
                        type="password"
                        className="form-control"
                        placeholder="비밀번호를 한번 더 입력해주세요."
                      />
                      {pw.current !== pw.confirm && (
                        <span className="register-warning">
                          비밀번호가 일치하지 않습니다.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-email">
                        <input
                          {...register("email", {
                            required: true,
                            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          })}
                          style={{ display: "none" }}
                        />
                        <input
                          style={{ width: "28%" }}
                          type="text"
                          // className="form-control"
                          name="email_head"
                          placeholder="이메일 주소를 입력해주세요."
                          onChange={(e) => setEmailHead(e.target.value)}
                          onInput={(e) => {
                            setEmailVerified(false);
                            setIsEmailCheck();
                            // const regExp = /[^0-9a-zA-Z]/g;
                            if (emailRegex.test(e.target.value)) {
                              e.target.value = e.target.value.replace(
                                emailRegex,
                                ""
                              );
                            }
                          }}
                          value={emailHead}
                        />
                        <span>@</span>
                        <input
                          type="text"
                          placeholder="선택해주세요"
                          name="email_domain"
                          readOnly={!emailCustom}
                          onChange={(e) => setEmailDomain(e.target.value)}
                          value={emailDomain}
                        />
                        <select
                          style={{ float: "inherit", marginLeft: "5px" }}
                          name="email_domain"
                          onChange={(e) => {
                            setEmailVerified(false);
                            setIsEmailCheck();
                            if (e.target.value === "custom") {
                              setEmailCustom(true);
                            } else {
                              setEmailDomain(e.target.value);
                            }
                          }}
                        >
                          <option value="" disabled selected>
                            선택
                          </option>
                          <option value="gmail.com">gmail.com</option>
                          <option value="hanmail.net">hanmail.net</option>
                          <option value="hotmail.com">hotmail.com</option>
                          <option value="nate.com">nate.com</option>
                          <option value="naver.com">naver.com</option>
                          <option value="yahoo.co.kr">yahoo.co.kr</option>
                          <option value="custom">직접입력</option>
                        </select>
                        <span className="input-group-btn">
                          <a
                            onClick={() => {
                              emailCheck(emailHead, emailDomain);
                            }}
                          >
                            중복확인
                          </a>
                        </span>
                      </div>
                      {isEmailCheck === 1 && (
                        <span className="register-warning">
                          이메일을 입력해주세요.
                        </span>
                      )}
                      {isEmailCheck === 2 && (
                        <span className="register-warning">
                          도메인을 선택해주세요
                        </span>
                      )}
                      {isEmailCheck === 3 && (
                        <span className="register-warning">
                          이미 사용중인 이메일 입니다.
                        </span>
                      )}
                      {isEmailCheck === 0 && (
                        <span className="register-ok">
                          사용 가능한 이메일 입니다.
                        </span>
                      )}
                      {errors.email?.type === "pattern" && (
                        <span className="register-warning">
                          이메일을 올바르게 입력하세요.
                        </span>
                      )}
                      {!watch("email") && isPageLoad && (
                        <span className="register-warning">
                          이메일 주소를 입력해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group mb-3">
                        <input
                          {...register("tel", {
                            required: true,
                            pattern:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/,
                          })}
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*)\./g, "$1");
                          }}
                          disabled={isPhoneVerified}
                          className="form-control form-control-sm"
                          placeholder="휴대폰번호를 -없이 입력해주세요."
                          maxLength={15}
                          type="number"
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          style={{ width: "68%" }}
                        />
                        <span
                          className="input-group-btn"
                          onClick={async () => {
                            if (!isPhoneVerified) {
                              if (!phoneRegex.test(watch("tel"))) {
                                alert("휴대폰 번호를 정확하게 입력하세요.");
                                return false;
                              }

                              const res =
                                await employeeRegisterActions.phoneCheckRequest(
                                  {
                                    tel: watch("tel"),
                                  }
                                );
                              if (res) {
                                setPhoneCheck(true);
                                setAlreadyPhone(false);
                              } else {
                                setAlreadyPhone(true);
                              }
                            }
                          }}
                        >
                          <a style={{ width: "30%" }}>
                            {phoneCheck ? "재전송" : "인증번호발송"}
                          </a>
                        </span>
                      </div>
                      {alreadyPhone && (
                        <span className="register-warning">
                          이미 사용중인 휴대폰번호 입니다.
                        </span>
                      )}
                      {phoneCheck && (
                        <div className="input-group">
                          <input
                            value={authNum}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*)\./g, "$1");
                            }}
                            onChange={(e) => {
                              setAuthNum(e.target.value);
                            }}
                            disabled={isPhoneVerified}
                            className="form-control form-control-sm"
                            name="pass_num"
                            placeholder="인증번호를 입력해주세요."
                            maxLength={5}
                            type="number"
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <span
                            className="input-group-btn"
                            onClick={async () => {
                              setSMSFailReason("");
                              setSMSCertified(false);
                              if (!isPhoneVerified) {
                                const res =
                                  await employeeRegisterActions.phoneCheck({
                                    tel: watch("tel"),
                                    authNum,
                                  });
                                if (res.result) {
                                  setIsPhoneVerified(true);
                                } else {
                                  setSMSCertified(true);
                                  setSMSFailReason(res.data);
                                }
                              }
                            }}
                          >
                            <a>인증하기</a>
                          </span>
                        </div>
                      )}
                      {errors.tel?.type === "required" && (
                        <span id="alert-danger" className="register-warning">
                          휴대폰 번호를 입력하세요.
                        </span>
                      )}
                      {errors.tel?.type === "pattern" && (
                        <span id="alert-danger" className="register-warning">
                          번호 형식이 일치하지 않습니다.
                        </span>
                      )}
                      <div className="alert-w tel_check_error">
                        <i>!</i>
                        <span id="alert-danger" className="register-warning">
                          인증번호가 맞지 않습니다.
                        </span>
                      </div>
                      {isPhoneVerified && (
                        <span className="register-ok">
                          인증이 완료되었습니다.
                        </span>
                      )}
                      {isSMSCertified && (
                        <span id="alert-danger" className="register-warning">
                          {isSMSFailReason}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="input-time-t">장애유형</span>
                      <div className="wrap">
                        <Controller
                          name="disability_degree"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="장애 정도 선택"
                              options={disabilityDegreeOptions}
                            />
                          )}
                        />
                      </div>
                      <div className="wrap">
                        <Controller
                          name="disability_type"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="장애 유형 선택"
                              options={disabilityTypeOptions}
                            />
                          )}
                        />
                      </div>
                      <div className="wrap">
                        <Controller
                          name="sub_disability_type"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="부장애 유형 선택"
                              options={subDisabilityTypeOptions}
                            />
                          )}
                        />
                      </div>
                      {(errors.disability_type?.type === "required" ||
                        errors.sub_disability_type?.type === "required" ||
                        errors.disability_degree?.type === "required") && (
                        <span id="alert-danger" className="register-warning">
                          장애 유형을 선택 해 주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="input-time-t">출퇴근시간</span>
                      <div className="register-flex">
                        <div className="register-flex time-select btn-group-left">
                          <div style={{ marginBottom: "10px" }}>
                            <Controller
                              name="start_time1"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  menuShouldScrollIntoView={false}
                                  className="elec-select"
                                  {...field}
                                  placeholder="선택"
                                  options={worktimeHourOption}
                                />
                              )}
                            />
                            <Controller
                              name="start_time2"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  menuShouldScrollIntoView={false}
                                  className="elec-select"
                                  {...field}
                                  placeholder="선택"
                                  options={worktimeMinuteOption}
                                />
                              )}
                            />
                          </div>
                          <span style={{ marginTop: 10 }}>~</span>
                          <div>
                            <Controller
                              name="end_time1"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  menuShouldScrollIntoView={false}
                                  className="elec-select"
                                  {...field}
                                  placeholder="선택"
                                  options={worktimeHourOption}
                                />
                              )}
                            />
                            <Controller
                              name="end_time2"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  menuShouldScrollIntoView={false}
                                  className="elec-select"
                                  {...field}
                                  placeholder="선택"
                                  options={worktimeMinuteOption}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-time">
                        {(errors.start_time1?.type === "required" ||
                          errors.start_time2?.type === "required" ||
                          errors.end_time1?.type === "required" ||
                          errors.end_time2?.type === "required") && (
                          <span id="alert-danger" className="register-warning">
                            출퇴근시간을 입력하세요.
                          </span>
                        )}

                        {isWorkTimeError === "timeHourCheck1" && (
                          <span id="alert-danger" className="register-warning">
                            출근 시간은 24시 기준으로 입력해주세요.
                          </span>
                        )}
                        {isWorkTimeError === "timeHourCheck2" && (
                          <span id="alert-danger" className="register-warning">
                            퇴근 시간은 24시 기준으로 입력해주세요.
                          </span>
                        )}

                        {isWorkTimeError === "timeMinCheck1" && (
                          <span id="alert-danger" className="register-warning">
                            출근 분은 60분 기준으로 입력해주세요.
                          </span>
                        )}

                        {isWorkTimeError === "timeMinCheck2" && (
                          <span id="alert-danger" className="register-warning">
                            퇴근 분은 60분 기준으로 입력해주세요.
                          </span>
                        )}
                      </div>

                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div
                        className="checkbt macheck"
                        style={{ marginRight: 30 }}
                      >
                        <input
                          {...register("allow_all", {
                            required: true,
                          })}
                          type="checkbox"
                          className="w-che"
                        />
                        <span
                          className={checked1 && checked2 ? "on" : "off"}
                          onClick={() => {
                            if (checked1 && checked2) {
                              setChecked1(false);
                              setChecked2(false);
                            } else {
                              setChecked1(true);
                              setChecked2(true);
                            }
                          }}
                        ></span>
                        <label
                          htmlFor="remember"
                          className="label"
                          onClick={() => {
                            if (checked1 && checked2) {
                              setChecked1(false);
                              setChecked2(false);
                            } else {
                              setChecked1(true);
                              setChecked2(true);
                            }
                          }}
                        >
                          모든 항목에 동의
                        </label>
                      </div>
                      <div
                        className="checkbt macheck"
                        style={{ marginRight: 30 }}
                      >
                        <input type="checkbox" className="w-che" />
                        <span
                          className={checked1 ? "on" : "off"}
                          onClick={() => setChecked1(!checked1)}
                        >
                          {" "}
                        </span>
                        <label
                          htmlFor="remember"
                          className="label"
                          onClick={() => setChecked1(!checked1)}
                        >
                          이용약관
                        </label>
                        <a
                          className="watch"
                          style={{
                            marginLeft: 8,
                            marginTop: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => setIsCheck1ModalOpen(true)}
                        >
                          [보기]
                        </a>
                      </div>
                      <div className="checkbt macheck">
                        <input type="checkbox" className="w-che" />
                        <span
                          className={checked2 ? "on" : "off"}
                          onClick={() => setChecked2(!checked2)}
                        ></span>
                        <label
                          htmlFor="remember"
                          className="label"
                          onClick={() => setChecked2(!checked2)}
                        >
                          개인정보 수집 및 이용
                        </label>
                        <a
                          className="watch"
                          style={{
                            marginLeft: 8,
                            marginTop: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => setIsCheck1ModalOpen(true)}
                        >
                          [보기]
                        </a>
                      </div>
                    </td>
                    <td>
                      {(!checked1 || !checked2) && isPageLoad && (
                        <span className="register-warning">
                          모든 약관에 동의해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="tdbutt">
                    <td colSpan={2}>
                      <button
                        type="submit"
                        className="lobutt on"
                        onClick={() => setPageLoad(true)}
                      >
                        회원가입
                      </button>
                    </td>
                  </tr>
                  <tr className="tdbutt">
                    <td colSpan={2}>
                      <Link to="/register-select">
                        <button type="button" className="lobutt">
                          취소
                        </button>
                      </Link>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </form>
          </div>
        </div>
      </div>
      <CommonModal
        open={isCheck1ModalOpen}
        onChangeModalState={onChageModal1State}
        title="이용약관"
      >
        <Stiputation />
      </CommonModal>
      <CommonModal
        open={isCheck2ModalOpen}
        onChangeModalState={onChageModal2State}
        title="개인정보 수집 및 이용"
      >
        <Privacy />
      </CommonModal>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(e) => {
          setValue("company_name", e.company_name);
          setValue("COMP_CD", e.COMP_CD);
        }}
      />
      <CommonModal title="가입완료" open={isDoneModalOpen}>
        <div id="comm_layer" className="pop-con">
          <div className="padt30"></div>
          <div className="id_tit id_center">
            <span>
              <img src={RegisterPop} alt="" />
            </span>
            <div className="text">
              <strong className="te_center">
                플립(Flipped) 회원 가입 요청이 완료되었습니다.
              </strong>
              <p>
                <em>회원가입 승인 대기 중</em>입니다.
              </p>
              <p>승인을 위해 관리자가 요청 내용을 확인 중이며,</p>
              <p>가입요청에 대해 빠르게 처리 드릴 수 있도록 노력하겠습니다.</p>
            </div>
          </div>
          <div className="s-button s-idpass cf">
            <a
              className="id_sear sc"
              onClick={() => {
                setIsDoneModalOpen(false);
                navigate("/");
              }}
            >
              확인
            </a>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default Register;
