import { useRecoilValue } from "recoil";
import { get, getPage } from ".";
import { authAtom } from "..";

const useSearchActions = () => {
  const auth = useRecoilValue(authAtom);

  return {
    searchCompanyNameByKeyword,
    searchCompanyRegister,
    getEmployeeByCompanyId,
    getEmployeeByCOMPCD,
    getECompanyAllUserByCOMPCD,
    getEmployeeByCOMPCDAdmin,
  };
  async function searchCompanyNameByKeyword(data) {
    const res = await getPage("common/company/name", { ...data, count: 10000 });
    return res;
  }

  async function searchCompanyRegister(data) {
    const res = await getPage("login/company/name", { ...data, count: 10000 });
    return res;
  }

  async function getEmployeeByCompanyId() {
    const res = await get("search/company/employee");
    return res;
  }

  async function getEmployeeByCOMPCD(COMP_CD) {
    const res = await get("search/employee/name", {
      COMP_CD,
      employee_name: "",
    });
    return res;
  }

  //기업용?
  async function getECompanyAllUserByCOMPCD(COMP_CD) {
    const res = await get("search/company/employee", {
      COMP_CD,
      // employee_name: "",
      schedule: true,
    });
    return res;
  }

  //관리자용
  async function getEmployeeByCOMPCDAdmin(COMP_CD) {
    const res = await get("search/employee/name", {
      COMP_CD,
      employee_name: "",
      schedule: true,
    });
    return res;
  }
};

export default useSearchActions;
