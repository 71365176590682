exports.WORKSTAMP = {
  earlyworkin: 1,
  workin: 2, //출근
  lateworkin: 3, //지각
  workout: 4, //퇴근
  lateworkout: 5, //초과근무
  disapper: 6, //결근
  not: 7, //출퇴근 시간 아님
  notLeavingWork: 8, // 전날 퇴근하지 않음
  earlyworkout: 9, // 조기퇴근
  alreadyExist: 10, //데이터가 있어서 입력 할 수 없음
  earlytenworkin: 11, //퇴근 시간 입력이 출근이 입력 된 시간 보다 빠름
  notexistworkin: 12, //출근 시간이 없어서 퇴근 입력이 되지 않음.
  dailyReportRequired: 13,//일일업무일지가 작성되지 않아서 퇴근이 입력 되지 않음.

  typeWorkIn : 100,//출근 (검색 필터 용)
  typeWorkOut : 200//퇴근 (검색 필터 용)
};

exports.WRITEWORKSTAMP = {
  workin: 1,
  workout: 2,
};

exports.HOLIDAYTYPE = {
  DAYOFF: 1, //연차
  HALFAM: 2, //오전 반차
  HALFPM: 3, //오후 반차
  HALFHALF: 4, //반반차
  CONGRATULATION: 5, //경조사
  LEAVE: 6, //병가
  ETC: 7, //기타 휴가
};

exports.SCHEDULETYPE = {
  MEETING: 1, //회의
  BIRTHDAY: 2, //생일
  HALFHALFOFF: 3, //반반차
  HALFOFF: 4, //반차
  DAYOFF: 5, //연차
  OUTWORKING: 6, //외근
  BUSINESSTRIP: 7, //출장
  CONFFRENCE: 8, //행사
  HALFAM: 9, //오전 반차
  HALFPM: 10, //오후 반차
  HALFHALF: 11, //반반차
  CONGRATULATION: 12, //경조사
  LEAVE: 13, //병가
  ETC: 14, //기타 휴가
};

exports.REPORTTYPE = {
  NONE: 0, //지정되지 않음
  DAYILY: 1, //일일보고
  WEEKILY: 2, //주간보고
  MONTHLIY: 3, //월간보고
};

exports.NOTICETYPE = {
  NONE: 0, //지정되지 않음
  OFFICIAL: 1, //공문
  DELEGATE: 2, //위임장
};

exports.APPLICATIONSTATE = {
  NONE: 0, //승인 요청
  APPROVE: 1, //승인
  REJECT: 2, //반려
  CANCEL: 3, //취소
  APPROVE_DELETED: 4, //승인(삭제됨)
  REJECT_DELETED: 5, //반려(삭제됨)
};

exports.JOINDOCUMNET = {
  NONE: 0, //미등록
  APPLICATIONDONE: 1, //등록
  PRESIGN: 2, //서명 전
  SIGN: 3, //서명완료
};

exports.JOINDOCTYPE = {
  work_contract: 1, //근로계약서
  digital_sign: 2, //전자서명 동의서
  remotework: 3, //재택근무 보안서약서
  personalagree: 4, //개인정보동의서
  secretagree: 5, //비밀유지 및 겸업 금지 서약서
  idcerti: 6, //주민등록등본
  degree: 7, //최종학교졸업증명서
  account: 8, //통장사본
  disable: 9, //중증장애인확인서
  health: 10, //건강보험득실확인서
  voucher: 11, //복지카드
  additional: 12, //추가요청서류
};

exports.APPROVALDOCTYPE = {
  WORKATTITUDE: 1, //근태
  WORK: 2, //업무
  PAYMENT: 3, //지출구매
  NORMAL : 4,//일반

  RESIGN: 10, //사직서
  PROBLEM: 11, //시말서
  COOPERATE: 20, //업무협조전
  SPECIALDAY: 30, //경조사비신청서
  PREBUYPAYMENT: 31, //지출품의서
  BUYPAYMENT: 32, //지출내역서
  OFFICIAL: 41, //공문
  DELEGATE: 42 //위임장
};

exports.SAVETYPE = {
  limit: 0, //영구 보존
  year: 1, //1년
  year3: 3, //3년
  year5: 5, //5년
  year10: 10, //10년
};

exports.JOINDOCTYPEINT = [
  "",
  "work_contract",
  "digital_sign",
  "remotework",
  "personalagree",
  "secretagree",
  "idcerti",
  "degree",
  "account",
  "disable",
  "health",
  "voucher",
  "additional",
];

exports.SOCKETENUM = {
  SERVEREEOR: "SERVEREEOR",
  CREATEROOM: "CREATEROOM",
  CREATEROOMDONE: "CREATEROOMDONE",
  SEND_MESSAGE: "SEND_MESSAGE",
  SEND_MESSAGE_DONE: "SEND_MESSAGE_DONE",
  ENTER_LOBBY: "ENTER_LOBBY",
  CHATROOMLIST: "CHATROOMLIST",
  REQUESTCHATROOM: "REQUESTCHATROOM",
  REQUESTCHATROOMLIST: "REQUESTCHATROOMLIST",
  RECEIVE_MESSAGE: "RECEIVE_MESSAGE",
  READ_MESSAGE: "READ_MESSAGE",
  CHATLIST: "CHATLIST",
  INVITEUSER: "INVITEUSER",
  EXITROOM: "EXITROOM",
  DOESNOTMATCHDATA: "DOESNOTMATCHDATA",
  PERSONALROOMCHECK: "PERSONALROOMCHECK",
  NOTENOTI : "NOTENOTI",
};

exports.CHATCONTENTSTYPE = {
  TEXT: "TEXT",
  FILE: "FILE",
};

exports.HRMTYPE = {
  HIRED: 0, //재직증명서
  CAREER: 1, //경력증명서
};

exports.INNERDOCTYPE = {
  RULES: 1, //회사 규칙
  BYLAWS: 2, //회사 내규
  FORMAT: 3, //회사 서식
  COUNCIL: 4, //노사협의회
  BUSINESS: 5, //사업자등록증
};

exports.USERGRADE = {
  BAD: 1, // 불량회원
  NORMAL: 0, // 일반회원
  GOOD: 2, // 우수회원
};