import { useEffect } from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { toastAtom } from "../../recoil";
import useMainActions, {
  EmployeeMyInfoQuery,
  MyInfoUpdate,
} from "../../recoil/api/main";
import { emailRegex, phoneRegex } from "../../utils/regex";
import ChangePasswordModal from "../common/home/ChangePasswordModal";
import { InputText } from "../form/Input";
import {
  disabilityDegreeOptions,
  disabilityTypeOptions,
  subDisabilityTypeOptions,
} from "./Register";
import ChangePhoneNumberModal from "../common/home/ChangePhoneNumberModal";

const EmployeeInfoSettings = ({ setIsSettingModalOpen }) => {
  const mainActions = useMainActions();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useRecoilState(toastAtom);
  const employeeMyInfo = useRecoilValue(EmployeeMyInfoQuery());
  const [stamp, setStamp] = useState();
  const myInfoUpdate = useSetRecoilState(MyInfoUpdate);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({});
  const [check, setCheck] = useState(true);
  const [fName, setFName] = useState("");

  //휴대폰 번호 변경 모달
  const [isChangePhoneNumberModal, setChangPhoneNumberModal] = useState(false);

  //휴대폰 모달에서 전화번호 변경 감시
  const [isModalPhoneNumber, setModalPhoneNumber] = useState(
    employeeMyInfo.employee_tel
  );

  useEffect(() => {
    setValue("employee_tel", String(isModalPhoneNumber));
  }, [isModalPhoneNumber]);

  const changeStamp = async () => {
    await mainActions.setSign({
      url: stamp,
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (stamp) {
      await changeStamp();
    }
    if (!check) {
      setToast({
        ...toast,
        error: true,
        message: "이미지 파일만 업로드 가능합니다. 다시 선택해주세요",
      });
      setLoading(false);
      return;
    }
    if (!emailRegex.test(data.employee_email)) {
      setToast({
        ...toast,
        error: true,
        message: "이메일 형식이 맞지 않습니다. 이메일을 다시 확인해주세요",
      });
      setLoading(false);
      return;
    }
    let params = {
      employee_email: data.employee_email,
      employee_tel: data.employee_tel,
      disabled_grade: data.disabled_grade.value,
      disabled_name: data.disabled_name.value,
      sub_disabled_name: data.sub_disabled_name.value,
    };

    const res = await mainActions.putEmployeeMyInfo(params);
    if (res.status === 200) {
      myInfoUpdate((update) => update + 1);
      setIsSettingModalOpen(false);
      setToast({ ...toast, on: true });
      setLoading(false);
    }
  };

  const imageCheck = (file) => {
    switch (file[0].type) {
      case "image/png":
        return stampCheck(file);
      case "image/gif":
        return stampCheck(file);
      case "image/jpeg":
        return stampCheck(file);
      default:
        return stampCheck(file, false);
    }
  };

  const stampCheck = (file, err) => {
    if (err === false) {
      setToast({
        ...toast,
        error: true,
        message: "이미지 파일만 업로드 가능합니다. 다시 선택해주세요",
      });
      setCheck(false);
    } else {
      setStamp(file);
      setCheck(true);
    }
  };

  useEffect(() => {
    reset({
      ...employeeMyInfo,
      disabled_grade: disabilityDegreeOptions.filter(
        (option) => option.value === employeeMyInfo?.disabled_grade
      )[0],
      disabled_name: disabilityTypeOptions.filter(
        (option) => option.value === employeeMyInfo?.disabled_name
      )[0],
      sub_disabled_name: subDisabilityTypeOptions.filter(
        (option) => option.value === employeeMyInfo?.sub_disabled_name
      )[0],
    });
  }, [employeeMyInfo]);

  return (
    <div id="comm_layer" className="pop-con">
      {employeeMyInfo && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="pop-in-pop">
            <colgroup>
              <col width="80" />
              <col width="170" />
              <col width="80" />
              <col width="150" />
            </colgroup>
            <tbody>
              <tr>
                <th className="pop_thd">아이디</th>
                <td className="pop_thd">{employeeMyInfo.employee_id}</td>
                <th className="pop_thd">이름(실명)</th>
                <td className="pop_thd">{employeeMyInfo.employee_name}</td>
              </tr>
              <tr>
                <th className="pop_thd">회사명</th>
                <td className="pop_thd">{employeeMyInfo.companyname}</td>
                <th className="pop_thd">직무</th>
                <td className="pop_thd">{employeeMyInfo.department || "-"}</td>
              </tr>
              <tr>
                <th className="pop_thd">이메일</th>
                <td className="pop_thd">
                  <InputText
                    errors={errors}
                    refs={{
                      ...register("employee_email", {
                        required: true,
                        // pattern: emailRegex,
                      }),
                    }}
                    type="text"
                    className="pop_input"
                  />
                </td>
                <th className="pop_thd">휴대폰</th>
                <td className="pop_thd">
                  <InputText
                    errors={errors}
                    refs={{
                      ...register("employee_tel", {
                        required: true,
                        pattern: phoneRegex,
                      }),
                    }}
                    type="text"
                    className="pop_input"
                    style={{ width: "70%", display: "inline-block" }}
                    isReadOnly={true}
                  />

                  <button
                    className="primarybtn-sm"
                    type="button"
                    onClick={() => setChangPhoneNumberModal(true)}
                    style={{ marginLeft: "5px" }}
                  >
                    변경
                  </button>
                </td>
              </tr>
              <tr>
                <th className="pop_thd">비밀번호</th>
                <td className="pop_thd">
                  <button
                    className="primarybtn-sm"
                    type="button"
                    onClick={() => setIsChangePasswordModalOpen(true)}
                  >
                    변경
                  </button>
                </td>
                <th className="pop_thd">중/경증</th>
                <td className="pop_thd">
                  <Controller
                    name="disabled_grade"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        options={disabilityDegreeOptions}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">장애유형</th>
                <td className="pop_thd">
                  <Controller
                    name="disabled_name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        options={disabilityTypeOptions}
                      />
                    )}
                  />
                </td>
                <th className="pop_thd">부장애유형</th>
                <td className="pop_thd">
                  <Controller
                    name="sub_disabled_name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="선택"
                        options={subDisabilityTypeOptions}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="pop_thd">도장 이름</th>
                <td className="pop_thd" colSpan={3}>
                  <div className="filetype">
                    <input
                      style={{ display: "none" }}
                      id="bizFile"
                      onChange={(e) => {
                        if (e.target.files[0].size > 2 * 1024 * 1024) {
                          setToast({
                            ...toast,
                            error: true,
                            message:
                              "파일사이즈는 2MB를 초과할 수 없습니다. 다시 선택해주세요",
                          });
                          return;
                        }
                        imageCheck(e.target.files);
                        setFName(e.target.files[0].name);
                      }}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                    />
                    <label
                      style={{ height: "36px" }}
                      htmlFor="bizFile"
                      className="file-btn"
                    >
                      + 파일선택
                    </label>
                    <span style={{ marginLeft: "10px" }}>
                      {fName !== "" ? fName : employeeMyInfo?.sign_name}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="pop_thd">도장 이미지</th>
                <td className="pop_thd" colSpan={3}>
                  {stamp ? (
                    <img
                      src={URL.createObjectURL(stamp[0])}
                      width="150"
                      alt="도장이미지"
                    />
                  ) : (
                    <>
                      {employeeMyInfo?.sign_url ? (
                        <img
                          src={employeeMyInfo?.sign_url}
                          width="150"
                          alt="도장이미지"
                        />
                      ) : (
                        <div></div>
                      )}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="s-button cf">
            <button className="s-save sc" disabled={loading} type="submit">
              저장
            </button>
            <button
              className="s-cans sc"
              type="button"
              onClick={() => setIsSettingModalOpen(false)}
            >
              취소
            </button>
          </div>
        </form>
      )}
      <ChangePasswordModal
        user={true}
        open={isChangePasswordModalOpen}
        setOpen={setIsChangePasswordModalOpen}
      />

      <ChangePhoneNumberModal
        open={isChangePhoneNumberModal}
        setOpen={setChangPhoneNumberModal}
        setModalPhoneNumber={setModalPhoneNumber}
      />
    </div>
  );
};

export default EmployeeInfoSettings;
