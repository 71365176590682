import React, { useRef, useState } from "react";
import { Stage, Layer, Line, Text } from "react-konva";
// import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useRecoilState } from "recoil";
import { ElecLabelAtom } from "../../../recoil";

const DrawSign = ({ targetIndex, setOpen, open }) => {
  const [tool, setTool] = useState("pen");
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);
  const stageRef = useRef(null);
  const [labels, setLabels] = useRecoilState(ElecLabelAtom);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };
  const handleExport = () => {
    const uri = stageRef.current.toDataURL();

    const newLabels = labels.map((label, i) => {
      if (i === targetIndex) {
        return { ...label, img: uri, isFulfilled: true };
      }
      return label;
    });

    setLabels(newLabels);
    setOpen(false);
  };
  return (
    <div>
      <div style={{ border: "1px solid #eee", width: "300px" }}>
        <Stage
          ref={stageRef}
          width={300}
          height={300}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
        >
          <Layer>
            {lines.map((line, i) => {
              return (
                <Line
                  key={i}
                  points={line.points}
                  stroke="black"
                  strokeWidth={line.tool === "eraser" ? 500 : 5}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    line.tool === "eraser" ? "destination-out" : "source-over"
                  }
                />
              );
            })}
          </Layer>
        </Stage>
        <select
          value={tool}
          onChange={(e) => {
            setTool(e.target.value);
          }}
        >
          <option value="pen">Pen</option>
          <option value="eraser">Eraser</option>
        </select>
      </div>

      <div className="modalfoot">
        <div className="btn-group">
          <button className="primarybtn-lg" onClick={handleExport}>
            입력하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default DrawSign;
