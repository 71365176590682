import { atom, selectorFamily, useRecoilValue } from "recoil";
import { get as getQuery, put, uploadfile } from ".";
import { authAtom } from "..";
import { v4 as uuidv4 } from "uuid";
import { useAccoutTypeActions } from "./auth";
import dayjs from "dayjs";
import { ACCOUNTTYPE } from "../../JSON/enum/account";

export const MyInfoUpdate = atom({
  key: "MyInfoUpdate",
  default: 0,
});

export const CompanyMyInfoQuery = selectorFamily({
  key: "CompanyMyInfoQuery",
  get:
    () =>
    async ({ get }) => {
      get(MyInfoUpdate);
      const companyId = get(authAtom)?.user?.id;
      const res = await getQuery("company/home/myinfo", { companyId });
      return res.data;
    },
});

export const EmployeeMyInfoQuery = selectorFamily({
  key: "EmployeeMyInfoQuery",
  get:
    () =>
    async ({ get }) => {
      get(MyInfoUpdate);
      const id = get(authAtom)?.user?.id;
      const res = await getQuery("employee/home/myinfo", { id });
      return res.data;
    },
});

export const CompanymployeeInfoQuery = selectorFamily({
  key: "CompanymployeeInfoQuery",
  get:
    () =>
    async ({ get }) => {
      const auth = get(authAtom);
      if (auth.user.isCompany) {
        const res = await getQuery("company/home/employeework", {
          COMP_CD: auth.user.COMP_CD,
        });
        return res.data;
      } else {
        const res = await getQuery("admin/home/employeework");
        return res.data;
      }
    },
});

const useMainActions = () => {
  const auth = useRecoilValue(authAtom);
  const accountType = useAccoutTypeActions();

  return {
    putCompanyMyInfo,
    putEmployeeMyInfo,
    getTotalVacationCnt,
    getMemberCnt,
    getCheckWorkstamp,
    getEmployeeInfo,
    getCompanyVacationCnt,
    getCompanyEmployeeCnt,
    getWorkOrder,
    getScheduleToday,
    getVacationList,
    getNoticeList,
    setSign,
    getPopup,
    getSettingMenu,
    getCommonCode,
  };

  async function putCompanyMyInfo(data) {
    const res = await put("company/home/myinfo", {
      companyId: auth.user.id,
      ...data,
    });
    return res;
  }

  async function putEmployeeMyInfo(data) {
    const res = await put("employee/home/myinfo", {
      id: auth.user.id,
      ...data,
    });
    return res;
  }

  async function getTotalVacationCnt() {
    const res = await getQuery("admin/home/vacation");
    return res;
  }

  async function getMemberCnt() {
    const res = await getQuery("admin/home/totalcnt");
    return res;
  }

  async function getCheckWorkstamp() {
    const res = await getQuery("employee/check/workstamp", {});
    return res;
  }

  async function getEmployeeInfo() {
    const res = await getQuery("employee/home/work", {
      id: auth.user.id,
    });
    return res;
  }

  async function getCompanyVacationCnt() {
    const res = await getQuery("company/home/vacation", {
      companyId: auth.user.id,
    });
    return res;
  }

  async function getCompanyEmployeeCnt() {
    const res = await getQuery("company/home/companycnt", {
      companyId: auth.user.id,
    });
    return res;
  }

  async function getWorkOrder(data) {
    let params = {
      ...data,
      page: 1,
      count: 5,
    };
    if (accountType.isCompany()) params.id = auth.user.id;
    if (accountType.isEmployee()) params.id = auth.user.id;

    const res = await getQuery(`${auth.accountType}/workorder`, params);
    return res;
  }

  async function getScheduleToday(data) {
    let params = {
      ...data,
      page: 1,
      count: 100,
    };

    if (accountType.isCompany()) params.id = auth.user.id;
    if (accountType.isEmployee()) params.id = auth.user.id;

    const res = await getQuery(`${auth.accountType}/schedule/list`, params);
    return res;
  }

  async function getVacationList(data) {
    let params = {
      ...data,
      page: 1,
      count: 3,
      starttime: dayjs().subtract(1, "year").format("YYYYMMDD"),
      endtime: dayjs().format("YYYYMMDD"),
    };
    // if (accountType.isEmployee()) params.id = auth.user.id;
    // if (accountType.isCompany()) params.companyId = auth.user.id;

    const res = await getQuery(
      `${auth.accountType}/vacation/application`,
      params
    );
    return res;
  }

  async function getNoticeList() {
    const res = await getQuery(
      "select/noticeList",
      auth.user.isAdmin
        ? {
            page: 1,
            count: 3,
          }
        : (auth.user.isCompany || auth.user.isEmployee) && {
            page: 1,
            count: 3,
            COMP_CD: auth.user.COMP_CD,
          }
    );
    return res;
  }

  async function setSign(data) {
    let params = {
      ...data,
    };
    accountType.isCompany() && (params.id = auth.user.id);
    accountType.isEmployee() && (params.id = auth.user.id);

    await uploadfile({ url: params.url[0] }, uuidv4()).then((res) => {
      const res2 = put(`sign/${auth.accountType}`, {
        ...params,
        url: `${res[1].data}?alt=media`,
        filename: res[0].name,
        extention: res[0].name?.split(".").pop(),
      });
      return res2;
    });
  }

  async function getPopup(data, url = `${auth.accountType}/home/popup`) {
    return await getQuery(url, {
      accountType: auth.user.isAdmin
        ? ACCOUNTTYPE.ADMIN
        : auth.user.isCompany
        ? ACCOUNTTYPE.COMPANY
        : auth.user.isEmployee && ACCOUNTTYPE.EMPLOYEE,
    });
  }

  async function getSettingMenu() {
    const res = await getQuery(`${auth.accountType}/settings/menu`);
    return res;
  }

  async function getCommonCode() {
    const res = await getQuery(`login/commonCode`);
    return res;
  }
};

export default useMainActions;
