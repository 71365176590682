import { useState } from "react";
import { useUserActions } from "../../../recoil/api/auth";
import CommonModal from "../../modal/CommonModal";
import FindPhone from "./FindPhone.jsx";
import PWPop from "../../../img/pass_pop.png";
import PasswordFound from "./PasswordFound";
import { toastAtom } from "../../../recoil";
import { useRecoilState } from "recoil";
import { emailRegex, phoneRegex } from "../../../utils/regex";

const FindPasswordModal = ({ open, setOpen }) => {
  const userActions = useUserActions();
  const [id, setId] = useState("");
  const [phone, setPhone] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [toast, setToast] = useRecoilState(toastAtom);

  const onSubmit = async () => {
    if (!id) {
      setToast({ ...toast, error: true, message: "아이디를 입력하세요." });
      return false;
    }
    if (!phone) {
      setToast({ ...toast, error: true, message: "휴대폰번호를 입력하세요." });
      return false;
    }
    if (!phoneRegex.test(phone)) {
      setToast({
        ...toast,
        error: true,
        message: "휴대폰번호 양식에 맞지 않습니다.",
      });
      return false;
    }
    const res = await userActions.findPassword({
      id,
      tel: phone,
    });

    if (res.status === 200) {
      setIsSuccess(true);
    } else {
      setToast({
        ...toast,
        error: true,
        message: "비밀번호를 찾을 수 없습니다.",
      });
    }
  };

  return (
    <CommonModal title="비밀번호 찾기" open={open} setOpen={setOpen}>
      {!isSuccess && (
        <>
          <div className="id_tit idpass">
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <img
                src={PWPop}
                alt="비밀번호 아이콘"
                style={{ display: "inline-block", verticalAlign: "top" }}
              />
            </span>
            <div className="text textin">
              <strong>비밀번호가 기억나지 않으세요?</strong>
              <p>아이디와 가입시 사용한 휴대폰번호를 입력해주세요.</p>
              <p>임시 비밀번호를 문자로 보내드립니다.</p>
            </div>
          </div>
          <div className="id_input lotable">
            <input
              value={id}
              onChange={(e) => setId(e.target.value)}
              type="text"
              placeholder="아이디"
            />
            <FindPhone setPhone={setPhone} />
            <div className="s-button s-idpass cf">
              <button
                className="id_sear id_width sc"
                onClick={() => onSubmit()}
              >
                비밀번호 찾기
              </button>
            </div>
          </div>
        </>
      )}
      {isSuccess && (
        <PasswordFound
          id={id}
          setOpen={setOpen}
          setId={setId}
          setIsSuccess={setIsSuccess}
          phone={phone}
        />
      )}
    </CommonModal>
  );
};
export default FindPasswordModal;
