import { useRecoilValue } from "recoil";
import { del, post, uploadfile, put, getPage } from ".";
import { authAtom } from "..";

export const useDocActions = () => {
  const auth = useRecoilValue(authAtom);

  return { editInnerDoc, postInnerDoc, deleteInnerDoc, getDocList };

  async function editInnerDoc(data) {
    let params = {
      ...data,
      sn: data.sn,
      type: data.type.value,
    };

    if (params?.file[0]?.size) {
      const [file, fileRes] = await uploadfile({ file: params.file[0] });

      params = {
        ...params,
        filename: file.name,
        extension: file.name.split(".").pop(),
        url: `${fileRes.data}?alt=media`,
        file: null,
      };
    }

    const res = await put("company/innerdoc", params);
    return res;
  }

  async function deleteInnerDoc(data) {
    const res = await del("company/innerdoc", data);
    return res;
  }

  async function postInnerDoc(data) {
    let params = {
      ...data,
      companyId: auth.user.id,
      type: 1,
    };

    const [file, fileRes] = await uploadfile({ file: params.file[0] });

    params = {
      ...params,
      filename: file.name,
      extension: file.name.split(".").pop(),
      url: `${fileRes.data}?alt=media`,
      file: null,
    };

    const res = await post("company/innerdoc", params);
    return res;
  }

  async function getDocList(params) {
    const res = await getPage(`${auth.accountType}/innerdoc`, params);
    return res;
  }
};
