import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import useSettngActions from "../../../recoil/api/settings";
import { PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchKeyword from "../../common/SearchKeyword";
import CommonModal from "../../modal/CommonModal";
import Select from "react-select";

const CompanyRegist = () => {
  const settingActions = useSettngActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [isRegistModalOpen, setIsRegistModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [bn, setBn] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [editTargetCompany, setEditTargetCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");

  const [editCompanyName, setEditCompanyName] = useState("");
  const [editBn, setEditBn] = useState("");
  const [editCOMP_CD, setEditCOMP_CD] = useState("");
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const [keywordType, setKeyWordType] = useState("");

  const keyT = [
    {
      value: null,
      label: "전체",
    },
    {
      value: "name",
      label: "업체명",
    },
    {
      value: "bn",
      label: "사업자번호",
    },
  ];

  useEffect(() => {
    getCompanyList();
  }, [pageInfo?.current, keyword]);

  const getCompanyList = async () => {
    setLoading(true);
    const res = await settingActions.getCompanyList({
      pageInfo,
      keywordType,
      keyword,
    });
    if (res) {
      setCompanyList(res.data.list);
      setPageInfo(res.data.pageinfo);
    }
    setLoading(false);
  };

  const onRegist = async () => {
    if (companyName.length === 0) {
      setToast({ ...toast, error: true, message: "업체명을 입력해 주세요." });
      return false;
    }
    if (bn.length !== 10) {
      setToast({
        ...toast,
        error: true,
        message: "사업자 등록번호 형식이 올바르지 않습니다.",
      });
      return false;
    }

    setLoading(true);
    const res = await settingActions.companyRegist({
      companyName,
      bn,
    });

    if (res.status === 200) {
      if (res.data.result === "fail") {
        setToast({ ...toast, error: true, message: "이미 등록된 기업입니다." });
      } else {
        setCompanyName("");
        setIsRegistModalOpen(false);
        setToast({ ...toast, on: true });
        getCompanyList();
      }
    }
    setLoading(false);
  };

  const onEdit = async () => {
    if (editCompanyName.length === 0) {
      setToast({ ...toast, error: true, message: "업체명을 입력해 주세요." });
      return false;
    }
    if (editBn.length !== 10) {
      setToast({
        ...toast,
        error: true,
        message: "사업자 등록번호 형식이 올바르지 않습니다.",
      });
      return false;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);
    setIsEditModalOpen(false);
    setLoading(true);

    const res = await settingActions.companyEdit({
      COMP_CD: editCOMP_CD,
      companyName: editCompanyName,
      bn: editBn,
    });

    if (res.status === 200) {
      if (res.data.result === "fail") {
        setToast({ ...toast, error: true, message: "이미 등록된 기업입니다." });
      } else {
        setCompanyName("");
        setIsRegistModalOpen(false);
        setToast({ ...toast, on: true });
        getCompanyList();
      }
    }
    setLoading(false);
    setDoubleclickLoading(false);
  };

  const onDelete = async (COMP_CD) => {
    setLoading(true);

    const res = await settingActions.deleteCompany({
      COMP_CD,
    });

    if (res.status === 200) {
      setToast({ ...toast, on: true });
      getCompanyList();
    }
    setLoading(false);
  };

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <div className="date-box float-left">
            <div className="btn-group">
              <button
                className="primarybtn-md border-line mouse zoom_font pencil-img"
                onClick={() => setIsRegistModalOpen(true)}
              >
                업체 등록
              </button>
            </div>
          </div>
        </div>
        <div className="all-group">
          <div className="btn-group">
            <div className=" select-border-line">
              <Select
                options={keyT}
                placeholder={"검색조건"}
                onChange={(e) => {
                  setKeyWordType(e.value);
                }}
              />
            </div>
            <SearchKeyword setKeyword={setKeyword} />
          </div>
        </div>
      </div>
      <DataTable
        columns={["업체명", "사업자등록번호", "활성화", "관리"]}
        checkbox={false}
        loading={loading}
      >
        {companyList?.map((company) => {
          return (
            <tr key={company.COMP_CD}>
              <td style={{ width: 200 }} className="border-line zoom_font">
                {company.name}
              </td>
              <td className="border-line zoom_font">{`${company.bn?.slice(
                0,
                3
              )}-${company.bn?.slice(3, 5)}-${company.bn?.slice(5, 10)}`}</td>
              <td className="border-line zoom_font">
                <input
                  type={"checkbox"}
                  checked={company.isactivate}
                  onChange={async () => {
                    await settingActions.companyEditActive({
                      COMP_CD: company.COMP_CD,
                      isactivate: !company.isactivate,
                    });
                    await getCompanyList();
                  }}
                />
              </td>
              <td>
                <div className="btn-group">
                  <button
                    className="primarybtn-sm border-line zoom_font"
                    onClick={() => {
                      setIsEditModalOpen(true);
                      setEditTargetCompany(company);
                      setEditCOMP_CD(company.COMP_CD);
                    }}
                  >
                    수정
                  </button>
                  {company.isDeletePossible === 0 && (
                    <button
                      className="pinkbtn-sm border-line zoom_font"
                      onClick={() => {
                        if (window.confirm("삭제하시겠습니까?")) {
                          onDelete(company.COMP_CD);
                        }
                      }}
                    >
                      삭제
                    </button>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </DataTable>
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
      <CommonModal
        title="업체 등록하기"
        open={isRegistModalOpen}
        setOpen={setIsRegistModalOpen}
      >
        <table className="writetable" style={{ border: "1px solid #e5e5e5" }}>
          <tbody>
            <tr>
              <th className="text-center-title w-30 zoom_font">기업명</th>
              <td className="zoom_font">
                <input
                  placeholder="업체명을 입력하세요"
                  className="write-input border-line mouse zoom_font"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value.trim())}
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title w-30 zoom_font">
                사업자등록번호
              </th>
              <td className="zoom_font">
                <input
                  placeholder="'-'없이 숫자만 입력하세요"
                  type="number"
                  className="write-input border-line mouse zoom_font"
                  maxLength={10}
                  value={bn}
                  onChange={(e) => setBn(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-group">
          <button
            className="fbtn submitbtn"
            type="submit"
            onClick={() => onRegist()}
          >
            저장
          </button>
          <button
            type="button"
            className="fbtn cancelbtn"
            onClick={() => setIsRegistModalOpen(false)}
          >
            취소
          </button>
        </div>
      </CommonModal>
      <CommonModal
        title="기업 수정"
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
      >
        <table className="writetable" style={{ border: "1px solid #e5e5e5" }}>
          <tbody>
            <tr>
              <th className="text-center-title w-30 zoom_font">업체명</th>
              <td className="zoom_font">
                <input
                  className="write-input border-line mouse zoom_font"
                  readOnly
                  value={editTargetCompany.name || ""}
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title w-30 zoom_font">
                사업자등록번호
              </th>
              <td className="zoom_font">
                <input
                  className="write-input border-line mouse zoom_font"
                  readOnly
                  value={
                    editTargetCompany.bn
                      ? `${editTargetCompany.bn?.slice(
                          0,
                          3
                        )}-${editTargetCompany.bn?.slice(
                          3,
                          5
                        )}-${editTargetCompany.bn?.slice(5, 10)}`
                      : ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title w-30 zoom_font">업체명 변경</th>
              <td className="zoom_font">
                <input
                  className="write-input border-line mouse zoom_font"
                  onChange={(e) => {
                    setEditCompanyName(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th className="text-center-title w-30 zoom_font">
                사업자등록번호 변경
              </th>
              <td className="zoom_font">
                <input
                  placeholder="'-'없이 숫자만 입력하세요"
                  className="write-input border-line mouse zoom_font"
                  onChange={(e) => {
                    setEditBn(e.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-group">
          <button
            className="fbtn submitbtn"
            type="submit"
            onClick={() => onEdit()}
          >
            저장
          </button>
          <button
            type="button"
            className="fbtn cancelbtn"
            onClick={() => setIsEditModalOpen(false)}
          >
            취소
          </button>
        </div>
      </CommonModal>
    </div>
  );
};

export default CompanyRegist;
