export const Toolbar = (props) => {
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-font"></select>
        <select className="ql-size" defaultValue="large">
          <option value="small">작게</option>
          <option value="large">기본</option>
          <option value="huge">크게</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-strike"></button>
      </span>
      <span className="ql-formats">
        <select className="ql-color"></select>
        <select className="ql-background"></select>
      </span>
      <select className="ql-header">
        <option value="1">Header 1</option>
        <option value="2">Header 2</option>
        <option value="3">Header 3</option>
        <option value="4">Header 4</option>
        <option value="5">Header 5</option>
        <option value="6">Header 6</option>
      </select>
      <span className="ql-formats">
        <button className="ql-blockquote"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-indent" value="-1"></button>
        <button className="ql-indent" value="+1"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-direction" value="rtl"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-link"></button>
        {props?.images && <button className="ql-image"></button>}
      </span>
      <span className="ql-formats">
        <button className="ql-clean"></button>
      </span>
    </div>
  );
};

export default Toolbar;
