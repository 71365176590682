import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { HOLIDAYTYPE } from "../../../JSON/enum/Dataenum";

const VacationFunction = () => {
  const auth = useRecoilValue(authAtom);

  return {
    annualOptions,
    annualOptionsEmployee,
  };

  function annualOptions() {
    return [
      {
        value: null,
        label: "모든 휴가",
      },
      {
        value: HOLIDAYTYPE.DAYOFF,
        label: auth?.commonCode[27]["연차"],
      },
      {
        value: HOLIDAYTYPE.HALFAM,
        label: auth?.commonCode[28]["오전 반차"],
      },
      {
        value: HOLIDAYTYPE.HALFPM,
        label: auth?.commonCode[29]["오후 반차"],
      },
      {
        value: HOLIDAYTYPE.ETC,
        label: auth?.commonCode[30]["기타 휴가"],
      },
      {
        value: HOLIDAYTYPE.CONGRATULATION,
        label: auth?.commonCode[31]["경조사"],
      },
      {
        value: HOLIDAYTYPE.LEAVE,
        label: auth?.commonCode[32]["병가"],
      },
      {
        value: HOLIDAYTYPE.HALFHALF,
        label: auth?.commonCode[33]["반반차"],
      },
    ];
  }

  function annualOptionsEmployee() {
    return [
      {
        value: HOLIDAYTYPE.DAYOFF,
        label: auth?.commonCode[27]["연차"],
      },
      {
        value: HOLIDAYTYPE.HALFAM,
        label: auth?.commonCode[28]["오전 반차"],
      },
      {
        value: HOLIDAYTYPE.HALFPM,
        label: auth?.commonCode[29]["오후 반차"],
      },
      {
        value: HOLIDAYTYPE.ETC,
        label: auth?.commonCode[30]["기타 휴가"],
      },
      {
        value: HOLIDAYTYPE.CONGRATULATION,
        label: auth?.commonCode[31]["경조사"],
      },
      {
        value: HOLIDAYTYPE.LEAVE,
        label: auth?.commonCode[32]["병가"],
      },
      // {
      //   value: HOLIDAYTYPE.HALFHALF,
      //   label: auth?.commonCode[33]["반반차"],
      // },
    ];
  }
};

export default VacationFunction;
