exports.ACCOUNTTYPE ={
  ADMIN:1,
  COMPANY:2,
  EMPLOYEE:3
}

exports.ACCOUNTTYPEENUM ={
  ADMIN: 1,
  COMPANY: 2,
  EMPLOYEE: 3
}

exports.JSONKEYS={
  ID : "id",
  ACCOUNTTYPE:"accountType",
  USERNAME : "userName",
  COMP_CD : "COMP_CD",
  ESN : "esn"
}