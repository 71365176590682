import { Outlet } from "react-router-dom";
import Navbar from "../common/Navbar";
import Toast from "./Toast";
import { ChattingLayout } from "./chatting/ChattingLayout";
import { useRecoilValue } from "recoil";
import { AccessibilibyAtom } from "../../recoil";
import { useEffect, useState } from "react";
import MailLayout from "./mail/MailLayout";

const CommonLayout = () => {
  const accessibility = useRecoilValue(AccessibilibyAtom);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [textContent, setTextContent] = useState("");

  //임시 제거

  useEffect(() => {
    if (
      accessibility.mask ||
      accessibility.guideLine ||
      accessibility.zoomLetter
    ) {
      const handleWindowMouseMove = (event) => {
        setCoords({
          x: event.pageX,
          y: event.pageY,
        });
      };
      window.addEventListener("mousemove", handleWindowMouseMove);
      return () => {
        window.removeEventListener("mousemove", handleWindowMouseMove);
      };
    }
  }, [
    accessibility.mask || accessibility.guideLine || accessibility.zoomLetter,
  ]);

  useEffect(() => {
    if (accessibility.zoomLetter) {
      const handleWindowMouseMove = (event) => {
        if (event.target.className.includes("zoom_font")) {
          setTextContent(event.target.textContent);
        } else {
          setTextContent("");
        }

        // dangerouslySetInnerHTML에서 글자 돋보기 쓰기 위한 조건문
        if (event.target.parentElement.className.includes("zoom_font_id")) {
          setTextContent(event.target.textContent);
        }
      };
      window.addEventListener("mouseover", handleWindowMouseMove);
      return () => {
        window.removeEventListener("mouseover", handleWindowMouseMove);
      };
    }
  }, [accessibility.zoomLetter]);

  return (
    <>
      {/* <Header /> */}

      <div className="section">
        {/* 확대기능 */}
        {accessibility.zoom && (
          <link
            rel="stylesheet"
            type="text/css"
            href="/accessibility/zoom.css"
          />
        )}
        {accessibility.pointer && (
          <link
            rel="stylesheet"
            type="text/css"
            href="/accessibility/pointer.css"
          />
        )}
        {accessibility.grayPointer && (
          <link
            rel="stylesheet"
            type="text/css"
            href="/accessibility/grayPointer.css"
          />
        )}
        {/* 읽기기능 */}

        {accessibility.guideLine && (
          <div
            style={{
              position: "absolute",
              width: 200,
              height: 8,
              top: coords.y + 5,
              left: coords.x - 100,
              zIndex: 999,
              background: "#23cdb7",
              border: "1px solid white",
            }}
          ></div>
        )}
        {accessibility.mask && (
          <>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: coords.y - 100,
                // top: coords.y + 5,
                left: 0,
                zIndex: 999,
                background: "#23cdb7",
                filter: "grayscale(1)",
                opacity: 0.7,
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                width: "100%",
                height: 800 - coords.y,
                // top: coords.y + 5,
                bottom: 0,
                left: 0,
                zIndex: 999,
                background: "#23cdb7",
                filter: "grayscale(1)",
                opacity: 0.7,
              }}
            ></div>
          </>
        )}
        {accessibility.zoomLetter && (
          <div
            style={{
              position: "absolute",
              top: coords.y + 5,
              left: coords.x + 10,
              zIndex: 999,
              background: "black",
              color: "white",
              fontSize: 24,
            }}
          >
            {textContent}
          </div>
        )}
        {/* 영역표시기능 */}
        {accessibility.allArea && (
          <link
            rel="stylesheet"
            type="text/css"
            href="/accessibility/allArea.css"
          />
        )}
        {accessibility.selectedArea && (
          <link
            rel="stylesheet"
            type="text/css"
            href="/accessibility/selectedArea.css"
          />
        )}
        {accessibility.grayscale && (
          <link
            rel="stylesheet"
            type="text/css"
            href="/accessibility/grayscale.css"
          />
        )}
        <Navbar />
        <div className="main-container">
          <div className="substance">
            <Outlet />
          </div>
        </div>
      </div>
      <Toast />
      <ChattingLayout />
      <MailLayout />
    </>
  );
};

export default CommonLayout;
