import { useEffect, useState } from "react";
import "../../css/toast.css";

// CommonModal과 완전히 같은 역할을 합니다
// open 상태에 따라 계속 렌더링을 하기에 창을 다시 열면 안에 있던 내용이 초기화됩니다
const CommonModalScrollTop = ({
  open,
  setOpen = () => {},
  title,
  onChangeModalState = () => {},
  children,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    onChangeModalState(false);
  };
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return open === true ? (
    <div>
      <div style={{ display: isOpen ? "block" : "none" }} id="modal">
        <div id="modalwin" style={{ maxWidth: width, minWidth: width }}>
          <div id="modalhead">
            <span>{title}</span>
            <button id="modalclose" onClick={() => closeModal()}>
              &times;
            </button>
          </div>
          <div id="modalsec">
            <div id="modaltable">{children}</div>
          </div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default CommonModalScrollTop;
