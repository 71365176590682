import { useParams, useNavigate } from "react-router-dom";
import { authAtom } from "../../../recoil";
import { useRecoilValue } from "recoil";
import BreadCrumb from "../BreadCrumb";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { useApprovalActions } from "../../../recoil/api/approval";
import { useEffect } from "react";
import { useState } from "react";
import {
  getApprovalDocDetailType,
  getApprovalDocType,
} from "../Approval/ApprovalDetailModal";
import { useDocformActions } from "../../../recoil/api/docform";
import { toastAtom } from "../../../recoil";
import { useRecoilState } from "recoil";
import { QuillEditor } from "../../toolbar/QuillEditor";

const DocumentFormWrite = () => {
  const { type, sn } = useParams();
  const navigate = useNavigate();
  const { register, control, setValue, handleSubmit } = useForm();
  const auth = useRecoilValue(authAtom);
  const [docForm, setDocForm] = useState({});
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);
  const [defaultCheck, setDefaultCheck] = useState("");

  const { getApprovalForm } = useApprovalActions();
  const { putDocForm } = useDocformActions();
  const [toast, setToast] = useRecoilState(toastAtom);

  const getDocForm = async (data) => {
    const res = await getApprovalForm(data);
    setDocForm(res);
    if (res.data.COMP_CD === null) {
      setDefaultCheck("insert");
    } else {
      setDefaultCheck("update");
    }
    setValue("editor", res.data.form);
  };

  useEffect(() => {
    getDocForm({ type });
  }, [type]);

  const onSubmit = async (data) => {
    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    const res = await putDocForm({
      form: data.editor,
      sn,
      flag: defaultCheck,
      type: type,
    });
    if (res.status === 200) {
      setToast({ ...toast, on: true, message: "저장했습니다." });
      navigate("/company/docform");
    } else {
      setToast({ ...toast, error: true, message: "저장에 실패했습니다." });
    }

    setDoubleclickLoading(false);
  };

  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table className="writetable">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font">양식 구분</th>
                <td className="zoom_font" colSpan={3}>
                  {getApprovalDocType(type)}
                </td>
              </tr>
              <tr>
                <th className="text-center-title w-15 zoom_font">양식 타입</th>
                <td className="zoom_font">{getApprovalDocDetailType(type)}</td>
                <th className="text-center-title w-15 zoom_font">작성일</th>
                <td className="zoom_font">{dayjs().format("YYYY-MM-DD")}</td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">내용</th>
                <td colSpan="3" style={{ height: "300px" }}>
                  <Controller
                    name="editor"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Editor
                        apiKey={
                          "pwlbxd7c7x9lapnd8nwqidzm2gre8bazisoovnitdbojymrp"
                        }
                        value={value}
                        onEditorChange={onChange}
                        init={{
                          plugins: ["table", "link", "autolink", "contextmenu"],
                          language: "ko_KR",
                          selector: "div",
                          elementpath: false,
                          branding: false,
                          autolink: true,
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                          contextmenu: "true",
                          menubar: "file insert view format table",
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="writebtnbox">
          <button className="writebtn submitbtn border-line mouse zoom_font">
            저장
          </button>
          <button
            onClick={() => navigate(`/${auth.accountType}/docform`)}
            className="writebtn cancelbtn border-line mouse zoom_font"
            type="button"
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};

export default DocumentFormWrite;
