import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { getPage } from "../../../recoil/api";
import { ChatDetailRoomSnAtom } from "../../../recoil/api/monitoring";
import { PageInfoState } from "../../../recoil/types/data";
import CommonModal from "../../modal/CommonModal";

const ChatModal = ({ open, setOpen }) => {
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const messagesRef = useRef();
  const [loading, setLoading] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const chatDetailRoomSn = useRecoilValue(ChatDetailRoomSnAtom);
  const loadingTriggerRef = useRef(null);
  const scrollBoxRef = useRef(null);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [chatKeyword, setChatKeyword] = useState("");

  // 옵저버 실행
  // const onIntersect = async ([entry], observer) => {
  //   if (entry.isIntersecting) {
  //     observer.unobserve(entry.target);
  //     //LOGIC
  //     setPageInfo(() => {
  //       return { ...pageInfo, current: pageInfo.current + 1 };
  //     });

  //     loadMoreChat();
  //     scrollBoxRef.current.scrollTop =
  //       scrollBoxRef.current.scrollHeight - scrollHeight;
  //     setScrollHeight(scrollBoxRef.current.scrollHeight);

  //     setLoadingMoreData(true);
  //     observer.observe(entry.target);
  //   }
  // };

  // useEffect(() => {
  // }, [pageInfo]);

  // useEffect(() => {
  //   if (open && !loading && pageInfo?.total >= pageInfo?.current) {
  //     let options = {
  //       root: scrollBoxRef.current,
  //       rootMargin: "0px",
  //       threshold: 1.0,
  //     };

  //     let observer = new IntersectionObserver(onIntersect, options);

  //     observer.observe(loadingTriggerRef.current);
  //     return () => observer.disconnect();
  //   }
  // });

  //initial
  useEffect(() => {
    if (open) {
      async function loadChat() {
        const room_sn = chatDetailRoomSn;
        const res = await getPage("admin/chat/room", {
          room_sn,
          page: 1,
          count: 100000,
        });
        if (res.status === 200) {
          setChatData(res.data.list);
          setPageInfo(res.data.pageinfo);
        }
      }
      loadChat();
    }
  }, [open]);

  return (
    <CommonModal title="채팅 모니터링" open={open} setOpen={setOpen}>
      <input
        style={{ width: "100%", margin: "10px 0px" }}
        placeholder="검색할 대화를 입력하세요."
        className="custom-input"
        type="text"
        onChange={async (e) => {
          setChatKeyword(e.target.value);
        }}
      />
      <div
        className="chat-content chat-scroll"
        style={{ height: 308, backgroundColor: "#dfe3e8" }}
        ref={scrollBoxRef}
      >
        {/* {pageInfo?.total >= pageInfo?.current && (
          <div ref={loadingTriggerRef}>
            <div className="flex-start counterpart">
              <div className="m-width-50">
                <p className="chat-cont-text">loading...</p>
              </div>
            </div>
          </div>
        )} */}
        {chatData
          ?.filter((item, idx) =>
            JSON.parse(item.contents)?.content?.includes(chatKeyword)
          )
          .map((chat, i) => {
            return (
              <div key={i}>
                <div className="flex-start counterpart">
                  <div className="m-width-50">
                    <p className="user-name">
                      {chat?.name} ({chat.id})
                    </p>
                    <p className="chat-cont-text">
                      {JSON.parse(chat.contents).content}
                    </p>
                  </div>
                  <div className="timebox">
                    <p>{dayjs(chat.createtime).format("YYYY-MM-DD HH:mm")}</p>
                  </div>
                </div>
              </div>
            );
          })}
        <div style={{ float: "left", clear: "both" }} ref={messagesRef}></div>
      </div>
    </CommonModal>
  );
};

export default ChatModal;
