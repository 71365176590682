import React, { useEffect, useRef, useState } from "react";
import { Stage, Layer, Image, Rect, Text, Transformer } from "react-konva";
import { getDocument } from "pdfjs-dist/webpack";
import useImage from "use-image";
import { useRecoilState } from "recoil";
import {
  ElecLabelAtom,
  ElecPageInfoAtom,
  ElecTextareaAtom,
} from "../../../recoil";
import CommonModal from "../../modal/CommonModal";
import DrawSign from "./DrawSign";
import CompanyStamp from "../../../img/elec/elec_sign2.svg";
import EmployeeStamp from "../../../img/elec/elec_sign.svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import UploadStamp from "./UploadStamp";
import { JOINDOCTYPE } from "../../../JSON/enum/Dataenum";

export const URLImage = ({ src }) => {
  const transformRef = useRef();
  const [image] = useImage(src);
  useEffect(() => {}, [image]);
  return <Image image={image} ref={transformRef} />;
};

const StampImage = ({
  src,
  label,
  setIsStampModalOpen,
  mode,
  onDragEnd,
  isCompany,
  setImageTargetIndex,
  i,
}) => {
  const stampImage = label.img || src;
  const [image] = useImage(stampImage);

  return (
    <Image
      onClick={() => {
        if (mode === "sign" && isCompany === label.isCompany) {
          setImageTargetIndex(i);
          setIsStampModalOpen(true);
        }
      }}
      draggable={mode === "create"}
      onDragEnd={(e) => onDragEnd(e)}
      width={label.img ? 80 : 40}
      height={label.img ? 80 : 40}
      x={label.img ? label.x - 20 : label.x}
      y={label.img ? label.y - 20 : label.y}
      image={image}
    />
  );
};

const TextBackground = ({
  onDeselect,
  onDragMove,
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  draggable,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  return (
    <React.Fragment>
      <Rect
        onDblClick={onDeselect}
        onDragMove={onDragMove}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        fill={shapeProps.isCompany ? "#cefff9" : "#fff8d9"}
        draggable={draggable}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export const Textarea = () => {
  const [textarea, setTextarea] = useRecoilState(ElecTextareaAtom);
  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const textareaRef = useRef();
  const [text, setText] = useState("");

  useEffect(() => {
    setText(textarea.text);
  }, [textarea]);

  const handleClickOutSide = (e) => {
    if (textarea.show && !textareaRef.current.contains(e.target)) {
      setTextarea(false);
    }
  };

  useEffect(() => {
    if (textarea.show)
      document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  });

  return (
    <textarea
      ref={textareaRef}
      style={{
        display: textarea.show ? "block" : "none",
        border: "1px solid black",
        position: "absolute",
        top: textarea.y,
        left: textarea.x,
        zIndex: 9999,
        fontSize: 14,
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setTextarea({ ...textarea, show: false });
          const newLabels = labels.map((label, i) => {
            if (i === textarea.targetIndex) {
              let isFulfilled = true;
              if (text.length === 0) {
                isFulfilled = false;
              }
              return { ...label, text: text, isFulfilled };
            } else {
              return label;
            }
          });
          setLabels(newLabels);
        }
      }}
      value={text}
      onChange={(e) => setText(e.target.value)}
    ></textarea>
  );
};

const ViewElec = ({ mode, isCompany, src, stageRef, type }) => {
  const [labels, setLabels] = useRecoilState(ElecLabelAtom);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [pdf, setPdf] = useState("");
  const [pdfRendering, setPdfRendering] = useState("");
  const [pageRendering, setPageRendering] = useState("");
  const [images, setImages] = useState([]);
  const [pageInfo, setPageInfo] = useRecoilState(ElecPageInfoAtom);
  const [textarea, setTextarea] = useRecoilState(ElecTextareaAtom);
  const [isStampModalOpen, setIsStampModalOpen] = useState(false);
  const [imageTargetIndex, setImageTargetIndex] = useState(0);
  const [selectedShape, setSelectedShape] = React.useState(null);

  useEffect(() => {
    setPageInfo({ ...pageInfo, current: 1 });
    setTextarea({ ...textarea, show: false });
    showPdf();
  }, []);

  async function showPdf() {
    try {
      setPdfRendering(true);
      await getDocument({
        url: src,
      }).promise.then((res) => {
        setPdf(res);
      });
      setPdfRendering(false);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {}, [labels]);

  async function renderPage() {
    setPageRendering(true);

    const newPageInfo = { ...pageInfo, total: pdf.numPages };
    setPageInfo(newPageInfo);
    const page = await pdf.getPage(pageInfo.current);

    const viewport = page.getViewport({
      scale: parseInt(type) === JOINDOCTYPE.work_contract ? 1.7 : 1,
    });

    const render_context = {
      canvasContext: document.querySelector("#pdf-canvas").getContext("2d"),
      viewport: viewport,
    };
    setWidth(viewport.width);
    setHeight(viewport.height);
    await page.render(render_context).promise;
    setPageRendering(false);
  }

  useEffect(() => {
    const canvas = document.getElementById("pdf-canvas");
    const img = canvas.toDataURL("image/png");
    setImages([img]);
  }, [pageRendering]);

  useEffect(() => {
    if (pdf) {
      renderPage();
    }
  }, [pdf, pageInfo.current]);

  const toggleCheckbox = (targetIndex, labelIsCompany) => {
    if (labelIsCompany === isCompany) {
      const newLabels = labels.map((label, i) => {
        if (i === targetIndex) {
          return {
            ...label,
            isFulfilled: !label.isFulfilled,
          };
        } else {
          return label;
        }
      });
      setLabels(newLabels);
    }
  };

  const setTextareaState = ({ label, e, i }) => {
    if (label.isCompany === isCompany) {
      setTextarea({
        ...textarea,
        text: label.text,
        x: e.evt.pageX,
        y: e.evt.pageY,
        show: true,
        targetIndex: i,
      });
    }
  };

  return (
    <div>
      <canvas
        id="pdf-canvas"
        width={width}
        height={height}
        style={{ display: "none" }}
      ></canvas>

      <Textarea />
      {images[0]?.includes("png") && (
        <Stage width={width} height={height} ref={stageRef}>
          <Layer>
            {images?.map((image, i) => (
              <URLImage key={i} src={image} />
            ))}
            {labels?.map((label, i) => {
              return (
                <React.Fragment key={i}>
                  {label.type === "stamp" &&
                    label.page === pageInfo.current && (
                      <>
                        <StampImage
                          isCompany={isCompany}
                          i={i}
                          mode={mode}
                          setImageTargetIndex={setImageTargetIndex}
                          setIsStampModalOpen={setIsStampModalOpen}
                          src={label.isCompany ? CompanyStamp : EmployeeStamp}
                          label={label}
                          onDragEnd={(e) => {
                            let newLabels = [...labels];
                            newLabels[i] = {
                              ...newLabels[i],
                              isDragging: false,
                              x: e.target.x(),
                              y: e.target.y(),
                            };
                            setLabels(newLabels);
                          }}
                        />
                      </>
                    )}
                  {label.type === "text" && label.page === pageInfo.current && (
                    <>
                      {!label.isFulfilled && !label.text && (
                        <TextBackground
                          draggable={mode === "create" && i === selectedShape}
                          onDragMove={(e) => {
                            let newLabels = [...labels];
                            newLabels[i] = {
                              ...newLabels[i],
                              isDragging: false,
                              x: e.target.x(),
                              y: e.target.y(),
                            };
                            setLabels(newLabels);
                          }}
                          key={i}
                          shapeProps={label}
                          isSelected={i === selectedShape}
                          onDeselect={() => {
                            setSelectedShape(null);
                          }}
                          onSelect={(e) => {
                            if (mode === "create") {
                              setSelectedShape(i);
                            } else {
                              setTextareaState({ label, e, i });
                            }
                          }}
                          onChange={(newAttrs) => {
                            const newLabels = labels.map((label, i) => {
                              if (i === selectedShape) {
                                return newAttrs;
                              } else {
                                return label;
                              }
                            });
                            setLabels(newLabels);
                          }}
                        />
                      )}
                      {label.isFulfilled && label.text && (
                        <Text
                          fontSize={13}
                          height={20}
                          x={label.x}
                          y={label.y + 5}
                          text={label.text}
                          draggable={mode === "create"}
                          onDragMove={(e) => {
                            let newLabels = [...labels];
                            newLabels[i] = {
                              ...newLabels[i],
                              isDragging: false,
                              x: e.target.x(),
                              y: e.target.y(),
                            };
                            setLabels(newLabels);
                          }}
                          onClick={(e) => {
                            setTextareaState({ label, e, i });
                          }}
                        />
                      )}
                    </>
                  )}
                  {label.type === "checkbox" &&
                    label.page === pageInfo.current && (
                      <>
                        <Rect
                          width={20}
                          height={20}
                          x={label.x}
                          y={label.y}
                          shadowColor="grey"
                          shadowBlur={4}
                          shadowOffset={{ x: 0, y: 2 }}
                          shadowOpacity={0.5}
                          fill={label.isCompany ? "#cefff9" : "#fff8d9"}
                          draggable={mode === "create"}
                          onClick={() => {
                            toggleCheckbox(i, label.isCompany);
                          }}
                          onDragEnd={(e) => {
                            let newLabels = [...labels];
                            newLabels[i] = {
                              ...newLabels[i],
                              isDragging: false,
                              x: e.target.x(),
                              y: e.target.y(),
                            };
                            setLabels(newLabels);
                          }}
                        />
                        {mode === "sign" && (
                          <Text
                            text="V"
                            fontSize={14}
                            width={10}
                            height={10}
                            x={label.x + 5}
                            y={label.y + 5}
                            fill={label.isFulfilled ? "black" : "transparent"}
                            onClick={() => {
                              toggleCheckbox(i, label.isCompany);
                            }}
                          />
                        )}
                      </>
                    )}
                </React.Fragment>
              );
            })}
          </Layer>
        </Stage>
      )}

      <CommonModal
        title="사인/도장 입력"
        open={isStampModalOpen}
        setOpen={setIsStampModalOpen}
        width="360px"
      >
        <Tabs>
          <TabList>
            <Tab>사인 하기</Tab>
            <Tab>도장 입력</Tab>
          </TabList>

          <TabPanel>
            <DrawSign
              targetIndex={imageTargetIndex}
              open={isStampModalOpen}
              setOpen={setIsStampModalOpen}
            />
          </TabPanel>
          <TabPanel>
            <UploadStamp
              targetIndex={imageTargetIndex}
              open={isStampModalOpen}
              setOpen={setIsStampModalOpen}
            />
          </TabPanel>
        </Tabs>
      </CommonModal>
    </div>
  );
};

export default ViewElec;
