import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { WORKSTAMP } from "../../../JSON/enum/Dataenum";
import { authAtom, toastAtom } from "../../../recoil";
import {
  useCreateWorkstamp,
  useWorkstampActions,
} from "../../../recoil/api/workstamp";
import {
  ReasonAtom,
  ReasonModalAtom,
  ReasonModalTextAtom,
  WorkstampTypeAtom,
  WorkstampTimeAtom,
  DreamCafeModalAtom,
} from "../../../recoil/atoms/workstamp";
import CommonModalScrollTop from "../../modal/CommonModalScrollTop";
import useMainActions from "../../../recoil/api/main";
import Select from "react-select";
import dayjs from "dayjs";
import CommonImageModal from "../../modal/CommonImageModal";

// 미퇴근, 조기출근, 지각, 조기퇴근, 초과근무

export const workstampNotLeavingWorkList = [
  { label: "기타(직접입력)", value: "" },
];

export const workstampEarlyWorkinList = [
  { label: "기타(직접입력)", value: "" },
  {
    label: "업무 준비를 빨리 하기 위하여",
    value: "업무 준비를 빨리 하기 위하여",
  },
  {
    label: "컴퓨터 켬과 동시에 출근누름",
    value: "컴퓨터 켬과 동시에 출근누름",
  },
];

export const workstampLateWorkinList = [
  { label: "기타(직접입력)", value: "" },
  { label: "늦잠", value: "늦잠" },
  { label: "화장실", value: "화장실" },
  {
    label: "출퇴근 누르는 것을 잊어버림",
    value: "출퇴근 누르는 것을 잊어버림",
  },
];

export const workstampEarlyWorkoutList = [
  { label: "기타(직접입력)", value: "" },
  {
    label: "반차로인한 조기퇴근",
    value: "반차로인한 조기퇴근",
  },
];

export const workstampLateWorkoutList = [
  { label: "기타(직접입력)", value: "" },
  { label: "업무일지 작성을 늦게함", value: "업무일지 작성을 늦게함" },
  { label: "퇴근 누르는 것을 잊어버림", value: "퇴근 누르는 것을 잊어버림" },
];

const EmployeeWorkstampHome = () => {
  const [loading, setLoading] = useState(false);
  const workstampActions = useWorkstampActions();
  const createWorkstampActions = useCreateWorkstamp();
  const auth = useRecoilValue(authAtom);
  const [workstampType, setWorkstampType] = useRecoilState(WorkstampTypeAtom);
  const [workstampTime, setWorkstampTime] = useRecoilState(WorkstampTimeAtom);
  const [reason, setReason] = useRecoilState(ReasonAtom);
  const [reasonModalText, setReasonModalText] =
    useRecoilState(ReasonModalTextAtom);
  const [isReasonModalOpen, setIsReasonModalOpen] =
    useRecoilState(ReasonModalAtom);
  const [isDreamCafeModalOpen, setIsDreamCafeModalOpen] =
    useRecoilState(DreamCafeModalAtom);
  const [buttonC, setButtonC] = useState(false);
  const [buttonC2, setButtonC2] = useState(false);
  const [toast, setToast] = useRecoilState(toastAtom);
  const mainActions = useMainActions();
  const [checkWorkstamp, setCheckWorkstamp] = useState("");
  const [earlyworkin_time, setEarlyworkinTime] = useState(0);

  useEffect(() => {
    getEmployeeWorkstampCheckById();
  }, []);

  const getCheckWorkstamp = async () => {
    const res = await mainActions.getCheckWorkstamp();
    if (res.status === 200) {
      setCheckWorkstamp("*" + res?.data?.text);
    }
  };

  useEffect(() => {
    getCheckWorkstamp();
  }, [workstampType]);

  const getEmployeeWorkstampCheckById = async () => {
    setLoading(true);
    const res = await workstampActions.getEmployeeWorkstampCheckById({
      employee_id: auth.user.id,
    });
    if (res) {
      setWorkstampType(parseInt(res.data.workstamp));
      if (res.data.workstamp === WORKSTAMP.earlyworkin) {
        setEarlyworkinTime(res?.data?.earlyworkin_time || 0);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div style={{ padding: "10px 5px" }} className="total-people">
        <span
          className="border-line zoom_font"
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          오늘 출근상태
        </span>
        <span
          style={{
            color: "#fd9f9f",
            minWidth: "55px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {auth.user.isEmployee ? checkWorkstamp : ""}
        </span>
        <span style={{ minWidth: "195px" }}>
          <button
            disabled={
              (workstampType !== WORKSTAMP.earlyworkin &&
                workstampType !== WORKSTAMP.workin &&
                workstampType !== WORKSTAMP.lateworkin) ||
              buttonC ||
              workstampType === WORKSTAMP.not
            }
            className="pill greenbtn border-line zoom_font mouse"
            id="diligence"
            onClick={async () => {
              if (workstampType === WORKSTAMP.earlyworkin) {
                if (earlyworkin_time <= -60) {
                  setToast({
                    ...toast,
                    error: true,
                    message: "조기출근은 출근 한 시간 전부터 가능합니다.",
                  });
                  return;
                }
              }
              setButtonC(true);
              setWorkstampTime(dayjs().format("YYYY-MM-DD HH:mm:ss"));
              await createWorkstampActions.createWorkstamp().finally(() => {
                setButtonC(false);
              });
            }}
          >
            출근하기
          </button>
          <button
            disabled={
              (workstampType !== WORKSTAMP.workout &&
                workstampType !== WORKSTAMP.lateworkout &&
                workstampType !== WORKSTAMP.dailyReportRequired &&
                workstampType !== WORKSTAMP.notLeavingWork &&
                workstampType !== WORKSTAMP.earlyworkout) ||
              buttonC ||
              workstampType === WORKSTAMP.not
            }
            className="pill greenbtn border-line zoom_font mouse"
            id="diligence"
            onClick={async () => {
              setButtonC(true);
              setWorkstampTime(dayjs().format("YYYY-MM-DD HH:mm:ss"));
              await createWorkstampActions.createWorkstamp().finally(() => {
                setButtonC(false);
              });
            }}
          >
            퇴근하기
          </button>
        </span>
      </div>
      <CommonModalScrollTop
        open={isReasonModalOpen}
        setOpen={setIsReasonModalOpen}
        title="사유 입력"
      >
        <div style={{ marginTop: "-20px" }}>
          <table>
            <tbody>
              <tr>
                <td className={"text-center-title"} style={{ width: "20%" }}>
                  <div>사유 선택</div>
                </td>
                <td>
                  <div className="select-border-line">
                    <Select
                      className="elec-select2"
                      placeholder="기타(직접입력)"
                      options={
                        workstampType === WORKSTAMP.notLeavingWork
                          ? workstampNotLeavingWorkList
                          : workstampType === WORKSTAMP.earlyworkin
                          ? workstampEarlyWorkinList
                          : workstampType === WORKSTAMP.lateworkin
                          ? workstampLateWorkinList
                          : workstampType === WORKSTAMP.lateworkout
                          ? workstampLateWorkoutList
                          : workstampEarlyWorkoutList
                      }
                      onChange={(e) => {
                        setReason(e.value);
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={"text-center-title"} style={{ width: "20%" }}>
                  {reasonModalText}
                </td>
                <td>
                  <textarea
                    className="textarea"
                    placeholder={"사유를 입력해주세요."}
                    onChange={(e) => setReason(e.target.value)}
                    value={reason}
                    style={{ width: "100%" }}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="modalfoot">
            <div className="btn-group">
              <button
                className="fbtn submitbtn"
                disabled={buttonC2}
                onClick={async () => {
                  if (reason.trim() === "") {
                    setToast({
                      ...toast,
                      error: true,
                      message: "사유를 입력해 주세요.",
                    });
                  } else {
                    setButtonC2(true);
                    await createWorkstampActions
                      .createReasonWorkstamp()
                      .finally(() => {
                        setButtonC2(false);
                        setReason("");
                      });
                  }
                }}
              >
                입력
              </button>
            </div>
          </div>
        </div>
      </CommonModalScrollTop>
      <CommonImageModal
        open={isDreamCafeModalOpen}
        setOpen={setIsDreamCafeModalOpen}
      ></CommonImageModal>
    </>
  );
};

export default EmployeeWorkstampHome;
