import { useRecoilValue } from "recoil";
import { authAtom } from "../../../recoil";
import { SCHEDULETYPE } from "../../../JSON/enum/Dataenum";

const ScheduleFunction = () => {
  const auth = useRecoilValue(authAtom);

  return {
    getScheduleType,
    scheduleListOptions,
  };

  function getScheduleType(scheduleType) {
    switch (scheduleType) {
      case SCHEDULETYPE.MEETING:
        return auth?.commonCode[19]["미팅"];
      case SCHEDULETYPE.BIRTHDAY:
        return auth?.commonCode[20]["생일"];
      case SCHEDULETYPE.HALFHALFOFF:
        return auth?.commonCode[21]["반반차"];
      case SCHEDULETYPE.HALFOFF:
        return auth?.commonCode[22]["반차"];
      case SCHEDULETYPE.DAYOFF:
        return auth?.commonCode[23]["연차"];
      case SCHEDULETYPE.OUTWORKING:
        return auth?.commonCode[24]["외근"];
      case SCHEDULETYPE.BUSINESSTRIP:
        return auth?.commonCode[25]["출장"];
      case SCHEDULETYPE.CONFFRENCE:
        return auth?.commonCode[26]["행사"];
      case SCHEDULETYPE.HALFAM:
        return auth?.commonCode[28]["오전 반차"];
      case SCHEDULETYPE.HALFPM:
        return auth?.commonCode[29]["오후 반차"];
      case SCHEDULETYPE.HALFHALF:
        return auth?.commonCode[33]["반반차"];
      case SCHEDULETYPE.CONGRATULATION:
        return auth?.commonCode[31]["경조사"];
      case SCHEDULETYPE.LEAVE:
        return auth?.commonCode[32]["병가"];
      case SCHEDULETYPE.ETC:
        return auth?.commonCode[30]["기타 휴가"];
      default:
        return "기타";
    }
  }

  function scheduleListOptions() {
    return [
      { value: null, label: "전체" },
      { value: SCHEDULETYPE.MEETING, label: auth?.commonCode[19]["미팅"] },
      { value: SCHEDULETYPE.BIRTHDAY, label: auth?.commonCode[20]["생일"] },
      {
        value: SCHEDULETYPE.HALFHALFOFF,
        label: auth?.commonCode[21]["반반차"],
      },
      { value: SCHEDULETYPE.HALFOFF, label: auth?.commonCode[22]["반차"] },
      { value: SCHEDULETYPE.DAYOFF, label: auth?.commonCode[23]["연차"] },
      { value: SCHEDULETYPE.OUTWORKING, label: auth?.commonCode[24]["외근"] },
      { value: SCHEDULETYPE.BUSINESSTRIP, label: auth?.commonCode[25]["출장"] },
      { value: SCHEDULETYPE.CONFFRENCE, label: auth?.commonCode[26]["행사"] },
    ];
  }
};

export default ScheduleFunction;
