import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../recoil";
import useManagementActions from "../../../recoil/api/management";
import { PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchKeyword from "../../common/SearchKeyword";
import CommonUpdateModal from "../management/CommonUpdateModal";

const CommonCode = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const [isPlusModalOpen, setIsPlusModalOpen] = useState(false);
  const { getCommoncCode } = useManagementActions();

  const [commonList, setCommonList] = useState([]);
  const [commonSelected, setCommonSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const [keyword, setKeyword] = useState("");

  const getCommon = async (pageInfo) => {
    setLoading(true);
    const res = await getCommoncCode({ pageInfo, keyword });
    setPageInfo(res?.data.pageinfo);
    setCommonList(res?.data.list);
    setLoading(false);
  };

  useEffect(() => {
    getCommon(pageInfo);
  }, [pageInfo?.current, isPlusModalOpen, keyword]);

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <div className="date-filtering" style={{ justifyContent: "end" }}>
        <div className="all-group"></div>
        {/* 코드명을 기준으로 검색하는 API가 필요함 */}
        <SearchKeyword placeholder="코드명" setKeyword={setKeyword} />
      </div>
      <DataTable
        loading={loading}
        checkbox={false}
        columns={["관련 페이지", "코드명", "표시명", "비고"]}
      >
        {commonList?.map((obj) => (
          <tr
            key={obj.sn}
            className="text-center"
            onClick={() => {
              setIsPlusModalOpen(true);
              setCommonSelected(obj);
            }}
          >
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj.page}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj.codename}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj.text}
            </td>
            <td
              className="border-line mouse zoom_font"
              style={{ cursor: "pointer" }}
            >
              {obj.memo}
            </td>
            {/* <td>
              {obj?.url > 0 && (
                <button
                  className="t-download border-line mouse"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{ cursor: "pointer" }}
                ></button>
              )}
            </td>
            <td
              className="complete hide border-line zoom_font"
              style={{ cursor: "pointer" }}
            ></td> */}
          </tr>
        ))}
      </DataTable>
      <CommonUpdateModal
        common={commonSelected}
        title="공통코드 편집"
        open={isPlusModalOpen}
        setOpen={setIsPlusModalOpen}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </div>
  );
};

export default CommonCode;
