import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { HRMTYPE } from "../../../JSON/enum/Dataenum";
import { authAtom, toastAtom } from "../../../recoil";
import useHrmActions from "../../../recoil/api/hrm";
import BreadCrumb from "../../common/BreadCrumb";
import { useState } from "react";
import Select from "react-select";

const HrmWrite = ({ hrm }) => {
  const hrmActions = useHrmActions();
  const { sn } = useParams();
  const navigate = useNavigate();
  const [toast, setToast] = useRecoilState(toastAtom);
  const auth = useRecoilValue(authAtom);
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: { id: auth.user.userName, type: HRMTYPE.HIRED + "" },
  });
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // 입사일 값 스테이트
  const [startWorkDate, setStartWorkDate] = useState("");
  const [endWorkDate, setEndWorkDate] = useState("");
  // 용도
  const [purpose, setPurpose] = useState("");
  const hrmPurpose = [
    { value: "개인확인용", label: "개인확인용" },
    { value: "공공기관 제출용", label: "공공기관 제출용" },
    { value: "금융기관 제출용", label: "금융기관 제출용" },
    { value: "기타", label: "기타" },
  ];

  useEffect(() => {
    if (hrm) {
      reset({
        id: hrm.id,
        createtime: hrm.createtime,
        type: hrm.type + "",
        department: hrm.department,
        position: hrm.position,
        birthday: hrm.birthday,
        joinday: dayjs(hrm?.joinday?.substring(0, 10)).format("YYYY-MM-DD"),
        address: hrm.address,
        // address_submit: hrm.address_submit,
        purpose: hrm.purpose,
      });
      setPurpose(hrm.purpose);
      setStartWorkDate(
        dayjs(hrm?.joinday?.substring(0, 10)).format("YYYY-MM-DD")
      );
      setEndWorkDate(hrm?.joinday?.substring(10, 23));
    }
  }, [hrm]);

  useEffect(() => {
    getEmployeeSend();
  }, []);

  const getEmployeeSend = async () => {
    let result = await hrmActions.getEmployee();

    if (result.status === 200) {
      setStartWorkDate(
        dayjs(result.data.ishired_datetime.substring(0, 10)).format(
          "YYYY-MM-DD"
        )
      );
    }
  };

  const onSubmit = async (data) => {
    if (data.department.trim() === "") {
      setToast({ ...toast, error: true, message: "근무부서를 입력해주세요." });
      return;
    }
    if (data.position.trim() === "") {
      setToast({ ...toast, error: true, message: "직책을 입력해주세요." });
      return;
    }
    if (data.birthday.trim() === "") {
      setToast({ ...toast, error: true, message: "생년월일을 입력해주세요." });
      return;
    }
    if (startWorkDate.trim() === "") {
      setToast({ ...toast, error: true, message: "입사일을 입력해주세요." });
      return;
    }
    if (data.address.trim() === "") {
      setToast({ ...toast, error: true, message: "주소를 입력해주세요." });
      return;
    }
    // if (data.address_submit.trim() === "") {
    //   setToast({ ...toast, error: true, message: "제출처를 입력해주세요." });
    //   return;
    // }
    if (purpose.trim() === "") {
      setToast({ ...toast, error: true, message: "용도를 입력해주세요." });
      return;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    // 수정
    if (hrm) {
      const res = await hrmActions.updateHrm({
        docsn: sn,
        COMP_CD: auth.user.COMP_CD,
        esn: auth.user.esn,
        type: parseInt(data.type),
        department: data.department,
        position: data.position,
        birthday: data.birthday,
        joinday: startWorkDate + endWorkDate,
        address: data.address,
        address_submit: "0",
        purpose: purpose,
      });
      if (res.status === 200) {
        navigate(`/${auth.accountType}/hrm`);
        setToast({ ...toast, on: true });
      }
    } // 저장
    else {
      const res = await hrmActions.hrmRegist({
        id: auth.user.id,
        type: parseInt(data.type),
        department: data.department,
        position: data.position,
        birthday: data.birthday,
        joinday: startWorkDate,
        address: data.address,
        address_submit: "0",
        purpose: purpose,
      });
      if (res.status === 200) {
        navigate(`/${auth.accountType}/hrm`);
        setToast({ ...toast, on: true });
      }
    }

    setDoubleclickLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BreadCrumb />
        <div className="table-responsive">
          <table className="writetable">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font">대상자</th>
                <td>
                  <input
                    className="zoom_font"
                    style={{ border: "none", background: "none" }}
                    {...register("id", { required: true })}
                    disabled
                  />
                </td>
                <th className="text-center-title zoom_font">작성일</th>
                <td className="zoom_font">{dayjs().format("YYYY-MM-DD")}</td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">증명서 종류</th>
                <td colSpan="5">
                  <div className="checkbt macheck" style={{ marginRight: 30 }}>
                    <input
                      {...register("type", { required: true })}
                      type="radio"
                      name="type"
                      className="vertical-middle border-line"
                      value={HRMTYPE.HIRED}
                      id="hired"
                    />
                    <label
                      className="vertical-middle zoom_font"
                      htmlFor="hired"
                    >
                      &nbsp;재직증명서
                    </label>
                    <input
                      {...register("type", { required: true })}
                      type="radio"
                      name="type"
                      className="vertical-middle border-line"
                      value={HRMTYPE.CAREER}
                      id="career"
                      style={{ marginLeft: 30 }}
                    />
                    <label
                      className="vertical-middle zoom_font"
                      htmlFor="career"
                    >
                      &nbsp;경력증명서
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">근무부서</th>
                <td colSpan="5">
                  <input
                    {...register("department")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="근무부서를 입력하세요."
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">직책</th>
                <td colSpan="5">
                  <input
                    {...register("position")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="직책을 입력하세요."
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">생년월일</th>
                <td colSpan="5">
                  <input
                    {...register("birthday")}
                    className="border-line mouse zoom_font"
                    placeholder="생년월일을 입력하세요."
                    id="birthDay"
                    type="date"
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">입사일</th>
                <td colSpan="5">
                  <input
                    {...register("joinday")}
                    className="border-line mouse zoom_font"
                    placeholder="입사일을 입력하세요."
                    type="date"
                    onChange={(e) => {
                      setStartWorkDate(e.target.value);
                    }}
                    defaultValue={startWorkDate}
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">주소</th>
                <td colSpan="5">
                  <input
                    {...register("address")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="주소를 입력하세요."
                  />
                </td>
              </tr>
              {/* <tr>
                <th className="text-center-title zoom_font">제출처</th>
                <td colSpan="5">
                  <input
                    {...register("address_submit")}
                    className="write-input border-line mouse zoom_font"
                    placeholder="제출처를 입력하세요."
                  />
                </td>
              </tr> */}
              <tr>
                <th className="text-center-title zoom_font">용도</th>
                <td colSpan="5">
                  <Select
                    placeholder={purpose === "" ? "용도 선택" : purpose}
                    options={hrmPurpose}
                    onChange={(e) => {
                      setPurpose(e.value);
                    }}
                  ></Select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-group">
          <button
            className="submitbtn fbtn border-line zoom_font"
            type="submit"
          >
            {hrm ? "수정" : "저장"}
          </button>

          <button
            className="fbtn cancelbtn border-line zoom_font"
            type="button"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
        </div>
      </form>
    </>
  );
};
export default HrmWrite;
